import { Tooltip } from 'antd'
import { InfoExclamation } from '../../../../assets/svg/info-excalamation'
import { userProfileSVG } from '../../../../assets/svg/userProfile'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { ChargerInfo } from '../../../../models/chargerv2'
import { HomePageActiveUsersChart } from '../../charts/active-users/active-users'
import { CoreInfoCard } from 'components/cards'
import { theme } from 'theme'

interface props {
  chargerInfo: ChargerInfo | undefined
  wsLoading: boolean
}

const ActiveUsersCard: React.FC<props> = ({ chargerInfo, wsLoading }) => {
  const { activeUsersText, chargingText, idleText, transactionOngoingText } = useDashboardTranslation()
  const infoDetails = [
    {
      label: chargingText,
      subLabel: chargerInfo?.users.charging,
    },
    {
      label: idleText,
      subLabel: chargerInfo?.users.idle,
      icon: (
        <Tooltip color={theme.colors.softBlack} title={transactionOngoingText}>
          <InfoExclamation />
        </Tooltip>
      ),
    },
  ]
  return (
    <CoreInfoCard
      title={activeUsersText}
      wsLoading={wsLoading}
      infoText={chargerInfo?.users?.active}
      chargerInfoChart={chargerInfo && <HomePageActiveUsersChart chargerInfo={chargerInfo} />}
      overviewIcon={userProfileSVG}
      infoDetails={chargerInfo && chargerInfo.users && infoDetails}
    />
  )
}

export default ActiveUsersCard
