import { SuccessTaggingResponse, Tagging } from 'models/tagging'
import { TaggingResponse } from '../models/http'

export const mockListingTaggingResp: Tagging[] = [
  {
    id: 1,
    tagName: 'SWTCH Care',
    status: 'active',
    tableName: 'Listing',
    frequency: 'none',
    tagType: 'admin',
    autoExpiration: 'never',
    note: 'note',
    createdAt: '2024-05-08T00:00:00.000Z',
    createdBy: '123',
    taggedObjects: [],
  },
  {
    id: 2,
    tagName: 'Nayax_Spark',
    status: 'active',
    tableName: 'Listing',
    frequency: 'none',
    tagType: 'admin',
    autoExpiration: 'never',
    note: '',
    createdAt: '2024-05-08T00:00:00.000Z',
    createdBy: '123',
    taggedObjects: [],
  },
]

export const mockTenantTaggingResp: Tagging[] = [
  {
    id: 3,
    tagName: 'CTEP',
    status: 'active',
    tableName: 'Tenant',
    frequency: 'none',
    tagType: 'admin',
    autoExpiration: 'never',
    note: '',
    createdAt: '2024-05-08T00:00:00.000Z',
    createdBy: '123',
    taggedObjects: [],
  },
  {
    id: 4,
    tagName: '10+Day Under Repair',
    status: 'active',
    tableName: 'Tenant',
    frequency: 'none',
    tagType: 'admin',
    autoExpiration: 'never',
    note: '',
    createdAt: '2024-05-08T00:00:00.000Z',
    createdBy: '123',
    taggedObjects: [],
  },
]

export const mockAllTaggingResp: TaggingResponse = {
  data: [...mockListingTaggingResp, ...mockTenantTaggingResp],
  pagination: { currentPage: 1, totalEntries: 2, perPage: 10 },
}

export const mockSuccess: SuccessTaggingResponse = {
  message: 'Successfully applyed taggings to objects',
}
