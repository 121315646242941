import { useFormatMessage } from '../../helpers/intl'

export const usePlanTranslation = () => {
  const subscribersText = useFormatMessage('dashboard.text.subscribers', 'Subscribers')
  const deleteMessage = useFormatMessage('dashboard.plan.modal.delete', 'Are you sure you want to delete the plan ')
  const cancelText = useFormatMessage('dashboard.reports.form.sendReport.cancelText', 'Cancel')
  const subscribeText = useFormatMessage('dashboard.button.subscribe', 'Subscribe')

  const editPlanText = useFormatMessage('dashboard.plan.editPlan', 'Edit Plan')
  const nameText = useFormatMessage('dashboard.text.name', 'Name')
  const emailsText = useFormatMessage('dashboard.reports.form.sendReport.emails.label', 'Emails')
  const descriptionText = useFormatMessage('dashboard.text.description', 'Description')
  const placeholderText = useFormatMessage('dashboard.subscriptionsPage.placeholder.searchPlan', 'Search Plan')
  const archiveMessage = useFormatMessage('dashboard.plan.modal.archive', 'Are you sure you want to archive the plan ')
  const unarchiveMessage = useFormatMessage(
    'dashboard.plan.modal.unarchive',
    'Are you sure you want to unarchive the plan ',
  )

  return {
    subscribersText,
    deleteMessage,
    cancelText,
    subscribeText,
    editPlanText,
    nameText,
    emailsText,
    descriptionText,
    placeholderText,
    archiveMessage,
    unarchiveMessage,
  }
}
