import { styled, theme } from '../../theme'

interface props {
  isAdmin?: boolean
}

export const CollapseWrapper = styled.div`
  .ant-collapse-borderless {
    background-color: ${(props) => props.theme.colors.white};
    border: none;
  }
`

export const UserCollapseWrapper = styled.div<props>`
  margin-left: ${(props) => (props.isAdmin ? '0' : '-20px')};
  margin-right: ${(props) => (props.isAdmin ? '0' : '-20px')};
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};

  .ant-collapse-borderless {
    background-color: ${(props) => props.theme.colors.white};
    border: none;
  }

  .ant-collapse-item-active {
    background-color: ${(props) => props.theme.colors.grayBackground};
    border: none;
  }

  .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    border-bottom: 1px solid #d9d9d9;
  }
`

export const ChargerCollapseWrapper = styled.div<props>`
  font-family: ${theme.fontFamily.regularFontFamily};

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra > a {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-pagination {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const AdvancedSearchWrapper = styled.div<{ showAdvancedSearch?: boolean }>`
  opacity: ${(props) => (props.showAdvancedSearch ? '1' : '0')}; /* Initial opacity */
  height: ${(props) => (props.showAdvancedSearch ? 'auto' : '0')}; /* Initial height */
  transition: opacity 500ms ease, height 500ms ease; /* Smooth transition */

  .ant-row {
    overflow: hidden;
  }

  .reset-button > button {
    padding: 9px 9px;
  }
`
