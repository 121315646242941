import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Empty, Row, Skeleton, Tabs } from 'antd'

import { ChargerTabDetail } from '../../../../components/chargers/charger-tab-detail'
import { ChargerTabDiagnostics } from '../../../../components/chargers/charger-tab-diagnostics'
import { ChargerTabListing } from '../../../../components/chargers/charger-tab-listing'
import { ChargerTabLog } from '../../../../components/chargers/charger-tab-log'
import { ChargerTabRemote } from '../../../../components/chargers/charger-tab-remote'

import { Charger } from '../../../../models/charger'
import { Connector } from '../../../../models/connector'
import { canManageCharger } from '../../../../models/user'

import { Box } from '../../../../atom/box'
import { ChargerTabDREventSimulator } from '../../../../components/chargers/charger-tab-dr-event-simulator'
import { renderFormatMessage, useFormatMessage } from '../../../../helpers/intl'
import { useAppState } from '../../../../state'
import { ChargerTabDetailMobileView } from './charger-tab-detail-mobile-view'

const { TabPane } = Tabs

interface props {
  loading: boolean
  charger: Charger
  connector?: Connector
}

export const ChargerTabs: React.FC<props> = ({ loading, charger, connector }) => {
  const { currentUser, IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const canViewCharger = currentUser?.role === 'user' && canManageCharger(currentUser.accesses, charger)

  const remoteControlTabDescriptionText = useFormatMessage(
    'dashboard.chargerPage.remotecontroltab.description',
    'Select a connector to proceed',
  )

  const demandResponseTabText = useFormatMessage('dashboard.chargerPage.DREventTab.heading', 'Demand Response')

  const chargerDetails = (
    <Skeleton loading={loading}>
      {charger && (isMobile || isTablet) && (
        <ChargerTabDetailMobileView charger={charger} connectors={charger.connectors} />
      )}
      {charger && (isDesktop || isLaptop) && <ChargerTabDetail charger={charger} />}
    </Skeleton>
  )

  const chargerRemoteControl = (
    <Skeleton loading={loading}>
      {charger && connector && <ChargerTabRemote charger={charger} connector={connector} />}
      {!connector && (
        <Empty
          image={<FontAwesomeIcon icon={faChargingStation} style={{ fontSize: 60 }} />}
          description={remoteControlTabDescriptionText}
        />
      )}
    </Skeleton>
  )

  const chargerLogs = (
    <Skeleton loading={loading}>
      {charger && connector && <ChargerTabLog charger={charger} connector={connector} />}
      {!connector && (
        <Empty
          image={<FontAwesomeIcon icon={faChargingStation} style={{ fontSize: 60 }} />}
          description={remoteControlTabDescriptionText}
        />
      )}
    </Skeleton>
  )

  return (
    <Row>
      <Col span={24}>
        <Box>
          <Tabs defaultActiveKey="1" tabBarStyle={{ paddingLeft: '1.5em' }}>
            <TabPane tab={renderFormatMessage('dashboard.text.details', 'Details')} key="details">
              {chargerDetails}
            </TabPane>
            {!canViewCharger && (
              <TabPane
                tab={renderFormatMessage('dashboard.chargerPage.remoteControlTab.heading', 'Remote Control')}
                key="remote_control"
              >
                {chargerRemoteControl}
              </TabPane>
            )}
            <TabPane
              tab={renderFormatMessage('dashboard.chargerPage.logs.chargerLogs.heading', 'Charger Logs')}
              key="charger_logs"
            >
              {chargerLogs}
            </TabPane>
            {!canViewCharger && (
              <TabPane tab={renderFormatMessage('dashboard.text.listing', 'Listing')} key="listing">
                <Skeleton loading={loading}>{charger && <ChargerTabListing charger={charger} />}</Skeleton>
              </TabPane>
            )}
            {!canViewCharger && (
              <TabPane
                tab={renderFormatMessage('dashboard.chargerPage.diagnosticsTab.heading', 'Diagnostics')}
                key="charger_diagnostics"
              >
                <Skeleton loading={loading}>{charger && <ChargerTabDiagnostics charger={charger} />}</Skeleton>
              </TabPane>
            )}
            {(charger.tenant?.displayName === 'engineering' || charger.tenant?.name === 'engineering') &&
              charger.chargePointSerialNumber === 'SWT00000101' &&
              charger.connectors[0].listing?.title === 'Kite Vehicle' && (
                <TabPane tab={demandResponseTabText} key="charger_dr_event_simulator">
                  <Skeleton loading={loading}>{charger && <ChargerTabDREventSimulator charger={charger} />}</Skeleton>
                </TabPane>
              )}
          </Tabs>
        </Box>
      </Col>
    </Row>
  )
}
