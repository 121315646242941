import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'

import { TrxSummary } from '../../../../models/transaction'
import { secondsToTime } from './transaction-overview'
import { renderFormatMessage } from '../../../../helpers/intl'
import { styled, theme } from '../../../../theme'

highchartsMore(Highcharts)

const HomePageGaugeChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .highcharts-gauge-series .highcharts-pivot {
    stroke-width: 1px;
    stroke: black;
    fill-opacity: 0.3;
  }

  .highcharts-yaxis-grid .highcharts-grid-line,
  .highcharts-yaxis-grid .highcharts-minor-grid-line {
    display: none;
  }

  .highcharts-yaxis .highcharts-minor-tick {
    stroke-width: 2px;
  }

  .highcharts-plot-band,
  .highcharts-pane {
    fill: transparent !important;
  }

  .utlization-insight-gauge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .utlization-insight-gauge::after {
    content: ' ';
    width: 200px;
    height: 200px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .highcharts-data-labels.highcharts-series-0.highcharts-gauge-series.highcharts-color-0.highcharts-tracker {
    display: none;
  }

  .utlization-insight-gauge-container {
    position: absolute;
    text-align: center;
  }
  .utlization-insight-gauge-container h1,
  .utlization-insight-gauge-container h3 {
    margin: 0;
  }

  .highcharts-axis.highcharts-yaxis.highcharts-radial-axis {
    stroke: transparent;
  }

  .utlization-insight-gauge-container h1 {
    font-size: 20px;
    font-weight: 900;
  }

  .utlization-insight-gauge-container p {
    opacity: 0.6;
  }

  .utlization-insight-gauge-axis-label {
    display: flex;
    position: absolute;
    justify-content: space-between;
    min-width: 220px;
    align-items: center;
    margin-top: 70%;
    margin-left: -50%;
  }

  .utlization-insight-gauge-axis-label p {
    left: -40%;
    bottom: -100%;
  }

  .utlization-insight-gauge-axis-label.subtext {
    margin-top: 90%;
    justify-content: end;
  }

  .utlization-insight-gauge-axis-label.subtext span {
    font-size: 12px;
    opacity: 0.6;
  }

  .text {
    display: flex;
    flex-direction: row;
    margin-top: 100%;
    position: absolute;
    min-width: 300px;
    justify-content: start;
    left: -50%;
  }

  .utlization-insight-gauge-axis-label p {
    opacity: 1;
  }

  .utlization-insight-gauge-axis-label.subtext p {
    opacity: 0.6;
  }

  .utlization-insight-gauge-axis-label p:first-of-type {
    /* margin-left: 15px; */
  }

  .utlization-insight-gauge-axis-label p:nth-of-type(2) {
    /* margin-right: 15px; */
  }
`

interface props {
  trxSummary: TrxSummary
  activeKey: string
}

export const HomePageGaugeChartUtlizationInsight: React.FC<props> = ({ trxSummary, activeKey }) => {
  const [gaugeChartUtilizationOptions, setGaugeChartUtlizationOptions] = useState({
    chart: {
      type: 'gauge',
      styledMode: true,
      className: 'utlization-insight-gauge',
      width: 300,
      height: 300,
      events: {
        render: function () {
          var chart: any = this,
            yAxis = chart.yAxis[0],
            key,
            tick,
            minorTick

          //console.log('-----chart----')
          // console.log(chart.series[0].data[0].y)

          for (key in yAxis.ticks) {
            tick = yAxis.ticks[key]

            if (tick.pos < 120) {
              tick.mark.attr({ stroke: 'red' })
            } else if (tick.pos >= 120 && tick.pos < 160) {
              tick.mark.attr({ stroke: 'green' })
            } else if (tick.pos >= 160) {
              tick.mark.attr({ stroke: 'yellow' })
            }
          }

          for (key in yAxis.minorTicks) {
            minorTick = yAxis.minorTicks[key]

            //console.log('---minorTick--')
            //console.log(minorTick)

            if (minorTick.pos <= chart.series[0].data[0].y) {
              minorTick.mark.attr({ stroke: theme.colors.primary })
            } else {
              minorTick.mark.attr({ stroke: '#999' })
            }

            // if (minorTick.pos < 120) {
            //   minorTick.mark.attr({ stroke: 'orange' })
            // } else if (minorTick.pos >= 120 && minorTick.pos < 160) {
            //   minorTick.mark.attr({ stroke: 'tomato' })
            // } else if (minorTick.pos >= 160) {
            //   minorTick.mark.attr({ stroke: 'blue' })
            // }
          }
        },
      },
    },

    title: {
      text: '',
    },

    pane: {
      startAngle: -130,
      endAngle: 130,
      size: '100%',
      center: ['50%', '50%'],
    },

    // the value axis
    yAxis: {
      min: 0,
      max: trxSummary.chargerAverageDayPlugin,

      minorTickInterval: 'auto',
      minorTickLength: 5,
      minorTickPosition: 'inside',

      tickPixelInterval: 30,
      tickPosition: 'outside',
      tickLength: 1,
      labels: {
        enabled: false,
      },
      // title: {
      //   text: 'km/h',
      // },
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        name: '',
        data: [0],
        dial: {
          path: [
            'M 105.0574 1.344 C 105.6109 2.91 107.1044 4.032 108.86 4.032 C 111.0868 4.032 112.892 2.2268 112.892 0 C 112.892 -2.2268 111.0868 -4.032 108.86 -4.032 C 107.1044 -4.032 105.6109 -2.91 105.0574 -1.344 L 95.42 -1.344 V 1.344 H 105.0574 Z',
          ],
        },
        tooltip: {
          valueSuffix: '',
        },
        //   dataLabels: {
        //     format:
        //       '<div  class="odometer">' +
        //       '<span style="font-size:25px">{y}</span> </br>' +
        //       '<span >Charging</span' +
        //       '</div>',
        //   },
      },
    ],

    plotOptions: {
      gauge: {
        dial: {
          radius: '100%',
          // backgroundColor: 'silver',
          borderColor: 'black',
          borderWidth: 1,
          // baseWidth: 10,
          topWidth: 1,
        },
      },
    },
  })

  const [plugInDuration, setPlugInDuration] = useState<{
    h: number
    m: number
    s: number
  }>()

  const [chargingDuration, setChargingDuration] = useState<{
    h: number
    m: number
    s: number
  }>()

  useEffect(() => {
    switch (activeKey) {
      case 'All':
        setGaugeChartUtlizationOptions({
          ...gaugeChartUtilizationOptions,
          yAxis: {
            ...gaugeChartUtilizationOptions.yAxis,
            max: trxSummary.chargerAverageDayPlugin,
          },
          series: [
            {
              ...gaugeChartUtilizationOptions.series[0],
              data: [trxSummary.chargerAverageDayDuration],
            },
          ],
        })

        trxSummary.chargerAverageDayPlugin && setPlugInDuration(secondsToTime(trxSummary.chargerAverageDayPlugin))
        trxSummary.chargerAverageDayDuration && setChargingDuration(secondsToTime(trxSummary.chargerAverageDayDuration))

        break
      case 'Level2':
        setGaugeChartUtlizationOptions({
          ...gaugeChartUtilizationOptions,
          yAxis: {
            ...gaugeChartUtilizationOptions.yAxis,
            max: trxSummary.chargerAverageDayLevel2Plugin,
          },
          series: [
            {
              ...gaugeChartUtilizationOptions.series[0],
              data: [trxSummary.chargerAverageDayLevel2Duration],
            },
          ],
        })

        trxSummary.chargerAverageDayLevel2Plugin &&
          setPlugInDuration(secondsToTime(trxSummary.chargerAverageDayLevel2Plugin))
        trxSummary.chargerAverageDayLevel2Duration &&
          setChargingDuration(secondsToTime(trxSummary.chargerAverageDayLevel2Duration))

        break
      case 'DCFC':
        setGaugeChartUtlizationOptions({
          ...gaugeChartUtilizationOptions,
          yAxis: {
            ...gaugeChartUtilizationOptions.yAxis,
            max: trxSummary.chargerAverageDayDcfcPlugin,
          },
          series: [
            {
              ...gaugeChartUtilizationOptions.series[0],
              data: [trxSummary.chargerAverageDayDcfcDuration],
            },
          ],
        })

        trxSummary.chargerAverageDayDcfcPlugin &&
          setPlugInDuration(secondsToTime(trxSummary.chargerAverageDayDcfcPlugin))
        trxSummary.chargerAverageDayDcfcDuration &&
          setChargingDuration(secondsToTime(trxSummary.chargerAverageDayDcfcDuration))

        break
    }
  }, [trxSummary, activeKey])

  return (
    <>
      {trxSummary && (
        <HomePageGaugeChartContainer>
          <HighchartsReact highcharts={Highcharts} options={gaugeChartUtilizationOptions} />
          <div className="utlization-insight-gauge-container">
            {chargingDuration && <h1 className="heading-04-black">{`${chargingDuration.h}h`}</h1>}
            {chargingDuration && <h1 className="heading-04-black">{`${chargingDuration.m}min`}</h1>}
            <p className="paragraph-04-regular">
              {renderFormatMessage(
                'dashboard.homePage.utilizationInsights.dial.chargingDurationText',
                'Charging Duration',
              )}
            </p>
            <div className="utlization-insight-gauge-axis-label">
              <p>
                <b>0</b>
              </p>
              {plugInDuration && (
                <p>
                  <b>{`${plugInDuration.h}h ${plugInDuration.m}m ${plugInDuration.s}s`}</b>
                </p>
              )}
            </div>
            <div className="utlization-insight-gauge-axis-label subtext">
              <p className="paragraph-03-regular">
                {renderFormatMessage(
                  'dashboard.homePage.utilizationInsights.dial.plugInDurationText',
                  'Plug-in-duration',
                )}
              </p>
            </div>
          </div>
        </HomePageGaugeChartContainer>
      )}
    </>
  )
}
