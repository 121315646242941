import { Button, message, Modal } from 'antd'
import { useState } from 'react'

import { useFormatMessage } from '../../../helpers/intl'
import { SwtchError } from '../../../models/error'
import { Plan } from '../../../models/plan'
import { ToggleActivePlan } from '../../../services/data-provider/plan'
import { AlertError } from '../../error'

interface props {
  plan: Plan
  onCancel: () => void
  onTogglePlan: (p: Plan) => void
}

export const DeletePlanModal: React.FC<props> = ({ plan, onCancel, onTogglePlan }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)

  const cancelText = useFormatMessage('dashboard.reports.form.sendReport.cancelText', 'Cancel')
  const deleteMessage = useFormatMessage('dashboard.plan.modal.delete', 'Are you sure you want to delete the plan ')

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    ToggleActivePlan(plan)
      .then((response) => {
        message.success(response.message)
        onTogglePlan(response.data)
      })
      .catch((error: SwtchError) => setError(error))
      .finally(() => setLoading(false))
  }

  const toggleTitle = plan.status === 'active' ? 'Archive Plan' : 'Make Available'

  return (
    <Modal
      title={toggleTitle}
      closable={false}
      visible={true}
      onCancel={() => onCancel()}
      onOk={onOk}
      afterClose={() => onCancel()}
      footer={[
        <Button key="back" loading={loading} onClick={() => onCancel()}>
          {cancelText}
        </Button>,
        <Button key="submit" type="primary" danger loading={loading} onClick={onOk}>
          {toggleTitle}
        </Button>,
      ]}
    >
      <AlertError error={error} />
      {deleteMessage} <strong>{plan.name}</strong>?
    </Modal>
  )
}
