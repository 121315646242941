import { Collapse, List } from 'antd'
import moment from 'moment'

import { renderFormatMessage } from '../../helpers/intl'
import { ChargerDailyMetric } from '../../models/analytics'

const { Panel } = Collapse

interface props {
  metrics: ChargerDailyMetric[]
}

export const DisconnectionsListView: React.FC<props> = ({ metrics }) => {
  return (
    <Collapse defaultActiveKey={['0']}>
      {metrics.map((metric, index) => (
        <Panel key={index} header={metric.tenant_display_name || metric.tenant || '---'} extra={metric.from}>
          <List
            dataSource={[metric]}
            renderItem={(metric) => (
              <>
                <List.Item actions={[metric.charger_serial_number]}>
                  <List.Item.Meta
                    title={renderFormatMessage(
                      'dashboard.disconnectionpage.table.heading.chargerSerialNumber',
                      'Charger Serial Number',
                    )}
                  />
                </List.Item>
                <List.Item actions={[`${moment.duration(metric.disconnect_daily_avg, 'milliseconds').seconds()} sec`]}>
                  <List.Item.Meta
                    title={renderFormatMessage(
                      'dashboard.disconnectionPage.table.heading.avgDisconnectDuration',
                      'Avg Daily Disconnect',
                    )}
                  />
                </List.Item>
                <List.Item actions={[metric.disconnect_total]}>
                  <List.Item.Meta
                    title={renderFormatMessage(
                      'dashboard.disconnectionpage.table.heading.totalDisconnect',
                      'Total Disconnect',
                    )}
                  />
                </List.Item>
                <List.Item actions={[`${moment.duration(metric.disconnect_high, 'milliseconds').seconds()} sec`]}>
                  <List.Item.Meta
                    title={renderFormatMessage(
                      'dashboard.disconnectionpage.table.heading.disconnectHigh',
                      'Disconnect High',
                    )}
                  />
                </List.Item>
                <List.Item
                  actions={[`${moment.duration(metric.avg_disconnect_duration, 'milliseconds').seconds()} sec`]}
                >
                  <List.Item.Meta
                    title={renderFormatMessage(
                      'dashboard.disconnectionPage.table.heading.avgDisconnectDuration',
                      'Avg Disconnect Duration',
                    )}
                  />
                </List.Item>
              </>
            )}
          />
        </Panel>
      ))}
    </Collapse>
  )
}
