import { styled, theme } from '../theme'

interface props {
  centerTabs?: boolean
}

export const ModifyButtonWrapper = styled.div`
  button {
    height: 38px;
    width: 38px;
    margin-left: 8px;
  }

  svg {
    margin-bottom: 0px;
    margin-top: 7px;
    height: 20px;
  }

  svg path {
    fill: ${theme.colors.black};
    stroke: ${theme.colors.black};
  }
`

export const TabsWrapper = styled.div<props>`
  font-family: ${theme.fontFamily.regularFontFamily};

  // keep old tabs design but update color
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.lightGray};
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: ${(props) => (props.centerTabs ? 'center' : 'flex-start')};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.primary};
    background-color: transparent;
  }

  .ant-tabs-tab {
    padding: 0;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
  }

  .ant-tabs-tab-btn {
    background-color: transparent;
    font-weight: 700;
    text-shadow: none;
    font-family: ${theme.fontFamily.boldFontFamily};
    font-size: 14px;
    padding: 8px 4px;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn:hover {
    background-color: transparent;
    color: ${theme.colors.primary};
  }

  .ant-tabs-tab-active {
    border-bottom: 2px ${theme.colors.primary} solid;
    margin-bottom: -1px;
  }
`

export const InputWrapper = styled.div`
  font-family: ${theme.fontFamily.regularFontFamily};

  .ant-form-item-control-input-content > .ant-input {
    border-radius: 48px;
    height: 38px;
  }

  .ant-picker {
    height: 39px;
    border-radius: 48px;
  }

  .ant-input-affix-wrapper {
    border-radius: 48px;
    height: 38px;
  }

  textarea {
    border-radius: 9px !important;
  }

  button {
    letter-spacing: 0.05em;
    font-family: ${theme.fontFamily.regularFontFamily};
  }

  // select component CSS
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* height: 38px; */
  }

  /* .ant-select-selection-placeholder { */
  /* .listing-author-selector {
    span.ant-select-selection-placeholder {
      margin-top: 2px;
    }
    .ant-select-selection-search-input {
      cursor: pointer;
      margin-top: 10px;
    }
  } */

  /* .ant-select-selection-placeholder {
    margin-top: 3px;
  } */

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  // for select component with mode="multiple"
  .ant-select-selection-overflow {
    margin-left: 0;
  }

  // end of select component CSS

  // Input and input number CSS
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${theme.colors.primary};
  }

  // For selector CSS
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${theme.colors.disabledInput};
  }

  .ant-input-number {
    border-radius: 0 !important;
  }

  .ant-switch-checked {
    background-color: ${theme.colors.primary};
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .align-center-label {
    label {
      margin-top: 4px;
    }
  }

  .radio-button {
    height: 38px;
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    align-items: center;
  }
`

export const MultiSelectWrapper = styled.div`
  .ant-select-selection-overflow {
    margin-left: 0;
  }
`

export const InputNumberWrapper = styled.div`
  .ant-input-number {
    border-radius: 0 !important;
  }
`
