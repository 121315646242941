import { styled } from '../theme'

interface TableProps {
  userIsAdmin: boolean
}

export const NestedTableWrapper = styled.div<TableProps>`
  ${({ userIsAdmin }) =>
    userIsAdmin &&
    `
  .ant-table {
    &-thead > tr {
      th {
        &:nth-of-type(1) {
          flex: 0 !important;
        }
      }
    }
    &-tbody > tr {
      td {
        &:nth-of-type(1) {
          flex: 0 !important;
        }
      }
    }
  }
`}
`

export const TableWrapper = styled.div<TableProps>`
  ${({ userIsAdmin }) =>
    userIsAdmin &&
    `
  .ant-table-row td::-webkit-scrollbar {
    display: none;
  }
  .columns {
    display: flex;
    align-items: center;
    width: 0;
  }
  .ant-table {
    &-thead > tr {
      th {
        &:nth-of-type(1) {
          flex: 0 0 4em;
        }
      }
    }
    &-tbody > tr {
      td {
        &:nth-of-type(1) {
          flex: 0 0 4em;
        }
      }
    }
    `}
`
