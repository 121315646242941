import { Alert, Button, Form, Input, Modal, Skeleton, Tag } from 'antd'
import React, { useState } from 'react'
import { useFormatMessage } from '../../../helpers/intl'
import { SwtchError } from '../../../models/error'
import { TenantRef } from '../../../models/tenant'
import { AugmentedUser, AugmentedUserTenantPermission } from '../../../models/user'
import { AuthorizeUser } from '../../../services/data-provider/tenants'
import { FindUser } from '../../../services/data-provider/users'
import { AlertError } from '../../error'

interface props {
  close: () => void
  tenants: TenantRef[]
  onDrivesAuthorized: (drivers: AugmentedUser[]) => void
  tenantDisplayName: string | undefined
}

export const AuthorizeDrivers: React.FC<props> = ({ close, onDrivesAuthorized, tenants }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [user, setUser] = useState<AugmentedUser>()
  const [notFound, setNotFound] = useState(false)
  const [modalMessage, setModalMessage] = useState<String>('')

  const [userPermissions, setUserPermissions] = useState<AugmentedUserTenantPermission[]>([])

  const authorizeModalHeading = useFormatMessage(
    'dashboard.usersPage.authorizeModal.heading',
    'Authorize drivers to access private listings',
  )

  const authorizeModalInfo = useFormatMessage('dashboard.usersPage.authorizeModal.info.heading', 'Driver Authorization')
  const authorizeModalDescription = useFormatMessage(
    'dashboard.usersPage.authorizeModal.info.p1',
    'Authorized drivers will gain access to private listings at',
  )

  const userText = useFormatMessage('dashboard.usersPage.authorizeModal.userSearchField.userText', 'User')
  const searchUserText = useFormatMessage(
    'dashboard.usersPage.authorizeModal.userSearchField.searchUserText',
    'Search User',
  )
  const searchText = useFormatMessage('dashboard.usersPage.authorizeModal.userSearchField.searchBtn', 'Search')
  const authorizeUserText = useFormatMessage('dashboard.usersPage.authorizeModal.authorizeBtn', 'Authorize User')
  const cancelText = useFormatMessage('dashboard.usersPage.authorizeModal.cancelBtn', 'Cancel')

  const alreadyHasAccessWarning = useFormatMessage(
    'dashboard.usersPage.authorizeModal.warning',
    'User already has access to the selected tenant',
  )
  const noMatchFoundWarning = useFormatMessage(
    'dashboard.usersPage.authorizeModal.noMatchFound',
    'No match found. Please confirm the user has registered a SWTCH account with the indicated e-mail address.',
  )

  const searchUser = (value: string) => {
    setNotFound(false)

    FindUser(value, tenants)
      .then((user) => {
        setUser(user)
        const { tenantPermission } = user
        const tenantPermissions = Object.values(tenantPermission)
        setUserPermissions(tenantPermissions)
      })
      .catch((err: SwtchError) => {
        setError(err)
        setModalMessage(noMatchFoundWarning)
        setNotFound(true)
      })
  }

  const renderDisplay = () => {
    if (!user) {
      return ''
    }

    let userParts = []
    if (user.name.trim()) {
      userParts.push(user.name)
    }
    if (user.email) {
      userParts.push(user.email)
    }
    if (tenants) {
      userParts.push(user.role)
    }
    return userParts.join(' - ')
  }

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return
  }

  const renderUserSelection = () => {
    return (
      <>
        {!user && (
          <Input.Search placeholder={searchUserText} allowClear enterButton={searchText} onSearch={searchUser} />
        )}
        {user && (
          <Tag
            closable={true}
            onClose={(e) => {
              setUser(undefined)
              setNotFound(false)
            }}
          >
            {renderDisplay()}
          </Tag>
        )}
        {!user && notFound && <Alert message={modalMessage} type="warning" closable onClose={onClose} />}
      </>
    )
  }

  const onSubmit = () => {
    if (!user || !tenants) {
      return
    }

    setLoading(true)
    AuthorizeUser(
      tenants.map((t) => t.id),
      user,
    )
      .then((drivers) => {
        onDrivesAuthorized(drivers)
        close()
      })
      .catch((err: SwtchError) => {
        setError(err)
      })
      .finally(() => {
        form.resetFields()
        setLoading(false)
      })
  }

  const renderAuthorizeDriverText = () => {
    let temp = undefined
    if (tenants && tenants.length === 1) {
      temp = tenants[0].displayName || tenants[0].name
    } else if (tenants && tenants.length > 1) {
      temp = `${tenants.length} Tenants`
    }

    return `${authorizeModalDescription} ${temp}`
  }

  const renderWarningInfoList = (res: string[]) => {
    return (
      <ul>
        {res.map((r) => {
          return <li key={r}>{tenants.filter((t) => t.id === r).map((t) => t.displayName || t.name)}</li>
        })}
      </ul>
    )
  }

  const renderTenantAlreadyAuthorizedWarning = () => {
    const userTenantPermissions = userPermissions.filter((t) => t.driver === true)
    const res = tenants.map((t) => t.id).filter((item) => userTenantPermissions.map((t) => t.id).includes(item))

    return (
      <>
        {res && res.length > 0 && (
          <Alert showIcon message={alreadyHasAccessWarning} description={renderWarningInfoList(res)} type={'warning'} />
        )}
      </>
    )
  }

  return (
    <>
      <AlertError error={error} />
      <Modal
        title={authorizeModalHeading}
        visible={true}
        closable={false}
        footer={[
          <Button
            key={'authorize-btn'}
            type={'primary'}
            disabled={!user}
            loading={loading || !tenants}
            onClick={onSubmit}
          >
            {authorizeUserText}
          </Button>,
          <Button key={'cancel-btn'} type={'ghost'} onClick={close} loading={loading || !tenants}>
            {cancelText}
          </Button>,
        ]}
      >
        <Skeleton loading={!tenants}>
          <Alert
            showIcon
            message={authorizeModalInfo}
            description={renderAuthorizeDriverText()}
            type={'info'}
            style={{
              marginBottom: '10px',
            }}
          />
          {renderTenantAlreadyAuthorizedWarning()}
          <Form form={form}>
            <AlertError error={error} />
            <Form.Item label={userText} name="user">
              {renderUserSelection()}
            </Form.Item>
          </Form>
        </Skeleton>
      </Modal>
    </>
  )
}
