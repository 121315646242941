import { useEffect, useState } from 'react'

import { Connector } from '../models/connector'
import { SwtchError } from '../models/error'
import { TransactionMetricResponse } from '../models/analytics'

import { GetTransactionMetrics } from '../services/data-provider/analytics'
import { useAppState } from '../state'

export const useChargerTransMetrics = (
  chargerId: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
  connector?: Connector,
) => {
  const { selectedTenant: defaultTenant } = useAppState()

  const [metrics, setMetrics] = useState<TransactionMetricResponse>()
  const [transMetricLoading, setTransMetricLoading] = useState(false)
  const [transMetricError, setTransMetricError] = useState<SwtchError>()

  useEffect(() => {
    setTransMetricLoading(true)
    GetTransactionMetrics({
      startDate,
      endDate,
      defaultTenant,
      chargerId,
      connector,
    })
      .then((data) => setMetrics(data))
      .catch((err) => setTransMetricError(err))
      .finally(() => setTransMetricLoading(false))
  }, [startDate, endDate, connector])

  return { transMetricLoading, metrics, transMetricError }
}
