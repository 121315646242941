import { CaretDownOutlined } from '@ant-design/icons'
import { Button, DatePicker, message, Select, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { Box } from '../../atom/box'
import { Charger } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { ListingBase } from '../../models/listing'
import { GenerateDREvent, GetChargerListings } from '../../services/data-provider/charger'
import { useAppState } from '../../state'
import { AlertError } from '../error'
import { styled } from '../../theme'

const Container = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
  }
`

interface props {
  charger: Charger
}

export const ChargerTabDREventSimulator: React.FC<props> = ({ charger }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const [listings, setListings] = useState<ListingBase[]>([])
  const [startDate, setStartDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD h:mm:ss a'))
  const [endDate, setEndDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD h:mm:ss a'))

  const [datePickerStartDate, setDatePickerStartDate] = useState<moment.Moment>(moment().startOf('month'))
  const [datePickerEndDate, setDatePickerEndDate] = useState<moment.Moment>(moment().startOf('month'))

  const { selectedTenant: defaultTenant } = useAppState()

  useEffect(() => {
    setLoading(true)
    GetChargerListings(charger, defaultTenant)
      .then((resp) => setListings(resp.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [])

  const onClick = () => {
    setLoading(true)
    const startDateParts = startDate.split(' ')
    const formattedStartDate = `${startDateParts[0]}T${startDateParts[1]}`
    const endDateParts = endDate.split(' ')
    const formattedEndDate = `${endDateParts[0]}T${endDateParts[1]}`

    startDate &&
      endDate &&
      GenerateDREvent(formattedStartDate, formattedEndDate, charger, defaultTenant)
        .then((resp) => {
          if (resp && resp.status === 'failed') {
            setError(new SwtchError(resp.message))
            message.error(resp.message)
          } else {
            message.success(resp.status)
          }
        })
        .catch((err) => setError(err))
        .finally(() => setLoading(false))
  }

  const columns: any = [
    {
      title: 'Listing Title',
      dataIndex: 'title',
    },
    {
      title: 'Connector ID',
      key: 'connectorId',
      render: () => charger.connectors[0].connectorId,
    },
    {
      title: 'Price',
      key: 'price',
      render: () => '$0.44',
    },
  ]

  const DRColumns: any = [
    {
      title: 'Event Type',
      render: () => {
        return (
          <Select
            defaultValue="openadr-autogrid"
            options={[
              {
                value: 'openadr-autogrid',
                label: 'OpenADR (Autogrid)',
              },
              {
                value: 'openadr-energyhub',
                label: 'OpenADR (EnergyHub)',
              },
              {
                value: 'custom-signal-ge',
                label: 'Custom Signal (GE)',
              },
            ]}
            suffixIcon={<CaretDownOutlined style={{ color: '#22a891' }} />}
          />
        )
      },
    },
    {
      title: 'Event Start',
      render: () => {
        return (
          <DatePicker
            showTime
            onChange={(date: moment.Moment | null, dateString: string) => {
              const formattedDateString = moment(dateString, 'YYYY-MM-DD h:mm:ss A').format('YYYY-MM-DD HH:mm:ss')
              setStartDate(formattedDateString)
              date && setDatePickerStartDate(date)
            }}
            defaultValue={datePickerStartDate}
            format="YYYY-MM-DD h:mm:ss a"
            style={{ minWidth: 190 }}
            suffixIcon={null}
          />
        )
      },
    },
    {
      title: 'Event End',
      render: () => {
        return (
          <DatePicker
            showTime
            onChange={(date: moment.Moment | null, dateString: string) => {
              const formattedDateString = moment(dateString, 'YYYY-MM-DD h:mm:ss A').format('YYYY-MM-DD HH:mm:ss')
              setEndDate(formattedDateString)
              date && setDatePickerEndDate(date)
            }}
            defaultValue={datePickerEndDate}
            format="YYYY-MM-DD h:mm:ss a"
            style={{ minWidth: 190 }}
            suffixIcon={null}
          />
        )
      },
    },
    {
      title: 'Event Target',
      render: () => {
        return (
          <Select
            defaultValue="kite-leaf"
            style={{ minWidth: 155 }}
            options={[
              {
                value: 'kite-leaf',
                label: 'Kite Leaf',
              },
              {
                value: 'kite-tesla',
                label: 'Kite Tesla',
              },
            ]}
            suffixIcon={<CaretDownOutlined style={{ color: '#22a891' }} />}
          />
        )
      },
    },
    {
      title: 'Action',
      render: () => (
        <Button type="primary" onClick={onClick}>
          Generate DR Event
        </Button>
      ),
    },
  ]

  return (
    <Container>
      <Box>
        <AlertError error={error} />
      </Box>
      <Table columns={columns} dataSource={listings} rowKey="id" loading={loading} pagination={false} />
      <Table columns={DRColumns} dataSource={listings} rowKey="id" loading={loading} pagination={false} />
    </Container>
  )
}