import { UserOutlined } from '@ant-design/icons'
import { AutoComplete, Spin, message } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useEffect, useRef, useState } from 'react'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { debounce } from 'lodash'
import { searchSVG } from '../../assets/svg/search'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { SwtchError } from '../../models/error'
import { UserWallet } from '../../models/user'
import { SearchUsers } from '../../services/data-provider/users'
import { NoIconSearch } from '../redesign/no-icon-search'
interface props {
  country?: string
  onUserSelected: (user: UserWallet | undefined) => void
  onSubmit: () => void
  dropdownClassName?: string
}

const renderUser = (user: UserWallet): any => {
  return {
    value: user.id,
    user,
    label: (
      <div>
        <UserOutlined /> {user.email || user.name}
      </div>
    ),
  }
}

export const UserSelectorNoTagRedesign: React.FC<props> = ({
  onUserSelected,
  country,
  onSubmit,
  dropdownClassName,
}) => {
  const [value, setValue] = useState('')
  const [selected, setSelected] = useState<UserWallet>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [showDropDown, setShowDropDown] = useState(false)

  const { emailTxt } = useUserTranslation()
  const autoCompleteInputRef = useRef<any>(null)

  useEffect(() => {
    setShowDropDown(!selected)
    onUserSelected(selected)
  }, [selected])

  const removeDuplicateEmails = (userList: UserWallet[]) => {
    return userList.reduce((acc: UserWallet[], current) => {
      const x = acc.find((item) => item.email === current.email)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
  }

  const searchResult = (value: string) => {
    if (value) {
      setLoading(true)
      SearchUsers(value, country)
        .then((users) => {
          const opts = removeDuplicateEmails(users)
            .filter((user) => user.email) //filter out user with no email
            .map((user) => renderUser(user))
          setOptions(opts)
        })
        .catch((err: SwtchError) => {
          setOptions([])
          message.error(err.description)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setOptions([])
    }
  }

  const onSelect = (value: string) => {
    autoCompleteInputRef?.current?.blur() //Remove focus to hide dropdown when the user press button
    options?.forEach((opt) => {
      if (opt.value === value) {
        setValue(opt.user.email)
        setSelected(opt.user)
      }
    })
  }

  const resetSearchIfNotSelected = () => {
    setShowDropDown(false)
    if (!selected) {
      setValue('')
      setSelected(undefined)
    }
  }

  const showDropdownOnFocus = () => setShowDropDown(true)

  const resetSelectedOnChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value)
    setSelected(undefined)
  }

  const hideDropDownAfterSearch = () => {
    setTimeout(() => autoCompleteInputRef?.current?.blur(), 100) //To hide dropdown after click caused from focusing autocomplete component
    onSubmit()
  }

  return (
    <AutoComplete
      value={value}
      dropdownMatchSelectWidth={false}
      dropdownClassName={dropdownClassName}
      options={options}
      open={showDropDown}
      onSelect={onSelect}
      onFocus={showDropdownOnFocus}
      onBlur={resetSearchIfNotSelected}
      onSearch={debounce(searchResult, 800)}
      notFoundContent={loading ? <Spin size="small" /> : null}
    >
      <NoIconSearch
        ref={autoCompleteInputRef}
        svg={searchSVG}
        className="subscription-email-search"
        placeholder={emailTxt}
        onChange={resetSelectedOnChangeText}
        suffix={
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={loading}
            disabled={loading}
            fetchData={hideDropDownAfterSearch}
            withInSuffix
          />
        }
      />
    </AutoComplete>
  )
}
