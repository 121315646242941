import React, { useState } from 'react'

import { Form, Input, Modal, Radio, Select, Space } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useTaggingTranslation } from 'hooks/translation/useTaggingTranslation'
import { CreateTagging, UpdateTagging } from 'services/data-provider/tagging'
import { styled } from 'theme'
import { closeSVG } from '../../../assets/svg/close'
import { PeakShavingSelectArrowDown } from '../../../assets/svg/select'
import { ButtonModal } from '../../../atom/button'
import { layout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { SwtchApiError } from '../../../models/error'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit: () => void
  initialValues?: Store //This indicates Edit modal
}

const CreateTagModalContainer = styled.div`
  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.colors.lightGray};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer .ant-btn {
    min-width: 240px;
  }
`

export const CreateTagModal: React.FC<props> = ({ visible, onCancel, onSubmit, initialValues }) => {
  const [loading, setLoading] = useState(false)
  const [CreateTagForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const messageKey = initialValues ? 'tagDetailsMessageKey' : 'createNewTagMessageKey'

  const {
    createTagText,
    nameText,
    objectText,
    statusText,
    typeText,
    noteText,
    editTagText,
    activeText,
    inactiveText,
    systemText,
    adminText,
    customerText,
    listingText,
    tenantText,
    isRequiredText,
    cannotExceedText,
    createSuccessText,
    editSuccessText,
    cancelText,
    submitText,
  } = useTaggingTranslation()

  const handleFormSubmit = () => {
    CreateTagForm.validateFields().then((values) => {
      setLoading(true)
      const body = {
        ...values,
        id: initialValues?.id,
      }
      return (initialValues ? UpdateTagging(body) : CreateTagging(body))
        .then((resp) => {
          const responseMessage = initialValues ? editSuccessText : createSuccessText.replace('-1', resp.tagName)
          CustomSuccessMessage({ key: messageKey, response: responseMessage })
          onSubmit()
          CreateTagForm.resetFields()
        })
        .catch((err: SwtchApiError) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  return (
    <CreateTagModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{initialValues ? editTagText : createTagText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} />
            <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={submitText} />
          </Space>
        }
        onCancel={onCancel}
        width={540}
        className={`createNewProgramModal ${error ? 'ant-modal-error' : ''}`}
        closeIcon={closeSVG}
      >
        <AlertError error={error} />
        <Form
          {...layout}
          form={CreateTagForm}
          style={{ marginTop: '2rem' }}
          initialValues={initialValues ?? { status: 'active' }}
          layout="vertical"
        >
          <Form.Item
            label={<p className="paragraph-02-regular">{nameText}</p>}
            name="tagName"
            rules={[{ required: true, message: isRequiredText.replace('-1', nameText) }]}
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label={<p className="paragraph-02-regular">{statusText}</p>}
            rules={[{ required: true }]}
            {...formItemLayout}
          >
            <Radio.Group>
              <Space wrap>
                <Radio value={'active'}>{activeText}</Radio>
                <Radio value={'inactive'}>{inactiveText}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{objectText}</p>}
            name="tableName"
            rules={[{ required: true, message: isRequiredText.replace('-1', objectText) }]}
            {...formItemLayout}
          >
            <Select
              options={[
                { value: 'Listing', label: listingText },
                { value: 'Tenant', label: tenantText },
              ]}
              suffixIcon={<PeakShavingSelectArrowDown />}
            />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{typeText}</p>}
            name="tagType"
            rules={[{ required: true, message: isRequiredText.replace('-1', typeText) }]}
            {...formItemLayout}
          >
            <Select
              options={[
                { value: 'system', label: systemText },
                { value: 'admin', label: adminText },
                { value: 'customer', label: customerText },
              ]}
              suffixIcon={<PeakShavingSelectArrowDown />}
            />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{noteText}</p>}
            name="note"
            rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', noteText) }]}
            {...formItemLayout}
          >
            <Input.TextArea rows={5} placeholder={noteText} />
          </Form.Item>
        </Form>
      </Modal>
    </CreateTagModalContainer>
  )
}
