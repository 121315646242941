import { useFormatMessage } from '../../helpers/intl'

export const useListingAuthorTranslation = () => {
  const listingAuthorText = useFormatMessage(
    'dashboard.createNewListing.listingStep.listingAuthor.heading',
    'Listing Author',
  )
  const authorText = useFormatMessage('dashboard.createNewListing.reviewStep.listing.author', 'Author')
  const refreshAuthorStripeText = useFormatMessage(
    'dashboard.createNewListing.refreshAuthorsText',
    'Refresh Authors and Stripe Accounts',
  )
  const listingAuthorRequiredText = useFormatMessage(
    'dashboard.createNewListing.listingStep.listingAuthor.warning',
    'Listing Author is required',
  )
  const selectListingAuthorText = useFormatMessage(
    'dashboard.createNewListing.listingStep.listingAuthor.selectTxt',
    'Select Author',
  )
  const stripeAccountRequiredText = useFormatMessage(
    'dashboard.createNewListing.listingStep.stripeAccount.heading',
    'Stripe Account is required',
  )
  const selectStripeAccountText = useFormatMessage(
    'dashboard.createNewListing.listingStep.stripeAccount.selectTxt',
    'Select Stripe Account',
  )
  return {
    listingAuthorText,
    authorText,
    refreshAuthorStripeText,
    listingAuthorRequiredText,
    selectListingAuthorText,
    stripeAccountRequiredText,
    selectStripeAccountText
  }
}
