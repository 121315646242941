import { Button, Col, Row } from 'antd'
import { useDashboardTranslation } from '../../../hooks/translation/useDashboardTranslation'
import { SubSection } from '../../../atom/box'
import {
  HomePageStationActivationContainer,
  HomePageTransactionOverviewTableContainer,
} from '../ContainerStyles/homepageStyles'
import { EmptyData } from '../../../components/redesign/emptyData'
import { StationActivation, Transactionv2 } from '../../../models/transaction'
import { Link } from 'react-router-dom'
import { Links } from '../../../components/routes/paths'
import { HomePageStationActivation } from '../charts/active-users/station-activation'
import { HomePageTransactionOverview } from '../charts/active-users/transaction-overview'
import { useMediaQuery } from 'react-responsive'
import { useAppState } from 'state'
import configProvider from 'config'

interface props {
  stationActivationDataEmpty: boolean
  stationActivation: StationActivation | undefined
  transactions: Transactionv2[] | undefined
}

const StationActivationSection: React.FC<props> = ({ stationActivationDataEmpty, stationActivation, transactions }) => {
  const { IsMobile } = useAppState()
  const { stationActivationText, transactionOverviewText, seeAllTransactionsText } = useDashboardTranslation()
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 1144 })
  const makeColumn = useMediaQuery({ minWidth: 0, maxWidth: 1144 })
  const isMobile = IsMobile()

  const defaultColSizes = {
    sm: 24,
    md: 24,
    lg: makeColumn ? 24 : 17,
    xl: 17,
    xxl: 18,
  }

  const customColSize = {
    itsElectric: {
      span: 24,
    },
  }

  return (
    <SubSection end>
      <Row gutter={[15, 15]} style={{ marginBottom: '15px' }}>
        {configProvider.config.network !== 'itsElectric' && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={makeColumn ? 24 : 7}
            xl={7}
            xxl={6}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <>
              <h5 className="section-sub-header" style={{ marginBottom: '25px', paddingTop: '5px' }}>
                {stationActivationText}
              </h5>
            </>
            <HomePageStationActivationContainer
              className={`${stationActivationDataEmpty ? 'empty-container' : ''}`}
              isResponsiveStyling={isResponsiveStyling}
            >
              {stationActivationDataEmpty ? (
                <EmptyData />
              ) : (
                <>{stationActivation && <HomePageStationActivation stationActivation={stationActivation} />}</>
              )}
            </HomePageStationActivationContainer>
          </Col>
        )}
        <Col
          {...(configProvider.config.network in customColSize
            ? customColSize[configProvider.config.network as keyof typeof customColSize]
            : defaultColSizes)}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
            <h5 className="section-sub-header" style={{ marginBottom: 0, paddingTop: '5px' }}>
              {transactionOverviewText}
            </h5>
            <Button type="ghost" className="trnx-btn" style={{ marginBottom: '15px' }}>
              <Link
                to={Links.transactions()}
                onClick={() => {
                  document.querySelector('body')?.classList.remove('redesignActive')
                }}
              >
                <span className="heading-07-light" style={{ letterSpacing: '0.8px' }}>
                  {seeAllTransactionsText}
                </span>
              </Link>
            </Button>
          </div>
          <HomePageTransactionOverviewTableContainer>
            {transactions && <HomePageTransactionOverview transactions={transactions} />}
          </HomePageTransactionOverviewTableContainer>
        </Col>
      </Row>
    </SubSection>
  )
}

export default StationActivationSection
