import { useFormatMessage } from '../../helpers/intl'

export const useHomeTranslation = () => {
  const transactionsAfter6PM = useFormatMessage(
    'dashboard.homePage.transactions.info.p1',
    'of transactions were started after 6 PM',
  )
  const transactionsOnWeekdays = useFormatMessage(
    'dashboard.homePage.transactions.info.p2',
    'of transactions were started on weekday',
  )
  const fromDemandResponse = useFormatMessage('dashboard.homePage.demandResponseText', 'from demand response')
  const fromCharging = useFormatMessage('dashboard.homePage.fromChargingText', 'from charging')
  const fromLoitering = useFormatMessage('dashboard.homePage.loiteringText', 'from loitering')
  const chargingPeakHours = useFormatMessage('dashboard.homePage.energy.info.p1', 'of charging occured during peak hours')
  const energyTxt = useFormatMessage('dashboard.homePage.energy', 'Energy')
  const GHGSavingsTxt = useFormatMessage('dashboard.homePage.ghgsavings.text', 'GHG Savings')
  return {
    transactionsAfter6PM,
    transactionsOnWeekdays,
    fromDemandResponse,
    fromCharging,
    fromLoitering,
    chargingPeakHours,
    energyTxt,
    GHGSavingsTxt
  }
}
