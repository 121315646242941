export const percentage = (value: number, total: number) => {
  return Math.floor((value / total) * 100)
}

export const getMode = (arr: number[]) => {
  const frequencyMap: Record<string, number> = {}
  let greatestFreq = 0
  let mode
  arr.forEach((item) => {
    frequencyMap[item] = (frequencyMap[item] || 0) + 1
    if (frequencyMap[item] > greatestFreq) {
      greatestFreq = frequencyMap[item]
      mode = item
    }
  })
  return mode
}
