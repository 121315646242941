import { useState } from 'react'
import { PricingSchema } from '../../../models/price'
import { useAppState } from '../../../state'
import { formatCentsPrice } from '../../../helpers/number-format'
import TimeOfDayModal from '../../../pages/listing/modal/TimeOfDayModal'
import { theme } from '../../../theme'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'

interface props {
  price: PricingSchema
  term?: string
}

const formatHour = (priceType: string) => (priceType === 'Hour' ? 'hr' : 'kWh')

const HandlePricingSchemaPrice: React.FC<props> = ({ price, term }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const { curtailmentAdjustmentEnabledText } = usePricingSchemasTranslation()
  const [selectedListing, setSelectedListing] = useState<PricingSchema | undefined>()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const showTimeOfDayModal = (price: PricingSchema) => setSelectedListing(price)

  const handleModal = () => setSelectedListing(undefined)

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const justPrice = (price: PricingSchema, pricePerUnitCents: number) => {
    const { priceType } = price

    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>{`${formatCentsPrice(pricePerUnitCents)}/${formatHour(priceType)}`}</div>
      </div>
    )
  }

  const handlePrice = (price: PricingSchema, term?: string) => {
    return parseGeneralPrice(price)
  }

  const freelisting = () => (
    <div className={isLargeScreen}>
      <div className={isSmallScreen}>Free</div>
    </div>
  )

  const flatFeeOnly = (flatFee: number | string) => {
    return (
      <div className={isLargeScreen}>
        <div className={`${isSmallScreen}`}>{formatCentsPrice(Number(flatFee))} Fee</div>
      </div>
    )
  }

  const flatFeeAndPrice = (pricePerUnitCents: number, priceType: string, flatFee: number) => {
    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>
          {formatCentsPrice(pricePerUnitCents)}/{formatHour(priceType)} + {formatCentsPrice(flatFee)} Fee
        </div>
      </div>
    )
  }

  const renderTimeOfDay = (price: PricingSchema, flatFee: number) => (
    <div className={isLargeScreen}>
      <span
        onClick={() => showTimeOfDayModal(price)}
        className={`${isSmallScreen} pointer underline`}
        style={{ color: theme.colors.primary }}
      >
        {flatFee > 0 ? `Time of Day + ${formatCentsPrice(Number(flatFee))} Fee` : 'Time of Day'}
      </span>
    </div>
  )

  const parseGeneralPrice = (price: PricingSchema) => {
    const { flatFeeCents, priceType } = price
    const numberFlatFee = Number(flatFeeCents)

    let enableTimeOfDayPricing: boolean =
      !price.timeOfDayPriceWeekday.every((pricing) => pricing === price.timeOfDayPriceWeekday[0]) ||
      !price.timeOfDayPriceWeekend.every((pricing) => pricing === price.timeOfDayPriceWeekend[0])

    const prices: any = {}
    price.timeOfDayPriceWeekday.forEach((num) => (prices[num] ? (prices[num] += 1) : (prices[num] = 1)))
    const pricePerUnitCents = Number(Object.keys(prices).sort((a, b) => prices[b] - prices[a])[0])

    if (enableTimeOfDayPricing) return renderTimeOfDay(price, numberFlatFee)

    if (numberFlatFee === 0 && pricePerUnitCents === 0) return freelisting()

    if (numberFlatFee === 0 && pricePerUnitCents > 0) return justPrice(price, pricePerUnitCents)

    if (numberFlatFee > 0 && pricePerUnitCents === 0) return flatFeeOnly(numberFlatFee)

    if (numberFlatFee > 0 && pricePerUnitCents > 0)
      return flatFeeAndPrice(pricePerUnitCents, priceType ? priceType : 'null', numberFlatFee)
  }

  return (
    <>
      {handlePrice(price, term)}
      {price.enablePricingCurtailment && (
        <div className="paragraph-04-light" style={{ marginTop: '5px' }}>
          {curtailmentAdjustmentEnabledText}
        </div>
      )}
      {selectedListing && (
        <TimeOfDayModal
          title={price.name}
          priceType={formatHour(price.priceType)}
          isModalOpen={!!selectedListing}
          flatFee={Number(price.flatFeeCents) / 100}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={price?.timeOfDayPriceWeekend}
          timeOfDayPriceWeekday={price?.timeOfDayPriceWeekday}
        />
      )}
    </>
  )
}

export default HandlePricingSchemaPrice
