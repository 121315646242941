import { Moment } from 'moment'
import { useAppState } from 'state'
import { Button, Cascader, Checkbox, Col, Row, Select, Space, message } from 'antd'

import { ReusableSelector } from 'components/reusableSelector/selector'
import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { LocationSelectorContainer } from 'components/redesign/location-selector'

import { useSelector } from 'hooks/useSelector'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'

import { UserRef } from 'models/user'
import { SystemArea } from 'models/activity-tracking'
import { ActivityTrackingFilter } from 'models/filter'

import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import { magnifyBlackSVG } from 'assets/svg/magnify'
import { activityTrackingCategories } from 'helpers/tenant/activity-tracking-option'
import { ListingRef } from 'models/listing'
import { ReactElement, useEffect, useState } from 'react'
import { FindListings } from 'services/data-provider/listing'
import { SwtchError } from 'models/error'
import { TenantRef } from 'models/tenant'

interface props {
  loading: boolean
  startDate: Moment
  endDate: Moment
  tenant: TenantRef
  filter: ActivityTrackingFilter
  dropdownClassName?: string
  onChangeDate: (dates: Moment[]) => void
  onFilter: (filters: ActivityTrackingFilter) => void
  onFetch: () => void
}

const ActivityTrackingSelectors: React.FC<props> = ({
  loading,
  startDate,
  endDate,
  filter,
  dropdownClassName,
  tenant,
  onChangeDate,
  onFilter,
  onFetch,
}) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const [listings, setListings] = useState<ListingRef[]>([])

  useEffect(() => {
    FindListings(undefined, tenant)
      .then((listings) => setListings(listings))
      .catch((err: SwtchError) => {
        setListings([])
        message.error(err.description)
      })
  }, [])

  const { searchText } = useGeneralTranslation()
  const { listingTitlesText } = useListingBasicTrans()
  const { calendarRanges } = useCalendarTransactions()
  const {
    multiUsersDebounceFetcher,
    multiListingTitleAndIdDebounceFetcher,
    multiUsersHandleChange,
    multiUsersHandlePlaceholder,
    multiUsersHandleValue,
    renderMultiUsersOption,
    multiListingTitleHandlePlaceholder,
    multiListingTitleAndIdHandleChange,

    multiListingTitleAndIdHandleValue,
  } = useSelector()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const calendar = (
    <CustomDateRangePicker
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      calendarRanges={calendarRanges}
      disabled={loading}
    />
  )

  const renderMultiListingIdOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
      >
        {option.title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingSelector = (
    <ReusableSelector
      showSearch
      showArrow
      onOptionsChange={(listingOption: ListingRef[]) => {
        const uniqueListings = listingOption.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id && t.title === item.title && t.timeZone === item.timeZone),
        )

        onFilter({ ...filter, listings: uniqueListings })
      }}
      onClear={() => onFilter({ ...filter, listings: [] })}
      combineType="right"
      customWidth="200px"
      isSingle={false}
      loading={loading}
      placeholder={listingTitlesText}
      dropDownList={listings}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiListingTitleAndIdDebounceFetcher}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleAndIdHandleChange}
      renderOption={renderMultiListingIdOption}
      handleValue={multiListingTitleAndIdHandleValue}
      defaultTenants={[tenant]}
    />
  )

  const renderMultiUserSelector = (
    <ReusableSelector
      showSearch
      dropdownClassName={dropdownClassName}
      onOptionsChange={(users: UserRef[]) => onFilter({ ...filter, users })}
      onClear={() => onFilter({ ...filter, users: [] })}
      combineType="both"
      isSingle={false}
      placeholder="Modified By"
      dropDownList={filter.users}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      filter={filter}
      disabled={loading}
      handlePlaceholder={multiUsersHandlePlaceholder}
      handleOptionChange={multiUsersHandleChange}
      debounceFetcher={multiUsersDebounceFetcher}
      handleValue={multiUsersHandleValue}
      renderOption={renderMultiUsersOption}
    />
  )

  const handleChangeCascader = (terms: any) => {
    const systemAreas: SystemArea[] = []
    const termMappings: Record<string, string[]> = {
      tenant: [],
      listing: [],
      pricing_schema: [],
    }

    terms.forEach((term: [string, string]) => {
      const [key, value] = term
      // Get the correct array based on the key
      const targetArray = termMappings[key]
      if (targetArray) {
        targetArray.push(value)
      }
    })

    // Check if any termMappings value array has exactly one value and update systemAreas
    Object.keys(termMappings).forEach((key) => {
      if (termMappings[key].length >= 1 || (termMappings[key][0] === key && termMappings[key].length === 0)) {
        systemAreas.push(key as SystemArea)
      }
    })

    const { tenant, listing, pricing_schema } = termMappings

    onFilter({
      ...filter,
      systemAreas,
      term: {
        tenantTerms: tenant,
        listingTerms: listing,
        pricingSchemaTerms: pricing_schema,
      },
    })
  }

  const renderPreviousAndCurrentValueSelector = (
    <LocationSelectorContainer removeSVG isCombineLeft>
      <Cascader
        multiple
        allowClear
        showSearch
        maxTagCount={0}
        disabled={loading}
        expandTrigger="hover"
        placeholder="System Area (s)"
        options={activityTrackingCategories}
        maxTagPlaceholder={(selectedOptions) => `${selectedOptions.length} Value Selected`}
        onChange={handleChangeCascader}
        style={{ width: 195, minWidth: '100%', lineHeight: '34px' }}
      />
    </LocationSelectorContainer>
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]}>
          {calendar}
          <div style={{ marginRight: '1rem' }}></div>
          {renderMultiListingSelector}
          <LineBreak />
          {renderMultiUserSelector}
          <LineBreak />
          {renderPreviousAndCurrentValueSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderMultiListingSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiUserSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderPreviousAndCurrentValueSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={loading} loading={loading} onClick={onFetch}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ActivityTrackingSelectors
