import { PlanResponse } from '../models/http'
import { Plan } from '../models/plan'

export const mockPlans = [
  {
    id: 1,
    name: 'plan #1',
    priceCents: 1000,
    priceCurrency: 'CAD',
    interval: 'month',
    intervalCount: 1,
    status: 'active',
    description: 'This is a sample of the plan',
    createdAt: '2021-03-01T23:59:59.000',
    updatedAt: '2021-01-01T00:00:00.000',
    currency: 'CAD',
    subscriptionsCount: 1,
    country: 'US',
  },
  {
    id: 2,
    name: 'plan #2',
    priceCents: 1200,
    priceCurrency: 'CAD',
    interval: 'month',
    intervalCount: 1,
    status: 'active',
    description: 'This is a second of the plan',
    createdAt: '2021-03-01T23:59:59.000',
    updatedAt: '2021-04-01T00:00:00.000',
    currency: 'CAD',
    subscriptionsCount: 2,
    country: 'CA',
  },
  {
    id: 3,
    name: 'plan #3',
    priceCents: 2300,
    priceCurrency: 'CAD',
    interval: 'month',
    intervalCount: 3,
    status: 'active',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra interdum purus.',
    createdAt: '2021-03-01T23:59:59.000',
    updatedAt: '2021-01-01T00:00:00.000',
    currency: 'USD',
    subscriptionsCount: 0,
    country: 'CA',
  },
  {
    id: 4,
    name: 'plan #4',
    priceCents: 1200,
    priceCurrency: 'CAD',
    interval: 'month',
    intervalCount: 4,
    status: 'active',
    description: 'This is the fourth plan',
    createdAt: '2021-03-01T23:59:59.000',
    updatedAt: '2021-01-01T00:00:00.000',
    currency: 'USD',
    subscriptionsCount: 4,
    country: 'CA',
  },
  {
    id: 5,
    name: 'plan #5',
    priceCents: 750,
    priceCurrency: 'CAD',
    interval: 'quarter',
    intervalCount: 5,
    status: 'inactive',
    description: 'This is the fifth plan',
    createdAt: '2021-03-01T23:59:59.000',
    updatedAt: '2021-01-01T00:00:00.000',
    currency: 'USD',
    subscriptionsCount: 3,
    country: 'CA',
  },
] as Plan[]

export const mockPlansResp = (page: number) => {
  return {
    data: mockPlans,
    pagination: {
      currentPage: page || 0,
      perPage: 6,
      totalEntries: mockPlans.length,
    },
  } as PlanResponse
}

export const mockPlanById: { [planId: number]: Plan } = {
  1: {
    ...mockPlans[0],
  },
  2: {
    ...mockPlans[1],
  },
  3: {
    ...mockPlans[2],
  },
  4: {
    ...mockPlans[3],
  },
  5: {
    ...mockPlans[4],
  },
}
