import { Col, Row } from 'antd'

import ActiveUsersCard from './active-users'
import ChargersPluggedInCard from './connectors-plugged-in'
import ChargersEnrolledCard from './chargers-enrolled'
import ChargersAvailabilityCard from './connector-availability'
import { ChargerInfo, ConnectorInfo } from '../../../../models/chargerv2'

interface StationOverviewCardGroupProps {
  wsLoading: boolean
  chargerInfo: ChargerInfo | undefined
  connectorInfo: ConnectorInfo | undefined
}

const CardComponents = [ChargersAvailabilityCard, ChargersPluggedInCard, ActiveUsersCard, ChargersEnrolledCard]

const StationOverviewCardGroup = ({ wsLoading, chargerInfo, connectorInfo }: StationOverviewCardGroupProps) => {
  return (
    <Row gutter={[24, 24]} style={{ height: '100%' }}>
      {CardComponents.map((Card, index) => (
        <Col xs={24} sm={12} lg={12} xl={12} xxl={12} key={index}>
          <Card wsLoading={wsLoading} chargerInfo={chargerInfo} connectorInfo={connectorInfo} />
        </Col>
      ))}
    </Row>
  )
}

export default StationOverviewCardGroup
