import configProvider from '../../config'
import { DiscountResponse } from '../../models/http'
import { log } from '../../logger'
import { apiClient } from './client'
import { DiscountSchema, DiscountSchemaPayload } from 'models/discount'
import { LocalDiscount, UsersDiscount } from 'models/price'
import { addQueryString } from 'helpers/url'

export async function ActiveDiscountForUser(email: string): Promise<DiscountSchema[]> {
  let url = '/users/search_for_active_discounts'
  let queryParams: { [key: string]: string } = {
    email,
  }
  url = addQueryString(url, queryParams)

  log('Getting discount for user', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {})
}

export async function AddUsersToDiscounts(
  localDiscounts: LocalDiscount[],
  users: UsersDiscount[],
): Promise<DiscountResponse> {
  const url = `/users/apply_discounts`

  const body = {
    local_discounts: localDiscounts,
    users,
  }
  log('Creating pricing schema', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export async function RemoveUSersFromDiscounts(
  localDiscounts: LocalDiscount[],
  users: UsersDiscount[],
): Promise<DiscountResponse> {
  const url = `/users/remove_discounts`

  const body = {
    local_discounts: localDiscounts,
    users,
  }
  log('Creating pricing schema', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify(body),
  })
}

export async function GetDiscounts(tenantId: number): Promise<DiscountSchema[]> {
  const url = `/tenants/${tenantId}/local_discounts?all_local_discounts=true`

  log('getting discount schemas', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchemaResp(page))
  }
  return apiClient(url, {})
}

export async function GetDiscountNames(tenantId: number): Promise<string[]> {
  const url = `/tenants/${tenantId}/local_discounts/names`

  log('getting discount schema names', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchemaResp(page))
  }
  return apiClient(url, {})
}

export async function CreateDiscountSchema(
  discountSchema: DiscountSchemaPayload,
  tenantId: number,
): Promise<DiscountSchema> {
  const url = `/tenants/${tenantId}/local_discounts`

  log('Creating pricing schema', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      local_discount: { ...discountSchema },
    }),
  })
}

export async function UpdateDiscountSchema(
  discountSchema: DiscountSchemaPayload,
  tenantId: number,
  discountSchemaId: number,
): Promise<DiscountResponse> {
  const url = `/tenants/${tenantId}/local_discounts/${discountSchemaId}`

  log('Updating discount schema', { url: url })

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      local_discount: { ...discountSchema },
    }),
  })
}

export async function ArchiveDiscountSchema(tenantId: number, discountSchemaId: number): Promise<DiscountResponse> {
  const url = `/tenants/${tenantId}/local_discounts/${discountSchemaId}`

  log('Delete discount schema', { url: url })

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function ApplyDiscountsToListings(
  listing: { ids: number[] },
  discountId: number,
): Promise<DiscountResponse> {
  const url = `/listings/local_discounts/${discountId}/update`

  log('update listings discount schema', { url: url })
  if (configProvider.config.mockEnabled) {
    // return returnMock(mockPricingSchema[0])
  }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      listing: listing,
      local_discount_id: discountId,
    }),
  })
}
