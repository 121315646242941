import { useState } from 'react'
import pluralize from 'pluralize'
import { Col, Row, Statistic } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation, faExchangeAlt, faMoneyBill, faSmog } from '@fortawesome/free-solid-svg-icons'
import { TransactionMetricResponse } from '../../models/analytics'
import { Widget } from '../../components/widget'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { GhgInfoModal } from './ghg-info-modal'
import { theme } from '../../theme'
import { calculateGHGSavings } from '../../models/transaction'
import { useFormatMessage } from '../../helpers/intl'

interface props {
  trxMetrics: TransactionMetricResponse | undefined
  loading: boolean
}

export const SummaryDetails: React.FC<props> = ({ trxMetrics, loading }) => {
  const [showGHGModal, setShowGHGModal] = useState(false)

  return (
    <>
      <Row gutter={[8, 8]} style={{ marginTop: '8px' }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Widget
            title={useFormatMessage('dashboard.homePage.revenue', 'Revenue ($)')}
            loading={loading}
            header={
              <Statistic
                value={trxMetrics?.totalRevenue?.toFixed(2) || 0}
                precision={2}
                prefix={<FontAwesomeIcon color={theme.colors.primary} icon={faMoneyBill} />}
              />
            }
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Widget
            title={pluralize(
              useFormatMessage('dashboard.homePage.transaction', 'Transaction'),
              trxMetrics?.totalCount || 0,
            )}
            loading={loading}
            header={
              <Statistic
                value={trxMetrics?.totalCount || 0}
                prefix={<FontAwesomeIcon color={theme.colors.primary} icon={faExchangeAlt} />}
              />
            }
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Widget
            title={useFormatMessage('dashboard.homePage.energyConsumptions', 'Energy Consumption (kWh)')}
            loading={loading}
            header={
              <Statistic
                value={trxMetrics?.totalEnergy || 0}
                precision={3}
                prefix={<FontAwesomeIcon color={theme.colors.primary} icon={faChargingStation} />}
              />
            }
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Widget
            title={
              <>
                {useFormatMessage('dashboard.homePage.ghgsavings', 'GHG Savings (kg C02 eq)')}
                <QuestionCircleOutlined
                  onClick={() => setShowGHGModal(true)}
                  style={{ fontSize: '20px', float: 'right' }}
                />
              </>
            }
            loading={loading}
            header={
              <Statistic
                value={(trxMetrics && calculateGHGSavings(trxMetrics.totalEnergy)) || 0}
                precision={3}
                prefix={<FontAwesomeIcon color={theme.colors.primary} icon={faSmog} />}
              />
            }
          />
          <GhgInfoModal visible={showGHGModal} onClose={() => setShowGHGModal(false)} />
        </Col>
      </Row>
    </>
  )
}
