import { List } from 'antd'

import { calendarSVG } from '../../../assets/svg/calendar'
import { SpaceBetween } from '../../../atom/group'

interface TimeOfDayPricing {
  timeslot: string
  price: number
}

interface TimeOfDay {
  [key: string]: TimeOfDayPricing[]
}

const renderTitle = (week: string) => (
  <SpaceBetween>
    {calendarSVG} <span className="heading-05-regular">{week}</span>
  </SpaceBetween>
)

export const columns = [
  {
    title: renderTitle('Weekdays'),
    key: 'weekday',
    render: (data: TimeOfDay) => renderTimeslot(data, 'weekdays'),
  },
  {
    title: renderTitle('Weekends'),
    key: 'weekend',
    render: (data: TimeOfDay) => renderTimeslot(data, 'weekends'),
  },
]

const renderTimeslot = (actualData: TimeOfDay, timeOfWeek: string) => {
  const filterData = timeOfWeek === 'weekdays' ? actualData.weekdays : actualData.weekends

  return (
    <List
      dataSource={filterData}
      renderItem={(item) => (
        <>
          {[item].map((i, index) => (
            <List.Item key={`${i.timeslot}-${index}`} actions={[i.price]}>
              <List.Item.Meta title={i.timeslot} />
            </List.Item>
          ))}
        </>
      )}
    />
  )
}
