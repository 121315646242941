import { Button, Col, Divider, Form, InputNumber, Select, Space, Switch } from 'antd'
import { InfoTextWrapper } from '../../../../atom/price'
import { TimeContainer } from '../../../../pages/peakShaving/modal/create-new-event-modal'
import { EditButtonWrapper } from '../../../../atom/tenants'
import { plusSVG } from '../../../../assets/svg/plus'
import { infoWithISVG } from '../../../../assets/svg/info'
import { PricingSchemaEventSchedule, PricingSchemaPayload } from '../../../../models/price'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { usePricingSchemasTranslation } from '../../../../hooks/translation/usePricingSchemaTranslation'
import { FormInstance } from 'antd/es/form/Form'
import { DarkGrayBackground } from 'atom/discount'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { DiscountFormValues } from 'models/discount'
import CoreBadge from 'components/badge/Badge'
import { useState } from 'react'

interface props {
  index: number
  form: FormInstance
  eventSchedules: PricingSchemaEventSchedule[]
  timeOfDay: boolean | undefined
  pricingSchemaValues: PricingSchemaPayload | undefined
  initialValues?: DiscountFormValues
  basePricingPlanRate: number
  modalType: string
  weekdayRates: string[]
  weekendRates: string[]
  setTimeOfDay: (enableTimeOfDayPricing: boolean) => void
  handleSelect: (
    listName: string,
    form: FormInstance,
    index: number,
    type: string,
    pricingSchema: PricingSchemaPayload | undefined,
    modalType: string,
    key: number,
  ) => void
  convertRate: (pricingSchemaValues: PricingSchemaPayload, weekday: boolean, loitering: boolean) => string[]
  validateInputChange: (
    form: FormInstance,
    index: number,
    value: number,
    key: number,
    typeOfDay: string,
    loitering: boolean,
  ) => void
  calculateDollarRateLimit: (
    pricingSchema: PricingSchemaPayload | undefined,
    values: any,
    type: string,
    modalType: string,
  ) => boolean
}

export const DiscountChargingSectionModal: React.FC<props> = ({
  index,
  form,
  timeOfDay,
  eventSchedules,
  pricingSchemaValues,
  initialValues,
  basePricingPlanRate,
  modalType,
  weekdayRates,
  weekendRates,
  setTimeOfDay,
  handleSelect,
  convertRate,
  validateInputChange,
  calculateDollarRateLimit,
}) => {
  const { chargingText, weekendText, weekdayText } = useDashboardTranslation()
  const {
    hoursNotDefinedText,
    hourText,
    toText,
    rateText,
    timeOfDayText,
    weekdayRateText,
    weekendRateText,
  } = usePricingSchemasTranslation()
  const { basePlanText, discountPlanInfoText } = useDiscountSchemaTranslation()

  const [dollarRateOverPricingDollar, setDollarRateOverPricingDollar] = useState(false)

  const getValidationStatus = () => {
    const { discount_schema_form } = form.getFieldsValue(true)
    setDollarRateOverPricingDollar(
      discount_schema_form[index].pricing_schema_dollar_rate < discount_schema_form[index].dollar_rate,
    )
  }

  const priceTypeSelector = (
    <Form.Item name={[index, 'price_type']} className="price-type">
      <Select
        className="combine-right"
        placeholder="Status"
        dropdownClassName="dropdown-large-font"
        disabled={!!pricingSchemaValues}
      >
        <Select.Option value={'Hour'}>{hourText}</Select.Option>
        <Select.Option value={'kWh'}>kWh</Select.Option>
      </Select>
    </Form.Item>
  )

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{chargingText}</span>
      </Divider>
      <DarkGrayBackground>
        <Col span={10}>
          <Form.Item label={<>{basePlanText}:</>} style={{ marginBottom: '20px' }}>
            <CoreBadge
              title={`$${
                modalType === 'edit'
                  ? form.getFieldsValue(true).discount_schema_form[index].pricing_schema_dollar_rate
                  : pricingSchemaValues?.dollar_rate
              }/${pricingSchemaValues?.price_type === 'Hour' ? 'hr' : pricingSchemaValues?.price_type}`}
            />
          </Form.Item>
        </Col>
        {(weekdayRates.length > 0 || weekendRates.length > 0) && (
          <Col span={10}>
            <Form.Item label={<>{timeOfDayText}:</>} style={{ alignItems: 'flex-start' }}>
              {weekdayRates.length > 0 && (
                <div
                  className="paragraph-01-regular"
                  style={{
                    display: 'flex',
                    marginTop: '4px',
                    marginBottom: weekendRates.length === 0 ? '0px' : '8px',
                  }}
                >
                  <div style={{ marginRight: '16px' }}>{weekdayText}: </div>
                  {weekdayRates.map((rate) => (
                    <CoreBadge title={rate} />
                  ))}
                </div>
              )}
              {weekendRates.length > 0 && (
                <div style={{ display: 'flex' }}>
                  <div
                    className="paragraph-01-regular"
                    style={{ marginRight: '16px', marginTop: weekdayRates.length === 0 ? '4px' : '0px' }}
                  >
                    {weekendText}:{' '}
                  </div>
                  {weekendRates.map((rate) => (
                    <CoreBadge title={rate} />
                  ))}
                </div>
              )}
            </Form.Item>
          </Col>
        )}
      </DarkGrayBackground>
      <Form.Item
        className="rate-title"
        label={`${rateText}:`}
        name={[index, 'dollar_rate']}
        style={{ marginBottom: '20px' }}
        validateStatus={dollarRateOverPricingDollar ? 'error' : ''}
        help={dollarRateOverPricingDollar ? discountPlanInfoText : null}
      >
        <InputNumber
          addonBefore={priceTypeSelector}
          precision={2}
          controls={false}
          onChange={getValidationStatus}
          className={`combine-left ${pricingSchemaValues?.price_type === 'Hour' ? 'disabled' : ''}`}
        />
      </Form.Item>
      <div style={{ display: 'flex' }}>
        <Form.Item label={`${timeOfDayText}:`} name={[index, 'enable_time_of_day']} style={{ marginBottom: 0 }}>
          <Switch checked={timeOfDay} onChange={(value) => setTimeOfDay(value)} />
        </Form.Item>
        {timeOfDay && (
          <InfoTextWrapper>
            {infoWithISVG} {hoursNotDefinedText}
          </InfoTextWrapper>
        )}
      </div>
      {timeOfDay && (
        <>
          <Form.List name={[index, 'weekdayRateExtras']} initialValue={initialValues?.discount_schema_form}>
            {(fields, { add }) => (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginTop: key === 0 ? '20px' : '0px' }}>
                      <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                        <Form.Item
                          name={[key, 'dollar_rate']}
                          label={key === 0 ? <>{weekdayRateText}:</> : ''}
                          style={{ marginLeft: key === 0 ? '' : '131px' }}
                          help={
                            calculateDollarRateLimit(
                              pricingSchemaValues,
                              form.getFieldValue(['discount_schema_form', index, 'weekdayRateExtras', key]),
                              'timeOfDayWeekday',
                              modalType,
                            )
                              ? discountPlanInfoText
                              : null
                          }
                          validateStatus={
                            calculateDollarRateLimit(
                              pricingSchemaValues,
                              form.getFieldValue(['discount_schema_form', index, 'weekdayRateExtras', key]),
                              'timeOfDayWeekday',
                              modalType,
                            )
                              ? 'error'
                              : ''
                          }
                        >
                          <InputNumber
                            precision={2}
                            controls={false}
                            onChange={(value: number) => validateInputChange(form, index, value, key, 'weekday', false)}
                          />
                        </Form.Item>
                        <TimeContainer>
                          <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                            <Select
                              style={{ width: '141px' }}
                              onSelect={() => {
                                handleSelect(
                                  'weekdayRateExtras',
                                  form,
                                  index,
                                  'timeOfDayWeekday',
                                  pricingSchemaValues,
                                  modalType,
                                  key,
                                )
                              }}
                              dropdownClassName="dropdown-large-font"
                            >
                              {eventSchedules.map((eventSchedule, _) => {
                                return (
                                  <Select.Option
                                    value={eventSchedule.from}
                                    key={
                                      eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`
                                    }
                                  >
                                    {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </TimeContainer>
                        <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>{toText}</div>
                        <TimeContainer>
                          <Form.Item name={[key, 'to']}>
                            <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                              {eventSchedules.map((eventSchedule, _) => {
                                return (
                                  <Select.Option
                                    value={eventSchedule.to}
                                    key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                  >
                                    {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </TimeContainer>
                      </Space>
                    </div>
                  ))}
                </div>
                <Form.Item>
                  <EditButtonWrapper>
                    <Button
                      className="add-rate-button"
                      type="primary"
                      shape="circle"
                      icon={plusSVG}
                      onClick={add}
                      loading={false}
                      disabled={false}
                    />
                  </EditButtonWrapper>
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Form.List name={[index, 'weekendRateExtras']}>
            {(fields, { add }) => (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8, marginTop: 0 }} align="baseline">
                      <Form.Item
                        name={[key, 'dollar_rate']}
                        label={key === 0 ? <>{weekendRateText}:</> : ''}
                        style={{ marginLeft: key === 0 ? '' : '131px' }}
                        help={
                          calculateDollarRateLimit(
                            pricingSchemaValues,
                            form.getFieldValue(['discount_schema_form', index, 'weekendRateExtras', key]),
                            'timeOfDayWeekend',
                            modalType,
                          )
                            ? discountPlanInfoText
                            : null
                        }
                        validateStatus={
                          calculateDollarRateLimit(
                            pricingSchemaValues,
                            form.getFieldValue(['discount_schema_form', index, 'weekendRateExtras', key]),
                            'timeOfDayWeekend',
                            modalType,
                          )
                            ? 'error'
                            : ''
                        }
                      >
                        <InputNumber
                          precision={2}
                          controls={false}
                          onChange={(value: number) => validateInputChange(form, index, value, key, 'weekend', false)}
                        />
                      </Form.Item>
                      <TimeContainer>
                        <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                          <Select
                            onSelect={() => {
                              handleSelect(
                                'weekendRateExtras',
                                form,
                                index,
                                'timeOfDayWeekend',
                                pricingSchemaValues,
                                modalType,
                                key,
                              )
                            }}
                            style={{ width: '141px' }}
                            dropdownClassName="dropdown-large-font"
                          >
                            {eventSchedules.map((eventSchedule, _) => {
                              return (
                                <Select.Option
                                  value={eventSchedule.from}
                                  key={
                                    eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`
                                  }
                                >
                                  {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </TimeContainer>
                      {toText}
                      <TimeContainer>
                        <Form.Item name={[key, 'to']}>
                          <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                            {eventSchedules.map((eventSchedule, _) => {
                              return (
                                <Select.Option
                                  value={eventSchedule.to}
                                  key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                >
                                  {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </TimeContainer>
                    </Space>
                  ))}
                </div>
                <Form.Item>
                  <EditButtonWrapper>
                    <Button
                      className="add-rate-button"
                      type="primary"
                      shape="circle"
                      icon={plusSVG}
                      onClick={add}
                      loading={false}
                      disabled={false}
                    />
                  </EditButtonWrapper>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </>
      )}
    </>
  )
}
