import { Checkbox, Col, Form, Input, Select } from 'antd'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'
import { InfoTextWrapper } from '../../../atom/price'
import { Tenant } from '../../../models/tenant'
import { PricingSchema } from '../../../models/price'
import { redInfoWithISVG } from '../../../assets/svg/info'
import { usePlanTranslation } from '../../../hooks/translation/usePlanTranslation'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { ReactElement, useEffect, useState } from 'react'
import { GetListings } from 'services/data-provider/listing'
import { usePricingSchemas } from 'hooks/usePricingSchemas'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'
import { NewListing } from 'models/listing'
import CoreBadge from 'components/badge/Badge'
import { ListingsResponse } from 'models/http'

interface props {
  tenant: Tenant
  nameExists: boolean | undefined
  selectedPricingSchema?: PricingSchema
  allListings: NewListing[]
  type: string
  nameCheck: (value: string, selectedPricingSchema?: PricingSchema) => void
  getPricingNames: (tenantId: number) => void
  setListings: (listings: NewListing[]) => void
  setAllListings: (listings: NewListing[]) => void
}

export const NameSectionModal: React.FC<props> = ({
  tenant,
  selectedPricingSchema,
  nameExists,
  allListings,
  type,
  nameCheck,
  getPricingNames,
  setListings,
  setAllListings,
}) => {
  const { nameInUseText, selectedListingsInfoText } = usePricingSchemasTranslation()
  const { nameText } = usePlanTranslation()
  const { tenantText, listingsText } = useGeneralTranslation()
  const { setError, setLoading } = usePricingSchemas()
  const {
    multiListingTitleAndIdHandleChange,
    multiListingTitleAndIdDebounceFetcher,
    multiListingTitleHandlePlaceholder,
    multiListingTitleAndIdHandleValue,
  } = useSelector()
  const [selectedListings, setSelectedListings] = useState<NewListing[]>()

  useEffect(() => {
    getPricingNames(Number(tenant.id))
    if (tenant) {
      GetListings({ defaultTenant: tenant, allListings: true })
        .then((listings: NewListing[] | ListingsResponse) => {
          if (Array.isArray(listings)) {
            setAllListings(listings)
            if (selectedPricingSchema?.appliedListings && type === 'edit') {
              const listingArr: NewListing[] = []
              selectedPricingSchema?.appliedListings.map((listing) => {
                listings.filter((listingInfo) => {
                  if (Number(listingInfo.id) === listing.listingId) listingArr.push(listingInfo)
                  return listingArr
                })
                return listingArr
              })
              setSelectedListings(listingArr)
            }
          }
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }, [])

  const renderListingOption = (
    listing: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={listing.id} value={listing.id} label={listing.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.title === listing.title) > -1}
      >
        {listing.title}
      </Checkbox>
    </Select.Option>
  )

  return (
    <>
      <Col span={10}>
        <Form.Item label={<>{tenantText}:</>} style={{ marginBottom: '25px' }}>
          <CoreBadge title={tenant?.displayName || tenant?.name} />
        </Form.Item>
      </Col>
      <div style={{ display: 'flex' }}>
        <Form.Item
          className="name-title"
          rules={[{ required: true }]}
          validateStatus={nameExists ? 'error' : ''}
          label={<>{nameText}:</>}
          name="name"
          style={{ marginRight: '10px', marginBottom: '16px' }}
        >
          <Input
            onChange={(e) => nameCheck(e.target.value, selectedPricingSchema)}
            placeholder={nameText}
            style={{ fontSize: '14px', paddingLeft: '12px' }}
          />
        </Form.Item>
        {nameExists && (
          <InfoTextWrapper>
            <div style={{ marginBottom: '8px' }}>{redInfoWithISVG}</div>{' '}
            <span className="danger-text">{nameInUseText}</span>
          </InfoTextWrapper>
        )}
      </div>
      <Form.Item label={<>{listingsText}:</>} name="listing" className="listing" help={selectedListingsInfoText}>
        <ReusableSelector
          showSearch={true}
          onClear={() => setListings([])}
          isSingle={false}
          showMagnifySVG={true}
          maxTagCount={0}
          isDebounceFetcher={true}
          handlePlaceholder={multiListingTitleHandlePlaceholder}
          debounceFetcher={multiListingTitleAndIdDebounceFetcher}
          customWidth="252px"
          dropdownClassName={'not-in-modal-selector dropdown-large-font'}
          placeholder={listingsText}
          dropDownList={allListings}
          defaultValues={type === 'create' ? allListings : selectedListings}
          selectAllOptions={true}
          onOptionsChange={(listing) => setListings(listing)}
          handleOptionChange={multiListingTitleAndIdHandleChange}
          renderOption={renderListingOption}
          handleValue={multiListingTitleAndIdHandleValue}
        />
      </Form.Item>
    </>
  )
}
