import { WebAuth } from 'auth0-js'
import jwt_decode from 'jwt-decode'
import React, { createContext, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Paths } from '../components/routes/paths'
import configProvider from '../config'
import { buildAppUrl } from '../helpers/url'
import { log } from '../logger'
import { JWT, JWTIssue } from '../models/auth'
import { AvailableLanguages, LocalStorageLangKey } from '../models/language'
import { getTenantPermissions, TenantPermission, User, UserSession } from '../models/user'
import {
  clearLanguage,
  clearSession,
  getSession,
  setSession as setSessionStore,
  getPersistSession,
  setPersistSession,
  clearPersist,
} from './session-store'
import { clearTenant, getTenant, setTenant as setTenantStore } from './tenant-store'

export interface StateContextType {
  auth0: WebAuth

  hydrateSession(): UserSession | undefined

  hydratePersist(): boolean

  setCurrentUser(user: User): void

  currentUser: User | undefined

  isAuthenticated(): boolean

  login(jwt: JWTIssue): void

  logout(): void

  selectTenant(tenant: TenantPermission | undefined): void

  selectedTenant: TenantPermission | undefined

  IsMobile(): boolean

  IsTablet(): boolean

  IsLaptop(): boolean

  IsDesktop(): boolean

  ToggleClassicDashboardView(): void
  isClassicView: boolean
  isUsersClassicView: boolean
  isTransactionsClassicView: boolean

  language: AvailableLanguages
  setLanguage(lng: AvailableLanguages): void
}

export const StateContext = createContext<StateContextType>(null!)

const decodeJWT = (token: string): UserSession => {
  const decoded = jwt_decode<JWT>(token)
  const session = {
    id: decoded.uid,
    a: decoded.a,
    token: token,
    expiresAt: decoded.exp,
  } as UserSession
  return session
}

const getStoredLang = (): AvailableLanguages => {
  const storedState = localStorage.getItem(LocalStorageLangKey)
  if (storedState) {
    return JSON.parse(storedState) as AvailableLanguages
  }
  return 'en' as AvailableLanguages
}

const storeLang = (lng?: AvailableLanguages) => {
  if (lng) {
    localStorage.setItem(LocalStorageLangKey, JSON.stringify(lng))
  } else {
    localStorage.removeItem(LocalStorageLangKey)
  }
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [session, setSession] = useState<UserSession>()
  const [persist, setPersist] = useState(false)
  const [currentUser, setCurrentUser] = useState<User>()
  const [selectedTenant, setSelectedTenant] = useState<TenantPermission>()
  const [language, setLanguage] = useState<AvailableLanguages>(getStoredLang())
  const [classicView, setClassicView] = useState(false)
  const [usersClassicView, setUsersClassicView] = useState(false)
  const [transactionsClassicView, setTransactionsClassicView] = useState(false)

  if (!configProvider.config.auth0.clientId) {
    throw new Error('Could not initialize WebAuth, Auth0 config has not been fetched')
  }

  const auth0 = new WebAuth({
    clientID: configProvider.config.auth0.clientId,
    domain: configProvider.config.auth0.domain,
    redirectUri: buildAppUrl(Paths.auth0Callback),
    responseType: 'token id_token',
    scope: configProvider.config.auth0.scope,
  })

  const hydrateSession: StateContextType['hydrateSession'] = (): UserSession | undefined => {
    let sess = getSession()

    log('hydrating sessions via local store', {
      sess: sess,
    })

    if (!sess) {
      return undefined
    }

    setSession(sess)
    return sess
  }

  const hydratePersist: StateContextType['hydratePersist'] = (): boolean => {
    let isPersist = getPersistSession()

    log('persisting sessions via local store', { persist })
    if (!isPersist) {
      return false
    }
    setPersistSession(isPersist)
    return isPersist
  }

  const hydrateSelectedTenant = (user: User) => {
    let storedSelectedTenant = getTenant()
    log('hydrating selected tenant via local store', {
      tenant: storedSelectedTenant,
    })

    if (!storedSelectedTenant) {
      return undefined
    }

    getTenantPermissions(user).forEach((tp) => {
      if (tp.id === storedSelectedTenant?.id) {
        setSelectedTenant(storedSelectedTenant)
      }
    })
  }

  const setCurrentUserFunc: StateContextType['setCurrentUser'] = (user: User) => {
    setCurrentUser(user)
    hydrateSelectedTenant(user)
  }

  const login: StateContextType['login'] = (jwt: JWTIssue) => {
    log('logging in with jwt %o ', jwt)
    const s = decodeJWT(jwt.token)
    const doNotShowAgain = localStorage.getItem('doNotShowAgain')
    log('jwt decoded', { session: s })
    setSession(s)
    setPersist(doNotShowAgain === 'true')
    setSessionStore(s)
    storeLang(getStoredLang())
  }

  const logout: StateContextType['logout'] = () => {
    clearSession()
    clearPersist()
    clearTenant()
    clearLanguage()
    setSession(undefined)
    setSelectedTenant(undefined)
    auth0.logout({})
  }

  const selectTenant: StateContextType['selectTenant'] = (tenant: TenantPermission) => {
    setTenantStore(tenant)
    setSelectedTenant((prev) => {
      return tenant
    })
  }

  const setLanguageFunc: StateContextType['setLanguage'] = (lng: AvailableLanguages) => {
    storeLang(lng)
    setLanguage(lng)
  }

  const isAuthenticated: StateContextType['isAuthenticated'] = (): boolean => {
    var sess: UserSession | undefined = session
    if (!sess) {
      log("local session isn't define attempt to manually fetching")
      sess = getSession()
      log('retrieved session %o', sess)

      if (!sess) {
        log('no local session found')
        return false
      }
    }

    // if (!sess.expiresAt) {
    //   log("session does not have an expiry")
    //   return [false, sess]
    // }
    //
    // const expired = new Date().getTime() / 1000 < sess.expiresAt
    // log("session expiration check", {
    //   expired: expired,
    // })
    return true
  }

  const IsMobile: StateContextType['IsMobile'] = (): boolean => useMediaQuery({ maxWidth: 576 })

  const IsTablet: StateContextType['IsTablet'] = (): boolean => useMediaQuery({ maxWidth: 992, minWidth: 576 })

  const IsLaptop: StateContextType['IsLaptop'] = (): boolean => useMediaQuery({ maxWidth: 1199, minWidth: 993 })

  const IsDesktop: StateContextType['IsDesktop'] = (): boolean => useMediaQuery({ minWidth: 1200 })

  const ToggleClassicDashboardView: StateContextType['ToggleClassicDashboardView'] = (): void => {
    setClassicView(!classicView)
    setUsersClassicView(!usersClassicView)

    if (currentUser?.role === 'user') {
      setTransactionsClassicView(!transactionsClassicView)
      if (transactionsClassicView) {
        document.querySelector('body')?.classList.add('redesignActive')
      }
    }
  }

  const isClassicView = classicView
  const isUsersClassicView = usersClassicView
  const isTransactionsClassicView = transactionsClassicView

  return (
    <StateContext.Provider
      value={{
        auth0,
        login,
        logout,
        selectTenant,
        setCurrentUser: setCurrentUserFunc,
        currentUser,
        selectedTenant,
        hydrateSession,
        hydratePersist,
        isAuthenticated,
        IsMobile,
        IsTablet,
        IsLaptop,
        IsDesktop,
        language,
        setLanguage: setLanguageFunc,
        ToggleClassicDashboardView,
        isClassicView,
        isUsersClassicView,
        isTransactionsClassicView,
      }}
    >
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}

export function useCurrentUser(): User {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useUserSession must be used within the AppStateProvider')
  }

  if (!context.currentUser) {
    throw new Error('useCurrentUser must be called within an authenticated component')
  }

  return context.currentUser
}
