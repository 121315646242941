import { styled } from '../theme'

interface props {
  customMargin?: string
}

export const HorizontalLine = styled.hr<props>`
  margin: ${(props) => (props.customMargin ? props.customMargin : '20px -24px 20px')};
  border: 0;
  border-top: 1px solid #f0f0f0;
`
