export const currency = ['CAD', 'USD']
export type Currency = typeof currency[number]

export const intervals = ['week', 'month', 'year']
export type Intervals = typeof intervals[number]

export const countries = ['CA', 'US']
export type Country = typeof countries[number]

const status = ['active', 'inactive']
export type Status = typeof status[number]

export interface Plan extends PlanRef {
  priceCents: number
  priceCurrency: string
  interval: Intervals
  intervalCount: number
  status: Status
  description: string
  createdAt: string
  updatedAt: string
  currency: Currency
  subscriptionsCount: number
  country: Country
}

export interface PlanRef {
  id: number
  name: string
}
