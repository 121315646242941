import { useFormatMessage } from '../../helpers/intl'

export const useContactTranslation = () => {
  const accountContactText = useFormatMessage('dashboard.tenantPage.contactInfo.accountContact', 'Account Contact')
  const onSiteContactText = useFormatMessage('dashboard.tenantPage.contactInfo.onsiteContact', 'On-Site Contact')
  const installerContactText = useFormatMessage(
    'dashboard.tenantPage.contactInfo.installerContact',
    'Installer Contact',
  )
  const nameText = useFormatMessage('dashboard.text.name', 'Name')

  const titleText = useFormatMessage('dashboard.tenantPage.contactInfo.title', 'Job Title')
  const phoneNumberText = useFormatMessage('dashboard.tenantPage.contactInfo.phoneNumber', 'Phone Number')
  const emailText = useFormatMessage('dashboard.tenantPage.contactInfo.email', 'Email')
  const typeofContactText = useFormatMessage('dashboard.tenantPage.contactInfo.typeofcontact', 'Type Of Contact')
  const installerText = useFormatMessage('dashbaord.text.installateur', 'Installer')

  const locationMissingText = useFormatMessage(
    'dashboard.tenantPage.contactInfo.location.err',
    'Please update tenants Location',
  )

  const contactUpdatedText = useFormatMessage('dashboard.tenantPage.contactInfo.contactUpdated', 'Contact Updated')

  const propertyManagementCompanyText = useFormatMessage(
    'dashboard.tenantPage.contactInfo.propertymanagementcompany',
    'Property Management Company',
  )

  const validEmailText = useFormatMessage('dashboard.tenantPage.contactInfo.validemail.warning', 'Enter valid E-mail!')

  const masterAccountText = useFormatMessage('dashboard.text.masterAccount', 'Master Account')
  const channelPartnerText = useFormatMessage('dashboard.text.channelPartner', 'Channel Partner')
  return {
    accountContactText,
    onSiteContactText,
    installerContactText,
    nameText,
    titleText,
    phoneNumberText,
    emailText,
    typeofContactText,
    installerText,
    locationMissingText,
    contactUpdatedText,
    propertyManagementCompanyText,
    validEmailText,
    masterAccountText,
    channelPartnerText,
  }
}
