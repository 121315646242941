import { Alert } from 'antd'

import { AlertBoxprops } from '../models/error'
import { useAppState } from '../state'

export const AlertError: React.FC<AlertBoxprops> = ({ error, closable }) => {
  const { currentUser } = useAppState()

  if (!error) {
    return <></>
  }

  const errorForAdmin = () => {
    return (
      currentUser?.role === 'admin' &&
      error.messages?.map((err, index) => {
        return <li key={index}>{error?.cause ? `${err} due to ${error.cause}` : err}</li>
      })
    )
  }

  const errorForNonAdmin = () => {
    return (
      currentUser?.role !== 'admin' && (
        <li>{'Something went wrong with this request. Please contact customer support if this issue persists.'}</li>
      )
    )
  }

  const description = () => {
    return (
      <ul>
        {errorForAdmin()}
        {errorForNonAdmin()}
      </ul>
    )
  }

  if (error?.cause?.message === 'Request Timed Out') {
    return (
      <Alert
        closable={closable || false}
        showIcon
        message={
          'It’s seems that you’re trying to generate a very large report. Please generate a report with a shorter time range. '
        }
        type="warning"
      />
    )
  }

  if (error.cause?.message === 'Diagnostics request already in progress for this charger') {
    return <Alert closable={closable || false} showIcon message={error.cause?.message} type="error" />
  }
  return (
    <Alert closable={closable || false} showIcon message={error.description} description={description()} type="error" />
  )
}
