import { Button, DatePicker, message, Space } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { renderFormatMessage } from '../../helpers/intl'
import { Charger, ChargerDiagnosticsState } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { GetChargerDiagnosticsState, RequestChargerDiagnostics } from '../../services/data-provider/charger'
import { useAppState } from '../../state'
import { AlertError } from '../error'

interface props {
  charger: Charger
  onRequestComplete: () => any | null
}

const { RangePicker } = DatePicker

type DateRange = [moment.Moment | null, moment.Moment | null]

export const ChargerDiagnosticsController: React.FC<props> = ({ charger, onRequestComplete }) => {
  const [error, setError] = useState<SwtchError>()
  const [state, setState] = useState<ChargerDiagnosticsState>()
  const [loading, setLoading] = useState(false)
  const [loadingDiag, setLoadingDiag] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>([moment().subtract(1, 'days'), moment()])

  const { selectedTenant: defaultTenant } = useAppState()

  const load = () => {
    setLoading(true)
    GetChargerDiagnosticsState(charger, defaultTenant)
      .then((state: ChargerDiagnosticsState) => {
        setState(state)
        onRequestComplete()
      })
      .catch((error: any) => {
        const displayError: SwtchError =
          error instanceof SwtchError ? error : new SwtchError('Unexpected error loading diagnostics state')
        setError(displayError)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    load()
  }, [charger])

  const requestDiagnostics = () => {
    setLoadingDiag(true)
    setLoading(true)
    RequestChargerDiagnostics(charger, dateRange, defaultTenant)
      .catch((err: any) => {
        const swError =
          err instanceof SwtchError
            ? err
            : new SwtchError('Could not request diagnostics', [], err instanceof Error ? err : undefined)
        setError(swError)
      })
      .then(() => {
        message.success('Diagnostic Request Sent!')
        load()
      })
      .finally(() => {
        setLoadingDiag(false)
        setLoading(false)
      })
  }

  const defaultStartTime = state?.startTime ? moment(state?.startTime) : moment().subtract(1, 'days')
  const defaultStopTime = state?.stopTime ? moment(state?.stopTime) : moment()

  return (
    <>
      <AlertError error={error} />
      <Space>
        <RangePicker
          defaultValue={[defaultStartTime, defaultStopTime]}
          disabled={[loadingDiag, loadingDiag]}
          showTime={true}
          onChange={(dates, dateStrings) => {
            if (dates) {
              setDateRange(dates)
            }
          }}
        />

        <Button type="primary" disabled={loading} onClick={requestDiagnostics}>
          {renderFormatMessage('dashboard.chargerPage.diagnostics.button.text', 'Request Diagnostics')}
        </Button>
        {/* <Button onClick={debounce(kickCharger, 3000, { leading: true })}> */}
        {/* {renderFormatMessage('dashboard.chargerPage.diagnostics.kickButton.text', 'Kick Charger')} */}
        {/* </Button> */}
      </Space>
    </>
  )
}
