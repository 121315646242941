import React from 'react'
import moment from 'moment'
import { Col, Layout, Row } from 'antd'

const { Footer } = Layout

export const AppFooter: React.FC = () => {
  return (
    <Footer
      style={{
        padding: '5px 10px',
        borderTop: '1px solid #DEDEDE',
      }}
    >
      <Row justify="end">
        <Col>
          <small className="paragraph-04-regular">
            Powered by <strong>SWTCH</strong> &copy; {moment().format('YYYY')}
          </small>
        </Col>
      </Row>
    </Footer>
  )
}
