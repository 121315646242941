import { Button, Modal } from 'antd'
import { useListingBasicTrans } from '../../../../hooks/translation/useListingBasicTrans'
import { circleErrorSVG } from 'assets/svg/error'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { ArchiveDiscountSchemaModalWrapper, CustomFooterLayout } from 'atom/price'
interface props {
  visible: boolean
  discount: any
  onOk: (discountId: number) => void
  onCancel: () => void
}

export const ArchiveDiscountSchemaModal: React.FC<props> = ({ visible, discount, onOk, onCancel }) => {
  const { cancel } = useListingBasicTrans()
  const {
    archiveText,
    archiveDescriptionText,
    actionDescriptionText,
    promoCodeDescriptionText,
    usersRemovedDescriptionText,
  } = useDiscountSchemaTranslation()
  return (
    <Modal visible={visible} onCancel={onCancel} width={400} bodyStyle={{ padding: '24px' }} footer={null}>
      <ArchiveDiscountSchemaModalWrapper>
        <div>{circleErrorSVG}</div>
        <div className="title">{archiveDescriptionText}</div>
        <div className="description paragraph-01-regular">{actionDescriptionText}</div>
        <div className="bullet-points paragraph-03-regular">
          <ul>
            <li>{promoCodeDescriptionText}</li>
            <li>{usersRemovedDescriptionText}</li>
          </ul>
        </div>
      </ArchiveDiscountSchemaModalWrapper>
      <CustomFooterLayout>
        <Button type="ghost" onClick={onCancel}>
          {cancel}
        </Button>
        <Button
          className="red"
          type="primary"
          onClick={() => {
            onOk(discount.id)
            onCancel()
          }}
          style={{ marginLeft: '12px' }}
        >
          {archiveText}
        </Button>
      </CustomFooterLayout>
    </Modal>
  )
}
