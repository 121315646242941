import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { theme } from '../../theme'
import { TransactionMetric } from '../../models/analytics'
import { useFormatMessage } from '../../helpers/intl'

interface props {
  data: TransactionMetric[]
}

export const TransactionGraph: React.FC<props> = ({ data }) => (
  <ResponsiveContainer minHeight={500} minWidth={290}>
    <BarChart data={data} margin={{ left: -10, top: 0, bottom: 0 }}>
      <XAxis dataKey="humanizeTimestamp" stroke={theme.colors.darkBlue} fontSize={'0.75em'} />

      <YAxis
        yAxisId="left"
        orientation="left"
        stroke={theme.colors.darkBlue}
        type="number"
        allowDecimals={false}
        fontSize={'0.75em'}
      />

      <Tooltip
        formatter={(value: number) => {
          return `${value}`
        }}
      />
      <Legend />

      <CartesianGrid stroke={theme.colors.ternary300} strokeDasharray="7 7" />

      <Bar
        name={useFormatMessage('dashboard.homePage.transaction', 'Transaction')}
        type="monotone"
        dataKey="count"
        fill={theme.colors.darkBlue}
        yAxisId="left"
      />
    </BarChart>
  </ResponsiveContainer>
)
