import { Card } from 'antd'
import { theme } from 'theme'

interface props {
  children: React.ReactNode
}

const TotalCard: React.FC<props> = ({ children }) => {
  return (
    <Card
      style={{ backgroundColor: theme.colors.cultered, marginTop: '12px', borderRadius: '5px' }}
      bodyStyle={{ padding: '8px 12px' }}
    >
      {children}
    </Card>
  )
}

export default TotalCard
