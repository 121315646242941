import { Button, Col, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'

import { Box } from '../../atom/box'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { TaggingColumns } from 'components/tagging/table/tagging-columns'
import { useTaggingTranslation } from 'hooks/translation/useTaggingTranslation'
import { useTagging } from 'hooks/useTagging'
import { Tagging } from 'models/tagging'
import { plusSignSVG } from '../../assets/svg/plusSign'
import { CreateTagModal } from './modal/create-tag-modal'

const TaggingBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive', 'tagging')
  const [showTagModal, setShowTagModal] = useState(false)

  const {
    taggings,
    loading,
    error,
    pagination,
    onPaginationChange,
    selectedTag,
    setSelectedTag,
    getTaggings,
  } = useTagging()
  const { createTagText } = useTaggingTranslation()

  useEffect(() => {
    if (!showTagModal) {
      setSelectedTag(undefined)
    }
  }, [showTagModal])

  const handleOnSubmit = () => {
    getTaggings()
    setShowTagModal(!showTagModal)
  }

  const selectTagToEdit = (tag: Tagging) => () => {
    setSelectedTag(tag)
    setShowTagModal(!showTagModal)
  }

  const renderTitle = () => {
    return (
      <Row justify="end">
        <Col>
          <Button
            key="create-tag-btn"
            loading={loading}
            size="large"
            type="primary"
            disabled={loading}
            icon={plusSignSVG}
            onClick={() => setShowTagModal(!showTagModal)}
            style={{ minWidth: '140px' }}
          >
            <span className="regular-cap">{createTagText}</span>
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      {renderTitle()}
      <Box style={{ marginTop: '19px' }}>
        <Row>
          <Col span={24}>
            <AlertError error={error} />
            <Table
              columns={TaggingColumns(selectTagToEdit, getTaggings)}
              dataSource={taggings}
              loading={loading}
              rowKey="id"
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: onPaginationChange,
              }}
            />
          </Col>
        </Row>
      </Box>
      {showTagModal && (
        <CreateTagModal
          visible={showTagModal}
          initialValues={selectedTag}
          onCancel={() => setShowTagModal(!showTagModal)}
          onSubmit={handleOnSubmit}
        />
      )}
    </div>
  )
}

export const TaggingPage = withAuthenticatedLayout(TaggingBasePage)
