import { Col, Row, Statistic } from 'antd'
import pluralize from 'pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation, faExchangeAlt, faMoneyBill } from '@fortawesome/free-solid-svg-icons'

import { Widget } from '../../../../components/widget'

import { TransactionMetricResponse } from '../../../../models/analytics'
import { Charger } from '../../../../models/charger'
import { Connector } from '../../../../models/connector'

import { theme } from '../../../../theme'
import { renderFormatMessage } from '../../../../helpers/intl'
import ConnectorStatusWidget from '../../../../features/connectorStatusWidget'

interface props {
  loading: boolean
  charger: Charger
  connector?: Connector
  metrics?: TransactionMetricResponse
}

export const ChargerStats: React.FC<props> = ({ loading, charger, connector, metrics }) => {
  return (
    <Row gutter={[8, 8]} style={{ marginTop: '8px' }} justify="space-around">
      <Col xs={24} sm={24} md={12} lg={6}>
        <ConnectorStatusWidget charger={charger} connector={connector} loading={loading} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <Widget
          loading={loading}
          title={pluralize('Transaction', metrics?.totalCount || 0)}
          header={
            <Statistic
              value={metrics?.totalCount || 0}
              prefix={<FontAwesomeIcon icon={faExchangeAlt} color={theme.colors.primary} />}
            />
          }
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <Widget
          loading={loading}
          title={renderFormatMessage(
            'dashboard.chargerPage.chargerStatus.energyConsumption',
            'Energy Consumption (kWh)',
          )}
          header={
            <Statistic
              value={metrics?.totalEnergy || 0}
              precision={3}
              prefix={<FontAwesomeIcon icon={faChargingStation} color={theme.colors.primary} />}
            />
          }
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <Widget
          loading={loading}
          title={renderFormatMessage('dashboard.chargerPage.chargerStatus.revenue', 'Revenue ($)')}
          header={
            <Statistic
              value={metrics?.totalRevenue?.toFixed(2) || 0}
              precision={2}
              prefix={<FontAwesomeIcon color={theme.colors.primary} icon={faMoneyBill} />}
            />
          }
        />
      </Col>
    </Row>
  )
}
