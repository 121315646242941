import { useFormatMessage } from '../../helpers/intl'

export const useCarTranslation = () => {
  const brandText = useFormatMessage('dashboard.text.brand', 'Brand')
  const modelText = useFormatMessage('dashboard.text.model', 'Model')
  const yearText = useFormatMessage('dashboard.text.year', 'Year')
  const socBeforeChargingText = useFormatMessage('dashboard.text.socBeforeCharging', 'Society Before Charging')
  const socAfterChargingText = useFormatMessage('dashboard.text.socAfterCharging', 'Society After Charging')

  return {
    brandText,
    modelText,
    yearText,
    socBeforeChargingText,
    socAfterChargingText,
  }
}
