import { Button, Col, Form, FormInstance, Row, Select, Space } from 'antd'
import { BinSVG } from '../../../assets/svg/bin'
import { PeakShavingAddIconSVG } from '../../../assets/svg/peak-shaving-add-icon'
import { PeakShavingSelectArrowDown } from '../../../assets/svg/select'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { PeakShavingEventFormType, PeakShavingEventSchedule } from '../../../models/peak-shaving'
import { styled } from '../../../theme'
import { TimeContainer } from '../modal/create-new-event-modal'

interface props {
  form: FormInstance<any>
  formListName: string
  disabled: boolean
  formMode: PeakShavingEventFormType
  onSelect(): void
}

const DeleteBtnContainer = styled.div`
  svg {
    margin-top: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
`

const PlusBtnContainer = styled.div`
  margin-top: 5px;
  margin-left: 5px;

  .ant-btn {
    width: 25.05px;
    height: 25.05px;
    padding: 0px;
    position: relative;
    border-radius: 50%;
  }
`

export const ScheduleComponent: React.FC<props> = ({ form, formListName, disabled, formMode, onSelect }) => {
  const {
    noneZoneText,
    primaryZoneText,
    secondaryZoneText,
    zoneWarningText,
    startTimeZoneWarningText,
  } = usePeakShavingTranslation()

  const eventSchedules: Omit<PeakShavingEventSchedule, 'id'>[] = Array.from(Array(24), (_, index) => ({
    from: index,
    to: index + 1,
    zone: 'none',
  }))

  const handleSelect = () => {
    onSelect()
    form.validateFields()

    form.getFieldValue(formListName) &&
      form.getFieldValue(formListName).map((_: any, index: any) => {
        return form.setFields([
          {
            name: [formListName, index, 'to'],
            value: form.getFieldValue([formListName, index, 'from'])
              ? form.getFieldValue([formListName, index, 'from']) + 1
              : 1,
          },
        ])
      })
  }

  const validateField = () => {
    const fromValues = form.getFieldValue(formListName).map((fieldValue: any, _: any) => {
      return fieldValue.from
    })
    const noDups = new Set(fromValues)

    if (noDups.size !== fromValues.length) {
      return Promise.reject(startTimeZoneWarningText)
    }
    return Promise.resolve()
  }

  const isOptionDisabled = (value: number): boolean => {
    const fromValues = form.getFieldValue(formListName).map((fieldValue: any, _: any) => {
      return fieldValue.from
    })
    return fromValues.includes(value)
  }

  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ position: 'relative' }}>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name={[index, 'zone']}
                      rules={[{ required: true, message: zoneWarningText }]}
                      className="zone"
                    >
                      <Select suffixIcon={<PeakShavingSelectArrowDown />} disabled={disabled} onSelect={handleSelect}>
                        <Select.Option value="none" key="none">
                          {noneZoneText}
                        </Select.Option>
                        <Select.Option value="primary" key="primaryPeakZone">
                          {primaryZoneText}
                        </Select.Option>
                        <Select.Option value="secondary" key="secondaryPeakZone">
                          {secondaryZoneText}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={2}>
                    <TimeContainer>
                      <Space>
                        <Form.Item
                          name={[index, 'from']}
                          rules={[
                            () => ({
                              required: true,
                              validator() {
                                return validateField()
                              },
                            }),
                          ]}
                          validateTrigger={['onChange', 'onSelect']}
                        >
                          <Select
                            suffixIcon={<PeakShavingSelectArrowDown />}
                            onSelect={() => {
                              handleSelect()
                            }}
                            disabled={formMode === 'create' ? false : true}
                          >
                            {eventSchedules.map((eventSchedule, _) => {
                              return (
                                <Select.Option
                                  value={eventSchedule.from}
                                  key={
                                    eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`
                                  }
                                  disabled={isOptionDisabled(eventSchedule.from)}
                                >
                                  {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <p className="paragraph-04-light" style={{ position: 'absolute', top: '11px' }}>
                          TO
                        </p>
                      </Space>
                    </TimeContainer>
                  </Col>
                  <Col span={3} offset={4}>
                    <TimeContainer>
                      <Form.Item name={[index, 'to']}>
                        <Select disabled showArrow={false}>
                          {eventSchedules.map((eventSchedule, _) => {
                            return (
                              <Select.Option
                                value={eventSchedule.to}
                                key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                              >
                                {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </TimeContainer>
                  </Col>
                  <Col span={1} offset={3}>
                    {fields.length > 1 && formMode === 'create' ? (
                      <DeleteBtnContainer>
                        <BinSVG
                          onClick={() => {
                            remove(field.name)
                            handleSelect()
                          }}
                        />
                      </DeleteBtnContainer>
                    ) : null}
                  </Col>
                </Row>
              </div>
            ))}
            {fields.length < 24 && formMode === 'create' && (
              <PlusBtnContainer>
                <Button
                  type="primary"
                  onClick={() => {
                    add()
                    handleSelect()
                  }}
                  className="add-btn"
                  icon={<PeakShavingAddIconSVG style={{ position: 'absolute' }} />}
                />
              </PlusBtnContainer>
            )}
          </div>
        )
      }}
    </Form.List>
  )
}
