import { useRef, useState } from 'react'
import { Col, Row } from 'antd'

import { PaginationMeta } from '../../../../models/pagination'
import { Transaction } from '../../../../models/transaction'

import { useAppState } from '../../../../state'
import TransactionsTable from './TransactionsTable'
import TransactionPanel from '../panel/TransactionPanel'
import TransactionsCollapse from '../collapse/TransactionsCollapse'

interface props {
  loading: boolean
  transactions: Transaction[]
  pagination: PaginationMeta
  onPageChange: (page: number) => void
}

const TransactionsTableView: React.FC<props> = ({ loading, transactions, pagination, onPageChange }) => {
  const { IsDesktop } = useAppState()

  const isDesktop = IsDesktop()

  const [showPanel, setShowPanel] = useState(false)
  const [position, setPosition] = useState(71)
  const [rowIndex, setRowIndex] = useState(1)
  const selectedTransactionIdRef = useRef<number | null>(null)

  const selectedTransaction = transactions.find((transaction) => transaction.id === selectedTransactionIdRef.current)

  const handleSelectedTransactionIdRef = (record: Transaction) => {
    selectedTransactionIdRef.current = record.id
  }

  const handlePanel = () => setShowPanel(!showPanel)

  const canShowPanel = showPanel && selectedTransaction ? true : false

  const handleRowIndex = (rowIndex?: number) => {
    if (!rowIndex) {
      return setRowIndex(0)
    }

    if (rowIndex) {
      return setRowIndex(rowIndex + 1)
    }
  }

  return (
    <>
      {isDesktop ? (
        <Row style={{ display: 'flex' }} gutter={[2, 80]}>
          {/* Row is the main container of the entire table so give it 'flex' so that side panel can be sticky */}
          <Col
            lg={canShowPanel ? 17 : 24}
            xl={canShowPanel ? 19 : 24}
            xxl={canShowPanel ? 19 : 24}
            style={{ transition: 'all 0.2s ease-in-out' }}
          >
            <TransactionsTable
              loading={loading}
              selectedTransaction={selectedTransaction}
              transactions={transactions}
              pagination={pagination}
              onPageChange={onPageChange}
              onSelectedTransaction={(record, index) => {
                handleRowIndex(index)
                handleSelectedTransactionIdRef(record)
              }}
              showPanel={canShowPanel}
              onShowPanel={handlePanel}
              onPosition={(record) => setPosition(record)}
            />
          </Col>

          {/* The transaction panel */}
          {selectedTransaction && showPanel ? (
            <Col lg={showPanel ? 7 : 0} xl={showPanel ? 5 : 0} xxl={showPanel ? 5 : 0}>
              <TransactionPanel
                loading={loading}
                selectedTransaction={selectedTransaction}
                onShowPanel={handlePanel}
                position={position}
                rowIndex={rowIndex}
                totalTransactions={transactions.length}
              />
            </Col>
          ) : null}
        </Row>
      ) : (
        <TransactionsCollapse
          loading={loading}
          transactions={transactions}
          pagination={pagination}
          onPaginationChange={onPageChange}
        />
      )}
    </>
  )
}

export default TransactionsTableView
