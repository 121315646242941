import { Button } from 'antd'
import { CustomFooterLayout } from '../../../atom/price'
import { HorizontalLine } from '../../../atom/horizontal-line'
import { FormInstance } from 'antd/es/form/Form'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { plusSVG } from 'assets/svg/plus'
import { ButtonModal } from 'atom/button'

interface props {
  loading: boolean
  form: FormInstance
  nameExists: boolean | undefined
  current?: number
  type: string
  setNextInModal?: (nextInModal: boolean) => void
  onCancel: () => void
}

export const FooterSectionModal: React.FC<props> = ({
  loading,
  form,
  nameExists,
  type,
  current,
  onCancel,
  setNextInModal,
}) => {
  const { cancelText, saveText, discountPlanText } = useGeneralTranslation()

  return (
    <>
      <HorizontalLine />
      <CustomFooterLayout>
        {type === 'edit' && (
          <Button key="back" loading={loading} onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {type === 'create' && current === 0 && (
          <ButtonModal
            type="default"
            icon={plusSVG}
            text={discountPlanText}
            disabled={form.getFieldValue('name') === '' || nameExists}
            click={
              setNextInModal
                ? () => {
                    setNextInModal(true)
                  }
                : () => {}
            }
          />
        )}
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loading}
          disabled={form.getFieldValue('name') === '' || nameExists}
        >
          {saveText}
        </Button>
      </CustomFooterLayout>
    </>
  )
}
