import { ChargerInfo } from '../../models/chargerv2'

export const mockChargerInfo: ChargerInfo = {
  users: {
    active: 0,
    charging: 0,
    idle: 0,
    accountHolder: 0,
    guest: 0,
  },
  connectors: {
    pluggedIn: 230,
    pluggedInLevel2: 229,
    pluggedInDc: 1,
    available: 2526,
    availableLevel2: 2494,
    availableDc: 32,
    drProgram: 196,
    drProgramLevel2: 196,
    drProgramDc: 0,
    charging: 121,
    totalConnectors: 2756,
  },
  chargers: {
    maxAmp: 128000,
    currentAmp: 1638.77,
    online: 2756,
    offline: 163,
    repair: 109,
    troubleshooting: 23,
    other: 11,
    active: 44,
  },
}
