import { transaction24x24SVG } from '../../../../assets/svg/transaction'

import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { useTransactionMetricTranslation } from '../../../../hooks/translation/useTransactionMetricTranslation'

import { TransactionMetric } from '../../../../models/analytics'
import { useAppState } from '../../../../state'
import { CoreInfoCard } from 'components/cards'

interface TotalTransactionsProps {
  metrics?: TransactionMetric
  loading: boolean
}

const TotalTransactions = ({ metrics, loading }: TotalTransactionsProps) => {
  const { currentUser } = useAppState()
  const { cancelHeading, errorHeading, totalHeading } = useTransactionMetricTranslation()
  const { transactionText, transactionsText } = useGeneralTranslation()

  const calculateErrorAndCancelCount = (metrics?.count || 0) - (metrics?.errorCount || 0) - (metrics?.cancelCount || 0)

  const totalErrorAndCancelCount =
    metrics?.errorCount && metrics.cancelCount ? calculateErrorAndCancelCount : metrics?.count || 0

  const cancelTrxsCount = `${cancelHeading}: ${metrics?.cancelCount || 0}`
  const errorTrxsCount = `${errorHeading}: ${metrics?.errorCount || 0}`
  const totalTrxsCount = `${totalHeading}: ${metrics?.count || 0}`

  const infoDetails = [
    {
      label: currentUser?.role === 'admin' ? `${cancelTrxsCount} ${errorTrxsCount} ${totalTrxsCount}` : '',
    },
  ]

  return (
    <CoreInfoCard
      title={totalErrorAndCancelCount >= 2 ? transactionsText : transactionText}
      wsLoading={loading}
      overviewIcon={transaction24x24SVG}
      infoText={totalErrorAndCancelCount}
      infoDetails={infoDetails}
    />
  )
}

export default TotalTransactions
