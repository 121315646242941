import React, { useEffect } from 'react'
import { AuthorizeOptions } from 'auth0-js'
import { RouteComponentProps } from 'react-router'
import { useAppState } from '../../state'
import { buildAppUrl } from '../../helpers/url'
import { Paths } from '../../components/routes/paths'
import configProvider from '../../config'
import { log } from '../../logger'

export const Auth0LoginPage: React.FC<RouteComponentProps> = () => {
  const { auth0 } = useAppState()
  const opts = {
    clientID: configProvider.config.auth0.clientId,
    domain: configProvider.config.auth0.domain,
    redirectUri: buildAppUrl(Paths.auth0Callback),
    scope: configProvider.config.auth0.scope,
  }
  log('auth0 configuration opt', { options: opts })
  useEffect(() => {
    const options: AuthorizeOptions = {
      mode: 'signUp',
    }
    auth0.authorize(options)
  }, [])

  return <div />
}
