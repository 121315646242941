import { useEffect, useState } from 'react'
import { message, Select, Tag } from 'antd'
import { FindConnectors } from '../../services/data-provider/connector'
import { Connector } from '../../models/connector'
import { SwtchError } from '../../models/error'
import { ChargerRef } from '../../models/charger'

const { Option } = Select

interface props {
  charger: ChargerRef
  width?: number
  onConnectorSelected: (connector: Connector) => void
  onClear: () => void
}

const display = (conn: Connector) => {
  return (
    <>
      Connector {conn.connectorId} {conn.listing ? `(${conn.listing?.title})` : 'No listing title'}
    </>
  )
}

export const ConnectorSelector: React.FC<props> = ({ charger, onConnectorSelected, onClear }) => {
  const [selected, setSelected] = useState<Connector>()
  const [connectors, setConnectors] = useState<Connector[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    FindConnectors(charger)
      .then((connectors) => {
        setConnectors(connectors)
      })
      .catch((err: SwtchError) => {
        message.error(err.description)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [charger])

  const onChange = (value: number) => {
    connectors.forEach((conn) => {
      if (conn.id === value) {
        setSelected(conn)
        onConnectorSelected(conn)
      }
    })
  }

  return (
    <>
      {!selected && (
        <Select loading={loading} placeholder="Select a connector" onChange={onChange}>
          {connectors.map((conn) => {
            return (
              <Option value={conn.id} key={`connector-${conn.id}`}>
                {display(conn)}
              </Option>
            )
          })}
        </Select>
      )}
      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {display(selected)}
        </Tag>
      )}
    </>
  )
}
