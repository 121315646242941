const commonSVG = `
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 10px;
  right: 11px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s;
  height: 13px;
`

export default commonSVG
