import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exportData from 'highcharts/modules/export-data'
import HC_exporting from 'highcharts/modules/exporting'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { initialChartOption } from '../../../../helpers/chart/initial-setup'

import { DailyProfile } from '../../../../models/transaction'
import { useAppState } from '../../../../state'
import { ConsumptionInsightLoadProfileChartContainer } from '../../../../atom/chart/chart'
import { useMediaQuery } from 'react-responsive'
import { useFormatMessage } from 'helpers/intl'

HC_exporting(Highcharts)
HC_exportData(Highcharts)

const handleFileName = (tabKey: string) => {
  return `Consumption Load Profile ${tabKey} Data ${moment().format('YYYY-DD-MM')}`
}
const handleChartTitle = (tabKey: string) => {
  return `Consumption Insight Load Profile - ${tabKey}`
}

interface props {
  tabKey: string
  dailyProfile: DailyProfile[]
  timezone?: string
  chartRef: React.MutableRefObject<null>
}

export const HomePageComumptionInsightLoadProfileChart: React.FC<props> = ({
  tabKey,
  dailyProfile,
  timezone,
  chartRef,
}) => {
  const { language } = useAppState()
  const [options, setOptions] = useState(initialChartOption)

  const timzeZoneText = useFormatMessage('dashboard.homePage.operationoverview.timeZone.heading', 'Time (EST)')

  const isResponsiveMenuStyling = useMediaQuery({ minWidth: 0, maxWidth: 991 })
  const isResponsiveStyling = useMediaQuery({ minWidth: 0, maxWidth: 649 })

  useEffect(() => {
    // TODO: we'll need a type for filteredData
    let filteredData: any
    switch (tabKey) {
      case 'All':
        filteredData = dailyProfile.map((obj) => [obj.consumeWeek / 1000])
        break
      case 'Weekday':
        filteredData = dailyProfile.map((obj) => [obj.time, obj.consumeWeekday / 1000])
        break
      case 'Weekend':
        filteredData = dailyProfile.map((obj) => [obj.time, obj.consumeWeekend / 1000])
        break
      default:
        break
    }
    setOptions({
      ...options,
      title: {
        text: handleChartTitle(tabKey),
      },
      xAxis: {
        ...options.xAxis,
        title: {
          ...options.xAxis.title,
          text: timezone && timezone === 'America/Toronto' ? timzeZoneText : '',
        },
      },
      series: [
        {
          ...options.series[0],
          data: filteredData,
        },
      ],
      exporting: {
        ...options.exporting,
        filename: handleFileName(tabKey),
      },
    })
  }, [tabKey, dailyProfile, timezone, language])

  return (
    <ConsumptionInsightLoadProfileChartContainer
      lang={language}
      isResponsiveMenuStyling={isResponsiveMenuStyling}
      isResponsiveStyling={isResponsiveStyling}
    >
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </ConsumptionInsightLoadProfileChartContainer>
  )
}
