import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { ChargerInfo } from '../../../../models/chargerv2'

highchartsMore(Highcharts)

const ConnectorsAvailabilityContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 0px;
  pointer-events: none;
  .highcharts-color-1 {
    fill: #54dec7;
    stroke: #54dec7;
    filter: none;
  }

  .highcharts-color-0 {
    fill: #000000;
    stroke: #000000;
    opacity: 0.05;
    filter: none;
  }

  .highcharts-tooltip span {
    height: auto;
    width: 450px;
    background-color: #242e2c;
    color: white;
    overflow: auto;
    white-space: normal !important;
  }
`

export const optionsconnectorsAvailability = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,

    // size: '100%',
    width: 100,
    height: 100,
  },

  title: {
    text: '', //removes title of chart
  },
  tooltip: {
    enabled: false,
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
    },
  },
  series: [
    {
      type: 'pie',
      name: 'Chargers Availability',
      colorByPoint: true,
      data: [
        {
          name: 'Total',
          y: 0,
          //   selected: true,
        },
        {
          name: 'Available Chargers',
          y: 0,
        },
      ],
      states: {
        hover: {
          halo: {
            size: 1,
            opacity: 0.4,
            attributes: {
              fill: '#54DEC7',
              strokeWidth: 25,
              stroke: '#54DEC7',
            },
          },
        },
        inactive: {
          enabled: false,
        },
        lineWidth: 5,
      },
    },
  ],
}

interface props {
  chargerInfo: ChargerInfo
}

export const HomePageChargersAvailbality: React.FC<props> = ({ chargerInfo }) => {
  const laptopScreens = useMediaQuery({ minWidth: 1200, maxWidth: 1600 })
  const largeDisplays = useMediaQuery({ minWidth: 1600 })
  if (laptopScreens) {
    optionsconnectorsAvailability.chart.width = 100
  } else if (largeDisplays) {
    optionsconnectorsAvailability.chart.width = 112
    optionsconnectorsAvailability.chart.height = 112
  } else {
    optionsconnectorsAvailability.chart.width = 100
  }

  const largeLaptopScreens = useMediaQuery({ minWidth: 1300, maxWidth: 1600 })
  const smallLaptopScreen = useMediaQuery({ minWidth: 1200, maxWidth: 1300 })

  if (largeLaptopScreens) {
    document.querySelector('.chargers-availability h1')?.classList.add('laptop-active')
    document.querySelector('.chargers-availability h1')?.classList.remove('laptop-active-small')
    document.querySelector('.chargers-availability')?.classList.add('laptop-active')
  } else if (smallLaptopScreen) {
    document.querySelector('.chargers-availability h1')?.classList.add('laptop-active-small')
  } else {
    document.querySelector('.chargers-availability h1')?.classList.remove('laptop-active', 'laptop-active-small')
    document.querySelector('.chargers-availability')?.classList.remove('laptop-active')
  }
  const [options, setOptions] = useState(optionsconnectorsAvailability)

  useEffect(() => {
    setOptions({
      ...options,
      series: [
        {
          ...options.series[0],
          data: [
            {
              name: 'Total',
              y: chargerInfo.connectors.totalConnectors - chargerInfo.connectors.available,
            },
            {
              name: 'Available Chargers',
              y: chargerInfo.connectors.available,
            },
          ],
        },
      ],
    })
  }, [chargerInfo])

  return (
    <ConnectorsAvailabilityContainer className="connectorsAvailability">
      <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
    </ConnectorsAvailabilityContainer>
  )
}
