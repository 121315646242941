import { Drawer } from 'antd'
import { styled } from '../theme'

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper > .ant-drawer-content {
    border-radius: 30px 30px 0px 0px;
  }
  .ant-drawer-content-wrapper {
    border-radius: 30px 30px 0px 0px;
  }
`
