import { Badge, Card, Col, Collapse, List, Row, Tag } from 'antd'
import { CardBody } from '../../atom/card'
import { SelectorWrapper } from '../../atom/select'
import { useFormatMessage } from '../../helpers/intl'
import { Plan } from '../../models/plan'
import { theme } from '../../theme'
import { AddUserToPlanButton, EditPlanButton, TogglePlanButton } from './plan-button'

const { Panel } = Collapse

interface props {
  plans: Plan[]
  loading: boolean
  onAddUserToPlan: (plan: Plan) => void
  onEditPlan: (plan: Plan) => void
  onTogglePlan: (plan: Plan) => void
}

const subcribersCount = (p: Plan) => {
  if (p.subscriptionsCount === null || p.subscriptionsCount === 0) {
    return
  }
  return <Badge count={p.subscriptionsCount} style={{ backgroundColor: theme.colors.green }} />
}

export const PlanMobileView: React.FC<props> = ({ loading, plans, onAddUserToPlan, onEditPlan, onTogglePlan }) => {
  const subscribeUserText = useFormatMessage('dashboard.plan.subscribeUser', 'Subscribe User')
  const editPlanText = useFormatMessage('dashboard.plan.editPlan', 'Edit Plan')
  const deletePlanText = useFormatMessage('dashboard.plan.togglePlan', 'Toggle Plan')
  const activeText = useFormatMessage('dashboard.text.active', 'Active')
  const inactiveText = useFormatMessage('dashboard.text.inactive', 'Inactive')

  return (
    <Col xs={24} xl={24}>
      <CardBody>
        <Card loading={loading} bordered={false}>
          <Collapse defaultActiveKey={1}>
            {plans.map((plan) => (
              <Panel
                extra={`$${(plan.priceCents / 100).toFixed(2)} / ${plan.interval} (${plan.currency})`}
                key={plan.id}
                header={plan.name}
              >
                <List
                  dataSource={[plan]}
                  renderItem={(plan) => (
                    <>
                      <List.Item actions={[plan.description]} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <List.Item.Meta title="Description" />
                      </List.Item>
                      <List.Item
                        actions={[
                          plan.status === 'active' ? (
                            <Tag color="green">{activeText}</Tag>
                          ) : (
                            <Tag color="red">{inactiveText}</Tag>
                          ),
                        ]}
                      >
                        <List.Item.Meta title="Status" />
                      </List.Item>
                      <List.Item actions={[subcribersCount(plan)]}>
                        <List.Item.Meta title="Subscription Count" />
                      </List.Item>
                      <List.Item actions={[plan.interval]}>
                        <List.Item.Meta title="Interval" />
                      </List.Item>
                    </>
                  )}
                >
                  <SelectorWrapper>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={8} md={8}>
                        <AddUserToPlanButton
                          plan={plan}
                          children={subscribeUserText}
                          onAddUserToPlan={() => onAddUserToPlan(plan)}
                        />
                      </Col>
                      <Col xs={24} sm={8} md={8}>
                        <EditPlanButton plan={plan} children={editPlanText} onEditPlan={(plan) => onEditPlan(plan)} />
                      </Col>
                      <Col xs={24} sm={8} md={8}>
                        <TogglePlanButton
                          plan={plan}
                          children={deletePlanText}
                          onTogglePlan={(plan) => onTogglePlan(plan)}
                        />
                      </Col>
                    </Row>
                  </SelectorWrapper>
                </List>
              </Panel>
            ))}
          </Collapse>
        </Card>
      </CardBody>
    </Col>
  )
}
