import { Button, Tooltip } from 'antd'
import { useFormatMessage } from '../../helpers/intl'
import { Plan } from '../../models/plan'

interface props {
  plan: Plan
  children: string | JSX.Element
}

interface AddUserProps extends props {
  onAddUserToPlan: (plan: Plan) => void
}

interface EditPlanProps extends props {
  onEditPlan: (plan: Plan) => void
}

interface TogglePlanProps extends props {
  onTogglePlan: (plan: Plan) => void
}

export const AddUserToPlanButton: React.FC<AddUserProps> = ({ plan, children, onAddUserToPlan }) => {
  const subscribeUserText = useFormatMessage('dashboard.plan.subscribeUser', 'Subscribe User')
  return (
    <Tooltip title={subscribeUserText}>
      <Button
        type="primary"
        block={true}
        disabled={plan.status === 'active' ? false : true}
        onClick={() => onAddUserToPlan(plan)}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export const EditPlanButton: React.FC<EditPlanProps> = ({ plan, children, onEditPlan }) => {
  const editPlanText = useFormatMessage('dashboard.plan.editPlan', 'Edit Plan')
  return (
    <Tooltip title={editPlanText}>
      <Button
        type="primary"
        block={true}
        disabled={plan.status === 'active' ? false : true}
        onClick={() => onEditPlan(plan)}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export const TogglePlanButton: React.FC<TogglePlanProps> = ({ plan, children, onTogglePlan }) => {
  const togglePlanText = useFormatMessage('dashboard.plan.togglePlan', 'Toggle Plan')
  return (
    <Tooltip title={togglePlanText}>
      <Button type="primary" block={true} onClick={() => onTogglePlan(plan)}>
        {children}
      </Button>
    </Tooltip>
  )
}
