export interface Breakpoints {
  up: (key: string) => string
  down: (key: string) => string
  between: (start: string, end: string) => string
  only: (key: string) => string
}

export const createBreakpoints = (): Breakpoints => {
  const keys = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'] // Keep in mind that @media is inclusive by the CSS specification.
  const breakpoints: { [key: string]: number } = {
    xxxs: 300,
    xxs: 325,
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  }
  const unit = 'px'
  const step = 5

  const up = (key: string): string => {
    // var value = typeof values[key] === 'number' ? values[key] : key
    let value = breakpoints[key]
    return '@media (min-width:'.concat(`${value}`).concat(unit, ')')
  }

  const down = (key: string): string => {
    var endIndex = keys.indexOf(key)
    var upperbound = breakpoints[keys[endIndex]]
    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up('xs')
    }

    return '@media (max-width:'.concat(`${upperbound - step / 100}`).concat(unit, ')')
  }

  const between = (start: string, end: string): string => {
    var endIndex = keys.indexOf(end)

    if (endIndex === keys.length - 1) {
      return up(start)
    }

    let maxWidth = breakpoints[keys[endIndex + 1]]

    return (
      '@media (min-width:'.concat(`${breakpoints[start]}`).concat(unit, ') and ') +
      '(max-width:'.concat(`${maxWidth - step / 100}`).concat(unit, ')')
    )
  }

  const only = (key: string): string => {
    return between(key, key)
  }

  return {
    up: up,
    down: down,
    between: between,
    only: only,
  } as Breakpoints
}
