import { CoreInfoCard } from 'components/cards'
import { percentage } from '../../../../helpers/calculation'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const TransactionMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { transactionsAfter6PM, transactionsOnWeekdays } = useHomeTranslation()
  const labelFormat = {
    label: (value: string | number) => <b>{value}</b>,
    subLabel: (value: string | number) => <span className="default-label">{value}</span>,
  }
  const infoDetails = [
    {
      label: trxProfileSummary && calculateTrxPercentage(trxProfileSummary.txnAfterHr, trxProfileSummary.txnCount),
      subLabel: transactionsAfter6PM,
      labelFormat,
    },
    {
      label: trxProfileSummary && calculateTrxPercentage(trxProfileSummary.txnOnDays, trxProfileSummary.txnCount),
      subLabel: transactionsOnWeekdays,
      labelFormat,
    },
  ]

  return (
    <CoreInfoCard
      title={'Transactions'}
      wsLoading={loading}
      infoText={trxProfileSummary && trxProfileSummary.txnCount ? renderTransactions(trxProfileSummary.txnCount) : '0'}
      infoDetails={infoDetails}
    />
  )
}

export default TransactionMetrics

const calculateTrxPercentage = (value: number, total: number) => {
  return `${value && total ? percentage(value, total) : '0'}%`
}

const renderTransactions = (trnxCount: number) => {
  const output = trnxCount < 1000 ? trnxCount : `${(trnxCount / 1000).toFixed(2)}K`
  return output
}
