import moment from 'moment'
import { Col, Row } from 'antd'

import { formatDuration } from '../../../helpers/duration'
import { Widget } from '../../../components/widget'
import StatisticValue from '../../../components/statistic/StatisticValue'

import { TransactionMetric } from '../../../models/analytics'
import { styled } from '../../../theme'
import { useAppState } from '../../../state'
import { useTransactionMetricTranslation } from '../../../hooks/translation/useTransactionMetricTranslation'

const FontSizeDuration = styled.span`
  font-size: ${(props) => props.theme.fontSizes[5]};
`

interface props {
  metrics?: TransactionMetric
  loading: boolean
}

export const StatsView: React.FC<props> = ({ metrics, loading }) => {
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={24} md={12} lg={6}>
        <TotalTransactions metrics={metrics} loading={loading} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <TotalEnergy metrics={metrics} loading={loading} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <TotalRevenue metrics={metrics} loading={loading} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <TotalPlugInDuration metrics={metrics} loading={loading} />
      </Col>
    </Row>
  )
}

const TotalTransactions: React.FC<props> = ({ metrics, loading }) => {
  const { currentUser } = useAppState()
  const { cancelHeading, errorHeading, totalHeading, totalTransactionsText } = useTransactionMetricTranslation()

  const calculateErrorAndCancelCount = (metrics?.count || 0) - (metrics?.errorCount || 0) - (metrics?.cancelCount || 0)

  const totalErrorAndCancelCount =
    metrics?.errorCount && metrics.cancelCount ? calculateErrorAndCancelCount : metrics?.count

  const cancelTrxsCount = `${cancelHeading}: ${metrics?.cancelCount || 0}`
  const errorTrxsCount = `${errorHeading}: ${metrics?.errorCount || 0}`
  const totalTrxsCount = `${totalHeading}: ${metrics?.count || 0}`

  const onlyAdminCanSeeErrorAndCancelCount =
    currentUser?.role === 'admin' ? `${cancelTrxsCount}, ${errorTrxsCount}, ${totalTrxsCount}` : ''

  return (
    <Widget
      loading={loading}
      title={totalTransactionsText}
      header={<StatisticValue value={totalErrorAndCancelCount} />}
      description={onlyAdminCanSeeErrorAndCancelCount}
    />
  )
}

const TotalEnergy: React.FC<props> = ({ metrics, loading }) => {
  const { totalEnergyText } = useTransactionMetricTranslation()
  return (
    <Widget
      loading={loading}
      title={totalEnergyText}
      header={<StatisticValue precision={3} value={metrics?.energy || 0} />}
    />
  )
}

const TotalRevenue: React.FC<props> = ({ metrics, loading }) => {
  const { totalRevenueText } = useTransactionMetricTranslation()
  return (
    <Widget
      loading={loading}
      title={totalRevenueText}
      header={<StatisticValue precision={2} prefix="$" value={metrics?.revenue || 0} />}
    />
  )
}

const TotalPlugInDuration: React.FC<props> = ({ metrics, loading }) => {
  const { totalChargingDurationText } = useTransactionMetricTranslation()
  return (
    <Widget
      loading={loading}
      title={totalChargingDurationText}
      header={<FontSizeDuration>{formatDuration(moment.duration(metrics?.duration || 0, 'seconds'))}</FontSizeDuration>}
    />
  )
}
