import moment, { duration } from 'moment'

export const calculateDuration = (start: moment.Moment, end: moment.Moment) => {
  const result = durationDiff(start, end)

  return formatDuration(result)
}

export const durationDiff = (start: moment.Moment, end: moment.Moment) => {
  return duration(end.diff(start))
}

export const chargingDuration = (start: moment.Moment, duration: number) => {
  const end = start.add(duration, 'seconds')
  return calculateDuration(start, end)
}

export const formatDuration = (duration: moment.Duration) => {
  // TODO: use destructuring objects!!!
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  if (months !== 0 && days >= 1) {
    return `${months} month ${days} day${days === 1 ? '' : 's'}`
  } else if (months !== 0 && days === 0) {
    return `${months} month${months === 1 ? '' : `s`}`
  } else if (months === 0 && days >= 1) {
    return `${days} day${days === 1 ? '' : `s`} ${hours}h ${minutes}m`
  } else {
    return `${hours}h ${minutes}m ${seconds}s`
  }
}

export const timeConvert = (n: number) => {
  const hours = Math.floor(n / 60)
  const minutes = n % 60

  if (hours > 0 && minutes > 0) {
    return `${hours} hr${hours > 1 ? 's' : ''} and ${minutes} min${minutes > 1 ? 's' : ''}`
  } else if (hours > 0) {
    return `${hours} hr${hours > 1 ? 's' : ''}`
  } else {
    return `${minutes} min${minutes > 1 ? 's' : ''}`
  }
}

export const formatDurationWithTwoDigits = (chargingDuration: number) => {
  const duration = moment.duration(chargingDuration, 'seconds')
  const seconds = duration.seconds()
  const minutes = duration.minutes()
  const hours = duration.hours()

  // Format the time parts to ensure they are always displayed with 2 digits
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}
