import { Button, Form, Space } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { tailLayout } from 'atom/form/page-layout'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface TenantFooterProps {
  form: FormInstance
  disabled: boolean
  setDisabled: (disabled: boolean) => void
}

const TenantFooter: React.FC<TenantFooterProps> = ({ form, disabled, setDisabled }) => {
  const { modifyText, cancelText, saveText } = useGeneralTranslation()

  return (
    <Form.Item {...tailLayout}>
      <Space>
        <Button htmlType="submit" type="primary" disabled={disabled}>
          {saveText}
        </Button>
        {disabled ? (
          <Button htmlType="button" onClick={() => setDisabled(!disabled)} disabled={!disabled} type="primary">
            {modifyText}
          </Button>
        ) : (
          <Button
            htmlType="button"
            onClick={() => {
              setDisabled(!disabled)
              form.resetFields()
            }}
            danger
          >
            {cancelText}
          </Button>
        )}
      </Space>
    </Form.Item>
  )
}

export default TenantFooter
