import { Form, message, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'
import { AlertError } from '../../components/error'
import { useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { AugmentedUser, DashboardAccessType } from '../../models/user'
import { FindTenants } from '../../services/data-provider/tenants'
import { AddUserToTenants } from '../../services/data-provider/users'

const { Option } = Select
interface props {
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserUpdated: (user: AugmentedUser) => void
  user: AugmentedUser
}

export const AddUserToMultipleTenantsForm: React.FC<props> = ({ onCancel, onUserUpdated, user }) => {
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const { tenantPermission, id, email } = user
  const [form] = Form.useForm()

  useEffect(() => {
    FindTenants()
      .then((tenants) => {
        const idsToFilter: string[] = Object.values(tenantPermission)
          .filter((t) => t.access === 'manager' || t.access === 'viewer')
          .map((t) => t.id)

        if (idsToFilter.length > 0) {
          setTenants(tenants.filter((t) => !idsToFilter.includes(t.id)))
        } else {
          setTenants(tenants)
        }
      })
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
        setError(err)
      })
  }, [])

  const onOk = () => {
    setLoading(true)
    form.validateFields().then((values: { tenantNames: string[]; role: DashboardAccessType }) => {
      const { tenantNames, role } = values

      const tenantIds: number[] = tenants
        .filter((t) => {
          return tenantNames.includes(t.displayName || t.name)
        })
        .map((t) => {
          return Number(t.id)
        })

      AddUserToTenants(tenantIds, id, email, role)
        .then((resp) => {
          onUserUpdated(resp)
          message.success(`${successMssg}`)
        })
        .catch((err: SwtchError) => {
          message.error(`${failureMssg}`)
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const roleText = useFormatMessage('dashboard.usersPage.inviteuser.role.heading', 'Role')
  const managerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')
  const roleWarningText = useFormatMessage(
    'dashboard.usersPage.inviteuser.lastName.warning',
    'A role must be selected!',
  )
  const tenantWarningText = useFormatMessage(
    'dashboard.usersPage.selectTenants.field.warning',
    'A tenant must be selected!',
  )
  const selectTenantsHeading = useFormatMessage(
    'dashboard.usersPage.selectTenants.heading',
    'Add User To Multiple Tenants',
  )
  const okBtnText = useFormatMessage('dashboard.usersPage.selectTenants.addbtn', 'Add')
  const selectTenantsText = useFormatMessage('dashboard.usersPage.selectTenants.field', 'Select Tenants')
  const selectTenantsLabel = useFormatMessage('dashboard.navigation.tenants', 'Tenant')
  const successMssg = useFormatMessage(
    'dashboard.usersPage.selectTenants.successmssg',
    'Successfully added user to tenants',
  )
  const failureMssg = useFormatMessage('dashboard.usersPage.selectTenants.failedmssg', 'Could not add user to tenants')
  return (
    <Modal
      title={selectTenantsHeading}
      visible={true}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
      okText={okBtnText}
    >
      <Form
        form={form}
        initialValues={{ role: 'manager' }}
        labelAlign="right"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item
          key="tenantNames"
          label={selectTenantsLabel}
          name="tenantNames"
          rules={[{ required: true, message: tenantWarningText }]}
        >
          <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder={selectTenantsText}>
            {tenants.map((t) => (
              <Option value={t.displayName || t.name} key={t.id}>
                {t.displayName || t.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item key="role" label={roleText} name="role" rules={[{ required: true, message: roleWarningText }]}>
          <Select>
            <Option key="manager" value="manager">
              {managerText}
            </Option>
            <Option key="viewer" value="viewer">
              {viewerText}
            </Option>
          </Select>
        </Form.Item>
      </Form>
      {error && <AlertError error={error} />}
    </Modal>
  )
}
