import { Card, Col, Collapse, List } from 'antd'
import moment from 'moment'
import { FormattedDate } from 'react-intl'

import { Connector } from '../../../../models/connector'
import { Charger } from '../../../../models/charger'
import { ChargerConnectorStateBadge } from '../../../../components/chargers/charger-connector-state-badge'
import { renderFormatMessage } from '../../../../helpers/intl'

const { Panel } = Collapse

interface props {
  charger: Charger
  connectors: Connector[]
}

const formatDate = (date: string) => (
  <FormattedDate
    value={moment(date).format()}
    year="numeric"
    month="short"
    day="2-digit"
    hour="2-digit"
    minute="2-digit"
    second="2-digit"
    timeZoneName="short"
  />
)

export const ChargerTabDetailMobileView: React.FC<props> = ({ charger, connectors }) => {
  const ChargerInfo = (
    <Card title={charger.chargePointSerialNumber} style={{ marginBottom: '8px' }}>
      <List
        dataSource={[charger]}
        renderItem={(charger) => (
          <>
            <List.Item actions={[charger.chargePointModel]}>
              <List.Item.Meta title={renderFormatMessage('dashboard.charger.model', 'Model')} />
            </List.Item>
            <List.Item actions={[charger.chargePointVendor]}>
              <List.Item.Meta title={renderFormatMessage('dashboard.charger.vendor', 'Vendor')} />
            </List.Item>
            <List.Item actions={[charger.tenant?.displayName || charger.tenant?.name || '---']}>
              <List.Item.Meta title={renderFormatMessage('dashboard.text.tenant', 'Site')} />
            </List.Item>
            <List.Item actions={[charger.firmwareVersion]}>
              <List.Item.Meta title={renderFormatMessage('dashboard.charger.firmwareVersion', 'Firmware Version')} />
            </List.Item>
          </>
        )}
      />
    </Card>
  )

  const ConnectorInfo = (
    <Collapse accordion>
      {connectors.map((connector) => (
        <Panel
          key={connector.connectorId}
          extra={<ChargerConnectorStateBadge state={connector.state} />}
          header={connector.listing?.title || renderFormatMessage('dashboard.chargerPage.noListing', 'No Listing')}
        >
          <List
            dataSource={[connector]}
            renderItem={(connector) => (
              <>
                <List.Item actions={[connector.connectorId]}>
                  <List.Item.Meta title={renderFormatMessage('dashboard.charger.connectorId', 'Connector Id')} />
                </List.Item>
                <List.Item actions={[formatDate(connector.lastStatusChangedAt)]}>
                  <List.Item.Meta
                    title={renderFormatMessage('dashboard.connector.lastStatusChangedAt', 'Last status changed at')}
                  />
                </List.Item>
              </>
            )}
          />
        </Panel>
      ))}
    </Collapse>
  )

  return (
    <Col xs={24} xl={24}>
      {ChargerInfo}
      {ConnectorInfo}
    </Col>
  )
}
