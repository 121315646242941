import { Form, Input } from 'antd'
import { TenantContact } from 'models/tenant'

interface TenantOnSiteContactInfoProps {
  onsiteContact: TenantContact | undefined
  disabled: boolean
  setOnsiteContact: (onsiteContact: TenantContact | undefined) => void
}

const TenantOnSiteContactInfo: React.FC<TenantOnSiteContactInfoProps> = ({
  onsiteContact,
  disabled,
  setOnsiteContact,
}) => {
  return (
    <>
      <Form.Item
        name="namePrimary"
        label={'On-site Contact Name'}
        rules={[{ required: false }]}
        className="align-center"
      >
        <Input
          disabled={disabled}
          onChange={(e) =>
            setOnsiteContact({
              ...onsiteContact,
              namePrimary: e.target.value,
            })
          }
        />
      </Form.Item>
      <Form.Item name="email" label={'On-site Contact Email'} rules={[{ required: false }]} className="align-center">
        <Input
          disabled={disabled}
          onChange={(e) =>
            setOnsiteContact({
              ...onsiteContact,
              email: e.target.value,
            })
          }
        />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label={'On-site Contact Number'}
        rules={[{ required: false }]}
        className="align-center"
      >
        <Input
          disabled={disabled}
          onChange={(e) =>
            setOnsiteContact({
              ...onsiteContact,
              phoneNumber: e.target.value,
            })
          }
        />
      </Form.Item>
    </>
  )
}

export default TenantOnSiteContactInfo
