import { Card, Col, List, Spin } from 'antd'

import { renderFormatMessage } from '../../../../helpers/intl'
import { ListingBase } from '../../../../models/listing'

interface props {
  listings: ListingBase[]
  loading: boolean
  onPriceType: (listing: ListingBase) => React.ReactNode
  onPrice: (listing: ListingBase) => React.ReactNode
  actions: (listing: ListingBase) => React.ReactNode
}

export const ChargerTabListingMobileView: React.FC<props> = ({ listings, loading, onPriceType, onPrice, actions }) => {
  return (
    <Col span={24}>
      <Spin spinning={loading}>
        {listings.map((listing) => (
          <Card title={listing.title} key={listing.id} loading={loading}>
            <List
              loading={loading}
              dataSource={[listing]}
              renderItem={(listing) => (
                <>
                  <List.Item actions={[listing.connector.connectorId]}>
                    <List.Item.Meta title={renderFormatMessage('dashboard.connector.connectorId', 'Connector ID')} />
                  </List.Item>
                  <List.Item actions={[onPriceType(listing)]}>
                    <List.Item.Meta
                      title={renderFormatMessage('dashboard.chargerPage.listing.pricetype.heading', 'Price Type')}
                    />
                  </List.Item>
                  <List.Item actions={[onPrice(listing)]}>
                    <List.Item.Meta
                      title={renderFormatMessage('dashboard.chargerPage.listing.price.heading', 'Price')}
                    />
                  </List.Item>
                  <List.Item actions={[actions(listing)]}>
                    <List.Item.Meta
                      title={renderFormatMessage('dashboard.chargerPage.listing.action.heading', 'Action')}
                    />
                  </List.Item>
                </>
              )}
            />
          </Card>
        ))}
      </Spin>
    </Col>
  )
}
