import { theme } from '../../theme'

export const plugNeonGreenSVG = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5058 18.8816V20.921H19.6242V22.9605H13.5058C12.9649 22.9605 12.4461 22.7456 12.0636 22.3632C11.6812 21.9807 11.4663 21.462 11.4663 20.921V18.8816H8.40707C7.32527 18.8816 6.28777 18.4518 5.52282 17.6869C4.75787 16.9219 4.32813 15.8844 4.32812 14.8026V10.7237H20.6439V14.8026C20.6439 15.8844 20.2142 16.9219 19.4492 17.6869C18.6843 18.4518 17.6468 18.8816 16.565 18.8816H13.5058ZM16.565 6.64474H19.6242C19.8946 6.64474 20.154 6.75218 20.3452 6.94342C20.5365 7.13465 20.6439 7.39403 20.6439 7.66448V9.70395H4.32812V7.66448C4.32812 7.39403 4.43556 7.13465 4.6268 6.94342C4.81804 6.75218 5.07741 6.64474 5.34786 6.64474H8.40707V2.5658H10.4465V6.64474H14.5255V2.5658H16.565V6.64474ZM12.486 15.3125C12.7565 15.3125 13.0158 15.2051 13.2071 15.0138C13.3983 14.8226 13.5058 14.5632 13.5058 14.2928C13.5058 14.0223 13.3983 13.7629 13.2071 13.5717C13.0158 13.3805 12.7565 13.273 12.486 13.273C12.2156 13.273 11.9562 13.3805 11.765 13.5717C11.5737 13.7629 11.4663 14.0223 11.4663 14.2928C11.4663 14.5632 11.5737 14.8226 11.765 15.0138C11.9562 15.2051 12.2156 15.3125 12.486 15.3125Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const plugWhiteSVG = (
  <svg width="18" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.83301 14V15.6667H12.833V17.3334H7.83301C7.39098 17.3334 6.96706 17.1578 6.6545 16.8452C6.34194 16.5326 6.16634 16.1087 6.16634 15.6667V14H3.66634C2.78229 14 1.93444 13.6488 1.30932 13.0237C0.684197 12.3986 0.333008 11.5507 0.333008 10.6667V7.33335H13.6663V10.6667C13.6663 11.5507 13.3152 12.3986 12.69 13.0237C12.0649 13.6488 11.2171 14 10.333 14H7.83301ZM10.333 4.00002H12.833C13.054 4.00002 13.266 4.08782 13.4223 4.2441C13.5785 4.40038 13.6663 4.61234 13.6663 4.83335V6.50002H0.333008V4.83335C0.333008 4.61234 0.420805 4.40038 0.577086 4.2441C0.733366 4.08782 0.945327 4.00002 1.16634 4.00002H3.66634V0.666687H5.33301V4.00002H8.66634V0.666687H10.333V4.00002ZM6.99967 11.0834C7.22069 11.0834 7.43265 10.9956 7.58893 10.8393C7.74521 10.683 7.83301 10.471 7.83301 10.25C7.83301 10.029 7.74521 9.81704 7.58893 9.66076C7.43265 9.50448 7.22069 9.41669 6.99967 9.41669C6.77866 9.41669 6.5667 9.50448 6.41042 9.66076C6.25414 9.81704 6.16634 10.029 6.16634 10.25C6.16634 10.471 6.25414 10.683 6.41042 10.8393C6.5667 10.9956 6.77866 11.0834 6.99967 11.0834Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const plugNeonGreenHeaderSVG = (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.875 14.9V16.7H13.125V18.5H7.875C7.41087 18.5 6.96575 18.3104 6.63756 17.9728C6.30937 17.6352 6.125 17.1774 6.125 16.7V14.9H3.5C2.57174 14.9 1.6815 14.5207 1.02513 13.8456C0.368749 13.1705 0 12.2548 0 11.3V7.7H14V11.3C14 12.2548 13.6313 13.1705 12.9749 13.8456C12.3185 14.5207 11.4283 14.9 10.5 14.9H7.875ZM10.5 4.1H13.125C13.3571 4.1 13.5796 4.19482 13.7437 4.3636C13.9078 4.53239 14 4.7613 14 5V6.8H0V5C0 4.7613 0.0921874 4.53239 0.256282 4.3636C0.420376 4.19482 0.642936 4.1 0.875 4.1H3.5V0.5H5.25V4.1H8.75V0.5H10.5V4.1ZM7 11.75C7.23206 11.75 7.45462 11.6552 7.61872 11.4864C7.78281 11.3176 7.875 11.0887 7.875 10.85C7.875 10.6113 7.78281 10.3824 7.61872 10.2136C7.45462 10.0448 7.23206 9.95 7 9.95C6.76794 9.95 6.54538 10.0448 6.38128 10.2136C6.21719 10.3824 6.125 10.6113 6.125 10.85C6.125 11.0887 6.21719 11.3176 6.38128 11.4864C6.54538 11.6552 6.76794 11.75 7 11.75Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const plugGreySVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9722 16V18H16.8055V20H10.9722C10.4565 20 9.96189 19.7893 9.59724 19.4142C9.23258 19.0391 9.02772 18.5304 9.02772 18V16H6.11106C5.07966 16 4.0905 15.5786 3.3612 14.8284C2.63189 14.0783 2.22217 13.0609 2.22217 12V8H17.7777V12C17.7777 13.0609 17.368 14.0783 16.6387 14.8284C15.9094 15.5786 14.9202 16 13.8888 16H10.9722ZM13.8888 4H16.8055C17.0633 4 17.3106 4.10536 17.493 4.29289C17.6753 4.48043 17.7777 4.73478 17.7777 5V7H2.22217V5C2.22217 4.73478 2.3246 4.48043 2.50693 4.29289C2.68925 4.10536 2.93654 4 3.19439 4H6.11106V0H8.0555V4H11.9444V0H13.8888V4ZM9.99995 12.5C10.2578 12.5 10.5051 12.3946 10.6874 12.2071C10.8697 12.0196 10.9722 11.7652 10.9722 11.5C10.9722 11.2348 10.8697 10.9804 10.6874 10.7929C10.5051 10.6054 10.2578 10.5 9.99995 10.5C9.7421 10.5 9.49481 10.6054 9.31248 10.7929C9.13015 10.9804 9.02772 11.2348 9.02772 11.5C9.02772 11.7652 9.13015 12.0196 9.31248 12.2071C9.49481 12.3946 9.7421 12.5 9.99995 12.5V12.5Z"
      fill={theme.colors.darkGray}
    />
  </svg>
)
