import React from 'react'
import { Table, TableProps } from 'antd'
import { styled } from '../theme'

const Wrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    .ant-table {
      display: block;
      &-thead {
        display: none;
      }

      &-thead > tr,
      &-tbody > tr {
        th,
        td {
          border-right: 1px solid #000 !important;
          border-left: 1px solid #000 !important;
          &:first-of-type {
            padding-top: 1rem;
            border-top: 1px solid #000;
            display: flex;
          }
          &:last-of-type {
            padding-bottom: 1rem;
            border-bottom: 1px solid #000 !important;
            margin-bottom: 10px;
          }
        }

        > th,
        > td {
          display: block;
          width: auto !important;
          border: none;
          padding: 0 1rem;

          &:last-child {
            border-bottom: 1px solid #000;
          }
        }
      }
      &-tbody > tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
        border: none !important;
      }
    }
  }
`

export const ResponsiveTable: React.FC<TableProps<any>> = ({ ...props }) => {
  return (
    <Wrapper>
      <Table {...props} />
    </Wrapper>
  )
}
