import moment from 'moment'

import { ChargerDailyMetric, Measurements, TransactionMetric, TransactionMetricResponse } from '../models/analytics'
import { ConnectorStatusAlias } from '../models/connector'
import { mockConnectors } from './connector-mock'

const totalConnectorStatus = (state: string[]): number =>
  mockConnectors.filter((connector) => state.includes(connector.state)).length

const totalServiceStatus = (serviceStatuses: string): number =>
  mockConnectors.filter((conn) => conn.charger && serviceStatuses.includes(conn.charger.serviceStatus)).length

const totalActive = (serviceStatus: string, ocppStatus: string[]) =>
  mockConnectors.filter((conn) => ocppStatus.includes(conn.state) && conn?.charger?.serviceStatus === serviceStatus)
    .length

export const mockWidgetTotal = {
  series: [
    {
      count: 1,
      revenue: 23,
      energy: 55.231,
      date: '2021-03-19',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-19',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-20',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-20',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-21',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-21',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-22',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-22',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-23',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-23',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-24',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-24',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-25',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-25',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-26',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-26',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-27',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-27',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-28',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-28',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-29',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-29',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-30',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-30',
    },
    {
      count: 0,
      revenue: 0,
      energy: 0,
      date: '2021-03-31',
      timestamp: moment(),
      humanizeTimestamp: '2021-03-31',
    },
  ],
  totalCount: 1,
  totalRevenue: 23,
  totalEnergy: 55.231,
} as TransactionMetricResponse

export const mockTransactionMetrics = {
  count: 100,
  errorCount: 1,
  cancelCount: 2,
  revenue: 123123,
  energy: 2312,
  timestamp: moment(),
  date: '2021-01-01',
  duration: 10,
  humanizeTimestamp: '213',
} as TransactionMetric

export const mockConnChargerCounts = {
  totalCount: mockConnectors.length,
  availableCount: totalConnectorStatus(ConnectorStatusAlias['available']),
  inUseCount: totalConnectorStatus(ConnectorStatusAlias['inuse']),
  unavailableCount: totalConnectorStatus(ConnectorStatusAlias['unavailable']),
  commissionCount: totalServiceStatus('in_maintenance'),
  activeCount: totalActive('service_mode', ConnectorStatusAlias['active']),
  activatingCount: totalServiceStatus('activating'),
  underRepairCount: totalServiceStatus('under_repair'),
}

export const mockChargerDailyMetrics = [
  {
    tenant: 'test',
    tenant_display_name: 'display_name_test',
    charger_serial_number: 'EA-123',
    disconnect_total: 123,
    disconnect_daily_avg: 123.123,
    disconnect_high: 123,
    avg_disconnect_duration: 12,
    from: moment().format('YYYY-MM-DD'),
    day_count: 123,
  },
  {
    tenant: 'John',
    tenant_display_name: null,
    charger_serial_number: 'L-123',
    disconnect_total: 2,
    disconnect_daily_avg: 1.13,
    disconnect_high: 2,
    avg_disconnect_duration: 2,
    from: moment().format('YYYY-MM-DD'),
    day_count: 4,
  },
  {
    avg_disconnect_duration: 4349.266666666666,
    charger_serial_number: '1EC1-1-1824-00018',
    day_count: 30,
    disconnect_daily_avg: 130478,
    disconnect_high: 130478,
    disconnect_total: 1,
    from: '2022-04-11',
    tenant: '',
    tenant_display_name: null,
  },
  {
    avg_disconnect_duration: 544.1775641025641,
    charger_serial_number: '1EC1-1-1824-00019',
    day_count: 30,
    disconnect_daily_avg: 16325.326923076924,
    disconnect_high: 224089,
    disconnect_total: 104,
    from: '2022-04-11',
    tenant: '',
    tenant_display_name: null,
  },
  {
    avg_disconnect_duration: 572.8990579710146,
    charger_serial_number: '1EC1-1-1824-00021',
    day_count: 30,
    disconnect_daily_avg: 17186.971739130437,
    disconnect_high: 447259,
    disconnect_total: 460,
    from: '2022-04-11',
    tenant: '',
    tenant_display_name: null,
  },
  {
    avg_disconnect_duration: 3658.233333333333,
    charger_serial_number: '1EC3-1-1917-00010',
    day_count: 30,
    disconnect_daily_avg: 109747,
    disconnect_high: 109747,
    disconnect_total: 1,
    from: '2022-04-11',
    tenant: '',
    tenant_display_name: null,
  },
  {
    avg_disconnect_duration: 3658.233333333333,
    charger_serial_number: '1EC3-1-1917-00047',
    day_count: 30,
    disconnect_daily_avg: 109747,
    disconnect_high: 109747,
    disconnect_total: 1,
    from: '2022-04-11',
    tenant: 'Lan disconnect',
    tenant_display_name: 'Lan Disconnect',
  },
] as ChargerDailyMetric[]

export const mockMeasurementsResp = [
  {
    power_factor: 988,
    voltage: 11665,
    amperage: 11800,
    wattage: 988,
  },
  { power_factor: 988, voltage: 11665, amperage: 11800, wattage: 988 },
] as Measurements[]
