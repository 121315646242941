import { styled } from '../../theme'

interface IframeProps {
  title: string
  loading?: string
  height: number
  url: string
}

const StyledIframe = styled.iframe`
  border: 2px solid ${(props) => props.theme.colors.lightGray};
  border-width: 1px;
`

const Iframe: React.FC<IframeProps> = ({ title, loading, height, url }) => {
  return <StyledIframe title={title} loading={loading ? 'lazy' : 'eager'} src={url} width="100%" height={height} />
}

export default Iframe
