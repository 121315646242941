import { Button, InputNumber, message, Select, Skeleton, Space, Table } from 'antd'
import { useEffect, useState } from 'react'

import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { formatCentsPrice } from '../../helpers/number-format'

import { Charger } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { ListingBase } from '../../models/listing'
import { ChargerTabListingMobileView } from '../../pages/chargers/views/charger/charger-tab-listing-mobile-view'

import { GetChargerListings, UpdateChargerListing } from '../../services/data-provider/charger'
import { useAppState } from '../../state'
import { AlertError } from '../error'
import { PriceType, priceType } from '../../models/price'
import { EmptyData } from '../redesign/emptyData'

interface props {
  charger: Charger
}

export const ChargerTabListing: React.FC<props> = ({ charger }) => {
  const { IsMobile, IsTablet, IsLaptop, IsDesktop, selectedTenant: defaultTenant } = useAppState()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const [listings, setListings] = useState<ListingBase>()
  const [modifiedListing, setModifiedListing] = useState<ListingBase>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const hourText = useFormatMessage('dashboard.text.hour', 'Hour')

  useEffect(() => {
    setLoading(true)
    GetChargerListings(charger, defaultTenant)
      .then((resp) => {
        if (resp.data.length === 0) return
        return setListings(resp.data[0])
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [])

  const saveListing = () => {
    if (!modifiedListing) {
      return
    }
    setLoading(true)
    UpdateChargerListing(charger, modifiedListing)
      .then((resolvedListing: ListingBase) => {
        setListings(resolvedListing)
        setModifiedListing(undefined)
        message.success(`Listing '${resolvedListing.title}' successfully saved`, 10)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const listingAction = (listing: ListingBase) => {
    if (!modifiedListing) {
      return (
        <Button type="primary" onClick={() => setModifiedListing(listing)}>
          {renderFormatMessage('dashboard.chargerPage.listing.action.modifybtn', 'Modify')}
        </Button>
      )
    }
    if (modifiedListing.id === listing.id) {
      return (
        <Space>
          <Button type="primary" onClick={() => saveListing()}>
            {renderFormatMessage('dashboard.chargerPage.listing.action.saveBtn', 'Save')}
          </Button>
          <Button type="ghost" danger onClick={() => setModifiedListing(undefined)}>
            {renderFormatMessage('dashboard.chargerPage.listing.action.cancelBtn', 'Cancel')}
          </Button>
        </Space>
      )
    }
  }

  const modifiedPrice = (listing: ListingBase) => {
    if (modifiedListing && modifiedListing.id === listing.id) {
      return (
        <InputNumber<number>
          defaultValue={listing.price.pricePerUnitCents / 100}
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(displayValue: string | undefined) => {
            if (!displayValue) {
              return 0
            }
            const str = displayValue.replace(/\$\s?|(,*)/g, '')
            return parseFloat(str)
          }}
          onChange={(value) => {
            setModifiedListing((prev) => {
              if (!prev) {
                return { ...modifiedListing, price: { ...modifiedListing.price, pricePerUnitCents: value * 100 } }
              }
              return { ...prev, price: { ...prev.price, pricePerUnitCents: value * 100 } }
            })
          }}
        />
      )
    }
    return formatCentsPrice(listing.price.pricePerUnitCents)
  }

  const modifiedPriceType = (listing: ListingBase) => {
    if (modifiedListing && modifiedListing.id === listing.id) {
      return (
        <Select
          defaultValue={listing.price.priceType}
          options={priceType.map((type) => ({ value: type, label: type }))}
          onChange={(value: PriceType) => {
            setModifiedListing((prev) => {
              if (!prev) {
                return { ...modifiedListing, price: { ...modifiedListing.price, priceType: value } }
              }
              return { ...prev, price: { ...prev.price, priceType: value } }
            })
          }}
        />
      )
    }
    return listing.price.priceType === 'Hour' ? hourText : listing.price.priceType
  }

  const columns: any = [
    {
      title: renderFormatMessage('dashboard.chargerPage.listing.listingTitle.heading', 'Listing Title'),
      dataIndex: 'title',
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.listing.connectorid.heading', 'Connector ID'),
      dataIndex: ['connector', 'connectorId'],
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.listing.price.heading', 'Price'),
      key: 'price',
      render: (listing: ListingBase) => modifiedPrice(listing),
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.listing.pricetype.heading', 'Price Type'),
      key: 'priceType',
      render: (listing: ListingBase) => modifiedPriceType(listing),
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.listing.action.heading', 'Action'),
      key: 'updatePrice',
      render: (listing: ListingBase) => listingAction(listing),
    },
  ]

  return (
    <>
      <AlertError error={error} />
      {typeof listings === 'undefined' && loading && <Skeleton />}
      {typeof listings === 'undefined' && !loading && <EmptyData />}
      {listings && (isMobile || isTablet) && (
        <ChargerTabListingMobileView
          listings={[listings]}
          loading={loading}
          onPriceType={modifiedPriceType}
          onPrice={modifiedPrice}
          actions={listingAction}
        />
      )}
      {listings && (isDesktop || isLaptop) && (
        <Table
          columns={columns}
          dataSource={listings ? [listings] : []}
          pagination={false}
          rowKey="id"
          loading={loading}
        />
      )}
    </>
  )
}
