import { styled } from 'theme'
import configProvider from 'config'

interface MinMaxProps {
  height: number
}

const MinMaxContainer = styled.div<MinMaxProps>`
  .title-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: ${({ height }) => (height ? `calc(50% - ${height / 2}px)` : 'calc(50% - 50px)')};
    width: ${({ height }) => (height ? `${height}px` : '100px')};
    height: ${({ height }) => (height ? `${height}px` : '100px')};
    ${(props) => props.theme.breakpoints.down('xxs')} {
      scale: 0.8;
    }
    ${(props) => props.theme.breakpoints.down('xxxs')} {
      scale: 0.65;
    }
  }
  .title-container h4,
  .title-container p {
    margin: 0;
  }

  .title-container p {
    opacity: 0.5;
  }

  .minmax-container {
    position: absolute;
    //text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    width: ${configProvider.config.network === 'itsElectric' ? '50%' : '100%'};
    ${(props) => props.theme.breakpoints.only('sm')} {
      width: 50%;
    }
    left: 0;
    right: 0;
    top: calc(50% + 50px);
    margin-top: 5px;
    ${(props) => props.theme.breakpoints.down('xxxs')} {
      top: calc(50% + 30px);
    }
  }

  .min,
  .max {
    /* position: absolute; */
    /* bottom: 20px; */
    color: #242e2c;
  }

  .min {
    margin-left: 17%;
  }

  .max {
    margin-right: 17%;
  }
`

interface props {
  title: string | number
  label: string
  min: string | number
  max: string | number
  height: number
}

export const MinMaxWithTitle: React.FC<props> = ({ title, label, min, max, height = 100 }) => {
  return (
    <MinMaxContainer height={height}>
      <div className="title-container">
        <h4 className="heading-04-black">{title}</h4>
        <p className="paragraph-04-regular">{label}</p>
      </div>
      <div className="minmax-container">
        <p className="min paragraph-04-bold">
          <b>{min}</b>
        </p>
        <p className="max paragraph-04-bold">
          <b>{max}</b>
        </p>
      </div>
    </MinMaxContainer>
  )
}
