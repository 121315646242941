import { apiClient } from './client'
import { addQueryString } from '../../helpers/url'
import configProvider from '../../config'
import { returnMock } from './mock'
import { log } from '../../logger'
import { ExportType } from '../../models/transaction'
import { TenantRef } from '../../models/tenant'

export async function SendExport(token: string, type: ExportType, defaultTenant?: TenantRef): Promise<string> {
  let queryParams: { [key: string]: string } = {
    type: type,
    token: token,
  }

  if (defaultTenant) {
    queryParams['tenant_id'] = defaultTenant.id
  }

  const url = addQueryString(`/transactions/exports`, queryParams)
  log('sending export', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('email has been sent')
  }
  return apiClient<{
    response: string
  }>(url, {}).then((resp) => {
    return resp.response
  })
}
