import { useState } from 'react'
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd'

import { layout } from '../../../atom/form/modal-layout'
import { AlertErrorCompact } from '../../error-compact'
import { SwtchError } from '../../../models/error'
import { countries, currency, intervals, Plan } from '../../../models/plan'
import { CreatePlan } from '../../../services/data-provider/plan'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { MustBeRequired } from '../../rules/rules'
import { HeadingWrapper, ModalBodyWrapper, RowInputWrapper, TextBodyWrapper } from '../../../atom/plans'
import { FooterLayout } from '../../../atom/user-edit'
import { useNotifications } from '../../../hooks/useNotification'

interface props {
  onCancel: () => void
  onCreatedPlan: (plan: Plan) => void
}

interface FormFields {
  name: string
  description: string
  priceCents: number
  interval: string
  currency: string
  country: string
}

const initialValues = { interval: 'month', currency: 'CAD', priceCents: 0, country: 'CA' }

export const CreatePlanRedesignForm: React.FC<props> = ({ onCancel, onCreatedPlan }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { openSuccessNotification } = useNotifications()

  const { cancelText, createText, countryText, currencyText, okText } = useGeneralTranslation()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    form.validateFields().then((values: FormFields) => {
      const { name, description, priceCents, interval, currency, country } = values

      CreatePlan(name, description, priceCents * 100, interval, currency, country)
        .then((plan) => {
          openSuccessNotification(
            <>
              The plan "<b>{plan.name}</b>" was created.
            </>,
          )
          onCreatedPlan(plan)
        })
        .catch((error: SwtchError) => setError(error))
        .finally(() => setLoading(false))
    })
  }

  const afterClose = () => form.resetFields()

  return (
    <Modal
      title={<HeadingWrapper>Create Plan</HeadingWrapper>}
      closable={true}
      okText={okText}
      cancelText={cancelText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      width={471}
      afterClose={afterClose}
      footer={
        <FooterLayout>
          <Button key="back" danger loading={loading} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={onOk}>
            {createText}
          </Button>
        </FooterLayout>
      }
    >
      <AlertErrorCompact error={error} />
      <Form {...layout} labelWrap layout="vertical" form={form} initialValues={initialValues} requiredMark={false}>
        <ModalBodyWrapper>
          <Form.Item
            className="first-row"
            key="name"
            label={<TextBodyWrapper>Title</TextBodyWrapper>}
            name="name"
            rules={[MustBeRequired('Subscription title must be at least 8 characters long'), { type: 'string' }]}
          >
            <Input placeholder="Subscription title" type="text" maxLength={50} />
          </Form.Item>
          <Form.Item
            key="description"
            label={<TextBodyWrapper>Description</TextBodyWrapper>}
            name="description"
            rules={[{ required: true }]}
          >
            <Input placeholder="Description of the plan" type="textarea" />
          </Form.Item>
          <Form.Item key="interval" label={<TextBodyWrapper>Interval</TextBodyWrapper>} name="interval">
            <Select placeholder="Month">
              {intervals.map((interval, index) => (
                <Select.Option key={`${interval}-${index}`} value={interval}>
                  {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <RowInputWrapper>
            <Form.Item
              key="priceCents"
              label={<TextBodyWrapper>Price</TextBodyWrapper>}
              name="priceCents"
              rules={[{ required: true }]}
              className="row-input"
            >
              <InputNumber<number>
                defaultValue={0}
                min={0}
                max={50}
                controls={false}
                placeholder="Price"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(displayValue: string | undefined) => {
                  if (!displayValue) {
                    return 0
                  }
                  const str = displayValue.replace(/\$\s?|(,*)/g, '')
                  return parseFloat(str)
                }}
              />
            </Form.Item>
            <Form.Item
              key="currency"
              label={<TextBodyWrapper>{currencyText}</TextBodyWrapper>}
              name="currency"
              className="row-input"
            >
              <Select placeholder="CAD">
                {currency.map((c, index) => (
                  <Select.Option key={`${c}-${index}`} value={c}>
                    {c}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </RowInputWrapper>
          <Form.Item
            className="last-row"
            key="country"
            label={<TextBodyWrapper>{countryText}</TextBodyWrapper>}
            name="country"
          >
            <Select placeholder="CA">
              {countries.map((country, index) => (
                <Select.Option key={`${country}-${index}`} value={country}>
                  {country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ModalBodyWrapper>
      </Form>
    </Modal>
  )
}
