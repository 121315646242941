import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './components/layouts/authenticated/error_boundary'
import { Routes } from './components/routes/routes'
import { NetworkProvider } from './context/network'
import AppStateProvider from './state'
import { GlobalStyle } from './theme'
import './app.less'
import { Locale } from './locale'

function App() {
  return (
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <NetworkProvider>
          <AppStateProvider>
            <Locale>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Locale>
          </AppStateProvider>
        </NetworkProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
