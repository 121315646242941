import { Button } from 'antd'

import { magnifyBlackSVG } from '../../../../assets/svg/magnify'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { AdvancedSearchProps } from '.'

type fetchProps = Pick<AdvancedSearchProps, 'onFetchTransactions' | 'loading'>

interface SearchButtonProps extends fetchProps {
  disabled: boolean
}

const SearchButton: React.FC<SearchButtonProps> = ({ onFetchTransactions, loading, disabled }) => {
  const { searchText } = useGeneralTranslation()
  return (
    <Button
      size="large"
      loading={loading}
      disabled={disabled}
      type="primary"
      icon={magnifyBlackSVG}
      onClick={onFetchTransactions}
      block={true}
    >
      <span className="regular-cap">{searchText}</span>
    </Button>
  )
}

export default SearchButton
