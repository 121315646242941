import { ChargerDiagnosticsFile } from '../models/charger'
import { ChargerDiagnosticsFileResponse } from '../models/http'

export const mockChargerDiagnosticFiles = [
  {
    id: 1,
    startTime: '2022-06-24T21:10:05.000-04:00',
    stopTime: '2021-06-25T05:18:43.000Z',
    requestTime: '2021-06-25T05:18:43.000Z',
    state: 'uploaded',
    filename: 'filename_URL_here',
  },
  {
    id: 2,
    startTime: '2022-03-09T21:10:05.000-04:00',
    stopTime: '2022-06-09T22:10:10.000-04:00',
    requestTime: '2022-06-24T21:12:05.000-04:00',
    state: 'not_available',
    filename: null,
  },
] as ChargerDiagnosticsFile[]

export const mockChargerDiagnosticFilesResp = {
  data: mockChargerDiagnosticFiles,
  pagination: {
    currentPage: 1 || 0,
    perPage: 20,
    totalEntries: 423,
  },
} as ChargerDiagnosticsFileResponse
