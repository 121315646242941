import React, { useEffect, useState } from 'react'

import { Form, Modal, Space, Spin } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule } from '../../../models/peak-shaving'
import {
  CreateDailyScheduleForEvent,
  CreateNewPeakShavingEvent,
  GetAllEventSchedulesForEvent,
  GetPeakShavingEventDetails,
  PublishPeakShavingEvent,
  isPeakShavingFormEnabled,
} from '../../../services/data-provider/peak-shaving'
import { styled } from '../../../theme'
import { EventDetailsBaseForm } from '../form/event-details-base-form'

import { ButtonModal } from '../../../atom/button'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  eventId: number
  term: string
}

const CreateNewEventModalContainer = styled.div``

export const DuplicateDailyEventModal: React.FC<props> = ({ visible, onCancel, onSubmit, eventId, term }) => {
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [DailyEventDetailsForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const [peakShavingEvent, setPeakShavingEvent] = useState<PeakShavingEvent>()

  const [weekDayEventSchedules, setWeekDayEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>(
    Array.from(Array(24), (_, index) => ({
      from: index,
      to: index + 1,
      zone: 'none',
    })),
  )
  const [weekEndEventSchedules, setWeekEndEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>(
    Array.from(Array(24), (_, index) => ({
      from: index,
      to: index + 1,
      zone: 'none',
    })),
  )

  const { publishText, cancelText, duplicateEventText, dailyEventCreateSuccessText } = usePeakShavingTranslation()

  useEffect(() => {
    setDisabled(true)
    setLoading(true)
    Promise.all([GetPeakShavingEventDetails(eventId), GetAllEventSchedulesForEvent(eventId)])
      .then((resp) => {
        const [eventDetailsResponse, eventSchedulesResponse] = resp
        const newArr = eventSchedulesResponse.map(({ id, ...rest }) => {
          return rest
        })
        const updatedWeekDayArray = weekDayEventSchedules.map((initialObj) => {
          const correspondingUpdateObj = newArr.find(
            (updateObj) => updateObj.from === initialObj.from && updateObj.dayType === 'weekday',
          )

          if (correspondingUpdateObj) {
            // Merge the initial object with the corresponding update object
            const { dayType, ...rest } = correspondingUpdateObj
            return { ...initialObj, ...rest }
          } else {
            return initialObj // No update found, keep the original object
          }
        })

        const updatedWeekEndArray = weekEndEventSchedules.map((initialObj) => {
          const correspondingUpdateObj = newArr.find(
            (updateObj) => updateObj.from === initialObj.from && updateObj.dayType === 'weekend',
          )

          if (correspondingUpdateObj) {
            // Merge the initial object with the corresponding update object
            const { dayType, ...rest } = correspondingUpdateObj
            return { ...initialObj, ...rest }
          } else {
            return initialObj // No update found, keep the original object
          }
        })

        setWeekDayEventSchedules(updatedWeekDayArray)
        setWeekEndEventSchedules(updatedWeekEndArray)
        setPeakShavingEvent(eventDetailsResponse)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [eventId, term])

  const handleCancel = () => {
    DailyEventDetailsForm.resetFields()
    setDisabled(true)
    setError(undefined)
    onCancel()
  }

  const publishEventAndCreateSchedule = (event: PeakShavingEvent, fields: PeakShavingEventSchedule[]) => {
    const zones = fields
      .filter((field: any) => field.zone !== 'none')
      .map((obj) => {
        return {
          from: obj.from,
          zone: obj.zone,
          dayType: obj.dayType,
        }
      })

    Promise.all([...zones.map((field) => CreateDailyScheduleForEvent(event.id, field))])
      .then((resp) => {
        resp &&
          resp.length > 0 &&
          PublishPeakShavingEvent(event.id).then(() => {
            CustomSuccessMessage({
              key: 'CreateNewDailyEventAndSchedule',
              response: dailyEventCreateSuccessText,
            })
            onSubmit()
          })
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
  }

  const handleFormSubmit = () => {
    DailyEventDetailsForm.validateFields()
      .then((values: any) => {
        setLoading(true)
        setDisabled(true)
        const { program, zonesWeekday, zonesWeekend, participants, ...createNewScheduleFormPrams } = values

        const ZonesWeekday = DailyEventDetailsForm.getFieldValue('zonesWeekday')
        const ZonesWeekend = DailyEventDetailsForm.getFieldValue('zonesWeekend')

        const zones: PeakShavingEventSchedule[] = [
          ...ZonesWeekday.map((obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekday',
            }
          }),
          ...ZonesWeekend.map((obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekend',
            }
          }),
        ]
        Promise.all(
          participants.map((participant_id: number) =>
            CreateNewPeakShavingEvent({ ...createNewScheduleFormPrams, participant_id })
              .then((event: PeakShavingEvent) => {
                event && publishEventAndCreateSchedule(event, zones)
              })
              .catch((err: SwtchApiError) => setError(err)),
          ),
        ).finally(() => {
          setLoading(false)
          setDisabled(false)
          DailyEventDetailsForm.resetFields()
        })
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
  }

  const handleChange = () => {
    DailyEventDetailsForm.validateFields()
      .then(() => {
        setDisabled(false)
      })
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  return (
    <CreateNewEventModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{duplicateEventText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          peakShavingEvent &&
          isPeakShavingFormEnabled(peakShavingEvent) && (
            <Space>
              <ButtonModal type="ghost" icon={<></>} click={handleCancel} text={cancelText} />
              <ButtonModal
                type="primary"
                icon={<></>}
                click={handleFormSubmit}
                text={publishText}
                disabled={disabled}
              />
            </Space>
          )
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`editDailyScheduleModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        <AlertError error={error} />
        <Spin spinning={loading}>
          {peakShavingEvent && peakShavingEvent.program && !loading && (
            <EventDetailsBaseForm
              program={peakShavingEvent.program}
              form={DailyEventDetailsForm}
              disabled={!isPeakShavingFormEnabled(peakShavingEvent)}
              schedules={weekDayEventSchedules}
              weekEndSchedules={weekEndEventSchedules}
              formMode="duplicate"
              event={peakShavingEvent}
              formListName="zones"
              scheduleFrequency="daily"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </CreateNewEventModalContainer>
  )
}
