import { httpClient } from './client'
import configProvider from '../../config'

interface GoogleTimeZoneResponse {
  status: string
  timeZoneId?: string
}

const googleTimeZoneBaseUrl = 'https://maps.googleapis.com/maps/api/timezone/json'

export type TimeZoneSuccess = {
  found: true
  timeZoneId: string
}

export type TimeZoneFailure = {
  found: false
  code: string
}

export type TimeZoneResult = TimeZoneSuccess | TimeZoneFailure

const TimeZone = async (lat: number, lng: number): Promise<TimeZoneResult> => {
  const unixTimestamp = Date.now() / 1000
  const requestUrl = `${googleTimeZoneBaseUrl}?location=${lat},${lng}&timestamp=${unixTimestamp}&key=${configProvider.config.googleTimeZoneApiKey}`
  const res = await httpClient<GoogleTimeZoneResponse>(requestUrl, {})
  if (res.status === 'OK') {
    return {
      found: true,
      timeZoneId: res.timeZoneId || 'Google should have put Zone ID here',
    }
  } else {
    return {
      found: false,
      code: res.status,
    }
  }
}

export default TimeZone
