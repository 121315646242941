import { Divider, Form, InputNumber } from 'antd'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'
import { useTenantTabPricingTranslation } from '../../../hooks/translation/useTenantTabPricingTranslation'

export const ConstantsSectionModal: React.FC = () => {
  const { constantsText, paymentLimitText } = usePricingSchemasTranslation()
  const { flatFeeText } = useTenantTabPricingTranslation()

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{constantsText}</span>
      </Divider>
      <Form.Item label={<>{flatFeeText}:</>} name="flat_fee" style={{ marginBottom: '29px' }}>
        <InputNumber precision={2} controls={false} />
      </Form.Item>
      <Form.Item label={<>{paymentLimitText}:</>} name="payment_limit" style={{ marginBottom: '29px' }}>
        <InputNumber precision={2} controls={false} />
      </Form.Item>
    </>
  )
}
