import { styled, theme } from '../../theme'

interface props {
  lang: string
  isResponsiveStyling?: boolean
  isResponsiveMenuStyling?: boolean
}

export const ConsumptionInsightLoadProfileChartContainer = styled.div<props>`
  ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.download-sub-menu {
    top: ${(props) => (props.isResponsiveStyling ? '170px' : '21px')};
    right: ${(props) => (props.lang === 'fr' ? '440px' : props.isResponsiveStyling ? '8px' : '325px')};
    margin-top: ${(props) => (props.isResponsiveStyling ? '-51px' : '')};
  }

  .highcharts-tooltip-box {
    fill: ${(props) => props.theme.colors.softBlack};
    fill-opacity: 1;
    stroke-width: 0;
    width: 19px;
    height: 19px;
  }

  .highcharts-tooltip text {
    fill: white;
    color: white;
    text-shadow: 0 0 3px black;
  }

  .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: ${theme.colors.softBlack};
    stroke-dasharray: 8, 5;
  }

  .highcharts-crosshair-category {
    stroke-width: 1px;
    stroke: ${theme.colors.softBlack};
    stroke-dasharray: 8, 5;
    stroke-opacity: 1;
  }

  .highcharts-halo {
    fill-opacity: 0;
    stroke-width: 0;
  }

  .highcharts-axis-line {
    fill: #000;
    stroke: #000;
  }

  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1;
    fill: #dedede;
    stroke: #dedede;
  }

  .highcharts-root text {
    font-family: ${theme.fontFamily.regularFontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
  }

  .highcharts-tick,
  .highcharts-title {
    opacity: 0;
  }
`

export const HomePageChargerUtilization = styled.div<props>`
  ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.download-sub-menu {
    top: ${(props) => (props.isResponsiveStyling ? '90px' : '21px')};
    right: ${(props) => (props.lang === 'fr' ? '440px' : props.isResponsiveStyling ? '10px' : '310px')};
  }

  .highcharts-yaxis text.highcharts-axis-title,
  .highcharts-legend-item.highcharts-column-series.highcharts-color-0.highcharts-series-0,
  .highcharts-legend {
    display: none;
  }

  .highcharts-axis-labels {
    fill: #000000;
    stroke: #000000;
  }

  .highcharts-color-0 {
    fill: ${theme.colors.primary};
    stroke: ${theme.colors.primary};
  }

  .highcharts-axis-line {
    fill: #000;
    stroke: #000;
  }

  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1;
    fill: #dedede;
    stroke: #dedede;
  }

  .highcharts-root text {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
  }

  .highcharts-column-series .highcharts-point {
    filter: none;
  }

  .highcharts-tooltip-box {
    fill: ${(props) => props.theme.colors.softBlack};
    fill-opacity: 1;
    stroke-width: 0;
  }
`
