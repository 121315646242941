import { theme } from '../../theme'

export const boltSVG = (
  <svg width="20" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1975 0.296992C11.0934 0.113281 10.8988 0 10.6877 0H3.6564C3.38746 0 3.15343 0.183125 3.0882 0.444023L0.744449 9.85809C0.700387 10.0332 0.739879 10.2186 0.850895 10.3605C0.96191 10.5029 1.13242 10.5859 1.31265 10.5859H3.98257L0.763942 19.208C0.660934 19.4827 0.777106 19.7911 1.03515 19.9302C1.29437 20.0691 1.61507 19.9947 1.78644 19.7585L11.1614 6.82879C11.2913 6.65082 11.3096 6.41508 11.2101 6.21824C11.11 6.02199 10.908 5.8984 10.6877 5.8984H8.20715L11.19 0.887461C11.2988 0.706094 11.3016 0.480664 11.1975 0.296992Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const bolt12x24SVG = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2369 0.356391C18.1119 0.135938 17.8784 0 17.6251 0H9.18756C8.86483 0 8.584 0.21975 8.50572 0.532828L5.69322 11.8297C5.64034 12.0398 5.68773 12.2623 5.82095 12.4326C5.95417 12.6035 6.15878 12.7031 6.37506 12.7031H9.57897L5.71661 23.0497C5.593 23.3792 5.7324 23.7494 6.04206 23.9162C6.35312 24.0829 6.73797 23.9937 6.94361 23.7102L18.1936 8.19455C18.3495 7.98098 18.3715 7.69809 18.252 7.46189C18.1318 7.22639 17.8894 7.07808 17.6251 7.07808H14.6485L18.2279 1.06495C18.3584 0.847313 18.3618 0.576797 18.2369 0.356391Z"
      fill={theme.colors.primary}
    />
  </svg>
)
