import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useEffect, useState } from 'react'
import { ChargerInfo } from '../../../../models/chargerv2'

highchartsMore(Highcharts)

export const optionsActiveUsers = {
  yAxis: {
    visible: false,
  },
  xAxis: {
    visible: false,
  },

  title: {
    visible: false,
    text: undefined,
  },
  legend: {
    enabled: false,
  },
  chart: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    plotShadow: false,
    borderWidth: 0,
    plotBorderWidth: 0,
    marginRight: 0,
    size: '100%',
    styled: true,
    events: {
      render: function () {
        //@ts-ignore
        this.reflow()
      },
    },
  },

  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#fff',
      fontFamily: 'AkkuratLLWeb-Regular',
      fontSize: '11px',
      fontWeight: 700,
    },
    formatter: function (): any {
      //@ts-ignore
      return ' <b>' + this.y + '</b>'
    },
  },

  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: '0%',
          y1: '0%',
          x2: '0%',
          y2: '100%',
        },
        stops: [
          [0, 'rgba(48, 215, 186, 0.21)'],
          [0.52, 'rgba(48, 215, 186, 0.21)'],
          [1, 'rgba(48, 215, 186, 0)'],
        ],
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 2010,
      color: '#30d7ba',
    },
  },

  defs: {
    gradient0: {
      tagName: 'linearGradient',
      id: 'gradient-0',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
    gradient1: {
      tagName: 'linearGradient',
      id: 'gradient-1',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
  },

  series: [
    {
      type: 'area',
      keys: ['y', 'selected'],
      name: 'Chargers enrolled in Demand Response',
      data: [0],
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          //   maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
}

interface props {
  chargerInfo: ChargerInfo
}

export const HomePageChargersParticpatingInDemandResponseEventsChart: React.FC<props> = ({ chargerInfo }) => {
  const [options, setOptions] = useState(optionsActiveUsers)

  const [datum, setDatum] = useState<number[]>([0])

  useEffect(() => {
    if (datum.length < 5) {
      datum.push(chargerInfo.connectors.drProgram)
    } else if (datum.length === 5) {
      datum.shift()
      datum.push(chargerInfo.connectors.drProgram)
    }

    setDatum([...datum])

    setOptions({
      ...options,
      series: [
        {
          ...options.series[0],
          data: [...datum],
        },
      ],
    })
  }, [chargerInfo])

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}
