import { Button, Divider, Form, InputNumber, Select, Space, Switch } from 'antd'
import { InfoTextWrapper } from '../../../atom/price'
import { TimeContainer } from '../../../pages/peakShaving/modal/create-new-event-modal'
import { EditButtonWrapper } from '../../../atom/tenants'
import { plusSVG } from '../../../assets/svg/plus'
import { infoWithISVG } from '../../../assets/svg/info'
import { PricingSchemaEventSchedule } from '../../../models/price'
import { useDashboardTranslation } from '../../../hooks/translation/useDashboardTranslation'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'
import { FormInstance } from 'antd/es/form/Form'

interface props {
  form: FormInstance
  eventSchedules: PricingSchemaEventSchedule[]
  timeOfDay: boolean | undefined
  priceType: string
  enableCurtailmentAdjustment: boolean | undefined
  setPricePerUnit: (pricePerUnit: number) => void
  setTimeOfDay: (enableTimeOfDayPricing: boolean) => void
  handleSelect: (type: string, form: FormInstance) => void
  setEnableCurtailmentAdjustment: (enableCurtailmentAdjustment: boolean) => void
  setPriceType: (priceType: string) => void
}

export const ChargingSectionModal: React.FC<props> = ({
  form,
  timeOfDay,
  eventSchedules,
  enableCurtailmentAdjustment,
  priceType,
  setPricePerUnit,
  setTimeOfDay,
  handleSelect,
  setEnableCurtailmentAdjustment,
  setPriceType,
}) => {
  const { chargingText } = useDashboardTranslation()
  const {
    hoursNotDefinedText,
    hourText,
    toText,
    rateText,
    curtailmentAdjustmentText,
    timeOfDayText,
    weekdayRateText,
    weekendRateText,
    curtailmentAdjustmentInfoText,
  } = usePricingSchemasTranslation()

  const priceTypeSelector = (
    <Form.Item name="price_type" className="price-type">
      <Select
        className="combine-right"
        placeholder="Status"
        dropdownClassName="dropdown-large-font"
        onChange={(value) => setPriceType(value)}
      >
        <Select.Option value={'Hour'}>{hourText}</Select.Option>
        <Select.Option value={'kWh'}>kWh</Select.Option>
      </Select>
    </Form.Item>
  )

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{chargingText}</span>
      </Divider>
      <Form.Item
        className="rate-title"
        rules={[{ required: true }]}
        label={`${rateText}:`}
        name="dollar_rate"
        style={{ marginBottom: '20px' }}
      >
        <InputNumber
          addonBefore={priceTypeSelector}
          precision={2}
          controls={false}
          className="combine-left"
          onChange={(value) => setPricePerUnit(Number(value))}
        />
      </Form.Item>
      {priceType === 'Hour' && (
        <>
          <Form.Item
            label={`${curtailmentAdjustmentText}:`}
            name="enable_pricing_curtailment"
            className="enable-pricing-curtailment"
            help={curtailmentAdjustmentInfoText}
          >
            <Switch checked={enableCurtailmentAdjustment} onChange={(value) => setEnableCurtailmentAdjustment(value)} />
          </Form.Item>
        </>
      )}
      <div style={{ display: 'flex' }}>
        <Form.Item label={`${timeOfDayText}:`} name="enable_time_of_day" style={{ marginBottom: 0 }}>
          <Switch checked={timeOfDay} onChange={(value) => setTimeOfDay(value)} />
        </Form.Item>
        {timeOfDay && (
          <InfoTextWrapper>
            {infoWithISVG} {hoursNotDefinedText}
          </InfoTextWrapper>
        )}
      </div>
      {timeOfDay && (
        <>
          <Form.List name="weekdayRateExtras">
            {(fields, { add }) => (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginTop: key === 0 ? '20px' : '0px' }}>
                      <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                        <Form.Item
                          name={[key, 'dollar_rate']}
                          label={key === 0 ? <>{weekdayRateText}:</> : ''}
                          style={{ marginLeft: key === 0 ? '' : '131px' }}
                        >
                          <InputNumber precision={2} controls={false} />
                        </Form.Item>
                        <TimeContainer>
                          <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                            <Select
                              style={{ width: '141px' }}
                              onSelect={() => {
                                handleSelect('weekdayRateExtras', form)
                              }}
                              dropdownClassName="dropdown-large-font"
                            >
                              {eventSchedules.map((eventSchedule, _) => {
                                return (
                                  <Select.Option
                                    value={eventSchedule.from}
                                    key={
                                      eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`
                                    }
                                  >
                                    {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </TimeContainer>
                        <div style={{ height: '38px', display: 'flex', alignItems: 'center' }}>{toText}</div>
                        <TimeContainer>
                          <Form.Item name={[key, 'to']}>
                            <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                              {eventSchedules.map((eventSchedule, _) => {
                                return (
                                  <Select.Option
                                    value={eventSchedule.to}
                                    key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                  >
                                    {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                  </Select.Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </TimeContainer>
                      </Space>
                    </div>
                  ))}
                </div>
                <Form.Item>
                  <EditButtonWrapper>
                    <Button
                      className="add-rate-button"
                      type="primary"
                      shape="circle"
                      icon={plusSVG}
                      onClick={add}
                      loading={false}
                      disabled={false}
                    />
                  </EditButtonWrapper>
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Form.List name="weekendRateExtras">
            {(fields, { add }) => (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8, marginTop: 0 }} align="baseline">
                      <Form.Item
                        name={[key, 'dollar_rate']}
                        label={key === 0 ? <>{weekendRateText}:</> : ''}
                        style={{ marginLeft: key === 0 ? '' : '131px' }}
                      >
                        <InputNumber precision={2} controls={false} />
                      </Form.Item>
                      <TimeContainer>
                        <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                          <Select
                            onSelect={() => {
                              handleSelect('weekendRateExtras', form)
                            }}
                            style={{ width: '141px' }}
                            dropdownClassName="dropdown-large-font"
                          >
                            {eventSchedules.map((eventSchedule, _) => {
                              return (
                                <Select.Option
                                  value={eventSchedule.from}
                                  key={
                                    eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`
                                  }
                                >
                                  {eventSchedule.from < 10 ? `0${eventSchedule.from}:00` : `${eventSchedule.from}:00`}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </TimeContainer>
                      {toText}
                      <TimeContainer>
                        <Form.Item name={[key, 'to']}>
                          <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                            {eventSchedules.map((eventSchedule, _) => {
                              return (
                                <Select.Option
                                  value={eventSchedule.to}
                                  key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                >
                                  {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </TimeContainer>
                    </Space>
                  ))}
                </div>
                <Form.Item>
                  <EditButtonWrapper>
                    <Button
                      className="add-rate-button"
                      type="primary"
                      shape="circle"
                      icon={plusSVG}
                      onClick={add}
                      loading={false}
                      disabled={false}
                    />
                  </EditButtonWrapper>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </>
      )}
    </>
  )
}
