import { Cascader } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { Option, locations } from '../../helpers/location'
import { useAppState } from '../../state'

interface props {
  onSearch: (chosenCountries: string[], chosenProvinces: string[]) => void
}

export const LocationSelector: React.FC<props> = ({ onSearch }) => {
  const { currentUser } = useAppState()

  const onChange = (selectedLocations: any) => {
    const selectedCountries: string[] = []
    const selectedProvinces: string[] = []
    selectedLocations.forEach((selectedLocation: [string, string]) => {
      const [country, province] = selectedLocation
      !selectedCountries.includes(country) && selectedCountries.push(country)

      // when only the country clicked, the provinces are not selected
      if (!province) {
        const childrenObj: any = locations.filter((t) => t.value === country).map((t) => t.children)
        const allProvinces = childrenObj[0].map((t: Option) => t.value)
        selectedProvinces.push(...allProvinces)
      } else {
        selectedProvinces.push(province)
      }
    })

    onSearch(selectedCountries, selectedProvinces)
  }

  return currentUser?.role === 'admin' ? (
    <Cascader
      placeholder={renderFormatMessage('dashboard.text.location', 'Location')}
      options={locations}
      allowClear
      multiple
      showSearch
      onChange={onChange}
      style={{ width: 159, minWidth: '100%' }}
      maxTagCount="responsive"
    />
  ) : (
    <></>
  )
}

// TODO: look at https://ant.design/components/cascader/#components-cascader-demo-showCheckedStrategy to get all nested values
