import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'

import { AlertErrorCompact } from '../../error-compact'
import { useFormatMessage } from '../../../helpers/intl'
import { SwtchError } from '../../../models/error'
import { Plan } from '../../../models/plan'
import { layout } from '../../../atom/form/modal-layout'
import { UpdatePlan } from '../../../services/data-provider/plan'

interface props {
  plan: Plan
  onCancel: () => void
  onUpdatedPlan: (p: Plan) => void
}

interface FormFields {
  name: string
  description: string
}

export const UpdatePlanForm: React.FC<props> = ({ plan, onCancel, onUpdatedPlan }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const afterClose = () => form.resetFields()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    form.validateFields().then((values: FormFields) => {
      const { name, description } = values
      UpdatePlan(plan, name, description)
        .then((p) => onUpdatedPlan(p))
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    })
  }

  const okText = useFormatMessage('dashboard.text.update', 'Update')
  const cancelText = useFormatMessage('dashboard.text.cancel', 'Cancel')

  return (
    <Modal
      title={`${useFormatMessage('dashboard.text.edit', 'Edit')} ${plan.name}`}
      closable={false}
      okText={okText}
      cancelText={cancelText}
      visible={true}
      onCancel={() => onCancel()}
      onOk={onOk}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={[
        <Button key="back" loading={loading} onClick={onCancel}>
          {cancelText}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          {okText}
        </Button>,
      ]}
    >
      <AlertErrorCompact error={error} />
      <Form
        {...layout}
        form={form}
        initialValues={{
          name: plan.name,
          interval: plan.interval,
          description: plan.description,
          priceCents: plan.priceCents / 100 || 0,
          currency: plan.currency,
        }}
      >
        <Form.Item
          key="name"
          label={useFormatMessage('dashboard.text.name', 'Name')}
          name="name"
          rules={[{ required: true, type: 'string', message: 'A name is required!' }]}
        >
          <Input
            disabled={loading}
            type="text"
            placeholder={useFormatMessage('dashboard.reports.form.sendReport.emails.label', 'Emails')}
          />
        </Form.Item>
        <Form.Item
          key="description"
          label={useFormatMessage('dashboard.text.description', 'Description')}
          name="description"
          rules={[{ required: true, type: 'string', message: 'A description is required!' }]}
        >
          <Input placeholder="Description" type="text" disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
