import React from 'react'

import GHGCalculation from '../../assets/images/ghg-equation.png'
import { Button, Modal } from 'antd'
import { useFormatMessage } from '../../helpers/intl'

interface props {
  visible: boolean
  onClose: () => void
}

export const GhgInfoModal: React.FC<props> = ({ visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      closable={false}
      width="1000px"
      footer={
        <Button type="primary" onClick={() => onClose()}>
          <span className="paragraph-02-regular">
            {useFormatMessage('dashboard.homePage.energyConsumptions.info.okbtn', 'Ok')}
          </span>
        </Button>
      }
      className="ghg-calcuation-modal"
    >
      <h3 className="heading-06-regular">
        {useFormatMessage(
          'dashboard.homePage.energyConsumptions.info.p1',
          'How are my greenhouse gas savings computed?',
        )}
      </h3>
      <br />
      <img src={GHGCalculation} alt="ghg calculation sample" style={{ width: '100%' }} />
    </Modal>
  )
}
