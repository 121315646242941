import { Form, Modal } from 'antd'
import { Tenant } from '../../../models/tenant'
import { StepperTitle } from './stepper-title'
import CreateDiscountSchemaModal from './create-discount-schema-modal'
import { useEffect, useState } from 'react'
import { PricingSchema, PricingSchemaPayload } from 'models/price'
import SelectPricingSchemaModal from './select-pricing-schema-modal'
import { useDiscountSchemas } from 'hooks/useDiscountSchemas'
import { DiscountSchema } from 'models/discount'

interface props {
  tenant: Tenant
  discounts: DiscountSchema[]
  pricingSchemaData: PricingSchema[] | undefined
  onCancel: () => void
  onOk: () => void
}

const CreateDiscountOnlySchemaModal: React.FC<props> = ({ tenant, discounts, pricingSchemaData, onCancel, onOk }) => {
  const [form] = Form.useForm()
  const afterClose = () => form.resetFields()
  const [pricingSchemaValues, setPricingSchemaValues] = useState<PricingSchemaPayload>()
  const {
    loading,
    current,
    nextInModal,
    pricingSchemaName,
    next,
    setNextInModal,
    convertPricingSchemaToPayload,
    setPricingSchemaName,
    back,
  } = useDiscountSchemas(
    tenant,
    onOk,
    onCancel,
    pricingSchemaValues,
    discounts,
    pricingSchemaValues?.applied_listings ? pricingSchemaValues?.applied_listings : [],
    form,
    'discount-only',
  )

  useEffect(() => {
    pricingSchemaData?.map((pricingSchema) => {
      if (pricingSchema.name === pricingSchemaName) {
        return setPricingSchemaValues(convertPricingSchemaToPayload(pricingSchema, 'discount-only'))
      }
      return pricingSchema
    })
  }, [current])

  return (
    <Modal
      title={<StepperTitle current={current} />}
      visible={true}
      onCancel={onCancel}
      width={1034}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={null}
    >
      {current === 0 ? (
        <SelectPricingSchemaModal
          tenant={tenant}
          current={current}
          nextInModal={nextInModal}
          pricingSchemaName={pricingSchemaName}
          onCancel={onCancel}
          onOk={onOk}
          next={next}
          setPricingSchemaValues={setPricingSchemaValues}
          setNextInModal={setNextInModal}
          setPricingSchemaName={setPricingSchemaName}
          convertPricingSchemaToPayload={convertPricingSchemaToPayload}
        />
      ) : (
        <CreateDiscountSchemaModal
          tenant={tenant}
          listings={pricingSchemaValues?.applied_listings ? pricingSchemaValues?.applied_listings : []}
          pricingSchemaValues={pricingSchemaValues}
          discounts={discounts}
          modalType="discount-only"
          onCancel={onCancel}
          onOk={onOk}
          back={back}
        />
      )}
    </Modal>
  )
}

export default CreateDiscountOnlySchemaModal
