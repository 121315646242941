import { Space } from 'antd'
import { theme } from '../../theme'

export const globeSVG = (
  <Space>
    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon">
      <path
        d="M5.657 4.24296L1.414 8.48596L0 7.07096L2.829 4.24296L0 1.41496L1.414 -3.8147e-05L5.657 4.24296Z"
        fill="#09121F"
      />
    </svg>

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="globe">
      <g clipPath="url(#clip0_1721_582)">
        <path
          d="M12.6525 11.25C12.75 10.5 12.75 9.75 12.75 9C12.75 8.25 12.7125 7.5 12.6525 6.75H16.9275C17.3575 8.21918 17.3575 9.78082 16.9275 11.25H12.6525ZM10.9725 5.25C10.5 2.3175 9.5625 0.75 9 0.75C8.4375 0.75 7.5 2.3175 7.0275 5.25H10.9725ZM7.0275 12.75C7.4925 15.6825 8.4375 17.25 9 17.25C9.5625 17.25 10.5 15.6825 10.9725 12.75H7.0275ZM11.1525 6.75H6.8475C6.7875 7.4475 6.75 8.19 6.75 9C6.75 9.81 6.7875 10.5525 6.8475 11.25H11.1525C11.2125 10.5525 11.25 9.81 11.25 9C11.25 8.19 11.25 7.4475 11.1525 6.75ZM5.25 9C5.25 8.25 5.25 7.5 5.3475 6.75H1.0725C0.642499 8.21918 0.642499 9.78082 1.0725 11.25H5.3475C5.2875 10.5 5.25 9.75 5.25 9ZM5.52 12.75H1.6575C2.17472 13.7573 2.89377 14.6474 3.76988 15.3649C4.646 16.0823 5.66043 16.6116 6.75 16.92C6.12165 15.6039 5.70647 14.1964 5.52 12.75ZM12.48 12.75C12.2935 14.1964 11.8784 15.6039 11.25 16.92C12.3396 16.6116 13.354 16.0823 14.2301 15.3649C15.1062 14.6474 15.8253 13.7573 16.3425 12.75H12.48ZM12.48 5.25H16.3425C15.8253 4.24265 15.1062 3.35256 14.2301 2.63515C13.354 1.91774 12.3396 1.38837 11.25 1.08C11.8784 2.39605 12.2935 3.80361 12.48 5.25ZM5.52 5.25C5.70647 3.80361 6.12165 2.39605 6.75 1.08C5.66043 1.38837 4.646 1.91774 3.76988 2.63515C2.89377 3.35256 2.17472 4.24265 1.6575 5.25H5.52Z"
          fill={theme.colors.softBlack}
        />
      </g>
      <defs>
        <clipPath id="clip0_1721_582">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Space>
)
