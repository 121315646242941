import { Col, Row } from 'antd'

import { TransactionMetric } from '../../../../models/analytics'

import TotalTransactions from './TotalTransactions'
import TotalEnergy from './TotalEnergy'
import TotalRevenue from './TotalRevenue'
import TotalUniqueUsers from './TotalUniqueUsers'

interface TransactionStatsProps {
  metrics?: TransactionMetric
  loading: boolean
}

const StatsComponents = [TotalTransactions, TotalEnergy, TotalRevenue, TotalUniqueUsers]

const StatsGroup = ({ metrics, loading }: TransactionStatsProps) => {
  return (
    <Row gutter={[16, 8]}>
      {StatsComponents.map((Stats, index) => (
        <Col xs={24} sm={24} md={12} lg={6} key={index}>
          <Stats metrics={metrics} loading={loading} />
        </Col>
      ))}
    </Row>
  )
}

export default StatsGroup

// ! This widget is not used in the current version of the app
// const TotalPlugInDuration: React.FC<props> = ({ metrics, loading }) => {
//   const { totalChargingDurationText } = useTransactionMetricTranslation()
//   return (
//     <Widget
//       loading={loading}
//       title={totalChargingDurationText}
//       removeTitleBorderBottom
//       bodyPadding
//       header={
//         <StatisticValue
//           prefix={clock24x24SVG}
//           value={formatDuration(moment.duration(metrics?.duration || 0, 'seconds'))}
//           valueStyle={valueStyle}
//         />
//       }
//     />
//   )
// }
