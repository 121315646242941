import { theme } from '../../theme'

export const greyInfoSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18.5C4.0293 18.5 0 14.4707 0 9.5C0 4.5293 4.0293 0.5 9 0.5C13.9707 0.5 18 4.5293 18 9.5C18 14.4707 13.9707 18.5 9 18.5ZM8.1 12.2V14H9.9V12.2H8.1ZM8.1 5V10.4H9.9V5H8.1Z"
      fill={theme.colors.lightGray}
    />
  </svg>
)
