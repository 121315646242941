import { Box } from '../../atom/box'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { useAppState } from '../../state'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import configProvider from '../../config'
import Iframe from '../../components/iframe'

const SupportDashboardBasePage: React.FC = () => {
  const { IsLaptop, IsDesktop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const renderReportsView = () => {
    if (isDesktop || isLaptop) {
      return renderIFrame(720)
    } else {
      return renderIFrame(500)
    }
  }
  const renderIFrame = (height: number) => {
    return (
      <Box>
        <Iframe title="suppprt-dashboard-report" url={configProvider.config.supportDashboardDomain} height={height} />
      </Box>
    )
  }
  return <BackgroundContainer>{renderReportsView()}</BackgroundContainer>
}
export const SupportDashboardPage = withAuthenticatedLayout(SupportDashboardBasePage)
