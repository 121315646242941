import moment from 'moment'

import configProvider from '../../config'
import { log } from '../../logger'
import {
  RemoteClearCachePayload,
  RemoteGetConfigPayload,
  RemoteMultiChargerTrigMessPayload,
  RemoteRestartChargerPayload,
  RemoteUpdateConfigPayload,
  RemoteUpdateFirmwarePayload,
} from '../../mock/charger-remote-mock'
import { Charger } from '../../models/charger'
import {
  RemoteCommandResponse,
  RemoteGetConfigurationResponse,
  RemoteMultiChargerTriggerMessageParams,
  RemoteMultiConnectorPayloadResponse,
  RemoteMultiUpdateResponse,
} from '../../models/charger-remote'
import { ConnectorRef } from '../../models/connector'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function RemoteRestartCharger(charger_ids: number[]): Promise<RemoteMultiUpdateResponse> {
  const url = `/chargers/remote/restart`
  log('remote restarting charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      passed: 2,
      failed: 1,
      payload: RemoteRestartChargerPayload,
      command: 'restart',
      sentAt: moment().format(),
    } as RemoteMultiUpdateResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: {
        charger_ids: charger_ids,
      },
    }),
  })
}

export async function RemoteStartCharger(charger: Charger, connector: ConnectorRef): Promise<RemoteCommandResponse> {
  const url = `/chargers/${charger.id}/remote/start`
  log('remote start charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      command: 'start',
      success: true,
      sentAt: moment().format(),
    } as RemoteCommandResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      connector_id: connector.id,
    }),
  })
}

export async function RemoteStopCharger(charger: Charger, connector: ConnectorRef): Promise<RemoteCommandResponse> {
  const url = `/chargers/${charger.id}/remote/stop`
  log('remote stop charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      command: 'stop',
      success: true,
      sentAt: moment().format(),
    } as RemoteCommandResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      connector_id: connector.id,
    }),
  })
}

export async function RemoteClearCache(charger_ids: number[]): Promise<RemoteMultiUpdateResponse> {
  const url = `/chargers/remote/clear_cache`
  log('remote clear cache charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      passed: 2,
      failed: 1,
      payload: RemoteClearCachePayload,
      command: 'clear_cache',
      sentAt: moment().format(),
    } as RemoteMultiUpdateResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: {
        charger_ids: charger_ids,
      },
    }),
  })
}

export async function RemoteGetConfiguration(charger: Charger): Promise<RemoteGetConfigurationResponse> {
  const url = `/chargers/${charger.id}/remote/get_configuration`
  log('remote get configuration charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      command: 'get_configuration',
      success: true,
      sentAt: moment().format(),
      message: RemoteGetConfigPayload,
    } as RemoteGetConfigurationResponse)
  }

  return apiClient(url, { method: 'POST' })
}

export async function RemoteTriggerMessage(data: {
  charger: Charger
  message: string
  connector: ConnectorRef
}): Promise<RemoteCommandResponse> {
  const url = `/chargers/remote/trigger`
  log('remote trigger message', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      command: 'trigger_message',
      success: true,
      sentAt: moment().format(),
      message: [3, 'yfzt95qqi1czvsbusaub4', { status: 'Accepted' }],
    } as RemoteCommandResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: data,
    }),
  })
}

export async function RemoteMultiChargerTriggerMessage(data: {
  charger_params: RemoteMultiChargerTriggerMessageParams[]
  message: string
}): Promise<RemoteMultiConnectorPayloadResponse> {
  const url = `/chargers/remote/trigger`
  log('remote trigger message', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      passed: 2,
      failed: 1,
      payload: RemoteMultiChargerTrigMessPayload,
      command: 'trigger_message',
      sentAt: '2022-04-21T21:07:55+05:00',
    } as RemoteMultiConnectorPayloadResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: data,
    }),
  })
}

export async function RemoteUpdateFirmware(data: {
  charger_ids: number[]
  location: string
  date: string
}): Promise<RemoteMultiUpdateResponse> {
  const url = `/chargers/remote/updatefirmware`
  log('remote update firmware', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      passed: 1,
      failed: 2,
      payload: RemoteUpdateFirmwarePayload,
      command: 'update_config',
      sentAt: moment().format(),
    } as RemoteMultiUpdateResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: data,
    }),
  })
}

export async function RemoteUpdateConfig(data: {
  charger_ids: number[]
  key: string
  value: string
}): Promise<RemoteMultiUpdateResponse> {
  const url = `/chargers/remote/updateconfig`
  log('remote update firmware', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({
      passed: 1,
      failed: 2,
      payload: RemoteUpdateConfigPayload,
      command: 'update_config',
      sentAt: moment().format(),
    } as RemoteMultiUpdateResponse)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      remote: data,
    }),
  })
}
