import { Button, message, Modal } from 'antd'
import { useState } from 'react'
import { SwtchError } from '../../../models/error'
import { Plan } from '../../../models/plan'
import { ToggleActivePlan } from '../../../services/data-provider/plan'
import { AlertError } from '../../error'
import { FooterLayout } from '../../../atom/user-edit'
import { HeadingWrapper, TextBodyWrapper } from '../../../atom/plans'
import { usePlanTranslation } from '../../../hooks/translation/usePlanTranslation'

interface props {
  plan: Plan
  onCancel: () => void
  onTogglePlan: (p: Plan) => void
}

export const DeletePlanRedesignModal: React.FC<props> = ({ plan, onCancel, onTogglePlan }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const { cancelText, archiveMessage, unarchiveMessage } = usePlanTranslation()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    ToggleActivePlan(plan)
      .then((response) => {
        message.success(response.message)
        onTogglePlan(response.data)
      })
      .catch((error: SwtchError) => setError(error))
      .finally(() => setLoading(false))
  }

  const toggleTitle = plan.status === 'active' ? 'Archive Plan' : 'Unarchive Plan'
  const toggleText = plan.status === 'active' ? archiveMessage : unarchiveMessage

  return (
    <Modal
      title={<HeadingWrapper>{toggleTitle}</HeadingWrapper>}
      closable={true}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={onCancel}
      footer={
        <FooterLayout>
          <Button key="back" loading={loading} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button className="red" key="submit" type="primary" danger loading={loading} onClick={onOk}>
            {toggleTitle}
          </Button>
        </FooterLayout>
      }
    >
      <AlertError error={error} />
      <TextBodyWrapper>
        {toggleText} {plan.name}?
      </TextBodyWrapper>
    </Modal>
  )
}
