import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingAccessTranslation = () => {
  const mapAndAccess = renderFormatMessage('dashboard.text.map&access', 'Map & Access')
  const accessControlText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.txt',
    'Access Control',
  )
  const publicOpenText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.publicOption',
    'Open',
  )
  const privateRestrictedText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.privateOption',
    'Restricted',
  )
  const hiddenMapText = useFormatMessage(
    'dashboard.createNewListing.listingStep.hiddenCategory.txt',
    'Listings hidden from map',
  )

  const carRequiredText = useFormatMessage(
    'dashboard.createNewListing.listingStep.carDetailsCategory.txt',
    'Users need car details to book',
  )

  const carSettingsText = useFormatMessage('dashboard.listingAccess.carSettingsText', 'Car Settings')

  const noCarDetailsRequiredText = useFormatMessage(
    'dashboard.listingAccess.noCarDetailsRequiredText',
    'No Car Details Required',
  )

  const carDetailsRequiredText = useFormatMessage(
    'dashboard.listingAccess.carDetailsRequiredText',
    'Car Details Required',
  )

  const mapSettingsText = useFormatMessage('dashboard.listingAccess.mapSettingsText', 'Map Settings')

  const hideListingText = useFormatMessage('dashboard.listingAccess.hideListingText', 'Hide Listing on Map')

  const displayListingText = useFormatMessage('dashboard.listingAccess.displayListingText', 'Display Listing on Map')

  return {
    mapAndAccess,
    accessControlText,
    publicOpenText,
    privateRestrictedText,
    hiddenMapText,
    carRequiredText,
    carSettingsText,
    noCarDetailsRequiredText,
    carDetailsRequiredText,
    mapSettingsText,
    hideListingText,
    displayListingText,
  }
}
