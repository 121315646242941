import { forwardRef } from 'react'
import { Input } from 'antd'
import { NoSearchIcon } from '../../atom/search-icon'

interface props {
  value?: string
  svg: JSX.Element
  placeholder: string
  className?: string
  setValue?: (value: string) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onPressEnter?: () => void
  onSearch?: (value: string) => void
  suffix?: React.ReactNode
}

const { Search } = Input

export const NoIconSearch = forwardRef((props: props, ref: any) => {
  const { svg, placeholder, className, value, onChange, onPressEnter, onSearch, suffix } = props
  return (
    <NoSearchIcon>
      <Search
        ref={ref}
        allowClear
        value={value}
        className={className ? className : ''}
        prefix={svg}
        placeholder={placeholder}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onSearch={onSearch}
        suffix={suffix}
      />
    </NoSearchIcon>
  )
})
