import { useState } from 'react'
import { Divider, Form, FormInstance, Radio, Space, Switch } from 'antd'

import { useListingAccessTranslation } from '../../hooks/translation/useListingAccessTranslation'

interface props {
  form: FormInstance<any>
}

const BaseListingAccessVisibility: React.FC<props> = ({ form }) => {
  const [accessType, setAccessType] = useState(form.getFieldValue('accessType'))

  const {
    mapAndAccess,
    accessControlText,
    publicOpenText,
    privateRestrictedText,
    hiddenMapText,
    carRequiredText,
  } = useListingAccessTranslation()
  return (
    <>
      <Divider>{mapAndAccess}</Divider>
      <Form.Item label={accessControlText} name="accessType" rules={[{ required: true }]}>
        <Radio.Group value={accessType} onChange={(e) => setAccessType(e.target.value)} buttonStyle="solid">
          <Space wrap>
            <Radio.Button value="public" className="radio-button">
              {publicOpenText}
            </Radio.Button>
            <Radio.Button value="private" className="radio-button">
              {privateRestrictedText}
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={hiddenMapText} name="hidden" valuePropName="checked">
        <Switch style={{ float: 'left' }} />
      </Form.Item>
      <Form.Item label={carRequiredText} name="carDetailRequired" valuePropName="checked">
        <Switch style={{ float: 'left' }} />
      </Form.Item>
    </>
  )
}

export const ListingAccessVisibility = BaseListingAccessVisibility
