import { useEffect, useState } from 'react'

import { Charger } from '../models/charger'
import { Connector } from '../models/connector'
import { SwtchError } from '../models/error'
import { GetCharger } from '../services/data-provider/charger'
import { useAppState } from '../state'
import { GetChargerTroubleShootPermission } from 'services/data-provider/tenants'

export const useCharger = (chargerId: number, connectorId: number) => {
  const { selectedTenant: defaultTenant } = useAppState()

  const [chargerLoading, setChargerLoading] = useState(false)
  const [charger, setCharger] = useState<Charger>()
  const [connector, setConnector] = useState<Connector | undefined>()
  const [title, setTitle] = useState<string | undefined>('')
  const [chargerError, setChargerError] = useState<SwtchError>()
  const [canTroubleShoot, setCanTroubleShoot] = useState(false)

  useEffect(() => {
    setChargerLoading(true)
    GetCharger(chargerId, defaultTenant)
      .then((charger) => {
        checkIfChargerHasConnector(charger, connectorId)
        setCharger(charger)
        if (charger.tenant?.id) {
          GetChargerTroubleShootPermission(charger.tenant?.id)
            .then((permission) => {
              setCanTroubleShoot(permission.operate_tenant)
            })
            .catch((err: SwtchError) => setChargerError(err))
        }
      })
      .catch((err: SwtchError) => setChargerError(err))
      .finally(() => setChargerLoading(false))
  }, [])

  const handleSelectConnector = (connId: string | number) => {
    if (!charger) {
      return
    }
    if (connId === '') {
      setConnector(undefined)
      setTitle(undefined)
    } else {
      charger.connectors.forEach((conn) => {
        if (connId === conn.id) {
          setConnector(conn)
          setTitle(conn.listing?.title)
        }
      })
    }
  }

  const checkIfChargerHasConnector = (charger: Charger, connectorId: number) => {
    if (charger.connectors.length === 0) {
      setConnector(undefined)
      setTitle(undefined)
      return
    }

    return hasConnector(charger, connectorId)
  }

  const hasConnector = (charger: Charger, connectorId: number) => {
    if (charger.connectors.length !== 0) {
      const foundMatchingConnector = charger.connectors.find((conn) => conn.id === Number(connectorId))

      if (foundMatchingConnector) {
        setTitle(foundMatchingConnector.listing?.title)
        setConnector(foundMatchingConnector)
        return
      }

      const hasConnectorWithListing = charger.connectors.map((connector) => connector.listing?.title)

      if (hasConnectorWithListing) {
        setTitle(hasConnectorWithListing[0])
        setConnector(charger.connectors[0])
        return
      }
    }
  }

  return { chargerLoading, chargerError, charger, connector, title, handleSelectConnector, canTroubleShoot }
}
