import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Links } from '../../components/routes/paths'
import { useAppState } from '../../state'
import queryString from 'query-string'
import { AsyncComponent } from '../../atom/async-component/async-component'
import { SwtchError } from '../../models/error'
import { LoggedInPath } from '../../components/routes/routes'
import { JWTFromAuth0 } from '../../services/data-provider/auth'
import { Button, Result } from 'antd'
import { Loading } from '../../atom/loader'
import { log } from '../../logger'

const onSuccessRedirection = (redirectUrl: string = LoggedInPath) => {
  log('auth login completed correctly, redirecting to user dashboard.', {
    redirectUrl: redirectUrl,
  })
  return <Redirect to={redirectUrl} />
}

const onLoading = (): React.ReactNode => {
  return <Loading size={'large'} />
}

export function Auth0CallbackFunc(props: RouteComponentProps) {
  const { auth0, login, logout } = useAppState()
  const { redirect_path } = queryString.parse(props.location.search)

  const handleAuthentication = async (props: RouteComponentProps) => {
    if (/access_token|id_token|error/.test(props.location.hash)) {
      await handleAuth0hash(props.location.hash)
      return
    } else {
      throw new Error('Invalid callback parameters')
    }
  }

  const handleAuth0hash = async (hash: string) => {
    return new Promise((resolve, reject) => {
      auth0.parseHash({ hash: props.location.hash }, function (err, authResult) {
        log('completed handling of Auth0 callback url decoding.', {
          authResult: authResult,
          error: err,
        })
        if (authResult?.idToken) {
          JWTFromAuth0(authResult.idToken)
            .then((jwt) => {
              log('completed handling of Auth0 callback url decoding.')
              login(jwt)
              resolve(Links.onboard())
              // history.push(LoggedInPath)
            })
            .catch((error: SwtchError) => {
              log('Unable to retrieve token from auth0 code', {
                token: authResult?.idToken,
                err: error.messages,
              })
              reject(error.messages)
            })
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  const onError = (err: any): React.ReactNode => {
    log('unable to correctly retrieve Auth0 authentication credentials from URL.')
    return (
      <Result
        status="500"
        title="Oops something went wrong"
        subTitle="We were unable to log you in. Try again"
        extra={
          <Button
            type="primary"
            onClick={() => {
              logout()
              window.location.replace(Links.auth0login())
            }}
          >
            Back to login
          </Button>
        }
      />
    )
  }

  return (
    <AsyncComponent
      id={'auth0-'}
      worker={() => handleAuthentication(props) as any}
      onLoading={onLoading}
      onSuccess={() => onSuccessRedirection(redirect_path as string)}
      onError={onError}
    />
  )
}
