import { Button, Col, PageHeader, Row, Skeleton, Space, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { SwtchError } from '../../models/error'

import _ from 'lodash'
import { PeakShavingProgram, PeakShavingScheduleFrequency } from '../../models/peak-shaving'

import styled from 'styled-components'
import { rightArrowSVG } from '../../assets/svg/arrow'
import { PeakShavingAddIconSVG } from '../../assets/svg/peak-shaving-add-icon'
import { plusSignSVG } from '../../assets/svg/plusSign'
import { GetPeakShavingProgram, publishProgram } from '../../services/data-provider/peak-shaving'
import { theme } from '../../theme'
import { ArchiveProgramModal } from './modal/archieve-program-modal'
import { CreateNewDailyScheduleModal } from './modal/create-new-daily-schedule-modal'
import { CreateNewEventModal } from './modal/create-new-event-modal'
import { EventDetailsModal } from './modal/event-details-modal'

import { Store } from 'antd/lib/form/interface'
import { CustomSuccessMessage } from '../../components/peakShaving/message'
import { Links } from '../../components/routes/paths'
import { usePeakShavingTranslation } from '../../hooks/translation/usePeakShavingTranslation'
import { DailyScheduleDetailsForm } from './form/daily-schedule-details-form'
import { ProgramDetailsForm } from './form/program-details-form'
import { AddParticipantModal } from './modal/add-participant-modal'
import { CancelEventModal } from './modal/cancel-event-modal'
import { DuplicateDailyEventModal } from './modal/duplicate-daily-event-modal'
import { DuplicateEventModal } from './modal/duplicate-event-modal'
import { EditDailyScheduleModal } from './modal/edit-daily-schedule-modal'
import { EventsTable } from './table/events-table'
import { ProgramListingsTable } from './table/program-listings-table'

const ProgramPageContainer = styled.div`
  .ant-page-header.ant-page-header-ghost {
    padding-left: 0;
    padding-top: 0;
  }
  .add-listings-icon {
    cursor: pointer;
  }
  .ant-page-header {
    background-color: unset !important;
  }
`

const PageHeaderContainer = styled.div`
  min-width: 350px;

  svg {
    height: 8px;
  }
  .ant-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    color: ${theme.colors.softBlack} !important;
  }
`

const PeakShavingEventBasePage: React.FC = () => {
  //@ts-ignore
  const { programId } = useParams()
  document.querySelector('body')?.classList.add('redesignActive')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [addListingsToProgramModalVisible, setAddListingsToProgramModalVisible] = useState(false)
  const [createNewScheduleModalVisible, setCreateNewScheduleModalVisible] = useState(false)
  const [createNewEventModalVisible, setCreateNewEventModalVisible] = useState(false)
  const [archiveProgramModalVisible, setArchiveProgramModalVisible] = useState(false)
  const [editDailyScheduleModalVisible, setEditDailyScheduleModalVisible] = useState(false)

  const [program, setProgram] = useState<PeakShavingProgram>()
  const [eventDetailModalVisible, setEventDetailModalVisible] = useState(false)
  const [duplicateEventModalVisible, setDuplicateEventModalVisible] = useState(false)
  const [duplicateDailyEventModalVisible, setDuplicateDailyEventModalVisible] = useState(false)
  const [cancelEventModalVisible, setCancelEventModalVisible] = useState(false)

  const [eventId, setEventId] = useState<number>()
  const [manualEventId, setManualEventId] = useState<number>()
  const [manualDuplicateEventId, setManualDuplicateEventId] = useState<number>()
  const [duplicateEventId, setDuplicateEventId] = useState<number>()

  const [cancelEventId, setCancelEventId] = useState<number>()
  const [term, setTerm] = useState(_.uniqueId())
  const [programListingsTerm, setProgramListingsTerm] = useState(_.uniqueId())
  const [eventDetailsTerm, setEventDetailsTerm] = useState(_.uniqueId())
  const [publishButtonDisabled, setPublishedButtonDisabled] = useState(true)

  const [participantToEdit, setParticipantToEdit] = useState<Store>()

  const history = useHistory()

  const {
    programText,
    newEventText,
    archiveText,
    programDetailsText,
    eventsText,
    publishProgramText,
    participantsText,
  } = usePeakShavingTranslation()

  useEffect(() => {
    setLoading(true)
    GetPeakShavingProgram(programId)
      .then((program) => {
        setProgram(program)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [programId, term])

  useEffect(() => {
    setAddListingsToProgramModalVisible(!!participantToEdit)
  }, [participantToEdit])

  useEffect(() => {
    if (!addListingsToProgramModalVisible) {
      setParticipantToEdit(undefined)
    }
  }, [addListingsToProgramModalVisible])

  const onClickPublishProgram = () => {
    setLoading(true)
    publishProgram(programId)
      .then(({ response }) => {
        CustomSuccessMessage({ key: 'publishProgramMessageKey', response: response })
        setTerm(_.uniqueId())
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const programDetailsContent = () => (
    <>
      {program && <ProgramDetailsForm program={program} setProgram={setProgram} />}
      <Row style={{ marginTop: '28px', marginBottom: '8px' }}>
        <Col span={24}>
          <Space>
            <span className="heading-05-regular">{participantsText}</span>
            {program && program.state !== 'archived' && (
              <PeakShavingAddIconSVG
                style={{ marginTop: '5px' }}
                onClick={() => setAddListingsToProgramModalVisible(!addListingsToProgramModalVisible)}
              />
            )}
          </Space>
        </Col>
      </Row>

      {program && (
        <ProgramListingsTable
          programId={programId}
          programListingsTerm={programListingsTerm}
          setParticipantToEdit={setParticipantToEdit}
          publishedButtonDisabled={(isDisabled) => setPublishedButtonDisabled(isDisabled)}
        />
      )}
    </>
  )

  const renderTitle = () => {
    return (
      <Row>
        <Col span={24}>
          <PageHeaderContainer>
            {program && (
              <Row>
                <Col span={4}>
                  <span className="paragraph-04-regular">
                    <Link to={Links.peakShavingPrograms()}>{programText}</Link>
                  </span>
                </Col>
                <Col span={1}>{rightArrowSVG}</Col>
                {program?.aggregatorName && (
                  <>
                    <Col>
                      <span className="paragraph-04-regular" style={{ color: theme.colors.darkGray }}>
                        {program.aggregatorName}
                      </span>
                    </Col>
                    <Col span={1}>{rightArrowSVG}</Col>
                  </>
                )}

                <Col style={{ justifyContent: 'flex-start' }}>
                  <span className="paragraph-04-regular" style={{ color: theme.colors.darkGray, marginLeft: '5px' }}>
                    {program.name}
                  </span>
                </Col>
              </Row>
            )}
          </PageHeaderContainer>
        </Col>
      </Row>
    )
  }

  if (!program) {
    return <Skeleton active />
  }

  return (
    <ProgramPageContainer>
      <AlertError error={error} />
      {program && (
        <PageHeader
          title={renderTitle()}
          extra={
            program.state === 'published' ? (
              <Space>
                <Button
                  key="new-program-btn"
                  loading={loading}
                  size="large"
                  type="primary"
                  disabled={loading}
                  icon={plusSignSVG}
                  onClick={() => setCreateNewEventModalVisible(!createNewEventModalVisible)}
                  style={{ width: '157px' }}
                >
                  <span className="regular-cap">{newEventText}</span>
                </Button>
                <Button type="ghost" onClick={() => setArchiveProgramModalVisible(!archiveProgramModalVisible)}>
                  {archiveText}
                </Button>
              </Space>
            ) : (
              <Space>
                {program && program.state === 'draft' && (
                  <Button type="primary" disabled={publishButtonDisabled} onClick={onClickPublishProgram}>
                    {publishProgramText}
                  </Button>
                )}
              </Space>
            )
          }
        />
      )}
      {program && program.state === 'draft' ? (
        programDetailsContent()
      ) : (
        <Tabs defaultActiveKey="programDetails">
          <Tabs.TabPane tab={<span className="regular-cap">{programDetailsText}</span>} key="programDetails">
            {programDetailsContent()}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span className="regular-cap">{eventsText}</span>}
            key="programEvents"
            className="programEvents"
          >
            {program && eventDetailsTerm && (
              <DailyScheduleDetailsForm
                onClickCreateNewSchedule={() => setCreateNewScheduleModalVisible(!createNewScheduleModalVisible)}
                program={program}
                term={eventDetailsTerm}
                onClickRemoveSchedule={() => {
                  setEventDetailsTerm(_.uniqueId())
                }}
                onSubmit={() => {
                  setEventDetailsTerm(_.uniqueId())
                  //setScheduleDetailsTerm(_.uniqueId())
                }}
              />
            )}
            <Row style={{ marginTop: '38px', marginBottom: '8px' }}>
              <Col span={24}>
                <Space>
                  <span className="heading-05-regular">{eventsText}</span>
                  {program && program.state !== 'archived' && (
                    <PeakShavingAddIconSVG
                      style={{ marginTop: '5px' }}
                      onClick={() => setCreateNewEventModalVisible(!createNewEventModalVisible)}
                    />
                  )}
                </Space>
              </Col>
            </Row>
            {program && eventDetailsTerm && (
              <EventsTable
                program={program}
                onClickId={(id: number, frequency: PeakShavingScheduleFrequency) => {
                  if (frequency === 'manual') {
                    setManualEventId(id)
                    setEventDetailModalVisible(!eventDetailModalVisible)
                    setEventDetailsTerm(_.uniqueId())
                  } else if (frequency === 'daily') {
                    setEventId(id)
                    setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
                    setEventDetailsTerm(_.uniqueId())
                  }
                }}
                onClickDuplicateEvent={(id: number, frequency: PeakShavingScheduleFrequency) => {
                  if (frequency === 'manual') {
                    setManualDuplicateEventId(id)
                    setDuplicateEventModalVisible(!duplicateEventModalVisible)
                    setEventDetailsTerm(_.uniqueId())
                  } else if (frequency === 'daily') {
                    setDuplicateEventId(id)
                    setDuplicateDailyEventModalVisible(!duplicateDailyEventModalVisible)
                    setEventDetailsTerm(_.uniqueId())
                  }
                }}
                onClickCancelEvent={(id: number) => {
                  setCancelEventId(id)
                  setCancelEventModalVisible(!cancelEventModalVisible)
                }}
                term={eventDetailsTerm}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      )}
      {program && addListingsToProgramModalVisible && (
        <AddParticipantModal
          programId={program.id}
          initialValues={participantToEdit}
          visible={addListingsToProgramModalVisible}
          onCancel={() => setAddListingsToProgramModalVisible(!addListingsToProgramModalVisible)}
          onSubmit={() => {
            setAddListingsToProgramModalVisible(!addListingsToProgramModalVisible)
            setProgramListingsTerm(_.uniqueId())
          }}
        />
      )}
      {program && (
        <CreateNewDailyScheduleModal
          visible={createNewScheduleModalVisible}
          onCancel={() => setCreateNewScheduleModalVisible(!createNewScheduleModalVisible)}
          onSubmit={() => {
            setCreateNewScheduleModalVisible(!createNewScheduleModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          program={program}
        />
      )}
      {program && (
        <CreateNewEventModal
          visible={createNewEventModalVisible}
          onCancel={() => setCreateNewEventModalVisible(!createNewEventModalVisible)}
          onSubmit={() => {
            setCreateNewEventModalVisible(!createNewEventModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          program={program}
        />
      )}
      {program && (
        <ArchiveProgramModal
          visible={archiveProgramModalVisible}
          onCancel={() => setArchiveProgramModalVisible(!archiveProgramModalVisible)}
          onSubmit={() => {
            setArchiveProgramModalVisible(!archiveProgramModalVisible)
            CustomSuccessMessage({
              key: 'publishProgramMessageKey',
              response: 'All Published events are cancelled and you can no longer add listings/events to the program.',
            })
            history.push(Links.peakShavingPrograms())
          }}
          programId={program.id}
        />
      )}
      {manualEventId && (
        <EventDetailsModal
          visible={eventDetailModalVisible}
          onCancel={() => setEventDetailModalVisible(!eventDetailModalVisible)}
          onSubmit={() => {
            setEventDetailModalVisible(!eventDetailModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          eventId={manualEventId}
          term={eventDetailsTerm}
        />
      )}
      {manualDuplicateEventId && program && (
        <DuplicateEventModal
          visible={duplicateEventModalVisible}
          onCancel={() => setDuplicateEventModalVisible(!duplicateEventModalVisible)}
          onSubmit={() => {
            setDuplicateEventModalVisible(!duplicateEventModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          eventId={manualDuplicateEventId}
          program={program}
          term={eventDetailsTerm}
        />
      )}
      {duplicateEventId && program && duplicateDailyEventModalVisible && (
        <DuplicateDailyEventModal
          visible={duplicateDailyEventModalVisible}
          onCancel={() => setDuplicateDailyEventModalVisible(!duplicateDailyEventModalVisible)}
          onSubmit={() => {
            setDuplicateDailyEventModalVisible(!duplicateDailyEventModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          eventId={duplicateEventId}
          term={eventDetailsTerm}
        />
      )}
      {cancelEventId && cancelEventModalVisible && (
        <CancelEventModal
          visible={cancelEventModalVisible}
          onCancel={() => {
            setCancelEventModalVisible(!cancelEventModalVisible)
          }}
          onSubmit={() => {
            setCancelEventModalVisible(!cancelEventModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          eventId={cancelEventId}
        />
      )}
      {editDailyScheduleModalVisible && eventId && (
        <EditDailyScheduleModal
          visible={editDailyScheduleModalVisible}
          onCancel={() => {
            setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
          }}
          onSubmit={() => {
            setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
            setEventDetailsTerm(_.uniqueId())
          }}
          eventId={eventId}
        />
      )}
    </ProgramPageContainer>
  )
}

export const PeakShavingProgramPage = withAuthenticatedLayout(PeakShavingEventBasePage)
