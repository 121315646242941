import { Button, Col, PageHeader, Row, Space, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Box } from '../../atom/box'
import { MobileCalendar } from '../../atom/mobile-calendar'
import { DateRangePicker } from '../../components/datepicker/datepicker'
import { AlertError } from '../../components/error'
import { EnergyGraph } from '../../components/graphs/energy-graph'
import { RevenueGraph } from '../../components/graphs/revenue-graph'
import { TransactionGraph } from '../../components/graphs/transaction-graph'
import { LocationSelector } from '../../components/selector/location-selector'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { ConnectorMetricCounts, TransactionMetricResponse } from '../../models/analytics'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { GetConnectorCounts, GetTransactionMetrics } from '../../services/data-provider/analytics'
import { useAppState } from '../../state'
import { styled } from '../../theme'
import { ConnectorSummaryDetails } from './charger-connector-summary-details'
import { SummaryDetails } from './summary-details'

const CalendarWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('lg')} {
    .ant-picker.ant-picker-range {
      width: 100%;
    }
  }
`

export const DashboardBasePageOld: React.FC = () => {
  const { selectedTenant, IsMobile, IsTablet, IsLaptop, IsDesktop, language } = useAppState()

  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))

  const [trxResp, setTrxResp] = useState<TransactionMetricResponse>()
  const [connectorMetricCounts, setConnectorMetricCounts] = useState<ConnectorMetricCounts>()

  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(true)

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()
  const [mobileCalendarVisible, setMobileCalendarVisible] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])

  const calendarRanges: string[] = [
    useFormatMessage('dashboard.calendar.today', 'Today'),
    useFormatMessage('dashboard.calendar.thisWeek', 'This Week'),
    useFormatMessage('dashboard.calendar.thisMonth', 'This Month'),
    useFormatMessage('dashboard.calendar.thisYear', 'This Year'),
  ]

  useEffect(() => {
    refreshData()
    document.querySelector('body')?.classList.remove('redesignActive')
  }, [selectedTenant, tenants])

  const refreshData = () => {
    setLoading(true)
    Promise.all([
      GetConnectorCounts(selectedTenant, tenants, selectedCountries, selectedProvinces)
        .then((resp) => setConnectorMetricCounts(resp))
        .catch((err: SwtchError) => setError(err)),
      GetTransactionMetrics({
        startDate,
        endDate,
        defaultTenant: selectedTenant,
        tenants: tenants,
        selectedCountries: selectedCountries,
        selectedProvinces: selectedProvinces,
      }).then((data) => setTrxResp(data)),
    ]).finally(() => setLoading(false))
  }

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const onConfirm = (startDateTime?: moment.Moment, endDateTime?: moment.Moment) => {
    setMobileCalendarVisible(false)

    if (startDateTime && startDateTime !== startDate) {
      setStartDate(startDateTime)
    }

    if (endDateTime && endDateTime !== endDate) {
      setEndDate(endDateTime)
    }
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  return (
    <>
      <PageHeader
        title={useFormatMessage('dashboard.homePage.home', 'Home')}
        extra={
          <>
            {isDesktop || isLaptop ? (
              <Space>
                <MultiTenantSelector
                  onOptionsChange={(tenants) => setTenants(tenants)}
                  onClear={() => setTenants([])}
                />
                <LocationSelector onSearch={handleLocation} />
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChangeDate={onChangeDate}
                  calendarRanges={calendarRanges}
                />
                <Button type="primary" onClick={refreshData}>
                  {renderFormatMessage('dashboard.homePage.search', 'Search')}
                </Button>
              </Space>
            ) : (
              <CalendarWrapper>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                    <MultiTenantSelector
                      onOptionsChange={(tenants) => setTenants(tenants)}
                      onClear={() => setTenants([])}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                    <LocationSelector onSearch={handleLocation} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                    {isTablet && (
                      <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        onChangeDate={onChangeDate}
                        calendarRanges={calendarRanges}
                      />
                    )}
                    {isMobile && (
                      <MobileCalendar
                        visible={mobileCalendarVisible}
                        setMobileCalendarVisible={setMobileCalendarVisible}
                        onCancel={() => setMobileCalendarVisible(false)}
                        handleConfirm={onConfirm}
                        startDate={startDate}
                        endDate={endDate}
                        language={language}
                        calendarRanges={calendarRanges}
                      />
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3}>
                    <Button
                      type="primary"
                      onClick={refreshData}
                      block={isMobile || isTablet ? true : false}
                      disabled={loading}
                    >
                      {renderFormatMessage('dashboard.homePage.search', 'Search')}
                    </Button>
                  </Col>
                </Row>
              </CalendarWrapper>
            )}
          </>
        }
      />
      <AlertError error={error} />
      <SummaryDetails trxMetrics={trxResp} loading={loading} />
      <ConnectorSummaryDetails metrics={connectorMetricCounts} loading={loading} tenants={tenants} />
      <Spin size="large" spinning={loading}>
        <Box>
          <Row>
            <Col sm={24} md={24} lg={8}>
              <RevenueGraph data={trxResp?.series || []} />
            </Col>
            <Col sm={24} md={24} lg={8}>
              <TransactionGraph data={trxResp?.series || []} />
            </Col>
            <Col sm={24} md={24} lg={8}>
              <EnergyGraph data={trxResp?.series || []} />
            </Col>
          </Row>
        </Box>
      </Spin>
    </>
  )
}
