import { theme } from '../../theme'

export const userSVG = (
  <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="4" r="3.5" fill={theme.colors.primary} />
    <ellipse cx="6.5" cy="14.5" rx="6.5" ry="4" fill={theme.colors.primary} />
  </svg>
)

export const userWhiteSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <circle cx="8.5" cy="3.5" r="3.5" fill={theme.colors.white} />
    <ellipse cx="8.5" cy="14" rx="6.5" ry="4" fill={theme.colors.white} />
  </svg>
)

export const uniqueUserSVG = (
  <svg width="20" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.92502" cy="3.82541" r="3.82541" fill={theme.colors.primary} />
    <path
      d="M13.7717 18.7072L11.3125 20L11.7819 17.2622L9.79297 15.3226L12.5425 14.9226L13.7717 12.4316L15.0017 14.9226L17.7504 15.3226L15.7615 17.2622L16.23 20L13.7717 18.7072Z"
      fill={theme.colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24359 18.0964C8.0074 17.5302 7.87695 16.9088 7.87695 16.257C7.87695 13.987 9.45887 12.0864 11.5805 11.5976C10.3755 10.9306 8.73314 10.52 6.92298 10.52C3.22573 10.52 0.228516 12.2327 0.228516 14.3454C0.228516 16.4581 3.22573 18.1708 6.92298 18.1708C7.37505 18.1708 7.81666 18.1452 8.24359 18.0964Z"
      fill={theme.colors.primary}
    />
  </svg>
)
