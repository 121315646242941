import { Badge } from 'antd'
import { theme } from '../theme'

interface props {
  noBackground?: boolean
}

const NoDataBadge = ({ noBackground }: props) => (
  <Badge
    count="..."
    style={{
      backgroundColor: noBackground ? 'transparent' : theme.colors.orangePeel15,
      color: theme.colors.warningPrimary,
      boxShadow: `transparent 0px 0px 0px 0.5px`,
    }}
  />
)

export default NoDataBadge
