import { Form, Input, Modal } from 'antd'
import { useFormatMessage } from '../../helpers/intl'
import { getFullNameFromEmail } from '../../helpers/users'
import { User } from '../../models/user'
import { theme } from '../../theme'

interface props {
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onNameEdited: (firstName: string, lastName: string) => void
  selectedUser: User
}

export const UserEditNameForm: React.FC<props> = ({ onCancel, onNameEdited, selectedUser }) => {
  const [form] = Form.useForm()

  const [firstName, lastName] = getFullNameFromEmail(selectedUser.name)
  const editUserNameText = useFormatMessage('dashboard.userspage.editUserName.heading', "Edit User's Name")
  const firstNameText = useFormatMessage('dashboard.userspage.editUserName.firstName.heading', 'First Name')
  const lastNameText = useFormatMessage('dashboard.userspage.editUserName.lastName.heading', 'Last Name')

  const firstNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.warning',
    'First name is required',
  )
  const lastNameRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.warning',
    'Last name is required',
  )

  const firstNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.firstName.maxlength.warning',
    'First name cannot exceed 20 characters!',
  )

  const lastNameMaxLengthRequiredWarning = useFormatMessage(
    'dashboard.userspage.editUserName.lastName.maxlength.warning',
    'Last name cannot exceed 20 characters!',
  )

  const cancelText = useFormatMessage('dashboard.userspage.editUserName.cancelText', 'Cancel')
  const updateText = useFormatMessage('dashboard.userspage.editUserName.updateText', 'Update')

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then((values: { firstName: string; lastName: string }) => {
      const { firstName, lastName } = values
      onNameEdited(firstName, lastName)
    })
  }

  return (
    <Modal
      title={editUserNameText}
      closable={false}
      okText={updateText}
      cancelText={cancelText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={afterClose}
    >
      <Form
        form={form}
        labelAlign="right"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={({ firstName, lastName }) => onNameEdited(firstName, lastName)}
        id="editUserForm"
      >
        <Form.Item
          key="firstName"
          name="firstName"
          label={firstNameText}
          rules={[
            { required: true, message: firstNameRequiredWarning },
            { max: 20, message: firstNameMaxLengthRequiredWarning },
          ]}
          initialValue={firstName}
          style={{ marginBottom: theme.fontSizes[2] }}
        >
          <Input value={firstName} />
        </Form.Item>
        <Form.Item
          key="lastName"
          name="lastName"
          label={lastNameText}
          rules={[
            { required: true, message: lastNameRequiredWarning },
            { max: 20, message: lastNameMaxLengthRequiredWarning },
          ]}
          initialValue={lastName}
          style={{ marginBottom: theme.fontSizes[2] }}
        >
          <Input value={lastName} />
        </Form.Item>
      </Form>
    </Modal>
  )
}