import { Checkbox, Select, Spin } from 'antd'
import { debounce } from 'lodash'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import { tenantSVG } from '../../assets/svg/tenant'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { useAppState } from '../../state'

import { removeIconSVG } from '../../assets/svg/removeIcon'
import { MultiTenantSelectorContainer } from '../../atom/select'
import { useSelectorTranslation } from '../../hooks/translation/useSelectorTranslation'
import { TransactionFilter } from '../../models/filter'
import { magnifySVG } from '../../assets/svg/magnify'
import { DefaultOptionType } from 'antd/lib/select'

interface props {
  defaultTenant?: TenantRef
  onOptionsChange: (options: TenantRef[]) => void
  onClear: () => void
  loading?: boolean
  isCombineRight?: boolean
  isCombineLeft?: boolean
  isCombineRightAndLeft?: boolean
  noChangeOnFocus?: boolean
  filter?: TransactionFilter
  longerWidth: boolean
  tenantIcon?: boolean
  dropdownClassName?: string
}

export const MultiTenantSelector: React.FC<props> = ({
  defaultTenant,
  onOptionsChange,
  onClear,
  loading,
  isCombineRight,
  isCombineLeft,
  isCombineRightAndLeft,
  filter,
  longerWidth,
  tenantIcon,
  dropdownClassName,
}) => {
  const { IsDesktop, IsLaptop, IsMobile, IsTablet } = useAppState()

  const { locationTextPlaceholder, startTypingText, tenantSelectedText } = useSelectorTranslation()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [selectedOptions, setSelectedOptions] = useState<TenantRef[]>([])
  const [dirty, setDirty] = useState(false)
  const [tenantOptions, setTenantOptions] = useState<TenantRef[]>([])
  const fetchRef = useRef(0)
  const [fetching, setFetching] = useState(false)

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const isMobile = IsMobile()
  const isTablet = IsTablet()

  useEffect(() => {
    if (filter?.tenants && filter.tenants.length === 0) {
      setDirty(false)
      setSelectedOptions([])
    }
  }, [filter?.tenants])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setFetching(true)

        FindTenants(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setTenants(newOptions)
          setTenantOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const renderOption = ({ id, displayName, name: ocppName, combineName }: TenantRef): ReactElement => {
    return (
      <Select.Option key={id} value={combineName} label={displayName ? displayName : ocppName}>
        <Checkbox
          onClick={handleCheckBoxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.combineName === combineName) > -1}
        >
          {displayName && (displayName !== '' || displayName !== null) ? displayName : ocppName}
        </Checkbox>
      </Select.Option>
    )
  }

  const handleCheckBoxClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (dirty) {
      e.stopPropagation()
    }
    setDirty(false)
  }

  const handleChange = (value: string[], option: any) => {
    setDirty(true)
    const selectedTenants = tenantOptions.filter((t) => value.includes(t.combineName || t.displayName || t.name))
    const removedDuplicatedOfTenants = selectedTenants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(selectedTenants)
  }

  const handleOnClear = () => {
    setSelectedOptions([])
    onClear()
  }

  const canCombineBorderRight = (isDesktop || isLaptop) && isCombineRight
  const canCombineBorderLeft = (isDesktop || isLaptop) && isCombineLeft
  const canCombineBorderRightAndLeft = (isDesktop || isLaptop) && isCombineRightAndLeft

  const renderTenantLists = (tenants: TenantRef[]) => {
    const optionsWithSelectedTenants = [
      ...selectedOptions,
      ...tenants.filter((tenant) => !selectedOptions.some((option) => option.id === tenant.id)),
    ]
    return optionsWithSelectedTenants.map((v) => renderOption(v)).sort((a, b) => sortTenants(a, b))
  }

  const sortTenants = (a: ReactElement, b: ReactElement) => {
    return b.props.children.props.checked - a.props.children.props.checked
  }

  const handlePlaceholder = () => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((tenant) => {
      // Use regular expression to capture text before open brackets or text inside brackets
      const match = tenant.combineName.match(/([^(]*)\s*\(([^)]*)\)/)

      if (match) {
        const [, textBeforeBrackets, textInsideBrackets] = match
        return textBeforeBrackets.trim() || textInsideBrackets.trim()
      }

      return tenant.combineName
    })

    return selected === 1 ? resultArray : tenantSelectedText.replace('-1', `${selected}`)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  //To keep the selected option visible
  const filterOption = (input: string, option: DefaultOptionType | undefined) => {
    if (selectedOptions.some((selectedOption) => `${selectedOption.id}` === option?.key)) {
      return true
    }
    return (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <MultiTenantSelectorContainer
      className="tenant-selector"
      isCombineRight={canCombineBorderRight}
      isCombineLeft={canCombineBorderLeft}
      isCombineRightAndLeft={canCombineBorderRightAndLeft}
      longerWidth={longerWidth}
      isMobileOrTablet={isMobile || isTablet}
    >
      {tenantIcon ? tenantSVG : magnifySVG}
      {!defaultTenant && (
        <Select
          dropdownClassName={dropdownClassName}
          disabled={loading}
          showSearch
          mode="multiple"
          allowClear
          filterOption={filterOption}
          loading={fetching}
          style={{ minWidth: 195, width: '100%', lineHeight: '34px' }}
          placeholder={locationTextPlaceholder}
          onClear={handleOnClear}
          onChange={handleChange}
          onSearch={debounceFetcher}
          dropdownMatchSelectWidth={false}
          maxTagCount={0}
          onMouseDown={(e) => handleMouseDown(e)}
          maxTagPlaceholder={handlePlaceholder}
          removeIcon={removeIconSVG}
          notFoundContent={fetching ? <Spin size="small" /> : startTypingText}
          value={filter?.tenants?.map((t) => t.combineName || t.displayName || t.name)}
        >
          {renderTenantLists(tenants)}
        </Select>
      )}
    </MultiTenantSelectorContainer>
  )
}
