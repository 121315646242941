import { Row } from 'antd'
import { styled, theme } from '../theme'
import { hexToRgba } from 'helpers/color'

interface props {
  padding?: boolean
}

export const Box = styled.div<props>`
  background-color: white;
  margin: 8px 0;
  padding: ${(props) => (props.padding ? '10px' : 0)};
  /* flex: 0 0 79.16666667%; */
  /* max-width: 79.16666667%; */
`

export const Section = styled.div`
  margin: 10px 0;
`

interface SubSectionProps {
  end?: boolean
}

export const SubSection = styled.div<SubSectionProps>`
  margin-top: 72px;
  margin-bottom: ${(props) => (props.end ? '30px' : 0)};
`

export const GreyBackground = styled.div`
  background-color: ${theme.colors.grey11};
  padding: '12px';
  border-radius: '12px';
`

export const BoxPadding = styled.div<props>`
  padding: ${(props) => (props.padding ? '12px 0' : 0)};
`

export const SelectionInfoContainer = styled(Row)`
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 8px 16px;
  background-color: ${hexToRgba(theme.colors.primary, 0.1)};
`
