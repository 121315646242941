import { Checkbox, Select, Spin } from 'antd'
import { ReactElement, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'
import { MultiOptionSelectorContainer } from '../../atom/select'
import { FindListings } from '../../services/data-provider/listing'
import { ListingRef } from '../../models/listing'
import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { TransactionFilter } from '../../models/filter'
import { useSelectorTranslation } from '../../hooks/translation/useSelectorTranslation'
import { DefaultOptionType } from 'antd/lib/select'
interface props {
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  loading?: boolean
  isCombineSelector?: boolean
  isCombineRightSelector?: boolean
  isCombineLeftSelector?: boolean
  filter?: TransactionFilter
  allListings?: ListingRef[]
  width?: string
  setAllListings?: (listings: ListingRef[]) => void
  onOptionChange: (listingTitles: ListingRef[]) => void
  setClear: (clear: boolean) => void
  dropdownClassName?: string
  minWidth?: number
}

export const MultiListingTitleSelector: React.FC<props> = ({
  defaultTenant,
  tenants,
  loading,
  isCombineSelector,
  isCombineRightSelector,
  isCombineLeftSelector,
  filter,
  allListings,
  width,
  setAllListings,
  onOptionChange,
  setClear,
  dropdownClassName,
  minWidth,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<ListingRef[]>([])
  const [listings, setListings] = useState<ListingRef[]>([])
  const [dirty, setDirty] = useState(false)
  const [listingOptions, setListingOptions] = useState<ListingRef[]>([])

  const fetchRef = useRef(0)
  const [fetching, setFetching] = useState(false)

  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const { listingTitlesText } = useListingBasicTrans()
  const { startTypingText } = useSelectorTranslation()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setListings([])
        setFetching(true)

        FindListings(value, defaultTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setListings(newOptions)
          setListingOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const renderOption = ({ id, title }: ListingRef): ReactElement => (
    <Select.Option key={id} value={title} label={title}>
      <Checkbox
        onClick={handleCheckBoxClick}
        checked={selectedOptions.findIndex((option) => option.title === title) > -1}
      >
        {title}
      </Checkbox>
    </Select.Option>
  )

  const handleCheckBoxClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (dirty) {
      e.stopPropagation()
    }
    setDirty(false)
  }

  const handleChange = (value: string[]) => {
    setDirty(true)
    const selectedListingsArr = listingOptions.filter((listing) => value.includes(listing.title))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionChange(selectedListingsArr)
    const listingArr: ListingRef[] = []
    value.map((v) => {
      const listing = allListings?.find((l) => l.title === v)
      if (listing) {
        listingArr.push(listing)
      }
      return listingArr
    })
    setAllListings && setAllListings(listingArr)
  }

  const handleOnClear = () => {
    setSelectedOptions([])
    setClear(true)
  }

  const renderTenantLists = (listings: ListingRef[]) => {
    const optionsWithSelectedListing = [
      ...selectedOptions,
      ...listings.filter((listing) => !selectedOptions.some((option) => option.id === listing.id)),
    ]
    return optionsWithSelectedListing.map((v) => renderOption(v)).sort((a, b) => sortListingTitles(a, b))
  }

  const sortListingTitles = (a: ReactElement, b: ReactElement) => {
    return b.props.children.props.checked - a.props.children.props.checked
  }

  const handlePlaceholder = () => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].title : selectedPlaceholder(selected)
  }

  const selectedPlaceholder = (selected: number) => `${selected} Listing Titles Selected`

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  const canCombineBorder = (isDesktop || isLaptop) && isCombineSelector
  const canCombineRightBorder = (isDesktop || isLaptop) && isCombineRightSelector
  const canCombineLeftBorder = (isDesktop || isLaptop) && isCombineLeftSelector

  //To keep the selected option visible
  const filterOption = (input: string, option: DefaultOptionType | undefined) => {
    if (selectedOptions.some((selectedOption) => `${selectedOption.id}` === option?.key)) {
      return true
    }
    return (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <MultiOptionSelectorContainer
      isCombine={canCombineBorder}
      isCombineRight={canCombineRightBorder}
      isCombineLeft={canCombineLeftBorder}
      removeSVG
      minWidth={minWidth}
    >
      {/* {magnifySVG} */}
      <Select
        dropdownClassName={dropdownClassName}
        disabled={loading}
        showSearch
        mode="multiple"
        allowClear
        filterOption={filterOption}
        loading={fetching}
        style={{ minWidth: minWidth ?? 156, width: width ? width : '100%', lineHeight: '34px' }}
        placeholder={listingTitlesText}
        onChange={handleChange}
        onSearch={debounceFetcher}
        dropdownMatchSelectWidth={false}
        onMouseDown={(e) => handleMouseDown(e)}
        maxTagCount={0}
        maxTagPlaceholder={handlePlaceholder}
        notFoundContent={fetching ? <Spin size="small" /> : startTypingText}
        value={filter?.listingTitle}
        onClear={handleOnClear}
      >
        {renderTenantLists(listings)}
      </Select>
    </MultiOptionSelectorContainer>
  )
}

export default MultiListingTitleSelector
