import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChargerInfo } from '../../../../models/chargerv2'
import { useAppState } from '../../../../state'
import { useFormatMessage } from '../../../../helpers/intl'

highchartsMore(Highcharts)
let scaleFactor = 26

const renderPoint = (seriesName: string, seriesValue: number) => {
  if (seriesName === 'DC Fast Connectors' || seriesName === 'Connecteurs rapides CC') {
    return Math.round(seriesValue / scaleFactor)
  }
  return seriesValue
}

const ConnectorsPluggedInContainer = styled.div`
  position: absolute;
  top: -60px;
  right: 0;
  opacity: 0;

  width: 100%;
  height: 90px;
  min-width: 0;
  overflow: hidden;
  display: flex;
  /* padding-right: 12px; */

  .highcharts-color-1 {
    fill: #dedede;
    stroke: #dedede;
    filter: none;
  }

  .highcharts-color-0 {
    fill: #54dec7;
    stroke: #54dec7;
    filter: none;
  }

  .highcharts-column-series rect.highcharts-point {
    stroke: unset;
    filter: unset;
  }

  .highcharts-tooltip-box {
    fill: #242e2c;
    display: block;
    border: none;
    stroke-width: 0;
  }
  .highcharts-tooltip text {
    fill: white;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    height: 200px;
    color: white;
  }

  .highcharts-tooltip-container .highcharts-tooltip > span {
    min-width: 350px;
    width: 400px;
    min-height: 200px;
    color: white;
  }

  .highcharts-label.highcharts-tooltip.highcharts-color-0,
  .highcharts-label.highcharts-tooltip.highcharts-color-1,
  .highcharts-label.highcharts-tooltip.highcharts-color-2 {
    stroke: unset;
  }

  .highcharts-point {
    filter: none;
  }
`

export const optionsconnectorsPluggedIn = {
  chart: {
    type: 'column',
    size: '100%',
    events: {
      render: function () {
        //@ts-ignore
        this.reflow()
      },
    },
  },

  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    style: {
      color: '#fff',
      fontFamily: 'AkkuratLLWeb-Regular',
      fontSize: '11px',
      fontWeight: 700,
    },
    // formatter: function (): any {
    //   console.log('+++++++++++++')

    //   //@ts-ignore
    //   return '<b>' +  + '</b> : ' + '<b>' + this.y + '</b>'
    // },
    headerFormat: '',
    pointFormatter: function (): any {
      //@ts-ignore
      return (
        '<span> ' +
        //@ts-ignore
        `<span style="color: ${this.color};">●</span> ` +
        //@ts-ignore
        this.series.name +
        '</b> : <b>' +
        //@ts-ignore
        renderPoint(this.series.name, this.y) +
        '</b> </span><br>'
      )
    },

    // footerFormat: '</table>',
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      borderRadiusBottomLeft: 5,
      groupPadding: 0.1,
    },
    series: {
      borderRadius: 3,
    },
  },
  series: [
    {
      name: 'DC Fast Connectors',
      color: '#dedede',
      data: [0],
    },
    {
      name: 'Level 2 Connectors',
      color: '#54dec7',
      data: [0],
    },
  ],
}

interface props {
  chargerInfo: ChargerInfo
}

export const HomePageConnectorsPluggedInChart: React.FC<props> = ({ chargerInfo }) => {
  const [options, setOptions] = useState(optionsconnectorsPluggedIn)

  const { language } = useAppState()

  const [dataLevel2Chargers, setDataLevel2Chargers] = useState<number[]>([0])
  const [dataDCFastChargers, setDataDCFastChargers] = useState<number[]>([0])
  const dcFastConnectorsText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors',
    'DC Fast',
  )

  const level2ConnectorsText = useFormatMessage(
    'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors',
    'Level 2',
  )

  useEffect(() => {
    if (dataLevel2Chargers.length < 5 && dataDCFastChargers.length < 5) {
      dataLevel2Chargers.push(chargerInfo.connectors.pluggedInLevel2)
      scaleFactor = Math.round(Math.max(...dataLevel2Chargers) / 6)
      dataDCFastChargers.push(chargerInfo.connectors.pluggedInDc * scaleFactor)
    } else if (dataLevel2Chargers.length === 5 && dataDCFastChargers.length === 5) {
      dataLevel2Chargers.shift()
      dataDCFastChargers.shift()
      dataLevel2Chargers.push(chargerInfo.connectors.pluggedInLevel2)
      scaleFactor = Math.round(Math.max(...dataLevel2Chargers) / 6)
      dataDCFastChargers.push(chargerInfo.connectors.pluggedInDc * scaleFactor)
    }

    setDataLevel2Chargers([...dataLevel2Chargers])
    setDataDCFastChargers([...dataDCFastChargers])

    setOptions({
      ...options,
      series: [
        {
          ...options.series[0],
          name: dcFastConnectorsText,
          data: [...dataDCFastChargers],
        },
        {
          ...options.series[1],
          name: level2ConnectorsText,
          data: [...dataLevel2Chargers],
        },
      ],
    })
  }, [chargerInfo, language])

  return (
    <ConnectorsPluggedInContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ConnectorsPluggedInContainer>
  )
}
