import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'
import { formatPrice } from '../../../../helpers/number-format'
import { CoreInfoCard } from 'components/cards'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const RevenueMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const { fromDemandResponse, fromCharging, fromLoitering } = useHomeTranslation()
  const labelFormat = {
    label: (value: string | number) => <b>{value}</b>,
    subLabel: (value: string | number) => <span className="default-label">{value}</span>,
  }
  const infoDetails = [
    {
      label:
        trxProfileSummary &&
        (trxProfileSummary.revenueDemandResponse
          ? formatPrice(trxProfileSummary.revenueDemandResponse / 100.0)
          : formatPrice(0)),
      subLabel: fromDemandResponse,
      labelFormat,
    },
    {
      label:
        trxProfileSummary && trxProfileSummary.revenueCharging
          ? formatPrice(trxProfileSummary.revenueCharging / 100.0)
          : formatPrice(0),
      subLabel: fromCharging,
      labelFormat,
    },
    {
      label:
        trxProfileSummary && trxProfileSummary.revenueLoitering
          ? formatPrice(trxProfileSummary.revenueDemandResponse / 100.0)
          : formatPrice(0),
      subLabel: fromLoitering,
      labelFormat,
    },
  ]
  return (
    <CoreInfoCard
      title={'Revenue'}
      wsLoading={loading}
      infoText={
        trxProfileSummary &&
        (trxProfileSummary.revenueTotal ? formatPrice(trxProfileSummary.revenueTotal / 100.0) : formatPrice(0))
      }
      infoDetails={infoDetails}
    />
  )
}

export default RevenueMetrics
