import { PageHeader } from 'antd'

import { AlertError } from 'components/error'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { useTenantActivityTracking } from 'hooks/useTenantActivityTracking'
import ActivityTrackingSelectors from 'features/tenants/activityTracking/ActivityTrackingSelectors'
import TenantActivityTrackingView from './table/ActivityTrackingView'
import { TenantRef } from 'models/tenant'

interface TenantTabActivityTrackingProps {
  tenant: TenantRef
}

const TenantTabActivityTracking: React.FC<TenantTabActivityTrackingProps> = ({ tenant }) => {
  document.querySelector('body')?.classList.add('redesignActive')

  const {
    error,
    loading,
    startDate,
    endDate,
    activityTracking,
    pagination,
    filter,
    fetchDataWithQuery,
    handlePaginationChange,
    setFilter,
    handleChangeDate,
  } = useTenantActivityTracking(tenant.id)

  return (
    <BackgroundContainer>
      <PageHeader
        title={
          <ActivityTrackingSelectors
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            filter={filter}
            onChangeDate={handleChangeDate}
            onFilter={setFilter}
            onFetch={fetchDataWithQuery}
            tenant={tenant}
          />
        }
      />
      <AlertError error={error} />

      <TenantActivityTrackingView
        loading={loading}
        activityTracking={activityTracking}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
      />
    </BackgroundContainer>
  )
}

export default TenantTabActivityTracking
