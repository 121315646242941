import { useFormatMessage } from '../../helpers/intl'

export const useDiscountSchemaTranslation = () => {
  const archiveText = useFormatMessage('dashboard.text.archive', 'Archive')
  const archiveDescriptionText = useFormatMessage(
    'dashboard.discountSchema.archiveDescription',
    'Archiving Discount Plan?',
  )
  const actionDescriptionText = useFormatMessage(
    'dashboard.discountSchema.actionDescription',
    'This action cannot be undone.',
  )
  const promoCodeDescriptionText = useFormatMessage(
    'dashboard.discountSchema.promoCodeDescription',
    'Promo code will no longer work',
  )
  const usersRemovedDescriptionText = useFormatMessage(
    'dashboard.discountSchema.usersRemovedDescription',
    'All users will be removed in this discount plan',
  )
  const promoCodeText = useFormatMessage('dashboard.discountSchema.promoCode', 'Promo Code')
  const skipDiscountPlanText = useFormatMessage('dashboard.discountSchema.skipDiscountPlan', 'Skip Discount Plan')
  const flatFeeInfoText = useFormatMessage(
    'dashboard.discountSchema.flatFeeInfo',
    'Flat fee amount is determined by base plan. Choose this amount or $0.00',
  )
  const promoCodeInfoText = useFormatMessage(
    'dashboard.discountSchema.promoCodeInfo',
    'Each code should be unique, only with letters & numbers.',
  )
  const discountPlanNameText = useFormatMessage('dashboard.discountSchema.discountPlanName', 'Discount Plan Name')
  const basePlanText = useFormatMessage('dashboard.discountSchema.basePricingPlan', 'Base Pricing Plan')
  const noLoiteringInfoText = useFormatMessage(
    'dashboard.discountSchema.noLoiteringInfoText',
    "Loitering adjustment is unavailable because there's no loitering in base plan.",
  )
  const noFlatFeeText = useFormatMessage('dashboard.discountSchema.noFlatFeeText', 'No Flat Fee')
  const noLoiteringText = useFormatMessage('dashboard.discountSchema.noLoiteringText', 'No Loitering')
  const discountPlanInfoText = useFormatMessage(
    'dashboard.discountSchema.discountPlanInfoText',
    'Discount plan cannot be higher than base plan.',
  )
  const gracePeriodText = useFormatMessage('dashboard.discountSchema.gracePeriodText', 'Grace Period')
  const timeLimitText = useFormatMessage('dashboard.discountSchema.timeLimittext', 'Time Limit')
  const selectPricingPlanText = useFormatMessage(
    'dashboard.discountSchema.selectPricingPlanText',
    'Select a pricing plan where the new discount plan will be located.',
  )
  const discountPlanWorksWithPricingPlanText = useFormatMessage(
    'dashboard.discountSchema.discountPlanWorksWithPricingPlanText',
    'A discount plan works inside a pricing plan.',
  )

  const pricingPlanText = useFormatMessage('dashboard.discountSchema.pricingPlan', 'Pricing Plan:')

  const discountPlanText = useFormatMessage('dashboard.discountSchema.discountPlan', 'Discount Plan:')

  const listingText = useFormatMessage('dashboard.discountSchema.listings', 'Listings:')

  const emailTxt = useFormatMessage('dashboard.placeholder.email', 'Email')

  const nameTxt = useFormatMessage('dashboard.text.name', 'Name')

  const timeAddedText = useFormatMessage('dashboard.discountSchema.timeAdded', 'Time Added')

  const actionText = useFormatMessage('dashboard.discountSchema.action', 'Action')

  const updatedByText = useFormatMessage('dashboard.discountSchema.updatedBy', 'Updated By')

  const manageUsersText = useFormatMessage('dashboard.discountSchema.manageUsers', 'Manage Users')

  const allUsersSelectedText = useFormatMessage(
    'dashboard.discountSchema.allUsersSelected',
    'All -1 users on this page are selected.',
  )
  const usersSelectedText = useFormatMessage('dashboard.discountSchema.usersSelected', 'Users Selected')
  const selectAllText = useFormatMessage(
    'dashboard.discountSchema.selectAll',
    'Select all -1 users in this discount plan',
  )

  const removingUsers = useFormatMessage('dashboard.discountSchema.removingUsers', 'Removing Users?')
  const removeText = useFormatMessage('dashboard.discountSchema.remove', 'Remove')

  const cancel = useFormatMessage('dashboard.chargersPage.modal.decommisioned.cancel', 'Cancel')

  const currentUsersText = useFormatMessage('dashboard.discountSchema.currentUsers', '-1 Users')
  const userAuthorizationHistoryText = useFormatMessage(
    'dashboard.discountSchema.userAuthorizationHistory',
    'User Authorization history',
  )
  const viewHistoryText = useFormatMessage('dashboard.discountSchema.viewHistory', 'View history')
  const usersRemovedText = useFormatMessage('dashboard.discountSchema.usersRemoved', 'Users removed')
  const usersAddededText = useFormatMessage('dashboard.discountSchema.usersAdded', 'Users added')
  const discountPlanModalTitleText = useFormatMessage(
    'dashboard.discountSchema.discountPlanModalTitle',
    'Discount Plan Modal',
  )

  return {
    archiveText,
    archiveDescriptionText,
    actionDescriptionText,
    promoCodeDescriptionText,
    usersRemovedDescriptionText,
    promoCodeText,
    skipDiscountPlanText,
    flatFeeInfoText,
    promoCodeInfoText,
    discountPlanNameText,
    basePlanText,
    noLoiteringInfoText,
    noFlatFeeText,
    noLoiteringText,
    discountPlanInfoText,
    gracePeriodText,
    timeLimitText,
    selectPricingPlanText,
    discountPlanWorksWithPricingPlanText,
    pricingPlanText,
    discountPlanText,
    listingText,
    emailTxt,
    nameTxt,
    timeAddedText,
    actionText,
    updatedByText,
    manageUsersText,
    allUsersSelectedText,
    usersSelectedText,
    selectAllText,
    removingUsers,
    removeText,
    cancel,
    currentUsersText,
    userAuthorizationHistoryText,
    viewHistoryText,
    usersRemovedText,
    usersAddededText,
    discountPlanModalTitleText,
  }
}
