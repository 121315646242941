import { Button, Modal, Space } from 'antd'
import { useState } from 'react'

import { peakShavingModalCloseSVG } from '../../../assets/svg/peak-shaving-modal-close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'

import { SwtchError } from '../../../models/error'

import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'

import { TenantRef } from 'models/tenant'
import { HeadingWrapper, TextBodyWrapper } from '../../../atom/plans'
import { RemoveTenantFromProgram } from '../../../services/data-provider/peak-shaving'

interface props {
  visible: boolean
  programId: number
  tenant?: TenantRef
  onCancel(): void
  onSubmit(): void
}

export const RemoveFromProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit, programId, tenant }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const { cancelText, removeTenantText, removeTenantWarningText } = usePeakShavingTranslation()

  const handleOk = async () => {
    if (!tenant) return
    setLoading(true)
    RemoveTenantFromProgram(programId, tenant.id)
      .then(({ response }) => {
        CustomSuccessMessage({ key: 'Remove Participant From Program', response })
        onSubmit()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      <Modal
        visible={visible}
        title={
          <HeadingWrapper>
            {removeTenantText} - {tenant?.displayName}
          </HeadingWrapper>
        }
        footer={
          <Space>
            <Button type="ghost" onClick={onCancel} loading={loading}>
              {cancelText}
            </Button>
            <Button danger onClick={handleOk} loading={loading}>
              {removeTenantText}
            </Button>
          </Space>
        }
        onCancel={onCancel}
        onOk={onSubmit}
        closeIcon={peakShavingModalCloseSVG}
      >
        <TextBodyWrapper>{removeTenantWarningText}</TextBodyWrapper>
      </Modal>
    </>
  )
}
