import { Tabs, TabsProps } from 'antd'
import { DownloadFilesMenu } from 'atom/download-files-menu'
import styled from 'styled-components'
import { theme } from 'theme'
interface props {
  onChange: (activeKey: string) => void
  activeKey: string
  centerTabs?: boolean
  className?: string
  tabProps?: TabsProps
  title?: string
  downloadable?: {
    chart: React.MutableRefObject<null>
    onClickCSV?: () => void
  }
  tabs: {
    title: string
    key: string
    content?: JSX.Element | false
  }[]
}

const TarBarLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-right: 20px;
`

const Title = styled.p`
  color: ${theme.colors.black};
  opacity: 0.6;
  padding-left: 20px;
`

const CoreTabs: React.FC<props> = ({
  onChange,
  activeKey,
  centerTabs = false,
  className,
  tabs,
  title,
  downloadable,
  tabProps,
}) => {
  return (
    <Tabs
      {...tabProps}
      className={`operation-overview-tabs ${centerTabs ? 'center-tabs' : ''} ${className}`}
      onChange={onChange}
      activeKey={activeKey}
      tabBarExtraContent={
        !centerTabs && {
          left: (
            <TarBarLeftWrapper>
              <Title className="heading-06-bold">{title}</Title>
              {downloadable && <DownloadFilesMenu chart={downloadable?.chart} onClickCSV={downloadable?.onClickCSV} />}
            </TarBarLeftWrapper>
          ),
        }
      }
    >
      {tabs.map((tab) => (
        <Tabs.TabPane tab={<span className="regular-cap">{tab.title}</span>} key={tab.key}>
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default CoreTabs
