import { Link } from 'react-router-dom'
import { styled } from 'theme'

interface UnderlinedLinkProps {
  to: string
  children: React.ReactNode
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`

export const UnderlinedLink: React.FC<UnderlinedLinkProps> = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>
}

export const HoveringIcon: React.FC<UnderlinedLinkProps> = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>
}
