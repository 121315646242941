import { Table } from 'antd'

import { PaginationMeta } from '../../../../models/pagination'
import { Transaction } from '../../../../models/transaction'

import { TransactionsColumns } from './TransactionsColumns'
import { isRetrievingData } from '../../../../helpers/empty'
import { styled } from '../../../../theme'

// Hide the TransactionPanel when a row is selected by pushing the view below the viewport
const StyledTable = styled.div<{ data: Transaction[] }>`
  margin-top: 25px;

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    min-height: ${(props) => (props.data.length !== 0 ? '80vh' : '0')};
    justify-content: space-between;
  }
`

interface props {
  loading: boolean
  transactions: Transaction[]
  pagination: PaginationMeta
  showPanel: boolean
  selectedTransaction: Transaction | undefined
  onShowPanel: (showPanel: boolean) => void
  onSelectedTransaction: (transaction: Transaction, rowIndex?: number) => void
  onPageChange: (page: number) => void
  onPosition: (position: number) => void
}

const TransactionsTable: React.FC<props> = ({
  loading,
  transactions,
  pagination,
  showPanel,
  selectedTransaction,
  onSelectedTransaction,
  onShowPanel,
  onPageChange,
  onPosition,
}) => {
  const handleClick = (event: React.MouseEvent<any, MouseEvent>, record: Transaction, rowIndex?: number) => {
    onSelectedTransaction(record, rowIndex)
    onPosition(event.currentTarget.clientHeight)

    // If the panel is already open and the selected transaction is not the same as the one that was clicked, render the new transaction
    if (showPanel && selectedTransaction && record.id !== selectedTransaction.id) {
      return
    } else {
      // If the panel is not open, open it and render the selected transaction OR if the panel is open and the selected transaction is the same as the one that was clicked, close the panel
      return onShowPanel(!showPanel)
    }
  }

  const locale = { emptyText: isRetrievingData(loading) }

  const handleOnRow = (record: Transaction, rowIndex?: number) => {
    return {
      onClick: (event: React.MouseEvent<any, MouseEvent>) => handleClick(event, record, rowIndex),
      className: selectedTransaction && showPanel && record.id === selectedTransaction.id ? 'selected-table-row' : '',
    }
  }

  return (
    <StyledTable data={transactions}>
      <Table
        loading={loading}
        rowKey="id"
        columns={TransactionsColumns(showPanel)}
        rowClassName={() => 'custom-transaction-row'}
        dataSource={transactions}
        locale={locale}
        onRow={handleOnRow}
        pagination={{
          hideOnSinglePage: false,
          position: ['bottomCenter'],
          total: pagination?.totalEntries,
          current: pagination?.currentPage,
          pageSize: pagination?.perPage,
          showSizeChanger: false,
          onChange: onPageChange,
        }}
      />
    </StyledTable>
  )
}

export default TransactionsTable
