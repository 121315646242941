import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingChargerTranslation = () => {
  const customNotifText = renderFormatMessage(
    'dashboard.createNewListing.chargerStep.customNotification.txt',
    'Custom notification',
  )
  const customNotifPlaceholderText = useFormatMessage(
    'dashboard.createNewListing.chargerStep.customNotification.info',
    'Provide instructions on how to operate the charger if necessary',
  )
  const tenantIsRequired = useFormatMessage(
    'dashboard.createNewListing.chargerStep.tenantCategory.warning',
    'Site is required',
  )

  const associatedTenanted = useFormatMessage(
    'dashboard.createNewListing.chargerStep.tenantCategory.txt',
    'Associated Site',
  )

  const chargerText = renderFormatMessage('dashboard.text.charger', 'Charger')

  const chargerOutputText = useFormatMessage(
    'dashboard.createNewListing.chargerStep.chargerOutput.txt',
    'Charger Output (kW)',
  )

  const searchTenantText = renderFormatMessage('dashboard.selector.searchTenant', 'Search Site')

  return {
    customNotifText,
    customNotifPlaceholderText,
    tenantIsRequired,
    associatedTenanted,
    chargerText,
    chargerOutputText,
    searchTenantText,
  }
}
