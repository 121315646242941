import { Col, Divider, Form, Select } from 'antd'
import { usePricingSchemasTranslation } from '../../../../hooks/translation/usePricingSchemaTranslation'
import { useTenantTabPricingTranslation } from '../../../../hooks/translation/useTenantTabPricingTranslation'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { DarkGrayBackground } from 'atom/discount'
import { PricingSchemaPayload } from 'models/price'
import CoreBadge from 'components/badge/Badge'

interface props {
  index: number
  pricingSchemaValues: PricingSchemaPayload | undefined
}

export const DiscountConstantsSectionModal: React.FC<props> = ({ index, pricingSchemaValues }) => {
  const { constantsText } = usePricingSchemasTranslation()
  const { flatFeeText } = useTenantTabPricingTranslation()
  const { flatFeeInfoText, basePlanText, noFlatFeeText } = useDiscountSchemaTranslation()

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{constantsText}</span>
      </Divider>
      <DarkGrayBackground>
        <Col span={10}>
          <Form.Item label={<>{basePlanText}:</>}>
            {pricingSchemaValues?.flat_fee === 0 ? (
              <div className="paragraph-01-regular">{noFlatFeeText}</div>
            ) : (
              <CoreBadge title={`$${pricingSchemaValues?.flat_fee?.toFixed(2)}`} />
            )}
          </Form.Item>
        </Col>
      </DarkGrayBackground>
      <Form.Item
        label={<>{flatFeeText}:</>}
        name={[index, 'flat_fee']}
        style={{ alignItems: 'flex-start', marginBottom: '0px' }}
        className="flat-fee"
        help={flatFeeInfoText}
      >
        <Select disabled={pricingSchemaValues?.flat_fee === 0}>
          <Select.Option value={pricingSchemaValues?.flat_fee}>
            {pricingSchemaValues?.flat_fee?.toFixed(2)}
          </Select.Option>
          {pricingSchemaValues?.flat_fee !== 0 && <Select.Option value={0}>0.00</Select.Option>}
        </Select>
      </Form.Item>
    </>
  )
}
