export const mockStripeAccountsResp = [
  [
    {
      id: 'v7NpvrHi6nT225fFCs9t3A',
      name: 'Sam Arabastani',
      stripeAccount: [
        {
          id: 1,
          name: 'Test123',
        },
        {
          id: 2,
          name: 'Quadreal Property Group',
        },
      ],
    },
    {
      id: 'ZHJGhjH9RgKk0JhpVLUfow',
      name: 'Julien Cassis',
      stripeAccount: [
        {
          id: 3,
          name: 'Swtch Test Account',
        },
      ],
    },
    {
      id: '53IlmglIiKas4XUYNxdSbA',
      name: 'Laura Bryson',
      stripeAccount: [],
    },
    {
      id: 'Mh7VeLYsfbUjz_wt8cqFkQ',
      name: 'Artem MAIN Rakov',
      stripeAccount: [
        {
          id: 4,
          name: 'Artem account',
        },
      ],
    },
  ],
]
