import { theme } from '../../theme'

export const dangerSVG = (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4139 11.7202L11.8966 2.02072C11.1552 0.715671 10.1293 0 9 0C7.87068 0 6.84481 0.715671 6.10343 2.02072L0.586142 11.7202C-0.11214 12.9578 -0.189726 14.145 0.370623 15.0796C0.930972 16.0142 2.03443 16.5278 3.48272 16.5278H14.5173C15.9656 16.5278 17.069 16.0142 17.6294 15.0796C18.1897 14.145 18.1121 12.9494 17.4139 11.7202ZM8.35344 5.89376C8.35344 5.54855 8.64655 5.26228 9 5.26228C9.35345 5.26228 9.64656 5.54855 9.64656 5.89376V10.1036C9.64656 10.4488 9.35345 10.7351 9 10.7351C8.64655 10.7351 8.35344 10.4488 8.35344 10.1036V5.89376ZM9.61207 13.2273C9.56897 13.261 9.52587 13.2946 9.48276 13.3283C9.43104 13.362 9.37931 13.3872 9.32759 13.4041C9.27586 13.4293 9.22414 13.4462 9.16379 13.4546C9.11207 13.463 9.05172 13.4714 9 13.4714C8.94827 13.4714 8.88793 13.463 8.82758 13.4546C8.77586 13.4462 8.72414 13.4293 8.67241 13.4041C8.62069 13.3872 8.56896 13.362 8.51724 13.3283C8.47413 13.2946 8.43103 13.261 8.38793 13.2273C8.23275 13.0673 8.13792 12.8484 8.13792 12.6295C8.13792 12.4106 8.23275 12.1917 8.38793 12.0317C8.43103 11.998 8.47413 11.9643 8.51724 11.9306C8.56896 11.897 8.62069 11.8717 8.67241 11.8549C8.72414 11.8296 8.77586 11.8128 8.82758 11.8044C8.93965 11.7791 9.06034 11.7791 9.16379 11.8044C9.22414 11.8128 9.27586 11.8296 9.32759 11.8549C9.37931 11.8717 9.43104 11.897 9.48276 11.9306C9.52587 11.9643 9.56897 11.998 9.61207 12.0317C9.76725 12.1917 9.86207 12.4106 9.86207 12.6295C9.86207 12.8484 9.76725 13.0673 9.61207 13.2273Z"
      fill={theme.colors.dangerPrimary}
    />
  </svg>
)
