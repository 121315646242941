import { Alert, Button, Col, DatePicker, Form, Input, Row, Spin } from 'antd'
import en_US from 'antd/es/date-picker/locale/en_US'
import fr_CA from 'antd/es/date-picker/locale/fr_CA'
import moment from 'moment'
import pluralize from 'pluralize'
import { useState } from 'react'
import { MoreSpacing } from '../../../atom/form/form-layout'
import { layout } from '../../../atom/form/modal-layout'
import { ChargerRef } from '../../../models/charger'
import { RemotePayloadResponse } from '../../../models/charger-remote'
import { SwtchError } from '../../../models/error'
import { RemoteUpdateFirmware } from '../../../services/data-provider/charger-remote'
import { useAppState } from '../../../state'
import { InputWrapper } from '../../../atom/chargers'
import { useChargerTranslation } from '../../../hooks/translation/useChargerTranslation'

interface props {
  chargers: ChargerRef[]
}

interface FirmwareDataProps {
  location: string
  date: moment.Moment
}

export const RemoteFirmwareUpdateControllerRedesign: React.FC<props> = ({ chargers }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const filterChargers = chargers.filter((v, i) => {
    return chargers.map((val) => val.id).indexOf(v.id) === i
  })

  const [loading, setLoading] = useState(false)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)
  const [error, setError] = useState<SwtchError[]>([])
  const [message, setMessage] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<RemotePayloadResponse[]>([])
  const { language } = useAppState()
  const {
    chargerText,
    locationWarningText,
    retrieveWarningText,
    locationTextHelp,
    updateFirmwareText,
    sendingFirmwareUpdateChangesText,
  } = useChargerTranslation()

  const chooseDatePickerLocale = (lang: string) => {
    let locale = undefined
    switch (lang) {
      case 'fr':
        locale = fr_CA
        break
      case 'en':
        locale = en_US
        break
      default:
        locale = en_US
    }
    return locale
  }

  const resetView = (loading: boolean) => {
    setLoading(loading)
    setError([])
    setPassed(0)
    setFailed(0)
  }

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  const onFinish = (values: FirmwareDataProps) => {
    resetView(true)
    RemoteUpdateFirmware({
      charger_ids: filterChargers.map((f) => f.id),
      location: values['location'],
      date: values['date'].format(),
    })
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        resp.payload.map((r: RemotePayloadResponse) => {
          return r.success
            ? setMessage((message) => [...message, r.message])
            : setErrorMessage((error) => [...error, r])
        })
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  return (
    <Row>
      <Col>
        <Form {...chooseLayout()} onFinish={onFinish}>
          <InputWrapper>
            <Form.Item name="location" rules={[{ required: true, message: locationWarningText }]}>
              <Input placeholder="ftp://XXX.XXX.XXX.XXXX/firmware" required={true} />
            </Form.Item>
            <MoreSpacing>
              <Form.Item
                name="date"
                rules={[{ required: true, message: retrieveWarningText }]}
                hasFeedback={true}
                help={locationTextHelp}
              >
                <DatePicker
                  format={'YYYY/MM/DD HH:mm'}
                  disabledDate={(current) => current <= moment().startOf('day')}
                  allowClear={false}
                  showTime={{ format: 'HH:mm' }}
                  locale={chooseDatePickerLocale(language)}
                />
              </Form.Item>
            </MoreSpacing>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {updateFirmwareText}
              </Button>
            </Form.Item>
          </InputWrapper>
        </Form>
      </Col>
      <Col span={24}>
        {loading && (
          <Alert
            message={
              <>
                <Spin style={{ marginRight: '10px' }} />
                {`${sendingFirmwareUpdateChangesText} ${
                  filterChargers.length > 1 ? filterChargers.length : ''
                } ${pluralize(
                  `${chargerText}${
                    filterChargers.length === 1 ? filterChargers.map((c) => ` ${c.chargePointSerialNumber}`) : ''
                  }`,
                  filterChargers.length || 1,
                )}!`}
              </>
            }
            type="info"
          />
        )}
        {passed >= 1 && <Alert message={`Changes ${message[0]}`} type="info" />}
        {failed >= 1 &&
          errorMessage.map((err, index) => (
            <Alert key={index} message={err.serialNumber} description={err.message} type="error" />
          ))}
        {failed >= 1 &&
          error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
      </Col>
    </Row>
  )
}
