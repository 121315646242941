import React from 'react'
import { AvailableLanguages } from './models/language'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { BaseLocal } from './intl/base'
import { French } from './intl/fr'
import { English } from './intl/en'
import frCA from 'antd/lib/locale-provider/fr_CA'
import enUS from 'antd/lib/locale-provider/en_US'
import { useAppState } from './state'
import moment from 'moment'

const defaultLocale = 'en'
const messages: { [ln: string]: BaseLocal } = {
  fr: French,
  en: English,
}

const getConfigProviderLocale = (lang: AvailableLanguages) => {
  let locale = undefined
  switch (lang) {
    case 'fr':
      locale = frCA
      break
    case 'en':
      locale = enUS
      break
    default:
      locale = enUS
  }
  return locale
}

export const Locale: React.FC = ({ children }) => {
  const { language } = useAppState()
  let languageMessage: Record<string, string> = {}
  languageMessage = messages[language]
  moment.locale(language as string)
  return (
    <IntlProvider locale={language} defaultLocale={defaultLocale} messages={languageMessage}>
      <ConfigProvider locale={getConfigProviderLocale(language)}>{children}</ConfigProvider>
    </IntlProvider>
  )
}
