import { InfoCircleOutlined } from '@ant-design/icons'
import { Badge, Col, Row, Statistic, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

import { Links } from '../../components/routes/paths'
import { Widget } from '../../components/widget'
import { useFormatMessage } from '../../helpers/intl'
import { addQueryString } from '../../helpers/url'
import { ConnectorMetricCounts } from '../../models/analytics'
import { TenantRef } from '../../models/tenant'
import { theme } from '../../theme'

interface props {
  tenants?: TenantRef[]
  metrics?: ConnectorMetricCounts
  loading: boolean
}

export const badgeColor = (text: string, color: string) => {
  return <Badge count={text} style={{ backgroundColor: color }} />
}

export const ConnectorSummaryDetails: React.FC<props> = ({ tenants, metrics, loading }) => {
  const comissioningText = useFormatMessage('dashboard.homePage.commissioning', 'Commissioning')

  const commission = metrics?.commissionCount || 0
  const totalCount = metrics?.totalCount || 0
  const inUse = metrics?.inUseCount || 0
  const available = metrics?.availableCount || 0
  const unavailable = metrics?.unavailableCount || 0
  const active = metrics?.activeCount || 0
  const activating = metrics?.activatingCount || 0
  const underRepair = metrics?.underRepairCount || 0

  const renderFilterLink = (
    ocppStatusAlias?: 'available' | 'inuse' | 'unavailable',
    serviceStatusAlias?: 'commissioning' | 'active' | 'activating' | 'underRepair',
  ) => {
    let queryParams: {
      [key: string]: string
    } = {}

    if (ocppStatusAlias) {
      queryParams['ocppStatus'] = ocppStatusAlias
    }

    if (serviceStatusAlias) {
      queryParams['serviceStatus'] = serviceStatusAlias
    }
    if (tenants && tenants.length > 0) {
      queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
      queryParams['tenant_name'] = `${tenants.map((t) => t.name)}`
      queryParams['tenant_displayName'] = `${tenants.map((t) => t.displayName || t.name)}`
    }

    return addQueryString(Links.chargers(), queryParams)
  }
  return (
    <>
      <Row gutter={[8, 8]} style={{ marginTop: '8px' }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink()}>
            <Widget
              title={useFormatMessage('dashboard.homePage.totalConnectors', 'Total Connectors')}
              loading={loading}
              header={<Statistic value={totalCount} />}
              hoverable
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink('inuse', 'active')}>
            <Widget
              title={badgeColor(useFormatMessage('dashboard.homePage.inuse', 'In Use'), theme.colors.blue)}
              loading={loading}
              header={<Statistic value={inUse} />}
              hoverable
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink('available', 'active')}>
            <Widget
              title={badgeColor(useFormatMessage('dashboard.homePage.available', 'Available'), theme.colors.green)}
              loading={loading}
              header={<Statistic value={available} />}
              hoverable
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink('unavailable', 'active')}>
            <Widget
              title={badgeColor(useFormatMessage('dashboard.homePage.unavailable', 'Unavailable'), theme.colors.red)}
              loading={loading}
              header={<Statistic value={unavailable} />}
              hoverable
            />
          </Link>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: '8px' }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink(undefined, 'active')}>
            <Widget
              title={useFormatMessage('dashboard.homePage.totalActive', 'Total Active Connectors')}
              loading={loading}
              header={<Statistic value={active} />}
              hoverable
              extra={
                <Tooltip
                  title={useFormatMessage(
                    'dashboard.homePage.info.totalActive',
                    'These are all connectors from the Available, In Use, and Unavailable blocks',
                  )}
                >
                  <InfoCircleOutlined style={{ fontSize: theme.fontSizes[4] }} />
                </Tooltip>
              }
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink(undefined, 'underRepair')}>
            <Widget
              title={badgeColor(useFormatMessage('dashboard.homePage.underRepair', 'Under Repair'), theme.colors.red)}
              loading={loading}
              header={<Statistic value={underRepair} />}
              hoverable
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink(undefined, 'activating')}>
            <Widget
              title={badgeColor(useFormatMessage('dashboard.homePage.activating', 'Activating'), theme.colors.yellow)}
              loading={loading}
              header={<Statistic value={activating} />}
              hoverable
              extra={
                <Tooltip
                  title={useFormatMessage(
                    'dashboard.homePage.info.totalActivating',
                    'Connectors where service status is set to Commissioned, Activating - Pending Property, or Activating - Pending Driver Subscription',
                  )}
                >
                  <InfoCircleOutlined style={{ fontSize: theme.fontSizes[4] }} />
                </Tooltip>
              }
            />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Link to={renderFilterLink(undefined, 'commissioning')}>
            <Widget
              title={badgeColor(comissioningText, theme.colors.gray)}
              loading={loading}
              header={<Statistic value={commission} />}
              hoverable
              extra={
                <Tooltip
                  // title="hi"
                  title={useFormatMessage(
                    'dashboard.homePage.info.totalCommissioning',
                    'Connectors where service status is set to On Hold, Awaiting Commissioning, or Troubleshooting',
                  )}
                >
                  <InfoCircleOutlined style={{ fontSize: theme.fontSizes[4] }} />
                </Tooltip>
              }
            />
          </Link>
        </Col>
      </Row>
    </>
  )
}
