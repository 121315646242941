import { Charger } from '../../models/charger'
import { Connector } from '../../models/connector'
import { humanizeTimestamp } from '../timestamp-format'

export const getLastConnectionTime = (
  charger: Charger,
  disconnectedText: string,
  howLongAgoText: string,
  connector?: Connector,
) => {
  let connTimeHuman = humanizeTimestamp(charger.lastConnectedAt, howLongAgoText)

  // If charger is online, use lastStatusChangedAt from connector
  if (connector?.lastStatusChangedAt && charger.online) {
    connTimeHuman = humanizeTimestamp(connector.lastStatusChangedAt, howLongAgoText)
  }

  // If charger is offline, use lastDisconnectedAt from charger
  if (charger.lastDisconnectedAt && !charger.online) {
    connTimeHuman = `${disconnectedText} ${humanizeTimestamp(charger.lastDisconnectedAt, howLongAgoText)}`
  }

  return connTimeHuman
}
