import { useState } from 'react'
import { AutoComplete, Input, message, Spin, Tag } from 'antd'
import { ScheduleOutlined } from '@ant-design/icons'
import { SelectProps } from 'antd/es/select'
import { SwtchError } from '../../models/error'
import { NoSearchIcon } from '../../atom/search-icon'
import { Plan } from '../../models/plan'
import { FindPlan } from '../../services/data-provider/plan'
import { debounce } from 'lodash'
import { trashcanSVG } from '../../assets/svg/trashcan'
import { ButtonModal } from '../../atom/button'
import { theme } from '../../theme'
import { usePlanTranslation } from '../../hooks/translation/usePlanTranslation'

const { Search } = Input

interface props {
  onPlanSelected: (plan: Plan) => void
  onSearch: (plan: Plan) => void
  defaultPlan?: Plan
}

const renderPlan = (plan: Plan): any => {
  return {
    value: plan.name,
    plan,
    label: (
      <div>
        <ScheduleOutlined /> {plan.name}
      </div>
    ),
  }
}

export const PlanSelectorRedesign: React.FC<props> = ({ onPlanSelected, onSearch, defaultPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(defaultPlan)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const { placeholderText } = usePlanTranslation()

  const searchResult = (value: string) => {
    setLoading(true)
    FindPlan(value)
      .then((plans) => {
        const opts = plans.map((plan) => renderPlan(plan))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelectedPlan(opt.plan)
        onPlanSelected(opt.plan)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt?.value?.toString().toLowerCase() === value.toLowerCase()) {
        setSelectedPlan(opt.plan)
        onSearch(opt.plan)
      }
    })
  }

  return (
    <>
      {!selectedPlan && (
        <AutoComplete
          dropdownMatchSelectWidth={false}
          options={options}
          onSelect={onSelect}
          onSearch={debounce(searchResult, 800)}
          notFoundContent={loading ? <Spin size="small" /> : 'Search for a plan'}
        >
          <NoSearchIcon>
            <Search placeholder={placeholderText} onSearch={(e) => handleOnSearch(e)} />
          </NoSearchIcon>
        </AutoComplete>
      )}
      {selectedPlan && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Tag color={theme.colors.cultered} closable={false}>
            {selectedPlan.name}
          </Tag>
          <ButtonModal type="link" icon={trashcanSVG} click={() => setSelectedPlan(undefined)} />
        </div>
      )}
    </>
  )
}
