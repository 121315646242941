import { useEffect, useState } from 'react'
import { Button, Col, PageHeader, Row, Space } from 'antd'
import moment from 'moment'

import { MobileCalendar } from '../../atom/mobile-calendar'

import { AlertError } from '../../components/error'
import { DateRangePicker } from '../../components/datepicker/datepicker'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { LocationSelector } from '../../components/selector/location-selector'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'

import { ChargerDailyMetric } from '../../models/analytics'
import { useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'

import { GetChargerDailyMetrics } from '../../services/data-provider/analytics'
import { useAppState } from '../../state'
import { styled } from '../../theme'
import { TableView } from './views/table-view'

const SelectorWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('lg')} {
    .ant-select,
    .ant-input,
    .ant-picker.ant-picker-range {
      width: 100% !important;
      margin-bottom: 4px;
    }
    .ant-btn-block {
      margin: 4px 0px;
    }
  }
`

export const DisconnectionStatsBasePage: React.FC = () => {
  const { selectedTenant, IsLaptop, IsDesktop, IsMobile, IsTablet, language } = useAppState()
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [loading, setLoading] = useState(true)
  const [metrics, setMetrics] = useState<ChargerDailyMetric[]>([])
  const [error, setError] = useState<SwtchError>()
  const [mobileCalendarVisible, setMobileCalendarVisible] = useState(false)
  const isDesktop = IsDesktop()
  const isMobile = IsMobile()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])

  const calendarRanges: string[] = [
    useFormatMessage('dashboard.calendar.today', 'Today'),
    useFormatMessage('dashboard.calendar.thisWeek', 'This Week'),
    useFormatMessage('dashboard.calendar.thisMonth', 'This Month'),
    useFormatMessage('dashboard.calendar.thisYear', 'This Year'),
  ]

  const searchBtnText = useFormatMessage('dashboard.text.search', 'Search')

  useEffect(() => {
    getDisconnectionMetrics()
  }, [tenants, selectedCountries, selectedProvinces])

  const getDisconnectionMetrics = () => {
    setLoading(true)
    GetChargerDailyMetrics({
      endDate: endDate,
      startDate: startDate,
      defaultTenant: selectedTenant,
      tenants: tenants,
      selectedCountries: selectedCountries,
      selectedProvinces: selectedProvinces,
    })
      .then((metrics) => {
        metrics.sort((a, b) => a.from.localeCompare(b.from))
        setMetrics(metrics)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const onConfirm = (startDateTime?: moment.Moment, endDateTime?: moment.Moment) => {
    setMobileCalendarVisible(false)

    if (startDateTime && startDateTime !== startDate) {
      setStartDate(startDateTime)
    }

    if (endDateTime && endDateTime !== endDate) {
      setEndDate(endDateTime)
    }
  }

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const renderLocationSelector = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  const dataSource = metrics.map((d, index) => ({
    ...d,
    key: `${index}`,
  }))

  return (
    <>
      <PageHeader
        title={useFormatMessage('dashboard.disconnectionpage.heading', 'Disconnection Summary')}
        extra={
          <>
            {(isDesktop || isLaptop) && (
              <Row>
                <Col lg={24} xl={24} xxl={24}>
                  <Space>
                    {!selectedTenant && (
                      <MultiTenantSelector
                        onOptionsChange={(tenants) => setTenants(tenants)}
                        onClear={() => setTenants([])}
                      />
                    )}
                    <LocationSelector onSearch={renderLocationSelector} />
                    <DateRangePicker
                      onChangeDate={onChangeDate}
                      startDate={startDate}
                      endDate={endDate}
                      calendarRanges={calendarRanges}
                    />
                    <Button
                      type="primary"
                      onClick={getDisconnectionMetrics}
                      block={isDesktop || isLaptop ? false : true}
                    >
                      {searchBtnText}
                    </Button>
                  </Space>
                </Col>
              </Row>
            )}
            {(isTablet || isMobile) && (
              <SelectorWrapper>
                <Row>
                  <Col xs={24} sm={24} md={24}>
                    {!selectedTenant && (
                      <MultiTenantSelector
                        onOptionsChange={(tenants) => {
                          setTenants(tenants)
                        }}
                        onClear={() => setTenants([])}
                      />
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <LocationSelector onSearch={renderLocationSelector} />
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    {isTablet && (
                      <DateRangePicker
                        onChangeDate={onChangeDate}
                        startDate={startDate}
                        endDate={endDate}
                        calendarRanges={calendarRanges}
                      />
                    )}
                    {isMobile && (
                      <MobileCalendar
                        visible={mobileCalendarVisible}
                        setMobileCalendarVisible={setMobileCalendarVisible}
                        onCancel={() => setMobileCalendarVisible(false)}
                        handleConfirm={onConfirm}
                        startDate={startDate}
                        endDate={endDate}
                        calendarRanges={calendarRanges}
                        language={language}
                      />
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Button
                      type="primary"
                      onClick={getDisconnectionMetrics}
                      block={isDesktop || isLaptop ? false : true}
                    >
                      {searchBtnText}
                    </Button>
                  </Col>
                </Row>
              </SelectorWrapper>
            )}
          </>
        }
      />

      <Row style={{ marginTop: '8px' }}>
        <Col span={24}>
          <AlertError error={error} />
          <TableView loading={loading} dataSource={dataSource} />
        </Col>
      </Row>
    </>
  )
}

export const DisconnectionStatsPage = withAuthenticatedLayout(DisconnectionStatsBasePage)
