import { buildApiBaseUrl } from '../../helpers/url'
import { mockAdminJwt, mockSelf } from './mock'
import configProvider from '../../config'
import { apiClient, authApiClient } from './client'
import { JWTIssue } from '../../models/auth'
import { User } from '../../models/user'
import { log } from '../../logger'

export async function JWTFromAuth0(idToken: string): Promise<JWTIssue> {
  const url = `${buildApiBaseUrl()}/auth`
  log('fetching user auth', { url: url })

  const body = { idToken: idToken }
  log('getting JWT via auth0', { url: url, body: body })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAdminJwt)
  }

  return authApiClient('', {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export async function GetSelf(): Promise<User> {
  const url = '/users/self'
  log('getting self', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockSelf)
  }
  return apiClient(url, {})
}
