import { useDashboardTranslation } from 'hooks/translation/useDashboardTranslation'
import { uniqueUserSVG } from '../../../../assets/svg/user'
import { TransactionMetric } from '../../../../models/analytics'
import { capitalizeFirstLetter } from 'helpers/users'
import { CoreInfoCard } from 'components/cards'

interface props {
  metrics?: TransactionMetric
  loading: boolean
}

const TotalUniqueUsers = ({ metrics, loading }: props) => {
  const { uniqueUsersText } = useDashboardTranslation()

  return (
    <CoreInfoCard
      title={capitalizeFirstLetter(uniqueUsersText)}
      wsLoading={loading}
      overviewIcon={uniqueUserSVG}
      infoText={metrics?.uniqueUsers || 0}
    />
  )
}

export default TotalUniqueUsers
