import { useEffect, useState } from 'react'
import { Table } from 'antd'
import { AlertError } from '../error'
import { SwtchError } from '../../models/error'
import { renderFormatMessage } from '../../helpers/intl'
import { Charger, ChargerDiagnosticsFile } from '../../models/charger'
import { ChargerDiagnosticsController } from './charger-diagnostics-controller'
import { GetChargerDiagnosticsFiles, GetChargerDiagnosticsFile } from '../../services/data-provider/charger'
import { PaginationMeta } from '../../models/pagination'
import { Box } from '../../atom/box'
import { FormattedDate } from 'react-intl'
import { useAppState } from '../../state'

interface props {
  charger: Charger
}

export const ChargerTabDiagnostics: React.FC<props> = ({ charger }) => {
  const [files, setFiles] = useState<ChargerDiagnosticsFile[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const { selectedTenant: defaultTenant } = useAppState()

  const load = () => {
    setLoading(true)
    GetChargerDiagnosticsFiles(currentPage, charger, defaultTenant)
      .then((files) => {
        setFiles(files.data)
        setPagination(files.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    load()
  }, [currentPage])

  const onPaginationChange = (page: number, pageSize?: number) => setCurrentPage(page)

  const promptDiagnosticsFileDownload = async (charger: Charger, filename: string) => {
    try {
      const fileContents: Blob = await GetChargerDiagnosticsFile(charger, filename)
      const contentUrl = URL.createObjectURL(fileContents)

      // This resource-freeing snippet is taken from
      // https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
      // Click handler that releases the object URL after the element has been clicked
      // This is required for one-off downloads of the blob content
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(contentUrl)
          contentLink.removeEventListener('click', clickHandler)
        }, 150)
      }

      const contentLink = document.createElement('a')
      contentLink.href = contentUrl
      contentLink.download = filename
      contentLink.addEventListener('click', clickHandler, false)
      contentLink.click()
    } catch (err) {
      const swError =
        err instanceof SwtchError
          ? err
          : new SwtchError('Could not download diagnostics file', [], err instanceof Error ? err : undefined)
      setError(swError)
    }
  }

  const columns: any = [
    {
      title: renderFormatMessage('dashboard.chargerPage.diagnostics.files.requestTime.heading', 'Request time'),
      dataIndex: 'requestTime',
      render: (requestTime: string) => (
        <FormattedDate
          value={requestTime}
          year="numeric"
          month="2-digit"
          day="2-digit"
          hour="2-digit"
          minute="2-digit"
          second="2-digit"
          timeZoneName="short"
        />
      ),
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.diagnostics.files.state.heading', 'State'),
      dataIndex: 'state',
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.diagnostics.files.filename.heading', 'Filename'),
      dataIndex: 'filename',
      key: 'filename',
      render: (value: string, record: ChargerDiagnosticsFile) => {
        if (record['state'] && record['state'] === 'uploaded') {
          return (
            <a onClick={() => promptDiagnosticsFileDownload(charger, value)} href={`#download_${value}`}>
              {value}
            </a>
          )
        } else {
          return { value }
        }
      },
    },
  ]

  return (
    <>
      <Box>
        <AlertError error={error} />
        <ChargerDiagnosticsController charger={charger} onRequestComplete={load} />
      </Box>
      <Table
        columns={columns}
        dataSource={files}
        rowKey="id"
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
          total: pagination?.totalEntries,
          current: pagination?.currentPage,
          pageSize: pagination?.perPage,
          showSizeChanger: false,
          onChange: onPaginationChange,
        }}
      />
    </>
  )
}
