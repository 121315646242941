import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingLoiteringTrans = () => {
  const emptyFieldWarning = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield',
    'Field cannot be empty!',
  )
  const loiteringStatusText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText',
    'Loitering Status',
  )
  const loiteringStatusNoPenaltyText = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none', 'None')

  const loiteringStatusTimeBasedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased',
    'Time Based',
  )

  const loiteringStatusBasedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased',
    'Status Based',
  )

  const loiteringPenaltyHourText = renderFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour',
    'Loitering Penalty Per Hour ($)',
  )

  const loiteringTimeBasedMinutesText = renderFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText',
    'Loitering Time Limit For Time-Based In Minutes',
  )

  const fieldEmptyText = renderFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield',
    'Field cannot be empty!',
  )

  const loiteringGracePeriodMinutesText = renderFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes',
    'Loitering Time Limit For Grace-Period in Minutes',
  )

  const loiteringTimeBasedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased',
    'Time Based',
  )
  return {
    emptyFieldWarning,
    loiteringStatusText,
    loiteringStatusNoPenaltyText,
    loiteringStatusTimeBasedText,
    loiteringStatusBasedText,
    loiteringPenaltyHourText,
    fieldEmptyText,
    loiteringTimeBasedMinutesText,
    loiteringGracePeriodMinutesText,
    loiteringTimeBasedText
  }
}
