import { useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
import moment from 'moment'

import { SwtchError } from '../../models/error'
import { Charger } from '../../models/charger'
import { PaginationMeta } from '../../models/pagination'
import { Connector, ConnectorLog } from '../../models/connector'

import { GetConnectorLogs } from '../../services/data-provider/connector'
import { renderFormatMessage } from '../../helpers/intl'
import { AlertError } from '../error'
import { useAppState } from '../../state'

interface props {
  charger: Charger
  connector: Connector
}

const columns = [
  {
    title: renderFormatMessage('dashboard.chargerPage.logs.timestamp.heading', 'Timestamp'),
    key: 'createdAt',
    render: (log: ConnectorLog) => moment(log.createdAt).format('YYYY-MM-DD - HH:mm:ss'),
  },
  {
    title: renderFormatMessage('dashboard.chargerPage.logs.heading', 'Log'),
    key: 'message',
    render: (log: ConnectorLog) => {
      if (log.success) {
        return log.message
      } else {
        return (
          <>
            {log.message}
            <Tag color="red" style={{ marginLeft: '10px' }}>
              error
            </Tag>
          </>
        )
      }
    },
  },
  {
    title: renderFormatMessage('dashboard.chargerPage.logs.errorCode.heading', 'Error Code'),
    key: 'errorCode',
    render: (log: ConnectorLog) => log.errorCode || null,
  },
  {
    title: renderFormatMessage('dashboard.chargerPage.logs.vendorErrorCode.heading', 'Vendor Error Code'),
    key: 'vendorErrorCode',
    render: (log: ConnectorLog) => log.vendorErrorCode || null,
  },
]
export const ChargerTabLog: React.FC<props> = ({ charger, connector }) => {
  const [logs, setLogs] = useState<ConnectorLog[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()

  const { selectedTenant: defaultTenant } = useAppState()

  useEffect(() => {
    setLoading(true)
    GetConnectorLogs(connector, currentPage, defaultTenant)
      .then((resp) => {
        setPagination(resp.pagination)
        setLogs(resp.data)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [charger, connector, currentPage])

  const onPaginationChange = (page: number, pageSize?: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      <AlertError error={error} />
      <Table
        size="small"
        columns={columns}
        dataSource={logs}
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
          total: pagination?.totalEntries,
          current: pagination?.currentPage,
          pageSize: pagination?.perPage,
          showSizeChanger: false,
          onChange: onPaginationChange,
        }}
        rowKey={(log: ConnectorLog): string => `${log.createdAt}-${log.message}`}
      />
    </>
  )
}
