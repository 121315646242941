import { Button, Divider, Form, Input, InputNumber, Select, Space, Switch } from 'antd'
import { InfoTextWrapper } from '../../../atom/price'
import { TimeContainer } from '../../../pages/peakShaving/modal/create-new-event-modal'
import { EditButtonWrapper } from '../../../atom/tenants'
import { plusSVG } from '../../../assets/svg/plus'
import { infoWithISVG } from '../../../assets/svg/info'
import { PricingSchemaEventSchedule } from '../../../models/price'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { FormInstance } from 'antd/es/form/Form'

interface props {
  form: FormInstance
  eventSchedules: PricingSchemaEventSchedule[]
  timeOfDayLoitering: boolean | undefined
  loiteringType: string | undefined
  setPricePerUnitLoitering: (pricePerUnitLoitering: number) => void
  setTimeOfDayLoitering: (enableTimeOfDayLoiteringPricing: boolean) => void
  handleSelect: (type: string, form: FormInstance) => void
  setLoiteringType: (loiteringType: string) => void
}

export const LoiteringSectionModal: React.FC<props> = ({
  form,
  timeOfDayLoitering,
  eventSchedules,
  loiteringType,
  setPricePerUnitLoitering,
  setTimeOfDayLoitering,
  handleSelect,
  setLoiteringType,
}) => {
  const {
    loiteringText,
    hoursNotDefinedText,
    toText,
    loiteringRateText,
    gracePeriodText,
    timeLimitText,
    timeOfDayLoiteringText,
    weekdayLoiteringRateText,
    weekendLoiteringRateText,
    maxLoiteringText,
    timeText,
    maxLoiteringInfoText,
  } = usePricingSchemasTranslation()
  const { noneText } = useUserTranslation()
  const { statusText } = useGeneralTranslation()

  const loiteringTypeSelector = (
    <Form.Item name="loitering_type" className="loitering-type">
      <Select
        className="combine-right"
        style={{ borderRightWidth: '2px' }}
        onChange={(value) => {
          if (value !== 'none') {
            form.setFieldsValue({ loitering_dollar_rate: '1' })
            setPricePerUnitLoitering(1)
          } else {
            form.setFieldsValue({ loitering_dollar_rate: '0' })
            setPricePerUnitLoitering(0)
          }
          setLoiteringType(value)
        }}
        dropdownClassName="dropdown-large-font"
      >
        <Select.Option value={'none'}>{noneText}</Select.Option>
        <Select.Option value={'time_based'}>{timeText}</Select.Option>
        <Select.Option value={'status_based'}>{statusText}</Select.Option>
      </Select>
    </Form.Item>
  )

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{loiteringText}</span>
      </Divider>
      <Form.Item
        label={<>{loiteringRateText}:</>}
        name="loitering_dollar_rate"
        style={{ marginBottom: '25px', alignItems: 'center' }}
      >
        <InputNumber
          precision={2}
          controls={false}
          addonBefore={loiteringTypeSelector}
          className="combine-left"
          disabled={loiteringType === 'none' ? true : false}
          onChange={(value) => setPricePerUnitLoitering(Number(value))}
        />
      </Form.Item>
      {loiteringType === 'status_based' && (
        <Form.Item
          label={<>{gracePeriodText}:</>}
          name="loitering_grace_period_minutes"
          style={{ marginBottom: '28px', alignItems: 'center' }}
        >
          <Input />
        </Form.Item>
      )}
      {loiteringType === 'time_based' && (
        <>
          <Form.Item
            label={<>{timeLimitText}:</>}
            name="loitering_time_limit_minutes"
            style={{ marginBottom: '28px', alignItems: 'center' }}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {loiteringType !== 'none' && (
        <>
          <Form.Item
            label={<>{maxLoiteringText}:</>}
            name="loitering_maximum"
            className="loitering-maximum"
            help={maxLoiteringInfoText}
          >
            <InputNumber precision={2} controls={false} />
          </Form.Item>
          <Form.Item label={<>{timeOfDayLoiteringText}:</>}>
            <div style={{ display: 'flex' }}>
              <Switch checked={timeOfDayLoitering} onChange={(value) => setTimeOfDayLoitering(value)} />{' '}
              {timeOfDayLoitering && (
                <InfoTextWrapper>
                  {infoWithISVG} {hoursNotDefinedText}
                </InfoTextWrapper>
              )}
            </div>
          </Form.Item>
          {timeOfDayLoitering && (
            <>
              <Form.List name="weekdayLoiteringRateExtras">
                {(fields, { add }) => (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} style={{ marginTop: key === 0 ? '20px' : '0px' }}>
                          <Space key={key} style={{ marginBottom: 8 }} align="end">
                            <Form.Item
                              name={[key, 'dollar_rate']}
                              label={key === 0 ? <>{weekdayLoiteringRateText}:</> : ''}
                              style={{ marginLeft: key === 0 ? '' : '131px' }}
                            >
                              <InputNumber precision={2} controls={false} />
                            </Form.Item>
                            <TimeContainer>
                              <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                                <Select
                                  style={{ width: '141px' }}
                                  onSelect={() => {
                                    handleSelect('weekdayLoiteringRateExtras', form)
                                  }}
                                  dropdownClassName="dropdown-large-font"
                                >
                                  {eventSchedules.map((eventSchedule, _) => {
                                    return (
                                      <Select.Option
                                        value={eventSchedule.from}
                                        key={
                                          eventSchedule.from < 10
                                            ? `0${eventSchedule.from}:00`
                                            : `${eventSchedule.from}:00`
                                        }
                                      >
                                        {eventSchedule.from < 10
                                          ? `0${eventSchedule.from}:00`
                                          : `${eventSchedule.from}:00`}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </TimeContainer>
                            <div
                              style={{ height: '38px', display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}
                            >
                              {toText}
                            </div>
                            <TimeContainer>
                              <Form.Item name={[key, 'to']}>
                                <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                                  {eventSchedules.map((eventSchedule, _) => {
                                    return (
                                      <Select.Option
                                        value={eventSchedule.to}
                                        key={
                                          eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`
                                        }
                                      >
                                        {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </TimeContainer>
                          </Space>
                        </div>
                      ))}
                    </div>
                    <Form.Item>
                      <EditButtonWrapper>
                        <Button
                          className="add-rate-button"
                          type="primary"
                          shape="circle"
                          icon={plusSVG}
                          onClick={add}
                          loading={false}
                          disabled={false}
                        />
                      </EditButtonWrapper>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
              <Form.List name="weekendLoiteringRateExtras">
                {(fields, { add }) => (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8, marginTop: 0 }} align="end">
                          <Form.Item
                            name={[key, 'dollar_rate']}
                            label={key === 0 ? <>{weekendLoiteringRateText}:</> : ''}
                            style={{ marginLeft: key === 0 ? '' : '131px' }}
                          >
                            <InputNumber precision={2} controls={false} />
                          </Form.Item>
                          <TimeContainer>
                            <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                              <Select
                                onSelect={() => {
                                  handleSelect('weekendLoiteringRateExtras', form)
                                }}
                                style={{ width: '141px' }}
                                dropdownClassName="dropdown-large-font"
                              >
                                {eventSchedules.map((eventSchedule, _) => {
                                  return (
                                    <Select.Option
                                      value={eventSchedule.from}
                                      key={
                                        eventSchedule.from < 10
                                          ? `0${eventSchedule.from}:00`
                                          : `${eventSchedule.from}:00`
                                      }
                                    >
                                      {eventSchedule.from < 10
                                        ? `0${eventSchedule.from}:00`
                                        : `${eventSchedule.from}:00`}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </TimeContainer>
                          <div
                            style={{ height: '38px', display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}
                          >
                            {toText}
                          </div>
                          <TimeContainer>
                            <Form.Item name={[key, 'to']}>
                              <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                                {eventSchedules.map((eventSchedule, _) => {
                                  return (
                                    <Select.Option
                                      value={eventSchedule.to}
                                      key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                    >
                                      {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </TimeContainer>
                        </Space>
                      ))}
                    </div>
                    <Form.Item>
                      <EditButtonWrapper>
                        <Button
                          className="add-rate-button"
                          type="primary"
                          shape="circle"
                          icon={plusSVG}
                          onClick={add}
                          loading={false}
                          disabled={false}
                        />
                      </EditButtonWrapper>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </>
          )}
        </>
      )}
    </>
  )
}
