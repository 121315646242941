import { FormInstance } from 'antd'
import { DiscountFormValues, DiscountSchema, DiscountSchemaPayload } from 'models/discount'
import { SwtchError } from 'models/error'
import { DiscountResponse } from 'models/http'
import { NewListing } from 'models/listing'
import {
  BasicListingInfo,
  ListingInfo,
  PricingSchema,
  PricingSchemaEventSchedule,
  PricingSchemaPayload,
} from 'models/price'
import { Tenant } from 'models/tenant'
import { useEffect, useState } from 'react'
import {
  ApplyDiscountsToListings,
  ArchiveDiscountSchema,
  CreateDiscountSchema,
  GetDiscountNames,
  UpdateDiscountSchema,
} from 'services/data-provider/discount'

export const useDiscountSchemas = (
  tenant: Tenant,
  onOk: () => void,
  onCancel: () => void,
  pricingSchema?: PricingSchemaPayload | undefined,
  discounts?: DiscountSchema[],
  pricingSchemaListings?: NewListing[] | BasicListingInfo[],
  form?: FormInstance,
  modalType?: string,
  selectedDiscountSchema?: DiscountSchema,
) => {
  const [loading, setLoading] = useState(false)
  const [pricingSchemaName, setPricingSchemaName] = useState('')
  const [loiteringType, setLoiteringType] = useState(pricingSchema?.loitering_type)
  const [timeOfDay, setTimeOfDay] = useState<boolean>(
    selectedDiscountSchema
      ? !selectedDiscountSchema.discountPercentageWeekday?.every(
          (discount) => discount === selectedDiscountSchema.discountPercentageWeekday[0],
        ) ||
          !selectedDiscountSchema.discountPercentageWeekend?.every(
            (discount) => discount === selectedDiscountSchema.discountPercentageWeekend[0],
          )
      : false,
  )
  const [timeOfDayLoitering, setTimeOfDayLoitering] = useState<boolean>(false)
  const [pricePerUnitLoitering, setPricePerUnitLoitering] = useState<number>(1)
  const [timeOfDayPriceWeekendLoitering, setTimeOfDayPricingWeekendLoitering] = useState<number[]>([])
  const [timeOfDayPriceWeekdayLoitering, setTimeOfDayPricingWeekDayLoitering] = useState<number[]>([])
  const [discountNames, setDiscountNames] = useState<string[]>([])
  const [promoCodes, setPromoCodes] = useState<string[]>([])
  const [discountNameExists, setDiscountNameExists] = useState({})
  const [promoCodeExists, setPromoCodeExists] = useState({})
  const [current, setCurrent] = useState(0)
  const [listings, setListings] = useState<NewListing[] | BasicListingInfo[]>(
    pricingSchemaListings ? pricingSchemaListings : [],
  )
  const [nextInModal, setNextInModal] = useState(false)
  const [discountId, setDiscountId] = useState(0)
  const [pricingSchemaValues, setPricingSchemaValues] = useState(pricingSchema)
  const [basePricingPlanRate, setBasePricingPlanRate] = useState(
    pricingSchema?.dollar_rate ? pricingSchema?.dollar_rate : 1,
  )
  const [basePricingPlanLoiteringRate, setBasePricingPlanLoiteringRate] = useState(
    pricingSchema?.loitering_dollar_rate ? pricingSchema?.loitering_dollar_rate : 1,
  )
  const discountPeriodArr = Array.from({ length: 48 }, () => 1)
  const eventSchedules: PricingSchemaEventSchedule[] = Array.from(Array(24), (_, index) => ({
    from: index,
    to: index + 1,
    dollar_rate: '1',
  }))

  const [defaultEventSchedule, setDefaultEventSchedule] = useState([
    {
      dollar_rate: basePricingPlanRate,
      to: 1,
      from: 0,
    },
  ])

  const [defaultLoiteringEventSchedule, setDefaultLoiteringEventSchedule] = useState([
    {
      dollar_rate: basePricingPlanLoiteringRate,
      to: 1,
      from: 0,
    },
  ])

  const isRateTheSame = (arr: number[]) => {
    if (arr.length === 0) return true
    return arr.every((num) => num === arr[0])
  }
  const checkLoiteringSuspensionPeriod = (selectedDiscountSchema: DiscountSchema) => {
    if (!selectedDiscountSchema) return false

    const { loiteringSuspensionPeriodWeekday, loiteringSuspensionPeriodWeekend } = selectedDiscountSchema
    return !isRateTheSame(loiteringSuspensionPeriodWeekday) || !isRateTheSame(loiteringSuspensionPeriodWeekend)
  }

  useEffect(() => {
    if (selectedDiscountSchema) setTimeOfDayLoitering(checkLoiteringSuspensionPeriod(selectedDiscountSchema))
  }, [selectedDiscountSchema])

  useEffect(() => {
    const discountArr: string[] = []
    discounts?.map((discount: DiscountSchema) => {
      if (discount.promoCode) discountArr.push(discount.promoCode)
      return discountArr
    })
    setPromoCodes(discountArr)
  }, [])

  useEffect(() => {
    setDefaultEventSchedule([
      {
        dollar_rate: basePricingPlanRate,
        to: 1,
        from: 0,
      },
    ])

    setDefaultLoiteringEventSchedule([
      {
        dollar_rate: basePricingPlanLoiteringRate,
        to: 1,
        from: 0,
      },
    ])
  }, [basePricingPlanRate, basePricingPlanLoiteringRate])

  const calculateDollarRateFromDiscountPlan = (
    selectedDiscountSchema: DiscountSchema,
    pricingSchema: PricingSchemaPayload,
  ) => {
    const modePercentage =
      (100 -
        Number(
          getMode([
            ...(selectedDiscountSchema.discountPercentageWeekday || []),
            ...(selectedDiscountSchema.discountPercentageWeekend || []),
          ]),
        )) /
      100
    const pricingPlanBasePlanRate =
      Number(
        getMode([...(pricingSchema.timeOfDayPriceWeekday || []), ...(pricingSchema.timeOfDayPriceWeekend || [])]),
      ) / 100

    if (modePercentage === 1) return pricingPlanBasePlanRate
    if (modePercentage === 0) return 0
    return pricingPlanBasePlanRate * modePercentage
  }

  useEffect(() => {
    if (pricingSchema) {
      if (pricingSchema?.applied_listings) setListings(pricingSchema?.applied_listings)
      form?.setFieldsValue({
        discount_schema_form: [
          {
            name: selectedDiscountSchema ? selectedDiscountSchema.name : '',
            promo_code: selectedDiscountSchema ? selectedDiscountSchema.promoCode : '',
            stripe_account_id: null,
            price_type: pricingSchema && pricingSchema.price_type,
            flat_fee: selectedDiscountSchema?.ignoreConstantFee ? 0 : pricingSchema && pricingSchema.flat_fee,
            loitering_type: selectedDiscountSchema?.ignoreLoiteringFee ? 'none' : pricingSchema.loitering_type,
            dollar_rate: selectedDiscountSchema
              ? calculateDollarRateFromDiscountPlan(selectedDiscountSchema, pricingSchema)
              : pricingSchema.dollar_rate,
            pricing_schema_dollar_rate: selectedDiscountSchema
              ? Number(
                  getMode([
                    ...(pricingSchema.timeOfDayPriceWeekday || []),
                    ...(pricingSchema.timeOfDayPriceWeekend || []),
                  ]),
                ) / 100
              : pricingSchema.dollar_rate,
            loitering_dollar_rate: selectedDiscountSchema
              ? Number(
                  getMode([
                    ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
                    ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
                  ]),
                ) / 100
              : pricingSchema.loitering_dollar_rate,
            weekdayRateExtras: selectedDiscountSchema
              ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekday')
              : timeOfDayRatesPricingSchema(pricingSchema, 'timeOfDayWeekday', modalType),
            weekendRateExtras: selectedDiscountSchema
              ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekend')
              : timeOfDayRatesPricingSchema(pricingSchema, 'timeOfDayWeekend', modalType),
            weekdayLoiteringRateExtras: selectedDiscountSchema
              ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekdayLoitering')
              : timeOfDayRatesPricingSchema(pricingSchema, 'timeOfDayWeekdayLoitering', modalType),
            weekendLoiteringRateExtras: selectedDiscountSchema
              ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekendLoitering')
              : timeOfDayRatesPricingSchema(pricingSchema, 'timeOfDayWeekendLoitering', modalType),
            enable_time_of_day:
              modalType === 'both'
                ? !!pricingSchema.enable_time_of_day
                : !isRateTheSame([
                    ...(pricingSchema.timeOfDayPriceWeekday || []),
                    ...(pricingSchema.timeOfDayPriceWeekend || []),
                  ]),
            enable_time_of_day_loitering:
              modalType === 'both'
                ? !!pricingSchema.enable_time_of_day_loitering
                : !isRateTheSame([
                    ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
                    ...(pricingSchema?.timeOfDayLoiteringWeekend || []),
                  ]),
            loitering_time_limit_minutes: pricingSchema.loitering_time_limit_minutes,
          },
        ],
      })

      setTimeOfDay(
        modalType === 'both'
          ? !!pricingSchema.enable_time_of_day
          : !isRateTheSame([
              ...(pricingSchema?.timeOfDayPriceWeekday || []),
              ...(pricingSchema?.timeOfDayPriceWeekend || []),
            ]),
      )
      setTimeOfDayLoitering(
        modalType === 'both'
          ? !!pricingSchema.enable_time_of_day_loitering
          : !isRateTheSame([
              ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
              ...(pricingSchema?.timeOfDayLoiteringWeekend || []),
            ]),
      )
      setBasePricingPlanRate(
        modalType !== 'both'
          ? Number(
              getMode([
                ...(pricingSchema?.time_of_day_price_weekday || []),
                ...(pricingSchema?.time_of_day_price_weekend || []),
              ]),
            ) / 100
          : pricingSchema?.dollar_rate
          ? pricingSchema?.dollar_rate
          : 1,
      )
      setBasePricingPlanLoiteringRate(
        modalType !== 'both'
          ? Number(
              getMode([
                ...(pricingSchema?.time_of_day_loitering_weekday || []),
                ...(pricingSchema?.time_of_day_loitering_weekend || []),
              ]),
            ) / 100
          : pricingSchema?.loitering_dollar_rate
          ? pricingSchema?.loitering_dollar_rate
          : 1,
      )
      setPricePerUnitLoitering(
        modalType !== 'both'
          ? Number(
              getMode([
                ...(pricingSchema?.time_of_day_loitering_weekday || []),
                ...(pricingSchema?.time_of_day_loitering_weekend || []),
              ]),
            ) / 100
          : pricingSchema?.loitering_dollar_rate
          ? pricingSchema?.loitering_dollar_rate
          : 1,
      )
      setPricingSchemaValues(pricingSchema)
    }
  }, [pricingSchema])

  useEffect(() => {
    const pricingArr = Array.from({ length: 48 }, () => (Number(pricePerUnitLoitering) === 0 ? 1 : 0))
    setTimeOfDayPricingWeekDayLoitering(pricingArr)
    setTimeOfDayPricingWeekendLoitering(pricingArr)
  }, [pricePerUnitLoitering])

  const getDiscountNames = (tenantId: number) => {
    setLoading(true)
    GetDiscountNames(tenantId)
      .then((resp) => {
        setLoading(false)
        setDiscountNames(resp)
      })
      .catch((err: SwtchError) => console.log(err))
      .finally(() => setLoading(false))
  }

  const nameCheck = (value: string, type: string, index: number) => {
    if (type === 'discountName') {
      const exists = discountNames.includes(value)
      setDiscountNameExists((prev) => ({ ...prev, [index]: exists }))
    } else {
      const exists = promoCodes.includes(value)
      setPromoCodeExists((prev) => ({ ...prev, [index]: exists }))
    }
  }

  const getMode = (arr: number[]) => {
    const frequencyMap: Record<string, number> = {}
    let greatestFreq = 0
    let mode: any
    arr.forEach((item) => {
      frequencyMap[item] = (frequencyMap[item] || 0) + 1
      if (frequencyMap[item] > greatestFreq) {
        greatestFreq = frequencyMap[item]
        mode = item
      }
    })
    return mode
  }

  const timeOfDayRatesPricingSchema = (
    selectedPricingSchema: PricingSchemaPayload | undefined,
    type: string,
    modalType?: string,
  ) => {
    let timeOfDayRateArr: PricingSchemaEventSchedule[] = []
    let dollarRate = 0
    let timeOfDayArr: number[] = []
    switch (type) {
      case 'timeOfDayWeekday':
        modalType === 'discount-only' || modalType === 'edit'
          ? (timeOfDayArr = selectedPricingSchema?.time_of_day_price_weekday || [])
          : (timeOfDayRateArr = selectedPricingSchema?.weekdayRateExtras ? selectedPricingSchema.weekdayRateExtras : [])
        break
      case 'timeOfDayWeekend':
        modalType === 'discount-only' || modalType === 'edit'
          ? (timeOfDayArr = selectedPricingSchema?.time_of_day_price_weekend || [])
          : (timeOfDayRateArr = selectedPricingSchema?.weekendRateExtras ? selectedPricingSchema.weekendRateExtras : [])
        break
      case 'timeOfDayWeekdayLoitering':
        modalType === 'discount-only' || modalType === 'edit'
          ? (timeOfDayArr = selectedPricingSchema?.time_of_day_loitering_weekday || [])
          : (timeOfDayRateArr = selectedPricingSchema?.weekdayLoiteringRateExtras
              ? selectedPricingSchema.weekdayLoiteringRateExtras
              : [])
        break
      case 'timeOfDayWeekendLoitering':
        modalType === 'discount-only' || modalType === 'edit'
          ? (timeOfDayArr = selectedPricingSchema?.time_of_day_loitering_weekend || [])
          : (timeOfDayRateArr = selectedPricingSchema?.weekendLoiteringRateExtras
              ? selectedPricingSchema.weekendLoiteringRateExtras
              : [])
        break
      default:
        break
    }

    dollarRate = modalType === 'discount-only' || modalType === 'edit' ? Number(getMode(timeOfDayArr)) : dollarRate

    if (modalType === 'discount-only' || modalType === 'edit') {
      timeOfDayArr.forEach((rate, index) => {
        if (rate !== dollarRate) {
          const eventScheduleObj: PricingSchemaEventSchedule = {
            dollar_rate: rate / 100,
            from: index / 2,
            to: index / 2 + 1,
          }
          if (index % 2 === 0) timeOfDayRateArr.push(eventScheduleObj)
        }
      })
    }

    if (timeOfDayRateArr.length === 0) {
      timeOfDayRateArr.push({
        dollar_rate: modalType === 'discount-only' || modalType === 'edit' ? dollarRate / 100 : dollarRate,
        to: 1,
        from: 0,
      })
    }
    return timeOfDayRateArr
  }

  const convertRate = (pricingSchemaValues: PricingSchemaPayload, weekday: boolean, loitering: boolean) => {
    let eventSchedule: PricingSchemaEventSchedule[] = []
    let baseRate = 0

    if (
      modalType !== 'both' &&
      isRateTheSame([
        ...(pricingSchema?.timeOfDayPriceWeekday || []),
        ...(pricingSchema?.timeOfDayPriceWeekend || []),
      ]) &&
      !loitering
    ) {
      return []
    }

    if (
      modalType !== 'both' &&
      isRateTheSame([
        ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
        ...(pricingSchema?.timeOfDayLoiteringWeekend || []),
      ]) &&
      loitering
    ) {
      return []
    }
    if (weekday) {
      if (loitering) {
        modalType === 'both'
          ? pricingSchemaValues.weekdayLoiteringRateExtras?.map((rate) => eventSchedule.push(rate))
          : modalType === 'discount-only'
          ? (baseRate = Number(pricingSchemaValues.loitering_dollar_rate))
          : (baseRate = getMode(
              pricingSchemaValues?.timeOfDayLoiteringWeekday ? pricingSchemaValues?.timeOfDayLoiteringWeekday : [],
            ))

        if (modalType !== 'both') {
          eventSchedule = timeOfDayRatesPricingSchema(pricingSchemaValues, 'timeOfDayWeekdayLoitering', modalType)
        }
      } else {
        modalType === 'both'
          ? pricingSchemaValues.weekdayRateExtras?.map((rate) => eventSchedule.push(rate))
          : modalType === 'discount-only'
          ? (baseRate = Number(pricingSchemaValues.dollar_rate))
          : (baseRate = getMode(
              pricingSchemaValues?.timeOfDayPriceWeekday ? pricingSchemaValues?.timeOfDayPriceWeekday : [],
            ))

        if (modalType !== 'both') {
          eventSchedule = timeOfDayRatesPricingSchema(pricingSchemaValues, 'timeOfDayWeekday', modalType)
        }
      }
    } else if (!weekday) {
      if (loitering) {
        modalType === 'both'
          ? pricingSchemaValues.weekendLoiteringRateExtras?.map((rate) => eventSchedule.push(rate))
          : modalType === 'discount-only'
          ? (baseRate = Number(pricingSchemaValues.loitering_dollar_rate))
          : (baseRate = getMode(
              pricingSchemaValues?.timeOfDayLoiteringWeekend ? pricingSchemaValues?.timeOfDayLoiteringWeekend : [],
            ))

        if (modalType !== 'both') {
          eventSchedule = timeOfDayRatesPricingSchema(pricingSchemaValues, 'timeOfDayWeekendLoitering', modalType)
        }
      } else {
        modalType === 'both'
          ? pricingSchemaValues.weekendRateExtras?.map((rate) => eventSchedule.push(rate))
          : modalType === 'discount-only'
          ? (baseRate = Number(pricingSchemaValues.dollar_rate))
          : (baseRate = getMode(
              pricingSchemaValues?.timeOfDayPriceWeekday ? pricingSchemaValues?.timeOfDayPriceWeekday : [],
            ))

        if (modalType !== 'both') {
          eventSchedule = timeOfDayRatesPricingSchema(pricingSchemaValues, 'timeOfDayWeekend', modalType)
        }
      }
    }

    // Function to convert an index to a time string
    const indexToTime = (index: number) => {
      const hour = index % 12
      const period = index < 12 ? 'AM' : 'PM'
      const hourString = hour === 0 ? 12 : hour // Convert 0 to 12 for 12AM/12PM
      return `${hourString}${period}`
    }

    // Filter out the intervals that have the base rate
    let filteredArr = eventSchedule.filter((item) => item.dollar_rate !== baseRate)
    if (pricingSchemaValues.loitering_type === 'none' && loitering) filteredArr = []
    // Sort the array by 'from' to ensure proper merging
    filteredArr.sort((a, b) => a.from - b.from)

    // Merge consecutive intervals with the same rate
    const mergedIntervals = []
    for (let i = 0; i < filteredArr.length; i++) {
      const current = filteredArr[i]
      let from = current.from
      let to = current.to
      let rate = current.dollar_rate

      // Merge consecutive intervals with the same rate
      while (i + 1 < filteredArr.length && filteredArr[i + 1].dollar_rate === rate && filteredArr[i + 1].from === to) {
        to = filteredArr[i + 1].to
        i++
      }
      mergedIntervals.push({ from, to, dollar_rate: rate })
    }

    // Convert merged intervals to the desired string format
    return mergedIntervals.map((item) => {
      const fromTime = indexToTime(item.from)
      const toTime = indexToTime(item.to)
      const rate = `$${Number(item.dollar_rate).toFixed(2)}/hr`
      return `${fromTime}-${toTime}: ${rate}`
    })
  }

  // Compute weekday and weekend rates
  const weekdayRates = pricingSchemaValues?.enable_time_of_day ? convertRate(pricingSchemaValues, true, false) : []
  const weekendRates = pricingSchemaValues?.enable_time_of_day ? convertRate(pricingSchemaValues, false, false) : []
  const weekdayLoiteringRates = pricingSchemaValues?.enable_time_of_day_loitering
    ? convertRate(pricingSchemaValues, true, true)
    : []
  const weekendLoiteringRates = pricingSchemaValues?.enable_time_of_day_loitering
    ? convertRate(pricingSchemaValues, false, true)
    : []

  const validateInputChange = (
    form: FormInstance,
    index: number,
    value: number,
    key: number,
    typeOfDay: string,
    loitering: boolean,
  ) => {
    const field = loitering ? `${typeOfDay}LoiteringRateExtras` : `${typeOfDay}RateExtras`
    form.setFieldsValue({
      discount_schema_form: form.getFieldValue('discount_schema_form').map((item: any, idx: any) =>
        idx === index
          ? {
              ...item,
              [field]: item[field].map((extra: any, extraIdx: any) =>
                extraIdx === key ? { ...extra, dollar_rate: value } : extra,
              ),
            }
          : item,
      ),
    })

    // Trigger validation
    form.validateFields([['discount_schema_form', index, field, key, 'dollar_rate']])
  }

  const mergeArrays = (
    arr1: PricingSchemaEventSchedule[],
    arr2: PricingSchemaEventSchedule[],
  ): PricingSchemaEventSchedule[] => {
    // Create a map for quick lookup of 'from' values in arr1
    const fromMap = new Map<number, boolean>()
    arr1.forEach((item) => {
      fromMap.set(item.from, true)
    })

    // Iterate through arr2 and add missing 'from' values to arr1
    arr2.forEach((item) => {
      if (!fromMap.has(item.from)) {
        arr1.push(item)
      }
    })

    // Sort the combined array based on 'from' value
    arr1.sort((a, b) => a.from - b.from)

    return arr1
  }

  const timeOfDayRates = (selectedDiscountSchema: DiscountSchema, type: string) => {
    let timeOfDayRateArr: PricingSchemaEventSchedule[] = []
    let dollarRate = 0
    let timeOfDayArr: number[] = []
    let timeOfDayPricingSchemaArr: number[] = []

    switch (type) {
      case 'timeOfDayWeekday':
        timeOfDayArr =
          isRateTheSame(selectedDiscountSchema.discountPercentageWeekday) === true
            ? []
            : selectedDiscountSchema.discountPercentageWeekday
        break
      case 'timeOfDayWeekend':
        timeOfDayArr =
          isRateTheSame(selectedDiscountSchema.discountPercentageWeekend) === true
            ? []
            : selectedDiscountSchema.discountPercentageWeekend
        break
      case 'timeOfDayWeekdayLoitering':
        timeOfDayArr = selectedDiscountSchema.loiteringSuspensionPeriodWeekday || []
        break
      case 'timeOfDayWeekendLoitering':
        timeOfDayArr = selectedDiscountSchema.loiteringSuspensionPeriodWeekend || []
        break
      default:
        break
    }

    switch (type) {
      case 'timeOfDayWeekday':
        timeOfDayPricingSchemaArr = pricingSchema?.time_of_day_price_weekday || []
        break
      case 'timeOfDayWeekend':
        timeOfDayPricingSchemaArr = pricingSchema?.time_of_day_price_weekend || []
        break
      case 'timeOfDayWeekdayLoitering':
        timeOfDayPricingSchemaArr = pricingSchema?.time_of_day_loitering_weekday || []
        break
      case 'timeOfDayWeekendLoitering':
        timeOfDayPricingSchemaArr = pricingSchema?.time_of_day_loitering_weekend || []
        break
      default:
        break
    }

    dollarRate = getMode(timeOfDayPricingSchemaArr)
    timeOfDayArr.forEach((rate, index) => {
      const pricingPlanChargingRate = timeOfDayPricingSchemaArr[index]
      if (rate !== 100 && rate !== 0) {
        if (type === 'timeOfDayWeekday' || type === 'timeOfDayWeekend') {
          if (
            ((100 - rate) / 100) * (pricingPlanChargingRate / 100) ===
            ((100 - getMode(timeOfDayArr)) / 100) * (pricingPlanChargingRate / 100)
          ) {
            return
          }
          const eventScheduleObj: PricingSchemaEventSchedule = {
            dollar_rate: ((100 - rate) / 100) * (pricingPlanChargingRate / 100),
            from: index / 2,
            to: index / 2 + 1,
          }
          if (index % 2 === 0) timeOfDayRateArr.push(eventScheduleObj)
        } else {
          const eventScheduleObj: PricingSchemaEventSchedule = {
            dollar_rate: 0,
            from: index / 2,
            to: index / 2 + 1,
          }
          if (index % 2 === 0) timeOfDayRateArr.push(eventScheduleObj)
        }
      }
      if (rate === 100) {
        const eventScheduleObj: PricingSchemaEventSchedule = {
          dollar_rate: 0,
          from: index / 2,
          to: index / 2 + 1,
        }
        if (index % 2 === 0) timeOfDayRateArr.push(eventScheduleObj)
      }
    })

    let firstDollarRate = dollarRate
    if (timeOfDayRateArr.length === 0) {
      if (type === 'timeOfDayWeekday') {
        if (selectedDiscountSchema.discountPercentageWeekday[0] === 0) {
          firstDollarRate = pricingSchemaValues?.timeOfDayPriceWeekend
            ? pricingSchemaValues.timeOfDayPriceWeekend[0]
            : dollarRate
        } else if (selectedDiscountSchema.discountPercentageWeekday[0] === 100) {
          firstDollarRate = 0
        } else {
          firstDollarRate = pricingSchemaValues?.timeOfDayPriceWeekday
            ? pricingSchemaValues.timeOfDayPriceWeekday[0] *
              ((100 - selectedDiscountSchema.discountPercentageWeekday[0]) / 100)
            : dollarRate
        }
      }
      if (type === 'timeOfDayWeekend') {
        if (selectedDiscountSchema.discountPercentageWeekend[0] === 0) {
          firstDollarRate = pricingSchemaValues?.timeOfDayPriceWeekend
            ? pricingSchemaValues.timeOfDayPriceWeekend[0]
            : dollarRate
        } else if (selectedDiscountSchema.discountPercentageWeekend[0] === 100) {
          firstDollarRate = 0
        } else {
          firstDollarRate = pricingSchemaValues?.timeOfDayPriceWeekend
            ? pricingSchemaValues.timeOfDayPriceWeekend[0] *
              ((100 - selectedDiscountSchema.discountPercentageWeekday[0]) / 100)
            : dollarRate
        }
      }
      if (type === 'timeOfDayWeekdayLoitering') {
        if (selectedDiscountSchema.loiteringSuspensionPeriodWeekday[0] === 0) {
          firstDollarRate = pricingSchemaValues?.timeOfDayLoiteringWeekday
            ? pricingSchemaValues.timeOfDayLoiteringWeekday[0]
            : dollarRate
        } else {
          firstDollarRate = 0
        }
      }
      if (type === 'timeOfDayWeekendLoitering') {
        if (selectedDiscountSchema.loiteringSuspensionPeriodWeekend[0] === 0) {
          firstDollarRate = pricingSchemaValues?.timeOfDayLoiteringWeekend
            ? pricingSchemaValues.timeOfDayLoiteringWeekend[0]
            : dollarRate
        } else {
          firstDollarRate = 0
        }
      }

      if (modalType !== 'edit' && (type === 'timeOfDayWeekday' || type === 'timeOfDayWeekend')) {
        timeOfDayRateArr.push({
          dollar_rate: (firstDollarRate / 100) * (dollarRate / 100),
          to: 1,
          from: 0,
        })
      } else {
        timeOfDayRateArr = timeOfDayRatesPricingSchema(pricingSchemaValues, type, modalType)
      }
    }
    return mergeArrays(timeOfDayRateArr, timeOfDayRatesPricingSchema(pricingSchemaValues, type, modalType))
  }

  const calculateDollarRateLimit = (
    pricingSchema: PricingSchemaPayload | undefined,
    values: any,
    type: string,
    modalType: string,
    loitering?: boolean,
  ) => {
    let dollarRate =
      type === 'timeOfDayWeekday' || type === 'timeOfDayWeekend' ? basePricingPlanRate : basePricingPlanLoiteringRate
    let arr: PricingSchemaEventSchedule[] = []

    if (pricingSchema) arr = timeOfDayRatesPricingSchema(pricingSchema, type, modalType)
    arr.map((weekdayRate: PricingSchemaEventSchedule) => {
      if (weekdayRate.from === values.from) {
        dollarRate = Number(weekdayRate.dollar_rate)
      }
      return dollarRate
    })

    if (!loitering) return dollarRate < values.dollar_rate
    if (loitering) return dollarRate
  }

  const initialValues: DiscountFormValues = {
    discount_schema_form: [
      {
        name: selectedDiscountSchema ? selectedDiscountSchema.name : '',
        promo_code: selectedDiscountSchema ? selectedDiscountSchema.promoCode : '',
        dollar_rate: basePricingPlanRate,
        stripe_account_id: null,
        price_type: pricingSchema && pricingSchema.price_type,
        flat_fee: selectedDiscountSchema?.ignoreConstantFee ? 0 : pricingSchema && pricingSchema.flat_fee,
        loitering_type: pricingSchema?.loitering_type,
        loitering_dollar_rate: basePricingPlanLoiteringRate,
        weekdayRateExtras: selectedDiscountSchema
          ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekday')
          : defaultEventSchedule,
        weekendRateExtras: selectedDiscountSchema
          ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekend')
          : defaultEventSchedule,
        weekdayLoiteringRateExtras: selectedDiscountSchema
          ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekdayLoitering')
          : defaultLoiteringEventSchedule,
        weekendLoiteringRateExtras: selectedDiscountSchema
          ? timeOfDayRates(selectedDiscountSchema, 'timeOfDayWeekendLoitering')
          : defaultLoiteringEventSchedule,
        enable_time_of_day: true,
      },
    ],
  }

  const handleSelect = (
    listName: string,
    form: FormInstance,
    index: number,
    type: string,
    pricingSchema: PricingSchemaPayload | undefined,
    modalType: string,
    key: number,
  ) => {
    const listFieldValue = form.getFieldValue(['discount_schema_form', index, listName])
    if (listFieldValue) {
      const fromFieldValue = form.getFieldValue(['discount_schema_form', index, listName, key, 'from'])
      form.setFields([
        {
          name: ['discount_schema_form', index, listName, key, 'to'],
          value: fromFieldValue ? fromFieldValue + 1 : 1,
        },
        {
          name: ['discount_schema_form', index, listName, key, 'dollar_rate'],
          value: calculateDollarRateLimit(
            pricingSchema,
            form.getFieldValue(['discount_schema_form', index, listName, key]),
            type,
            modalType,
            true,
          ),
        },
      ])
    }
  }

  const onFinish = (values: DiscountFormValues, modal: string, discountId?: number) => {
    setLoading(true)

    values.discount_schema_form.map((discountValues) => {
      let timeOfDayPriceWeekdayArr = Array.from({ length: 48 }, () =>
        discountValues.dollar_rate === 0 && discountValues.dollar_rate !== basePricingPlanRate
          ? 100
          : discountValues.dollar_rate === basePricingPlanRate
          ? 0
          : 100 - (discountValues.dollar_rate / basePricingPlanRate) * 100,
      )
      if (timeOfDay && timeOfDayPriceWeekdayArr && timeOfDayPriceWeekdayArr.length > 0) {
        discountValues['weekdayRateExtras'] &&
          discountValues['weekdayRateExtras'].map((_: any, rateIndex: number) => {
            let index = 0
            const pricingPlanWeekdayChargingRate = calculateDollarRateLimit(
              pricingSchemaValues,
              discountValues.weekdayRateExtras?.[rateIndex],
              'timeOfDayWeekday',
              modalType ? modalType : '',
              true,
            )
            if (_.from !== 0) {
              index = _.from * 2
            }
            timeOfDayPriceWeekdayArr[index] =
              Number(_.dollar_rate) === Number(pricingPlanWeekdayChargingRate)
                ? 0
                : 100 - (Number(_.dollar_rate) / Number(pricingPlanWeekdayChargingRate)) * 100
            timeOfDayPriceWeekdayArr[index + 1] =
              Number(_.dollar_rate) === Number(pricingPlanWeekdayChargingRate)
                ? 0
                : 100 - (Number(_.dollar_rate) / Number(pricingPlanWeekdayChargingRate)) * 100
            return timeOfDayPriceWeekdayArr
          })
      }

      let timeOfDayPriceWeekendArr = Array.from({ length: 48 }, () =>
        discountValues.dollar_rate === 0 && discountValues.dollar_rate !== basePricingPlanRate
          ? 100
          : discountValues.dollar_rate === basePricingPlanRate
          ? 0
          : 100 - (discountValues.dollar_rate / basePricingPlanRate) * 100,
      )
      if (timeOfDay && timeOfDayPriceWeekendArr && timeOfDayPriceWeekendArr.length > 0) {
        discountValues['weekendRateExtras'] &&
          discountValues['weekendRateExtras'].map((_: any, rateIndex: number) => {
            const pricingPlanWeekendChargingRate = Number(
              calculateDollarRateLimit(
                pricingSchemaValues,
                discountValues.weekendRateExtras?.[rateIndex],
                'timeOfDayWeekend',
                modalType ? modalType : '',
                true,
              ),
            )
            let index = 0
            if (_.from !== 0) {
              index = _.from * 2
            }
            timeOfDayPriceWeekendArr[index] =
              Number(_.dollar_rate) === Number(pricingPlanWeekendChargingRate)
                ? 0
                : 100 - (Number(_.dollar_rate) / Number(pricingPlanWeekendChargingRate)) * 100
            timeOfDayPriceWeekendArr[index + 1] =
              Number(_.dollar_rate) === Number(pricingPlanWeekendChargingRate)
                ? 0
                : 100 - (Number(_.dollar_rate) / Number(pricingPlanWeekendChargingRate)) * 100
            return timeOfDayPriceWeekendArr
          })
      }

      const timeOfDayPriceWeekdayLoiteringArr = [...timeOfDayPriceWeekdayLoitering]
      if (timeOfDayLoitering && timeOfDayPriceWeekdayLoiteringArr) {
        discountValues['weekdayLoiteringRateExtras'] &&
          discountValues['weekdayLoiteringRateExtras'].map((_: any) => {
            let index = 0
            if (_.from !== 0) {
              index = _.from * 2
            }
            timeOfDayPriceWeekdayLoiteringArr[index] = Number(_.dollar_rate) === 0 ? 1 : 0
            timeOfDayPriceWeekdayLoiteringArr[index + 1] = Number(_.dollar_rate) === 0 ? 1 : 0
            return timeOfDayPriceWeekdayLoiteringArr
          })
      }

      let timeOfDayPriceWeekendLoiteringArr = [...timeOfDayPriceWeekendLoitering]
      if (timeOfDayLoitering && timeOfDayPriceWeekendLoiteringArr && timeOfDayPriceWeekendLoiteringArr.length > 0) {
        discountValues['weekendLoiteringRateExtras'] &&
          discountValues['weekendLoiteringRateExtras'].map((_: any) => {
            let index = 0
            if (_.from !== 0) {
              index = _.from * 2
            }
            timeOfDayPriceWeekendLoiteringArr[index] = Number(_.dollar_rate) === 0 ? 1 : 0
            timeOfDayPriceWeekendLoiteringArr[index + 1] = Number(_.dollar_rate) === 0 ? 1 : 0
            return timeOfDayPriceWeekendLoiteringArr
          })
      }

      const data: DiscountSchemaPayload = {
        tenant_id: Number(tenant?.id),
        name: discountValues['name'],
        promo_code: discountValues['promo_code'],
        discount_period_weekday: discountPeriodArr,
        discount_period_weekend: discountPeriodArr,
        discount_percentage_weekday: timeOfDayPriceWeekdayArr,
        discount_percentage_weekend: timeOfDayPriceWeekendArr,
        loitering_suspension_period_weekday: timeOfDayPriceWeekdayLoiteringArr,
        loitering_suspension_period_weekend: timeOfDayPriceWeekendLoiteringArr,
        ignore_charging_fee: false,
        ignore_constant_fee: discountValues['flat_fee'] === 0 ? true : false,
        ignore_demand_response_fee: false,
        ignore_guest_fee: false,
        ignore_loitering_fee: discountValues['loitering_type'] === 'none' ? true : false,
        usage_status: 'active',
      }

      if (modal === 'createDiscountSchema') {
        const listingsArr: number[] = []
        listings.map((listing: NewListing | BasicListingInfo) => listingsArr.push(listing.id))
        const listingObj = { ids: listingsArr }

        CreateDiscountSchema(data, Number(tenant?.id))
          .then((resp: DiscountSchema) => {
            if (listingsArr.length > 0) {
              ApplyDiscountsToListings(listingObj, resp.id)
                .then((resp: DiscountResponse) => {
                  onOk()
                  onCancel()
                })
                .catch((err: SwtchError) => console.log(err))
                .finally(() => setLoading(false))
            } else {
              onOk()
              onCancel()
            }
          })
          .catch((err: SwtchError) => console.log(err))
          .finally(() => setLoading(false))
      } else {
        if (discountId) {
          UpdateDiscountSchema(data, Number(tenant?.id), discountId)
            .then((resp: DiscountResponse) => {
              onOk()
              onCancel()
            })
            .catch((err: SwtchError) => console.log(err))
            .finally(() => setLoading(false))
        }
      }
      return data
    })
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const back = () => {
    setCurrent(current - 1)
  }

  const transformListingInfoToNewListing = (listingInfo: ListingInfo) => {
    return {
      id: listingInfo.listingId,
      title: listingInfo.listingTitle,
    }
  }

  const convertListingInfo = (listing: ListingInfo[] | undefined) => {
    return listing?.map(transformListingInfoToNewListing)
  }

  const convertPricingSchemaToPayload = (pricingSchema: PricingSchema, type: string) => {
    const pricingSchemaPayload = {
      tenant_id: pricingSchema.tenantId,
      id: pricingSchema.id,
      name: pricingSchema.name,
      state: pricingSchema.state,
      price_type: pricingSchema.priceType,
      flat_fee_cents: pricingSchema.flatFeeCents,
      flat_fee: Number(pricingSchema.flatFeeCents) / 100,
      demand_response_fee_cents: pricingSchema.demandResponseFeeCents,
      guest_fee_cents: pricingSchema.guestFeeCents,
      commission_percent: pricingSchema.commissionPercent?.toString(),
      payment_limit_cents: pricingSchema.paymentLimitCents,
      enable_addtional_service_fee: pricingSchema.enableAddtionalServiceFee,
      enable_pricing_curtailment: pricingSchema.enablePricingCurtailment,
      dollar_rate:
        type === 'discount-only' || type === 'edit'
          ? pricingSchema.timeOfDayPriceWeekday && pricingSchema.timeOfDayPriceWeekend
            ? Number(getMode([...pricingSchema.timeOfDayPriceWeekday, ...pricingSchema.timeOfDayPriceWeekend])) / 100
            : 1
          : pricingSchemaValues
          ? Number(
              getMode([
                ...pricingSchemaValues.time_of_day_price_weekday,
                ...pricingSchemaValues.time_of_day_price_weekend,
              ]),
            ) / 100
          : 1,
      loitering_dollar_rate:
        type === 'discount-only' || type === 'edit'
          ? pricingSchema.timeOfDayLoiteringWeekday && pricingSchema.timeOfDayLoiteringWeekend
            ? Number(
                getMode([
                  ...(pricingSchema.timeOfDayLoiteringWeekday || []),
                  ...(pricingSchema.timeOfDayLoiteringWeekend || []),
                ]),
              ) / 100
            : 0
          : pricingSchemaValues
          ? Number(
              getMode([
                ...(pricingSchemaValues.time_of_day_loitering_weekday || []),
                ...(pricingSchemaValues.time_of_day_loitering_weekend || []),
              ]),
            ) / 100
          : 0,
      loitering_type: pricingSchema.loiteringType,
      loitering_time_limit_minutes: pricingSchema.loiteringTimeLimitMinutes,
      loitering_grace_period_minutes: pricingSchema.loiteringGracePeriodMinutes,
      loitering_maximum_cents: pricingSchema.loiteringMaximumCents,
      loitering_maximum: Number(pricingSchema.loiteringMaximumCents) / 100,
      time_of_day_price_weekday: pricingSchema.timeOfDayPriceWeekday,
      time_of_day_price_weekend: pricingSchema.timeOfDayPriceWeekend,
      time_of_day_loitering_weekday: pricingSchema.timeOfDayLoiteringWeekday,
      time_of_day_loitering_weekend: pricingSchema.timeOfDayLoiteringWeekend,
      applied_listings: convertListingInfo(pricingSchema.appliedListings),
      timeOfDayPriceWeekday: pricingSchema.timeOfDayPriceWeekday,
      timeOfDayPriceWeekend: pricingSchema.timeOfDayPriceWeekend,
      timeOfDayLoiteringWeekday: pricingSchema.timeOfDayLoiteringWeekday,
      timeOfDayLoiteringWeekend: pricingSchema.timeOfDayLoiteringWeekend,
      enable_time_of_day: !isRateTheSame([
        ...(pricingSchema.timeOfDayPriceWeekday || []),
        ...(pricingSchema.timeOfDayPriceWeekend || []),
      ]),
      enable_time_of_day_loitering: !isRateTheSame([
        ...(pricingSchema?.timeOfDayLoiteringWeekday || []),
        ...(pricingSchema?.timeOfDayLoiteringWeekend || []),
      ]),
    }
    return pricingSchemaPayload
  }

  const archiveDiscountSchema = (discountId: number) => {
    ArchiveDiscountSchema(Number(tenant.id), discountId)
      .then((resp) => {
        onOk()
      })
      .catch((err: SwtchError) => console.log(err))
      .finally(() => {})
  }

  return {
    loading,
    pricingSchemaName,
    loiteringType,
    timeOfDay,
    eventSchedules,
    initialValues,
    defaultEventSchedule,
    discountNameExists,
    promoCodeExists,
    timeOfDayLoitering,
    current,
    listings,
    nextInModal,
    discountId,
    basePricingPlanRate,
    basePricingPlanLoiteringRate,
    weekdayRates,
    weekendRates,
    weekdayLoiteringRates,
    weekendLoiteringRates,
    setPricingSchemaName,
    setLoiteringType,
    setTimeOfDay,
    handleSelect,
    setPricePerUnitLoitering,
    setTimeOfDayLoitering,
    getDiscountNames,
    nameCheck,
    convertRate,
    validateInputChange,
    onFinish,
    next,
    setListings,
    setNextInModal,
    convertPricingSchemaToPayload,
    back,
    archiveDiscountSchema,
    setDiscountId,
    calculateDollarRateLimit,
  }
}
