import { TenantFilter } from '../../models/filter'

export const tenantQuery = (f: TenantFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['term'] = `${f.tenants.map((t) => t.id)}`
  }

  if (f.country) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province) {
    queryParams['province'] = `${f.province}`
  }

  if (f.taggings) {
    queryParams['taggings'] = `${f.taggings.map((t) => t.id)}`
  }

  if (f.status && f.status.length !== 0) {
    queryParams['status'] = f.status.join(',')
  }

  const listingsIdArr: number[] = []
  f.listings?.map((listing) => {
    return listingsIdArr.push(listing.id)
  })
  if (f.listings && f.listings.length !== 0) {
    queryParams['listing_ids'] = listingsIdArr.join(',')
  }

  if (f.accountName) {
    queryParams['master_account_ids'] = f.accountName.id.toString()
  }

  return queryParams
}
