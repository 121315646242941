import { useState } from 'react'
import { PageHeader } from 'antd'

import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { BoxPadding } from '../../atom/box'
import { useAppState } from '../../state'

import AdvancedSearch from '../../features/transactions/components/advancedSearch'

import { AlertError } from '../../components/error'

import TransactionsSelectors from '../../features/transactions/components/selectors/transactions-selectors'

import { useTransactions } from '../../hooks/useTransactions'

import AdvancedSearchButton from '../../features/transactions/components/advancedSearch/AdvancedSearchButton'
import TableView from '../../features/transactions/components/table'
import Stats from '../../features/transactions/components/metric'
import InfoMessage from 'components/message/InfoMessage'

const TransactionsPageV2: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { selectedTenant } = useAppState()

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)

  const {
    handlePaginationChange,
    handleTenantSelection,
    handleListingTitle,
    handleChangeDate,
    handleEmail,
    handleTransactionId,
    handleStartmethod,
    handleState,
    handleUsername,
    handleChargerId,
    handleResetAdvancedSearch,
    fetchTransactionsAndStats,
    setTenants,
    setFilter,
    setDisabled,
    checkValidEmail,
    fetchDataWithQuery,
    setMasterAccount,
    reset,
    showSearchQuery,
    disabled,
    startDate,
    endDate,
    metrics,
    metricsLoading,
    filter,
    tenants,
    transactions,
    pagination,
    loading,
    error,
  } = useTransactions()

  const extraPageHeaderComponents = (
    <AdvancedSearchButton
      filter={filter}
      onShowAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
      showAdvancedSearch={showAdvancedSearch}
      loading={loading}
    />
  )

  const renderAllSelectors = (): React.ReactNode => (
    <TransactionsSelectors
      loading={loading}
      filter={filter}
      defaultTenant={selectedTenant}
      tenants={tenants}
      startDate={startDate}
      endDate={endDate}
      disabled={disabled}
      reset={reset}
      onTenants={setTenants}
      onFilter={setFilter}
      onEmail={handleEmail}
      onListingTitle={handleListingTitle}
      onFetchTransactions={fetchTransactionsAndStats}
      onTenantSelection={handleTenantSelection}
      onTransactionId={handleTransactionId}
      onDisabled={setDisabled}
      onChangeDate={handleChangeDate}
      checkValidEmail={checkValidEmail}
      dropdownClassName="not-in-modal-selector"
      onFetchDataWithQuery={fetchDataWithQuery}
      setMasterAccount={setMasterAccount}
    />
  )

  return (
    <BackgroundContainer>
      {/* The four main search with calendar and filter button */}
      <PageHeader title={renderAllSelectors()} extra={extraPageHeaderComponents} />

      <AlertError error={error} />

      {/* The advance search filter */}
      <AdvancedSearch
        loading={loading}
        disabled={disabled}
        tenants={tenants}
        defaultTenant={selectedTenant}
        filter={filter}
        showAdvancedSearch={showAdvancedSearch}
        reset={reset}
        onState={handleState}
        onUsername={handleUsername}
        onChargerId={handleChargerId}
        onStartMethod={handleStartmethod}
        onFetchTransactions={fetchTransactionsAndStats}
        onResetAdvancedSearch={handleResetAdvancedSearch}
        onFetchDataWithQuery={fetchDataWithQuery}
      />

      <InfoMessage
        loading={loading}
        text="transaction"
        totalEntries={pagination.totalEntries}
        showSearchQuery={showSearchQuery}
      />

      {/* Revenue, transactions, energy (kWh), unique users */}
      <BoxPadding padding>
        <Stats loading={metricsLoading} metrics={metrics} />
      </BoxPadding>

      {/* The table of transactions */}
      <TableView
        loading={loading}
        transactions={transactions}
        pagination={pagination}
        onPageChange={handlePaginationChange}
      />
    </BackgroundContainer>
  )
}

export default TransactionsPageV2
