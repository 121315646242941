import React, { useEffect, useState } from 'react'

import { Form, Modal, Space, Spin } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule } from '../../../models/peak-shaving'
import {
  CreateEventScheduleForEvent,
  GetAllEventSchedulesForEvent,
  GetPeakShavingEventDetails,
  UpdatePeakShavingEvent,
  isPeakShavingFormEnabled,
} from '../../../services/data-provider/peak-shaving'
import { styled } from '../../../theme'
import { EventDetailsBaseForm } from '../form/event-details-base-form'

import _ from 'lodash'
import { ButtonModal } from '../../../atom/button'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  eventId: number
}

const CreateNewEventModalContainer = styled.div``

export const EditDailyScheduleModal: React.FC<props> = ({ visible, onCancel, onSubmit, eventId }) => {
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [DailyScheduleForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const [peakShavingEvent, setPeakShavingEvent] = useState<PeakShavingEvent>()

  const [weekDayEventSchedules, setWeekDayEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>(
    Array.from(Array(24), (_, index) => ({
      from: index,
      to: index + 1,
      zone: 'none',
    })),
  )
  const [weekEndEventSchedules, setWeekEndEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>(
    Array.from(Array(24), (_, index) => ({
      from: index,
      to: index + 1,
      zone: 'none',
    })),
  )

  const [term, setTerm] = useState(_.uniqueId())

  const { cancelText, publishText, dailyEventUpdateSuccessText } = usePeakShavingTranslation()

  useEffect(() => {
    setDisabled(true)
    setLoading(true)
    Promise.all([GetPeakShavingEventDetails(eventId), GetAllEventSchedulesForEvent(eventId)])
      .then((resp) => {
        const [eventDetailsResponse, eventSchedulesResponse] = resp
        const newArr = eventSchedulesResponse.map(({ id, ...rest }) => rest)

        const getWeekdayEventSchedule = newArr.filter((obj) => obj.dayType === 'weekday')
        const getWeekendEventSchedule = newArr.filter((obj) => obj.dayType === 'weekend')

        setWeekDayEventSchedules(getWeekdayEventSchedule)
        setWeekEndEventSchedules(getWeekendEventSchedule)
        setPeakShavingEvent(eventDetailsResponse)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
    return () => {
      setWeekDayEventSchedules([])
      setWeekEndEventSchedules([])
    }
  }, [eventId, term])

  const handleCancel = () => {
    DailyScheduleForm.resetFields()
    setDisabled(true)
    setError(undefined)
    onCancel()
  }

  const handleFormSubmit = () => {
    DailyScheduleForm.validateFields()
      .then((values: any) => {
        setLoading(true)
        setDisabled(true)
        const { program, date, name, frequency, zonesWeekday, zonesWeekend, participants, ...updateEventPrams } = values

        const ZonesWeekday = DailyScheduleForm.getFieldValue('zonesWeekday')
        const ZonesWeekend = DailyScheduleForm.getFieldValue('zonesWeekend')

        const zones: PeakShavingEventSchedule[] = [
          ...ZonesWeekday.map((obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekday',
            }
          }),
          ...ZonesWeekend.map((obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekend',
            }
          }),
        ]

        UpdatePeakShavingEvent(eventId, { ...updateEventPrams, participant_id: participants[0].participantId }).then(
          () => {
            Promise.all([
              ...zones
                .map((obj: Omit<PeakShavingEventSchedule, 'id'>) => {
                  return {
                    from: obj.from,
                    zone: obj.zone,
                    dayType: obj.dayType,
                  }
                })
                .map((schedule: Omit<PeakShavingEventSchedule, 'id' | 'to'>) =>
                  CreateEventScheduleForEvent(eventId, schedule),
                ),
            ])
              .then(() => {
                CustomSuccessMessage({
                  key: 'UpdateDailyEventAndSchedule',
                  response: dailyEventUpdateSuccessText,
                })
                setTerm(_.uniqueId())
                onSubmit()
              })
              .catch((err: SwtchApiError) => {
                setError(err)
              })
              .finally(() => {
                setLoading(false)
              })
          },
        )
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
  }

  const handleChange = () => {
    DailyScheduleForm.validateFields()
      .then(() => {
        setDisabled(false)
      })
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  return (
    <CreateNewEventModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{`${!disabled ? 'Edit' : ''} Schedule - ${eventId}`}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          peakShavingEvent &&
          isPeakShavingFormEnabled(peakShavingEvent) && (
            <Space>
              <ButtonModal type="ghost" icon={<></>} click={handleCancel} text={cancelText} />
              <ButtonModal
                type="primary"
                icon={<></>}
                click={handleFormSubmit}
                text={publishText}
                disabled={disabled}
              />
            </Space>
          )
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`editDailyScheduleModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        <AlertError error={error} />
        <Spin spinning={loading}>
          {peakShavingEvent && peakShavingEvent.program && !loading && (
            <EventDetailsBaseForm
              program={peakShavingEvent.program}
              form={DailyScheduleForm}
              disabled={!isPeakShavingFormEnabled(peakShavingEvent)}
              schedules={weekDayEventSchedules}
              weekEndSchedules={weekEndEventSchedules}
              formMode="view"
              event={peakShavingEvent}
              formListName="zones"
              scheduleFrequency="daily"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </CreateNewEventModalContainer>
  )
}
