import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useTransactionMetricTranslation = () => {
  const cancelHeading = useFormatMessage('dashboard.transactionsPage.statsView.cancel', 'Cancel')
  const errorHeading = useFormatMessage('dashboard.transactionsPage.statsView.error', 'Error')
  const totalHeading = useFormatMessage('dashboard.transactionsPage.statsView.total', 'Total')
  const totalTransactionsText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalTransactions',
    'Total Transactions',
  )
  const totalEnergyWithKwhText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalEnergyWithKwh',
    'Total Energy (kWh)',
  )

  const totalEnergyText = useFormatMessage('dashboard.transactionsPage.statsView.totalEnergy', 'Total Energy')
  const totalRevenueText = renderFormatMessage(
    'dashboard.transactionsPage.statsView.totalNetRevenue',
    'Total Net Revenue',
  )

  const netRevenueText = useFormatMessage('dashboard.text.netRevenue', 'Net Revenue')
  const totalChargingDurationText = renderFormatMessage(
    'dashboard.transactionsPage.totalChargingDuration',
    'Total Charging Duration',
  )

  const energyConsumedText = useFormatMessage('dashboard.text.energyConsumedWithkWh', 'Energy Consumed (kWh)')

  return {
    cancelHeading,
    errorHeading,
    energyConsumedText,
    netRevenueText,
    totalHeading,
    totalTransactionsText,
    totalEnergyText,
    totalEnergyWithKwhText,
    totalRevenueText,
    totalChargingDurationText,
  }
}
