interface Props {
  color: string
}

const EVChargerSVG: React.FC<Props> = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 600.000000 710.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path
          d="M670 7058 c-102 -21 -137 -32 -215 -69 -183 -86 -339 -258 -402 -444
-46 -134 -44 -17 -41 -3196 l3 -3025 34 -68 c63 -123 160 -192 302 -215 94
-16 3157 -15 3231 0 151 31 248 115 315 274 4 11 10 612 13 1335 l5 1315 288
3 c158 1 287 -1 288 -5 0 -5 1 -438 1 -963 0 -958 4 -1070 37 -1190 5 -19 13
-46 16 -60 6 -24 10 -33 32 -90 117 -294 396 -540 678 -599 196 -41 244 -41
440 0 109 23 211 67 320 140 99 66 156 119 229 213 102 132 159 261 195 444
15 76 15 3932 0 4016 -15 87 -48 191 -83 266 -76 165 -114 207 -1001 1091
-247 247 -466 459 -485 472 -145 96 -343 38 -419 -122 -30 -63 -29 -183 1
-249 16 -35 100 -126 341 -369 176 -176 317 -325 313 -331 -3 -5 -32 -22 -63
-37 -305 -148 -501 -431 -541 -785 -7 -63 -6 -113 3 -190 21 -157 35 -209 82
-305 38 -76 94 -169 113 -185 3 -3 25 -29 49 -58 40 -48 169 -156 231 -192 94
-54 196 -94 300 -116 77 -17 315 -19 374 -3 22 6 59 15 81 21 22 6 49 14 60
19 38 15 65 20 66 12 1 -4 3 -638 4 -1408 2 -1492 3 -1472 -46 -1570 -31 -62
-104 -135 -165 -164 -27 -13 -57 -27 -66 -32 -10 -5 -61 -9 -114 -9 -87 0
-102 3 -162 31 -101 49 -160 116 -211 242 -8 20 -11 292 -11 960 0 977 -2
1025 -45 1152 -69 202 -217 368 -415 464 -35 17 -71 31 -81 31 -10 0 -20 4
-23 9 -11 17 -82 23 -346 29 l-265 7 -6 1435 c-3 974 -8 1448 -16 1475 -38
142 -102 255 -207 363 -66 69 -142 124 -221 161 -83 39 -120 50 -231 70 -77
14 -2497 13 -2564 -1z m1541 -1759 c52 -18 92 -52 117 -100 22 -44 22 -46 25
-649 l2 -605 250 -5 c272 -5 289 -9 335 -67 73 -93 66 -158 -32 -320 -21 -35
-38 -65 -38 -69 0 -3 -8 -18 -18 -32 -11 -15 -32 -52 -49 -82 -17 -30 -44 -79
-62 -108 -17 -29 -31 -55 -31 -58 0 -3 -8 -18 -19 -32 -19 -28 -43 -69 -94
-162 -16 -30 -33 -59 -37 -65 -5 -5 -20 -31 -34 -57 -52 -95 -74 -134 -105
-186 -17 -29 -31 -54 -31 -56 0 -2 -18 -34 -41 -71 -22 -37 -49 -83 -60 -101
-10 -19 -28 -50 -39 -69 -11 -19 -29 -51 -40 -70 -11 -19 -30 -52 -41 -72 -12
-21 -35 -63 -52 -93 -17 -30 -38 -67 -49 -82 -10 -14 -18 -29 -18 -32 0 -3
-14 -29 -31 -58 -18 -29 -45 -78 -62 -108 -52 -97 -111 -140 -193 -140 -70 0
-137 41 -172 105 -15 27 -18 92 -22 570 l-5 540 -260 5 c-249 5 -261 6 -297
28 -58 36 -83 85 -83 162 1 58 6 74 46 150 26 47 65 120 88 163 23 43 59 110
80 150 21 40 59 110 83 157 25 47 61 114 80 150 33 61 63 116 158 295 22 41
58 109 80 150 94 177 125 234 156 293 19 34 49 90 68 125 18 34 52 97 75 140
22 42 60 112 82 155 23 42 50 93 61 113 23 44 43 63 94 89 45 22 86 25 135 9z
m3366 -200 c165 -67 242 -152 279 -310 28 -120 -21 -260 -125 -357 -85 -80
-231 -119 -335 -91 -130 36 -221 109 -275 222 -28 58 -31 75 -31 155 0 50 5
104 11 119 54 134 132 212 257 257 46 17 182 20 219 5z"
        />
      </g>
    </svg>
  )
}

export default EVChargerSVG
