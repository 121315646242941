import { Button, message, Modal } from 'antd'
import { useState } from 'react'
import { AlertErrorCompact } from '../../error-compact'
import { useFormatMessage } from '../../../helpers/intl'
import { SwtchError } from '../../../models/error'
import { Subscription } from '../../../models/subscription'
import { CancelSubscription } from '../../../services/data-provider/subscriptions'

interface props {
  subscription: Subscription
  onCancel: () => void
  onCancelSubscription: () => void
}

export const CancelSubscriptionModal: React.FC<props> = ({ subscription, onCancel, onCancelSubscription }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)

  const okText = useFormatMessage('dashboard.text.cancelSubscription', 'Cancel Subscription')
  const closeText = useFormatMessage('dashboard.text.close', 'Close')
  const deleteMessage = useFormatMessage(
    'dashboard.subscriptionsPage.modal.cancel',
    'Are you sure you want to cancel the subscription for',
  )

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    CancelSubscription(subscription)
      .then((msg) => {
        message.success(msg)
        onCancelSubscription()
      })
      .catch((error: SwtchError) => setError(error))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={useFormatMessage('dashboard.text.cancelSubscription', 'Cancel Subscription')}
      okText={okText}
      cancelText={closeText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={onCancel}
      footer={[
        <Button key="back" loading={loading} onClick={onCancel}>
          {closeText}
        </Button>,
        <Button key="submit" type="primary" danger loading={loading} onClick={onOk}>
          {okText}
        </Button>,
      ]}
    >
      <AlertErrorCompact error={error} />
      {deleteMessage} <strong>{subscription.subscriber.email} </strong>?
    </Modal>
  )
}
