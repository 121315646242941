import { Button, Divider, Form, InputNumber, Select, Space, Switch } from 'antd'
import { PricingSchemaEventSchedule, PricingSchemaPayload } from '../../../../models/price'
import { usePricingSchemasTranslation } from '../../../../hooks/translation/usePricingSchemaTranslation'
import { useUserTranslation } from '../../../../hooks/translation/useUserTranslation'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { FormInstance } from 'antd/es/form/Form'
import { DarkGrayBackground } from 'atom/discount'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { useListingLoiteringTrans } from 'hooks/translation/useListingLoiteringTrans'
import { InfoTextWrapper } from 'atom/price'
import { infoWithISVG } from 'assets/svg/info'
import { TimeContainer } from 'pages/peakShaving/modal/create-new-event-modal'
import { EditButtonWrapper } from 'atom/tenants'
import { plusSVG } from 'assets/svg/plus'
import { useDashboardTranslation } from 'hooks/translation/useDashboardTranslation'
import { timeConvert } from 'helpers/duration'
import { useEffect, useState } from 'react'
import CoreBadge from 'components/badge/Badge'

interface props {
  index: number
  form: FormInstance
  eventSchedules: PricingSchemaEventSchedule[]
  timeOfDayLoitering: boolean | undefined
  loiteringType: string | undefined
  pricingSchemaValues: PricingSchemaPayload | undefined
  basePricingPlanLoiteringRate: number
  modalType: string
  weekdayLoiteringRates: string[]
  weekendLoiteringRates: string[]
  setPricePerUnitLoitering: (pricePerUnitLoitering: number) => void
  setTimeOfDayLoitering: (timeOfDayLoiteringPricing: boolean) => void
  handleSelect: (
    listName: string,
    form: FormInstance,
    index: number,
    type: string,
    pricingSchema: PricingSchemaPayload | undefined,
    modalType: string,
    key: number,
  ) => void
  setLoiteringType: (loiteringType: string) => void
  convertRate: (pricingSchemaValues: PricingSchemaPayload, weekday: boolean, loitering: boolean) => string[]
  validateInputChange: (
    form: FormInstance,
    index: number,
    value: number,
    key: number,
    typeOfDay: string,
    loitering: boolean,
  ) => void
  calculateDollarRateLimit: (
    pricingSchema: PricingSchemaPayload | undefined,
    values: any,
    type: string,
    modalType: string,
    loitering?: boolean,
  ) => any
}

export const DiscountLoiteringSectionModal: React.FC<props> = ({
  index,
  form,
  timeOfDayLoitering,
  eventSchedules,
  loiteringType,
  pricingSchemaValues,
  basePricingPlanLoiteringRate,
  modalType,
  weekdayLoiteringRates,
  weekendLoiteringRates,
  setPricePerUnitLoitering,
  setTimeOfDayLoitering,
  handleSelect,
  setLoiteringType,
  convertRate,
  validateInputChange,
  calculateDollarRateLimit,
}) => {
  const {
    loiteringText,
    hoursNotDefinedText,
    toText,
    loiteringRateText,
    timeOfDayLoiteringText,
    weekdayLoiteringRateText,
    weekendLoiteringRateText,
    timeText,
    timeOfDayText,
  } = usePricingSchemasTranslation()
  const { noneText } = useUserTranslation()
  const { statusText } = useGeneralTranslation()
  const { weekdayText, weekendText } = useDashboardTranslation()
  const { basePlanText, noLoiteringText, gracePeriodText, timeLimitText } = useDiscountSchemaTranslation()
  const { loiteringStatusBasedText, loiteringStatusTimeBasedText } = useListingLoiteringTrans()

  const calculating = (listName: string, type: string, key: number) => {
    const rate = calculateDollarRateLimit(
      pricingSchemaValues,
      form.getFieldValue(['discount_schema_form', index, listName, key]),
      type,
      modalType,
      true,
    )
    return rate
  }
  const [calculate, setCalculate] = useState<any>()
  const [rate, setRate] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (calculate) setRate(calculating(calculate.listName, calculate.type, calculate.key))
    setCalculate(undefined)
  }, [calculate])

  const loiteringTypeSelector = (
    <Form.Item name={[index, 'loitering_type']} className="loitering-type">
      <Select
        className="combine-right"
        style={{ borderRightWidth: '2px' }}
        disabled={pricingSchemaValues?.loitering_type === 'none' ? true : false}
        onChange={(value) => {
          if (value !== 'none') {
            form.setFieldsValue({ loitering_dollar_rate: '1' })
            setPricePerUnitLoitering(1)
          } else {
            form.setFieldsValue({ loitering_dollar_rate: '0' })
            setPricePerUnitLoitering(0)
          }
          setLoiteringType(value)
        }}
        dropdownClassName="dropdown-large-font"
      >
        <Select.Option value={'none'}>{noneText}</Select.Option>
        {pricingSchemaValues?.loitering_type === 'time_based' && (
          <Select.Option value={'time_based'}>{timeText}</Select.Option>
        )}
        {pricingSchemaValues?.loitering_type === 'status_based' && (
          <Select.Option value={'status_based'}>{statusText}</Select.Option>
        )}
      </Select>
    </Form.Item>
  )

  return (
    <>
      <Divider>
        <span className="paragraph-02-bold">{loiteringText}</span>
      </Divider>
      <DarkGrayBackground>
        <Form.Item label={<>{basePlanText}:</>}>
          {pricingSchemaValues?.loitering_type === 'none' ? (
            <div className="paragraph-01-regular">{noLoiteringText}</div>
          ) : (
            <Space wrap>
              <CoreBadge
                title={
                  pricingSchemaValues?.loitering_type === 'time_based'
                    ? loiteringStatusTimeBasedText
                    : loiteringStatusBasedText
                }
              />
              {pricingSchemaValues?.loitering_type === 'status_based' && (
                <CoreBadge
                  title={`${timeConvert(
                    Number(pricingSchemaValues?.loitering_grace_period_minutes),
                  )} ${gracePeriodText}`}
                />
              )}
              {pricingSchemaValues?.loitering_type === 'time_based' && pricingSchemaValues?.loitering_maximum && (
                <CoreBadge
                  title={`${timeConvert(Number(pricingSchemaValues?.loitering_time_limit_minutes))} ${timeLimitText}`}
                />
              )}
              <CoreBadge
                title={`$${
                  modalType === 'edit'
                    ? form.getFieldsValue(true).discount_schema_form[index].loitering_dollar_rate
                    : pricingSchemaValues?.loitering_dollar_rate
                }/hr`}
              />
            </Space>
          )}
        </Form.Item>
        {(weekdayLoiteringRates.length > 0 || weekendLoiteringRates.length > 0) && (
          <Form.Item label={<>{timeOfDayText}:</>} style={{ alignItems: 'flex-start' }}>
            {weekdayLoiteringRates.length > 0 && (
              <div
                className="paragraph-01-regular"
                style={{
                  display: 'flex',
                  marginTop: '4px',
                  marginBottom: weekendLoiteringRates.length === 0 ? '0px' : '8px',
                }}
              >
                <div style={{ marginRight: '16px' }}>{weekdayText}: </div>
                {weekdayLoiteringRates.map((rate) => (
                  <CoreBadge title={rate} />
                ))}
              </div>
            )}
            {weekendLoiteringRates.length > 0 && (
              <div style={{ display: 'flex' }}>
                <div
                  className="paragraph-01-regular"
                  style={{ marginRight: '16px', marginTop: weekdayLoiteringRates.length === 0 ? '4px' : '0px' }}
                >
                  {weekendText}:{' '}
                </div>
                {weekendLoiteringRates.map((rate) => (
                  <CoreBadge title={rate} />
                ))}
              </div>
            )}
          </Form.Item>
        )}
      </DarkGrayBackground>
      <Form.Item
        label={<>{loiteringRateText}:</>}
        name={[index, 'loitering_dollar_rate']}
        style={{ marginBottom: loiteringType === 'none' ? '4px' : '25px', alignItems: 'flex-start' }}
      >
        <InputNumber
          precision={2}
          controls={false}
          addonBefore={loiteringTypeSelector}
          className={`combine-left ${pricingSchemaValues?.price_type === 'Hour' ? 'disabled' : ''}`}
          disabled={true}
        />
      </Form.Item>
      {loiteringType !== 'none' && (
        <>
          <Form.Item label={<>{timeOfDayLoiteringText}:</>}>
            <div style={{ display: 'flex' }}>
              <Switch checked={timeOfDayLoitering} onChange={(value) => setTimeOfDayLoitering(value)} />{' '}
              {timeOfDayLoitering && (
                <InfoTextWrapper>
                  {infoWithISVG} {hoursNotDefinedText}
                </InfoTextWrapper>
              )}
            </div>
          </Form.Item>
          {timeOfDayLoitering && (
            <>
              <Form.List name={[index, 'weekdayLoiteringRateExtras']}>
                {(fields, { add }) => (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} style={{ marginTop: key === 0 ? '20px' : '0px' }}>
                          <Space key={key} style={{ marginBottom: 8 }} align="end">
                            <Form.Item
                              name={[key, 'dollar_rate']}
                              label={key === 0 ? <>{weekdayLoiteringRateText}:</> : ''}
                              style={{ marginLeft: key === 0 ? '' : '131px' }}
                            >
                              <Select className="loitering-dollar-rate">
                                <Select.Option
                                  value={
                                    rate
                                      ? rate
                                      : calculateDollarRateLimit(
                                          pricingSchemaValues,
                                          form.getFieldValue([
                                            'discount_schema_form',
                                            index,
                                            'weekdayLoiteringRateExtras',
                                            key,
                                          ]),
                                          'timeOfDayWeekdayLoitering',
                                          modalType,
                                          true,
                                        )
                                  }
                                >
                                  {rate
                                    ? rate.toFixed(2)
                                    : calculateDollarRateLimit(
                                        pricingSchemaValues,
                                        form.getFieldValue([
                                          'discount_schema_form',
                                          index,
                                          'weekdayLoiteringRateExtras',
                                          key,
                                        ]),
                                        'timeOfDayWeekdayLoitering',
                                        modalType,
                                        true,
                                      ).toFixed(2)}
                                </Select.Option>
                                {rate !== 0 && <Select.Option value={0}>0.00</Select.Option>}
                              </Select>
                            </Form.Item>
                            <TimeContainer>
                              <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                                <Select
                                  style={{ width: '141px' }}
                                  onSelect={() => {
                                    handleSelect(
                                      'weekdayLoiteringRateExtras',
                                      form,
                                      index,
                                      'timeOfDayWeekdayLoitering',
                                      pricingSchemaValues,
                                      modalType,
                                      key,
                                    )
                                    setCalculate({
                                      listName: 'weekdayLoiteringRateExtras',
                                      type: 'timeOfDayWeekdayLoitering',
                                      key: key,
                                    })
                                  }}
                                  dropdownClassName="dropdown-large-font"
                                >
                                  {eventSchedules.map((eventSchedule, _) => {
                                    return (
                                      <Select.Option
                                        value={eventSchedule.from}
                                        key={
                                          eventSchedule.from < 10
                                            ? `0${eventSchedule.from}:00`
                                            : `${eventSchedule.from}:00`
                                        }
                                      >
                                        {eventSchedule.from < 10
                                          ? `0${eventSchedule.from}:00`
                                          : `${eventSchedule.from}:00`}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </TimeContainer>
                            <div
                              style={{ height: '38px', display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}
                            >
                              {toText}
                            </div>
                            <TimeContainer>
                              <Form.Item name={[key, 'to']}>
                                <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                                  {eventSchedules.map((eventSchedule, _) => {
                                    return (
                                      <Select.Option
                                        value={eventSchedule.to}
                                        key={
                                          eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`
                                        }
                                      >
                                        {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                            </TimeContainer>
                          </Space>
                        </div>
                      ))}
                    </div>
                    <Form.Item>
                      <EditButtonWrapper>
                        <Button
                          className="add-rate-button"
                          type="primary"
                          shape="circle"
                          icon={plusSVG}
                          onClick={add}
                          loading={false}
                          disabled={false}
                        />
                      </EditButtonWrapper>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
              <Form.List name={[index, 'weekendLoiteringRateExtras']}>
                {(fields, { add }) => (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8, marginTop: 0 }} align="end">
                          <Form.Item
                            name={[key, 'dollar_rate']}
                            label={key === 0 ? <>{weekendLoiteringRateText}:</> : ''}
                            style={{ marginLeft: key === 0 ? '' : '131px' }}
                          >
                            <Select className="loitering-dollar-rate">
                              <Select.Option
                                value={
                                  rate
                                    ? rate
                                    : calculateDollarRateLimit(
                                        pricingSchemaValues,
                                        form.getFieldValue([
                                          'discount_schema_form',
                                          index,
                                          'weekendLoiteringRateExtras',
                                          key,
                                        ]),
                                        'timeOfDayWeekendLoitering',
                                        modalType,
                                        true,
                                      )
                                }
                              >
                                {rate
                                  ? rate.toFixed(2)
                                  : calculateDollarRateLimit(
                                      pricingSchemaValues,
                                      form.getFieldValue([
                                        'discount_schema_form',
                                        index,
                                        'weekendLoiteringRateExtras',
                                        key,
                                      ]),
                                      'timeOfDayWeekendLoitering',
                                      modalType,
                                      true,
                                    ).toFixed(2)}
                              </Select.Option>
                              {calculateDollarRateLimit(
                                pricingSchemaValues,
                                form.getFieldValue(['discount_schema_form', index, 'weekendLoiteringRateExtras', key]),
                                'timeOfDayWeekendLoitering',
                                modalType,
                                true,
                              ) !== 0 && <Select.Option value={0}>0.00</Select.Option>}
                            </Select>
                          </Form.Item>
                          <TimeContainer>
                            <Form.Item name={[key, 'from']} validateTrigger={['onChange', 'onSelect']}>
                              <Select
                                onSelect={() => {
                                  handleSelect(
                                    'weekendLoiteringRateExtras',
                                    form,
                                    index,
                                    'timeOfDayWeekendLoitering',
                                    pricingSchemaValues,
                                    modalType,
                                    key,
                                  )
                                  setCalculate({
                                    listName: 'weekendLoiteringRateExtras',
                                    type: 'timeOfDayWeekendLoitering',
                                    key: key,
                                  })
                                }}
                                style={{ width: '141px' }}
                                dropdownClassName="dropdown-large-font"
                              >
                                {eventSchedules.map((eventSchedule, _) => {
                                  return (
                                    <Select.Option
                                      value={eventSchedule.from}
                                      key={
                                        eventSchedule.from < 10
                                          ? `0${eventSchedule.from}:00`
                                          : `${eventSchedule.from}:00`
                                      }
                                    >
                                      {eventSchedule.from < 10
                                        ? `0${eventSchedule.from}:00`
                                        : `${eventSchedule.from}:00`}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </TimeContainer>
                          <div
                            style={{ height: '38px', display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}
                          >
                            {toText}
                          </div>
                          <TimeContainer>
                            <Form.Item name={[key, 'to']}>
                              <Select disabled style={{ width: '141px' }} dropdownClassName="dropdown-large-font">
                                {eventSchedules.map((eventSchedule, _) => {
                                  return (
                                    <Select.Option
                                      value={eventSchedule.to}
                                      key={eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                    >
                                      {eventSchedule.to < 10 ? `0${eventSchedule.to}:00` : `${eventSchedule.to}:00`}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </TimeContainer>
                        </Space>
                      ))}
                    </div>
                    <Form.Item>
                      <EditButtonWrapper>
                        <Button
                          className="add-rate-button"
                          type="primary"
                          shape="circle"
                          icon={plusSVG}
                          onClick={add}
                          loading={false}
                          disabled={false}
                        />
                      </EditButtonWrapper>
                    </Form.Item>
                  </div>
                )}
              </Form.List>
            </>
          )}
        </>
      )}
    </>
  )
}
