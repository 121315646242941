import { Space } from 'antd'
import { ButtonModal } from 'atom/button'
import { useTaggingTranslation } from 'hooks/translation/useTaggingTranslation'
import { Tagging, TaggingStatus, TaggingType } from 'models/tagging'
import moment from 'moment'
import { useEffect } from 'react'

export const TaggingColumns = (selectTagToEdit: (tag: Tagging) => () => void, getTaggings: () => void) => {
  const {
    idText,
    nameText,
    objectText,
    statusText,
    typeText,
    createdAtText,
    createdByText,
    noteText,
    editText,
    activeText,
    inactiveText,
    systemText,
    adminText,
    customerText,
  } = useTaggingTranslation()

  const taggingLabels = {
    status: {
      active: activeText,
      inactive: inactiveText,
    },
    tagType: {
      system: systemText,
      admin: adminText,
      customer: customerText,
    },
  }

  const columns = [
    {
      title: idText,
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: nameText,
      dataIndex: 'tagName',
      width: '10%',
    },
    {
      title: objectText,
      dataIndex: 'tableName',
      width: '10%',
    },
    {
      title: statusText,
      dataIndex: 'status',
      render: (status: TaggingStatus) => taggingLabels.status[status],
    },
    {
      title: typeText,
      dataIndex: 'tagType',
      render: (type: TaggingType) => taggingLabels.tagType[type],
    },
    {
      title: createdAtText,
      dataIndex: 'createdAt',
      render: (createdAt: string) => moment(createdAt).format('YYYY-MM-DD'),
    },
    {
      title: createdByText,
      dataIndex: 'createdBy',
    },
    {
      title: noteText,
      dataIndex: 'note',
      width: '15%',
    },
    {
      title: '',
      key: 'action',
      render: (tag: Tagging) => (
        <Space size="small">
          <ButtonModal type="link" text={editText} click={selectTagToEdit(tag)} />
        </Space>
      ),
      width: '90px',
    },
  ]

  useEffect(() => {
    getTaggings()
  }, [])

  return columns
}
