import { ReactElement, useEffect, useState } from 'react'
import { GetMasterAccounts } from 'services/data-provider/master-account'
import { MasterAccountRef } from 'models/master-account'
import { Select } from 'antd'

export const useMasterAccountSelector = (setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void) => {
  const [allMasterAccounts, setAllMasterAccounts] = useState<MasterAccountRef[]>()

  useEffect(() => {
    GetMasterAccounts()
      .then((resp) => setAllMasterAccounts(resp))
      .catch((resp) => console.log(resp))
      .finally(() => {})
  }, [])

  const handleMasterAccountSelectChange = (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const findMasterAccount = allMasterAccounts?.find((p) => p.name === value)
    setMasterAccount(findMasterAccount)
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const options = allMasterAccounts?.map((masterAccount) => ({
    label: masterAccount.name,
    value: masterAccount.name,
    id: masterAccount.id,
  }))

  const renderMasterAccountOption = (
    option: any,
    selectedOptions: any,
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.state.id} value={option.state.value} label={option.state.value}>
      {option.state.value}
    </Select.Option>
  )
  return { options, allMasterAccounts, handleMasterAccountSelectChange, renderMasterAccountOption }
}
