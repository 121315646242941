import { renderFormatMessage } from './intl'

export const renderChargerSettingText = (chargerSetting: string) => {
  switch (chargerSetting) {
    case 'Arena or Stadium':
    case 'arena_or_stadium':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.arenaOrStadium', 'Arena or Stadium')
    case 'Campground':
    case 'campground':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.campground', 'Campground')
    case 'Fleet':
    case 'fleet':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.fleet', 'Fleet')
    case 'Gas Station':
    case 'gas_station':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.gasStation', 'Gas Station')
    case 'Government':
    case 'government':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.government', 'Government')
    case 'Hospital':
    case 'hospital':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.hospital', 'Hospital')
    case 'Hotel':
    case 'hotel':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.hotel', 'Hotel')
    case 'Multi Unit Residential':
    case 'multi_unit_residential':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.multiUnitResidential', 'Multi Unit Residential')
    case 'On Street':
    case 'on_street':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.onStreet', 'On Street')
    case 'Parking Lot':
    case 'parking_lot':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.parkingLot', 'Parking Lot')
    case 'Parking Garage':
    case 'parking_garage':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.parkingGarage', 'Parking Garage')
    case 'Public':
    case 'public':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.public', 'Public')
    case 'Retail':
    case 'retail':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.retail', 'Retail')
    case 'School/University':
    case 'school_university':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.schoolUniversity', 'School/University')
    case 'Single Family Residential':
    case 'single_family_residential':
      return renderFormatMessage(
        'dashboard.tenantPage.overviewTab.singleFamilyResidential',
        'Single Family Residential',
      )
    case 'Workplace':
    case 'workplace':
      return renderFormatMessage('dashboard.tenantPage.overviewTab.workplace', 'Workplace')
    default:
      return chargerSetting
  }
}
