import { Collapse, List, Skeleton } from 'antd'
import { Transactionv2 } from '../../../../models/transaction'
import { renderFormatMessage } from '../../../../helpers/intl'
import { TransactionsCollapseWrapper } from '../../../../atom/transactions'
import { DateContainer } from '../../ContainerStyles/homepageStyles'
import { HomePageTransactionOverviewContainer, secondsToTime } from './transaction-overview'

interface props {
  transactions: Transactionv2[]
}

const TransactionOverviewCollapse: React.FC<props> = ({ transactions }) => {
  const renderTZ = (dateStr: string) => {
    const dateArr = dateStr.split(' ')
    let tzPart = dateArr[1].substring(dateArr[1].indexOf('-') + 1)
    let timeZone = `(-${tzPart.substring(0, 2) + ':' + tzPart.substring(2)})`
    return timeZone
  }

  const renderState = (state: string) => {
    let stateClass = undefined
    switch (state.toLowerCase()) {
      case 'free':
        stateClass = 'free'
        break
      case 'confirmed':
        stateClass = 'confirmed'
        break
      case 'no-reservation':
        stateClass = 'no-reservation'
        break
      case 'cancelled':
        stateClass = 'cancelled'
        break
      case 'errored':
        stateClass = 'errored'
        break
      case 'paid':
        stateClass = 'paid'
        break
      case 'preauthorized':
        stateClass = 'preauthorized'
        break
      case 'pending_payment':
        stateClass = 'pending_payment'
        break
      case 'checked_in':
        stateClass = 'checked_in'
        break
    }

    return stateClass
  }

  const renderStateText = (state: string) => {
    let text = undefined

    switch (state.toLowerCase()) {
      case 'confirmed':
        text = renderFormatMessage('dashboard.transaction.confirmed', 'Confirmed')
        break

      case 'free':
        text = renderFormatMessage('dashboard.transaction.free', 'Free')
        break

      case 'pending_payment':
        text = renderFormatMessage('dashboard.transaction.pending_payment', 'Pending Payment')
        break

      case 'cancelled':
        text = renderFormatMessage('dashboard.transaction.canceled', 'Canceled')
        break

      case 'errored':
        text = renderFormatMessage('dashboard.transaction.errored', 'Errored')
        break

      case 'paid':
        text = renderFormatMessage('dashboard.transaction.paid', 'Paid')
        break

      case 'pre-authorized':
        text = renderFormatMessage('dashboard.transaction.preauthorized', 'Pre-authorized')
        break

      case 'no-reservation':
        text = renderFormatMessage('dashboard.transaction.noReservation', 'No Reservation')
        break
      case 'checked_in':
        text = 'Checked In'
        break
    }

    return text
  }
  return (
    <Skeleton loading={false}>
      <HomePageTransactionOverviewContainer>
        <TransactionsCollapseWrapper>
          <Collapse accordion bordered={false} expandIconPosition="right">
            {transactions.map((transaction) => (
              <Collapse.Panel
                key={transaction.txnId}
                header={
                  <div
                    className="paragraph-01-bold"
                    style={{
                      minHeight: '12px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {renderFormatMessage('dashboard.transactionsPage.table.transactionId', 'Transaction ID')}
                  </div>
                }
                extra={transaction.txnId}
              >
                <List
                  dataSource={[transaction]}
                  renderItem={(transaction) => (
                    <>
                      <List.Item
                        actions={[
                          <div className={`state ${transaction.startMethod.toLowerCase()} paragraph-01-regular`}>
                            {transaction.startMethod}
                          </div>,
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.startMethod}-tenant`}
                          title={<div className="paragraph-01-bold">Start Method</div>}
                        />
                      </List.Item>
                      <List.Item actions={[<div className="paragraph-01-regular">{transaction.username}</div>]}>
                        <List.Item.Meta
                          key={`${transaction.username}-tenant`}
                          title={<div className="paragraph-01-bold">User</div>}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          <div className="paragraph-01-regular">
                            {secondsToTime(transaction.plugInDuration).h}h {secondsToTime(transaction.plugInDuration).m}
                            m {secondsToTime(transaction.plugInDuration).s}s
                          </div>,
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.plugInDuration}-tenant`}
                          title={<div className="paragraph-01-bold">Plug-in Duration</div>}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.connected === null ? (
                            <p className="paragraph-01-regular isUndefined">...</p>
                          ) : (
                            <DateContainer>
                              <div>
                                <div className="paragraph-01-regular" style={{ opacity: 0.6 }}>
                                  {`${transaction.connected
                                    .split(' ')[1]
                                    .substring(0, transaction.connected.split(' ')[1].indexOf('-'))} ${renderTZ(
                                    transaction.connected,
                                  )}`}
                                  {'  '}
                                </div>
                                <div className="paragraph-01-regular">
                                  {transaction.connected.split(' ')[0].replaceAll('-', '/')}
                                </div>
                              </div>
                            </DateContainer>
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.connected}-tenant`}
                          title={
                            <div className="paragraph-01-bold">
                              {renderFormatMessage('dashboard.transactionsPage.table.connected', 'Connected')}
                            </div>
                          }
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.disconnected === null ? (
                            <p className="paragraph-01-regular isUndefined">...</p>
                          ) : (
                            <DateContainer>
                              <div>
                                <div className="paragraph-01-regular" style={{ opacity: 0.6 }}>
                                  {`${transaction.disconnected
                                    .split(' ')[1]
                                    .substring(0, transaction.disconnected.split(' ')[1].indexOf('-'))} ${renderTZ(
                                    transaction.disconnected,
                                  )}`}
                                  {'  '}
                                </div>
                                <div className="paragraph-01-regular">
                                  {transaction.disconnected.split(' ')[0].replaceAll('-', '/')}
                                </div>
                              </div>
                            </DateContainer>
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.disconnected}-tenant`}
                          title={
                            <div className="paragraph-01-bold">
                              {renderFormatMessage('dashboard.transactionsPage.table.disconnected', 'Disconnected')}
                            </div>
                          }
                        />
                      </List.Item>
                      <List.Item actions={[<div className="paragraph-01-regular">{transaction.energy} kWh</div>]}>
                        <List.Item.Meta
                          key={`${transaction.energy}-tenant`}
                          title={<div className="paragraph-01-bold">Energy(kWh)</div>}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.state === 'no-reservation' || transaction.state === 'checked_in' ? (
                            <p className="paragraph-01-regular isUndefined">...</p>
                          ) : (
                            <span className={`state ${renderState(transaction.state)} paragraph-01-regular`}>
                              {renderStateText(transaction.state)}
                            </span>
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.state}-tenant`}
                          title={
                            <div className="paragraph-01-bold">
                              {renderFormatMessage('dashboard.transactionsPage.table.state', 'State')}
                            </div>
                          }
                        />
                      </List.Item>
                      <List.Item
                        actions={[<div className="paragraph-01-regular">${(transaction.total / 100).toFixed(2)}</div>]}
                      >
                        <List.Item.Meta
                          key={`${transaction.total}-tenant`}
                          title={
                            <div className="paragraph-01-bold">
                              {renderFormatMessage('dashboard.transactionsPage.table.total', 'Total')}
                            </div>
                          }
                        />
                      </List.Item>
                    </>
                  )}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </TransactionsCollapseWrapper>
      </HomePageTransactionOverviewContainer>
    </Skeleton>
  )
}

export default TransactionOverviewCollapse
