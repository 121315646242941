import { RevenueSharePartnerSelectorWrapper } from 'atom/tenants'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useRevenueSharePartnerSelector } from 'hooks/useRevenueSharePartnerSelector'
import { Tagging } from 'models/tagging'

interface props {
  defaultSelectedPartner?: Tagging
  disabled?: boolean
  hideAdd?: boolean
  placeholder?: string
  onSelect: (revPartner: Tagging) => void
  dropdownClassName?: string
  customWidth?: string
}

export const RevenueSharePartnerSelector: React.FC<props> = ({
  defaultSelectedPartner,
  disabled = false,
  hideAdd,
  placeholder,
  onSelect,
  dropdownClassName,
  customWidth,
}) => {
  const {
    options,
    handleTaggingSelectChange,
    renderTaggingOption,
    dropdownRender,
    handleSearch,
    revenueSharePartnerPrefix,
  } = useRevenueSharePartnerSelector()

  const defaultValue = defaultSelectedPartner
    ? {
        label: defaultSelectedPartner?.tagName.replace(revenueSharePartnerPrefix, ''),
        value: defaultSelectedPartner?.tagName.replace(revenueSharePartnerPrefix, ''),
        id: defaultSelectedPartner?.id,
      }
    : null
  return (
    <RevenueSharePartnerSelectorWrapper>
      <ReusableSelector
        dropdownClassName={dropdownClassName}
        showSearch
        customWidth={customWidth}
        disabled={disabled}
        isSingle
        isDebounceFetcher
        debounceFetcher={handleSearch}
        showMagnifySVG={false}
        maxTagCount={0}
        oneTimeUpdateDefaultValues
        defaultValues={defaultValue ? [defaultValue].map((val) => val.label) : undefined}
        placeholder={placeholder}
        handleOptionChange={handleTaggingSelectChange}
        renderOption={renderTaggingOption}
        onOptionsChange={onSelect}
        dropdownRender={dropdownRender(hideAdd)}
        dropDownList={options}
      />
    </RevenueSharePartnerSelectorWrapper>
  )
}
