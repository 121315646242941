import { Empty, EmptyProps } from 'antd'

import { emptyBoxSVG } from '../../assets/svg/EmptyBox'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'

export const EmptyData: React.FC<EmptyProps> = ({ ...props }) => {
  const { noDataToDisplayText } = useGeneralTranslation()

  const description = props.description || noDataToDisplayText

  return (
    <>
      <Empty {...props} image={emptyBoxSVG} description={description} />
      <br />
    </>
  )
}
