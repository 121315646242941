import { useEffect, useState } from 'react'
import moment from 'moment'

import { ActivityTracking } from 'models/activity-tracking'
import { ActivityTrackingFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'
import { SwtchError } from 'models/error'

import { GetTenantActivityTracking } from 'services/data-provider/tenants'

export const useTenantActivityTracking = (tenantId: string) => {
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [activityTracking, setActivityTracking] = useState<ActivityTracking[]>([])
  const [showSearchQuery, setShowSearchQuery] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>({ currentPage: 1, totalEntries: 0, perPage: 20 })

  const [filter, setFilter] = useState<ActivityTrackingFilter>({
    page: 1,
    systemAreas: [],
    listings: [],
    users: [],
    term: { tenantTerms: [], listingTerms: [], pricingSchemaTerms: [] },
  })

  useEffect(() => {
    fetchTenantActivityTracking()
  }, [])

  const fetchTenantActivityTracking = (page = 1) => {
    setLoading(true)
    setError(undefined)
    GetTenantActivityTracking(tenantId, startDate, endDate, { ...filter, page })
      .then((response) => {
        setActivityTracking(response.data)
        setPagination(response.pagination)
        setFilter({ ...filter, page })
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const fetchDataWithQuery = () => {
    setShowSearchQuery(true)
    fetchTenantActivityTracking()
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchTenantActivityTracking(page)
    return
  }

  return {
    error,
    loading,
    startDate,
    endDate,
    activityTracking,
    pagination,
    filter,
    showSearchQuery,
    fetchTenantActivityTracking,
    fetchDataWithQuery,
    handleChangeDate,
    setFilter,
    handlePaginationChange,
  }
}
