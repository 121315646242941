import { theme } from '../../theme'

export const pdfIconSVG = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3432 4.23287L11.1367 0.0905426C11.0802 0.0339535 11.0009 0 10.9179 0H3.15768C1.98439 0 1.0337 1.00351 1.0337 2.24093V9.45098H0V15.0571H1.0337V15.5626C1.0337 16.7963 1.98817 17.8035 3.15768 17.8035H13.3211C14.4944 17.8035 15.4451 16.8 15.4451 15.5626V4.47809C15.4451 4.38755 15.4111 4.297 15.3432 4.23287ZM10.8123 0.69416L14.4717 4.30078H12.4798C11.5593 4.30078 10.8123 3.56512 10.8123 2.66346V0.69416ZM14.8037 15.5626C14.8037 16.4265 14.1397 17.1282 13.3211 17.1282H3.15768C2.33902 17.1282 1.67504 16.4265 1.67504 15.5626V15.0571H8.72982C10.1068 15.0571 11.2197 13.8008 11.2197 12.254C11.2197 10.7073 10.1031 9.45098 8.72982 9.45098H1.67504V2.24093C1.67504 1.377 2.33902 0.675297 3.15768 0.675297H10.171V2.66346C10.171 3.93861 11.2047 4.97607 12.4798 4.97607H14.8037V15.5626ZM1.98439 13.6688V10.8393H3.06713C3.38026 10.8393 3.62548 10.9185 3.79902 11.0808C3.97256 11.243 4.0631 11.4542 4.0631 11.7183C4.0631 11.9824 3.97633 12.1974 3.79902 12.3559C3.62171 12.5143 3.37649 12.5973 3.06713 12.5973H2.45597V13.6688H1.98439ZM4.63654 13.6688V10.8393H5.57215C5.93054 10.8393 6.22104 10.9525 6.44739 11.1751C6.67375 11.3976 6.78315 11.6881 6.78315 12.039V12.4691C6.78315 12.8237 6.66998 13.1104 6.44739 13.333C6.22104 13.5556 5.93054 13.6688 5.57215 13.6688H4.63654ZM9.0354 12.0843V12.4615H7.89608V13.6688H7.42073V10.8393H9.23158V11.2166H7.8923V12.0805H9.0354V12.0843Z"
      fill={theme.colors.dangerPrimary}
    />
    <path
      d="M3.46334 12.0771C3.55011 11.9828 3.59538 11.8658 3.59538 11.7225C3.59538 11.5791 3.55011 11.4584 3.46334 11.3641C3.37657 11.2697 3.24453 11.2207 3.06722 11.2207H2.45605V12.2204H3.06722C3.24453 12.2167 3.3728 12.1714 3.46334 12.0771Z"
      fill={theme.colors.dangerPrimary}
    />
    <path
      d="M5.57243 11.2207H5.1084V13.2956H5.57243C5.79879 13.2956 5.97987 13.2202 6.11191 13.0655C6.24395 12.9108 6.31186 12.7147 6.31186 12.4732V12.0394C6.31186 11.8017 6.24395 11.6055 6.11191 11.4508C5.97987 11.2962 5.79879 11.2207 5.57243 11.2207Z"
      fill={theme.colors.dangerPrimary}
    />
  </svg>
)
