import { useFormatMessage } from '../../helpers/intl'
export const useTenantTabPricingTranslation = () => {
  const uniformPricingUpdatedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.updatedtext',
    'uniform pricing updated!',
  )

  const uniformEnabledText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.enabled',
    'Uniform Pricing Enabled for all the listings in the tenant',
  )
  const uniformDisabledText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.disabled',
    'Uniform Pricing Disabled for all the listings in the tenant',
  )

  const listingAuthorText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.listing',
    'Listing Author',
  )

  const infoTextp1 = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p1',
    'If enabled, it indicates that all chargers in this tenant will have the same pricing configuration.',
  )
  const infoTextp2 = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p2',
    ' If disabled, then each charger’s pricing settings must be configured individually',
  )

  const stripeAccountText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.stripeAccount.text',
    'Stripe Account',
  )

  const selectListingAuthorText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectListingAuthorText',
    'Select listing author',
  )

  const selectStripeAccountText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectStripeAccountText',
    'Select Stripe Account',
  )

  const priceCentsText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText',
    'Price ($)',
  )

  const enableLoadManagementPricingText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText',
    'Hourly pricing based on kWh',
  )

  const enableTenantPreferredPricing = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.enableTenantPreferredPricingText',
    'Enable Tenant Preferred Pricing',
  )

  const tenantPreferredPricingText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPricingText',
    'Site Preferred Pricing ($)',
  )

  const tenantPreferredPricingWarningText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredWarningText',
    'Preferred Price cannot be empty!',
  )
  const tenantPreferredPricingPriceTypeWarningText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPriceTypeWarningText',
    'Please select a type for preferred price',
  )

  const priceTypeHourText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText',
    'Hour',
  )
  const priceText = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText', 'Price')
  const flatFeeText = useFormatMessage('dashboard.discountSchema.flatFee', 'Flat Fee')

  const defineNewComissionTypeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText',
    'Define New Commission Fee Type',
  )

  const defineNewComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText',
    'Define New Commission Fee (%)',
  )

  const defineNewComissionTypeWarningText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText',
    'Commission fee cannot be empty!',
  )

  const guestFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.guestFeeText',
    'Guest Fee ($)',
  )

  const paymentLimitText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.paymentLimitText',
    'Payment Limit ($)',
  )

  const loiteringStatusText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText',
    'Loitering Status',
  )

  const loiteringStatusNoneText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none',
    'None',
  )
  const loiteringStatusStatusBasedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased',
    'Status Based',
  )
  const loiteringStatusTimeBasedText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased',
    'Time Based',
  )

  const loiteringPenaltyPerHourText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour',
    'Loitering Penalty Per Hour ($)',
  )

  const loiteringtimeLimitText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText',
    'Loitering Time Limit For Time-Based In Minutes',
  )

  const emptyFieldWarning = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield',
    'Field cannot be empty!',
  )

  const loiteringtimeLimitGraceMinutesText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes',
    'Loitering Time Limit For Grace-Period in Minutes',
  )

  const selectComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText',
    'Select Commission Fee',
  )

  const selectPreferredPricingOptionText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPreferredPricingOptionText',
    'Select Preferred Pricing Option',
  )

  const selectPricingOptionText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText',
    'Select Pricing Option',
  )

  const uniformPricingText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing.text',
    'Uniform Pricing',
  )

  const enableText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing..enable.text',
    'Enable',
  )

  const priceTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText', 'Price')

  const todTxt = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading',
    'Enable Time of Day Pricing',
  )
  const weekdaysTxt = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays',
    'Weekdays',
  )
  const weekendsTxt = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends',
    'Weekends',
  )
  const startTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start', 'Start')
  const endTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end', 'End')

  const disableLoiteringTxt = useFormatMessage(
    'dashboard.tenantPage.enableLoitering',
    'Disable Loitering For Site Member',
  )

  return {
    uniformPricingUpdatedText,
    uniformEnabledText,
    uniformDisabledText,
    listingAuthorText,
    infoTextp1,
    infoTextp2,
    stripeAccountText,
    selectListingAuthorText,
    selectStripeAccountText,
    priceCentsText,
    enableLoadManagementPricingText,
    enableTenantPreferredPricing,
    tenantPreferredPricingText,
    tenantPreferredPricingWarningText,
    tenantPreferredPricingPriceTypeWarningText,
    priceTypeHourText,
    priceText,
    flatFeeText,
    defineNewComissionTypeText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    guestFeeText,
    paymentLimitText,
    loiteringStatusText,
    loiteringStatusNoneText,
    loiteringStatusStatusBasedText,
    loiteringStatusTimeBasedText,
    loiteringPenaltyPerHourText,
    loiteringtimeLimitText,
    emptyFieldWarning,
    loiteringtimeLimitGraceMinutesText,
    selectComissionFeeText,
    selectPreferredPricingOptionText,
    selectPricingOptionText,
    uniformPricingText,
    enableText,
    priceTxt,
    todTxt,
    weekdaysTxt,
    weekendsTxt,
    startTxt,
    endTxt,
    disableLoiteringTxt,
  }
}
