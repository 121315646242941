import { theme } from '../../theme'

export const InfoIcon = (props: any) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM8.1 11.7V13.5H9.9V11.7H8.1ZM9.9 10.2195C10.6233 10.0015 11.2442 9.53096 11.6497 8.89357C12.0552 8.25618 12.2183 7.49435 12.1093 6.74682C12.0002 5.99929 11.6263 5.31579 11.0556 4.82081C10.4849 4.32583 9.75544 4.05228 9 4.05C8.27177 4.04994 7.566 4.30214 7.00271 4.76369C6.43943 5.22525 6.05341 5.86767 5.9103 6.5817L7.6761 6.9354C7.72621 6.68469 7.84649 6.45336 8.02293 6.26833C8.19937 6.08331 8.42473 5.95219 8.67278 5.89023C8.92083 5.82828 9.18138 5.83804 9.4241 5.91837C9.66682 5.99871 9.88174 6.14631 10.0438 6.34402C10.206 6.54173 10.3086 6.7814 10.3398 7.03516C10.371 7.28892 10.3295 7.54632 10.2202 7.77742C10.1108 8.00853 9.9381 8.20383 9.72209 8.3406C9.50608 8.47737 9.25567 8.54998 9 8.55C8.7613 8.55 8.53239 8.64482 8.3636 8.8136C8.19482 8.98239 8.1 9.2113 8.1 9.45V10.8H9.9V10.2195Z"
        fill={theme.colors.lightGray}
      />
    </svg>
  )
}

export const infoWithISVG = (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.49905 0.973633C14.7463 0.973633 19 5.22731 19 10.4746C19 15.7209 14.7463 19.9736 9.49905 19.9736C4.25177 19.9746 1.591e-07 15.7209 1.591e-07 10.4746C-0.000949746 5.22731 4.25177 0.973633 9.49905 0.973633ZM9.49525 8.81035C9.26246 8.81067 9.03789 8.89646 8.86418 9.05143C8.69047 9.2064 8.57971 9.41977 8.55294 9.65101L8.54629 9.7612L8.55009 14.9876L8.55579 15.0978C8.58234 15.3295 8.69319 15.5433 8.86722 15.6985C9.04126 15.8538 9.26632 15.9395 9.49952 15.9395C9.73273 15.9395 9.95779 15.8538 10.1318 15.6985C10.3059 15.5433 10.4167 15.3295 10.4433 15.0978L10.449 14.9866L10.4452 9.76025L10.4385 9.64911C10.411 9.41806 10.2997 9.20512 10.1257 9.05068C9.95165 8.89625 9.72698 8.81107 9.4943 8.8113L9.49525 8.81035ZM9.5 5.24916C9.18471 5.24916 8.88233 5.3744 8.65939 5.59735C8.43644 5.82029 8.31119 6.12267 8.31119 6.43796C8.31119 6.75325 8.43644 7.05563 8.65939 7.27857C8.88233 7.50152 9.18471 7.62677 9.5 7.62677C9.65605 7.6267 9.81057 7.59591 9.95472 7.53613C10.0989 7.47635 10.2298 7.38877 10.3401 7.27838C10.4504 7.16799 10.5379 7.03695 10.5976 6.89275C10.6572 6.74855 10.6879 6.59402 10.6879 6.43796C10.6878 6.28191 10.657 6.12739 10.5972 5.98324C10.5374 5.83909 10.4499 5.70813 10.3395 5.59782C10.2291 5.48752 10.098 5.40004 9.95384 5.34038C9.80964 5.28072 9.6551 5.25004 9.49905 5.2501L9.5 5.24916Z"
      fill={theme.colors.darkGray}
    />
  </svg>
)

export const redInfoWithISVG = (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.49905 0.973633C14.7463 0.973633 19 5.22731 19 10.4746C19 15.7209 14.7463 19.9736 9.49905 19.9736C4.25177 19.9746 1.591e-07 15.7209 1.591e-07 10.4746C-0.000949746 5.22731 4.25177 0.973633 9.49905 0.973633ZM9.49525 8.81035C9.26246 8.81067 9.03789 8.89646 8.86418 9.05143C8.69047 9.2064 8.57971 9.41977 8.55294 9.65101L8.54629 9.7612L8.55009 14.9876L8.55579 15.0978C8.58234 15.3295 8.69319 15.5433 8.86722 15.6985C9.04126 15.8538 9.26632 15.9395 9.49952 15.9395C9.73273 15.9395 9.95779 15.8538 10.1318 15.6985C10.3059 15.5433 10.4167 15.3295 10.4433 15.0978L10.449 14.9866L10.4452 9.76025L10.4385 9.64911C10.411 9.41806 10.2997 9.20512 10.1257 9.05068C9.95165 8.89625 9.72698 8.81107 9.4943 8.8113L9.49525 8.81035ZM9.5 5.24916C9.18471 5.24916 8.88233 5.3744 8.65939 5.59735C8.43644 5.82029 8.31119 6.12267 8.31119 6.43796C8.31119 6.75325 8.43644 7.05563 8.65939 7.27857C8.88233 7.50152 9.18471 7.62677 9.5 7.62677C9.65605 7.6267 9.81057 7.59591 9.95472 7.53613C10.0989 7.47635 10.2298 7.38877 10.3401 7.27838C10.4504 7.16799 10.5379 7.03695 10.5976 6.89275C10.6572 6.74855 10.6879 6.59402 10.6879 6.43796C10.6878 6.28191 10.657 6.12739 10.5972 5.98324C10.5374 5.83909 10.4499 5.70813 10.3395 5.59782C10.2291 5.48752 10.098 5.40004 9.95384 5.34038C9.80964 5.28072 9.6551 5.25004 9.49905 5.2501L9.5 5.24916Z"
      fill={theme.colors.dangerPrimary}
    />
  </svg>
)
