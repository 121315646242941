import { Button, Card, Col, Collapse, List, Row, Tag, Tooltip } from 'antd'

import { CardBody } from '../../../atom/card'
import { SelectorWrapper } from '../../../atom/select'
import { SubscriptionStatusBadge } from '../../../components/subscriptions/subscription-status-badge'
import { formatDate } from '../../../helpers/date-format'
import { BillingAddress, Subscription } from '../../../models/subscription'
import moment from 'moment'
import { useSubscriptionTranslation } from '../../../hooks/translation/useSubscriptionTranslation'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { theme } from '../../../theme'

interface props {
  loading: boolean
  subscriptions: Subscription[]
  // editSub: (sub: Subscription) => void
  pauseSub: (sub: Subscription) => void
  unpauseSub: (sub: Subscription) => void
  cancelSub: (sub: Subscription) => void
}

export const SubscriptionCollapseView: React.FC<props> = ({
  loading,
  subscriptions,
  pauseSub,
  unpauseSub,
  cancelSub,
}) => {
  const parseBillingAddress = (billingAddress?: BillingAddress) => {
    if (billingAddress) {
      const { zip, state, country } = billingAddress
      return (
        <span>
          {zip}, {state}, {country}
        </span>
      )
    }
    return null
  }

  const handleStatus = (subscription: Subscription) => {
    return !subscription.plan ? (
      handleUnavailablePlan(subscription)
    ) : (
      <SubscriptionStatusBadge subscription={subscription} />
    )
  }

  const handleUnavailablePlan = (subscription: Subscription) => (
    <Tooltip title="No plan is associated">
      <Tag key={subscription.id} color="red">
        {unavailableText}
      </Tag>
      <ExclamationCircleOutlined style={{ fontSize: theme.fontSizes[2] }} />
    </Tooltip>
  )

  const { billingAddressText, cancelSubText, unpauseSubText, pauseSubText, planText } = useSubscriptionTranslation()
  const { startDateText, endDateText, unavailableText } = useGeneralTranslation()

  return (
    <Col xs={24} xl={24}>
      <CardBody>
        <Card loading={loading} bordered={false}>
          <Collapse accordion defaultActiveKey={1}>
            {subscriptions.map((sub) => (
              <Collapse.Panel extra={handleStatus(sub)} key={sub.id} header={sub.subscriber.email}>
                <List
                  dataSource={[sub]}
                  renderItem={(sub) => (
                    <>
                      <List.Item actions={[sub?.plan?.name]}>
                        <List.Item.Meta title={planText} />
                      </List.Item>
                      <List.Item actions={[parseBillingAddress(sub.billingAddress)]}>
                        <List.Item.Meta title={billingAddressText} />
                      </List.Item>
                      <List.Item actions={[sub.startDate ? formatDate(moment(sub.startDate).format()) : null]}>
                        <List.Item.Meta title={startDateText} />
                      </List.Item>
                      <List.Item actions={[sub.endDate ? formatDate(moment(sub?.endDate).format()) : null]}>
                        <List.Item.Meta title={endDateText} />
                      </List.Item>
                    </>
                  )}
                >
                  <SelectorWrapper>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} md={12}>
                        <Button
                          type="primary"
                          block={true}
                          onClick={() => pauseSub(sub)}
                          disabled={!sub.plan || sub.status === 'cancelled' || sub.status === 'paused'}
                        >
                          {pauseSubText}
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <Button
                          type="primary"
                          block={true}
                          onClick={() => unpauseSub(sub)}
                          disabled={!sub.plan || sub.status === 'cancelled'}
                        >
                          {unpauseSubText}
                        </Button>
                      </Col>
                      <Col xs={24} sm={12} md={12}>
                        <Button
                          type="primary"
                          block={true}
                          onClick={() => cancelSub(sub)}
                          disabled={!sub.plan || sub.status === 'canceled'}
                        >
                          {cancelSubText}
                        </Button>
                      </Col>
                    </Row>
                  </SelectorWrapper>
                </List>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Card>
      </CardBody>
    </Col>
  )
}
