export const mockVendors = [
  'Delta Electronics',
  'LITE-ON',
  'Alfen BV',
  'Tritium',
  'EV-BOX',
  'SMPCT',
  'PHIHONG',
  'The New Motion BV',
  'CMI',
  'Joint',
  'Atom Power',
  'OCA',
  'ABB',
  'Phihong AX48',
  'TopStar',
  'com.cnchargepoint',
  'IES',
  'EVVendorID',
  'EnelX',
  'telluspower',
  'UnitedChargers',
  'Phihong 48',
  'WWWW',
  'Teltonika_Energy',
  'XCHARGE',
  'Eaton',
  'SWTCH',
  'LGE',
  'guanke',
  'Teltonika Energy',
  'Power Electronics',
  'Zerova AX48',
  'H80',
]
