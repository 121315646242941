import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { Button, Card, Carousel, Col, Row } from 'antd'

import { EnergyGraph } from '../../../../components/graphs/energy-graph'
import { RevenueGraph } from '../../../../components/graphs/revenue-graph'
import { TransactionGraph } from '../../../../components/graphs/transaction-graph'

import { renderFormatMessage } from '../../../../helpers/intl'
import { TransactionMetricResponse } from '../../../../models/analytics'
import { styled } from '../../../../theme'

interface props {
  loading: boolean
  metrics?: TransactionMetricResponse
}

const CarouselArrow = styled.div`
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    color: ${(props) => props.theme.colors.ternary600};
  }
  .ant-carousel .slick-prev {
    left: -22px;
  }
  .ant-carousel .slick-next {
    right: -5px;
  }

  .ant-btn .anticon {
    font-size: ${(props) => props.theme.fontSizes[6]};
  }
`

const RightArrow = () => <Button icon={<RightCircleOutlined />} />

const LeftArrow = () => <Button icon={<LeftCircleOutlined />} />

export const ChargerGraphs: React.FC<props> = ({ loading, metrics }) => {
  return (
    <Row style={{ marginTop: '10px' }}>
      <Col span={24}>
        <Card
          loading={loading}
          title={renderFormatMessage(
            'dashboard.chargerPage.graph.heading',
            'Revenue, Transactions, and Energy Consumption',
          )}
          bordered={false}
        >
          <CarouselArrow>
            <Carousel effect="fade" arrows={true} prevArrow={LeftArrow()} nextArrow={RightArrow()}>
              <RevenueGraph data={metrics?.series || []} />
              <EnergyGraph data={metrics?.series || []} />
              <TransactionGraph data={metrics?.series || []} />
            </Carousel>
          </CarouselArrow>
        </Card>
      </Col>
    </Row>
  )
}
