const whiteLabelColorScheme = {
  swtch: {
    colors: {
      primary: '#30d7ba',
      secondary: '#000',
      ternary: '#FFF',
      multiArrow: '#fff',
      menuBackground: '#000',
      menuItemHover: '#0e1412',
      menuItemSelected: '#242E2C',
      fontSelected: '#30d7ba',
      fontNotSelected: '#FFF',
      iconPrimary: '#30d7ba',
      iconHovering: '#FFF',
      iconNotHovering: '#FFF',
      buttonPrimary: '#30d7ba',
      buttonHovering: '#00B799',
      activeSelection: 'rgba(48, 215, 186, 0.1)',
      tableSelectedRowBg: '#30d7ba1a',
      tableSelectedRowHoverBg: '#dbfff4',

      pieGraphPrimary: '#30D7BA',
      pieGraphSecondary: '#b9f0d2',
      pieGraphTernary: '#1C6D78',
      pieGraphQuaternary: '#242e2c',
      pieGraphQuinary: '#dedede',

      graphLinearGradientFrom: 'rgba(48, 215, 186, 0.21)',
      graphLinearGradientTo: 'rgba(48, 215, 186, 0.21)',

      regularFontFamily: 'AkkuratLLWeb-Regular',
      blackFontFamily: 'AkkuratLLWeb-Black',
      lightFontFamily: 'AkkuratLLWeb-Light',
      boldFontFamily: 'AkkuratLLWeb-Bold',

      tableCellFontFamily: 'AkkuratLLWeb-Regular',

      envFontFamily: 'AkkuratLL',
    },
  },
  itsElectric: {
    colors: {
      primary: '#12B5CB',
      secondary: '#D7D7D7',
      ternary: '#FFF',
      multiArrow: '#000',
      menuBackground: '#D7D7D7',
      menuItemHover: '#12B5CB',
      menuItemSelected: '#12B5CB',
      fontSelected: '#FFF',
      fontNotSelected: '#12B5CB',
      iconPrimary: '#12B5CB',
      iconHovering: '#FFF',
      iconNotHovering: '#12B5CB',
      buttonPrimary: '#12B5CB',
      buttonHovering: '#078ea6',
      tableSelectedRowBg: '#12b5cb1a',
      tableSelectedRowHoverBg: '#bafffe',

      activeSelection: '#e6fffe',
      pieGraphPrimary: '#12B5CB',
      pieGraphSecondary: '#1C6D78',
      pieGraphTernary: '#54DEC7',
      pieGraphQuaternary: '#242e2c',
      pieGraphQuinary: '#dedede',

      graphLinearGradientFrom: '#12B5CBA1',
      graphLinearGradientTo: '#12B5CB33',

      regularFontFamily: 'Chalet-NewYorkNineteenSixty',
      blackFontFamily: 'Graphik-Semibold',
      lightFontFamily: 'Graphik-Regular',
      boldFontFamily: 'Graphik-Medium',

      tableCellFontFamily: 'Chalet-NewYorkNineteenSixty',

      envFontFamily: 'Chalet',
    },
  },
  lakeland: {
    colors: {
      primary: '#EA1C2D',
      secondary: '#121F48',
      ternary: '#F7F7F7',
      multiArrow: '#FFF',
      menuBackground: '#000',
      menuItemHover: '#0e1412',
      menuItemSelected: '#121F48',
      fontSelected: '#EA1C2D',
      fontNotSelected: '#FFF',
      iconPrimary: '#EA1C2D',
      iconHovering: '#FFF',
      iconNotHovering: '#FFF',
      buttonPrimary: '#EA1C2D',
      buttonHovering: '#c40e23',
      tableSelectedRowBg: '#ea1c2d1a',
      tableSelectedRowHoverBg: '#ffc5c2',

      activeSelection: '#ffeceb',
      pieGraphPrimary: '#EA1C2D',
      pieGraphSecondary: '#121F485E',
      pieGraphTernary: '#121F48',
      pieGraphQuaternary: '#242e2c',
      pieGraphQuinary: '#dedede',

      graphLinearGradientFrom: '#EA1C2D5E',
      graphLinearGradientTo: '#EA1C2D26',

      regularFontFamily: 'Larken',
      blackFontFamily: 'Larken',
      lightFontFamily: 'Inter-Regular',
      boldFontFamily: 'Larken',

      tableCellFontFamily: 'Inter-Regular',

      envFontFamily: 'Larken',
    },
  },
  powercharge: {
    colors: {
      primary: '#ed351c',
      secondary: '#000',
      ternary: '#F7F7F7',
      multiArrow: '#FFF',
      menuBackground: '#000',
      menuItemHover: '#0e1412',
      menuItemSelected: '#242E2C',
      fontSelected: '#ed351c',
      fontNotSelected: '#FFF',
      iconPrimary: '#ed351c',
      iconHovering: '#ed351c',
      iconNotHovering: '#FFF',
      buttonPrimary: '#ed351c',
      buttonHovering: '#c40e23',
      tableSelectedRowBg: '#ed351c1a',
      tableSelectedRowHoverBg: '#ffd1c2',

      activeSelection: '#ffeceb',
      pieGraphPrimary: '#ed351c',
      pieGraphSecondary: '#121F485E',
      pieGraphTernary: '#121F48',
      pieGraphQuaternary: '#242e2c',
      pieGraphQuinary: '#dedede',

      graphLinearGradientFrom: '#ed351c5E',
      graphLinearGradientTo: '#ed351c26',

      regularFontFamily: 'AkkuratLLWeb-Regular',
      blackFontFamily: 'AkkuratLLWeb-Black',
      lightFontFamily: 'AkkuratLLWeb-Light',
      boldFontFamily: 'AkkuratLLWeb-Bold',

      tableCellFontFamily: 'AkkuratLLWeb-Regular',

      envFontFamily: 'AkkuratLL',
    },
  },
}

module.exports = {
  whiteLabelColorScheme,
}
