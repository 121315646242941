import { Form, Input } from 'antd'
import { useEffect } from 'react'
import { GetListings } from 'services/data-provider/listing'
import { usePricingSchemas } from 'hooks/usePricingSchemas'
import { NewListing } from 'models/listing'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { usePlanTranslation } from 'hooks/translation/usePlanTranslation'
import { InfoTextWrapper } from 'atom/price'
import { redInfoWithISVG } from 'assets/svg/info'
import { Tenant } from 'models/tenant'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'

interface props {
  index: number
  tenant: Tenant
  discountNameExists: { [key: number]: boolean }
  promoCodeExists: { [key: number]: boolean }
  nameCheck: (value: string, type: string, index: number) => void
  getDiscountNames: (tenantId: number) => void
  setAllListings: (listings: NewListing[]) => void
}

export const DiscountNameSectionModal: React.FC<props> = ({
  index,
  tenant,
  discountNameExists,
  promoCodeExists,
  nameCheck,
  getDiscountNames,
  setAllListings,
}) => {
  const { nameInUseText } = usePricingSchemasTranslation()
  const { nameText } = usePlanTranslation()
  const { setError, setLoading } = usePricingSchemas()
  const { promoCodeText, promoCodeInfoText, discountPlanNameText } = useDiscountSchemaTranslation()

  useEffect(() => {
    getDiscountNames(Number(tenant.id))
    if (tenant) {
      GetListings({ defaultTenant: tenant, allListings: true })
        .then((listings) => {
          if (Array.isArray(listings)) setAllListings(listings)
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }, [])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Form.Item
          className="name-title"
          rules={[{ required: true }]}
          validateStatus={discountNameExists[index] ? 'error' : ''}
          label={<>{nameText}:</>}
          name={[index, 'name']}
          style={{ marginRight: '10px', marginBottom: '16px' }}
        >
          <Input
            onChange={(e) => {
              nameCheck(e.target.value, 'discountName', index)
            }}
            placeholder={discountPlanNameText}
            style={{ fontSize: '14px', paddingLeft: '12px' }}
          />
        </Form.Item>
        {discountNameExists[index] && (
          <InfoTextWrapper>
            <div style={{ marginBottom: '8px' }}>{redInfoWithISVG}</div>{' '}
            <span className="danger-text">{nameInUseText}</span>
          </InfoTextWrapper>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <Form.Item
          className="promo-code-title"
          rules={[{ required: true }]}
          validateStatus={promoCodeExists[index] ? 'error' : ''}
          label={<>{promoCodeText}:</>}
          name={[index, 'promo_code']}
          style={{ marginRight: '10px', alignItems: 'flex-start' }}
          help={promoCodeInfoText}
        >
          <Input
            onChange={(e) => {
              nameCheck(e.target.value, 'promoCode', index)
            }}
            placeholder={promoCodeText}
            style={{ fontSize: '14px', paddingLeft: '12px' }}
            maxLength={255}
          />
        </Form.Item>
        {promoCodeExists[index] && (
          <InfoTextWrapper style={{ marginBottom: '15px', marginLeft: '-64px' }}>
            <div style={{ marginBottom: '8px' }}>{redInfoWithISVG}</div>{' '}
            <span className="danger-text">{nameInUseText}</span>
          </InfoTextWrapper>
        )}
      </div>
    </>
  )
}
