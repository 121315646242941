import { Card, Col, Dropdown, Empty, Form, Menu, Row, Space, Spin } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { cancelEventSVG } from '../../../assets/svg/cancel-event'
import { DotsSVG } from '../../../assets/svg/dots'
import { editIconSVG } from '../../../assets/svg/edit'
import { PeakShavingAddIconSVG } from '../../../assets/svg/peak-shaving-add-icon'
import { peakShavingInfoSVG } from '../../../assets/svg/peak-shaving-info-icon'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError, SwtchError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingProgram } from '../../../models/peak-shaving'
import {
  DeletePeakShavingEvent,
  GetDailyShavingEvent,
  UnPublishPeakShavingEvent,
} from '../../../services/data-provider/peak-shaving'
import { styled, theme } from '../../../theme'
import { EditDailyScheduleModal } from '../modal/edit-daily-schedule-modal'
import { RemoveScheduleModal } from '../modal/remove-schedule'

interface props {
  onClickCreateNewSchedule: () => void
  onClickRemoveSchedule: () => void
  onSubmit(): void
  program: PeakShavingProgram
  term: string
}

const ScheduleDetailsContainer = styled.div<{ eventPresent: boolean }>`
  .ant-card {
    background: ${(props) => props.theme.colors.white};
    border-radius: 5px;
    box-shadow: 0px 4px 17px 0px #00000012;
    min-height: 179px;
    display: ${(props) => (!props.eventPresent ? 'flex' : '')};
    justify-content: center;
    align-items: center;
  }

  .ant-card-body {
    padding: 10px 15px 15px 15px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  label {
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 15.6px;
    color: ${(props) => props.theme.colors.softBlack};
  }

  .edit-schedule-icon {
    rotate: 0deg;
    cursor: pointer;
    transition: all 500ms ease;
  }

  .ant-dropdown-trigger.ant-dropdown-open .edit-schedule-icon {
    rotate: 90deg;
  }

  .ant-dropdown-trigger.ant-dropdown-open svg rect {
    stroke: ${(props) => props.theme.colors.buttonPrimary};
  }

  .ant-col.ant-form-item-label,
  .ant-col.ant-col-23,
  .ant-col.ant-col-1 {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .ant-empty-image {
    height: auto;
    margin-bottom: 0;
  }
  .ant-empty-description {
    max-width: 321px;
    margin: 0 auto;
  }
`

const formItemLayout = {
  labelCol: {
    md: {
      span: 3,
    },
    lg: {
      span: 3,
    },
    xl: {
      span: 3,
    },
    xxl: {
      span: 2,
    },
  },
  wrapperCol: {
    md: {
      span: 21,
      offset: 0,
    },
    lg: {
      span: 21,
      offset: 0,
    },
    xl: {
      span: 21,
      offset: 0,
    },
    xxl: {
      span: 22,
      offset: 0,
    },
  },
}

export const DailyScheduleDetailsForm: React.FC<props> = ({
  onClickCreateNewSchedule,
  program,
  term,
  onClickRemoveSchedule,
  onSubmit,
}) => {
  const [editDailyScheduleModalVisible, setEditDailyScheduleModalVisible] = useState(false)
  const [removeScheduleModalVisible, setRemoveScheduleModalVisible] = useState(false)
  const [dailySchedule, setDailySchedule] = useState<PeakShavingEvent>()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [triggerTerm, setTriggerTerm] = useState<string>(_.uniqueId())
  const [dailyScheduleForm] = Form.useForm()
  const {
    scheduleText,
    updatedByText,
    allowOptOutText,
    startDateText,
    intervalText,
    notestText,
    editScheduleText,
    removeScheduleText,
    dailyScheduleInfoText,
    dailyText,
    yesText,
    noText,
  } = usePeakShavingTranslation()

  useEffect(() => {
    setLoading(true)
    GetDailyShavingEvent(program.id)
      .then((event) => {
        _.isEmpty(event) ? setDailySchedule(undefined) : setDailySchedule(event)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [program, term, triggerTerm])

  const removeDailySchedule = () => {
    // unpublish daily schedule event and then delete it
    setLoading(true)
    dailySchedule &&
      UnPublishPeakShavingEvent(dailySchedule.id)
        .then(() => {
          DeletePeakShavingEvent(dailySchedule.id)
            .then(({ response }) => {
              setDailySchedule(undefined)
              CustomSuccessMessage({ key: 'Remove Daily Schedule', response: response })
              onClickRemoveSchedule()
            })
            .catch((err: SwtchApiError) => {
              setError(err)
            })
        })
        .catch((err: SwtchApiError) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
          setRemoveScheduleModalVisible(!removeScheduleModalVisible)
          setTriggerTerm(_.uniqueId())
        })
  }

  return (
    <>
      <Row style={{ marginBottom: '17px' }}>
        <Col span={24}>
          <Space>
            <span className="heading-05-regular">{scheduleText}</span>
            {!dailySchedule && program && program.state !== 'archived' && (
              <PeakShavingAddIconSVG
                style={{ marginTop: '5px', cursor: 'pointer' }}
                onClick={onClickCreateNewSchedule}
              />
            )}
          </Space>
        </Col>
      </Row>
      <ScheduleDetailsContainer eventPresent={dailySchedule ? true : false}>
        <AlertError error={error} />
        <Spin spinning={loading}>
          <Card>
            {dailySchedule && (
              <Form {...formItemLayout} form={dailyScheduleForm} initialValues={{ notes: dailySchedule.notes }}>
                <Form.Item label={updatedByText} name="updatedBy">
                  <Row>
                    <Col span={23}>
                      <span className="paragraph-02-regular">{dailySchedule.adminPerson.name}</span>
                      <span
                        className="paragraph-04-light"
                        style={{
                          marginLeft: '10px',
                          color: theme.colors.softBlack,
                          opacity: 0.6,
                          marginBottom: '-2px',
                        }}
                      >
                        {moment(dailySchedule.modificationDateTime).format('MMMM DD, Y')}
                      </span>
                    </Col>
                    <Col span={1}>
                      <Dropdown
                        overlay={
                          <Menu className="edit-schedule-dropdown">
                            <Menu.Item
                              icon={editIconSVG}
                              onClick={() => {
                                setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
                              }}
                              key="editSchedule"
                            >
                              <p className="paragraph-02-regular">{editScheduleText}</p>
                            </Menu.Item>
                            <Menu.Item
                              icon={cancelEventSVG}
                              onClick={() => {
                                setRemoveScheduleModalVisible(!removeScheduleModalVisible)
                              }}
                              key="removeSchedule"
                            >
                              <p className="paragraph-02-regular">{removeScheduleText}</p>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <span onClick={(e) => e.preventDefault()}>{<DotsSVG className="edit-schedule-icon" />}</span>
                      </Dropdown>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label={allowOptOutText} name="allowOptOut">
                  <span className="paragraph-02-regular">{dailySchedule.allowOptOut ? yesText : noText}</span>
                </Form.Item>
                <Form.Item label={startDateText} name="date">
                  <span className="paragraph-02-regular">{dailySchedule.date}</span>
                </Form.Item>
                <Form.Item label={intervalText} name="interval">
                  <span className="paragraph-02-regular">{dailyText}</span>
                </Form.Item>
                <Form.Item label={notestText} name="notes">
                  <span className="paragraph-02-regular">{dailySchedule.notes}</span>
                </Form.Item>
              </Form>
            )}
            {!dailySchedule && (
              <Empty
                image={peakShavingInfoSVG}
                description={
                  <p className="paragraph-04-regular" style={{ color: theme.colors.darkGray }}>
                    {dailyScheduleInfoText}
                  </p>
                }
              />
            )}
          </Card>
        </Spin>
      </ScheduleDetailsContainer>
      {editDailyScheduleModalVisible && dailySchedule && (
        <EditDailyScheduleModal
          visible={editDailyScheduleModalVisible}
          onCancel={() => {
            setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
          }}
          onSubmit={() => {
            setEditDailyScheduleModalVisible(!editDailyScheduleModalVisible)
            setTriggerTerm(_.uniqueId())
            onSubmit()
          }}
          eventId={dailySchedule.id}
        />
      )}
      {removeScheduleModalVisible && (
        <RemoveScheduleModal
          visible={removeScheduleModalVisible}
          onCancel={() => {
            setRemoveScheduleModalVisible(!removeScheduleModalVisible)
          }}
          onSubmit={removeDailySchedule}
        />
      )}
    </>
  )
}
