import { Col, Form, Space } from 'antd'
import { NewListing } from 'models/listing'
import { Tenant } from 'models/tenant'
import { BasicListingInfo, PricingSchemaPayload } from 'models/price'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { GrayBackground } from 'atom/discount'
import CoreBadge from 'components/badge/Badge'

interface props {
  tenant: Tenant
  listings: NewListing[] | BasicListingInfo[] | undefined
  pricingSchemaValues: PricingSchemaPayload | undefined
}

export const InfoSectionModal: React.FC<props> = ({ tenant, listings, pricingSchemaValues }) => {
  const { tenantText, listingsText, pricingPlanText } = useGeneralTranslation()

  return (
    <GrayBackground>
      <Col span={10}>
        <Form.Item label={<>{tenantText}:</>} style={{ marginBottom: '25px' }}>
          <CoreBadge title={tenant?.displayName || tenant?.name} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={<>{pricingPlanText}:</>} style={{ marginBottom: '25px' }}>
          <CoreBadge title={pricingSchemaValues?.name ? pricingSchemaValues?.name : ''} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={<>{listingsText}:</>} style={{ marginBottom: '0px', alignItems: 'flex-start' }}>
          <div style={{ width: '825px' }}>
            <Space wrap>
              {listings?.map((listing: NewListing | BasicListingInfo) => (
                <CoreBadge title={listing.title} />
              ))}
            </Space>
          </div>
        </Form.Item>
      </Col>
    </GrayBackground>
  )
}
