import styled from 'styled-components'
import { theme } from '../theme'

interface props {
  disabled: boolean
}

export const EmailWrapper = styled.div`
  margin-top: -4px;
  margin-bottom: -4px;

  div .ant-row .ant-form-item .email .ant-form-item-has-error {
    margin: 0 !important;
  }
  div {
    margin: 0;
  }
  .ant-form-item {
    margin: 0;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -11px;
  }

  label.ant-form-item-required {
    position: relative;
  }

  .ant-form-item-control > .ant-form-item-explain {
    height: 0;
    min-height: 0;
    display: none;
  }

  .ant-select-selection-item,
  .redesignActive .ant-select-multiple .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    padding-top: 0px !important;
  }
`

export const FooterLayout = styled.div<props>`
  display: flex;
  justify-content: space-between;
  padding: 10px 9px;

  .ant-btn-default {
    background-color: ${(props) => props.theme.colors.beightWhite};
    color: ${(props) => props.theme.colors.black};
    border-color: ${(props) => props.theme.colors.lightGray};
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: ${({ disabled }) => (disabled ? `${theme.colors.cultered} !important` : theme.colors.primary)};
    border-color: ${({ disabled }) => (disabled ? `${theme.colors.cultered} !important` : theme.colors.primary)};
  }

  .ant-btn-default:hover {
    background-color: ${(props) => props.theme.colors.beightWhite};
    border-color: ${(props) => props.theme.colors.lightGray};
  }

  .ant-btn-primary:hover {
    background-color: ${(props) => props.theme.colors.buttonHovering};
    border-color: ${(props) => props.theme.colors.buttonHovering};
  }

  .ant-btn-primary > span > span {
    opacity: ${({ disabled }) => (disabled ? '0.15' : '1')};
  }

  .ant-btn-primary > svg > path {
    fill: ${({ disabled }) => (disabled ? '#cdcdcd' : theme.colors.black)};
  }
`

export const Heading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
`

export const HeadingDescription = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.darkGray};
  font-weight: 400;
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
`

export const Body = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2px;

  .tenant-select {
    width: 240px;
    margin-right: 15px;
    margin-bottom: 16px;
  }

  .role-select {
    width: 120px;
    margin-right: 15px;
    margin-bottom: 16px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
  }

  .switch .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
    margin-top: 7px;
  }

  .ant-modal-body {
    max-height: 200px;
  }

  .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    max-width: 190px;
    overflow: hidden;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > button {
    display: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${(props) => props.theme.colors.primary} !important;
    box-shadow: none !important;
  }
`

export const MobileBody = styled.div`
  .tenant-select {
    margin-bottom: 16px;
  }

  .role-select {
    margin-bottom: 16px;
  }

  .switch {
    margin-bottom: 16px !important;
  }

  .discount-code {
    padding-bottom: 16px;
    color: ${theme.colors.darkGray};
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
  }

  .switch {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 53%;
    margin-bottom: -20px;
  }

  .switch > .ant-form-item-control {
    display: flex;
    align-items: flex-end;
    padding-right: 24px;
  }

  .switch .ant-form-item-label {
    padding-bottom: 20px;
  }

  .switch .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
    margin-top: 7px;
  }

  .ant-modal-body {
    max-height: 200px;
  }

  .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    max-width: 190px;
    overflow: hidden;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > button {
    display: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${(props) => props.theme.colors.primary} !important;
    box-shadow: none !important;
  }
`

export const SvgSpacing = styled.span`
  svg {
    margin-right: 5px;
  }
`

export const SwitchLabel = styled.span`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 11px;
`

export const ButtonTextSpacing = styled.span`
  margin-right: 2px;
  font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 15.6px;
`

export const SelectStyling = styled.div<{ disabled: boolean }>`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
    min-height: 38px;
    padding-top: 2.5px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    padding-top: 5px;
    border-radius: 0 !important;
  }

  .ant-select-selection-overflow {
    margin-left: 0px;
  }

  .ant-btn {
    border: 0;

    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.primary)};
    &:hover {
      background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : theme.colors.buttonHovering)};
    }
  }
`

export const TenantInputBody = styled.div`
  max-height: 225px;
  overflow-y: scroll;
  margin-bottom: -24px;

  // show scrollbar
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
  }

  .tenant-select {
    margin-right: 16px;
  }
`

export const PermissionWrapper = styled.div`
  background: ${theme.colors.grayBackground};
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 8px;
`

export const TenantInputBodyAdminView = styled.div`
  max-height: 225px;
  overflow-y: scroll;
  margin-bottom: -24px;
  margin-right: -27px;

  // show scrollbar
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
  }

  .tenant-select {
    margin-right: 16px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
  }

  .ant-select-multiple span.ant-select-selection-item {
    background-color: rgba(48, 215, 186, 0.1) !important;
    padding-top: 1px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    border-radius: 0px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item > span {
    color: ${theme.colors.softBlack};
  }
`

export const TenantInputMobileBody = styled.div`
  margin-bottom: -24px;
`

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.dangerPrimary};
  margin-top: 5px !important;
`

export const UsersTagsWrapper = styled.div`
  margin-top: 8px !important;

  .ant-tag {
    background: ${(props) => props.theme.colors.cultered};
    font-size: 11px;
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    margin-top: 7px;
  }
`

export const DropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: solid 1px ${(props) => props.theme.colors.lightGray};
  min-width: 100%;

  label > .ant-checkbox-checked {
    padding-left: 10px;
    margin-top: -5px;
  }

  label > span {
    color: ${(props) => props.theme.colors.primary} !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    width: 100%;
  }

  label > span > button {
    background-color: transparent !important;
    border: none;
    padding: 10px 0px 10px 10px !important;
  }
`

export const UnselectedDropdownWrapper = styled.div`
  .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }
`

export const AddMoreWrapper = styled.div`
  .add-more-button {
    margin-bottom: -4px;
    margin-top: 32px;
  }

  .ant-form-item-control-input {
    min-height: fit-content;
  }
`

export const MobileLineContainer = styled.div`
  width: 62px;
  height: 3px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.lightGray};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
`

export const AdminViewBody = styled.div`
  display: flex;
  align-items: center;

  .discount-schema-select {
    width: 225px;
    margin-bottom: 16px;
  }

  .role-select {
    width: 120px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
  }

  .switch {
    margin-right: 16px;
  }

  .switch .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: 0.05em;
    margin-top: 7px;
  }

  .ant-modal-body {
    max-height: 200px;
  }

  .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-selection-item .ant-checkbox,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  .ant-checkbox + span {
    padding-left: 0px;
    max-width: 190px;
    overflow: hidden;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-item
    > div
    > label
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span {
    color: black !important;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > svg {
    display: none;
  }

  .ant-row
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-item
    > div
    > .ant-checkbox-wrapper
    > span
    > button {
    display: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: ${(props) => props.theme.colors.primary} !important;
    box-shadow: none !important;
  }
`
export const AdminViewInputBody = styled.div`
  padding-right: 16px;
  padding-bottom: 0px;

  .discount-code {
    margin-bottom: 21px;
    margin-top: -15px;
    margin-left: 237px;
    color: ${theme.colors.darkGray};
  }
`

export const LabelWrapper = styled.div`
  color: ${theme.colors.darkGray};
`
