import { UserSession } from '../models/user'
import { log } from '../logger'
import { LocalStorageLangKey } from '../models/language'

const SessionStorePrefix = 's'
const PersistStorePrefix = 'doNotShowAgain'

export const getSession = (): UserSession | undefined => {
  const item = localStorage.getItem(SessionStorePrefix)

  log('retrieving local store session', { session: item })
  if (!item) {
    return undefined
  }
  try {
    return JSON.parse(item) as UserSession
  } catch (error) {
    return undefined
  }
}

export const setSession = (sess: UserSession) => {
  localStorage.setItem(SessionStorePrefix, JSON.stringify(sess))
}

export const clearSession = () => {
  localStorage.removeItem(SessionStorePrefix)
}

export const clearPersist = () => {
  localStorage.removeItem(PersistStorePrefix)
}

export const clearLanguage = () => {
  localStorage.removeItem(LocalStorageLangKey)
}

export const getPersistSession = (): boolean => {
  const persist = localStorage.getItem(PersistStorePrefix)
  log('retrieving local store persist', { persist })

  if (!persist) {
    setPersistSession(false)
    return false
  }

  return JSON.parse(persist) as boolean
}

export const setPersistSession = (persist: boolean) => {
  localStorage.setItem(PersistStorePrefix, persist.toString())
}
