import { theme } from '../../theme'

export const ConnectorPluggedInIcon = () => (
  <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.41872 27.9913C3.59213 28.039 2.77051 27.8346 2.06275 27.4049C1.35499 26.9752 0.794468 26.3407 0.455441 25.5853C0.0863518 24.8888 -0.0666307 24.0981 0.0159663 23.3142C0.0985634 22.5303 0.413014 21.7889 0.919159 21.1846C1.58291 20.4618 2.29938 19.7892 3.06265 19.1724L4.37499 20.7296L2.98389 21.9108C2.58304 22.2006 2.28716 22.6128 2.14077 23.0853C1.99438 23.5579 2.00542 24.0651 2.17217 24.5309C2.33892 24.9966 2.65239 25.3956 3.06543 25.6678C3.47847 25.94 3.96872 26.0707 4.46245 26.0402L4.41872 27.9913Z"
      fill={theme.colors.primary}
    />
    <path
      d="M25.6426 9.51352C25.6426 10.7121 25.6426 11.9108 25.6426 13.1094C25.6426 14.1067 25.2051 14.4917 24.199 14.5441C23.1929 14.5966 22.1693 14.5442 21.7231 13.3368C21.6706 13.2056 21.3644 13.1094 21.1806 13.1006C20.3057 13.1006 19.4309 13.1006 18.556 13.1006C18.2411 13.1363 17.9402 13.2506 17.6811 13.4331C15.0564 15.1829 12.4317 16.9326 9.85955 18.6824C8.98466 19.2948 8.55599 19.2336 7.86482 18.4024C7.17366 17.5713 6.54374 16.8276 5.89632 16.0315C5.77171 15.9266 5.67342 15.7941 5.60935 15.6444C5.54529 15.4947 5.51723 15.332 5.52744 15.1695C5.53765 15.007 5.58585 14.8491 5.66815 14.7086C5.75044 14.5681 5.86455 14.4489 6.0013 14.3605C8.67847 11.7358 11.3557 9.11111 14.0678 6.53893C15.4647 5.20158 17.3309 4.46636 19.2646 4.49166C20.9094 4.49166 22.5455 4.49166 24.1903 4.49166C25.2401 4.49166 25.6426 4.90285 25.6514 5.96147L25.6426 9.51352Z"
      fill={theme.colors.primary}
    />
    <path
      d="M26.4922 13.0831V5.3316C27.5508 5.3316 28.5657 5.3316 29.5806 5.3316C29.8384 5.36029 30.0747 5.48918 30.2383 5.6905C30.402 5.89182 30.48 6.14941 30.4555 6.4077C30.4555 8.24497 30.4555 10.0822 30.4555 11.9195C30.4675 12.0627 30.4498 12.2068 30.4035 12.3428C30.3572 12.4788 30.2832 12.6037 30.1862 12.7097C30.0893 12.8157 29.9714 12.9005 29.84 12.9588C29.7087 13.017 29.5668 13.0474 29.4231 13.0481C28.487 13.1269 27.5071 13.0831 26.4922 13.0831Z"
      fill={theme.colors.primary}
    />
    <path
      d="M5.06576 16.189L7.69044 19.3911C7.08844 19.7477 6.46622 20.0691 5.82692 20.3535C5.58195 20.4497 5.22324 20.2572 4.95202 20.1872C4.95202 20.1872 4.8908 20.1172 4.8558 20.0822C3.07977 17.8338 2.90478 18.5599 5.06576 16.189Z"
      fill={theme.colors.primary}
    />
    <rect x="29" width="2" height="19" fill={theme.colors.primary} />
  </svg>
)
