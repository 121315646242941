import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useSubscriptionTranslation = () => {
  const billingAddressText = useFormatMessage('dashboard.text.billingAddress', 'Billing Address')
  const planText = useFormatMessage('dashboard.text.plan', 'Plan')
  const cancelSubText = renderFormatMessage('dashboard.text.cancelSubscription', 'Cancel Subscription')
  const pauseSubText = renderFormatMessage('dashboard.text.pauseSubscription', 'Pause Subscription')
  const unpauseSubText = renderFormatMessage('dashboard.text.unpauseSubscription', 'unPause Subscription')
  const closeText = useFormatMessage('dashboard.text.close', 'Close')
  const deleteMessageText = useFormatMessage(
    'dashboard.subscriptionsPage.modal.cancel',
    'Are you sure you want to cancel the subscription for',
  )
  const pauseMessageText = useFormatMessage(
    'dashboard.subscriptionsPage.modal.pause',
    'Are you sure you want to pause the subscription for ',
  )
  const unpauseMessageText = useFormatMessage(
    'dashboard.subscriptionsPage.modal.unpause',
    'Are you sure you want to unpause the subscription for ',
  )

  return {
    billingAddressText,
    cancelSubText,
    pauseSubText,
    planText,
    unpauseSubText,
    closeText,
    deleteMessageText,
    pauseMessageText,
    unpauseMessageText,
  }
}
