import { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useMediaQuery } from 'react-responsive'

import { ChargerStationInfo, ConnectorInfo } from '../../../../models/chargerv2'
import { styled, theme } from '../../../../theme'
import { MinMaxWithTitle } from './min-max-with-title'

highchartsMore(Highcharts)

const options = {
  chart: {
    type: 'gauge',
    styledMode: true,
    className: 'stations-gauge',
    // width: 190,
    height: 312,
    animation: false,
    events: {
      render: function () {
        var chart: any = this,
          yAxis = chart.yAxis[0],
          key,
          tick,
          minorTick
        for (key in yAxis.ticks) {
          tick = yAxis.ticks[key]

          if (tick.pos < 120) {
            tick.mark.attr({ stroke: 'red' })
          } else if (tick.pos >= 120 && tick.pos < 160) {
            tick.mark.attr({ stroke: 'green' })
          } else if (tick.pos >= 160) {
            tick.mark.attr({ stroke: 'yellow' })
          }
        }

        for (key in yAxis.minorTicks) {
          minorTick = yAxis.minorTicks[key]
          if (minorTick.pos <= chart.series[0].data[0].y) {
            minorTick.mark.attr({ stroke: theme.colors.primary })
          } else {
            minorTick.mark.attr({ stroke: '#999' })
          }
        }
      },
      events: {
        load() {
          //@ts-ignore
          setTimeout(this.reflow.bind(this), 0)
        },
      },
    },
  },

  title: {
    text: '',
  },

  pane: {
    startAngle: -110,
    endAngle: 110,
    size: '50%',
    center: ['50%', '50%'],
  },

  // the value axis
  yAxis: {
    min: 0,
    max: 200,

    minorTickInterval: 'auto',
    minorTickLength: 5,
    minorTickPosition: 'inside',

    tickPixelInterval: 30,
    tickPosition: 'outside',
    tickLength: 1,
    labels: {
      enabled: false,
    },
  },

  tooltip: {
    enabled: false,
  },

  series: [
    {
      name: 'Charging Stations',
      data: [40],
      dial: {
        path: [
          'M 55.0574 1.344 C 55.6109 2.91 57.1044 4.032 58.86 4.032 C 61.0868 4.032 62.892 2.2268 62.892 0 C 62.892 -2.2268 61.0868 -4.032 58.86 -4.032 C 57.1044 -4.032 55.6109 -2.91 55.0574 -1.344 L 45.42 -1.344 V 1.344 H 55.0574 Z',
        ],
      },
    },
  ],

  plotOptions: {
    gauge: {
      dial: {
        radius: '100%',
        // backgroundColor: 'silver',
        borderColor: 'black',
        borderWidth: 1,
        // baseWidth: 10,
        topWidth: 1,
      },
    },
  },
}

const HomePageGaugeChartContainer = styled.div`
  .highcharts-yaxis-grid .highcharts-grid-line,
  .highcharts-yaxis-grid .highcharts-minor-grid-line {
    display: none;
  }

  .highcharts-yaxis .highcharts-minor-tick {
    stroke-width: 2px;
    /* stroke: #999; */
  }

  position: relative;

  .highcharts-plot-band,
  .highcharts-pane {
    fill: transparent !important;
  }

  .stations-gauge {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stations-gauge::after {
    content: '';
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .stations-gauge.laptop::after {
    width: 100px;
    height: 100px;
  }

  .highcharts-data-labels.highcharts-series-0.highcharts-gauge-series.highcharts-color-0.highcharts-tracker {
    display: none;
  }

  .highcharts-label-box.highcharts-data-label-box {
    stroke-width: 0;
  }

  .highcharts-axis.highcharts-yaxis.highcharts-radial-axis {
    stroke: transparent;
  }

  .stations-gauge.largeLaptop::after {
    width: 90px;
    height: 90px;
  }
`

interface props {
  connectorInfo: ConnectorInfo
  chargerStationInfo: ChargerStationInfo
  title: string | number
  label: string
  min: string | number
  max: string | number
  height: number
}

export const HomePageGaugeChart: React.FC<props> = ({
  connectorInfo,
  chargerStationInfo,
  title,
  label,
  min,
  max,
  height,
}) => {
  const customMediaQuery = useMediaQuery({ minWidth: 1600, maxWidth: 2000 })

  if (customMediaQuery) {
    document.querySelector('.minmax-container')?.classList.add('custom-media-active')
  } else {
    document.querySelector('.minmax-container')?.classList.remove('custom-media-active')
  }

  const [guageOptions, setGauageOptions] = useState(options)

  useEffect(() => {
    const xxsQuery = theme.breakpoints.down('xxs').replace('@media', '')
    const xxxsQuery = theme.breakpoints.down('xxxs').replace('@media', '')
    setGauageOptions({
      ...guageOptions,

      pane: {
        ...guageOptions.pane,
        size: window.matchMedia(xxxsQuery).matches
          ? '85%'
          : window.matchMedia(xxsQuery).matches
          ? '70%'
          : guageOptions.pane.size,
      },
      // the value axis
      yAxis: {
        ...guageOptions.yAxis,
        max: chargerStationInfo.online + chargerStationInfo.offline + chargerStationInfo.repair,
      },

      //odometer
      series: [
        {
          ...guageOptions.series[0],
          data: [connectorInfo.charging],
        },
      ],
    })
  }, [connectorInfo])

  return (
    <HomePageGaugeChartContainer className="gauge-chart">
      <HighchartsReact highcharts={Highcharts} options={guageOptions} />
      <MinMaxWithTitle title={title} label={label} min={min} max={max} height={height} />
    </HomePageGaugeChartContainer>
  )
}
