import { Checkbox, Col, Row, Select, Space } from 'antd'
import {
  TransactionStartMethod,
  TransactionStateV2,
  transactionStartMethodsArr,
  transactionStateV2Arr,
} from '../../../../models/transaction'
import { TenantRef } from '../../../../models/tenant'
import { TransactionFilter } from '../../../../models/filter'
import { theme } from '../../../../theme'
import { AdvancedSearchWrapper } from '../../../../atom/collapse'
import { useAppState } from '../../../../state'
import UsernameSelector from './UsernameSelector'
import ResetButton from './ResetButton'
import SearchButton from './SearchButton'
import { ButtonIcon } from '../../../../atom/button'
import { magnifyBlackSVG } from '../../../../assets/svg/magnify'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { ChargerRef } from 'models/charger'
import { useSelector } from 'hooks/useSelector'
import { ReactElement } from 'react'
import { useTransactionSearchTranslation } from 'hooks/translation/useTransactionSearchTranslation'
import { DropdownWrapper } from 'atom/user-edit'
import { renderFormatMessage } from 'helpers/intl'

export interface AdvancedSearchProps {
  loading: boolean
  filter: TransactionFilter
  tenants: TenantRef[]
  defaultTenant?: TenantRef
  showAdvancedSearch: boolean
  disabled: boolean
  reset: boolean
  onState: (state: TransactionStateV2) => void
  onUsername: (user: string) => void
  onChargerId: (chargerId: string[]) => void
  onStartMethod: (value: TransactionStartMethod) => void
  onFetchTransactions: () => void
  onResetAdvancedSearch: () => void
  onFetchDataWithQuery: () => void
}

const advancedSearchStyle = {
  border: `1px solid ${theme.colors.lightGray}`,
  borderRadius: '4px',
  padding: '20px',
  backgroundColor: theme.colors.white,
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  loading,
  tenants,
  defaultTenant,
  filter,
  showAdvancedSearch,
  disabled,
  reset,
  onStartMethod,
  onChargerId,
  onFetchTransactions,
  onResetAdvancedSearch,
  onState,
  onUsername,
  onFetchDataWithQuery,
}) => {
  const { IsDesktop, IsLaptop } = useAppState()
  const { serialNumbersText } = useChargerTranslation()
  const { stateText, startMethodText } = useTransactionSearchTranslation()
  const {
    multiSerialNumberSelectorDebounceFetcher,
    multiSerialNumberHandleChange,
    startMethodHandleValue,
    stateHandleValue,
    multiSerialNumberHandleValue,
    multiSerialNumberHandlePlaceholder,
    startMethodHandleChange,
    stateHandleChange,
  } = useSelector()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const renderChargerSerialNumberOption = (
    charger: ChargerRef,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option
      key={charger.chargePointSerialNumber}
      value={charger.chargePointSerialNumber}
      label={charger.chargePointSerialNumber}
    >
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.id === charger.id) > -1}
      >
        {charger.chargePointSerialNumber}
      </Checkbox>
    </Select.Option>
  )

  const renderStateOption = (
    option: any,
    selectedOptions: any,
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    filter?: TransactionFilter,
  ): ReactElement => (
    <Select.Option key={`${option.state}-${option.index}`} value={option.state} label={option.state}>
      <DropdownWrapper selected={filter?.state === option.state}>
        {renderFormatMessage(`dashboard.transactionsPage.advancedSearch.state.${option.state}`, option.state)}
      </DropdownWrapper>
    </Select.Option>
  )

  const renderStartMethodOption = (
    option: any,
    selectedOptions: any,
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    filter?: TransactionFilter,
  ): ReactElement => (
    <Select.Option key={`${option.state}-${option.index}`} value={option.state} label={option.state}>
      <DropdownWrapper selected={filter?.startMethod === option.state}>
        {renderFormatMessage(`dashboard.transactionsPage.advancedSearch.method.${option.state}`, option.state)}
      </DropdownWrapper>
    </Select.Option>
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <AdvancedSearchWrapper showAdvancedSearch={showAdvancedSearch}>
          <Row style={advancedSearchStyle}>
            <Space wrap size={[9, 9]}>
              <ReusableSelector
                showSearch={false}
                filter={filter}
                onClear={() => {}}
                customWidth="290px"
                isSingle={true}
                placeholder={startMethodText}
                dropDownList={transactionStartMethodsArr}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={false}
                noSearch={true}
                debounceFetcher={undefined}
                customHeight="37px"
                reset={reset}
                onOptionsChange={onStartMethod}
                handleOptionChange={startMethodHandleChange}
                renderOption={renderStartMethodOption}
                handleValue={startMethodHandleValue}
              />
              <ReusableSelector
                showSearch={false}
                filter={filter}
                onClear={() => {}}
                isSingle={true}
                placeholder={stateText}
                dropDownList={transactionStateV2Arr}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={false}
                noSearch={true}
                debounceFetcher={undefined}
                customHeight="37px"
                customWidth="290px"
                reset={reset}
                onOptionsChange={onState}
                handleOptionChange={stateHandleChange}
                renderOption={renderStateOption}
                handleValue={stateHandleValue}
              />
              <ReusableSelector
                showSearch
                onOptionsChange={(chargerId: ChargerRef[]) =>
                  onChargerId(chargerId.map((charger) => charger.chargePointSerialNumber))
                }
                dropdownClassName="not-in-modal-selector"
                filter={filter}
                onClear={() => {}}
                customWidth="290px"
                isSingle={false}
                placeholder={serialNumbersText}
                dropDownList={tenants}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={true}
                debounceFetcher={multiSerialNumberSelectorDebounceFetcher}
                customHeight="37px"
                reset={reset}
                showArrow={false}
                handleOptionChange={multiSerialNumberHandleChange}
                renderOption={renderChargerSerialNumberOption}
                handleValue={multiSerialNumberHandleValue}
                handlePlaceholder={multiSerialNumberHandlePlaceholder}
              />
              <UsernameSelector onUsername={onUsername} filter={filter} onFetchTransactions={onFetchDataWithQuery} />
              <ButtonIcon
                icon={magnifyBlackSVG}
                fetchData={onFetchDataWithQuery}
                loading={loading}
                disabled={disabled}
              />
              <div className="reset-button">
                <ResetButton onResetAdvancedSearch={onResetAdvancedSearch} />
              </div>
            </Space>
          </Row>
        </AdvancedSearchWrapper>
      ) : (
        <AdvancedSearchWrapper showAdvancedSearch={showAdvancedSearch}>
          <Row gutter={[8, 8]} style={advancedSearchStyle}>
            <Col xs={24} md={12}>
              <ReusableSelector
                showSearch={false}
                filter={filter}
                onClear={() => {}}
                customWidth="341px"
                isSingle={true}
                placeholder={startMethodText}
                dropDownList={transactionStartMethodsArr}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={false}
                noSearch={true}
                debounceFetcher={undefined}
                customHeight="37px"
                reset={reset}
                onOptionsChange={onStartMethod}
                handleOptionChange={startMethodHandleChange}
                renderOption={renderStartMethodOption}
                handleValue={startMethodHandleValue}
              />
            </Col>
            <Col xs={24} md={12}>
              <ReusableSelector
                showSearch={false}
                filter={filter}
                onClear={() => {}}
                customWidth="341px"
                isSingle={true}
                placeholder={stateText}
                dropDownList={transactionStateV2Arr}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={false}
                noSearch={true}
                debounceFetcher={undefined}
                customHeight="37px"
                reset={reset}
                onOptionsChange={onState}
                handleOptionChange={stateHandleChange}
                renderOption={renderStateOption}
                handleValue={stateHandleValue}
              />
            </Col>
            <Col xs={24} md={12}>
              <ReusableSelector
                showSearch
                onOptionsChange={(chargerId: ChargerRef[]) =>
                  onChargerId(chargerId.map((charger) => charger.chargePointSerialNumber))
                }
                dropdownClassName="not-in-modal-selector"
                filter={filter}
                onClear={() => {}}
                customWidth="341px"
                isSingle={false}
                placeholder={serialNumbersText}
                dropDownList={tenants}
                showMagnifySVG={true}
                maxTagCount={0}
                isDebounceFetcher={true}
                debounceFetcher={multiSerialNumberSelectorDebounceFetcher}
                customHeight="37px"
                reset={reset}
                handleOptionChange={multiSerialNumberHandleChange}
                renderOption={renderChargerSerialNumberOption}
                handleValue={multiSerialNumberHandleValue}
                handlePlaceholder={multiSerialNumberHandlePlaceholder}
              />
            </Col>
            <Col xs={24} md={12}>
              <UsernameSelector onUsername={onUsername} filter={filter} onFetchTransactions={onFetchDataWithQuery} />
            </Col>
            <Col span={12}>
              <SearchButton onFetchTransactions={onFetchDataWithQuery} loading={loading} disabled={disabled} />
            </Col>
            <Col span={12} className="reset-button">
              <ResetButton onResetAdvancedSearch={onResetAdvancedSearch} />
            </Col>
          </Row>
        </AdvancedSearchWrapper>
      )}
    </>
  )
}

export default AdvancedSearch
