import { useEffect, useState } from 'react'
import { SwtchError } from '../models/error'
import {
  CreatePricings,
  GetDiscountsFromPricings,
  GetPricingNames,
  GetPricings,
  UpdatePricings,
} from '../services/data-provider/pricing'
import { PricingSchema, PricingSchemaEventSchedule, PricingSchemaPayload } from '../models/price'
import { PaginationMeta } from '../models/pagination'
import { Tenant } from '../models/tenant'
import { FormInstance } from 'antd'
import { NewListing } from 'models/listing'
import { UpdatePricingSchema } from 'services/data-provider/listing'
import { useNotifications } from './useNotification'
import { ApplyDiscountsToListings } from 'services/data-provider/discount'
import { getMode } from 'helpers/calculation'

export const usePricingSchemas = (
  tenant?: Tenant,
  onOk?: () => void,
  onCancel?: () => void,
  selectedPricingSchema?: PricingSchema,
) => {
  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [pricingSchemaData, setPricingSchemaData] = useState<PricingSchema[]>()
  const [error, setError] = useState<SwtchError>()
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [names, setNames] = useState<string[]>()
  const [nameExists, setNameExists] = useState<boolean>()
  const [pricePerUnit, setPricePerUnit] = useState<number>(
    selectedPricingSchema
      ? Number(
          getMode([...selectedPricingSchema.timeOfDayPriceWeekday, ...selectedPricingSchema.timeOfDayPriceWeekend]),
        ) / 100
      : 1,
  )
  const [pricePerUnitLoitering, setPricePerUnitLoitering] = useState<number>(
    selectedPricingSchema
      ? Number(
          getMode([
            ...(selectedPricingSchema.timeOfDayLoiteringWeekday || []),
            ...(selectedPricingSchema.timeOfDayLoiteringWeekend || []),
          ]),
        ) / 100
      : 0,
  )
  const [timeOfDayPriceWeekend, setTimeOfDayPricingWeekend] = useState<number[]>([])
  const [timeOfDayPriceWeekday, setTimeOfDayPricingWeekday] = useState<number[]>([])
  const [timeOfDayPriceWeekdayLoitering, setTimeOfDayPricingWeekDayLoitering] = useState<number[]>([])
  const [timeOfDayPriceWeekendLoitering, setTimeOfDayPricingWeekendLoitering] = useState<number[]>([])
  const [showEditPricingSchemaModal, setShowEditPricingSchemaModal] = useState(false)
  const [showCreatePricingSchemaModal, setShowCreatePricingSchemaModal] = useState(false)
  const [enableCurtailmentAdjustment, setEnableCurtailmentAdjustment] = useState(
    selectedPricingSchema ? selectedPricingSchema.enablePricingCurtailment : true,
  )
  const [loiteringType, setLoiteringType] = useState(
    selectedPricingSchema ? selectedPricingSchema.loiteringType : 'none',
  )
  const [timeOfDay, setTimeOfDay] = useState<boolean>(
    selectedPricingSchema
      ? !selectedPricingSchema.timeOfDayPriceWeekday.every(
          (pricing) => pricing === selectedPricingSchema.timeOfDayPriceWeekday[0],
        ) ||
          !selectedPricingSchema.timeOfDayPriceWeekend.every(
            (pricing) => pricing === selectedPricingSchema.timeOfDayPriceWeekend[0],
          )
      : false,
  )
  const [timeOfDayLoitering, setTimeOfDayLoitering] = useState<boolean>(
    selectedPricingSchema
      ? !selectedPricingSchema.timeOfDayLoiteringWeekday?.every(
          (pricing) => pricing === selectedPricingSchema.timeOfDayLoiteringWeekday[0],
        ) ||
          !selectedPricingSchema.timeOfDayLoiteringWeekend?.every(
            (pricing) => pricing === selectedPricingSchema.timeOfDayLoiteringWeekend[0],
          )
      : false,
  )
  const [listings, setListings] = useState<NewListing[]>([])
  const [allListings, setAllListings] = useState<NewListing[]>([])
  const [current, setCurrent] = useState(0)
  const [pricingType, setPricingType] = useState(selectedPricingSchema ? selectedPricingSchema.priceType : 'Hour')
  const [nextInModal, setNextInModal] = useState(false)
  const defaultEventSchedule = [
    {
      dollar_rate: 1,
      to: 1,
      from: 0,
    },
  ]

  useEffect(() => {
    const pricingArr = Array.from({ length: 48 }, () => Number(pricePerUnitLoitering) * 100)
    setTimeOfDayPricingWeekDayLoitering(pricingArr)
    setTimeOfDayPricingWeekendLoitering(pricingArr)
  }, [pricePerUnitLoitering])

  useEffect(() => {
    const pricingArr = Array.from({ length: 48 }, () => Number(pricePerUnit) * 100)
    setTimeOfDayPricingWeekday(pricingArr)
    setTimeOfDayPricingWeekend(pricingArr)
  }, [pricePerUnit])

  const handleSelect = (listName: string, form: FormInstance) => {
    form.validateFields()
    form.getFieldValue(listName) &&
      form.getFieldValue(listName).map((_: any, index: any) => {
        return form.setFields([
          {
            name: [listName, index, 'to'],
            value: form.getFieldValue([listName, index, 'from'])
              ? form.getFieldValue([listName, index, 'from']) + 1
              : 1,
          },
        ])
      })
  }

  const onPaginationChange = (page: number, pageSize?: number) => setCurrentPage(page)

  const getPricings = (currentPage: number, tenantId: number) => {
    setLoading(true)
    GetPricings(currentPage, tenantId)
      .then((pricing) => {
        setPricingSchemaData(pricing.data)
        setPagination(pricing.pagination)
        const namesArr: string[] = []
        pricing.data.map((pricing) => {
          namesArr.push(pricing.name)
          return namesArr
        })
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const getPricingNames = (tenantId: number) => {
    setLoading(true)
    GetPricingNames(Number(tenantId))
      .then((resp) => {
        setLoading(false)
        setNames(resp)
      })
      .catch((err: SwtchError) => console.log(err))
      .finally(() => setLoading(false))
  }

  const nameCheck = (value: string, selectedPricingSchema?: PricingSchema) => {
    const namesArr = names?.map((v) => v.toLowerCase())
    if (selectedPricingSchema?.name) {
      if (namesArr?.includes(value.toLowerCase()) && value !== selectedPricingSchema.name) return setNameExists(true)
    } else {
      if (namesArr?.includes(value.toLowerCase())) return setNameExists(true)
    }
    return setNameExists(false)
  }

  const timeOfDayRates = (selectedPricingSchema: PricingSchema, type: string) => {
    const timeOfDayRateArr: PricingSchemaEventSchedule[] = []
    let dollarRate = 0
    let timeOfDayArr: number[] = []

    switch (type) {
      case 'timeOfDayWeekday':
        timeOfDayArr = selectedPricingSchema.timeOfDayPriceWeekday
        break
      case 'timeOfDayWeekend':
        timeOfDayArr = selectedPricingSchema.timeOfDayPriceWeekend
        break
      case 'timeOfDayWeekdayLoitering':
        timeOfDayArr = selectedPricingSchema.timeOfDayLoiteringWeekday || []
        break
      case 'timeOfDayWeekendLoitering':
        timeOfDayArr = selectedPricingSchema.timeOfDayLoiteringWeekend || []
        break
      default:
        break
    }

    dollarRate = Number(getMode(timeOfDayArr))

    timeOfDayArr.forEach((rate, index) => {
      if (rate !== dollarRate) {
        const eventScheduleObj: PricingSchemaEventSchedule = {
          dollar_rate: rate / 100,
          from: index / 2,
          to: index / 2 + 1,
        }
        if (index % 2 === 0) timeOfDayRateArr.push(eventScheduleObj)
      }
    })

    if (timeOfDayRateArr.length === 0) {
      timeOfDayRateArr.push({
        dollar_rate: 1,
        to: 1,
        from: 0,
      })
    }

    return timeOfDayRateArr
  }

  const eventSchedules: PricingSchemaEventSchedule[] = Array.from(Array(24), (_, index) => ({
    from: index,
    to: index + 1,
    dollar_rate: '1',
  }))

  const initialValues = {
    tenant_id: tenant?.id,
    name: selectedPricingSchema ? selectedPricingSchema.name : '',
    stripe_account_id: null,
    state: selectedPricingSchema ? selectedPricingSchema.state : '',
    price_type: selectedPricingSchema ? selectedPricingSchema.priceType : 'Hour',
    flat_fee_cents: selectedPricingSchema ? selectedPricingSchema.flatFeeCents : 0,
    flat_fee: selectedPricingSchema ? Number(selectedPricingSchema.flatFeeCents) / 100 : 0,
    demand_response_fee_cents: selectedPricingSchema ? selectedPricingSchema.demandResponseFeeCents : null,
    payment_limit_cents: selectedPricingSchema ? selectedPricingSchema.paymentLimitCents : null,
    enable_pricing_curtailment: selectedPricingSchema ? selectedPricingSchema.enablePricingCurtailment : true,
    loitering_type: selectedPricingSchema ? selectedPricingSchema.loiteringType : 'none',
    loitering_time_limit_minutes: selectedPricingSchema ? selectedPricingSchema.loiteringTimeLimitMinutes : 0,
    loitering_grace_period_minutes: selectedPricingSchema ? selectedPricingSchema.loiteringGracePeriodMinutes : 0,
    loitering_maximum: selectedPricingSchema ? Number(selectedPricingSchema.loiteringMaximumCents) / 100 : 100,
    dollar_rate: selectedPricingSchema
      ? Number(
          getMode([...selectedPricingSchema.timeOfDayPriceWeekday, ...selectedPricingSchema.timeOfDayPriceWeekend]),
        ) / 100
      : 1,
    loitering_dollar_rate: selectedPricingSchema
      ? Number(
          getMode([
            ...(selectedPricingSchema.timeOfDayLoiteringWeekday || []),
            ...(selectedPricingSchema.timeOfDayLoiteringWeekend || []),
          ]),
        ) / 100
      : 0,
    weekdayRateExtras: selectedPricingSchema
      ? timeOfDayRates(selectedPricingSchema, 'timeOfDayWeekday')
      : defaultEventSchedule,
    weekendRateExtras: selectedPricingSchema
      ? timeOfDayRates(selectedPricingSchema, 'timeOfDayWeekend')
      : defaultEventSchedule,
    weekdayLoiteringRateExtras: selectedPricingSchema
      ? timeOfDayRates(selectedPricingSchema, 'timeOfDayWeekdayLoitering')
      : defaultEventSchedule,
    weekendLoiteringRateExtras: selectedPricingSchema
      ? timeOfDayRates(selectedPricingSchema, 'timeOfDayWeekendLoitering')
      : defaultEventSchedule,
    payment_limit: selectedPricingSchema ? Number(selectedPricingSchema.paymentLimitCents) / 100 : 100,
  }

  const handlePricingSchemaCreateOrUpdate = () => {
    setShowCreatePricingSchemaModal(false)
    setShowEditPricingSchemaModal(false)
    return getPricings(currentPage, Number(tenant?.id))
  }

  const applyDiscountsToListings = (tenantId: number, listings: NewListing[], pricingId: number) => {
    GetDiscountsFromPricings(tenantId, pricingId)
      .then((resp) => {
        resp.data.map((individualDiscount) => {
          ApplyDiscountsToListings(
            {
              ids: listings.map((f) => f.id),
            },
            individualDiscount.id,
          )
            .then(() => {})
            .catch((err: SwtchError) => console.log('error', err))
          return individualDiscount
        })
      })
      .catch((err: SwtchError) => console.log('error', err))
  }

  const onFinish = (values: PricingSchemaPayload, modal: string, listings: NewListing[], nextPageInModal?: boolean) => {
    setLoading(true)
    const timeOfDayPriceWeekdayArr = [...timeOfDayPriceWeekday]
    if (timeOfDay && timeOfDayPriceWeekdayArr) {
      values['weekdayRateExtras'] &&
        values['weekdayRateExtras'].map((_: any) => {
          let index = 0
          if (_.from !== 0) {
            index = _.from * 2
          }
          timeOfDayPriceWeekdayArr[index] = Number(_.dollar_rate) * 100
          timeOfDayPriceWeekdayArr[index + 1] = Number(_.dollar_rate) * 100
          return timeOfDayPriceWeekdayArr
        })
    }

    let timeOfDayPriceWeekendArr = [...timeOfDayPriceWeekend]
    if (timeOfDay && timeOfDayPriceWeekendArr && timeOfDayPriceWeekendArr.length > 0) {
      values['weekendRateExtras'] &&
        values['weekendRateExtras'].map((_: any) => {
          let index = 0
          if (_.from !== 0) {
            index = _.from * 2
          }
          timeOfDayPriceWeekendArr[index] = Number(_.dollar_rate) * 100
          timeOfDayPriceWeekendArr[index + 1] = Number(_.dollar_rate) * 100
          return timeOfDayPriceWeekendArr
        })
    }

    const timeOfDayPriceWeekdayLoiteringArr = [...timeOfDayPriceWeekdayLoitering]
    if (timeOfDayLoitering && timeOfDayPriceWeekdayLoiteringArr) {
      values['weekdayLoiteringRateExtras'] &&
        values['weekdayLoiteringRateExtras'].map((_: any) => {
          let index = 0
          if (_.from !== 0) {
            index = _.from * 2
          }
          timeOfDayPriceWeekdayLoiteringArr[index] = Number(_.dollar_rate) * 100
          timeOfDayPriceWeekdayLoiteringArr[index + 1] = Number(_.dollar_rate) * 100
          return timeOfDayPriceWeekdayLoiteringArr
        })
    }

    let timeOfDayPriceWeekendLoiteringArr = [...timeOfDayPriceWeekendLoitering]
    if (timeOfDayLoitering && timeOfDayPriceWeekendLoiteringArr && timeOfDayPriceWeekendLoiteringArr.length > 0) {
      values['weekendLoiteringRateExtras'] &&
        values['weekendLoiteringRateExtras'].map((_: any) => {
          let index = 0
          if (_.from !== 0) {
            index = _.from * 2
          }
          timeOfDayPriceWeekendLoiteringArr[index] = Number(_.dollar_rate) * 100
          timeOfDayPriceWeekendLoiteringArr[index + 1] = Number(_.dollar_rate) * 100
          return timeOfDayPriceWeekendLoiteringArr
        })
    }

    const data: PricingSchemaPayload = {
      tenant_id: Number(tenant?.id),
      name: values['name'],
      price_type: values['price_type'],
      flat_fee_cents: Number(values['flat_fee']) * 100,
      loitering_type: values['loitering_type'],
      time_of_day_price_weekday: timeOfDayPriceWeekdayArr,
      time_of_day_price_weekend: timeOfDayPriceWeekendArr,
      time_of_day_loitering_weekday: timeOfDayPriceWeekdayLoiteringArr,
      time_of_day_loitering_weekend: timeOfDayPriceWeekendLoiteringArr,
      enable_pricing_curtailment: values['enable_pricing_curtailment'],
      payment_limit_cents: Number(values['payment_limit']) * 100,
    }

    if (values['loitering_type'] === 'time_based') {
      data['loitering_time_limit_minutes'] = values['loitering_time_limit_minutes']
      data['loitering_maximum_cents'] = Number(values['loitering_maximum']) * 100
    }

    if (values['loitering_type'] === 'status_based') {
      data['loitering_grace_period_minutes'] = values['loitering_grace_period_minutes']
    }

    if (modal === 'editPricingSchema' && selectedPricingSchema) {
      UpdatePricings(data, Number(tenant?.id), selectedPricingSchema.id)
        .then((pricingResp) => {
          if (listings.length > 0) {
            UpdatePricingSchema({
              ids: listings.map((f) => f.id),
              pricing_schema_id: pricingResp.id,
            })
              .then((resp) => {
                if (resp && resp.success === true) {
                  applyDiscountsToListings(Number(tenant?.id), listings, pricingResp.id)
                  openSuccessNotification(resp.msg)
                } else {
                  openErrorNotification(resp.msg)
                }
                if (onOk && onCancel) {
                  onOk()
                  onCancel()
                }
              })
              .catch((err) => setError(err))
              .finally(() => setLoading(false))
          } else {
            if (onOk && onCancel) {
              onOk()
              onCancel()
            }
          }
        })
        .catch((err: SwtchError) => console.log(err))
        .finally(() => setLoading(false))
    } else {
      CreatePricings(data, Number(tenant?.id))
        .then((pricingResp: PricingSchema) => {
          if (listings.length > 0) {
            UpdatePricingSchema({
              ids: listings.map((f) => f.id),
              pricing_schema_id: pricingResp.id,
            })
              .then((resp) => {
                if (resp && resp.success === true) {
                  applyDiscountsToListings(Number(tenant?.id), listings, pricingResp.id)
                  openSuccessNotification(resp.msg)
                } else {
                  openErrorNotification(resp.msg)
                }
                if (onOk && nextPageInModal) onOk()
                if (onOk && onCancel && !nextPageInModal) {
                  onOk()
                  onCancel()
                }
              })
              .catch((err) => {
                setError(err)
                openErrorNotification(err.msg)
              })
              .finally(() => setLoading(false))
          } else {
            if (onOk && nextPageInModal) onOk()
            if (onOk && onCancel && !nextPageInModal) {
              onOk()
              onCancel()
            }
          }
        })
        .catch((err: SwtchError) => console.log(err))
        .finally(() => setLoading(false))
    }
  }

  const next = () => {
    setCurrent(current + 1)
  }

  return {
    loading,
    error,
    currentPage,
    pricingSchemaData,
    pagination,
    initialValues,
    names,
    nameExists,
    eventSchedules,
    timeOfDayPriceWeekday,
    timeOfDayPriceWeekend,
    timeOfDayPriceWeekdayLoitering,
    timeOfDayPriceWeekendLoitering,
    timeOfDay,
    timeOfDayLoitering,
    showEditPricingSchemaModal,
    showCreatePricingSchemaModal,
    enableCurtailmentAdjustment,
    loiteringType,
    listings,
    allListings,
    current,
    pricingType,
    nextInModal,
    setLoiteringType,
    setEnableCurtailmentAdjustment,
    onPaginationChange,
    getPricings,
    getMode,
    getPricingNames,
    nameCheck,
    setPricePerUnitLoitering,
    setPricePerUnit,
    handleSelect,
    onFinish,
    setTimeOfDay,
    setTimeOfDayLoitering,
    handlePricingSchemaCreateOrUpdate,
    setShowEditPricingSchemaModal,
    setShowCreatePricingSchemaModal,
    setTimeOfDayPricingWeekday,
    setTimeOfDayPricingWeekend,
    setTimeOfDayPricingWeekDayLoitering,
    setTimeOfDayPricingWeekendLoitering,
    setListings,
    setError,
    setLoading,
    setCurrent,
    next,
    setAllListings,
    setPricingType,
    setNextInModal,
    applyDiscountsToListings,
  }
}
