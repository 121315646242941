import { Badge } from 'antd'

import { useFormatMessage } from '../../helpers/intl'
import { Subscription } from '../../models/subscription'
import { theme } from '../../theme'

interface props {
  subscription: Subscription
}

export const SubscriptionStatusBadge: React.FC<props> = ({ subscription }) => {
  const [status, backgroundColor, fontColor] = GetSubscriptionState(subscription.status)
  const translatedStatus = useFormatMessage(renderConnectionMessage(subscription.status), status)

  return (
    <Badge
      key={subscription.id}
      count={translatedStatus}
      style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
    ></Badge>
  )
}

export const renderConnectionMessage = (subscriptionStatus: string): string => {
  let status = ''
  switch (subscriptionStatus) {
    case 'active':
      status = 'dashboard.text.active'
      break
    case 'pending':
      status = 'dashboard.text.pending'
      break
    case 'inactive':
      status = 'dashboard.text.inactive'
      break
    case 'canceled':
    case 'cancelled':
      status = 'dashboard.text.cancelled'
      break
    case 'paused':
      status = 'dashboard.text.paused'
      break
  }
  return status
}

export const GetSubscriptionState = (status: string): string[] => {
  switch (status) {
    case 'active':
      return [status, theme.colors.active, theme.colors.neonGreen]
    case 'inactive':
    case 'canceled':
    case 'cancelled':
      return [status, theme.colors.grey12, theme.colors.black]
    case 'pending':
      return [status, theme.colors.orangePeel15, theme.colors.orangePeel]
    case 'unavailable':
      return [status, theme.colors.goldenHoneycomb, theme.colors.goldenOlive]
    case 'paused':
      return [status, theme.colors.underRepair, theme.colors.dangerPrimary]
    default:
      return [status, 'gray', 'gray']
  }
}
