import { Alert } from 'antd'
import React from 'react'
import { AlertBoxprops } from '../models/error'

export const AlertErrorCompact: React.FC<AlertBoxprops> = ({ error, closable }) => {
  if (!error) {
    return <></>
  }

  return (
    <Alert
      closable={closable || false}
      showIcon
      message={null}
      description={String(error.cause).replace('Error:', '')}
      type="error"
    />
  )
}
