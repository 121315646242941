import { SubscriberResponse, SubscriptionResponse } from '../models/http'
import { Subscription } from '../models/subscription'
import { Subscriber } from '../models/user'
import { mockPlanById } from './plan-mock'

export const mockSubscriptionsByEmail: { [email: string]: Subscription[] } = {
  'weihang.tang@swtchev.com': [
    {
      id: 1,
      personId: 1,
      planId: 1,
      // startDate: '2021-05-01T23:59:59.000',
      // endDate: '2021-06-01T00:00:00.000',
      status: 'pending',
      createdAt: '2021-05-01T23:59:59.000',
      updatedAt: '2021-06-01T00:00:00.000',
      quantity: 1,
      subscriber: { id: 'kSOpOau2d3Fnm2Rk7Mxc6A', email: 'weihang.tang@swtchev.com', name: 'Weihang Tang', note: '' },
      plan: mockPlanById[1],
    },
  ],
  'thomas.martin@swtchenergy.com': [
    {
      id: 2,
      personId: 2,
      planId: 2,
      startDate: '2021-05-01T23:59:59.000',
      endDate: '2021-06-01T00:00:00.000',
      status: 'cancelled',
      createdAt: '2021-05-01T23:59:59.000',
      updatedAt: '2021-06-01T00:00:00.000',
      billingAddress: {
        country: 'CA',
        state: 'ON',
        city: 'Toronto',
        address: '12 Laidlaw Street',
        zip: 'M6K1X2',
      },
      quantity: 2,
      subscriber: { id: 'yinh_nzFeJ4OK4MzU-ja-A', email: 'thomas.martin@swtchenergy.com', name: 'Thomas Martin-Admin', note: '' },
      plan: mockPlanById[2],
    },
  ],
  'laura.bryson@gmail.com': [
    {
      id: 3,
      personId: 3,
      planId: 3,
      startDate: '2021-05-01T23:59:59.000',
      endDate: '2021-06-01T00:00:00.000',
      status: 'active',
      createdAt: '2021-05-01T23:59:59.000',
      updatedAt: '2021-06-01T00:00:00.000',
      billingAddress: {
        country: 'CA',
        state: 'ON',
        city: 'Toronto',
        address: '7 Queen Elizabeth Blvd',
        zip: 'M6K1X2',
      },
      quantity: 1,
      subscriber: { id: 'jXD9CMKXeavenIGoSK6qDQ', email: 'laura.bryson@gmail.com', name: 'Laura Christine Bryson', note: '' },
      plan: mockPlanById[3],
    },
  ],
}

export const mockSubscriptions = [
  mockSubscriptionsByEmail['weihang.tang@swtchev.com'][0],
  mockSubscriptionsByEmail['thomas.martin@swtchenergy.com'][0],
  mockSubscriptionsByEmail['laura.bryson@gmail.com'][0],
] as Subscription[]

export const mockSubscribers = [
  {
    id: 'sjdflsfj',
    name: 'John',
    email: 'John@gmail.com',
    subscriptions: mockSubscriptions,
  },
  {
    id: 'sjdflsfj',
    name: 'John2',
    email: 'John2@gmail.com',
    subscriptions: mockSubscriptions,
  },
] as Subscriber[]

export const mockSubscribersResp = (page: number) => {
  return {
    data: mockSubscribers,
    pagination: {
      currentPage: page || 0,
      perPage: 10,
      totalEntries: mockSubscribers.length,
    },
  } as SubscriberResponse
}

export const mockSubcriptionsResp = (page: number, email?: string) => {

  if (email) {
    return {
      data: mockSubscriptionsByEmail[email],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalEntries: mockSubscriptionsByEmail[email].length,
      },
    }
  }
  return {
    data: mockSubscriptions,
    pagination: {
      currentPage: page || 0,
      perPage: 10,
      totalEntries: mockSubscriptions.length,
    },
  } as SubscriptionResponse
}
