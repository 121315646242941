import { CheckCircleTwoTone, CloseOutlined, EditFilled, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Dropdown, Form, Input, Menu, Row, Select, Space, Switch, Tag, Tooltip } from 'antd'
import { List } from 'antd-mobile'
import MobileListView, { MobileListViewI } from '../../atom/mobile-list-view'
import { getFullNameFromEmail } from '../../helpers/users'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import { AugmentedUser, DashboardAccessType, User, UserRole, UserUpdate } from '../../models/user'
import { useAppState } from '../../state'
import { styled, theme } from '../../theme'

const { Item } = List
const { Option } = Select

const UserListView = MobileListView as MobileListViewI<User>

interface props {
  users: AugmentedUser[]
  onToggleRole: (userId: string, role: UserRole) => void
  hasTenantScope: boolean
  tenantScope: TenantRef | undefined
  selectedUser: AugmentedUser | undefined
  onSelectedUser: (selectedUser: AugmentedUser | undefined) => void
  userUpdate: UserUpdate | undefined
  onClickSaveBtn: (firstName: string, lastName: string) => void
  onClickCross: () => void
  onClickPlus: () => void
  onUserSelect: (value: DashboardAccessType) => void
  onDriverSelect: (checked: boolean) => void
  onClickModifyBtn: (user: AugmentedUser) => void
  onPaginationChange: (page: number, pageSize?: number) => void
  pagination: PaginationMeta | undefined
  loading: boolean
  isMobile: boolean
  renderDriverCheckbox: (user: AugmentedUser) => boolean
  renderAccessTag: (user: AugmentedUser) => any
  renderDriverInfoText: () => void
  renderAccessInfoText: () => void
}

const CustomFlexBasis = styled.div`
  .am-list-item .am-list-line .am-list-extra {
    flex-basis: 70%;
  }
`

export const UsersListView: React.FC<props> = ({
  users,
  onToggleRole,
  hasTenantScope,
  tenantScope,
  selectedUser,
  onSelectedUser,
  userUpdate,
  onClickSaveBtn,
  onClickCross,
  onClickPlus,
  onUserSelect,
  onDriverSelect,
  onClickModifyBtn,
  onPaginationChange,
  pagination,
  loading,
  isMobile,
  renderDriverCheckbox,
  renderAccessTag,
  renderDriverInfoText,
  renderAccessInfoText,
}) => {
  const { currentUser } = useAppState()

  const renderListItem = (user: AugmentedUser) => {
    const { name, id, email, accesses } = user
    const isDriver = renderDriverCheckbox(user)
    const perm = renderAccessTag(user)
    return (
      <>
        <List
          renderHeader={() => {
            if (id === selectedUser?.id) {
              const [firstname, lastname] = getFullNameFromEmail(user.name)
              return (
                <Form
                  layout={isMobile ? 'vertical' : 'inline'}
                  onFinish={({ firstName, lastName }) => onClickSaveBtn(firstName, lastName)}
                  id="editMobileUserForm"
                >
                  <Form.Item
                    name="firstName"
                    label={
                      <div>
                        {'First Name '}
                        <Tooltip title="Edit First Name">
                          <EditFilled style={{ fontSize: '15px' }} />
                        </Tooltip>
                      </div>
                    }
                    rules={[
                      { required: true, message: 'First Name is Required' },
                      { max: 20, message: 'First Name cannot exceed 20 characters' },
                    ]}
                    initialValue={firstname}
                  >
                    <Input value={firstname} />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label={
                      <div>
                        {'Last Name '}
                        <Tooltip title="Edit Last Name">
                          <EditFilled style={{ fontSize: '15px' }} />
                        </Tooltip>
                      </div>
                    }
                    rules={[
                      { required: true, message: 'Last Name is Required' },
                      { max: 20, message: 'Last Name cannot exceed 20 characters' },
                    ]}
                    initialValue={lastname}
                  >
                    <Input value={lastname} />
                  </Form.Item>
                </Form>
              )
            }
            return `${name}`
          }}
          key={id}
        >
          <CustomFlexBasis>
            <Item extra={id}>Id</Item>
            <Item extra={email}>Email</Item>
          </CustomFlexBasis>
          {currentUser?.role !== 'user' && (
            <Item
              extra={
                <>
                  {user.isAdmin && <Tag color="green">admin</Tag>}
                  {user.isSupport && <Tag color="warning">support</Tag>}
                  {user.isUser && <Tag color="blue">user</Tag>}
                </>
              }
            >
              Role
            </Item>
          )}
          {hasTenantScope && (
            <>
              <Item
                extra={
                  <>
                    {!tenantScope && <></>}

                    {selectedUser && selectedUser.id === user.id && (
                      <Switch
                        defaultChecked={isDriver}
                        onChange={(checked) => {
                          onDriverSelect(checked)
                        }}
                      />
                    )}
                    {isDriver && <CheckCircleTwoTone twoToneColor={theme.colors.green} style={{ fontSize: '21px' }} />}
                  </>
                }
              >
                {
                  <Tooltip placement="topLeft" title={renderDriverInfoText}>
                    Driver <InfoCircleOutlined />
                  </Tooltip>
                }
              </Item>
              <CustomFlexBasis>
                <Item
                  multipleLine={true}
                  extra={
                    <>
                      {!tenantScope && <></>}
                      {selectedUser && selectedUser.id === user.id && (
                        <Row justify="space-between" gutter={[2, 4]} align="middle">
                          {userUpdate?.access && (
                            <>
                              <Col xs={9}>
                                <Select
                                  defaultValue={userUpdate?.access}
                                  onChange={(value) => {
                                    onUserSelect(value)
                                  }}
                                >
                                  <Option key="manager" value="manager">
                                    Manager
                                  </Option>
                                  <Option key="viewer" value="viewer">
                                    Viewer
                                  </Option>
                                </Select>
                              </Col>
                              <Col xs={4}>
                                <Button
                                  type={'ghost'}
                                  danger={true}
                                  onClick={() => {
                                    onClickCross()
                                  }}
                                >
                                  <CloseOutlined style={{ fontSize: '20px' }} />
                                </Button>
                              </Col>
                            </>
                          )}

                          {!userUpdate?.access && (
                            <Col xs={4}>
                              <Button
                                type={'primary'}
                                onClick={() => {
                                  onClickPlus()
                                }}
                              >
                                <PlusOutlined style={{ fontSize: '20px' }} />
                              </Button>
                            </Col>
                          )}

                          <Col xs={7}>
                            {!perm && <></>}
                            {perm?.access === 'manager' && <Tag>Manager</Tag>}
                            {perm?.access === 'viewer' && <Tag>Viewer</Tag>}
                          </Col>
                        </Row>
                      )}
                    </>
                  }
                >
                  {
                    <Tooltip placement="topLeft" title={renderAccessInfoText}>
                      Access <InfoCircleOutlined />
                    </Tooltip>
                  }
                </Item>
              </CustomFlexBasis>
              <CustomFlexBasis>
                <Item
                  extra={
                    <>
                      {!user.isUser && <></>}
                      {selectedUser && selectedUser.id === user.id && (
                        <Space>
                          <Button form="editMobileUserForm" key="submit" htmlType="submit">
                            Save
                          </Button>
                          <Button
                            danger
                            type={'ghost'}
                            onClick={() => {
                              onSelectedUser(undefined)
                            }}
                          >
                            Cancel
                          </Button>
                        </Space>
                      )}
                      {selectedUser && <></>}
                      {!tenantScope && <></>}
                      {user.id === currentUser?.id && <></>}
                      {user.isUser && !selectedUser && (
                        <Button
                          type="primary"
                          onClick={() => {
                            onClickModifyBtn(user)
                          }}
                        >
                          Modify
                        </Button>
                      )}
                    </>
                  }
                >
                  Actions
                </Item>
              </CustomFlexBasis>
            </>
          )}

          {!hasTenantScope && (
            <>
              <Item extra={<Badge count={accesses.length} style={{ backgroundColor: theme.colors.green }} />}>
                Tenant Access
              </Item>
              <Item
                extra={
                  <>
                    {user.id === currentUser?.id && <></>}
                    {
                      <Dropdown
                        overlay={
                          <Menu
                            onClick={(e) => {
                              onToggleRole(user.id, e.key as UserRole)
                            }}
                          >
                            <Menu.Item key="admin" disabled={user.role === 'admin'}>
                              Admin
                            </Menu.Item>
                            <Menu.Item key="support" disabled={user.role === 'support'}>
                              Support
                            </Menu.Item>
                            <Menu.Item key="user" disabled={user.role === 'user'}>
                              User
                            </Menu.Item>
                          </Menu>
                        }
                        arrow
                      >
                        <Button>Toggle Role</Button>
                      </Dropdown>
                    }
                  </>
                }
              >
                Action
              </Item>
            </>
          )}
        </List>
      </>
    )
  }
  return (
    <UserListView
      data={users}
      renderItem={renderListItem}
      loading={loading}
      onPaginationChange={onPaginationChange}
      pagination={pagination}
    />
  )
}
