import { Alert } from 'antd'
import React from 'react'
import { AlertBoxprops } from '../models/error'
import { errorSVG } from '../assets/svg/error'
import { styled } from '../theme'

const AlertWrapper = styled.div`
  display: flex;

  .alert-error-redesign {
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }

  .ant-alert-description {
    color: ${(props) => props.theme.colors.dangerPrimary};
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const AlertErrorCompactRedesign: React.FC<AlertBoxprops> = ({ error, closable }) => {
  if (!error) {
    return <></>
  }

  return (
    <AlertWrapper>
      <Alert
        className="alert-error-redesign"
        closable={closable || false}
        showIcon
        icon={errorSVG}
        description={String(error.cause).replace('Error:', '')}
        type="error"
      />
    </AlertWrapper>
  )
}
