import { theme } from 'theme'

const Stamp: React.FC = () => {
  return (
    <div
      style={{
        color: theme.colors.dangerPrimary,
        border: `1px solid ${theme.colors.dangerPrimary}`,
        fontSize: theme.fontSizes[2],
        position: 'absolute',
        backgroundColor: theme.colors.white,
        transform: 'rotate(-10deg) scale(2) translateX(30%) translateY(-50%)',
        zIndex: 1,
      }}
    >
      Mock
    </div>
  )
}

export default Stamp
