import { dollar24x24SVG } from '../../../../assets/svg/currency'
import { formatPrice } from '../../../../helpers/number-format'
import { TransactionMetric } from '../../../../models/analytics'

import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { CoreInfoCard } from 'components/cards'

interface TotalRevenueProps {
  metrics?: TransactionMetric
  loading: boolean
}

const TotalRevenue = ({ metrics, loading }: TotalRevenueProps) => {
  const { revenueText } = useGeneralTranslation()

  const calculateNetRevenue = metrics?.revenue ? formatPrice(metrics.revenue) : 0

  return (
    <CoreInfoCard
      title={revenueText}
      wsLoading={loading}
      overviewIcon={dollar24x24SVG}
      infoText={`${calculateNetRevenue}`}
    />
  )
}

export default TotalRevenue
