import { CoreInfoCard } from 'components/cards'
import { lightningSVG } from '../../../../assets/svg/lightning'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { ChargerInfo } from '../../../../models/chargerv2'
import { HomePageChargersParticpatingInDemandResponseEventsChart } from '../../charts/active-users/chargers-participating-demand-response-events'
import configProvider from 'config'

interface props {
  chargerInfo: ChargerInfo | undefined
  wsLoading: boolean
}

const ChargersEnrolledCard: React.FC<props> = ({ chargerInfo, wsLoading }) => {
  const {
    chargersEnrolledInDemandResponseText,
    levelTwoConnectorsText,
    dcFastConnectorsText,
  } = useDashboardTranslation()
  const infoDetails = [
    {
      label: levelTwoConnectorsText,
      subLabel: chargerInfo?.connectors.drProgramLevel2,
    },
    ...(configProvider.config.network === 'itsElectric'
      ? []
      : [
          {
            label: dcFastConnectorsText,
            subLabel: chargerInfo?.connectors.drProgramDc,
          },
        ]),
  ]

  return (
    <CoreInfoCard
      title={chargersEnrolledInDemandResponseText}
      wsLoading={wsLoading}
      infoText={chargerInfo?.connectors.drProgram}
      chargerInfoChart={
        chargerInfo && <HomePageChargersParticpatingInDemandResponseEventsChart chargerInfo={chargerInfo} />
      }
      overviewIcon={lightningSVG}
      infoDetails={chargerInfo && chargerInfo.connectors && infoDetails}
    />
  )
}

export default ChargersEnrolledCard
