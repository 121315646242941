import { Result } from 'antd'
import { EmptyData } from '../../components/redesign/emptyData'

const retrieveEmptyData = (description?: string) => (
  <EmptyData style={{ marginTop: '10vh', marginBottom: '10vh' }} description={description} />
)

export const isRetrievingData = (loading: boolean) => {
  if (loading) {
    return <Result icon={retrieveEmptyData('Retrieving data...')} />
  }
  return <Result icon={retrieveEmptyData()} />
}
