import { log } from '../../logger'
import { SubscriberResponse, SubscriptionResponse } from '../../models/http'
import { returnMock } from './mock'
import { apiClient } from './client'
import configProvider from '../../config'
import { mockSubscribersResp, mockSubcriptionsResp } from '../../mock/subscription-mock'
import { TenantRef } from '../../models/tenant'
import { addQueryString } from '../../helpers/url'
import { PlanRef } from '../../models/plan'
import { UserRef } from '../../models/user'
import { BillingAddress, BillingPeriod, Subscription } from '../../models/subscription'
import { Address } from '../../helpers/location'
import { mockPlanById } from '../../mock/plan-mock'

export async function GetSubscriptions(
  page: number,
  filter?: string,
  searchUser?: UserRef,
): Promise<SubscriptionResponse> {
  let url = '/subscriptions'
  let queryParams: { [key: string]: string } = {}

  if (filter) {
    queryParams['selected_status'] = filter
  }

  if (searchUser) {
    queryParams['selected_email'] = searchUser.email
  }

  if (page) {
    queryParams['page'] = `${page}`
  }

  url = addQueryString(url, queryParams)

  log('getting subscriptions', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockSubcriptionsResp(page))
  }
  return apiClient(url, {})
}

export async function GetSubscribers(page: number, term?: string, tenant?: TenantRef): Promise<SubscriberResponse> {
  let url = '/users/subscriptions'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (tenant) {
    queryParams['tenant_id'] = tenant.id.toString()
  }

  if (page) {
    queryParams['page'] = `${page}`
  }

  url = addQueryString(url, queryParams)

  log('getting subscribers', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockSubscribersResp(page))
  }
  return apiClient(url, {})
}

export async function CreateSubscription(
  plan: PlanRef,
  user: UserRef,
  startDate?: moment.Moment,
  address?: Address,
): Promise<Subscription> {
  let url = '/subscriptions'
  let billingAddress: any = undefined

  if (address) {
    billingAddress = {
      zip: address?.zip,
      state: address?.state,
      country: address?.country,
    }
  }

  const body = JSON.stringify({
    start_date: startDate ? startDate.format() : undefined,
    person_id: user.id,
    plan_id: plan.id,
    address: billingAddress,
  })

  log('create a subscription', { url: url })
  if (configProvider.config.mockEnabled) {
    const newSubscriber: Subscription = {
      id: Math.floor(Math.random() * 100),
      personId: Number(user.id),
      planId: plan.id,
      startDate: startDate ? startDate : undefined,
      status: 'pending',
      createdAt: '2021-05-01T23:59:59.000',
      updatedAt: '2021-06-01T00:00:00.000',
      billingAddress,
      quantity: 1,
      subscriber: { id: user.id, email: user.email, name: user.name, note: user.note },
      plan: mockPlanById[plan.id],
    }
    return returnMock(newSubscriber)
  }

  return apiClient(url, {
    method: 'POST',
    body,
  })
}

export async function UpdateSubscription(
  subscription: Subscription,
  startDate: moment.Moment,
  location: Address,
  user: UserRef,
  plan?: PlanRef,
): Promise<Subscription> {
  const url = `/subscriptions/${subscription.id}`

  log('update subscription', { url: url })
  if (configProvider.config.mockEnabled) {
    const editSubscription: Subscription = {
      id: subscription.id,
      personId: Number(user.id),
      planId: plan?.id,
      startDate: startDate.format(),
      endDate: '2021-06-01T00:00:00.000',
      status: 'pending',
      createdAt: '2021-05-01T23:59:59.000',
      updatedAt: '2021-06-01T00:00:00.000',
      billingAddress: {
        country: location.country,
        state: location.state,
        city: location.city,
        address: location.street_address,
        zip: location.zip,
      },
      quantity: 1,
      subscriber: { id: user.id, email: user.email, name: user.name },
      plan: plan ? mockPlanById[plan?.id] : null,
    } as Subscription
    return returnMock(editSubscription)
  }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      address: `${location.street_address}, ${location.zip}, ${location.city}, ${location.state} ${location.country}`,
      startDate: startDate.format('YYYY-MM-DD'),
      person_id: user.id,
    }),
  })
}

export async function UpdateBillingPeriod(
  subscription: Subscription,
  user: UserRef,
  billingPeriod: BillingPeriod,
): Promise<Subscription> {
  const url = `/subscriptions/${subscription.id}`
  // if (configProvider.config.mockEnabled) {
  //   return returnMock(updatedBillingPeriodSubscription)
  // }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      person_id: user.id,
      billing_period: {
        interval: billingPeriod.interval,
        interval_count: billingPeriod.intervalCount,
      },
    }),
  })
}

export async function UpdateBillingAddress(
  subscription: Subscription,
  user: UserRef,
  location: BillingAddress,
): Promise<Subscription> {
  const url = `/subscriptions/${subscription.id}/billing_address`
  if (configProvider.config.mockEnabled) {
    return returnMock(subscription)
  }
  log('update subscription billing address', { url: url })
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      location: {
        address: location.address,
        zip: location.zip,
        city: location.city,
        state: location.state,
        country: location.country,
      },
      person_id: user.id,
      quantity: subscription.quantity,
    }),
  })
}

export async function CancelSubscription(subscription: Subscription): Promise<string> {
  const url = `/subscriptions/${subscription.id}/cancel`

  log('cancelling subscription', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Subscription has been cancelled!')
  }
  return apiClient<{ message: string }>(url, {
    method: 'PUT',
  }).then((resp) => resp.message)
}

export async function PauseSubscription(subscription: Subscription): Promise<string> {
  const url = `/subscriptions/${subscription.id}/pause`

  log('pausing subscription', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Subscription has been paused!')
  }
  return apiClient<{ message: string }>(url, {
    method: 'PUT',
  }).then((resp) => resp.message)
}

export async function UnpauseSubscription(subscription: Subscription): Promise<string> {
  const url = `/subscriptions/${subscription.id}/unpause`

  log('unpausing subscription', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Subscription has been unpaused!')
  }
  return apiClient<{ message: string }>(url, {
    method: 'PUT',
  }).then((resp) => resp.message)
}
