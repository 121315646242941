import { useFormatMessage } from '../../helpers/intl'

export const useServiceStatusReasonsTranslation = () => {
  const breakerResetText = useFormatMessage('dashboard.serviceStatus.breakerReset', 'Breaker reset')
  const cableReplacementText = useFormatMessage('dashboard.serviceStatus.cableReplacement', 'Cable replacement')
  const chargerIsOfflineText = useFormatMessage('dashboard.serviceStatus.chargerIsOffline', 'Charger is offline')
  const initialActivationText = useFormatMessage('dashbaord.serviceStatus.initialActivation', 'Initial activation')

  const chargerIsFaultedText = useFormatMessage('dashboard.serviceStatus.chargerIsFaulted', 'Charger is faulted')
  const chargerIsDamagedText = useFormatMessage('dashboard.serviceStatus.chargerIsDamaged', 'Charger is damaged')
  const chargerIsVandalisedText = useFormatMessage(
    'dashboard.serviceStatus.chargerIsVandalised',
    'Charger is vandalised',
  )
  const hardwarePartsReplacementDCFCText = useFormatMessage(
    'dashboard.serviceStatus.hardwarePartsReplacement',
    'Hardware parts replacement (DCFC)',
  )
  const lackOfFeatureText = useFormatMessage('dashboard.serviceStatus.lackOfFeature', 'Lack of feature')
  const networkResetText = useFormatMessage('dashboard.serviceStatus.networkReset', 'Network reset')
  const otherPleaseSpecifyText = useFormatMessage(
    'dashboard.serviceStatus.otherPleaseSpecify',
    'Other - Please specify',
  )
  const networkHardwareReplacementText = useFormatMessage(
    'dashboard.serviceStatus.networkHardwareReplacement',
    'Network hardware replacement',
  )
  const poorCustomerServiceText = useFormatMessage(
    'dashboard.serviceStatus.poorCustomerService',
    'Poor customer service',
  )
  const poorHardwareQualityText = useFormatMessage(
    'dashboard.serviceStatus.poorHardwareQuality',
    'Poor hardware quality',
  )
  const productBugText = useFormatMessage('dashboard.serviceStatus.productBug', 'Product bug')
  const priceIsNotCompetitiveText = useFormatMessage(
    'dashboard.serviceStatus.priceIsNotCompetitive',
    'Price is not competitive',
  )
  const stationIsMalfunctioningText = useFormatMessage(
    'dashboard.serviceStatus.stationIsMalfunctioning',
    'Station is malfunctioning',
  )
  const unitReplacementText = useFormatMessage('dashboard.serviceStatus.unitReplacement', 'Unit replacement')

  return {
    breakerResetText,
    cableReplacementText,
    initialActivationText,
    chargerIsOfflineText,
    chargerIsFaultedText,
    chargerIsDamagedText,
    chargerIsVandalisedText,
    hardwarePartsReplacementDCFCText,
    lackOfFeatureText,
    networkHardwareReplacementText,
    networkResetText,
    otherPleaseSpecifyText,
    poorHardwareQualityText,
    poorCustomerServiceText,
    productBugText,
    priceIsNotCompetitiveText,
    stationIsMalfunctioningText,
    unitReplacementText,
  }
}
