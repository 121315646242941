import { Button, Divider, Form, Input, message, Select, Space, Spin, Switch } from 'antd'
import { useState } from 'react'
import { layout, tailLayout } from '../../atom/form/page-layout'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { Tenant, TenantPayload } from '../../models/tenant'
import { Box } from '../../atom/box'
import { getChangedValues } from 'helpers/form'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabAccessCode: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(true)
  const [isUniformUpdate, setIsUniformUpdate] = useState(tenant.allowUniformAccessPolicy)
  const [form] = Form.useForm()

  const tenantName = tenant.displayName || tenant.name
  const tenantUpdatedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.tenantUpdatedMessage',
    '-1 has been updated!',
  ).replace('-1', tenantName)

  const initialValues = {
    accessCode: tenant.accessCode,
    accessPolicy: tenant.accessPolicy,
    notes: tenant.notes,
    allowUniformAccessPolicy: tenant.allowUniformAccessPolicy,
    uniformAccessPolicy: tenant.uniformAccessPolicy,
  }

  const updateTenantOverview = async (values: any) => {
    setLoading(true)
    const changedValues: any = { ...getChangedValues(initialValues, values as any), id: tenant.id }
    onSave(changedValues)
    message.success(`${tenantUpdatedText}`)
    setLoading(false)
    setDisabled(true)
  }

  return (
    <Spin spinning={loading}>
      <Box padding>
        <Form
          {...layout}
          form={form}
          name={`tenant-${tenant?.id}`}
          onFinish={updateTenantOverview}
          initialValues={initialValues}
        >
          <Form.Item
            name="allowUniformAccessPolicy"
            label={renderFormatMessage('dashboard.tenantPage.accessCodeuniformUpdateText', 'Uniform Access Control')}
          >
            <Switch
              checked={isUniformUpdate}
              onChange={() => {
                setIsUniformUpdate(!isUniformUpdate)
              }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.allowUniformAccessPolicy !== currentValues.allowUniformAccessPolicy
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('allowUniformAccessPolicy') === true ? (
                <Form.Item
                  name="uniformAccessPolicy"
                  label={renderFormatMessage(
                    'dashboard.tenantPage.accesscontrolsetting.heading',
                    'Access Control Setting',
                  )}
                  rules={[{ required: false }]}
                  validateStatus="warning"
                >
                  <Select style={{ width: 120 }} disabled={disabled}>
                    <Select.Option value="private">
                      {renderFormatMessage('dashboard.tenantPage.accesscontrolsetting.restricted', 'Restricted')}
                    </Select.Option>
                    <Select.Option value="public">
                      {renderFormatMessage('dashboard.tenantPage.accesscontrolsetting.openaccess', 'Open Access')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Divider />
          <Form.Item
            name="accessCode"
            label={useFormatMessage('dashboard.tenantPage.accesscode.heading', 'Access Code')}
            rules={[
              {
                len: 6,
                message: useFormatMessage(
                  'dashboard.tenantPage.detailTab.accessCode.warning',
                  'Please enter a 6-digit access code',
                ),
                required: true,
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <Form.Item
            name="notes"
            label={useFormatMessage('dashboard.tenantPage.notes', 'Notes')}
            rules={[
              {
                max: 255,
                message: useFormatMessage('dashboard.tenantPage.notes.warning', 'Limit is 255 characters'),
                required: false,
              },
            ]}
          >
            <Input.TextArea disabled={disabled} showCount maxLength={255} />
          </Form.Item>
          <Divider />
          <Form.Item {...tailLayout}>
            <Space>
              <Button htmlType="submit" type="primary" disabled={disabled}>
                {renderFormatMessage('dashboard.tenantPage.overviewTab.saveBtn', 'Save')}
              </Button>

              {disabled ? (
                <Button htmlType="button" onClick={() => setDisabled(!disabled)} disabled={!disabled} type="primary">
                  {renderFormatMessage('dashboard.tenantPage.overviewTab.modifybtn', 'Modify')}
                </Button>
              ) : (
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.resetFields()
                    setDisabled(!disabled)
                  }}
                  danger
                >
                  {renderFormatMessage('dashboard.tenantPage.overviewTab.cancelBtn', 'Cancel')}
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </Box>
    </Spin>
  )
}
