import { useState, useEffect } from 'react'
import { Badge, Pagination, Space, Table, Tag, Typography } from 'antd'
import { EditOutlined, InteractionOutlined, ReloadOutlined, UserAddOutlined } from '@ant-design/icons'

import { SwtchError } from '../../models/error'
import { Plan } from '../../models/plan'
import { GetPlans } from '../../services/data-provider/plan'
import { theme } from '../../theme'
import { PaginationMeta } from '../../models/pagination'
import { UpdatePlanForm } from './form/update-plan-form'
import CreateSubscriptionForm from '../subscriptions/form/CreateSubscriptionForm'
import { DeletePlanModal } from './form/delete-plan-modal'

import { AlertError } from '../error'
import { CreatePlanForm } from './form/create-plan-form'
import { Subscription } from '../../models/subscription'
import { useAppState } from '../../state'
import { AddUserToPlanButton, TogglePlanButton, EditPlanButton } from './plan-button'
import { PlanMobileView } from './plan-mobile-view'
import { usePlanTranslation } from '../../hooks/translation/usePlanTranslation'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'

interface props {
  onCancel: () => void
  createPlanForm: boolean
}

export const Plans: React.FC<props> = ({ onCancel, createPlanForm }) => {
  const { IsMobile, IsTablet, IsDesktop, IsLaptop } = useAppState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState<SwtchError>()
  const [currentPage, setCurrentPage] = useState(1)
  const [togglePlan, setTogglePlan] = useState<Plan>()

  const [plans, setPlans] = useState<Plan[]>([])
  const [addUserToPlan, setAddUserToPlan] = useState<Plan>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const { subscribersText } = usePlanTranslation()

  const {
    activeText,
    descriptionText,
    inactiveText,
    intervalText,
    priceText,
    refreshText,
    titleText,
    statusText,
  } = useGeneralTranslation()

  useEffect(() => {
    getPlans()
  }, [currentPage])

  const getPlans = () => {
    setLoading(true)
    GetPlans(currentPage)
      .then((resp) => {
        setPlans(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const subcribersCount = (p: Plan) => {
    if (p.subscriptionsCount === null || p.subscriptionsCount === 0) {
      return
    }
    return <Badge count={p.subscriptionsCount} style={{ backgroundColor: theme.colors.green }} />
  }

  const handlePlanUpdated = (modifiedPlan: Plan) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === modifiedPlan.id) {
        return modifiedPlan
      }
      return plan
    })
    setPlans(updatedPlans)
    setSelectedPlan(undefined)
  }

  const handlePlanCreated = (newPlan: Plan) => {
    setPlans([...plans, newPlan])
    onCancel()
  }

  const handleNewSubscription = (newSub: Subscription) => {
    const updatePlans = plans.map((plan) => {
      if (plan.id === newSub?.plan?.id) {
        return { ...newSub.plan, subscriptionsCount: plan.subscriptionsCount + 1 }
      }
      return plan
    })
    setPlans(updatePlans)
    setAddUserToPlan(undefined)
  }

  const onPageChange = (page: number) => setCurrentPage(page)

  const handleStatus = (plan: Plan) =>
    plan.status === 'active' ? <Tag color="green">{activeText}</Tag> : <Tag color="red">{inactiveText}</Tag>

  const handlePriceCents = (plan: Plan) => `$${(plan.priceCents / 100).toFixed(2)} / ${plan.interval}`

  const columns = [
    { title: titleText, dataIndex: 'name' },
    { title: descriptionText, dataIndex: 'description' },
    {
      title: subscribersText,
      key: 'subscribers',
      render: (plan: Plan) => subcribersCount(plan),
    },
    {
      title: statusText,
      key: 'status',
      render: (plan: Plan) => handleStatus(plan),
    },
    { title: intervalText, dataIndex: 'interval' },
    {
      title: priceText,
      key: 'priceCents',
      render: (plan: Plan) => handlePriceCents(plan),
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: (
        <Typography.Link disabled={loading} onClick={() => getPlans()}>
          {<ReloadOutlined />} {refreshText}
        </Typography.Link>
      ),
      key: 'charger_action',
      align: 'right' as 'right',
      render: (plan: Plan) => {
        return (
          <Space>
            <AddUserToPlanButton
              plan={plan}
              children={<UserAddOutlined key="addUser" />}
              onAddUserToPlan={(plan) => setAddUserToPlan(plan)}
            />
            <EditPlanButton
              plan={plan}
              children={<EditOutlined key="editPlan" />}
              onEditPlan={(plan) => setSelectedPlan(plan)}
            />
            <TogglePlanButton
              plan={plan}
              children={<InteractionOutlined key="deletePlan" />}
              onTogglePlan={(plan) => setTogglePlan(plan)}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <AlertError error={error} />
      {(isDesktop || isLaptop) && (
        <Table
          loading={loading}
          columns={columns}
          dataSource={plans}
          rowKey={(plan: Plan): string => `${plan.id}`}
          pagination={{
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPageChange,
          }}
        />
      )}
      {(isMobile || isTablet) && (
        <>
          <PlanMobileView
            plans={plans}
            loading={loading}
            onAddUserToPlan={(plan) => setAddUserToPlan(plan)}
            onEditPlan={(plan) => setSelectedPlan(plan)}
            onTogglePlan={(plan) => setTogglePlan(plan)}
          />
          <br />
          <Pagination
            current={currentPage}
            onChange={onPageChange}
            total={pagination?.totalEntries}
            showQuickJumper={false}
            showSizeChanger={false}
            showTitle={true}
            style={{ textAlign: 'center' }}
          />
        </>
      )}

      {addUserToPlan && (
        <CreateSubscriptionForm
          onCancel={() => setAddUserToPlan(undefined)}
          defaultPlan={addUserToPlan}
          onSubscribe={handleNewSubscription}
        />
      )}
      {selectedPlan && (
        <UpdatePlanForm
          plan={selectedPlan}
          onCancel={() => setSelectedPlan(undefined)}
          onUpdatedPlan={(p) => handlePlanUpdated(p)}
        />
      )}

      {togglePlan && (
        <DeletePlanModal
          plan={togglePlan}
          onCancel={() => setTogglePlan(undefined)}
          onTogglePlan={(p) => {
            handlePlanUpdated(p)
            setTogglePlan(undefined)
          }}
        />
      )}
      {createPlanForm && <CreatePlanForm onCancel={onCancel} onCreatedPlan={(p) => handlePlanCreated(p)} />}
    </>
  )
}
