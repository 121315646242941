import { DownOutlined, EditFilled } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'

import EVChargerSVG from 'assets/svg/ev_charging'
import { plugGreySVG } from 'assets/svg/plug'
import { Links } from 'components/routes/paths'

import { renderFormatMessage } from 'helpers/intl'
import { buildRailsListingUrl } from 'helpers/url'

import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useListingsTranslation } from 'hooks/translation/useListingsTranslation'
import { NewListing } from 'models/listing'

import { useAppState } from 'state'
import { theme } from 'theme'

const menuStyle = {
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.frenchGray}`,
  borderRadius: '5px',
}

const ActionsMenu = ({ listing }: { listing: NewListing }) => {
  const { currentUser } = useAppState()
  const isAdmin = currentUser?.role === 'admin'

  const { viewChargerInAppText } = useChargerTranslation()
  const { viewChargerDetailsText } = useChargerTranslation()
  const { editListingDetailsText } = useListingsTranslation()

  if (!listing) return null

  const menu = (
    <Menu style={menuStyle}>
      <Menu.Item
        disabled={!listing.charger || !listing.connector}
        aria-disabled={!listing.charger || !listing.connector}
        icon={plugGreySVG}
      >
        {listing.charger?.id && listing.connector?.id ? (
          <a
            key={`${listing.id}-charger`}
            href={Links.charger({ chargerId: listing.charger.id, connectorId: listing.connector?.id })}
            target="_blank"
            rel="noreferrer"
            aria-label={`View details for charger ${listing.charger.id}`}
          >
            {viewChargerDetailsText}
          </a>
        ) : (
          <span>{viewChargerDetailsText}</span>
        )}
      </Menu.Item>
      <Menu.Item disabled={!listing.id} icon={<EVChargerSVG color={theme.colors.darkGray} />}>
        {listing.id ? (
          <a
            style={{ marginLeft: '0.5rem' }}
            key={`${listing.id}-${listing.title}`}
            href={buildRailsListingUrl(listing)}
            target="_blank"
            rel="noreferrer"
            aria-label={`View charger status for listing ${listing.title}`}
          >
            {viewChargerInAppText}
          </a>
        ) : (
          <span style={{ marginLeft: '0.5rem' }}>{viewChargerInAppText}</span>
        )}
      </Menu.Item>
      {isAdmin ? (
        <Menu.Item
          disabled={!listing.id}
          icon={<EditFilled style={{ fontSize: theme.fontSizes[3], color: theme.colors.darkGray }} />}
        >
          {listing.id ? (
            <a
              key={`${listing.id}-${listing.title}`}
              href={Links.listing({ listingId: listing.id })}
              target="_blank"
              rel="noreferrer"
            >
              {editListingDetailsText}
            </a>
          ) : (
            <span>{editListingDetailsText}</span>
          )}
        </Menu.Item>
      ) : null}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} overlayClassName="custom-dropdown" aria-haspopup="true" aria-controls="actions-menu">
      <span onClick={(e) => e.preventDefault()} role="button" tabIndex={0} aria-label="More actions">
        <Space>
          <span style={{ color: theme.colors.softBlack, textTransform: 'uppercase' }}>
            {renderFormatMessage('dashboard.text.more', 'More')}
          </span>
          <DownOutlined style={{ color: theme.colors.softBlack }} />
        </Space>
      </span>
    </Dropdown>
  )
}

export default ActionsMenu
