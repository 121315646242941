import { useState } from 'react'
import { Button, Form, Input, InputNumber, message, Modal, Select } from 'antd'

import { layout } from '../../../atom/form/modal-layout'
import { AlertErrorCompact } from '../../error-compact'
import { SwtchError } from '../../../models/error'
import { countries, currency, intervals, Plan } from '../../../models/plan'
import { CreatePlan } from '../../../services/data-provider/plan'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { MustBeRequired } from '../../rules/rules'

interface props {
  onCancel: () => void
  onCreatedPlan: (plan: Plan) => void
}

interface FormFields {
  name: string
  description: string
  priceCents: number
  interval: string
  currency: string
  country: string
}

const initialValues = { interval: 'month', currency: 'CAD', priceCents: 0, country: 'CA' }

export const CreatePlanForm: React.FC<props> = ({ onCancel, onCreatedPlan }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const { cancelText, createText, countryText, currencyText, okText } = useGeneralTranslation()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    form.validateFields().then((values: FormFields) => {
      const { name, description, priceCents, interval, currency, country } = values

      CreatePlan(name, description, priceCents * 100, interval, currency, country)
        .then((plan) => {
          message.success(`A new plan has been created: ${plan.name}`)
          onCreatedPlan(plan)
        })
        .catch((error: SwtchError) => setError(error))
        .finally(() => setLoading(false))
    })
  }

  const afterClose = () => form.resetFields()

  return (
    <Modal
      title="Create Plan"
      closable={false}
      okText={okText}
      cancelText={cancelText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={afterClose}
      footer={[
        <Button key="back" danger loading={loading} onClick={onCancel}>
          {cancelText}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          {createText}
        </Button>,
      ]}
    >
      <AlertErrorCompact error={error} />
      <Form {...layout} form={form} initialValues={initialValues}>
        <Form.Item
          key="name"
          label="Title"
          name="name"
          rules={[MustBeRequired('Subscription title must be at least 8 characters long'), { type: 'string' }]}
        >
          <Input placeholder="Subscription title" type="text" maxLength={50} />
        </Form.Item>
        <Form.Item key="description" label="Description" name="description" rules={[{ required: true }]}>
          <Input placeholder="Description of the plan" type="textarea" />
        </Form.Item>
        <Form.Item key="interval" label="Interval" name="interval">
          <Select placeholder="Month">
            {intervals.map((interval, index) => (
              <Select.Option key={`${interval}-${index}`} value={interval}>
                {interval}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item key="priceCents" label="Price" name="priceCents" rules={[{ required: true }]}>
          <InputNumber<number>
            defaultValue={0}
            min={0}
            max={50}
            placeholder="Price"
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(displayValue: string | undefined) => {
              if (!displayValue) {
                return 0
              }
              const str = displayValue.replace(/\$\s?|(,*)/g, '')
              return parseFloat(str)
            }}
          />
        </Form.Item>
        <Form.Item key="currency" label={currencyText} name="currency">
          <Select placeholder="CAD">
            {currency.map((c, index) => (
              <Select.Option key={`${c}-${index}`} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item key="country" label={countryText} name="country">
          <Select placeholder="CA">
            {countries.map((country, index) => (
              <Select.Option key={`${country}-${index}`} value={country}>
                {country}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
