export const loiteringStatusType = ['no_penalty', 'time_based', 'status_based'] as const
export type LoiteringStatusType = typeof loiteringStatusType[number]

export interface Loitering extends TimeOfDayLoitering {
  loiteringStatus?: LoiteringStatusType
  loiteringTimeLimit?: number
  loiteringGracePeriod?: number
  loiteringPenalty?: number
  disableLoiteringForTenantMember?: boolean
}

export interface TimeOfDayLoitering {
  timeOfDayLoiteringWeekday?: number[]
  timeOfDayLoiteringWeekend?: number[]
}

export interface UniformLoitering {
  disableLoitering: boolean
}
