import { Table } from 'antd'
import moment from 'moment'

import { DateTime } from '../../../components/date-time'
import { formatDuration } from '../../../helpers/duration'
import { renderFormatMessage } from '../../../helpers/intl'
import { formatPrice } from '../../../helpers/number-format'
import { ConnectorRef } from '../../../models/connector'
import { ListingRef } from '../../../models/listing'
import { Transaction } from '../../../models/transaction'

interface props {
  transaction: Transaction
}

export const nestedColumns = [
  {
    title: renderFormatMessage('dashboard.text.listing', 'Listing'),
    dataIndex: 'listing',
    render: (listing: ListingRef) => listing.title,
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.connectorId', 'Connector ID'),
    dataIndex: 'connector',
    render: (conn: ConnectorRef) => (conn ? conn.connectorId : '---'),
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.battery', 'Battery Percent (%)'),
    dataIndex: 'percent',
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.chargedAt', 'Charged At'),
    key: 'chargeStartAt',
    render: ({ chargeStartAt, timezone }: Transaction) => {
      return chargeStartAt ? <DateTime date={moment(chargeStartAt)} timezone={timezone} /> : '---'
    },
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.endChargedAt', 'End Charging At'),
    key: 'chargeEndAt',
    render: ({ chargeStartAt, chargeEndAt, timezone, duration }: Transaction) => {
      if (chargeEndAt && chargeStartAt) {
        return <DateTime date={moment(chargeEndAt)} timezone={timezone} />
      } else if (!chargeEndAt && chargeStartAt && duration) {
        return <DateTime date={moment(chargeStartAt).add(duration, 'seconds')} timezone={timezone} />
      } else {
        return '---'
      }
    },
  },
  {
    title: renderFormatMessage(
      'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration',
      'Charging Duration',
    ),
    key: 'chargingDuration',
    render: ({ duration, chargeStartAt }: Transaction) => {
      if (duration === 0 || !chargeStartAt) {
        return '---'
      }
      const result = formatDuration(moment.duration(duration, 'seconds'))
      return <span>{result}</span>
    },
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.subtotal', 'Sub-total'),
    dataIndex: 'financial',
    render: ({ subtotal }: Transaction['financial']) => formatPrice(subtotal / 100),
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.tax', 'Tax'),
    dataIndex: 'financial',
    render: ({ tax }: Transaction['financial']) => formatPrice(tax / 100),
  },
  {
    title: renderFormatMessage('dashboard.transactionsPage.table.nestedTable.heading.seller', 'Seller'),
    dataIndex: 'financial',
    render: ({ tenantAmount }: Transaction['financial']) => formatPrice(tenantAmount / 100),
  },
]

export const TransactionsNestedColumns: React.FC<props> = ({ transaction }) => {
  return (
    <Table
      columns={nestedColumns}
      dataSource={[transaction]}
      pagination={false}
      rowKey={(transaction: Transaction): string => `${transaction.id}`}
    />
  )
}
