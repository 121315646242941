import { theme } from '../../theme'

export const reportSVG = (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5556 0.5L16 5V17.6072C15.9998 17.8441 15.9067 18.0712 15.7412 18.2386C15.5757 18.406 15.3513 18.5 15.1173 18.5H0.882667C0.649225 18.4984 0.425793 18.4038 0.260638 18.2367C0.0954826 18.0697 0.00186191 17.8436 0 17.6072V1.3928C0 0.8996 0.395556 0.5 0.882667 0.5H11.5556ZM7.11111 5V6.8H8.88889V5H7.11111ZM7.11111 8.6V14H8.88889V8.6H7.11111Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const reportWhiteSVG = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 17.3334H1.33333C1.11232 17.3334 0.900358 17.2456 0.744078 17.0893C0.587797 16.933 0.5 16.721 0.5 16.5V1.50002C0.5 1.27901 0.587797 1.06704 0.744078 0.910765C0.900358 0.754484 1.11232 0.666687 1.33333 0.666687H14.6667C14.8877 0.666687 15.0996 0.754484 15.2559 0.910765C15.4122 1.06704 15.5 1.27901 15.5 1.50002V16.5C15.5 16.721 15.4122 16.933 15.2559 17.0893C15.0996 17.2456 14.8877 17.3334 14.6667 17.3334ZM4.66667 4.83335V6.50002H11.3333V4.83335H4.66667ZM4.66667 8.16669V9.83335H11.3333V8.16669H4.66667ZM4.66667 11.5V13.1667H8.83333V11.5H4.66667Z"
      fill={theme.colors.white}
    />
  </svg>
)
