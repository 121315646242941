import { TenantPermission } from '../models/user'

const TenantStorePrefix = 't'

export const getTenant = (): TenantPermission | undefined => {
  const item = localStorage.getItem(TenantStorePrefix)
  if (!item) {
    return undefined
  }
  try {
    return JSON.parse(item)
  } catch (error) {
    return undefined
  }
}

export const setTenant = (tenant: TenantPermission) => {
  localStorage.setItem(TenantStorePrefix, JSON.stringify(tenant))
}

export const clearTenant = () => {
  localStorage.removeItem(TenantStorePrefix)
  // localStorage.clear()
}
