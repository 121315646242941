import { useRef, useState } from 'react'
import { SwtchError } from '../models/error'
import { ListingBulkUpdateDetails, NewListing } from '../models/listing'
import { UpdateBulkListings } from 'services/data-provider/listing'
import { useNotifications } from './useNotification'
import { useListingsTranslation } from './translation/useListingsTranslation'

export const useListingAccess = (listings: NewListing[], onOk: () => void) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const selectRef = useRef<any>(null)
  const { openSuccessNotification } = useNotifications()
  const { listingUpdated, listingsUpdated } = useListingsTranslation()

  const initialValues = {
    access_type: 'private',
    car_settings: 'not_required',
    map_settings: 'hide',
  }

  const handleSelect = (value: string) => {
    if (value === 'other') {
      // Close the dropdown when 'other' is selected
      if (selectRef) {
        selectRef.current.blur()
      }
    }
  }

  const onFinish = (values: ListingBulkUpdateDetails) => {
    setError(undefined)
    setLoading(true)

    const data = {
      access_type: values['access_type'],
      car_detail_required: values['car_settings'] === 'required' ? true : false,
      hidden: values['map_settings'] === 'hide' ? true : false,
    }

    UpdateBulkListings({
      listing_ids: listings.map((f) => f.id),
      listing: data,
    })
      .then((resp) => {
        if (resp) {
          openSuccessNotification(listings.length > 1 ? listingsUpdated : listingUpdated)
        }
        onOk()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return {
    error,
    loading,
    selectRef,
    initialValues,
    handleSelect,
    onFinish,
  }
}
