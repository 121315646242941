import { renderFormatMessage } from './intl'

export const SELECTOR_OPTIONS: { [key: string]: JSX.Element } = {
  'Select OCPP Status': renderFormatMessage('dashboard.selector.selectConnectorStatus', 'Connector Status'),
  'Select Service Status': renderFormatMessage('dashboard.selector.serviceStatus', 'Service Status'),
  'Service Status': renderFormatMessage('dashboard.selector.serviceStatus', 'Service Status'),
  'Select Vendor': renderFormatMessage('dashboard.chargersPage.selectVendor', 'Select Vendor'),
  'Vendor (s)': renderFormatMessage('dashboard.selector.vendors', 'Vendor (s)'),
  'Status (es)': renderFormatMessage('dashboard.text.statuses', 'Status (es)'),
  'Account Name (s)': renderFormatMessage('dashboard.text.accountName', 'Account Name (s)'),
  'System Area (s)': renderFormatMessage('dashboard.text.systemAreas', 'System Area (s)'),
}
