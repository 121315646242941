import { useState } from 'react'

import { Button, Modal, Space } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { CancelShavingEvent } from '../../../services/data-provider/peak-shaving'
import { HeadingWrapper, TextBodyWrapper } from '../../../atom/plans'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  eventId: number
}

export const CancelEventModal: React.FC<props> = ({ visible, onCancel, onSubmit, eventId }) => {
  const { cancelText, cancelEventText, cancelEventWarningText } = usePeakShavingTranslation()
  const [error, setError] = useState<SwtchApiError>()
  const [loading, setLoading] = useState(false)

  const onClickCancelEventButton = () => {
    setLoading(true)
    CancelShavingEvent(eventId)
      .then(({ response }) => {
        CustomSuccessMessage({ key: 'Cancel Event', response: response })
        onSubmit()
      })
      .catch((err: SwtchApiError) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      <Modal
        visible={visible}
        title={
          <HeadingWrapper>
            {cancelEventText} - {eventId}
          </HeadingWrapper>
        }
        footer={
          <Space>
            <Button type="ghost" onClick={onCancel} loading={loading}>
              {cancelText}
            </Button>
            <Button danger onClick={onClickCancelEventButton} loading={loading}>
              {cancelEventText}
            </Button>
          </Space>
        }
        onCancel={onCancel}
        closeIcon={closeSVG}
      >
        <TextBodyWrapper>{cancelEventWarningText}</TextBodyWrapper>
      </Modal>
    </>
  )
}
