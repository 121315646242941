import { Statistic } from 'antd'

interface props {
  value?: string | number
  precision?: number
  prefix?: string | JSX.Element
  valueStyle?: React.CSSProperties
  suffix?: string | JSX.Element
}

const StatisticValue: React.FC<props> = ({ value, precision, prefix, valueStyle, suffix }) => {
  return <Statistic value={value} precision={precision} prefix={prefix} valueStyle={valueStyle} suffix={suffix} />
}

export default StatisticValue
