export const capitalizeFirstLetter = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

export const capitalizeAndReplaceUnderscore = (word: string): string => {
  // Replace underscores with empty spaces
  const stringWithSpaces = word.replace(/_/g, ' ')

  // Capitalize the first letter of each word
  const capitalizedString = stringWithSpaces.replace(/\b\w/g, (match) => match.toUpperCase())

  // Lowercase the letter after 'é' and 'ô'
  const finalString = capitalizedString.replace(
    /([éô])(\w)/g,
    (_, accentedChar, nextChar) => accentedChar + nextChar.toLowerCase(),
  )

  return finalString
}

export const getFullNameFromEmail = (displayName: string): string[] => {
  let chunks: string[]

  if (displayName.includes('@')) {
    chunks = displayName.split('@')
    return chunks
  }

  chunks = displayName.split(' ')
  if (chunks.length > 1) {
    let firstName = ''
    for (let i = 0; i < chunks.length - 1; i++) {
      firstName += chunks[i]
      if (i < chunks.length - 2) {
        firstName += ' '
      }
    }
    let lastName = chunks[chunks.length - 1]
    return [firstName.trim(), lastName.trim()]
  }

  return ['', '']
}

export const getFirstAndLastName = (fullName?: string) => {
  let chunks: string[]

  if (fullName) {
    chunks = fullName.split(' ')
    if (chunks.length > 1) {
      let firstName = ''
      for (let i = 0; i < chunks.length - 1; i++) {
        firstName += chunks[i]
        if (i < chunks.length - 2) {
          firstName += ' '
        }
      }
      let lastName = chunks[chunks.length - 1]
      return [firstName.trim(), lastName.trim()]
    }
  }
  return ['', '']
}
