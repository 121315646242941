import { Table } from 'antd'

import { Connector, ConnectorV2 } from '../../models/connector'
import { Charger } from '../../models/charger'
import { ChargerConnectorStateBadge } from './charger-connector-state-badge'
import { renderFormatMessage } from '../../helpers/intl'

interface props {
  charger: Charger
}

export const ChargerTabDetail: React.FC<props> = ({ charger }) => {
  const columns: any = [
    {
      title: renderFormatMessage('dashboard.chargerPage.tab.detail.title', 'Title'),
      dataIndex: ['listing', 'title'],
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.tab.detail.connectorid', 'Connector Id'),
      dataIndex: 'connectorId',
    },
    {
      title: renderFormatMessage('dashboard.selector.serviceStatus', 'Service Status'),
      key: 'status',
      render: (conn: ConnectorV2) =>
        conn.serviceStatus ? <ChargerConnectorStateBadge state={conn.serviceStatus} /> : null,
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.tab.detail.tenant', 'Site'),
      key: 'tenant',
      render: (conn: Connector) => conn.charger?.tenant?.displayName || conn.charger?.tenant?.name,
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.tab.detail.vendor', 'Vendor'),
      dataIndex: 'chargePointVendor',
      render: () => charger.chargePointVendor,
    },
    {
      title: renderFormatMessage('dashboard.chargerPage.tab.detail.firmwareVersion', 'Firmware Version'),
      dataIndex: 'firmwareVersion',
      render: () => charger.firmwareVersion,
    },
  ]

  return <Table columns={columns} dataSource={charger.connectors} pagination={false} rowKey="id" />
}
