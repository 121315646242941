import { Button } from 'antd'
import { CustomFooterLayout } from '../../../../atom/price'
import { HorizontalLine } from '../../../../atom/horizontal-line'
import { FormInstance } from 'antd/es/form/Form'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { DiscountSchemaPayload } from 'models/discount'

interface props {
  loading: boolean
  form: FormInstance
  discountNameExists?: { [key: number]: boolean }
  promoCodeExists?: { [key: number]: boolean }
  current?: number
  modalType?: string
  setNextInModal?: (nextInModal: boolean) => void
  onCancel: () => void
  back?: () => void
}

export const DiscountFooterSectionModal: React.FC<props> = ({
  loading,
  form,
  discountNameExists,
  promoCodeExists,
  current,
  modalType,
  onCancel,
  setNextInModal,
  back,
}) => {
  const { saveText, nextText } = useGeneralTranslation()
  const { skipDiscountPlanText } = useDiscountSchemaTranslation()

  const hasValidationErrors = () => {
    let exists = false
    form.getFieldsValue(true).discount_schema_form?.map((discountSchema: DiscountSchemaPayload) => {
      if (discountSchema.name === '' || discountSchema.promo_code === '') return (exists = true)
      return exists
    })

    return (
      Object.values(discountNameExists ? discountNameExists : {}).some((state) => state === true) ||
      Object.values(promoCodeExists ? promoCodeExists : {}).some((state) => state === true) ||
      exists
    )
  }

  return (
    <>
      <HorizontalLine />
      <CustomFooterLayout>
        {current === 0 ? (
          <Button
            type="primary"
            onClick={
              setNextInModal
                ? () => {
                    setNextInModal(true)
                  }
                : () => {}
            }
          >
            {nextText}
          </Button>
        ) : (
          <>
            {modalType === 'discount-only' && (
              <Button key="back" loading={loading} onClick={back} style={{ position: 'absolute', left: '24px' }}>
                Back
              </Button>
            )}
            {modalType === 'both' && (
              <Button key="back" loading={loading} onClick={onCancel}>
                {skipDiscountPlanText}
              </Button>
            )}

            <Button key="submit" htmlType="submit" type="primary" loading={loading} disabled={hasValidationErrors()}>
              {saveText}
            </Button>
          </>
        )}
      </CustomFooterLayout>
    </>
  )
}
