import moment from 'moment'
import { Card, Collapse, List, Pagination, Skeleton } from 'antd'

import { Transaction } from '../../../../models/transaction'
import { PaginationMeta } from '../../../../models/pagination'
import { DateTimeV2 } from '../../../../components/date-time'
import NoDataBadge from '../../../../atom/Badge'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { useTransactionSearchTranslation } from '../../../../hooks/translation/useTransactionSearchTranslation'
import { useTransactionMetricTranslation } from '../../../../hooks/translation/useTransactionMetricTranslation'
import { renderChargingStop } from '../../../../helpers/date-format'
import { formatEnergy, formatPrice } from '../../../../helpers/number-format'
import { TransactionStateBadge } from '../../../../components/transactions/transaction-state-badge'
import { useListingBasicTrans } from '../../../../hooks/translation/useListingBasicTrans'
import { useChargerTranslation } from '../../../../hooks/translation/useChargerTranslation'
import { theme } from '../../../../theme'
import { CollapseWrapper } from '../../../../atom/collapse'
import { useFinancialTranslation } from '../../../../hooks/translation/useFinancialTranslation'
import { useConnectorTranslation } from '../../../../hooks/translation/useConnectorTranslation'
import { useCarTranslation } from '../../../../hooks/translation/useCarTranslation'
import { isRetrievingData } from '../../../../helpers/empty'
import { formatDurationWithTwoDigits } from '../../../../helpers/duration'
import { ListCollapseWrapper } from 'atom/list'

interface props {
  loading: boolean
  transactions: Transaction[]
  pagination: PaginationMeta
  onPaginationChange: (page: number) => void
}

const TransactionCollapse: React.FC<props> = ({ loading, transactions, pagination, onPaginationChange }) => {
  const {
    chargingStartText,
    chargingEndText,
    chargingDurationText,
    connectedText,
    disconnectedText,
    tenantText,
  } = useGeneralTranslation()
  const { stateText, startMethodText } = useTransactionSearchTranslation()
  const { totalEnergyText } = useTransactionMetricTranslation()
  const { listingTitleText, listingIDText } = useListingBasicTrans()
  const { chargerTypeText, SNText, vendorText } = useChargerTranslation()
  const { connectorTypeText, connectorIdText } = useConnectorTranslation()
  const { taxText, subTotalText, totalText } = useFinancialTranslation()
  const { brandText, modelText, socAfterChargingText, socBeforeChargingText, yearText } = useCarTranslation()

  return (
    <Skeleton loading={loading}>
      {transactions.length === 0 && isRetrievingData(loading)}
      <CollapseWrapper>
        <Collapse accordion bordered={false} expandIconPosition="right">
          {transactions.map((transaction) => (
            <Collapse.Panel
              key={transaction.id}
              header={
                <div>
                  <div style={{ opacity: 0.6 }}> ID: {transaction.id}</div>
                </div>
              }
            >
              <ListCollapseWrapper>
                <List
                  dataSource={[transaction]}
                  renderItem={(transaction) => (
                    <>
                      <List.Item actions={[transaction.startMethod]}>
                        <List.Item.Meta key={`${transaction.id}-username-start-method`} title={startMethodText} />
                      </List.Item>
                      <List.Item actions={[transaction.tenant.name]}>
                        <List.Item.Meta key={`${transaction.id}-tenant`} title={tenantText} />
                      </List.Item>
                      <List.Item
                        actions={[
                          <DateTimeV2 date={transaction.startAt} timezone={transaction.timezone} isSidePanel />,
                        ]}
                      >
                        <List.Item.Meta key={`${transaction.id}-connected`} title={connectedText} />
                      </List.Item>
                      <List.Item actions={[disconnected(transaction)]}>
                        <List.Item.Meta key={`${transaction.id}-disconnected`} title={disconnectedText} />
                      </List.Item>
                      <List.Item actions={[chargingStart(transaction)]}>
                        <List.Item.Meta key={`${transaction.id}-charging-start`} title={chargingStartText} />
                      </List.Item>
                      <List.Item actions={[renderChargingStop(transaction)]}>
                        <List.Item.Meta key={`${transaction.id}-charging-end`} title={chargingEndText} />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.currentChargingDuration ? (
                            formatDurationWithTwoDigits(transaction.currentChargingDuration)
                          ) : (
                            <NoDataBadge noBackground />
                          ),
                        ]}
                      >
                        <List.Item.Meta
                          key={`${transaction.id}-current-charging-duration`}
                          title={chargingDurationText}
                        />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.energy ? (
                            `${formatEnergy(transaction.energy / 1000)} kWh`
                          ) : (
                            <NoDataBadge noBackground />
                          ),
                        ]}
                      >
                        <List.Item.Meta key={`${transaction.id}-total-energy`} title={totalEnergyText} />
                      </List.Item>
                      <List.Item actions={[<TransactionStateBadge transaction={transaction} />]}>
                        <List.Item.Meta key={`${transaction.id}-currentState`} title={stateText} />
                      </List.Item>
                      <List.Item actions={[transaction.user?.email]}>
                        <List.Item.Meta key={`${transaction.id}-email`} title="Email" />
                      </List.Item>
                      <List.Item actions={[transaction.listing.title]}>
                        <List.Item.Meta key={`${transaction.id}-listing-title`} title={listingTitleText} />
                      </List.Item>
                      <List.Item actions={[transaction.listing.id]}>
                        <List.Item.Meta key={`${transaction.id}-listing-id`} title={listingIDText} />
                      </List.Item>
                      <List.Item actions={[transaction.charger.chargePointVendor]}>
                        <List.Item.Meta key={`${transaction.id}-charger-vendor`} title={vendorText} />
                      </List.Item>
                      <List.Item actions={[transaction.charger.chargePointSerialNumber]}>
                        <List.Item.Meta title={SNText} />
                      </List.Item>
                      <List.Item actions={[transaction.charger.chargingType]}>
                        <List.Item.Meta title={chargerTypeText} />
                      </List.Item>
                      <List.Item actions={[transaction.connector.connectorType]}>
                        <List.Item.Meta title={connectorTypeText} />
                      </List.Item>
                      <List.Item actions={[transaction.connector.id]}>
                        <List.Item.Meta title={connectorIdText} />
                      </List.Item>
                      <List.Item actions={[transaction.car?.brand || '-']}>
                        <List.Item.Meta title={brandText} />
                      </List.Item>
                      <List.Item
                        actions={[
                          transaction.car?.model && transaction.car.year
                            ? `${transaction.car?.model} ${transaction.car?.year}`
                            : '-',
                        ]}
                      >
                        <List.Item.Meta title={`${modelText} & ${yearText}`} />
                      </List.Item>
                      <List.Item actions={[`${transaction.percentStart}%` || '-']}>
                        <List.Item.Meta title={socBeforeChargingText} />
                      </List.Item>
                      <List.Item actions={[`${transaction.percent}%` || '-']}>
                        <List.Item.Meta title={socAfterChargingText} />
                      </List.Item>
                      <Card
                        style={{ backgroundColor: theme.colors.cultered, marginTop: '12px' }}
                        bodyStyle={{ padding: '6px 12px' }}
                      >
                        <List.Item
                          actions={[formatPrice(transaction.financial.subtotal / 100, transaction.financial.currency)]}
                        >
                          <List.Item.Meta title={subTotalText} />
                        </List.Item>
                        <List.Item
                          actions={[formatPrice(transaction.financial.tax / 100, transaction.financial.currency)]}
                        >
                          <List.Item.Meta title={taxText} />
                        </List.Item>
                        <List.Item
                          actions={[formatPrice(transaction.financial.total / 100, transaction.financial.currency)]}
                        >
                          <List.Item.Meta title={totalText} />
                        </List.Item>
                      </Card>
                    </>
                  )}
                />
              </ListCollapseWrapper>
            </Collapse.Panel>
          ))}
        </Collapse>
        <Pagination
          current={pagination.currentPage}
          onChange={onPaginationChange}
          total={pagination?.totalEntries}
          showQuickJumper={false}
          showSizeChanger={false}
          showTitle={true}
          style={{ textAlign: 'center' }}
        />
      </CollapseWrapper>
    </Skeleton>
  )
}

export default TransactionCollapse

const disconnected = ({ endAt, timezone }: Transaction) =>
  endAt ? <DateTimeV2 date={endAt} timezone={timezone} isSidePanel /> : <NoDataBadge noBackground />

const chargingStart = ({ chargeStartAt, timezone }: Transaction) =>
  chargeStartAt ? (
    <DateTimeV2 date={moment(chargeStartAt)} timezone={timezone} isSidePanel />
  ) : (
    <NoDataBadge noBackground />
  )
