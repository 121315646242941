import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { useAppState } from '../../state'
import { UsersBasePage } from './users-page'
import { UsersRedesignBasePage } from './users-page-redesign'

export const UsersHomeBasePage: React.FC = () => {
  const { isUsersClassicView } = useAppState()

  return isUsersClassicView ? <UsersBasePage /> : <UsersRedesignBasePage />
}

export const UsersHomePage = withAuthenticatedLayout(UsersHomeBasePage)
