import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import {
  mockChargerDailyMetrics,
  mockConnChargerCounts,
  mockMeasurementsResp,
  mockWidgetTotal,
} from '../../mock/analytic-mock'
import {
  ChargerDailyMetric,
  ConnectorMetricCounts,
  Measurements,
  TransactionMetricResponse,
} from '../../models/analytics'
import { ConnectorRef } from '../../models/connector'
import { MeasurementsResponse } from '../../models/http'
import { Tenant, TenantRef } from '../../models/tenant'
import { TenantPermission } from '../../models/user'
import { apiClient, apiFleetClient } from './client'
import { returnMock } from './mock'

export function GetTransactionMetrics(params: {
  startDate: moment.Moment
  endDate: moment.Moment
  defaultTenant?: TenantPermission | undefined
  tenants?: TenantRef[]
  chargerId?: number
  connector?: ConnectorRef
  selectedCountries?: string[]
  selectedProvinces?: string[]
}): Promise<TransactionMetricResponse> {
  let url = '/transactions/metrics'

  const {
    startDate,
    endDate,
    defaultTenant,
    tenants,
    chargerId,
    connector,
    selectedCountries,
    selectedProvinces,
  } = params

  let queryParams: { [key: string]: string } = {
    start_date: `${startDate.format('DD-MM-YYYY')}`,
    end_date: `${endDate.format('DD-MM-YYYY')}`,
  }

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }
  if (chargerId) {
    queryParams['charger_id'] = `${chargerId}`
  }
  if (connector) {
    queryParams['connector_id'] = `${connector.id}`
  }

  if (selectedCountries && selectedCountries.length > 0) {
    queryParams['country'] = `${selectedCountries}`
  }

  if (selectedProvinces && selectedProvinces.length > 0) {
    queryParams['province'] = `${selectedProvinces}`
  }

  url = addQueryString(url, queryParams)
  log('getting transaction data', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockWidgetTotal)
  }
  return apiClient(url, {})
}

export async function GetConnectorCounts(
  defaultTenant?: TenantRef,
  tenants?: TenantRef[],
  selectedCountries?: string[],
  selectedProvinces?: string[],
): Promise<ConnectorMetricCounts> {
  let url = '/connectors/metrics'
  let queryParams: { [key: string]: string } = {}

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }
  if (selectedCountries && selectedCountries.length > 0) {
    queryParams['country'] = `${selectedCountries}`
  }

  if (selectedProvinces && selectedProvinces.length > 0) {
    queryParams['province'] = `${selectedProvinces}`
  }

  url = addQueryString(url, queryParams)

  log('getting aggregated status', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockConnChargerCounts)
  }
  return apiClient(url, {})
}

export async function GetChargerDailyMetrics(params: {
  startDate: moment.Moment
  endDate: moment.Moment
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  selectedCountries?: string[]
  selectedProvinces?: string[]
}): Promise<ChargerDailyMetric[]> {
  let url = '/chargers/metrics'

  let queryParams: { [key: string]: string } = {}

  const { startDate, endDate, defaultTenant, tenants, selectedCountries, selectedProvinces } = params

  if (defaultTenant) {
    // since hitting mongo we request tenant.name.... maybe we should resolve
    // the id on rails and be consistent
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  if (startDate) {
    queryParams['start_date'] = `${startDate.format('DD-MM-YYYY')}`
  }

  if (endDate) {
    queryParams['end_date'] = `${endDate.format('DD-MM-YYYY')}`
  }

  if (selectedCountries && selectedCountries.length > 0) {
    queryParams['country'] = `${selectedCountries}`
  }

  if (selectedProvinces && selectedProvinces.length > 0) {
    queryParams['province'] = `${selectedProvinces}`
  }

  url = addQueryString(url, queryParams)
  log('getting charger events metrics', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargerDailyMetrics)
  }

  return apiClient(url, {})
}

export async function GetMeasurements(tenant: Tenant): Promise<Measurements[]> {
  const url = `/tenants/${tenant.id}/get_data`

  log('getting eyedro measurement', { url: url })

  if (configProvider.config.mockEnabled) {
    return returnMock(mockMeasurementsResp)
  }

  return apiFleetClient<MeasurementsResponse>(url, {}).then((resp: MeasurementsResponse) => resp.data)
}
