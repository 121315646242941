import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { theme } from '../../theme'
import { TransactionMetric } from '../../models/analytics'
import React from 'react'
import { useFormatMessage } from '../../helpers/intl'

interface props {
  data: TransactionMetric[]
}

export const EnergyGraph: React.FC<props> = ({ data }) => (
  <ResponsiveContainer minHeight={500} minWidth={290}>
    <BarChart data={data} margin={{ left: 0, top: 0, bottom: 0 }}>
      <XAxis dataKey="humanizeTimestamp" stroke={theme.colors.yellow} fontSize={'0.75em'} />

      <YAxis
        yAxisId="left"
        orientation="left"
        stroke={theme.colors.yellow}
        unit=" kWh"
        allowDecimals={false}
        fontSize={'0.75em'}
      />

      <Tooltip
        formatter={(value: number) => {
          return `${value} kWh`
        }}
      />
      <Legend />

      <CartesianGrid stroke={theme.colors.ternary300} strokeDasharray="7 7" />
      <Bar
        name={useFormatMessage('dashboard.homePage.energy', 'Energy')}
        type="monotone"
        dataKey="energy"
        fill={theme.colors.yellow}
        yAxisId="left"
      />
    </BarChart>
  </ResponsiveContainer>
)
