import { ReportFilter } from '../../models/filter'

export const reportsQuery = (f: ReportFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: `${f.startDate.format('YYYY-MM-DD')}`,
    end_date: `${f.endDate.format('YYYY-MM-DD')}`,
  }

  if (f.tenant_id && f.tenant_id.length !== 0) {
    queryParams['tenant_id'] = `${f.tenant_id.join(',')}`
  }

  if (f.listing_title && f.listing_title.length !== 0) {
    queryParams['listing_title'] = `${f.listing_title.join(',')}`
  }

  if (f.selectedProvinces && f.selectedProvinces.length !== 0) {
    queryParams['province'] = `${f.selectedProvinces}`
  }

  if (f.selectedCountries && f.selectedCountries.length !== 0) {
    queryParams['country'] = `${f.selectedCountries}`
  }

  if (f.masterAccount) {
    queryParams['master_account_ids'] = f.masterAccount.id.toString()
  }

  return queryParams
}
