import { Button, Form } from 'antd'
import { UpdateListingWrapper } from 'atom/listings'
import { ObjectTagging } from 'components/tagging/object-taggings'
import { useTagging } from 'hooks/useTagging'
import { useEffect } from 'react'
import { tabLayout } from '../../atom/form/modal-layout'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { NewListing } from '../../models/listing'
import { useAppState } from '../../state'
import { AlertError } from '../error'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const UpdateListingTagging: React.FC<props> = ({ listings, onOk }) => {
  const { language } = useAppState()

  const {
    error,
    applyTagToObject,
    loading,
    getTaggingsWithoutPagination,
    taggings,
    setTaggings,
    dropdownRender,
  } = useTagging()

  const [form] = Form.useForm()

  const onFinish = async () => {
    applyTagToObject(
      listings.map((listing) => ({ id: listing.id })),
      'listings',
    )
    onOk()
  }

  useEffect(() => {
    getTaggingsWithoutPagination('Listing')
  }, [])

  const { applyText } = useGeneralTranslation()
  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    }
    return language === 'fr' ? frLayout : tabLayout
  }

  return (
    <>
      <AlertError error={error} />
      <Form
        form={form}
        {...chooseLayout()}
        onFinish={onFinish}
        labelCol={{ span: 7.5 }}
        labelWrap={true}
        labelAlign="left"
      >
        <UpdateListingWrapper>
          <ObjectTagging
            hideDivider
            taggings={taggings}
            setTaggings={setTaggings}
            objectIds={listings.map(({ id }) => ({ id }))}
            tableName="Listing"
            dropdownRender={dropdownRender}
          />
        </UpdateListingWrapper>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {applyText}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
