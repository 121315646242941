import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { InputRef, SelectProps } from 'antd'

import { renderFormatMessage } from '../../helpers/intl'
import { MasterAccountRef } from '../../models/master-account'
import { CreateMasterAccount, GetMasterAccounts } from '../../services/data-provider/master-account'

interface Props {
  masterAccount?: MasterAccountRef
  onOptionChange: (selectedMasterAccount?: MasterAccountRef) => void
  disabled: boolean
  hideAdd?: boolean
  placeholder?: string
  dropdownClassName?: string
}

export const MasterAccountSelector: React.FC<Props> = ({
  masterAccount,
  onOptionChange,
  disabled,
  hideAdd,
  placeholder,
  dropdownClassName,
}) => {
  const [masterAccounts, setMasterAccounts] = useState<MasterAccountRef[]>([])
  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    GetMasterAccounts().then((resp) => setMasterAccounts(resp))
  }, [])

  const handleSelectChange = (selectedParterName: string) => {
    const selectedAccount = masterAccounts.find((account) => account.name === selectedParterName)
    onOptionChange(selectedAccount)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateMasterAccount(name).then((newMasterAccount) => {
      setMasterAccounts((prev) => [newMasterAccount, ...prev])
      setName('')
      setTimeout(() => {
        inputRef.current?.focus()
      }, 500)
    })
  }

  const checkDuplicate = () => {
    return name.length === 0 || masterAccounts.some((account) => account.name?.toLowerCase() === name.toLowerCase())
  }

  const options: SelectProps['options'] = useMemo(
    () =>
      masterAccounts.map((account) => ({
        label: account.name,
        value: account.name,
        id: account.id,
      })),
    [masterAccounts],
  )

  const defaultValue = masterAccount ? masterAccount.name : undefined

  return (
    <Select
      dropdownClassName={dropdownClassName}
      showSearch
      disabled={disabled}
      value={defaultValue}
      placeholder={
        placeholder === 'Account Name'
          ? renderFormatMessage('dashboard.tenantPage.tenantTabOverview.accountName', placeholder)
          : 'Add new master account'
      }
      onChange={handleSelectChange}
      allowClear
      dropdownRender={(menu) => (
        <>
          {menu}
          {!hideAdd && (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <Space wrap style={{ padding: '0 8px 4px' }}>
                <Input placeholder="Enter new account" ref={inputRef} value={name} onChange={handleNameChange} />
                <Button type="primary" disabled={checkDuplicate()} icon={<PlusOutlined />} onClick={addItem}>
                  {renderFormatMessage('dashboard.text.add', 'Add')}
                </Button>
              </Space>
            </>
          )}
        </>
      )}
      options={options}
    />
  )
}
