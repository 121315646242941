import { InfoExclamation } from '../../assets/svg/info-excalamation'
import { theme } from '../../theme'

interface WarningInfoProps {
  message: string
}

const WarningInfo: React.FC<WarningInfoProps> = ({ message }) => {
  return (
    <div style={{ margin: '30px 110px 50px 110px' }}>
      <div style={{ textAlign: 'center' }}>{<InfoExclamation />}</div>
      <div
        className="paragraph-04-regular"
        style={{
          color: theme.colors.darkGray,
          textAlign: 'center',
        }}
      >
        {message}
      </div>
    </div>
  )
}

export default WarningInfo
