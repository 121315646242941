import { Form, FormInstance, Input, InputRef, Table } from 'antd'
import React, { useRef } from 'react'

type EditableTableProps = Parameters<typeof Table>[0]

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  dataIndex: keyof any
  record: any
  placeholder?: string
  isRequired?: boolean
  handleSave: (record: any) => void
}

interface Props {
  dataSource: any[]
  setDataSource: (val: any) => void
  columns: (ColumnTypes[number] & {
    editable?: boolean
    dataIndex: string
    placeholder?: string
    isRequired?: boolean
  })[]
  form: FormInstance // Accept form instance from parent
}

export const EditableTable: React.FC<Props> = ({ dataSource, setDataSource, columns, form }) => {
  const EditableContext = React.createContext<FormInstance<any> | null>(null)

  const EditableRow: React.FC<{ index: number }> = ({ index, ...props }) => {
    return (
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    )
  }

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    placeholder,
    isRequired,
    ...restProps
  }) => {
    const inputRef = useRef<InputRef>(null)

    const save = async () => {
      try {
        const values = await form.validateFields()
        handleSave({ ...record, ...values })
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }

    let childNode = children

    if (editable) {
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={[record.key, dataIndex]}
          initialValue={record[dataIndex]}
          wrapperCol={{ span: 24 }}
          rules={
            isRequired
              ? [
                  {
                    required: true,
                    message: `${title} is required.`,
                  },
                ]
              : undefined
          }
        >
          <Input placeholder={placeholder} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const handleSave = (row: any) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row[index],
    })
    setDataSource(newData)
  }

  const tableColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        placeholder: col.placeholder,
        isRequired: !!col.isRequired,
        handleSave,
      }),
    }
  })

  return (
    <Table
      pagination={false}
      components={components}
      dataSource={dataSource}
      columns={tableColumns as ColumnTypes}
      rowClassName={() => 'editable-row'}
    />
  )
}
