import { PricingResponse } from '../models/http'
import { PricingSchema } from '../models/price'

export const mockPricingSchema = [
  {
    id: 1,
    name: 'test pricing schema 1',
    tenantId: 43,
    state: 'active',
    priceType: 'Hour',
    flatFeeCents: 100,
    demandResponseFeeCents: 0,
    guestFeeCents: 50,
    commissionPercent: 10.0,
    paymentLimitCents: 10000,
    timeOfDayPriceWeekday: [
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
    ],
    timeOfDayPriceWeekend: [
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
    ],
    enableAddtionalServiceFee: false,
    enablePricingCurtailment: false,
    loiteringType: 'none',
    loiteringTimeLimitMinutes: 0,
    loiteringGracePeriodMinutes: 0,
    timeOfDayLoiteringWeekday: [
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
    ],
    timeOfDayLoiteringWeekend: [
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
      200,
    ],
    loiteringMaximumCents: 10000,
    appliedListings: [
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
    ],
  },
  {
    id: 2,
    name: 'Test',
    tenantId: 43,
    state: 'active',
    priceType: 'Hour',
    flatFeeCents: 0,
    demandResponseFeeCents: 0,
    guestFeeCents: 50,
    commissionPercent: 10.0,
    paymentLimitCents: 0,
    timeOfDayPriceWeekday: [
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
    ],
    timeOfDayPriceWeekend: [
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
      100,
    ],
    enableAddtionalServiceFee: false,
    enablePricingCurtailment: false,
    loiteringType: 'none',
    loiteringTimeLimitMinutes: 0,
    loiteringGracePeriodMinutes: 0,
    timeOfDayLoiteringWeekday: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    timeOfDayLoiteringWeekend: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    loiteringMaximumCents: 10000,
    appliedListings: [
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
    ],
  },
] as PricingSchema[]

export const mockPricingSchemaResp = (page: number) => {
  return {
    data: mockPricingSchema,
    pagination: {
      currentPage: page || 0,
      perPage: 6,
      totalEntries: mockPricingSchema.length,
    },
  } as PricingResponse
}

export const mockPricingSchemaNamesResp = () => {
  return ['test', 'testing']
}
