import React, { useState, useEffect } from 'react'
import { Dropdown, Pagination, Space, Table } from 'antd'
import { SwtchError } from '../../models/error'
import { Plan } from '../../models/plan'
import { GetPlans } from '../../services/data-provider/plan'
import { PaginationMeta } from '../../models/pagination'
import { AlertError } from '../error'
import { Subscription } from '../../models/subscription'
import { useAppState } from '../../state'
import { PlanMobileView } from './plan-mobile-view'
import { usePlanTranslation } from '../../hooks/translation/usePlanTranslation'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import ServiceStatusBadge from '../connectors/ServiceStatusBadge'
import { MoreButtonWrapper, MoreDropdownWrapper } from '../../atom/plans'
import { subscribeUserSVG } from '../../assets/svg/subscribeUser'
import { editGreySVG } from '../../assets/svg/edit'
import { archiveSVG } from '../../assets/svg/archive'
import { CreatePlanRedesignForm } from './form/create-plan-form-redesign'
import { UpdatePlanRedesignForm } from './form/update-plan-form-redesign'
import { DeletePlanRedesignModal } from './form/delete-plan-form-redesign'
import CreateSubscriptionRedesignForm from '../subscriptions/form/CreateSubscriptionFormRedesign'
import { TableStyling } from '../../atom/users-page-redesign-styles'

interface props {
  onCancel: () => void
  createPlanForm: boolean
}

export const PlansRedesign: React.FC<props> = ({ onCancel, createPlanForm }) => {
  const { IsMobile, IsTablet, IsDesktop, IsLaptop } = useAppState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState<SwtchError>()
  const [currentPage, setCurrentPage] = useState(1)
  const [togglePlan, setTogglePlan] = useState<Plan>()
  const [plans, setPlans] = useState<Plan[]>([])
  const [addUserToPlan, setAddUserToPlan] = useState<Plan>()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [verticalMoreButton, setVerticalMoreButton] = useState(false)

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const { subscribersText } = usePlanTranslation()
  const { descriptionText, priceText, titleText, statusText } = useGeneralTranslation()

  useEffect(() => {
    getPlans()
  }, [currentPage])

  const getPlans = () => {
    setLoading(true)
    GetPlans(currentPage)
      .then((resp) => {
        setPlans(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const subcribersCount = (p: Plan) => {
    if (p.subscriptionsCount === null || p.subscriptionsCount === 0) {
      return
    }
    return p.subscriptionsCount
  }

  const handlePlanUpdated = (modifiedPlan: Plan) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === modifiedPlan.id) {
        return modifiedPlan
      }
      return plan
    })
    setPlans(updatedPlans)
    setSelectedPlan(undefined)
  }

  const handlePlanCreated = (newPlan: Plan) => {
    setPlans([...plans, newPlan])
    onCancel()
  }

  const handleNewSubscription = (newSub: Subscription) => {
    const updatePlans = plans.map((plan) => {
      if (plan.id === newSub?.plan?.id) {
        return { ...newSub.plan, subscriptionsCount: plan.subscriptionsCount + 1 }
      }
      return plan
    })
    setPlans(updatePlans)
    setAddUserToPlan(undefined)
  }

  const onPageChange = (page: number) => setCurrentPage(page)

  const handleStatus = (plan: Plan) => <ServiceStatusBadge state={plan.status} />

  const handlePriceCents = (plan: Plan) => `$${(plan.priceCents / 100).toFixed(2)} / ${plan.interval}`

  const moreDropDown = (plan: Plan, index: number) => (
    <MoreDropdownWrapper
      subscribeUserDisabled={plan.status === 'active' ? false : true}
      editPlanDisabled={plan.status === 'active' ? false : true}
    >
      <div
        className="dropdown-list-item subscribe-user"
        onClick={(e: any) => {
          if (plan.status === 'active' ? true : false) setAddUserToPlan(plan)
        }}
      >
        {subscribeUserSVG} Subscribe User
      </div>
      <div
        className="dropdown-list-item edit-plan"
        onClick={(e: any) => {
          if (plan.status === 'active' ? true : false) setSelectedPlan(plan)
        }}
      >
        {editGreySVG} Edit Plan
      </div>
      <div className="last-item" onClick={(e: any) => setTogglePlan(plan)}>
        {archiveSVG} Archive Plan
      </div>
    </MoreDropdownWrapper>
  )

  const columns = [
    { title: titleText, dataIndex: 'name' },
    { title: descriptionText, dataIndex: 'description' },
    {
      title: subscribersText,
      key: 'subscribers',
      render: (plan: Plan) => subcribersCount(plan),
    },
    {
      title: statusText,
      key: 'status',
      render: (plan: Plan) => handleStatus(plan),
    },
    {
      title: priceText,
      key: 'priceCents',
      render: (plan: Plan) => handlePriceCents(plan),
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: '',
      key: 'charger_action',
      align: 'right' as 'right',
      render: (plan: Plan, record: any, index: number) => {
        return (
          <Space>
            <MoreButtonWrapper vertical={verticalMoreButton}>
              <Dropdown.Button
                onVisibleChange={(e) => {
                  if (e === true) setVerticalMoreButton(true)
                  if (e === false) setVerticalMoreButton(false)
                }}
                className="opened"
                trigger={['click']}
                overlay={moreDropDown(plan, index)}
              ></Dropdown.Button>
            </MoreButtonWrapper>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <AlertError error={error} />
      {(isDesktop || isLaptop) && (
        <TableStyling>
          <Table
            loading={loading}
            columns={columns}
            dataSource={plans}
            rowKey={(plan: Plan): string => `${plan.id}`}
            pagination={{
              position: ['bottomCenter'],
              total: pagination?.totalEntries,
              current: pagination?.currentPage,
              pageSize: pagination?.perPage,
              showSizeChanger: false,
              onChange: onPageChange,
            }}
          />
        </TableStyling>
      )}
      {(isMobile || isTablet) && (
        <>
          <PlanMobileView
            plans={plans}
            loading={loading}
            onAddUserToPlan={(plan) => setAddUserToPlan(plan)}
            onEditPlan={(plan) => setSelectedPlan(plan)}
            onTogglePlan={(plan) => setTogglePlan(plan)}
          />
          <br />
          <Pagination
            current={currentPage}
            onChange={onPageChange}
            total={pagination?.totalEntries}
            showQuickJumper={false}
            showSizeChanger={false}
            showTitle={true}
            style={{ textAlign: 'center' }}
          />
        </>
      )}
      {addUserToPlan && (
        <CreateSubscriptionRedesignForm
          onCancel={() => setAddUserToPlan(undefined)}
          defaultPlan={addUserToPlan}
          onSubscribe={handleNewSubscription}
        />
      )}
      {selectedPlan && (
        <UpdatePlanRedesignForm
          plan={selectedPlan}
          onCancel={() => setSelectedPlan(undefined)}
          onUpdatedPlan={(p) => handlePlanUpdated(p)}
        />
      )}
      {togglePlan && (
        <DeletePlanRedesignModal
          plan={togglePlan}
          onCancel={() => setTogglePlan(undefined)}
          onTogglePlan={(p) => {
            handlePlanUpdated(p)
            setTogglePlan(undefined)
          }}
        />
      )}
      {createPlanForm && <CreatePlanRedesignForm onCancel={onCancel} onCreatedPlan={(p) => handlePlanCreated(p)} />}
    </>
  )
}
