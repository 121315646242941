import { Badge } from 'antd'

import { connectorServiceStatus, connectorStatus } from '../../models/connector'
import { theme } from '../../theme'
import { useFormatMessage } from '../../helpers/intl'
import { serviceStatus } from '../../models/charger'
import { renderConnectionMessage } from '../../helpers/status'

export const allStatus = [...connectorStatus, ...serviceStatus, ...connectorServiceStatus, 'Commissioning'] as const
export type AllStatus = typeof allStatus[number]
interface props {
  state: AllStatus
}

export const ChargerConnectorStateBadge: React.FC<props> = ({ state }) => {
  const [status, backgroundColor, fontColor] = GetConnectorState(state)
  const translatedStatus = useFormatMessage(renderConnectionMessage(state), status)
  return (
    <Badge
      count={translatedStatus}
      style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
    />
  )
}

// Returns the a tupple, where the first argument in the Humanize state and the second is the css class
export const GetConnectorState = (connectorStatus: AllStatus): string[] => {
  switch (connectorStatus) {
    case 'Unavailable':
      return [connectorStatus, theme.colors.goldenHoneycomb, theme.colors.goldenOlive]
    case 'Faulted':
    case 'under_repair':
    case 'Offline':
    case 'troubleshooting':
      return [connectorStatus, theme.colors.underRepair, theme.colors.dangerPrimary]
    case 'Charging':
    case 'awaiting_commissioning':
      return [connectorStatus, theme.colors.skyBlue15, theme.colors.carribeanCurrent]
    case 'Available':
    case 'service_mode':
    case 'active':
      return [connectorStatus, theme.colors.magicMint, theme.colors.viridian]
    case 'SuspendedEV':
    case 'SuspendedEVSE':
      return [connectorStatus, theme.colors.whisperingLavender, theme.colors.electricIndigo]
    case 'Preparing':
    case 'Finishing':
    case 'activating':
    case 'property_activating':
    case 'driver_activating':
    case 'commissioning':
    case 'Commissioned':
    case 'commissioned':
      return [connectorStatus, theme.colors.orangePeel15, theme.colors.warningPrimary]
    case 'in_maintenance':
    case 'on_hold':
    case 'other':
    case 'decommissioned':
    case 'Terminated':
    case 'terminated':
      return [connectorStatus, theme.colors.black05, theme.colors.black]
    default:
      return ['Unknown', theme.colors.grey14, theme.colors.black]
  }
}
