import { CoreInfoCard } from 'components/cards'
import { ConnectorPluggedInIcon } from '../../../../assets/svg/connectorPluggedIn'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { ChargerInfo } from '../../../../models/chargerv2'
import { HomePageConnectorsPluggedInChart } from '../../charts/active-users/connectors-plugged-in'
import configProvider from 'config'

interface props {
  chargerInfo: ChargerInfo | undefined
  wsLoading: boolean
}

const ChargersPluggedInCard: React.FC<props> = ({ chargerInfo, wsLoading }) => {
  const { chargersPluggedInText, levelTwoConnectorsText, dcFastConnectorsText } = useDashboardTranslation()
  const infoDetails = [
    {
      label: levelTwoConnectorsText,
      subLabel: chargerInfo?.connectors.pluggedInLevel2,
    },
    ...(configProvider.config.network === 'itsElectric'
      ? []
      : [
          {
            label: dcFastConnectorsText,
            subLabel: chargerInfo?.connectors.pluggedInDc,
          },
        ]),
  ]

  return (
    <CoreInfoCard
      title={chargersPluggedInText}
      wsLoading={wsLoading}
      infoText={chargerInfo && chargerInfo.connectors && chargerInfo.connectors.pluggedIn}
      chargerInfoChart={chargerInfo && <HomePageConnectorsPluggedInChart chargerInfo={chargerInfo} />}
      overviewIcon={<ConnectorPluggedInIcon />}
      infoDetails={chargerInfo && chargerInfo.connectors && infoDetails}
    />
  )
}

export default ChargersPluggedInCard
