import { theme } from '../../theme'

export const dollarSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM6.5 12V14H9V16H11V14H12C12.663 14 13.2989 13.7366 13.7678 13.2678C14.2366 12.7989 14.5 12.163 14.5 11.5C14.5 10.837 14.2366 10.2011 13.7678 9.73223C13.2989 9.26339 12.663 9 12 9H8C7.86739 9 7.74021 8.94732 7.64645 8.85355C7.55268 8.75979 7.5 8.63261 7.5 8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74021 8.05268 7.86739 8 8 8H13.5V6H11V4H9V6H8C7.33696 6 6.70107 6.26339 6.23223 6.73223C5.76339 7.20107 5.5 7.83696 5.5 8.5C5.5 9.16304 5.76339 9.79893 6.23223 10.2678C6.70107 10.7366 7.33696 11 8 11H12C12.1326 11 12.2598 11.0527 12.3536 11.1464C12.4473 11.2402 12.5 11.3674 12.5 11.5C12.5 11.6326 12.4473 11.7598 12.3536 11.8536C12.2598 11.9473 12.1326 12 12 12H6.5Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const dollar24x24SVG = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM7.8 14.4V16.8H10.8V19.2H13.2V16.8H14.4C15.1957 16.8 15.9587 16.4839 16.5213 15.9213C17.0839 15.3587 17.4 14.5956 17.4 13.8C17.4 13.0044 17.0839 12.2413 16.5213 11.6787C15.9587 11.1161 15.1957 10.8 14.4 10.8H9.6C9.44087 10.8 9.28826 10.7368 9.17574 10.6243C9.06321 10.5117 9 10.3591 9 10.2C9 10.0409 9.06321 9.88826 9.17574 9.77574C9.28826 9.66321 9.44087 9.6 9.6 9.6H16.2V7.2H13.2V4.8H10.8V7.2H9.6C8.80435 7.2 8.04129 7.51607 7.47868 8.07868C6.91607 8.64129 6.6 9.40435 6.6 10.2C6.6 10.9957 6.91607 11.7587 7.47868 12.3213C8.04129 12.8839 8.80435 13.2 9.6 13.2H14.4C14.5591 13.2 14.7117 13.2632 14.8243 13.3757C14.9368 13.4883 15 13.6409 15 13.8C15 13.9591 14.9368 14.1117 14.8243 14.2243C14.7117 14.3368 14.5591 14.4 14.4 14.4H7.8Z"
      fill={theme.colors.primary}
    />
  </svg>
)
