import { theme } from '../../theme'

export const questionMarkSVG = (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 34C7.6109 34 0 26.3891 0 17C0 7.6109 7.6109 0 17 0C26.3891 0 34 7.6109 34 17C34 26.3891 26.3891 34 17 34ZM15.3 22.1V25.5H18.7V22.1H15.3ZM18.7 19.3035C20.0662 18.8917 21.2391 18.0029 22.0051 16.799C22.771 15.595 23.079 14.156 22.873 12.744C22.6671 11.332 21.9608 10.0409 20.8828 9.10598C19.8049 8.17101 18.4269 7.65431 17 7.65C15.6244 7.6499 14.2913 8.12626 13.2273 8.99809C12.1634 9.86992 11.4342 11.0834 11.1639 12.4321L14.4993 13.1002C14.594 12.6266 14.8211 12.1897 15.1544 11.8402C15.4877 11.4907 15.9134 11.243 16.3819 11.126C16.8505 11.009 17.3426 11.0274 17.8011 11.1791C18.2596 11.3309 18.6655 11.6097 18.9717 11.9831C19.2779 12.3566 19.4718 12.8093 19.5308 13.2886C19.5897 13.768 19.5114 14.2542 19.3048 14.6907C19.0982 15.1272 18.772 15.4961 18.364 15.7545C17.9559 16.0128 17.4829 16.15 17 16.15C16.5491 16.15 16.1167 16.3291 15.7979 16.6479C15.4791 16.9667 15.3 17.3991 15.3 17.85V20.4H18.7V19.3035Z"
      fill={theme.colors.lightGray}
    />
  </svg>
)
