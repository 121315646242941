import moment from 'moment'

export const humanizeTimestamp = (timestampStr: string | null, disconnectedText: string) => {
  if (timestampStr === '') {
    return
  }
  const timestamp = moment(timestampStr)

  if (!timestamp.isValid()) {
    return
  }
  const howLongAgo = timestamp.fromNow()
  const time = timestamp.format('hh:mm a')
  const translatedText = disconnectedText.replace('-1', howLongAgo).replace('-2', time)

  return `${translatedText}`
}
