import { useState } from 'react'
import { Divider, Form, FormInstance, Switch } from 'antd'
import { useFormatMessage } from 'helpers/intl'
import { useListingCommissionFeeTranslation } from 'hooks/translation/useListingCommissionFeeTrans'

interface props {
  form: FormInstance<any>
}

export const ListingLoadManagement: React.FC<props> = ({ form }) => {
  const [addCloudLoadManagement, setAddCloudLoadManagement] = useState(form.getFieldValue('addCloudLoadManagement'))
  const [enableLoadManagementAutoStop, setEnableLoadManagementAutoStop] = useState(
    form.getFieldValue('enableLoadManagementAutoStop'),
  )
  const { enableLoadMgmtAutoStopText, loadManagementText } = useListingCommissionFeeTranslation()

  return (
    <>
      <Divider>{loadManagementText}</Divider>
      <Form.Item
        name="addCloudLoadManagement"
        label={useFormatMessage(
          'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt',
          'Allow Cloud Load Management',
        )}
      >
        <Switch
          style={{ float: 'left' }}
          onChange={() => {
            setAddCloudLoadManagement(!addCloudLoadManagement)
            form.setFieldsValue({ addCloudLoadManagement: !addCloudLoadManagement })
          }}
          checked={addCloudLoadManagement}
        />
      </Form.Item>

      <Form.Item name="enableLoadManagementAutoStop" label={enableLoadMgmtAutoStopText}>
        <Switch
          style={{ float: 'left' }}
          onChange={() => {
            setEnableLoadManagementAutoStop(!enableLoadManagementAutoStop)
            form.setFieldsValue({ enableLoadManagementAutoStop: !enableLoadManagementAutoStop })
          }}
          checked={enableLoadManagementAutoStop}
        />
      </Form.Item>
    </>
  )
}
