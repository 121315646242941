import { renderFormatMessage } from '../../helpers/intl'
import { capitalizeFirstLetter } from '../../helpers/users'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const postalCodeRegex = '([A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9])'

export const MustBeRequired = (message?: string | JSX.Element, max?: number) => {
  return { required: true, message, max }
}

export const MustBeNumerical = { pattern: new RegExp('^([-]?[1-9][0-9]*|0)$'), message: 'Must be a number' }

export const MustBePositiveIntegers = {
  min: 0,
  message: 'Please enter a positive number',
  pattern: new RegExp('^[0-9]*$'),
}

export const MustBeOnlyLetters = { pattern: new RegExp('^[a-zA-Z ]*$'), message: 'Must be only letter' }

export const MustBeIpAddress = (message?: string) => {
  return { pattern: new RegExp('^[0-9]+.[0-9]+.[0-9]+.[0-9]?[0-9]?[0-9]$'), message }
}

export const MustHaveNAmountOfNum = (subject: string, num: number) => {
  return {
    pattern: new RegExp('^[0-9]{4,}$'),
    message: `${capitalizeFirstLetter(subject)} must have ${num} or more numbers`,
  }
}

export const MustBeHTTP = {
  pattern: new RegExp('^((https?)://)(www.)?[a-z0-9]+.[a-z]+(.[a-zA-Z]?)+(.com)+(/[a-zA-Z0-9#]+/?)*$'),
  message: renderFormatMessage('dashboard.loadManagementTab.rule.http', 'Must be either an HTTPS or HTTP protocol'),
}
// https://stackoverflow.com/questions/42618872/regex-for-website-or-url-validation

export const MustBePostalCode = {
  pattern: new RegExp(`^(${postalCodeRegex}|${zipCodeRegex})$`),
  message: 'Must be a valid postal code/zip code',
}


export const MustBePhoneNumber = {
  pattern: new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'),
  message: 'Enter a 11 digit phone number',
}

export const MustBeAnEmail = (message?: string | JSX.Element) => {
  return {
    pattern: new RegExp('/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/'),
    message,
  }
}

export const MustMaxOut = (message?: string) => {
  return {
    required: false,
    max: 255,
    message,
  }
}

// Reference
// https://ihateregex.io/expr/phone/
