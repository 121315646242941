import { styled } from '../theme'

export const TagSelectorWrapper = styled.div`
  .ant-select-single .ant-select-selection-placeholder {
    position: relative;
    align-self: center;
    top: 0px !important;
    left: 0px !important;
  }

  .ant-form-item-label > label {
    height: 40px;
  }

  .ant-row {
    display: flex;
    align-items: start;
  }

  .ant-tag {
    padding: 0 7px;
  }
`
