import { Form, Input, Modal, Select, message } from 'antd'
import React, { ReactNode, useState } from 'react'
import { AlertErrorCompact } from '../../components/error-compact'
import { useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { TenantPermissionType, User, UserPermissions, UserRef } from '../../models/user'
import { NewInviteUser, SearchEmail } from '../../services/data-provider/users'

const { Option } = Select

interface props {
  selectedTenants?: TenantRef[]
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserInvited: (user: User) => void
  renderModalTitle: () => ReactNode
}

export const UserInvitation: React.FC<props> = ({ selectedTenants, onCancel, onUserInvited, renderModalTitle }) => {
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchError>()
  const emailText = useFormatMessage('dashboard.usersPage.inviteUser.email.heading', 'Email')
  const firstNameText = useFormatMessage('dashboard.usersPage.inviteUser.firstName.heading', 'First Name')
  const lastNameText = useFormatMessage('dashboard.usersPage.inviteUser.lastName.heading', 'Last Name')
  const roleText = useFormatMessage('dashboard.usersPage.inviteUser.role.heading', 'Role')
  const emailWarningText = useFormatMessage('dashboard.usersPage.inviteUser.email.warning', 'Email is Required!')
  const firstNameWarningText = useFormatMessage(
    'dashboard.usersPage.inviteUser.firstName.warning',
    'First Name is Required!',
  )
  const lastNameWarningText = useFormatMessage(
    'dashboard.usersPage.inviteUser.lastName.warning',
    'Last Name is Required!',
  )

  const roleWarningText = useFormatMessage(
    'dashboard.usersPage.inviteUser.lastName.warning',
    'A role must be selected!',
  )

  const emailNotValidText = useFormatMessage(
    'dashboard.usersPage.inviteUser.email.notValid.warning',
    'Email is not a valid email!',
  )

  const tenantWarning = useFormatMessage(
    'dashboard.usersPage.inviteUser.email.notValid.warning',
    'A tenant must be selected to process',
  )

  const managerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.manager', 'Manager')
  const viewerText = useFormatMessage('dashboard.usersPage.table.nestedTable.accessLevel.viewer', 'Viewer')

  const okText = useFormatMessage('dashboard.usersPage.inviteUser.oktext', 'Ok')
  const cancelText = useFormatMessage('dashboard.usersPage.inviteUser.cancelText', 'Cancel')

  const afterClose = () => {
    form.resetFields()
  }

  const onOk = () => {
    setError(undefined)
    if (!selectedTenants) {
      setError(new SwtchError(tenantWarning))
      return
    }
    form
      .validateFields()
      .then((values: { email: string; role: TenantPermissionType; firstName: string; lastName: string }) => {
        const { email, role, firstName, lastName } = values
        const name = `${firstName.trim()} ${lastName.trim()}`
        const tenantsArr: UserPermissions[] = []
        selectedTenants.map((tenant) => {
          return tenantsArr.push({ id: tenant.id, name: tenant.name, access: role, driver: false })
        })

        SearchEmail(email).then((resp) => {
          let id = ''
          if (resp.email_exist && resp.person_id) {
            id = resp.person_id
            const user: UserRef[] = [
              {
                id,
                email,
                name,
              },
            ]
            NewInviteUser(tenantsArr, user)
              .then((user) => {
                form.resetFields()
                message.success(`Invited user`)
                return onUserInvited(user)
              })
              .catch((error) => setError(error))
          } else {
            message.error(`Please enter a valid SWTCH email address`)
          }
        })
      })
      .catch((error) => setError(error))
  }

  const validateMessages = {
    types: {
      email: emailNotValidText,
    },
  }

  return (
    <Modal
      title={renderModalTitle()}
      closable={false}
      okText={okText}
      cancelText={cancelText}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      afterClose={afterClose}
    >
      <AlertErrorCompact error={error} />

      <Form
        form={form}
        initialValues={{ name: '', email: '', role: '' }}
        validateMessages={validateMessages}
        labelAlign="right"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item
          key="email"
          label={emailText}
          name="email"
          rules={[{ required: true, message: emailWarningText }, { type: 'email' }]}
        >
          <Input placeholder={emailText} type="text" />
        </Form.Item>
        <Form.Item
          key="firstName"
          label={firstNameText}
          name="firstName"
          rules={[{ required: true, type: 'string', message: firstNameWarningText }]}
        >
          <Input placeholder={firstNameText} type="text" />
        </Form.Item>
        <Form.Item
          key="lastName"
          label={lastNameText}
          name="lastName"
          rules={[{ required: true, type: 'string', message: lastNameWarningText }]}
        >
          <Input placeholder={lastNameText} type="text" />
        </Form.Item>
        <Form.Item key="role" label={roleText} name="role" rules={[{ required: true, message: roleWarningText }]}>
          <Select allowClear>
            <Option key="manager" value="manager">
              {managerText}
            </Option>
            <Option key="viewer" value="viewer">
              {viewerText}
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
