import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockPlanById, mockPlans, mockPlansResp } from '../../mock/plan-mock'
import { PlanResponse, ToggleActivePlanResponse } from '../../models/http'
import { Plan } from '../../models/plan'
import { apiClient } from './client'
import { returnMock } from './mock'

export async function GetPlans(page: number): Promise<PlanResponse> {
  const url = `/plans?page=${page}`

  log('getting plans', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockPlansResp(page))
  }
  return apiClient(url, {})
}

export async function CreatePlan(
  name: string,
  description: string,
  priceCents: number,
  interval: string,
  currency: string,
  country: string,
): Promise<Plan> {
  const url = '/plans'

  log('creating a plan', { url: url })
  if (configProvider.config.mockEnabled) {
    const mockCreatePlan = {
      id: Math.floor(Math.random() * 100),
      name,
      priceCents,
      priceCurrency: currency,
      interval,
      description,
      status: 'active', // 1 === active in the backend
      createdAt: '2021-03-01T23:59:59.000',
      updatedAt: '2021-01-01T00:00:00.000',
      currency,
      subscriptionsCount: 0,
      country: 'CA',
    } as Plan
    return returnMock(mockCreatePlan)
  }
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      plan: {
        name,
        description,
        interval,
        currency,
        price_cents: priceCents,
        country,
      },
    }),
  })
}

export async function UpdatePlan(plan: Plan, name: string, description: string): Promise<Plan> {
  const url = `/plans/${plan.id}`
  log(`updating ${plan.name} plan`, { url: url })

  if (configProvider.config.mockEnabled) {
    let editedMockPlan = mockPlanById[plan.id]
    editedMockPlan.name = name

    editedMockPlan.description = description
    return returnMock(editedMockPlan)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      name,
      description,
    }),
  })
}

export async function DeletePlan(plan: Plan): Promise<string> {
  const url = `/plans/${plan.id}`

  log(`deleting ${plan.name}`)
  if (configProvider.config.mockEnabled) {
    return returnMock(`${plan.name} has been deleted!`)
  }

  return apiClient<{ response: string }>(url, {
    method: 'DELETE',
  }).then((resp) => resp.response)
}

export async function ToggleActivePlan(plan: Plan): Promise<ToggleActivePlanResponse> {
  const url = `/plans/${plan.id}/toggle_active`

  log(`toggle ${plan.name}`)
  if (configProvider.config.mockEnabled) {
    return returnMock({ data: plan, message: 'Success' } as ToggleActivePlanResponse)
  }

  return apiClient(url, { method: 'PUT' })
}

export async function FindPlan(term?: string): Promise<Plan[]> {
  let url = '/plans/search'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return returnMock(mockPlans)
  }

  return apiClient(url, {})
}
