export const RemoteRestartChargerPayload = [
  {
    success: true,
    message: 'Accepted',
    chargerId: 68,
    serialNumber: '5SW2-1-2141-00091',
  },
  {
    success: true,
    message: 'Accepted',
    chargerId: 67,
    serialNumber: '1EC1-1-1824-00035',
  },
  {
    success: false,
    message: 'Cannot reset because the charger is not connected to the Central System, please try again.',
    chargerId: 66,
    serialNumber: '1920511',
  },
]

export const RemoteClearCachePayload = [
  {
    success: true,
    message: 'Accepted',
    chargerId: 68,
    serialNumber: '5SW2-1-2141-00091',
  },
  {
    success: true,
    message: 'Accepted',
    chargerId: 67,
    serialNumber: '1EC1-1-1824-00035',
  },
  {
    success: false,
    message: 'Cannot clear cache! Charger is not connected to the Central System, please try again',
    chargerId: 66,
    serialNumber: '1920511',
  },
]

export const RemoteGetConfigPayload = [
  3,
  'yfzt95qqi1czvsbusaub4',
  {
    configurationKey: [
      { key: 'AllowOfflineTxForUnknownId', readonly: false, value: 'FALSE' },
      { key: 'AuthorizationCacheEnabled', readonly: false, value: 'FALSE' },
      { key: 'AuthorizeRemoteTxRequests', readonly: true, value: 'FALSE' },
      { key: 'BlinkRepeat', readonly: false, value: '0' },
      { key: 'ClockAlignedDataInterval', readonly: false, value: '0' },
      { key: 'ConnectionTimeOut', readonly: false, value: '100' },
      { key: 'GetConfigurationMaxKeys', readonly: true, value: '49' },
      { key: 'HeartbeatInterval', readonly: false, value: '3600' },
      { key: 'LightIntensity', readonly: false, value: '0' },
      { key: 'LocalAuthorizeOffline', readonly: true, value: 'TRUE' },
      { key: 'LocalPreAuthorize', readonly: true, value: 'FALSE' },
      { key: 'MaxEnergyOnInvalidId', readonly: false, value: '0' },
      {
        key: 'MeterValuesAlignedData"',
        readonly: false,
        value: 'Current.Import,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Active.Import,Voltage',
      },
      {
        key: 'StopTxnSampledData',
        readonly: true,
        value: 'Current.Import,Energy.Active.Import.Interval,Power.Active.Import,Voltage',
      },
    ],
  },
]

export const RemoteMultiChargerTrigMessPayload = [
  {
    chargerId: 52,
    serialNumber: 'OCPIT2',
    connectorId: 1,
    success: true,
    message: [3, 'yfzt95qqi1czvsbusaub4', { status: 'Accepted' }],
  },
  {
    chargerId: 52,
    serialNumber: 'OCPIT2',
    connectorId: 2,
    success: true,
    message: [3, 'yfzt95qqi1czvsbusaub4', { status: 'Accepted' }],
  },
  {
    chargerId: 53,
    serialNumber: 'chademotest',
    connectorId: 1,
    success: false,
    message: {
      error: 'Cannot trigger message! Charger is not connected to the Central System, please try again.',
    },
  },
]

export const RemoteUpdateFirmwarePayload = [
  {
    success: true,
    message: 'Accepted',
    chargerId: 29,
    serialNumber: '1EC3-1-1917-00010',
  },
  {
    success: false,
    message: 'Cannot change configuration! Charger is not connected to the Central System, please try again.',
    chargerId: 35,
    serialNumber: '1EC4-1-2001-00002',
  },
  {
    success: false,
    message: 'Cannot change configuration! Charger is not connected to the Central System, please try again.',
    chargerId: 17,
    serialNumber: 'JDC181100007WD',
  },
]

export const RemoteUpdateConfigPayload = [
  {
    success: true,
    message: 'Accepted',
    chargerId: 29,
    serialNumber: '1EC3-1-1917-00010',
  },
  {
    success: false,
    message: 'Cannot change configuration! Charger is not connected to the Central System, please try again.',
    chargerId: 35,
    serialNumber: '1EC4-1-2001-00002',
  },
  {
    success: false,
    message: 'Cannot change configuration! Charger is not connected to the Central System, please try again.',
    chargerId: 17,
    serialNumber: 'JDC181100007WD',
  },
]
