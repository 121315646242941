import { CoreInfoCard } from 'components/cards'
import { ConnectorAvailabilityIcon } from '../../../../assets/svg/connectorAvailability'
import { useDashboardTranslation } from '../../../../hooks/translation/useDashboardTranslation'
import { ChargerInfo, ConnectorInfo } from '../../../../models/chargerv2'
import { HomePageChargersAvailbality } from '../../charts/active-users/chargers-availability'
import configProvider from 'config'

interface props {
  chargerInfo: ChargerInfo | undefined
  connectorInfo: ConnectorInfo | undefined
  wsLoading: boolean
}

const ChargersAvailabilityCard: React.FC<props> = ({ connectorInfo, chargerInfo, wsLoading }) => {
  const { chargersAvailabilityText, levelTwoConnectorsText, dcFastConnectorsText } = useDashboardTranslation()
  const infoDetails = [
    {
      label: levelTwoConnectorsText,
      subLabel: connectorInfo?.availableLevel2,
    },
    ...(configProvider.config.network === 'itsElectric'
      ? []
      : [
          {
            label: dcFastConnectorsText,
            subLabel: connectorInfo?.availableDc,
          },
        ]),
  ]
  return (
    <CoreInfoCard
      title={chargersAvailabilityText}
      wsLoading={wsLoading}
      infoText={connectorInfo && `${connectorInfo.available}/${connectorInfo.totalConnectors}`}
      chargerInfoChart={chargerInfo && <HomePageChargersAvailbality chargerInfo={chargerInfo} />}
      overviewIcon={<ConnectorAvailabilityIcon />}
      infoDetails={chargerInfo && chargerInfo.users && infoDetails}
    />
  )
}

export default ChargersAvailabilityCard
