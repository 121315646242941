import { styled } from '../../theme'

export const ListWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0; /* Adjust the top value to control when the List component becomes sticky */

  // for the list header
  .ant-list-header {
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    padding: 14px 16px 10px 16px;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
  }

  // for the list item
  .ant-spin-nested-loading > .ant-spin-container > .ant-list-items > .ant-list-item {
    padding: 8px 16px 6px 16px;
  }

  // for the list item where we don't want to show the bottom border
  .ant-list-item.hideBorderBottom {
    border-bottom: none;
  }

  // for title on the left of the list
  .ant-list-item > .ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-title {
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 13px;
    margin-bottom: 0;
  }

  // for value on the far right of the list
  .ant-list-item > .ant-list-item-action {
    font-family: ${(props) => props.theme.fontFamily.regularFontFamily};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 13px;
    color: ${(props) => props.theme.colors.darkGray};
  }

  // for the close icon
  .ant-list-header > .ant-row > .ant-col.closeIcon {
    cursor: pointer;
    margin-right: 5px;
  }

  // wrap long text
  .wrap-text {
    max-width: 200px;
    word-wrap: break-word;
    text-align: right;
  }
`

export const ListCardWrapper = styled.div`
  padding: 0 12px;
  .ant-card > .ant-card-body > .ant-list-item {
    padding: 6px 0;
  }
`

export const ListCollapseWrapper = styled.div`
  .ant-list-item > .ant-list-item-meta > .ant-list-item-meta-content > .ant-list-item-meta-title {
    margin-bottom: 0;
  }
`
