import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { StripeAccount } from '../../models/stripe'
import { log } from '../../logger'

import { apiClient } from './client'
import { returnMock } from './mock'
import { mockStripeAccounts } from '../../mock/stripe-account-mock'

export async function GetStripeAccounts(authorId?: string, tenantId?: string): Promise<StripeAccount[]> {
  let url = '/stripe_accounts'

  let queryParams: { [key: string]: string } = {}

  if (tenantId) {
    queryParams['tenant_id'] = `${tenantId}`
  }

  if (authorId) {
    queryParams['author_id'] = authorId
  }

  if (!authorId) {
    queryParams['author_id'] = 'ZHJGhjH9RgKk0JhpVLUfow'
  }

  url = addQueryString(url, queryParams)

  log('get stripe accounts', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockStripeAccounts)
  }
  return apiClient(url, {})
}

export async function GetAllStripeAccounts(): Promise<StripeAccount[]> {
  let url = '/stripe_accounts/all'

  log('get stripe accounts', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockStripeAccounts)
  }
  return apiClient(url, {})
}
