import { useState } from 'react'

import { Drawer, Layout, Row } from 'antd'

import { useNetwork } from '../../../context/network'

import { useAppState } from '../../../state'
import { styled, theme } from '../../../theme'
import NavigationOption from './navigationOption'

const { Sider } = Layout
const NAVIGATION_COLLAPSE_SIZE = 70

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`
interface props {
  collapsed?: boolean
  skeleton?: boolean
  handleToggleMenu(): void
}

interface IProps {
  collapsed: boolean
  isDesktop: boolean
}

const NavigationMenuWrapper = styled.div<IProps>`
  ${({ isDesktop, collapsed }) =>
    !isDesktop &&
    !collapsed &&
    `
    position: fixed;
    z-index: 1000;
    `}

  .swtch-icon {
    margin: 2.1rem 2.28rem auto 2.6rem;
  }

  svg:not(:root) {
    overflow: visible;
  }

  .svg-clipboard {
    margin-left: ${(props) => (props.collapsed ? '0' : '-3px')};
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed li.ant-menu-item {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: 0 auto 0.3rem;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }

  li.ant-menu-item:not(.ant-menu-item-selected) svg {
    path,
    circle,
    ellipse {
      opacity: 0.5;
    }
  }

  li.ant-menu-item:not(.ant-menu-item-selected) svg {
    .clipboardSVG,
    path,
    circle,
    ellipse {
      fill: ${(props) => props.theme.colors.iconNotHovering};
    }
  }

  li.ant-menu-item.ant-menu-item-active svg {
    .clipboardSVG,
    path,
    circle,
    ellipse {
      fill: ${(props) => props.theme.colors.iconHovering};
    }
  }

  .ant-menu.ant-menu-dark {
    background-color: ${(props) => props.theme.colors.secondary};
  }

  .ant-menu-dark .ant-menu-item > span > a {
    color: ${(props) => props.theme.colors.fontNotSelected};
  }

  .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: ${(props) => props.theme.colors.menuItemHover};
    .ant-menu-title-content {
      a {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${(props) => props.theme.colors.menuItemSelected};
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-title-content,
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark .ant-menu-title-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.colors.fontSelected};
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark
    li.ant-menu-item:last-child.ant-menu-item-only-child
    .ant-menu-title-content {
    justify-content: end;
  }

  .ant-menu-title-content a {
    margin-left: 10px;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .ant-menu-item a {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .ant-menu-item.ant-menu-item-selected svg {
    .clipboardSVG,
    path,
    circle,
    ellipse {
      fill: ${(props) => props.theme.colors.fontSelected} !important;
    }
  }

  .ant-menu-item.ant-menu-item-selected svg {
    .clipboardSVG-no-style {
      fill: ${(props) => props.theme.colors.softBlack};
    }
  }

  .ant-tooltip-inner > svg {
    display: none !important;
  }

  .toggleNavigation {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    background-color: ${(props) => props.theme.colors.secondary};
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .toggleNavigation.expanded {
    right: 0;
  }

  .toggleNavigation svg {
    transform: translateX(-50%);
    margin: 2rem auto;
    fill: ${(props) => props.theme.colors.multiArrow};
    path {
      fill: ${(props) => props.theme.colors.multiArrow};
    }
  }

  .toggleNavigation.expanded svg {
    position: absolute;
    bottom: 0;
    right: 2.9rem;
    transform: rotate(180deg) translateY(-25%);
  }

  .ant-menu-item {
    border-radius: 46px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    width: 92.4%;
    margin: 4px auto;
    padding-left: 16.5px !important;
  }

  .ant-menu-dark .ant-menu-item-selected > span > a {
    color: ${(props) => props.theme.colors.fontSelected};
  }

  .toggleNavigation::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ffff;
    left: 0;
    bottom: 5.7rem;
    position: absolute;
    display: block;
    opacity: 0.3;
  }

  .ant-menu.ant-menu-root {
    padding: 0;
    margin: 4.1rem 1rem auto 1rem;
    height: 100%;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: none;
  }

  .ant-menu-submenu li[aria-disabled='true'] .ant-menu-title-content::after {
    right: -0.5px;
    top: 17px;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub .mobile-language {
    width: 100%;
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark
    li.ant-menu-item:last-child.ant-menu-item-only-child.mobile-language
    .ant-menu-title-content {
    justify-content: start;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected.mobile-language {
    background-color: transparent;
  }
`

export const Navigation: React.FC<props> = ({ skeleton, handleToggleMenu }) => {
  const { IsMobile, IsDesktop } = useAppState()
  const network = useNetwork()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()

  const [collapsed, setCollapsed] = useState(true)

  return (
    <NavigationMenuWrapper className="tesr" collapsed={collapsed} isDesktop={isDesktop}>
      {
        <Sider
          collapsed={collapsed}
          collapsedWidth={isMobile && collapsed ? 60 : NAVIGATION_COLLAPSE_SIZE}
          style={{ backgroundColor: theme.colors.secondary }}
          width={250}
        >
          <Wrapper>
            {collapsed && network.icon}
            {!collapsed && (
              <Row justify="start" style={{ width: '100%' }}>
                {network.logo}
              </Row>
            )}

            <NavigationOption collapsed={collapsed} onCollapsed={() => setCollapsed(!collapsed)} />
          </Wrapper>
        </Sider>
      }
      {isMobile && (
        <Drawer
          closable={false}
          placement="left"
          // visible={collapsed}
          onClose={handleToggleMenu}
          width="54vw"
          drawerStyle={{ backgroundColor: theme.colors.secondary }}
        >
          <p>{network.logo}</p>
          <NavigationOption collapsed={collapsed} onCollapsed={() => setCollapsed(!collapsed)} />
        </Drawer>
      )}
    </NavigationMenuWrapper>
  )
}
