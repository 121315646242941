import { styled } from '../theme'
import React from 'react'
import { Spin } from 'antd'
import { SpinSize } from 'antd/es/spin'

const Wrapper = styled.div`
  margin: auto auto;
  text-align: center;
  padding-top: 60px;
`

interface props {
  size?: SpinSize
}

export const Loading: React.FC<props> = ({ size }) => {
  return (
    <Wrapper>
      <Spin size={size || 'large'} />
    </Wrapper>
  )
}
