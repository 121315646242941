import { Alert, Button, Col, Row, Spin } from 'antd'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'

import { useFormatMessage } from '../../helpers/intl'

import { SwtchError } from '../../models/error'
import { ChargerRef } from '../../models/charger'
import { RemotePayloadResponse } from '../../models/charger-remote'

import { RemoteRestartCharger } from '../../services/data-provider/charger-remote'

interface props {
  chargers: ChargerRef[]
}

export const RemoteRestartChargerController: React.FC<props> = ({ chargers }) => {
  const filterChargers = chargers.filter((v, i) => {
    return chargers.map((val) => val.id).indexOf(v.id) === i
  })

  const [loading, setLoading] = useState(false)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)

  const [message, setMessage] = useState<RemotePayloadResponse[]>([])

  const [error, setError] = useState<SwtchError[]>([])
  const [errorMessage, setErrorMessasge] = useState<RemotePayloadResponse[]>([])

  const restartChargerText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1',
    'Click below to restart the',
  )

  const chargerText = useFormatMessage('dashboard.chargerPage.heading', 'Charger').toLowerCase()

  const restartBtnText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn',
    'Restart',
  )

  const chargerHardResetRequestText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest',
    'Sending Hard Reset request for',
  )

  useEffect(() => {
    setLoading(false)
    setMessage([])
    setError([])
  }, [])

  const handleRestart = () => {
    setLoading(true)

    setError([])
    setMessage([])
    setPassed(0)
    setFailed(0)
    RemoteRestartCharger(filterChargers.map((f) => f.id))
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        resp.payload.map((r: RemotePayloadResponse) => {
          return r.success ? setMessage((message) => [...message, r]) : setErrorMessasge((error) => [...error, r])
        })
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  const renderMessages = () => {
    return (
      <>
        {passed >= 1 &&
          message.map((res, index) => (
            <Alert key={index} message={`${res.serialNumber}`} description={res.message} type="info" />
          ))}
        {failed >= 1 &&
          errorMessage.map((err, index) => (
            <Alert key={index} message={err.serialNumber} description={err.message} type="error" />
          ))}
        {failed >= 1 &&
          error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
      </>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <p>
          {restartChargerText} {filterChargers.length === 1 ? chargerText : pluralize(chargerText)}
        </p>
        <Button onClick={handleRestart} loading={loading}>
          {restartBtnText}
        </Button>
      </Col>
      <Col span={24} style={{ paddingTop: '10px' }}>
        {loading && (
          <Alert
            message={
              <div>
                <Spin style={{ marginRight: '10px' }} />
                {`${chargerHardResetRequestText} ${filterChargers.length > 1 ? filterChargers.length : ''} ${pluralize(
                  `charger${
                    filterChargers.length === 1 ? filterChargers.map((c) => ` ${c.chargePointSerialNumber}`) : ''
                  }`,
                  filterChargers.length || 1,
                )}!`}
              </div>
            }
          />
        )}
        {!loading && renderMessages()}
      </Col>
    </Row>
  )
}
