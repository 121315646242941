export const swtchLogoStyle = {
  margin: '2px 13px',
  filter: 'invert(1)',
  width: '75%',
}

export const swtchIconStyle = {
  margin: '5px 20px',
  width: '53%',
  filter: 'invert(1)',
}

export const powerChargeLogoStyle = {
  width: '90%',
  margin: '5px 15px',
}

export const powerChargeIconStyle = {
  width: '50%',
  margin: '5px 20px',
}

export const evzoneLogoStyle = {
  width: '75%',
  margin: '2px 13px',
}

export const evzoneIconStyle = {
  width: '53%',
  margin: '5px 20px',
}

export const itsElectricIconStyle = {
  width: '50%',
  margin: '5px 20px',
}

export const itsElectricLogoStyle = {
  width: '137px',
  margin: '25px',
}

export const swtchInfo = {
  logoAlt: 'SWTCH Energy Logo',
  iconAlt: 'SWTCH Energy Icon',
  name: 'swtch',
  title: 'Swtch',
  iconStyle: swtchIconStyle,
  logoStyle: swtchLogoStyle,
}

export const powerChargeInfo = {
  logoAlt: 'PowerCharge banner',
  iconAlt: 'PowerCharge icon',
  name: 'powercharge',
  title: 'PowerCharge',
  iconStyle: powerChargeIconStyle,
  logoStyle: powerChargeLogoStyle,
}

export const evzoneInfo = {
  logoAlt: 'EVZONE logo',
  iconAlt: 'EVZONE icon',
  name: 'evzone',
  title: 'EV ZONE',
  iconStyle: evzoneIconStyle,
  logoStyle: evzoneLogoStyle,
}

export const itsElectricInfo = {
  logoAlt: "It's Electric logo",
  iconAlt: "It's Electric icon",
  name: 'itselectric',
  title: "It's Electric",
  iconStyle: itsElectricIconStyle,
  logoStyle: itsElectricLogoStyle,
}

export const clientInfo = {
  swtch: swtchInfo,
  powercharge: powerChargeInfo,
  evzone: evzoneInfo,
  itsElectric: itsElectricInfo,
}
