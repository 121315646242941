import { Skeleton, Table } from 'antd'
import moment from 'moment'

import { useAppState } from '../../../state'
import { ChargerDailyMetric } from '../../../models/analytics'
import { DisconnectionsListView } from '../../../components/analytics/disconnections-list-view'
import { renderFormatMessage } from '../../../helpers/intl'
import { formatDuration } from '../../../helpers/duration'

const columns = [
  {
    title: renderFormatMessage('dashboard.disconnectionpage.table.heading.avgDisconnectDuration', 'Tenant'),
    render: (m: ChargerDailyMetric) => {
      return m.tenant_display_name || m.tenant
    },
  },
  {
    title: renderFormatMessage(
      'dashboard.disconnectionpage.table.heading.chargerSerialNumber',
      'Charger Serial Number',
    ),
    dataIndex: 'charger_serial_number',
  },
  {
    title: renderFormatMessage('dashboard.disconnectionpage.table.heading.date', 'Date'),
    dataIndex: 'from',
  },
  {
    title: renderFormatMessage('dashboard.disconnectionpage.table.heading.avgDailyDisconnect', 'Avg Daily Disconnect'),
    key: 'disconnect_daily_avg',
    render: (m: ChargerDailyMetric) => formatDuration(moment.duration(m.disconnect_daily_avg)),
    sorter: (a: ChargerDailyMetric, b: ChargerDailyMetric) => a.disconnect_daily_avg - b.disconnect_daily_avg,
  },
  {
    title: renderFormatMessage('dashboard.disconnectionpage.table.heading.totalDisconnect', 'Total Disconnect'),
    dataIndex: 'disconnect_total',
    sorter: (a: ChargerDailyMetric, b: ChargerDailyMetric) => a.disconnect_total - b.disconnect_total,
  },
  {
    title: renderFormatMessage('dashboard.disconnectionpage.table.heading.disconnectHigh', 'Disconnect High'),
    key: 'disconnect_high',
    render: (m: ChargerDailyMetric) => formatDuration(moment.duration(m.disconnect_high)),
    sorter: (a: ChargerDailyMetric, b: ChargerDailyMetric) => a.disconnect_high - b.disconnect_high,
  },
  {
    title: renderFormatMessage(
      'dashboard.disconnectionPage.table.heading.avgDisconnectDuration',
      'Avg Disconnect Duration',
    ),
    key: 'avg_disconnect_duration',
    render: (m: ChargerDailyMetric) => formatDuration(moment.duration(m.avg_disconnect_duration)),
    sorter: (a: ChargerDailyMetric, b: ChargerDailyMetric) => a.avg_disconnect_duration - b.avg_disconnect_duration,
  },
]

interface props {
  loading: boolean
  dataSource: ChargerDailyMetric[]
}

export const TableView: React.FC<props> = ({ loading, dataSource }) => {
  const { IsMobile, IsDesktop, IsLaptop, IsTablet } = useAppState()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  return (
    <>
      {(isDesktop || isLaptop) && (
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          pagination={{ position: ['bottomCenter'] }}
        />
      )}
      {(isMobile || isTablet) && (
        <Skeleton loading={loading}>
          <DisconnectionsListView metrics={dataSource} />
        </Skeleton>
      )}
    </>
  )
}
