import React from 'react'

function makeCancelable<T>(worker: Promise<T>, id?: string): CancelablePromise<T> {
  let hasCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    worker.then(
      (val: T) => {
        return hasCanceled ? reject({ isCanceled: true }) : resolve(val)
      },
      (error: Error) => {
        return hasCanceled ? reject({ isCanceled: true }) : reject(error)
      },
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}

/**
 * Usage:
 *
 * ```
 * <AsyncComponent
 *   id="id-for-logging"
 *   worker={() => fetch('https://google.com')
 *   onLoading={onLoading}
 *   onSuccess={onSuccess}
 *   onError={onError}
 * />
 * ```
 *
 * Where the `onLoading`, `onSuccess`, `onError` are either callbacks
 * (respectively `() => React.ReactNode`, `(result: T) => React.ReactNode` and
 * `(error: E) => React.ReactNode`).
 *
 * Or plain `React.ReactNode` element. If there are not defined at all, they yield
 * `null`.
 *
 * The `AsyncComponent` on mount will execute `worker` props and wait for it's
 * completion, wrapping the returned `Promise<T>` into a cancellable promise.
 * When the component unmounts, the `Promise<T>` is canceled.
 *
 * At this level, cancelation has the only effect of rejecting the original
 * promise with a special `{ hasCanceled: true }` error. It does **NOT** cripple
 * down to cancelling actual work unit (like cancelling a `fetch` call).
 */
interface Props<T, E = any> {
  id?: string
  worker: () => Promise<T>
  onLoading?: React.ReactNode | LoadingCallback
  onSuccess?: React.ReactNode | SuccessCallback<T>
  onError?: React.ReactNode | ErrorCallback<E>
}

type LoadingCallback = () => React.ReactNode
type SuccessCallback<T> = (result: T) => React.ReactNode
type ErrorCallback<E> = (error?: E) => React.ReactNode

interface State<T, E = any> {
  success?: boolean
  result?: T
  error?: E
}

export class AsyncComponent<T, E = any> extends React.Component<Props<T, E>, State<T, E>> {
  cancelablePromise?: CancelablePromise<T>
  state: State<T, E> = {}

  async componentDidMount() {
    const { id } = this.props
    this.cancelablePromise = makeCancelable(this.props.worker(), id)
    try {
      const result = await this.cancelablePromise.promise
      this.setState({ result, success: true })
    } catch (error) {
      if (error === undefined || error.isCanceled !== true) {
        this.setState({ error, success: false })
      }
    }
  }

  componentWillUnmount() {
    if (this.cancelablePromise) {
      this.cancelablePromise.cancel()
      this.cancelablePromise = undefined
    }
  }

  render() {
    const { onSuccess, onError, onLoading } = this.props

    if (this.state.success === true) {
      return typeof onSuccess === 'function' ? (onSuccess as SuccessCallback<T>)(this.state.result!) : onSuccess || null
    }

    if (this.state.success === false) {
      return typeof onError === 'function' ? (onError as ErrorCallback<E>)(this.state.error!) : onError || null
    }

    return typeof onLoading === 'function' ? (onLoading as LoadingCallback)() : onLoading || null
  }
}

interface CancelablePromise<T> {
  promise: Promise<T>

  cancel(): void
}
