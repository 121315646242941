import { useEffect } from 'react'
import { Form, Modal } from 'antd'
import { Tenant } from '../../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../../atom/price'
import { PricingSchemaPayload } from '../../../../models/price'
import { InfoSectionModal } from './info-section'
import { NewDiscountSchemaSectionModal } from './new-discount-schema-section'
import { useDiscountSchemas } from 'hooks/useDiscountSchemas'
import { DiscountSchema } from 'models/discount'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'

interface props {
  tenant: Tenant
  selectedDiscountSchema: DiscountSchema
  selectedPricingSchema: PricingSchemaPayload | undefined
  discounts: DiscountSchema[]
  discountId: number
  onCancel: () => void
  onOk: () => void
}

const EditDiscountSchemaModal: React.FC<props> = ({
  tenant,
  selectedDiscountSchema,
  selectedPricingSchema,
  discounts,
  discountId,
  onCancel,
  onOk,
}) => {
  const [form] = Form.useForm()
  const {
    loading,
    initialValues,
    discountNameExists,
    promoCodeExists,
    loiteringType,
    timeOfDay,
    eventSchedules,
    timeOfDayLoitering,
    basePricingPlanRate,
    basePricingPlanLoiteringRate,
    weekdayRates,
    weekendRates,
    weekdayLoiteringRates,
    weekendLoiteringRates,
    setLoiteringType,
    setTimeOfDay,
    handleSelect,
    setPricePerUnitLoitering,
    setTimeOfDayLoitering,
    convertRate,
    validateInputChange,
    getDiscountNames,
    nameCheck,
    onFinish,
    calculateDollarRateLimit,
  } = useDiscountSchemas(
    tenant,
    onOk,
    onCancel,
    selectedPricingSchema,
    discounts,
    selectedPricingSchema?.applied_listings,
    form,
    'edit',
    selectedDiscountSchema,
  )
  const { discountPlanModalTitleText } = useDiscountSchemaTranslation()

  useEffect(() => {
    return () => {}
  }, [])

  const afterClose = () => form.resetFields()

  return (
    <Modal
      title={<h5 className="heading-05-regular">{discountPlanModalTitleText}</h5>}
      visible={true}
      onCancel={onCancel}
      width={1034}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={null}
    >
      <CreatePricingModalWrapper>
        <Form
          colon={false}
          form={form}
          layout="horizontal"
          requiredMark={true}
          labelAlign="left"
          initialValues={{
            ...initialValues,
          }}
          onFinish={(values) => {
            onFinish(values, 'editDiscountSchema', discountId)
          }}
          labelWrap={true}
        >
          <InfoSectionModal
            tenant={tenant}
            listings={selectedPricingSchema?.applied_listings}
            pricingSchemaValues={selectedPricingSchema}
          />
          <NewDiscountSchemaSectionModal
            initialValues={initialValues}
            tenant={tenant}
            form={form}
            discountNameExists={discountNameExists}
            promoCodeExists={promoCodeExists}
            pricingSchemaValues={selectedPricingSchema}
            timeOfDay={timeOfDay}
            eventSchedules={eventSchedules}
            timeOfDayLoitering={timeOfDayLoitering}
            loiteringType={loiteringType}
            modalType="edit"
            basePricingPlanRate={basePricingPlanRate}
            basePricingPlanLoiteringRate={basePricingPlanLoiteringRate}
            weekdayRates={weekdayRates}
            weekendRates={weekendRates}
            weekdayLoiteringRates={weekdayLoiteringRates}
            weekendLoiteringRates={weekendLoiteringRates}
            nameCheck={nameCheck}
            getDiscountNames={getDiscountNames}
            setAllListings={() => {}}
            onCancel={onCancel}
            setTimeOfDay={setTimeOfDay}
            setLoiteringType={setLoiteringType}
            setPricePerUnitLoitering={setPricePerUnitLoitering}
            setTimeOfDayLoitering={setTimeOfDayLoitering}
            handleSelect={handleSelect}
            convertRate={convertRate}
            validateInputChange={validateInputChange}
            calculateDollarRateLimit={calculateDollarRateLimit}
          />
        </Form>
      </CreatePricingModalWrapper>
    </Modal>
  )
}

export default EditDiscountSchemaModal
