import { Form, Spin } from 'antd'

import { Box } from 'atom/box'
import { InputWrapper } from 'atom/chargers'
import { layout } from 'atom/form/page-layout'

import { Tenant } from 'models/tenant'

import TenantFooter from 'features/tenants/TenantTabOverview/TenantFooter'
import { useEffect, useState } from 'react'
import { useTagging } from 'hooks/useTagging'
import { ObjectTagging } from 'components/tagging/object-taggings'

interface tenantTabDetailProps {
  tenant: Tenant
}

export const TenantTabTagging: React.FC<tenantTabDetailProps> = ({ tenant }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const {
    applyTagToObject,
    loading,
    getTaggingsWithoutPagination,
    taggings,
    setTaggings,
    dropdownRender,
  } = useTagging()

  const [form] = Form.useForm()
  const [disabled, setDisabled] = useState(true)

  const onFinish = async () => {
    applyTagToObject([{ id: parseFloat(tenant?.id) }], 'tenants').then(() => {
      setDisabled(true)
    })
  }

  useEffect(() => {
    getTaggingsWithoutPagination('Tenant')
  }, [])

  return (
    <Spin spinning={loading}>
      <Box padding>
        <Form {...layout} form={form} name={`tenant-tagging-${tenant?.id}`} onFinish={onFinish}>
          <InputWrapper>
            <ObjectTagging
              hideDivider
              disabled={disabled}
              taggings={taggings}
              setTaggings={setTaggings}
              objectIds={[{ id: parseFloat(tenant?.id) }]}
              tableName="Tenant"
              dropdownRender={dropdownRender}
            />
            <TenantFooter form={form} disabled={disabled} setDisabled={setDisabled} />
          </InputWrapper>
        </Form>
      </Box>
    </Spin>
  )
}
