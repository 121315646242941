import { theme } from '../../theme'

export const peakShavingIcon = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9 8.1V3.6L5.4 9.9H8.1V14.4L12.6 8.1H9.9ZM9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18Z"
      fill={theme.colors.white}
    />
  </svg>
)
