import { useFormatMessage } from '../../helpers/intl'

export const useTaggingTranslation = () => {
  const taggingText = useFormatMessage('dashboard.tagging', 'Tagging')
  const createTagText = useFormatMessage('dashboard.tagging.createTag', 'Create Tag')
  const idText = useFormatMessage('dashboard.tagging.table.id', 'ID')
  const nameText = useFormatMessage('dashboard.tagging.table.name', 'Name')
  const objectText = useFormatMessage('dashboard.tagging.table.object', 'Object')
  const statusText = useFormatMessage('dashboard.tagging.table.status', 'Statut')
  const typeText = useFormatMessage('dashboard.tagging.table.type', 'Type')
  const createdAtText = useFormatMessage('dashboard.tagging.table.createdAt', 'Created At')
  const createdByText = useFormatMessage('dashboard.tagging.table.createdBy', 'Created By')
  const noteText = useFormatMessage('dashboard.tagging.table.note', 'Note')
  const editText = useFormatMessage('dashboard.tagging.table.edit', 'Edit')
  const inactivateText = useFormatMessage('dashboard.tagging.table.inactivate', 'Inactivate')

  const editTagText = useFormatMessage('dashboard.tagging.modal.editTag', 'Edit Tag')
  const activeText = useFormatMessage('dashboard.tagging.modal.active', 'Active')
  const inactiveText = useFormatMessage('dashboard.tagging.modal.inactive', 'Inactive')
  const systemText = useFormatMessage('dashboard.tagging.modal.system', 'System')
  const adminText = useFormatMessage('dashboard.tagging.modal.admin', 'Admin')
  const customerText = useFormatMessage('dashboard.tagging.modal.customer', 'Customer')

  //Table name
  const listingText = useFormatMessage('dashboard.text.listing', 'Listing')
  const tenantText = useFormatMessage('dashboard.text.tenant', 'Site')

  const isRequiredText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.nameRequiredWarningText',
    '-1 is required!',
  )

  const cannotExceedText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.cannotExceed',
    '-1 cannot exceed 255 characters!',
  )

  const createSuccessText = useFormatMessage(
    'dashboard.tagging.modal.createSuccess',
    'Tag -1 has been created successfully',
  )
  const editSuccessText = useFormatMessage('dashboard.tagging.modal.editSuccess', 'Tag has been updated successfully')

  const cancelText = useFormatMessage('dashboard.text.cancel', 'Cancel')
  const submitText = useFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')

  const addNewTagText = useFormatMessage('dashboard.tagging.addNewTag', 'Add New Tag')

  return {
    taggingText,
    addNewTagText,
    createTagText,
    idText,
    nameText,
    objectText,
    statusText,
    typeText,
    createdAtText,
    createdByText,
    noteText,
    editText,
    inactivateText,

    editTagText,
    activeText,
    inactiveText,
    systemText,
    adminText,
    customerText,

    listingText,
    tenantText,

    isRequiredText,
    cannotExceedText,

    createSuccessText,
    editSuccessText,
    cancelText,
    submitText,
  }
}
