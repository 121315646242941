import { httpClient } from './client'
import configProvider from '../../config'

interface Address {
  numberAndStreet?: string
  city?: string
  province?: string
  postalCode?: string
  country?: string
}
export type SwtchAddress = Address

interface GoogleGeocodeLatLng {
  lat: number
  lng: number
}

interface GoogleGeocodeGeometry {
  location: GoogleGeocodeLatLng
}

interface GoogleGeocodeAddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface GoogleGeocodeResult {
  geometry: GoogleGeocodeGeometry
  address_components: GoogleGeocodeAddressComponent[]
}

interface GoogleGeocodeResponse {
  results: GoogleGeocodeResult[]
  status: string
  error_message?: string
}

const googleGeocodeBaseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'

export type GeocodingSuccess = {
  found: true
  lat: number
  lng: number
  province?: string
  country?: string
}

export type GeocodingFailure = {
  found: false
  code: string
  message: string
}

export type GeocodingResult = GeocodingSuccess | GeocodingFailure

const geocode = async (address: SwtchAddress): Promise<GeocodingResult> => {
  const addressComponents: Array<string | undefined> = [
    address.numberAndStreet,
    address.city,
    address.province,
    address.postalCode,
    address.country,
  ]
  const urlEncodedComponents = addressComponents.map((c) => (c ? encodeURIComponent(c) : '')).join(',+')
  const requestUrl = `${googleGeocodeBaseUrl}?address=${urlEncodedComponents}&key=${configProvider.config.googleMapsApiKey}`

  return await callGeocodeUrl(requestUrl)
}

const reverseGeocode = async (latlng: { lat: number; lng: number }): Promise<GeocodingResult> => {
  return await callGeocodeUrl(
    `${googleGeocodeBaseUrl}?latlng=${latlng.lat},${latlng.lng}&key=${configProvider.config.googleMapsApiKey}`,
  )
}

const callGeocodeUrl = async (requestUrl: string): Promise<GeocodingResult> => {
  const res = await httpClient<GoogleGeocodeResponse>(requestUrl, {})
  if (res.results.length === 0) {
    return {
      found: false,
      code: res.status,
      message: res.error_message || 'Google says no',
    }
  } else {
    const latlng = res.results[0].geometry.location
    return {
      found: true,
      ...latlng,
      province: res.results[0].address_components.find((ac) => ac.types.includes('administrative_area_level_1'))
        ?.short_name,
      country: res.results[0].address_components.find((ac) => ac.types.includes('country'))?.short_name,
    }
  }
}

export { geocode, reverseGeocode }
