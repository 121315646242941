import { useState } from 'react'
import { AutoComplete, Input, message, Spin, Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { SelectProps } from 'antd/es/select'

import { SwtchError } from '../../models/error'
import { UserWallet } from '../../models/user'
import { NoSearchIcon } from '../../atom/search-icon'
import { SearchUsers } from '../../services/data-provider/users'
import { useFormatMessage } from '../../helpers/intl'
import { debounce } from 'lodash'

const { Search } = Input

interface props {
  onUserSelected: (user: UserWallet) => void
  onClear: () => void
  country?: string
}

const renderUser = (user: UserWallet): any => {
  return {
    value: user.id,
    user,
    label: (
      <div>
        <UserOutlined /> {user.email || user.name}
      </div>
    ),
  }
}

export const UserSelector: React.FC<props> = ({ onUserSelected, onClear, country }) => {
  const [selected, setSelected] = useState<UserWallet>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectProps<object>['options']>([])

  const searchEmailTxt = useFormatMessage('dashboard.placeholder.searchEmail', 'Search Email')

  const searchResult = (value: string) => {
    setLoading(true)
    SearchUsers(value, country)
      .then((users) => {
        const opts = users.map((user) => renderUser(user))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelected(opt.user)
        onUserSelected(opt.user)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt.user.email !== null && opt.user.email === selected?.email) {
        setSelected(opt.user)
        onUserSelected(opt.user)
      }
    })
  }

  return (
    <>
      {!selected && (
        <AutoComplete
          dropdownMatchSelectWidth={false}
          options={options}
          onSelect={onSelect}
          onSearch={debounce(searchResult, 800)}
          notFoundContent={loading ? <Spin size="small" /> : null}
        >
          <NoSearchIcon>
            <Search placeholder={searchEmailTxt} onSearch={handleOnSearch} />
          </NoSearchIcon>
        </AutoComplete>
      )}
      {selected && (
        <>
        <Tag
          closable={false}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {selected.email || selected.name}
          
        </Tag>
        
        </>
      )}
    </>
  )
}
