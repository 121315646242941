import { Button, Modal, Space } from 'antd'

import { closeSVG } from '../../../assets/svg/close'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { HeadingWrapper, TextBodyWrapper } from '../../../atom/plans'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
}

export const RemoveScheduleModal: React.FC<props> = ({ visible, onCancel, onSubmit }) => {
  const { cancelText, removeScheduleText, removeScheduleWarningText } = usePeakShavingTranslation()
  return (
    <Modal
      visible={visible}
      title={<HeadingWrapper>{removeScheduleText}</HeadingWrapper>}
      footer={
        <Space>
          <Button onClick={onCancel} type="ghost">
            {cancelText}
          </Button>
          <Button danger onClick={onSubmit}>
            {removeScheduleText}
          </Button>
        </Space>
      }
      onCancel={onCancel}
      closeIcon={closeSVG}
    >
      <TextBodyWrapper>{removeScheduleWarningText}</TextBodyWrapper>
    </Modal>
  )
}
