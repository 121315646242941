import { useEffect, useState } from 'react'
import { timeConvert } from '../../../helpers/duration'
import { formatCentsPrice, formatPrice } from '../../../helpers/number-format'
import { PricingSchema } from '../../../models/price'
import { useAppState } from '../../../state'
import { usePricingSchemas } from 'hooks/usePricingSchemas'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import TimeOfDayModal from 'pages/listing/modal/TimeOfDayModal'

interface props {
  price: PricingSchema
}

const PricingSchemaLoiteringStatus: React.FC<props> = ({ price }) => {
  const [mode, setMode] = useState<string | undefined>()
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const { getMode } = usePricingSchemas()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const { timeOfDayText } = usePricingSchemasTranslation()

  const [showTimeOfDay, setShowTimeOfDay] = useState(false)

  const showTimeOfDayModal = () => setShowTimeOfDay(true)

  const handleModal = () => setShowTimeOfDay(false)

  const { timeOfDayLoiteringWeekday, timeOfDayLoiteringWeekend } = price

  useEffect(() => {
    setMode(getMode([...price.timeOfDayLoiteringWeekday, ...price.timeOfDayLoiteringWeekend]))
  }, [price])

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const renderTimeBased = (
    price: PricingSchema,
    isSmallScreen: string,
    isLargeScreen: string,
    mode: string | undefined,
  ) => {
    const isTODPriceWeekdaySame = timeOfDayLoiteringWeekday?.every((value) => value === timeOfDayLoiteringWeekday[0])
    const isTODPriceWeekendSame = timeOfDayLoiteringWeekend?.every((value) => value === timeOfDayLoiteringWeekend[0])

    const samePenaltyAllday =
      ((isTODPriceWeekdaySame === undefined && isTODPriceWeekendSame === undefined) ||
        (isTODPriceWeekdaySame && isTODPriceWeekendSame)) &&
      timeOfDayLoiteringWeekday?.[0] === timeOfDayLoiteringWeekend?.[0]

    return (
      <div className={isLargeScreen}>
        <div
          onClick={!samePenaltyAllday ? showTimeOfDayModal : undefined}
          className={`${isSmallScreen} ${!samePenaltyAllday ? 'pointer underline' : ''}`}
        >
          {samePenaltyAllday
            ? `${
                timeOfDayLoiteringWeekday?.[0]
                  ? formatCentsPrice(timeOfDayLoiteringWeekday[0])
                  : formatPrice(Number(mode) / 100)
              }/hr`
            : timeOfDayText}
        </div>
        {renderTimeLimitIdling(price)}
      </div>
    )
  }

  const renderStatusBased = (
    price: PricingSchema,
    isSmallScreen: string,
    isLargeScreen: string,
    mode: string | undefined,
  ) => {
    const isTODPriceWeekdaySame = timeOfDayLoiteringWeekday?.every((value) => value === timeOfDayLoiteringWeekday[0])
    const isTODPriceWeekendSame = timeOfDayLoiteringWeekend?.every((value) => value === timeOfDayLoiteringWeekend[0])

    const samePenaltyAllday =
      ((isTODPriceWeekdaySame === undefined && isTODPriceWeekendSame === undefined) ||
        (isTODPriceWeekdaySame && isTODPriceWeekendSame)) &&
      timeOfDayLoiteringWeekday?.[0] === timeOfDayLoiteringWeekend?.[0]

    return samePenaltyAllday ? (
      <div className={isLargeScreen}>
        {renderRate(isSmallScreen, price, mode)}
        {renderGracePeriodCharging(price)}
      </div>
    ) : (
      <div className={isLargeScreen}>
        <div onClick={showTimeOfDayModal} className={`${isSmallScreen} pointer underline`}>
          {timeOfDayText}
        </div>
        {renderGracePeriodCharging(price)}
      </div>
    )
  }

  const renderRate = (isSmallScreen: string, price: PricingSchema, mode: string | undefined) => {
    return <div className={isSmallScreen}>{`${formatPrice(Number(mode) / 100)}/hr`}</div>
  }

  const renderTimeLimitIdling = (price: PricingSchema) => (
    <div className="opacity-06 paragraph-04-light">
      {price.loiteringTimeLimitMinutes ? `After ${timeConvert(price.loiteringTimeLimitMinutes)} of charging` : ''}
    </div>
  )

  const renderGracePeriodCharging = (price: PricingSchema) => (
    <div className="opacity-06 paragraph-04-light">
      {price.loiteringGracePeriodMinutes ? `After ${timeConvert(price.loiteringGracePeriodMinutes)} of idling` : ''}
    </div>
  )

  return (
    <>
      {showTimeOfDay && timeOfDayLoiteringWeekend && timeOfDayLoiteringWeekday && (
        <TimeOfDayModal
          title={price.name}
          priceType={'hr'}
          loitering
          isModalOpen={showTimeOfDay}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={timeOfDayLoiteringWeekend}
          timeOfDayPriceWeekday={timeOfDayLoiteringWeekday}
        />
      )}
      {price.loiteringType === 'none' && 'No Penalty'}
      {price.loiteringType === 'time_based' && renderTimeBased(price, isSmallScreen, isLargeScreen, mode)}
      {price.loiteringType === 'status_based' && renderStatusBased(price, isSmallScreen, isLargeScreen, mode)}
    </>
  )
}

export default PricingSchemaLoiteringStatus
