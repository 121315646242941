import { useEffect, useState } from 'react'

import { SwtchError } from 'models/error'
import { TenantFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'
import { TenantRef, TenantStatus, tenantStatusesDefault } from 'models/tenant'

import { GetTenants } from 'services/data-provider/tenants'
import { useAppState } from 'state'
import { MasterAccountRef } from 'models/master-account'
import { ListingRef } from 'models/listing'

export const useTenants = () => {
  const { selectedTenant } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>()
  const [filter, setFilter] = useState<TenantFilter>({
    page: 1,
    defaultTenant: selectedTenant,
    status: tenantStatusesDefault,
  })
  useEffect(() => {
    fetchTenants()() //initial fetch
  }, [])

  const fetchTenants = (page = 1) => () => {
    setLoading(true)
    setError(undefined)
    GetTenants({
      page: page,
      tenants: filter.tenants,
      country: `${selectedCountries.join(',')}`,
      province: `${selectedProvinces.join(',')}`,
      status: filter.status,
      accountName: masterAccount,
      listings: filter.listings,
      taggings: filter?.taggings,
    })
      .then((resp) => {
        setFilter({ ...filter, page })
        setTenants(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handlePaginationChange = (page: number) => fetchTenants(page)()

  const handleFilterChange = (filter: TenantFilter, tenants?: TenantRef[]) => {
    if (tenants) {
      setFilter({ ...filter, tenants })
    } else setFilter({ ...filter })
  }

  const handleTenantStatus = (tenantStatus: TenantStatus[]) => setFilter({ ...filter, status: tenantStatus })

  const onLocationChange = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  const handleListingTitle = (listings: ListingRef[]) => setFilter({ ...filter, listings: listings })

  return {
    loading,
    tenants,
    error,
    pagination,
    filter,
    masterAccount,
    setFilter,
    handleFilterChange,
    handleTenantStatus,
    fetchTenants,
    handlePaginationChange,
    onLocationChange,
    handleListingTitle,
    setMasterAccount,
  }
}
