import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more'
import { useEffect, useRef, useState } from 'react'

import { ChargerStationInfo } from '../../../../models/chargerv2'
import { useFormatMessage } from '../../../../helpers/intl'
import { useAppState } from '../../../../state'
import { styled, theme } from '../../../../theme'
import { MinMaxWithTitle } from './min-max-with-title'
import { LegendsContainer } from './legends-container'

highchartsMore(Highcharts)

const HomePageCurrentContainer = styled.div`
  position: relative;

  .highcharts-color-0 {
    fill: ${theme.colors.pieGraphPrimary};
    stroke: ${theme.colors.pieGraphPrimary};
    stroke-width: 3;
  }
  .highcharts-data-labels.highcharts-series-0.highcharts-gauge-series.highcharts-color-0.highcharts-tracker {
    display: none;
  }

  .highcharts-data-labels {
    display: none;
  }

  .stations-gauge {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stations-gauge::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 16%);
    border-radius: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
    ${(props) => props.theme.breakpoints.down('xxs')} {
      scale: 0.8;
    }
    ${(props) => props.theme.breakpoints.down('xxxs')} {
      scale: 0.65;
    }
  }

  .stations-gauge.laptop::after {
    width: 100px;
    height: 100px;
  }

  text[text-anchor='middle'] {
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #242e2c;
    fill: #242e2c;
  }

  .highcharts-series.highcharts-series-0.highcharts-solidgauge-series.highcharts-color-0.highcharts-tracker.highcharts-series-hover
    path {
    stroke: ${theme.colors.buttonHovering};
    fill: ${theme.colors.buttonHovering};
  }
`

interface props {
  chargerStationInfo: ChargerStationInfo
  chartData: {
    label: string
    data?: number
    color: string
    onClick?: () => void
  }[]
  chartInfo: {
    title?: string | number
    label: string
    min: string | number
    max: string | number
    height: number
  }
  dataFormatter?: (data: string) => string
}

export const HomePageCurrentChart: React.FC<props> = ({ chargerStationInfo, chartData, chartInfo, dataFormatter }) => {
  const activeChargerCurrent = useFormatMessage(
    'dashboard.homePage.stationoverview.current.description1',
    'Active Charger Current',
  )

  const { language } = useAppState()

  useEffect(() => {
    switch (language) {
      case 'fr':
        document
          .querySelector(
            '.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span',
          )
          ?.classList.add('fr')
        break
      case 'en':
        document
          .querySelector(
            '.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-tracker span',
          )
          ?.classList.remove('fr')
        break
    }
  }, [language])

  const [options, setOptions] = useState({
    chart: {
      type: 'solidgauge',
      height: 312,
      // width: 288,
      styledMode: true,
      className: 'stations-gauge',
      animation: false,
    },

    title: {
      text: '',
    },
    pane: {
      center: ['50%', '50%'],
      size: '90%',
      startAngle: -115,
      endAngle: 115,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    // the value axis
    yAxis: {
      stops: [
        [0, '#54DEC7'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        enabled: false,
      },
      min: 0,
      max: chargerStationInfo.maxAmp,
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
      series: {
        animation: true,
      },
    },
    series: [
      {
        name: activeChargerCurrent,
        data: [Math.round(chargerStationInfo.currentAmp)],
        showInLegend: false,
        tooltip: {
          enable: false,
        },
        type: 'solidgauge',
        color: '#54DEC7',
        rounded: false,
        borderRadius: 12,
        point: {
          events: {
            mouseOver: function () {
              setHoveredSlice((this as any).index)
            },
            mouseOut: function () {
              setHoveredSlice(-1)
            },
          },
        },
      },
    ],
  })

  const [hoveredSlice, setHoveredSlice] = useState(-1)
  const initialAnimationNotTriggered = useRef(true)

  useEffect(() => {
    setOptions({
      chart: {
        type: 'solidgauge',
        height: 312,
        styledMode: true,
        className: 'stations-gauge',
        animation: false, //Need to dissable this for pane flickering issue
      },

      title: {
        text: '',
      },
      pane: {
        center: ['50%', '50%'],
        size: '90%',
        startAngle: -115,
        endAngle: 115,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },

      exporting: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      // the value axis
      yAxis: {
        stops: [
          [0, '#54DEC7'], // green
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          enabled: false,
        },
        min: 0,
        max: chargerStationInfo.maxAmp,
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
        series: {
          animation: initialAnimationNotTriggered.current, //show initial animation then disable after
        },
      },
      series: [
        {
          name: activeChargerCurrent,
          data: [chartData[0]].map((info) => Math.max(info.data || 0, 0.1)),
          showInLegend: false,
          tooltip: {
            enable: false,
          },
          type: 'solidgauge',
          color: '#54DEC7',
          rounded: false,
          borderRadius: 12,
          point: {
            events: {
              mouseOver: function () {
                setHoveredSlice((this as any).index)
              },
              mouseOut: function () {
                setHoveredSlice(-1)
              },
            },
          },
        },
      ],
    })
  }, [chargerStationInfo, language])

  return (
    <>
      <HomePageCurrentContainer className={language}>
        <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
        <MinMaxWithTitle
          title={chartInfo.title || '0'}
          label={chartInfo.label}
          min={chartInfo.min}
          max={chartInfo.max}
          height={chartInfo.height}
        />
        <LegendsContainer dataFormatter={dataFormatter} legends={chartData} hoveredIndex={hoveredSlice} />
      </HomePageCurrentContainer>
    </>
  )
}
