import { message } from 'antd'
import styled from 'styled-components'
import { checkboxSVG, closeSVG } from '../../assets/svg/message'

interface props {
  key: string
  response: string
}

const MessageContentContainer = styled.div`
  display: flex;

  svg {
    cursor: pointer;
    margin-left: 8px;
  }
`

export const CustomSuccessMessage: React.FC<props> = ({ key, response }) => {
  const successConfig = {
    className: 'success-message',
    duration: 3,
    icon: checkboxSVG,
    key,
    content: (
      <MessageContentContainer>
        <p className="paragraph-01-regular">{response}</p>
        {closeSVG({ onClick: () => message.destroy(key) })}
      </MessageContentContainer>
    ),
  }

  message.success(successConfig)
  return <>{message}</>
}
