import { useEffect, useState } from 'react'

import { ListingPricingSchemaUpdate, NewListing } from '../models/listing'
import { TenantPricingSchemaOptions, UpdatePricingSchema } from '../services/data-provider/listing'
import { useListingBasicTrans } from './translation/useListingBasicTrans'
import { SwtchError } from '../models/error'
import { DropdownOption } from '../models/option'
import { useNotifications } from './useNotification'
import { usePricingSchemas } from './usePricingSchemas'

export const useListingPricingSchema = (listings: NewListing[], onOk: () => void) => {
  const tenantIds = listings.map((l) => l?.tenant?.id)
  console.log('🚀 ~ useListingPricingSchema ~ tenantIds:', tenantIds)
  const allSameTenant = tenantIds.every((tenantId) => tenantId === tenantIds[0])
  console.log('🚀 ~ useListingPricingSchema ~ allSameTenant:', allSameTenant)
  const tenantName = listings[0]?.tenant?.name
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<SwtchError>()
  const [pricingSchemaOptions, setPricingSchemaOptions] = useState<DropdownOption[]>([])

  useEffect(() => {
    if (tenantIds[0]) {
      TenantPricingSchemaOptions(tenantIds[0]).then((data) => {
        setPricingSchemaOptions(data.map((pricing_schema) => ({ key: pricing_schema.id, label: pricing_schema.name })))
      })
    }
  }, [])

  const { statusIsRequired, pricingSchemaCanNotUpdate, pricingSchemaIsRequired } = useListingBasicTrans()

  const filterListings = listings.filter((v, i) => {
    return listings.map((val) => val.id).indexOf(v.id) === i
  })

  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const { applyDiscountsToListings } = usePricingSchemas()

  const onFinish = (values: ListingPricingSchemaUpdate) => {
    setLoading(true)
    setError(undefined)
    UpdatePricingSchema({
      ids: filterListings.map((f) => f.id),
      pricing_schema_id: values.pricing_schema_id,
    })
      .then((resp) => {
        if (resp && resp.success === true) {
          tenantIds.map((tenantId) => {
            applyDiscountsToListings(Number(tenantId), filterListings, values.pricing_schema_id)
            return resp
          })
          openSuccessNotification(resp.msg)
        } else {
          openErrorNotification(resp.msg)
        }
        onOk()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  console.log('🚀 ~ useListingPricingSchema ~ pricingSchemaOptions:', pricingSchemaOptions)
  return {
    error,
    loading,
    allSameTenant,
    tenantName,
    pricingSchemaOptions,
    statusIsRequired,
    pricingSchemaCanNotUpdate,
    pricingSchemaIsRequired,
    onFinish,
  }
}
