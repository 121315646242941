import React, { useEffect, useState } from 'react'

import { Button, Form, Modal, Space, Spin } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule } from '../../../models/peak-shaving'
import {
  CreateEventScheduleForEvent,
  GetAllEventSchedulesForEvent,
  GetPeakShavingEventDetails,
  UpdatePeakShavingEvent,
  isPeakShavingFormEnabled,
} from '../../../services/data-provider/peak-shaving'
import { styled } from '../../../theme'
import { EventDetailsBaseForm } from '../form/event-details-base-form'

import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  eventId: number
  term: string
}

const CreateNewEventModalContainer = styled.div``

export const EventDetailsModal: React.FC<props> = ({ visible, onCancel, onSubmit, eventId, term }) => {
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [EventDetailsForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const [peakShavingEvent, setPeakShavingEvent] = useState<PeakShavingEvent>()
  const [eventSchedules, setEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>([])

  const { publishText, cancelText, eventUpdateSuccessText } = usePeakShavingTranslation()

  useEffect(() => {
    setDisabled(true)
    setLoading(true)
    Promise.all([GetPeakShavingEventDetails(eventId), GetAllEventSchedulesForEvent(eventId)])
      .then((resp) => {
        const [eventDetailsResponse, eventSchedulesResponse] = resp
        const newArr = eventSchedulesResponse.map(({ id, dayType, ...rest }) => rest)
        setPeakShavingEvent(eventDetailsResponse)
        setEventSchedules(newArr)
      })
      .catch((err: SwtchApiError) => setError(err))
      .finally(() => setLoading(false))

    return () => {
      setEventSchedules([])
    }
  }, [eventId, term])

  const handleCancel = () => {
    EventDetailsForm.resetFields()
    setDisabled(true)
    setError(undefined)
    onCancel()
  }

  const handleFormSubmit = () => {
    EventDetailsForm.validateFields()
      .then((values: any) => {
        setLoading(true)
        setDisabled(true)
        const { zones, program, date, name, frequency, participants, ...updateEventPrams } = values
        console.log('🚀 ~ file: event-details-modal.tsx:73 ~ .then ~ values:', values)
        UpdatePeakShavingEvent(eventId, { ...updateEventPrams, participant_id: participants[0].participantId })
          .then((resp) => {
            resp &&
              Promise.all([
                ...zones
                  .map((obj: Omit<PeakShavingEventSchedule, 'id'>) => {
                    return {
                      from: obj.from,
                      zone: obj.zone,
                    }
                  })
                  .map((schedule: Omit<PeakShavingEventSchedule, 'id'>) =>
                    CreateEventScheduleForEvent(eventId, schedule),
                  ),
              ])
                .then(() => {
                  CustomSuccessMessage({
                    key: 'UpdateEventAndSchedule',
                    response: eventUpdateSuccessText,
                  })
                  onSubmit()
                })
                .catch((err: SwtchApiError) => setError(err))
          })
          .finally(() => setLoading(false))
      })
      .catch((err: SwtchApiError) => {
        setError(err)
        setDisabled(true)
      })
  }

  const handleChange = () => {
    EventDetailsForm.validateFields()
      .then(() => {
        setDisabled(false)
      })
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  return (
    <CreateNewEventModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{`${!disabled ? 'Edit' : ''} Event - ${eventId}`}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          peakShavingEvent &&
          isPeakShavingFormEnabled(peakShavingEvent) && (
            <Space>
              <Button type="ghost" onClick={handleCancel}>
                {cancelText}
              </Button>
              <Button type="primary" onClick={handleFormSubmit} disabled={disabled}>
                {publishText}
              </Button>
            </Space>
          )
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`createNewEventModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        <AlertError error={error} />
        <Spin spinning={loading}>
          {peakShavingEvent?.program && term && !loading && (
            <EventDetailsBaseForm
              program={peakShavingEvent.program}
              form={EventDetailsForm}
              disabled={!isPeakShavingFormEnabled(peakShavingEvent)}
              schedules={eventSchedules}
              formMode="view"
              event={peakShavingEvent}
              formListName="zones"
              scheduleFrequency="manual"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </CreateNewEventModalContainer>
  )
}
