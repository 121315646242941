import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { useAppState } from '../../state'
import TransactionsPageV2 from './TransactionsPageV2'
import { TransactionsPage } from './transactions-page'

const TransactionsBasePage: React.FC = () => {
  const { isTransactionsClassicView } = useAppState()

  return isTransactionsClassicView ? <TransactionsPage /> : <TransactionsPageV2 />
}

export const TransactionsHomePage = withAuthenticatedLayout(TransactionsBasePage)
