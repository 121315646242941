import { Form, Modal, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'

import { closeSVG } from '../../../assets/svg/close'
import { ButtonModal } from '../../../atom/button'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule, PeakShavingProgram } from '../../../models/peak-shaving'
import {
  CreateEventScheduleForEvent,
  CreateNewPeakShavingEvent,
  GetAllEventSchedulesForEvent,
  GetPeakShavingEventDetails,
  PublishPeakShavingEvent,
} from '../../../services/data-provider/peak-shaving'
import { EventDetailsBaseForm } from '../form/event-details-base-form'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  eventId: number
  program: PeakShavingProgram
  term: string
}

export const DuplicateEventModal: React.FC<props> = ({ visible, onCancel, onSubmit, eventId, program, term }) => {
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [DuplicateEventForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const [peakShavingEvent, setPeakShavingEvent] = useState<PeakShavingEvent>()
  const [eventSchedules, setEventSchedules] = useState<Omit<PeakShavingEventSchedule, 'id'>[]>(
    Array.from(Array(24), (_, index) => ({
      from: index,
      to: index + 1,
      zone: 'none',
    })),
  )

  const { duplicateEventText, cancelText, publishText } = usePeakShavingTranslation()

  useEffect(() => {
    setLoading(true)
    setError(undefined)
    Promise.all([GetPeakShavingEventDetails(eventId), GetAllEventSchedulesForEvent(eventId)])
      .then((resp) => {
        const [eventDetailsResponse, eventSchedulesResponse] = resp

        const newArr = eventSchedulesResponse.map(({ id, ...rest }) => {
          return rest
        })

        setPeakShavingEvent(eventDetailsResponse)
        setEventSchedules(newArr)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
    return () => {
      setEventSchedules([])
    }
  }, [eventId, term])

  const handleCancel = () => {
    setError(undefined)
    onCancel()
    setDisabled(true)
    setLoading(false)
  }

  const handleFormSubmit = () => {
    DuplicateEventForm.validateFields().then(() => {
      setDisabled(true)
      const { zones, program, participants, ...createNewEventFormPrams } = DuplicateEventForm.getFieldsValue()

      const filteredFields = zones.filter((field: any) => field.zone !== 'none')
      if (filteredFields.length === 0) {
        setError(
          new SwtchApiError({
            message: 'Unable to publish shaving event',
            code: '422',
            details: ['Event must have at least one schedule'],
          }),
        )
        setDisabled(true)
      } else {
        setLoading(true)
        Promise.all(
          participants.map((participant_id: number) =>
            CreateNewPeakShavingEvent({ ...createNewEventFormPrams, participant_id })
              .then((event: PeakShavingEvent) => {
                event && publishEventAndCreateSchedule(event, filteredFields)
              })
              .catch((err: SwtchApiError) => setError(err)),
          ),
        ).finally(() => {
          setLoading(false)
          setDisabled(false)
          DuplicateEventForm.resetFields()
        })
      }
    })
  }

  const publishEventAndCreateSchedule = (event: PeakShavingEvent, fields: PeakShavingEventSchedule[]) => {
    const filteredFields = fields
      .filter((field: any) => field.zone !== 'none')
      .map((obj) => {
        return {
          from: obj.from,
          zone: obj.zone,
        }
      })

    Promise.all([...filteredFields.map((field) => CreateEventScheduleForEvent(event.id, field))])
      .then((resp) => {
        resp &&
          resp.length > 0 &&
          PublishPeakShavingEvent(event.id).then(({ response }) => {
            CustomSuccessMessage({
              key: 'CreateNewEventAndSchedule',
              response: response,
            })
            onSubmit()
          })
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
      .finally(() => {
        DuplicateEventForm.resetFields()
      })
  }

  const handleChange = () => {
    setError(undefined)
    DuplicateEventForm.validateFields()
      .then(() => {
        setDisabled(false)
      })
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  return (
    <>
      <Modal
        title={
          <h5 className="heading-05-regular">
            {duplicateEventText} - {eventId}
          </h5>
        }
        visible={visible}
        confirmLoading={loading}
        footer={
          peakShavingEvent && (
            <Space>
              <ButtonModal type="ghost" icon={<></>} click={handleCancel} text={cancelText} />
              <ButtonModal
                type="primary"
                icon={<></>}
                click={handleFormSubmit}
                text={publishText}
                disabled={disabled}
              />
            </Space>
          )
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`duplicateEventModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        {error && <AlertError error={error} closable />}

        <Spin spinning={loading}>
          {peakShavingEvent && peakShavingEvent.program && !loading && (
            <EventDetailsBaseForm
              program={program}
              form={DuplicateEventForm}
              disabled={false}
              schedules={eventSchedules}
              formMode="duplicate"
              event={peakShavingEvent}
              formListName="zones"
              scheduleFrequency="manual"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </>
  )
}
