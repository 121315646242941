import { Typography } from 'antd'
import { capitalizeFirstLetter } from '../../helpers/users'
import { Transaction } from '../../models/transaction'
import { theme } from 'theme'

interface props {
  transaction: Transaction
}

const ellipsisValue = (value: string): JSX.Element | string => {
  if (value.length < 10) {
    return value
  }
  return (
    <Typography.Text style={{ color: theme.colors.softBlack, width: 70 }} ellipsis={{ tooltip: value }}>
      {value}
    </Typography.Text>
  )
}

const TitleAndSubtitle: React.FC<props> = ({ transaction }) => {
  return (
    <div className={transaction.user && transaction.user.name !== '' ? 'flex-and-gap-below' : ''}>
      <span>{transaction.user ? ellipsisValue(transaction.user.name) : null}</span>
      <span className="opacity-06 paragraph-04-light">{capitalizeFirstLetter(transaction.startMethod)}</span>
    </div>
  )
}

export default TitleAndSubtitle
