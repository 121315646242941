import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { useAppState } from '../../state'
import { DashboardBasePageOld } from './dashboard-page-old'
import { DashboardBasePageRedesign } from './dashboard-page-redesign'

export const DashboardBasePage: React.FC = () => {
  const { isClassicView } = useAppState()

  return isClassicView ? <DashboardBasePageOld /> : <DashboardBasePageRedesign />
}

export const DashboardPage = withAuthenticatedLayout(DashboardBasePage)
