import numeral from 'numeral'

export const formatNumber = (transactionCount: number): string => {
  return numeral(transactionCount).format('0,0[.]00')
}

export const formatEnergy = (energyAmount: number, hideDecimal?: boolean): string => {
  return hideDecimal ? numeral(energyAmount).format('0,0') : numeral(energyAmount).format('0,0[.]000')
}

export const formatCentsPrice = (price: number): string => {
  return formatPrice(price / 100.0)
}

export const formatPrice = (price: number, currency?: string): string => {
  if (!price) {
    return '$0.00'
  }
  let formatStr = '$0,0.00 a'

  if (price === 0) {
    formatStr = '$0.00'
  } else if (price < 0) {
    formatStr = '(' + formatStr + ')'
  } else if (price <= 10000) {
    formatStr = '$0,0.00'
  }

  const numberFormat = numeral(price).format(formatStr)
  const currencyFormat = currency ? ` ${currency.toUpperCase()}` : ''

  return `${numberFormat}${currencyFormat}`
}

export const formatDollarsToCents = (price: number): string => (price / 100).toFixed(2)

export const convertStringifyNumberToNumberType = (value: string | number) => {
  if (typeof value === 'string') {
    return parseFloat(value)
  }
  // If value is already a number, it remains unchanged
  return value
}
