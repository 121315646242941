import { Checkbox, Select } from 'antd'
import { ReactElement, ReactNode, useState, MouseEvent } from 'react'

import { renderFormatMessage } from '../../helpers/intl'
import { renderConnectionMessage } from '../../helpers/status'
import { magnifySVG } from '../../assets/svg/magnify'
import { useAppState } from '../../state'
import { MultiOptionSelectorWrapper } from '../../atom/select'
import { SELECTOR_OPTIONS } from '../../helpers/select'
import { DefaultOptionType } from 'antd/lib/select'

interface props<T> {
  options: T[]
  defaultValues?: T[]
  onOptionsChange: (options: T[]) => void
  onClear: () => void
  onSearch: (options: T[]) => void
  placeholder: string
  loading: boolean
  isCombineLeft?: boolean
  isCombineRightAndLeft?: boolean
  isCombineRight?: boolean
  longerWidth?: boolean
  removeSVG?: boolean
  dropdownClassName?: string
}

const MultiOptionSelector = <T extends string>(props: props<T> & { children?: ReactNode }) => {
  const {
    defaultValues,
    options,
    placeholder,
    loading,
    isCombineLeft,
    isCombineRightAndLeft,
    isCombineRight,
    longerWidth,
    removeSVG,
    onOptionsChange,
    onClear,
    onSearch,
    dropdownClassName,
  } = props
  const { IsDesktop, IsLaptop } = useAppState()

  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValues || [])
  const [dirty, setDirty] = useState(false)

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const handleChange = (values: string[]) => {
    setSelectedOptions(values)
    setDirty(true)
    onOptionsChange((values as unknown) as T[])
  }

  const handleOnSearch = (input: string) => {
    options.forEach((opt) => {
      if (opt.toLowerCase() === input.toLowerCase()) {
        onSearch(options)
      }
    })
  }

  const renderMultiOptions = (options: T[]) => {
    const optionsWithSelected = [
      ...selectedOptions,
      ...options.filter((val) => !selectedOptions.some((option) => option === val)),
    ]
    const final = optionsWithSelected
      .map((v) => renderOption(v, placeholder))
      .sort((a: ReactElement, b: ReactElement) => {
        return b.props.children.props.checked - a.props.children.props.checked
      })
    return final
  }

  const renderOption = (option: string, placeholder?: string): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    // Vendors have fixed names so it doesn't need translation.
    const modifiedOption = placeholder !== 'Vendor (s)' ? translateOpt : option

    return (
      <Select.Option key={option} value={option}>
        <Checkbox onClick={handleDirty} checked={selectedOptions.includes(option)}>
          {modifiedOption}
        </Checkbox>
      </Select.Option>
    )
  }

  const humanizeFirstSelected =
    placeholder === 'Vendor (s)'
      ? selectedOptions[0]
      : renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])

  const handleMaxTagPlaceholder = () => {
    if (selectedOptions.length === 1) {
      return humanizeFirstSelected
    } else {
      if (placeholder === 'Vendor (s)') {
        return `${selectedOptions.length} Vendors Selected`
      } else if (placeholder === 'Status (es)') {
        return `${selectedOptions.length} Statuses Selected`
      } else if (placeholder === 'Service Status (es)') {
        return `${selectedOptions.length} Service Statuses Selected`
      } else {
        return `${selectedOptions.length} ${placeholder} Selected`
      }
    }
  }

  const handleDirty = (e: MouseEvent) => (dirty ? e.stopPropagation() : setDirty(false))

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setDirty(false)
    e.stopPropagation()
  }

  const canCombineBorder = (isDesktop || isLaptop) && isCombineLeft
  const canCombineRightAndLeftBorder = (isDesktop || isLaptop) && isCombineRightAndLeft
  const canCombineRightBorder = (isDesktop || isLaptop) && isCombineRight

  //To keep the selected option visible
  const filterOption = (input: string, option: DefaultOptionType | undefined) => {
    if (selectedOptions.some((selectedOption) => selectedOption === option?.value)) {
      return true
    }
    return (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <MultiOptionSelectorWrapper
      isCombineLeft={canCombineBorder}
      isCombineRightAndLeft={canCombineRightAndLeftBorder}
      isCombineRight={canCombineRightBorder}
      longerWidth={longerWidth === false ? false : true}
      removeSVG={removeSVG}
    >
      {magnifySVG}
      <Select
        dropdownClassName={dropdownClassName}
        mode="multiple"
        allowClear
        filterOption={filterOption}
        showArrow
        disabled={loading}
        style={{ width: '100%', lineHeight: '34px' }}
        placeholder={SELECTOR_OPTIONS[placeholder]}
        defaultValue={defaultValues}
        onChange={handleChange}
        onClear={onClear}
        onMouseDown={handleMouseDown}
        dropdownMatchSelectWidth={false}
        onSearch={(e) => handleOnSearch(e)}
        maxTagCount={0}
        maxTagPlaceholder={handleMaxTagPlaceholder}
      >
        {renderMultiOptions(options)}
      </Select>
    </MultiOptionSelectorWrapper>
  )
}

export default MultiOptionSelector
