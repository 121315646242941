import { useState } from 'react'
import { AutoComplete, Input, message, Spin, Tag } from 'antd'
import { ScheduleOutlined } from '@ant-design/icons'
import { SelectProps } from 'antd/es/select'

import { SwtchError } from '../../models/error'
import { NoSearchIcon } from '../../atom/search-icon'
import { useFormatMessage } from '../../helpers/intl'
import { Plan } from '../../models/plan'
import { FindPlan } from '../../services/data-provider/plan'
import { debounce } from 'lodash'

const { Search } = Input

interface props {
  onPlanSelected: (plan: Plan) => void
  onSearch: (plan: Plan) => void
  defaultPlan?: Plan
}

const renderPlan = (plan: Plan): any => {
  return {
    value: plan.name,
    plan,
    label: (
      <div>
        <ScheduleOutlined /> {plan.name}
      </div>
    ),
  }
}

export const PlanSelector: React.FC<props> = ({ onPlanSelected, onSearch, defaultPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(defaultPlan)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectProps<object>['options']>([])

  const placeholder = useFormatMessage('dashboard.subscriptionsPage.placeholder.searchPlan', 'Search Plan')

  const searchResult = (value: string) => {
    setLoading(true)
    FindPlan(value)
      .then((plans) => {
        const opts = plans.map((plan) => renderPlan(plan))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelectedPlan(opt.plan)
        onPlanSelected(opt.plan)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt?.value?.toString().toLowerCase() === value.toLowerCase()) {
        setSelectedPlan(opt.plan)
        onSearch(opt.plan)
      }
    })
  }

  return (
    <>
      {!selectedPlan && (
        <AutoComplete
          dropdownMatchSelectWidth={false}
          options={options}
          onSelect={onSelect}
          onSearch={debounce(searchResult, 800)}
          notFoundContent={loading ? <Spin size="small" /> : 'Search for a plan'}
        >
          <NoSearchIcon>
            <Search placeholder={placeholder} onSearch={(e) => handleOnSearch(e)} />
          </NoSearchIcon>
        </AutoComplete>
      )}
      {selectedPlan && <Tag closable={false}>{selectedPlan.name}</Tag>}
    </>
  )
}
