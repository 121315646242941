export interface Option {
  value: string | number
  label: string
  children?: Option[]
}

export const tenantActivityTrackingCategories: Option[] = [
  { label: 'Display Name', value: 'tenant_name' },
  { label: 'Site Status', value: 'tenant_status' },
  { label: 'Account', value: 'account' },
  { label: 'Tax Registration', value: 'tax_registration' },
  { label: 'O&M Opt-in', value: 'om_opt_in' },
  { label: 'Charger Settings', value: 'charger_settings' },
  { label: 'Notes', value: 'notes' },
  { label: 'Uniform Service Statuses', value: 'uniform_service_statuses' },
  { label: 'On-Site Contact Name', value: 'on_site_contact_name' },
  { label: 'On-Site Contact Email', value: 'on_site_contact_email' },
  { label: 'On-Site Contact Number', value: 'on_site_contact_number' },
  { label: 'Address', value: 'address' },
  { label: 'City', value: 'city' },
  { label: 'Postal Code', value: 'postal_code' },
  { label: 'Country', value: 'country' },
  { label: 'Province', value: 'province' },
  { label: 'Uniform Map Settings', value: 'uniform_map_settings' },
  { label: 'Uniform Access Control', value: 'uniform_access_control' },
  { label: 'Access Code', value: 'access_code' },
]

export const listingActivityTrackingCategories: Option[] = [
  { label: 'Access Control', value: 'access_control' },
  { label: 'Associated Site', value: 'associated_tenant' },
  {
    label: 'Charging point accessible by vehicle with trailer',
    value: 'charging_point_accessible_by_vehicle_with_trailer',
  },
  { label: 'Charger Output (kW)', value: 'charger_output' },
  { label: 'Custom Notification', value: 'custom_notification' },
  { label: 'Commission (%)', value: 'commission' },
  { label: 'Enable pricing for site members', value: 'enable_pricing_for_tenant_members' },
  { label: 'Funding Sources', value: 'funding_sources' },
  { label: 'Flat Fee ($)', value: 'flat_fee' },
  { label: 'Guest Fee ($)', value: 'guest_fee' },
  { label: 'Hourly pricing based on kWh', value: 'hourly_pricing_based_on_kwh' },
  { label: 'Listing Author', value: 'listing_author' },
  { label: 'Listing Category', value: 'listing_category' },
  { label: 'Listing Title', value: 'listing_title' },
  { label: 'Listing Info', value: 'listing_info' },
  { label: 'Listing hidden from map', value: 'listing_hidden_from_map' },
  { label: 'Payment Limit ($)', value: 'payment_limit' },
  { label: 'Preferred Pricing for site members', value: 'preferred_pricing_for_tenant_members' },
  { label: 'Price ($)', value: 'price' },
  { label: 'Reason for service Status Change', value: 'reason_for_service_status_change' },
  { label: 'Service Status', value: 'service_status' },
  { label: 'Stripe Account', value: 'stripe_account' },
  { label: 'Time of Day Pricing Entry', value: 'time_of_day_pricing_entry' },
  { label: 'Enable Time of Day Pricing', value: 'enable_time_of_day_pricing' },
  { label: 'Users need car details to book', value: 'users_need_car_details_to_book' },
  { label: 'Wallet Functionality', value: 'wallet_functionality' },
  { label: 'Allow Cloud Management', value: 'allow_cloud_management' },
  { label: 'Local Load Management: Transaction Auto-Stop', value: 'local_load_management_transaction_auto_stop' },
  { label: 'Disable Loitering For Site member', value: 'disable_loitering_for_tenant_member' },
  { label: 'Loitering Status', value: 'loitering_status' },
  { label: 'Loitering Penalty Per Hour ($)', value: 'loitering_penalty_per_hour' },
  {
    label: 'Loitering Time Limit For Timed-Based in Minutes',
    value: 'loitering_time_limit_for_timed_based_in_minutes',
  },
  {
    label: 'Loitering Time Limit for Grace-Period in Minutes',
    value: 'loitering_time_limit_for_grace_period_in_minutes',
  },
]

export const pricingSchemaTrackingCategories: Option[] = [
  { label: 'Name', value: 'name' },
  { label: 'Rate', value: 'rate' },
  { label: 'Curtailment Adjustment', value: 'curtailment_adjustment' },
  { label: 'Time of Day', value: 'time_of_day' },
  { label: 'Weekday Rate', value: 'weekday_rate' },
  { label: 'Weekend Rate', value: 'weekend_rate' },
  { label: 'Loitering Rate', value: 'loitering_rate' },
  { label: 'Flat Fee', value: 'flat_fee' },
  { label: 'Payment Processing Fee', value: 'payment_processing_fee' },
  { label: 'Commission Fee', value: 'commission_fee' },
]

export const activityTrackingCategories: Option[] = [
  {
    value: 'tenant',
    label: 'Site',
    children: tenantActivityTrackingCategories,
  },
  {
    value: 'listing',
    label: 'Listing',
    children: listingActivityTrackingCategories,
  },

  {
    value: 'pricing_schema',
    label: 'Pricing Plan',
    children: pricingSchemaTrackingCategories,
  },
]
