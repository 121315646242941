import { styled } from '../../theme'

interface PanelProps {
  position: number
  total: number
}

export const PanelContainer = styled.div<PanelProps>`
  background-color: white;

  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 5px;
  height: ${(props) => (props.total <= 13 ? '97%' : '94%')};
  margin-left: 1.3rem;
  padding-bottom: 1rem;
  margin-top: 25px;
`
