import React, { ReactNode } from 'react'
import { Card } from 'antd'
import { theme } from '../theme'

interface props {
  loading?: boolean
  title: React.ReactElement | string
  description?: string | ReactNode
  header: React.ReactElement
  color?: string
  extra?: React.ReactElement
  hoverable?: boolean
  removeTitleBorderBottom?: boolean
  bodyPadding?: boolean
}

export const Widget: React.FC<props> = ({
  title,
  description,
  header,
  loading,
  color,
  extra,
  hoverable,
  removeTitleBorderBottom,
  bodyPadding,
}) => {
  const headStyle = {
    borderBottom: removeTitleBorderBottom === true ? 'none' : '1px solid $#f0f0f0',
    padding: '0 15px',
    fontFamily: theme.fontFamily.regularFontFamily,
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.colors.black,
    fontStyle: 'normal',
    opacity: 0.6,
  }

  const bodyStyle = { padding: bodyPadding === true ? '0 15px 10px 15px' : '24px' }

  return (
    <Card
      title={title}
      bordered={true}
      loading={loading}
      extra={extra}
      hoverable={hoverable}
      style={{ height: '100%', boxShadow: `0px 4px 17px 0px ${theme.colors.boxShadow}` }}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
    >
      <h2 className={color}>{header}</h2>
      {description && <small>{description}</small>}
    </Card>
  )
}
