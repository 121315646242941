import { Badge } from 'antd'

import { useFormatMessage } from '../../helpers/intl'
import { Transaction } from '../../models/transaction'
import { theme } from '../../theme'

interface props {
  transaction: Transaction
}

export const TransactionStateBadge: React.FC<props> = ({ transaction }) => {
  const [status, backgroundColor, fontColor] = getStyle(transaction.currentState)

  const translatedStatus = useFormatMessage(`dashboard.transaction.${status}`, status)

  return (
    <Badge
      count={translatedStatus}
      style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
    />
  )
}

/**
 * * IMPORTNANT: Transaction State below in 'getStyle' function
 * Ongoing:
    no_reservation
    checked_in
  Completed:
    confirmed
    free
  Pending Payment:
    Pending_payment
  Errored:
    Errored
  Canceled:
    Canceled
    For everything else, display status as is 
 **/

const getStyle = (currentState: string): string[] => {
  const {
    active,
    neonGreen,
    orangePeel15,
    warningPrimary,
    grey12,
    black,
    underRepair,
    dangerPrimary,
    yellow,
    blue,
  } = theme.colors

  switch (currentState) {
    case 'confirmed':
    case 'completed':
    case 'free':
      return ['completed', active, neonGreen]
    case 'pending_payment':
    case 'reserved':
      return [currentState, orangePeel15, warningPrimary]
    case 'no_reservation':
    case 'ongoing':
    case 'checked_in':
      return ['ongoing', orangePeel15, warningPrimary]
    case 'canceled':
      return [currentState, grey12, black]
    case 'errored':
      return [currentState, underRepair, dangerPrimary]
    case 'paid':
    case 'preauthorized':
      return [currentState, yellow]
    default:
      return [currentState, blue]
  }
}
