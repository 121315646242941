import { AutoComplete, Input, message, Tag } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { NoSearchIcon } from '../../atom/search-icon'
import { useFormatMessage } from '../../helpers/intl'
import { SwtchError } from '../../models/error'
import { ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { PeakShavingProgramListing } from '../../models/peak-shaving'
import { FindListings } from '../../services/data-provider/listing'

const { Search } = Input

interface props {
  defaultTenant?: TenantRef
  defaultListing?: ListingRef
  tenants?: TenantRef[]
  shaving?: boolean
  peakShavingProgramListing?: PeakShavingProgramListing[]
  onListingSelected: (listing: ListingRef) => void
  onClear: () => void
  onSearch: (listing: ListingRef) => void
}

const renderListing = (listing: ListingRef): any => {
  return {
    value: listing.title,
    listing,
    label: <div>{listing.title}</div>,
  }
}

export const ListingSelector: React.FC<props> = ({
  defaultTenant,
  tenants,
  shaving,
  peakShavingProgramListing,
  onListingSelected,
  onClear,
  onSearch,
  defaultListing,
}) => {
  const [selected, setSelected] = useState<ListingRef>()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const searchListingText = useFormatMessage('dashboard.selector.searchListing', 'Search Listing')

  useEffect(() => {
    if (defaultListing?.title) {
      setSelected(defaultListing)
    }
  }, [defaultListing])

  const searchResult = (value: string) => {
    FindListings(value, defaultTenant, tenants)
      .then((listings) => {
        if (peakShavingProgramListing && peakShavingProgramListing.length > 0) {
          listings = listings.filter(
            (listing) => !peakShavingProgramListing.map((obj) => obj.title).includes(listing.title),
          )
        }

        const opts = listings.map((listing) => {
          return renderListing(listing)
        })
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelected(opt.listing)
        onListingSelected(opt.listing)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt.listing.title.toLowerCase() === value.toLowerCase()) {
        setSelected(opt.listing)
        onSearch(opt.listing)
      }
    })
  }

  return (
    <>
      {!selected && (
        <AutoComplete
          style={{ minWidth: 156, width: '100%' }}
          dropdownMatchSelectWidth={false}
          options={options}
          onSelect={onSelect}
          onSearch={debounce(searchResult, 800)}
        >
          <NoSearchIcon>
            <Search placeholder={searchListingText} onSearch={(e) => handleOnSearch(e)} />
          </NoSearchIcon>
        </AutoComplete>
      )}
      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {selected.title}
        </Tag>
      )}
    </>
  )
}
