import { keyframes } from 'styled-components'
import { styled } from '../theme'

export const fadeOutAnimation = keyframes`
     0% {
        opacity: 1
      }    
    100% {
        opacity: 0
    }
`
export const fadeInAnimation = keyframes`
     0% {
        opacity: 0
      }    
    100% {
        opacity: 1
    }
`

export const BatteryEmptyWrapper = styled.div`
  animation-name: ${fadeOutAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export const BatteryHalfWrapper = styled.div`
  animation-name: ${fadeInAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
`

export const BatteryFullWrapper = styled.div`
  animation-name: ${fadeInAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-delay: 2.2s;
`
export const LightingBoltWrapper = styled.div`
  animation-name: ${fadeOutAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`

export const ChargingIconWrapper = styled.div`
  position: relative;
  margin-right: 50px;

  svg[data-icon='battery-empty'],
  svg[data-icon='battery-full'],
  svg[data-icon='battery-half'] {
    position: absolute;
    top: -20px;
    left: 0;
  }

  svg[data-icon='bolt'] {
    position: absolute;
    top: -20px;
    left: 35px;
  }
`
