export const getChangedValues = <T extends Record<string, any>>(initialValues: T, currentValues: T): Partial<T> => {
  const changedValues: Partial<T> = {}

  for (const key in currentValues) {
    if (currentValues[key] !== initialValues[key]) {
      changedValues[key] = currentValues[key]
    }
  }

  return changedValues
}
