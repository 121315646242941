import { Transaction } from '../../../../models/transaction'

import TransactionPanelCard from './TransactionPanelCard'
import { PanelContainer } from '../../../../atom/panel/panel'

interface props {
  loading: boolean
  selectedTransaction: Transaction
  position: number
  rowIndex: number
  totalTransactions: number
  onShowPanel: () => void
}

const TransactionPanel: React.FC<props> = ({
  loading,
  selectedTransaction,
  position,
  rowIndex,
  onShowPanel,
  totalTransactions,
}) => {
  if (rowIndex === 0) {
    rowIndex = 1
  }

  return (
    <PanelContainer className="container" position={rowIndex * position} total={totalTransactions}>
      <TransactionPanelCard loading={loading} selectedTransaction={selectedTransaction} onShowPanel={onShowPanel} />
    </PanelContainer>
  )
}

export default TransactionPanel
