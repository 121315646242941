import { bolt12x24SVG } from '../../../../assets/svg/bolt'

import { formatEnergy } from '../../../../helpers/number-format'

import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { TransactionMetric } from '../../../../models/analytics'
import { CoreInfoCard } from 'components/cards'

interface TotalEnergyProps {
  metrics?: TransactionMetric
  loading: boolean
}

const TotalEnergy = ({ metrics, loading }: TotalEnergyProps) => {
  const { energyText } = useGeneralTranslation()
  return (
    <CoreInfoCard
      title={energyText}
      wsLoading={loading}
      overviewIcon={bolt12x24SVG}
      infoText={`${formatEnergy(metrics?.energy || 0, true)} kWh`}
    />
  )
}

export default TotalEnergy
