import { theme } from '../../theme'

export const pieChartSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16699 1.70835V10.8333H18.292C17.8745 15.0442 14.3212 18.3333 10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 10C1.66699 5.67918 4.95616 2.12585 9.16699 1.70835ZM10.8337 0.452515C15.4612 0.850015 19.1495 4.53918 19.5478 9.16668H10.8337V0.452515Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const pieChart18x18SVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.54998 1.2642V10.45H16.7358C16.3155 14.6889 12.7385 18 8.38887 18C3.7557 18 0 14.2443 0 9.61113C0 5.2615 3.31109 1.68449 7.54998 1.2642ZM9.22776 0C13.8861 0.400149 17.599 4.1139 18 8.77224H9.22776V0Z"
      fill={theme.colors.white}
    />
  </svg>
)
