import { Button, Modal, Space } from 'antd'
import { circleErrorSVG } from 'assets/svg/error'
import { ArchiveDiscountSchemaModalWrapper, DiscountModalFooter } from 'atom/price'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
interface props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export const RemoveUserDiscountSchemaModal: React.FC<props> = ({ visible, onOk, onCancel }) => {
  const { removingUsers, removeText, cancel } = useDiscountSchemaTranslation()
  return (
    <Modal visible={visible} onCancel={onCancel} width={400} bodyStyle={{ padding: '24px' }} footer={null}>
      <ArchiveDiscountSchemaModalWrapper onlyTitle>
        <div>{circleErrorSVG}</div>
        <div className="title">{removingUsers}</div>
      </ArchiveDiscountSchemaModalWrapper>
      <DiscountModalFooter>
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {cancel}
          </Button>
          <Button className="red" type="primary" onClick={onOk}>
            {removeText}
          </Button>
        </Space>
      </DiscountModalFooter>
    </Modal>
  )
}
