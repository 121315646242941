import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, InputRef, Select, Space } from 'antd'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { renderFormatMessage } from 'helpers/intl'
import { debounce } from 'lodash'
import { Tagging } from 'models/tagging'
import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { CreateTagging } from 'services/data-provider/tagging'
import { useTaggingTranslation } from './translation/useTaggingTranslation'
import { useTagging } from './useTagging'

export const useRevenueSharePartnerSelector = () => {
  const { getTaggingsWithoutPagination, taggings } = useTagging()
  const { createSuccessText } = useTaggingTranslation()
  const createNewTagMessageKey = 'createNewTagMessageKey'

  const revenueSharePartnerPrefix = 'Rev_Share_Partner_'
  useEffect(() => {
    getTaggingsWithoutPagination('Tenant', revenueSharePartnerPrefix)
  }, [])

  const [tagName, setTagName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const allTaggingsRef = useRef<Tagging[]>([])

  useEffect(() => {
    allTaggingsRef.current = taggings
  }, [taggings])

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    CreateTagging({
      tagName: revenueSharePartnerPrefix + tagName,
      status: 'active',
      tableName: 'Tenant',
      tagType: 'admin',
    }).then((resp) => {
      setTagName('')
      const responseMessage = createSuccessText.replace('-1', resp.tagName)
      CustomSuccessMessage({ key: createNewTagMessageKey, response: responseMessage })
      getTaggingsWithoutPagination('Tenant', revenueSharePartnerPrefix)
    })
    setTagName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  const handleSearch = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      //Need to replace with api later
      if (value.length >= 1) {
        const searchedOptions = allTaggingsRef.current.filter((option) =>
          option.tagName?.replace(revenueSharePartnerPrefix, '').toLowerCase().includes(value.toLocaleLowerCase()),
        )
        setValues(searchedOptions)
        setValueOptions(searchedOptions)
      } else {
        setValues(allTaggingsRef.current)
        setValueOptions(allTaggingsRef.current)
      }
    }
    return debounce(loadOptions, 100)
  }, [100])

  const handleTaggingSelectChange = (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any) => void,
    onOptionsChange: (selectedTenants: any) => void,
    options: any[],
  ) => {
    const findMasterAccount = taggings?.find((p) => p.tagName.replace(revenueSharePartnerPrefix, '') === value)
    setSelectedOptions(value)
    onOptionsChange(findMasterAccount)
  }

  const renderTaggingOption = (option: Tagging, searchValue: string): ReactElement => {
    return option?.tagName
      ?.replace(revenueSharePartnerPrefix, '')
      .toLowerCase()
      .includes(searchValue?.toLowerCase()) ? (
      <Select.Option
        key={option.id}
        value={option.tagName.replace(revenueSharePartnerPrefix, '')}
        label={option.tagName.replace(revenueSharePartnerPrefix, '')}
      >
        {option.tagName.replace(revenueSharePartnerPrefix, '')}
      </Select.Option>
    ) : (
      <></>
    )
  }

  const checkDuplicate = () => {
    const filterName = taggings.filter(
      (m) => m.tagName?.replace(revenueSharePartnerPrefix, '').toLowerCase() === tagName.toLowerCase(),
    )

    if (tagName.length === 0) {
      return true
    } else if (filterName.length !== 0) {
      return true
    } else {
      return false
    }
  }

  const dropdownRender = (hideAdd: boolean = false) => (
    menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  ) => (
    <>
      {menu}
      {!hideAdd && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Space wrap style={{ padding: '0 8px 4px' }}>
            <Input placeholder="Enter new partner" ref={inputRef} value={tagName} onChange={onNameChange} />
            <Button
              style={{ height: '30px' }}
              type="primary"
              disabled={checkDuplicate()}
              icon={<PlusOutlined />}
              onClick={addItem}
            >
              {renderFormatMessage('dashboard.text.add', 'Add')}
            </Button>
          </Space>
        </>
      )}
    </>
  )

  return {
    options: taggings,
    revenueSharePartnerPrefix,
    handleTaggingSelectChange,
    renderTaggingOption,
    dropdownRender,
    handleSearch,
  }
}
