import { Col, List, Row } from 'antd'

import { closeSVG } from 'assets/svg/close'
import { Transaction } from 'models/transaction'
import { ListWrapper } from 'atom/list'
import TransactionPanelItem from './TransactionPanelItem'
import TransactionPanelTotal from './TransactionPanelTotal'

interface props {
  loading: boolean
  selectedTransaction: Transaction
  onShowPanel: () => void
}

const TransactionPanelCard = ({ loading, selectedTransaction, onShowPanel }: props) => {
  return (
    <ListWrapper>
      <List
        header={
          <Row justify="space-between">
            <Col>ID #{selectedTransaction.id}</Col>
            <Col className="closeIcon" onClick={onShowPanel}>
              {closeSVG}
            </Col>
          </Row>
        }
        loading={loading}
        dataSource={[selectedTransaction]}
        renderItem={(transaction) => (
          <>
            <TransactionPanelItem transaction={transaction} />
            <TransactionPanelTotal financial={transaction.financial} />
          </>
        )}
      />
    </ListWrapper>
  )
}

export default TransactionPanelCard

// ! code for transaction action buttons (ERROR, START, STOP)
// ! Leave here for now until further notice
// const TransactionActionButtons: React.FC<{
//   item: Transaction
//   actionLoading: boolean
//   onHandleClick: (item: Transaction) => void
// }> = ({ item, actionLoading, onHandleClick }) => {
//   return (
//     <Row gutter={[8, 8]} style={{ marginTop: '12px' }}>
//       <Col span={12}>
//         {item.ocppTransactionId && !hasEnded(item) ? (
//           <Button block={true} loading={actionLoading} onClick={() => onHandleClick(item)}>
//             {renderFormatMessage('dashboard.text.stop', 'Stop')}
//           </Button>
//         ) : null}
//         {!item.ocppTransactionId && !hasEnded(item) ? (
//           <Button block={true} loading={actionLoading} onClick={() => onHandleClick(item)}>
//             {renderFormatMessage('dashboard.text.start', 'Start')}
//           </Button>
//         ) : null}
//       </Col>
//       <Col span={12}>
//         {errorTransaction(item) ? (
//           <Button block={true} loading={actionLoading} onClick={() => onHandleClick(item)} className="regular-cap">
//             {renderFormatMessage('dashboard.text.error', 'Error')}
//           </Button>
//         ) : (
//           <Button block={true} loading={actionLoading} disabled={true} className="regular-cap">
//             {renderFormatMessage('dashboard.text.error', 'Error')}
//           </Button>
//         )}
//       </Col>
//     </Row>
//   )
// }
