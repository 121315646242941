import { useFormatMessage } from '../../helpers/intl'

export const useAppHeaderTranslation = () => {
  const adminDashboardText = useFormatMessage('dashboard.title.admindashboard', 'Admin Dashboard')
  const supportDashboardText = useFormatMessage('dashboard.title.supportdashboard', 'Support Dashboard')
  const dashboardText = useFormatMessage('dashboard.title', 'Dashboard')
  const logoutText = useFormatMessage('dashboard.header.logout', 'Logout')
  const chargersText = useFormatMessage('dashboard.navigation.chargers', 'Chargers')
  const transactionsText = useFormatMessage('dashboard.navigation.transactions', 'Transactions')
  const listingText = useFormatMessage('dashboard.navigation.listings', 'Listings')
  const tenantsText = useFormatMessage('dashboard.navigation.tenants', 'Sites')
  const analyticsText = useFormatMessage('dashboard.navigation.analytics', 'Analytics')
  const reportsText = useFormatMessage('dashboard.navigation.reports', 'Reports')
  const usersText = useFormatMessage('dashboard.navigation.users', 'Users')
  const peakShavingText = useFormatMessage('dashboard.navigation.peakShaving', 'Demand Management')
  const taggingText = useFormatMessage('dashboard.navigation.tagging', 'Tagging')

  const switchHomePageText = useFormatMessage('dashboard.text.switchHomePage', 'Switch to the classic dashboard view')
  const switchUsersPageText = useFormatMessage('dashboard.text.switchUsersPage', 'Switch to the classic users page')
  const switchHomePageNewVersionText = useFormatMessage(
    'dashboard.text.switchHomePageNewVersion',
    'Switch to the new dashboard view',
  )
  const switchUsersPageNewVersionText = useFormatMessage(
    'dashboard.text.switchUsersPageNewVersion',
    'Switch to the new users page',
  )

  return {
    adminDashboardText,
    supportDashboardText,
    dashboardText,
    chargersText,
    transactionsText,
    listingText,
    tenantsText,
    analyticsText,
    reportsText,
    usersText,
    peakShavingText,
    taggingText,
    logoutText,
    switchHomePageText,
    switchUsersPageText,
    switchUsersPageNewVersionText,
    switchHomePageNewVersionText,
  }
}
