export interface UserInfo {
  active: number
  guest: number
  accountHolder: number
  charging: number
  idle: number
}

export interface ConnectorInfo {
  pluggedIn: number
  pluggedInLevel2: number
  pluggedInDc: number
  available: number
  availableLevel2: number
  availableDc: number
  drProgram: number
  drProgramLevel2: number
  drProgramDc: number
  charging: number
  totalConnectors: number
}

export interface ChargerStationInfo {
  online: number
  offline: number
  other: number
  repair: number
  troubleshooting: number
  active: number
  maxAmp: number
  currentAmp: number
}

export interface ChargerInfo {
  users: UserInfo
  connectors: ConnectorInfo
  chargers: ChargerStationInfo
}

export const chargerSettings = [
  'arena_or_stadium',
  'campground',
  'fleet',
  'gas_station',
  'government',
  'hospital',
  'hotel',
  'multi_unit_residential',
  'on_street',
  'parking_lot',
  'parking_garage',
  'public',
  'retail',
  'school_university',
  'single_family_residential',
  'workplace',
]
