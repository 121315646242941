import { useState } from 'react'
import { Button, message, Modal, Space } from 'antd'

import { useAppState } from '../../state'
import { ExportType } from '../../models/transaction'
import { SendExport } from '../../services/data-provider/export'
import { SwtchError } from '../../models/error'
import { useFormatMessage } from '../../helpers/intl'

interface props {
  token?: string
  disabled: boolean
}
export const ExportTransactions: React.FC<props> = ({ token, disabled }) => {
  const { IsDesktop, currentUser, selectedTenant } = useAppState()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const isDesktop = IsDesktop()

  const showModal = () => setVisible(true)

  const sendExportEmail = (type: ExportType) => {
    if (!token) {
      return
    }
    setLoading(true)
    SendExport(token, type, selectedTenant)
      .then((msg) => message.success(msg, 10))
      .catch((err: SwtchError) => message.error(err.messages?.join(', '), 10))
      .finally(() => {
        setLoading(false)
        handleClose()
      })
  }

  const handleClose = () => setVisible(false)

  return (
    <>
      <Button type="primary" onClick={showModal} disabled={disabled} block={!isDesktop}>
        Export
      </Button>
      <Modal
        title={useFormatMessage('dashboard.transactionsPage.export.title', 'Export Data')}
        visible={visible}
        keyboard={true}
        onCancel={handleClose}
        footer={[
          <Space>
            <Button key="submit" type="primary" loading={loading} onClick={() => sendExportEmail('csv')}>
              CSV
            </Button>
            <Button key="submit" type="primary" loading={loading} onClick={() => sendExportEmail('pdf')}>
              PDF
            </Button>
          </Space>,
        ]}
      >
        <p>
          {useFormatMessage(
            'dashboard.transactionsPage.export.message',
            'Choose either to export the data via PDF or CSV. The file will be emailed to',
          )}{' '}
          {currentUser?.email}
        </p>
      </Modal>
    </>
  )
}
