import { Button, List, PageHeader, Skeleton, Space } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '../../atom/box'
import { DateTime } from '../../components/date-time'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { TransactionStateBadge } from '../../components/transactions/transaction-state-badge'
import { calculateDuration, formatDuration } from '../../helpers/duration'
import { useFormatMessage } from '../../helpers/intl'
import { formatNumber, formatPrice } from '../../helpers/number-format'
import { SwtchError } from '../../models/error'
import { errorTransaction, hasEnded, Transaction } from '../../models/transaction'
import {
  ErrorTransaction,
  GetTransaction,
  StartTransaction,
  StopTransaction,
} from '../../services/data-provider/transaction'
import { styled, theme } from '../../theme'
const { Item } = List
const ListWrapper = styled.div`
  .ant-list-item-main {
    min-width: 10px;
  }
`
const TransactionBasePage: React.FC = () => {
  // @ts-ignore
  const { transactionId } = useParams()
  const [transaction, setTransaction] = useState<Transaction>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [actLoading, setActLoading] = useState(false)

  const trasactionIDHeading = useFormatMessage('dashboard.transactionPage.transactionId', 'Transaction ID')

  const tenantHeading = useFormatMessage('dashboard.transactionPage.tenant', 'Site')
  const startMethodHeading = useFormatMessage('dashboard.transactionPage.startmethod', 'Start Method')
  const userHeading = useFormatMessage('dashboard.transactionPage.user', 'User')
  const stateHeading = useFormatMessage('dashboard.transactionPage.state', 'State')
  const batteryLevelHeading = useFormatMessage('dashboard.transactionPage.batteryLevel', 'Battery Level (%)')
  const connectedAtHeading = useFormatMessage('dashboard.transactionPage.connectedAt', 'Connected At')
  const disConnectedAtHeading = useFormatMessage('dashboard.transactionPage.disconnectedAt', 'Disconnected At')
  const pluginDurationHeading = useFormatMessage('dashboard.transactionPage.pluginDuraction', 'Plug-in Duration')
  const energyHeading = useFormatMessage('dashboard.transactionPage.energy', 'Energy (kWh)')
  const totalHeading = useFormatMessage('dashboard.transactionPage.energy', 'Total')
  const stopHeading = useFormatMessage('dashboard.transactionPage.stop', 'Stop')
  const startHeading = useFormatMessage('dashboard.transactionPage.start', 'Start')
  const errorHeading = useFormatMessage('dashboard.transactionPage.error', 'Error')
  const actionHeading = useFormatMessage('dashboard.transactionPage.error', 'Action')
  const listingHeading = useFormatMessage('dashboard.transactionPage.listing', 'Listing')
  const connectorIDHeading = useFormatMessage('dashboard.transactionPage.connectorId', 'Connector ID')
  const chargedAtHeading = useFormatMessage('dashboard.transactionPage.chargedAt', 'Charged At')
  const endchargingAtHeading = useFormatMessage('dashboard.transactionPage.endChargingAt', 'End Charging At')
  const chargingDurationHeading = useFormatMessage('dashboard.transactionPage.chargingDuration', 'Charging Duration')
  const subtotalHeading = useFormatMessage('dashboard.transactionPage.subtotal', 'Sub-Total')
  const taxHeading = useFormatMessage('dashboard.transactionPage.tax', 'Tax')
  const sellerHeading = useFormatMessage('dashboard.transactionPage.seller', 'Seller')

  useEffect(() => {
    setLoading(true)
    setError(undefined)
    GetTransaction(Number(transactionId))
      .then((transaction) => {
        setTransaction(transaction)
      })
      .catch((err: SwtchError) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [transactionId])

  const onErrorTransaction = (transaction: Transaction): Promise<void> => {
    setError(undefined)
    setActLoading(true)
    setLoading(true)
    return ErrorTransaction(transaction)
      .then((trx) => {
        setTransaction(trx)
      })
      .catch((err: SwtchError) => {
        setError(err)
      })
  }

  const onStartTransaction = (transaction: Transaction): Promise<void> => {
    setError(undefined)
    setActLoading(true)
    setLoading(true)
    return StartTransaction(transaction)
      .then((trx) => {
        setTransaction(trx)
      })
      .catch((err: SwtchError) => {
        setError(err)
      })
  }

  const onStopTransaction = (transaction: Transaction): Promise<void> => {
    setError(undefined)
    setActLoading(true)
    setLoading(true)
    return StopTransaction(transaction)
      .then((trx) => {
        setTransaction(trx)
      })
      .catch((err: SwtchError) => {
        setError(err)
      })
  }

  const handleStopTransaction = (transaction: Transaction): void => {
    onStopTransaction(transaction).finally(() => {
      setActLoading(false)
      setLoading(false)
    })
  }

  const handleStartTransaction = (transaction: Transaction): void => {
    onStartTransaction(transaction).finally(() => {
      setActLoading(false)
      setLoading(false)
    })
  }

  const handleErrorTransaction = (transaction: Transaction): void => {
    onErrorTransaction(transaction).finally(() => {
      setActLoading(false)
      setLoading(false)
    })
  }

  const format_charging_duration = (duration: number) => {
    return <span>{duration === 0 ? '---' : formatDuration(moment.duration(duration, 'seconds'))}</span>
  }

  if (!transaction) {
    return (
      <Box>
        <Skeleton active />
      </Box>
    )
  }
  const {
    id,
    startMethod,
    percent,
    user,
    startAt,
    timezone,
    endAt,
    energy,
    financial,
    listing,
    connector,
    chargeStartAt,
    chargeEndAt,
    duration,
    ocppTransactionId,
    tenant,
  } = transaction
  return (
    <>
      <PageHeader title={`${trasactionIDHeading}: ${id} `} onBack={() => window.history.back()} />
      <AlertError error={error} />
      <ListWrapper>
        <List loading={loading} itemLayout={'vertical'} bordered style={{ backgroundColor: theme.colors.white }}>
          <Item extra={tenant.displayName || tenant.name}>{tenantHeading}</Item>
          <Item extra={startMethod}>{startMethodHeading}</Item>
          <Item extra={user.name}>{userHeading}</Item>
          <Item extra={<TransactionStateBadge transaction={transaction} />}>{stateHeading}</Item>
          <Item extra={percent}>{batteryLevelHeading}</Item>
          <Item extra={<DateTime date={startAt} timezone={timezone} />}>{connectedAtHeading}</Item>
          <Item extra={endAt ? <DateTime date={endAt} timezone={timezone} /> : '---'}>{disConnectedAtHeading}</Item>
          <Item extra={!endAt ? '---' : <span>{calculateDuration(moment(startAt), moment(endAt))}</span>}>
            {pluginDurationHeading}
          </Item>
          <Item extra={formatNumber(energy)}>{energyHeading}</Item>
          <Item
            extra={
              financial.total && financial.total !== 0
                ? formatPrice(financial.total / 100)
                : formatPrice(financial.total)
            }
          >
            {totalHeading}
          </Item>
          <Item
            extra={
              <>
                <Space>
                  {ocppTransactionId && !hasEnded(transaction) && (
                    <Button loading={actLoading} onClick={() => handleStopTransaction(transaction)} block={true}>
                      {stopHeading}
                    </Button>
                  )}
                  {!ocppTransactionId && !hasEnded(transaction) && (
                    <Button
                      loading={actLoading}
                      type="primary"
                      onClick={() => handleStartTransaction(transaction)}
                      block={true}
                    >
                      {startHeading}
                    </Button>
                  )}
                  {errorTransaction(transaction) && (
                    <Button
                      loading={actLoading}
                      type="ghost"
                      danger
                      onClick={() => handleErrorTransaction(transaction)}
                      block={true}
                    >
                      {errorHeading}
                    </Button>
                  )}
                </Space>
              </>
            }
          >
            {actionHeading}
          </Item>
          <Item extra={listing.title}>{listingHeading}</Item>
          <Item extra={connector.id}>{connectorIDHeading}</Item>
          <Item extra={chargeStartAt ? <DateTime date={chargeStartAt} timezone={timezone} /> : ''}>
            {chargedAtHeading}
          </Item>
          <Item extra={chargeEndAt ? <DateTime date={chargeEndAt} timezone={timezone} /> : ''}>
            {endchargingAtHeading}
          </Item>
          <Item extra={format_charging_duration(duration)}>{chargingDurationHeading}</Item>
          <Item extra={formatPrice(financial.subtotal / 100)}>{subtotalHeading}</Item>
          <Item extra={formatPrice(financial.tax / 100)}>{taxHeading}</Item>
          <Item extra={formatPrice(financial.tenantAmount / 100)}>{sellerHeading}</Item>
        </List>
      </ListWrapper>
    </>
  )
}
export const TransactionPage = withAuthenticatedLayout(TransactionBasePage)
