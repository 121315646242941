import { useState } from 'react'
import { SwtchError } from '../../models/error'
import { Button, Modal, message } from 'antd'
import { AlertErrorCompact } from '../error-compact'
import { Subscription } from '../../models/subscription'
import { PauseSubscription } from '../../services/data-provider/subscriptions'
import { FooterLayout } from '../../atom/user-edit'
import { HeadingWrapper, TextBodyWrapper } from '../../atom/plans'
import { useSubscriptionTranslation } from '../../hooks/translation/useSubscriptionTranslation'

interface props {
  subscription: Subscription
  onCancel: () => void
  onPauseSubscription: () => void
}

const PauseSubscriptionRedesignModal: React.FC<props> = ({ subscription, onCancel, onPauseSubscription }) => {
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const { closeText, pauseMessageText } = useSubscriptionTranslation()

  const onOk = () => {
    setError(undefined)
    setLoading(true)
    PauseSubscription(subscription)
      .then((msg) => {
        message.success(msg)
        onPauseSubscription()
      })
      .catch((error: SwtchError) => setError(error))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={<HeadingWrapper>Pause Subscription</HeadingWrapper>}
      cancelText="Cancel"
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      width={568}
      afterClose={onCancel}
      footer={
        <FooterLayout>
          <Button key="back" loading={loading} onClick={onCancel}>
            {closeText}
          </Button>
          <Button className="red" key="submit" type="primary" danger loading={loading} onClick={onOk}>
            Pause Subscription
          </Button>
        </FooterLayout>
      }
    >
      <AlertErrorCompact error={error} />
      <TextBodyWrapper>
        {pauseMessageText} <strong>"{subscription.subscriber.email}"</strong>?
      </TextBodyWrapper>
    </Modal>
  )
}

export default PauseSubscriptionRedesignModal
