import { Button, Form, Select, Spin, Tag } from 'antd'
import { NewListing } from '../../models/listing'
import { theme } from '../../theme'
import WarningInfo from '../info/WarningInfo'
import { AlertError } from '../error'
import { useListingPricingSchema } from '../../hooks/useListingPricingSchema'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const ListingPricingSchema: React.FC<props> = ({ listings, onOk }) => {
  const {
    error,
    loading,
    allSameTenant,
    tenantName,
    pricingSchemaOptions,
    pricingSchemaIsRequired,
    pricingSchemaCanNotUpdate,
    onFinish,
  } = useListingPricingSchema(listings, onOk)

  const { applyText, tenantText, pricingPlanText } = useGeneralTranslation()

  return (
    <>
      <Form onFinish={onFinish}>
        <AlertError error={error} />
        {allSameTenant ? (
          <>
            <Form.Item name="tenant" label={tenantText}>
              <Tag color={theme.colors.cultered} closable={false}>
                {tenantName}
              </Tag>
            </Form.Item>
            <Form.Item
              name="pricing_schema_id"
              label={pricingPlanText}
              rules={[{ required: true, message: pricingSchemaIsRequired }]}
              className="pricing-schema"
            >
              <Select
                placeholder={pricingPlanText}
                notFoundContent={loading ? <Spin size="small" /> : 'Retrieving Pricing Schema...'}
                loading={loading}
                disabled={loading}
              >
                {pricingSchemaOptions.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {applyText}
              </Button>
            </Form.Item>
          </>
        ) : (
          <WarningInfo message={pricingSchemaCanNotUpdate} />
        )}
      </Form>
    </>
  )
}
