import { Modal, Skeleton, Tabs } from 'antd'
import { ChargerBase, ChargerRef } from '../../../models/charger'
import { RemoteMultiChargerTriggerMessageParams } from '../../../models/charger-remote'
import { RemoteFirmwareUpdateControllerRedesign } from '../../../components/chargers/redesign/remote-firmware-update-controller-redesign'
import { RemoteUpdateConfigurationRedesign } from '../../../components/chargers/redesign/remote-update-configuration-redesign'
import { RemoteRestartChargerControllerRedesign } from '../../../components/chargers/redesign/remote-restart-charger-controller-redesign'
import { RemoteClearCacheControllerRedesign } from '../../../components/chargers/redesign/remote-clear-cache-controller-redesign'
import { RemoteTriggerMultipleChargersRedesign } from '../../../components/chargers/redesign/remote-trigger-multiple-chargers-redesign'
import { TabsWrapper } from '../../../atom/chargers'
import { useChargerTranslation } from '../../../hooks/translation/useChargerTranslation'
import { Heading } from '../../../atom/user-edit'

interface props {
  loading: boolean
  selectedCharger: ChargerRef[] | ChargerBase[]
  selectedChargersConns: RemoteMultiChargerTriggerMessageParams[]
  onOk: () => void
  onCancel: () => void
}

export const ChargerModalRedesign: React.FC<props> = ({
  loading,
  selectedCharger,
  selectedChargersConns,
  onOk,
  onCancel,
}) => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const {
    updateChargersText,
    selectedText,
    firmwareText,
    configurationText,
    restartText,
    triggerMessageText,
    clearCacheText,
  } = useChargerTranslation()

  const modalTitle = `${updateChargersText} (${selectedCharger.length} ${selectedText})`

  return (
    <Modal
      title={<Heading>{modalTitle}</Heading>}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
      width={600}
    >
      <TabsWrapper>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={firmwareText} key="firmware">
            <Skeleton loading={loading}>
              {selectedCharger && <RemoteFirmwareUpdateControllerRedesign chargers={selectedCharger} />}
            </Skeleton>
          </Tabs.TabPane>

          <Tabs.TabPane tab={configurationText} key="configuration">
            <Skeleton loading={loading}>
              {selectedCharger && <RemoteUpdateConfigurationRedesign chargers={selectedCharger} />}
            </Skeleton>
          </Tabs.TabPane>

          <Tabs.TabPane tab={restartText} key="restart">
            <Skeleton loading={loading}>
              {selectedCharger && <RemoteRestartChargerControllerRedesign chargers={selectedCharger} />}
            </Skeleton>
          </Tabs.TabPane>

          <Tabs.TabPane tab={clearCacheText} key="clear_cache">
            {selectedCharger && <RemoteClearCacheControllerRedesign chargers={selectedCharger} />}
          </Tabs.TabPane>

          <Tabs.TabPane tab={triggerMessageText} key="trigger">
            {selectedCharger && <RemoteTriggerMultipleChargersRedesign chargers={selectedChargersConns} />}
          </Tabs.TabPane>
        </Tabs>
      </TabsWrapper>
    </Modal>
  )
}
