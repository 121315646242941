import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Form, Modal, Select, Switch, Tooltip, message } from 'antd'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { User, UserInviteInfo, UserPermissions, UserRef, userInviteInfo } from '../../models/user'
import { NewInviteUser } from '../../services/data-provider/users'
import { theme } from '../../theme'
import { FindTenants } from '../../services/data-provider/tenants'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { addSVG } from '../../assets/svg/add'
import { sendSVG } from '../../assets/svg/send'
import { AlertError } from '../../components/error'
import {
  AddMoreWrapper,
  ButtonTextSpacing,
  FooterLayout,
  Heading,
  HeadingDescription,
  MobileBody,
  MobileLineContainer,
  SvgSpacing,
  SwitchLabel,
  TenantInputMobileBody,
} from '../../atom/user-invite'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import UserEmail from './modal/user-email'
import { DropdownWrapper, SelectWrapper } from '../../atom/user-edit'
import { useAppState } from '../../state'
import { StyledDrawer } from '../../atom/drawer'
import { HorizontalLine } from '../../atom/horizontal-line'
import { UserInviteTenantsAdminView } from './modal/user-invite-tenants-admin-view'
import { UserInviteTenants } from './modal/user-invite-tenants'
import { AddUsersToDiscounts } from 'services/data-provider/discount'
import { DiscountSchema } from 'models/discount'

const { Option } = Select

interface props {
  open: boolean
  setOpen: (open: boolean) => void
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserInvited: (user: User) => void
}

export const UserInvitationRedesign: React.FC<props> = ({ open, setOpen, onCancel, onUserInvited }) => {
  const { currentUser, IsMobile } = useAppState()
  const isMobile = IsMobile()
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchError>()
  const [selectedUsers, setSelectedUsers] = useState<UserInviteInfo[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [tenantInputs, setTenantInputs] = useState(1)
  const [tenantDropdownData, setTenantDropdownData] = useState({ 1: [...tenants] })
  const [loading, setLoading] = useState(false)
  const [validateStatus, setValidateStatus] = useState<string>('')
  const [disableSendInviteButton, setDisableSendInviteButton] = useState(true)
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([
    { name: '', id: '', access: '', driver: false },
  ])
  const [currentDiscounts, setCurrentDiscounts] = useState<{ [key: number]: DiscountSchema[] }>({})
  const [isManager, setIsManager] = useState(false)
  const {
    heading,
    headingInfo,
    role2Text,
    tenantsInviteText,
    driverInviteText,
    addMoreInviteText,
    tooltipInviteText,
    managerInviteText,
    viewerInviteText,
    sendInvite,
    cancelInviteText,
    chargerOperatorText,
  } = useUserTranslation()

  //To filter out charger operator if the user is not admin
  const userPermissionInfo = userInviteInfo.filter(
    (item) => currentUser?.role === 'admin' || item !== 'charger_operator',
  )
  const roleTextTransform = {
    none: '-',
    manager: managerInviteText,
    charger_operator: chargerOperatorText,
    viewer: viewerInviteText,
  }

  useEffect(() => {
    FindTenants()
      .then((tenants) => {
        const tenantsArr: TenantRef[] = []
        tenants.map((tenant) => {
          if (tenant.name) tenantsArr.push(tenant)
          return tenantsArr
        })
        setTenants([...tenantsArr])
        setTenantDropdownData({ 1: [...tenantsArr] })
      })
      .catch((err: SwtchError) => {
        setTenants([])
        message.error(err.description)
        setError(err)
      })
    setOpen(true)

    let manager = false
    currentUser?.accesses.map((access) => {
      access.permissions.map((permission) => {
        if (permission === 'tenant.manager') manager = true
        return manager
      })
      return manager
    })
    setIsManager(manager)
  }, [])

  useEffect(() => {
    let disable = false
    userPermissions.map((permission) => {
      if (permission.name === '' || permission.access === '' || permission.id === '') {
        disable = true
      }
      return disable
    })
    if (disable) {
      setDisableSendInviteButton(true)
    } else {
      setDisableSendInviteButton(false)
    }
    setOpen(true)
  }, [userPermissions])

  useEffect(() => {
    form.validateFields(['email'])
  }, [form.getFieldValue('email')])

  const afterClose = () => {
    form.resetFields()
  }

  const handleDiscount = (selectedDiscounts: DiscountSchema[], index: number) => {
    const tempData = currentDiscounts
    tempData[index] = selectedDiscounts
    setCurrentDiscounts(tempData)
  }

  const onOk = () => {
    if (userPermissions[0].name === '') {
      return
    }
    const tempSelectedUsersArr: UserRef[] = []
    selectedUsers.map((user) => {
      const tempSelectedUsersObj = {
        id: user.id,
        email: user.email,
        name: user.name,
      }
      return tempSelectedUsersArr.push(tempSelectedUsersObj)
    })
    NewInviteUser(userPermissions, tempSelectedUsersArr)
      .then((user) => {
        message.success(`Invited users`)
        const localDiscounts = Object.values(currentDiscounts)
          .flat()
          .map((discount) => ({
            id: discount.id,
            name: discount.name,
          }))
        const users = selectedUsers.map((user) => ({
          email: user.email,
        }))

        if (currentUser?.role === 'admin' || isManager === true) {
          AddUsersToDiscounts(localDiscounts, users)
            .then((res) => {})
            .catch((err) => {
              setError(err)
            })
            .finally(() => setLoading(false))
        }
        return onUserInvited(user)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  const handleTenantChange = (value: string, index: number) => {
    const tempData = userPermissions
    let id = ''
    tenantDropdownData[1].map((tenant: any) => {
      if (tenant.name === value) {
        return (id = tenant.id)
      } else return null
    })
    tempData[index] = { name: value, id: id, access: tempData[index].access, driver: tempData[index].driver }
    setUserPermissions([...tempData])
  }

  const handleRoleChange = (value: string, index: number) => {
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: value,
      driver: tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleDriverChange = (index: number) => {
    const tempData = userPermissions
    tempData[index] = {
      name: tempData[index].name,
      id: tempData[index].id,
      access: tempData[index].access,
      driver: !tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const selectTenant = (value: string, index: number) => {
    let id = 1
    tenantDropdownData[1].map((tenant) => {
      if (tenant.name === value) id = Number(tenant.id)
      return id
    })
    handleTenantChange(value, index)
    setCurrentDiscounts({ ...currentDiscounts, [index]: [] })
  }

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    return (
      <Option style={{ marginTop: '10px' }} key={tenant.id} value={tenant.name}>
        <DropdownWrapper selected={userPermissions[index].name === tenant.name}>{tenant.name}</DropdownWrapper>
      </Option>
    )
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={
            <>
              <Heading>{heading}</Heading>
              <HeadingDescription>{headingInfo}</HeadingDescription>
            </>
          }
          closable={true}
          visible={true}
          afterClose={afterClose}
          onCancel={onCancel}
          onOk={onOk}
          width={543}
          footer={
            <FooterLayout disabled={disableSendInviteButton}>
              <Button style={{ width: '240px' }} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelInviteText}</span>
              </Button>
              <Button style={{ width: '240px' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                <ButtonTextSpacing>
                  <span className="paragraph-02-regular">{sendInvite}</span>
                </ButtonTextSpacing>
                {sendSVG}
              </Button>
            </FooterLayout>
          }
        >
          <Form form={form} labelAlign="right" layout="vertical">
            <UserEmail
              selectedUsers={selectedUsers}
              validateStatus={validateStatus}
              loading={loading}
              setValidateStatus={setValidateStatus}
              setSelectedUsers={setSelectedUsers}
              setLoading={setLoading}
            />
            {selectedUsers.length > 0 && (
              <>
                {currentUser?.role === 'admin' || isManager === true ? (
                  <UserInviteTenantsAdminView
                    userPermissions={userPermissions}
                    error={error}
                    tenantDropdownData={tenantDropdownData}
                    userPermissionInfo={userPermissionInfo}
                    roleTextTransform={roleTextTransform}
                    tenantInputs={tenantInputs}
                    tenants={tenants}
                    currentDiscounts={currentDiscounts}
                    selectTenant={selectTenant}
                    handleTenantChange={handleTenantChange}
                    handleRoleChange={handleRoleChange}
                    handleDriverChange={handleDriverChange}
                    setUserPermissions={setUserPermissions}
                    setTenantInputs={setTenantInputs}
                    setTenantDropdownData={setTenantDropdownData}
                    handleDiscount={handleDiscount}
                  />
                ) : (
                  <UserInviteTenants
                    userPermissions={userPermissions}
                    error={error}
                    tenantDropdownData={tenantDropdownData}
                    userPermissionInfo={userPermissionInfo}
                    roleTextTransform={roleTextTransform}
                    tenantInputs={tenantInputs}
                    tenants={tenants}
                    handleTenantChange={handleTenantChange}
                    handleRoleChange={handleRoleChange}
                    handleDriverChange={handleDriverChange}
                    setUserPermissions={setUserPermissions}
                    setTenantInputs={setTenantInputs}
                    setTenantDropdownData={setTenantDropdownData}
                  />
                )}
              </>
            )}
          </Form>
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            selectedUsers.length > 0 ? (
              <FooterLayout disabled={disableSendInviteButton}>
                <Button style={{ width: '100%' }} disabled={disableSendInviteButton} type="primary" onClick={onOk}>
                  <ButtonTextSpacing>
                    <span className="paragraph-02-regular">{sendInvite}</span>
                  </ButtonTextSpacing>
                  {sendSVG}
                </Button>
              </FooterLayout>
            ) : null
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{heading}</Heading>
          <HeadingDescription>{headingInfo}</HeadingDescription>
          <HorizontalLine />
          <Form form={form} labelAlign="right" layout="vertical">
            <UserEmail
              selectedUsers={selectedUsers}
              validateStatus={validateStatus}
              loading={loading}
              setValidateStatus={setValidateStatus}
              setSelectedUsers={setSelectedUsers}
              setLoading={setLoading}
            />
            {selectedUsers.length > 0 && (
              <>
                <HorizontalLine />
                <TenantInputMobileBody>
                  <MobileBody>
                    <Form.Item className="tenant-select" label={tenantsInviteText}>
                      <Select
                        dropdownMatchSelectWidth={false}
                        showSearch
                        onChange={(value) => handleTenantChange(value, 0)}
                        value={userPermissions[0].name}
                      >
                        {tenantDropdownData[1]
                          .map((v) => renderOption(v, 0))
                          .sort(
                            (a: ReactElement, b: ReactElement) =>
                              b.props.children.props.checked - a.props.children.props.checked,
                          )}
                      </Select>
                    </Form.Item>
                    <Form.Item className="role-select" key="role" label={role2Text} name={0}>
                      <SelectWrapper>
                        <Select onChange={(value) => handleRoleChange(value, 0)}>
                          {userPermissionInfo.map((role) => (
                            <Select.Option key={role} value={role}>
                              <DropdownWrapper selected={userPermissions[0].access === role}>
                                {roleTextTransform[role]}
                              </DropdownWrapper>
                            </Select.Option>
                          ))}
                        </Select>
                      </SelectWrapper>
                    </Form.Item>
                    <Form.Item
                      className="switch"
                      key="driver"
                      label={
                        <>
                          {driverInviteText}
                          <Tooltip
                            overlayInnerStyle={{ borderRadius: '5px' }}
                            placement="top"
                            title={tooltipInviteText}
                          >
                            <ExclamationCircleFilled
                              style={{ fontSize: theme.fontSizes[3], marginLeft: '5px', color: theme.colors.lightGray }}
                            />
                          </Tooltip>
                        </>
                      }
                      name="driver"
                    >
                      <div>
                        <SwitchLabel>Off</SwitchLabel>
                        <Switch
                          style={{
                            marginLeft: '5px',
                            marginRight: '5px',
                            backgroundColor: userPermissions[0].driver ? theme.colors.primary : '#bfbfbf',
                          }}
                          onChange={() => handleDriverChange(0)}
                        ></Switch>
                        <SwitchLabel>On</SwitchLabel>
                      </div>
                    </Form.Item>
                  </MobileBody>
                  {tenantInputs &&
                    [...Array(tenantInputs)].map((tenantInput, tenantInputIndex) => {
                      return (
                        <MobileBody key={tenantInputIndex}>
                          {tenantInputIndex > 0 && (
                            <>
                              <HorizontalLine />
                              <Form.Item className="tenant-select" label={tenantsInviteText}>
                                <Select
                                  dropdownMatchSelectWidth={false}
                                  showSearch
                                  onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                                  value={userPermissions[tenantInputIndex].name}
                                >
                                  {tenantDropdownData[tenantInputIndex as keyof typeof tenantDropdownData]
                                    .map((v) => renderOption(v, tenantInputIndex))
                                    .sort(
                                      (a: ReactElement, b: ReactElement) =>
                                        b.props.children.props.checked - a.props.children.props.checked,
                                    )}
                                </Select>
                              </Form.Item>
                              <Form.Item className="role-select" key="role" label={role2Text}>
                                <SelectWrapper>
                                  <Select onChange={(value) => handleRoleChange(value, tenantInputIndex)}>
                                    {userPermissionInfo.map((role) => (
                                      <Select.Option key={role} value={role}>
                                        <DropdownWrapper selected={userPermissions[tenantInputIndex].access === role}>
                                          {roleTextTransform[role]}
                                        </DropdownWrapper>
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SelectWrapper>
                              </Form.Item>
                              <Form.Item
                                className="switch"
                                key="driver"
                                name="driver"
                                label={
                                  <>
                                    {driverInviteText}
                                    <Tooltip
                                      overlayInnerStyle={{ borderRadius: '5px' }}
                                      placement="top"
                                      title={tooltipInviteText}
                                    >
                                      <ExclamationCircleFilled
                                        style={{
                                          fontSize: theme.fontSizes[3],
                                          marginLeft: '5px',
                                          color: theme.colors.lightGray,
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                }
                              >
                                <SwitchLabel>Off</SwitchLabel>
                                <Switch
                                  style={{
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    backgroundColor: userPermissions[tenantInputIndex].driver
                                      ? theme.colors.primary
                                      : '#bfbfbf',
                                  }}
                                  onChange={() => handleDriverChange(tenantInputIndex)}
                                ></Switch>
                                <SwitchLabel>On</SwitchLabel>
                              </Form.Item>
                            </>
                          )}
                        </MobileBody>
                      )
                    })}
                </TenantInputMobileBody>
                <HorizontalLine />
                <AddMoreWrapper>
                  <Form.Item className="add-more-button">
                    <Button
                      style={{
                        color: theme.colors.primary,
                        padding: 0,
                        height: 'fit-content',
                        marginBottom: '0px',
                        letterSpacing: '0.05em',
                      }}
                      type="link"
                      onClick={() => {
                        setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                        setTenantInputs((currentTenantInputs) => currentTenantInputs + 1)
                        setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
                      }}
                    >
                      <SvgSpacing>{addSVG}</SvgSpacing>
                      {addMoreInviteText}
                    </Button>
                  </Form.Item>
                </AddMoreWrapper>
              </>
            )}
          </Form>
        </StyledDrawer>
      )}
    </>
  )
}
