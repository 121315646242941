import { useEffect, useState } from 'react'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, Pagination, Space, Table, Tag, Tooltip, Typography } from 'antd'

import type { TableProps } from 'antd/es/table'

import { AlertError } from '../error'
import { PaginationMeta } from '../../models/pagination'
import { Subscription } from '../../models/subscription'
import { SwtchError } from '../../models/error'

import { GetSubscriptions } from '../../services/data-provider/subscriptions'
import { CancelSubscriptionModal } from './form/cancel-subscription-modal'
import CreateSubscriptionForm from './form/CreateSubscriptionForm'
import { useAppState } from '../../state'
import { SubscriptionStatusBadge } from './subscription-status-badge'
import { SubscriptionCollapseView } from '../../pages/subscription/views/subscription-collapse-view'
import { theme } from '../../theme'
import { UserRef } from '../../models/user'
import PauseSubscriptionModal from './PauseSubscription'
import UnpauseSubscriptionModal from './UnpauseSubscription'
import { useSubscriptionTranslation } from '../../hooks/translation/useSubscriptionTranslation'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { formatMomentDate } from '../../helpers/date-format'

interface props {
  searchUser?: UserRef
  showInviteForm: boolean
  onUserInvited: () => void
}

export const Subscriptions: React.FC<props> = ({ searchUser, showInviteForm, onUserInvited }) => {
  const { IsMobile, IsTablet, IsDesktop, IsLaptop } = useAppState()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  const [cancelSubscription, setCancelSubscription] = useState<Subscription>()
  const [pauseSubscription, setPauseSubscription] = useState<Subscription>()
  const [unpauseSubscription, setUnpauseSubscription] = useState<Subscription>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<string | undefined>()
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState<SwtchError>()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  useEffect(() => {
    getSubscriptions()
  }, [currentPage, filter, searchUser])

  const getSubscriptions = () => {
    setError(undefined)
    setLoading(true)
    GetSubscriptions(currentPage, filter, searchUser)
      .then((resp) => {
        setSubscriptions(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const { billingAddressText, cancelSubText, unpauseSubText, pauseSubText, planText } = useSubscriptionTranslation()
  const { statusText, startDateText, endDateText, refreshText, unavailableText } = useGeneralTranslation()

  const handleNewSubscription = (newSub: Subscription) => {
    setSubscriptions([...subscriptions, newSub])
    onUserInvited()
  }

  const handleCancelSubscription = (deleteSub: Subscription) => {
    const remainingSub = subscriptions.filter((sub) => sub.id !== deleteSub.id)
    setSubscriptions(remainingSub)
    setCancelSubscription(undefined)
  }

  const handlePauseSubscription = (pauseSub: Subscription) => {
    setSubscriptions((prevSubs) => {
      return prevSubs.map((sub) => {
        if (sub.id === pauseSub.id) {
          return { ...pauseSub, status: 'paused' }
        }
        return sub
      })
    })
    setPauseSubscription(undefined)
  }

  const handleUnpauseSubscription = (unpauseSub: Subscription) => {
    setSubscriptions((prevSubs) => {
      return prevSubs.map((sub) => {
        if (sub.id === unpauseSub.id) {
          return { ...unpauseSub, status: 'active' }
        }
        return sub
      })
    })
    setUnpauseSubscription(undefined)
  }

  const onPaginationChange = (page: number, pageSize?: number) => setCurrentPage(page)

  const handleUnavailablePlan = (subscription: Subscription) => (
    <Tooltip title="No plan is associated">
      <Tag key={subscription.id} color="red">
        {unavailableText}
      </Tag>
      <ExclamationCircleOutlined style={{ fontSize: theme.fontSizes[2] }} />
    </Tooltip>
  )

  const handleStatus = (subscription: Subscription) => {
    return !subscription.plan ? (
      handleUnavailablePlan(subscription)
    ) : (
      <SubscriptionStatusBadge subscription={subscription} />
    )
  }

  const handleBillingAddress = (subscription: Subscription) => {
    if (subscription.billingAddress) {
      const { zip, state, country } = subscription.billingAddress
      return (
        <span>
          {state}, {zip}, {country}
        </span>
      )
    }
    return null
  }

  const handleRefreshTitle = (
    <Typography.Link disabled={loading} onClick={() => getSubscriptions()}>
      {<ReloadOutlined />} {refreshText}
    </Typography.Link>
  )

  const disableUnpauseButton = (sub: Subscription) => {
    return !sub.plan || sub.status === 'active' || sub.status === 'inactive' || sub.status === 'pending'
  }

  const handleSubscriptionAction = (sub: Subscription) => {
    return (
      <Space>
        <Tooltip title={pauseSubText}>
          <Button
            type="primary"
            onClick={() => setPauseSubscription(sub)}
            disabled={!sub.plan || sub.status === 'paused'}
          >
            <PauseOutlined key="pause" />
          </Button>
        </Tooltip>
        <Tooltip title={unpauseSubText}>
          <Button type="primary" disabled={disableUnpauseButton(sub)} onClick={() => setUnpauseSubscription(sub)}>
            <PlayCircleOutlined key="unpause" />
          </Button>
        </Tooltip>
        <Tooltip title={cancelSubText}>
          <Button
            type="primary"
            disabled={!sub.plan || sub.status === 'canceled'}
            onClick={() => setCancelSubscription(sub)}
          >
            <DeleteOutlined key="cancelSubscription" />
          </Button>
        </Tooltip>
      </Space>
    )
  }

  const columns: any = [
    { title: planText, dataIndex: ['plan', 'name'] },
    { title: 'Email', dataIndex: ['subscriber', 'email'] },
    {
      title: billingAddressText,
      key: 'billingAdress',
      render: (subscription: Subscription) => handleBillingAddress(subscription),
    },
    {
      title: statusText,
      key: 'status',
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Cancelled', value: 'canceled' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Pending', value: 'pending' },
        { text: 'Paused', value: 'paused' },
      ],
      filterMultiple: false,
      width: '10%',
      render: (subscription: Subscription) => handleStatus(subscription),
    },
    {
      title: startDateText,
      key: 'startDate',
      render: (sub: Subscription) => formatMomentDate(sub.startDate, 'YYYY-MM-DD'),
    },
    {
      title: endDateText,
      key: 'endDate',
      render: (sub: Subscription) => (sub.endDate ? formatMomentDate(sub.endDate, 'YYYY-MM-DD') : '---'),
    },
    {
      title: handleRefreshTitle,
      key: 'charger_action',
      align: 'right' as 'right',
      render: (sub: Subscription) => handleSubscriptionAction(sub),
    },
  ]

  const onPageChange = (page: number) => setCurrentPage(page)

  const handleChange: TableProps<any>['onChange'] = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (!filters.status) {
      setFilter(undefined)
    } else {
      setFilter(filters.status[0])
    }
    setCurrentPage(pagination.current)
  }

  return (
    <>
      <AlertError error={error} />
      {(isDesktop || isLaptop) && (
        <Table
          loading={loading}
          rowKey="id"
          dataSource={subscriptions}
          columns={columns}
          onChange={handleChange}
          pagination={{
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
        />
      )}
      {(isMobile || isTablet) && (
        <>
          <SubscriptionCollapseView
            loading={loading}
            subscriptions={subscriptions}
            pauseSub={(sub) => setPauseSubscription(sub)}
            unpauseSub={(sub) => setUnpauseSubscription(sub)}
            cancelSub={(sub) => setCancelSubscription(sub)}
          />
          <br />
          <Pagination
            current={currentPage}
            onChange={onPageChange}
            total={pagination?.totalEntries}
            showQuickJumper={false}
            showSizeChanger={false}
            showTitle={true}
            style={{ textAlign: 'center' }}
          />
        </>
      )}

      {pauseSubscription && (
        <PauseSubscriptionModal
          subscription={pauseSubscription}
          onCancel={() => setPauseSubscription(undefined)}
          onPauseSubscription={() => handlePauseSubscription(pauseSubscription)}
        />
      )}
      {unpauseSubscription && (
        <UnpauseSubscriptionModal
          subscription={unpauseSubscription}
          onCancel={() => setUnpauseSubscription(undefined)}
          onUnpauseSubscription={() => handleUnpauseSubscription(unpauseSubscription)}
        />
      )}
      {cancelSubscription && (
        <CancelSubscriptionModal
          subscription={cancelSubscription}
          onCancel={() => setCancelSubscription(undefined)}
          onCancelSubscription={() => handleCancelSubscription(cancelSubscription)}
        />
      )}
      {showInviteForm && <CreateSubscriptionForm onCancel={onUserInvited} onSubscribe={handleNewSubscription} />}
    </>
  )
}
