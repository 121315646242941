import { useEffect, useRef, useState } from 'react'
import { SwtchError } from '../models/error'
import { ListingBulkUpdateDetails, NewListing, StripeAccountOption } from '../models/listing'
import { UpdateBulkListings } from 'services/data-provider/listing'
import { useNotifications } from './useNotification'
import { FormInstance, SelectProps, message } from 'antd'
import { GetAllStripeAccounts } from 'services/data-provider/stripe'
import { StripeAccount } from 'models/stripe-account'
import { useListingsTranslation } from './translation/useListingsTranslation'

export const useListingSettlement = (form: FormInstance<any>, listings: NewListing[], onOk: () => void) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [comissionFeeType, setComissionFeeType] = useState(form.getFieldValue('commisionPercent'))
  const [stripeAccountOptions, setStripeAccountOptions] = useState<SelectProps<object>['options']>([])
  const [defaultStripeAccount, setDefaultStripeAccount] = useState<number | null>(null)
  const selectRef = useRef<any>(null)
  const { openSuccessNotification } = useNotifications()
  const { listingUpdated, listingsUpdated } = useListingsTranslation()

  const location = (listings: NewListing[], stripeAccounts: StripeAccount[]) => {
    let canada = false
    let swtchCaId = null
    let us = false
    let swtchUsId = null

    listings.forEach((listing) => {
      if (listing.tenant?.country === 'CA') canada = true
      if (listing.tenant?.country === 'US') us = true
    })
    stripeAccounts.forEach((stripeAccount) => {
      if (stripeAccount.name === 'SWTCH Energy Inc.') {
        if (stripeAccount.country === 'CA') swtchCaId = stripeAccount.id
        if (stripeAccount.country === 'US') swtchUsId = stripeAccount.id
      }
    })

    if (canada && us) setDefaultStripeAccount(null)
    if (canada) setDefaultStripeAccount(swtchCaId)
    if (us) setDefaultStripeAccount(swtchUsId)
  }

  const renderStripeAccount = (stripeAccount: StripeAccount): StripeAccountOption => {
    return {
      id: stripeAccount.id,
      value: stripeAccount.id,
      label: <span>{`${stripeAccount.name} [${stripeAccount.country || '---'}]`}</span>,
    }
  }

  useEffect(() => {
    GetAllStripeAccounts()
      .then((stripeAccounts) => {
        const opts = stripeAccounts.map((stripeAcc) => renderStripeAccount(stripeAcc))
        setStripeAccountOptions(opts)
        location(listings, stripeAccounts)
      })
      .catch((err: SwtchError) => {
        message.error(err.description)
      })
  }, [])

  useEffect(() => {
    form.setFieldsValue({ stripe_account: defaultStripeAccount })
  }, [defaultStripeAccount])

  const initialValues = {
    commission_percent: '10%',
    stripe_account: defaultStripeAccount,
  }

  const handleSelect = (value: string) => {
    if (value === 'other') {
      // Close the dropdown when 'other' is selected
      if (selectRef) {
        selectRef.current.blur()
      }
    }
  }

  const onFinish = (values: ListingBulkUpdateDetails) => {
    setError(undefined)
    setLoading(true)

    const data: any = {
      stripe_account_id: values['stripe_account'],
    }

    if (values['commission_percent'] === '2.9% + 30c') {
      data['additional_service_fee'] = true
      data['partner_commission_percent'] = null
    } else {
      data['additional_service_fee'] = false
      if (values['commission_percent'] === 'define_new') {
        data['commision_percent'] = values['custom_commission_fee'] ?? 0
        data['partner_commission_percent'] = values['partner_commission_percent'] ?? 0
      } else {
        data['commision_percent'] = 10
        data['partner_commission_percent'] = null
      }
    }

    UpdateBulkListings({
      listing_ids: listings.map((f) => f.id),
      listing: data,
    })
      .then((resp) => {
        if (resp) {
          openSuccessNotification(listings.length > 1 ? listingsUpdated : listingUpdated)
        }
        onOk()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  return {
    error,
    loading,
    selectRef,
    comissionFeeType,
    stripeAccountOptions,
    initialValues,
    handleSelect,
    onFinish,
    setComissionFeeType,
  }
}
