import { Input } from 'antd'
import { SingleSelector } from '../../../../atom/select'
import { useSelectorTranslation } from '../../../../hooks/translation/useSelectorTranslation'
import { magnifySVG } from '../../../../assets/svg/magnify'
import { AdvancedSearchProps } from '.'

type usernameProps = Pick<AdvancedSearchProps, 'onUsername' | 'filter' | 'onFetchTransactions'>

const UsernameSelector: React.FC<usernameProps> = ({ filter, onUsername, onFetchTransactions }) => {
  const { usernameText } = useSelectorTranslation()
  const handleUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target
    onUsername(inputValue)
  }
  return (
    <SingleSelector>
      <Input
        placeholder={usernameText}
        value={filter.userName}
        onChange={handleUser}
        prefix={magnifySVG}
        style={{ minWidth: 272 }}
        onPressEnter={onFetchTransactions}
      />
    </SingleSelector>
  )
}

export default UsernameSelector
