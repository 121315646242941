import { Card } from 'antd'
import { ChargerStationInfo, ConnectorInfo } from 'models/chargerv2'
import { ChartContainer } from 'pages/dashboard/ContainerStyles/homepageStyles'

interface props {
  title: string
  label: string
  loading: boolean
  connectorInfo?: ConnectorInfo
  chargerStationInfo?: ChargerStationInfo
  Chart: React.ComponentType<any>
  chartData: {
    label: string
    data?: number
    onClick?: () => void
    color: string
  }[]
  chartInfo: {
    title?: string | number
    label: string
    min: string | number
    max: string | number
    height: number
  }
  dataFormatter?: (data: string) => string
}

const CoreStationCard: React.FC<props> = ({
  title,
  label,
  loading,
  connectorInfo,
  chargerStationInfo,
  Chart,
  chartData,
  chartInfo,
  dataFormatter,
}) => {
  return (
    <Card title={<span className="heading-06-bold">{title}</span>} className={label} loading={loading}>
      <ChartContainer>
        {chargerStationInfo && connectorInfo && (
          <Chart
            chartInfo={chartInfo}
            chartData={chartData}
            chargerStationInfo={chargerStationInfo}
            connectorInfo={connectorInfo}
            dataFormatter={dataFormatter}
          />
        )}
      </ChartContainer>
    </Card>
  )
}

export default CoreStationCard
