import { CarOutlined } from '@ant-design/icons'
import { AutoComplete, Input, message, Tag } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useState } from 'react'

import { GetChargers } from '../../services/data-provider/charger'

import { NoSearchIcon } from '../../atom/search-icon'
import { useFormatMessage } from '../../helpers/intl'
import { ChargerRef } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'

const { Search } = Input

interface props {
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  onChargerSelected: (charger: ChargerRef) => void
  onClear: () => void
  onSearch: (charger: ChargerRef) => void
}

const renderCharger = (charger: ChargerRef): any => {
  return {
    value: charger.chargePointSerialNumber,
    charger,
    label: (
      <div>
        <CarOutlined /> {charger.chargePointSerialNumber}
      </div>
    ),
  }
}

export const ChargerSelector: React.FC<props> = ({ defaultTenant, tenants, onChargerSelected, onClear, onSearch }) => {
  const [selected, setSelected] = useState<ChargerRef>()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const searchChargerText = useFormatMessage('dashboard.selector.searchCharger', 'Search Charger')

  const searchResult = (value: string) => {
    GetChargers(value, defaultTenant, tenants)
      .then((chargers) => {
        const opts = chargers.map((charger) => {
          return renderCharger(charger)
        })
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        setSelected(opt.charger)
        onChargerSelected(opt.charger)
      }
    })
  }

  const handleOnSearch = (value: string) => {
    options?.forEach((opt) => {
      if (opt?.value?.toString().toLowerCase() === value.toLowerCase()) {
        setSelected(opt.charger)
        onSearch(opt.charger)
      }
    })
  }

  return (
    <>
      {!selected && (
        <AutoComplete
          dropdownMatchSelectWidth={false}
          options={options}
          onSelect={onSelect}
          onSearch={searchResult}
          style={{ minWidth: 156, width: '100%' }}
        >
          <NoSearchIcon>
            <Search placeholder={searchChargerText} onSearch={(e) => handleOnSearch(e)} />
          </NoSearchIcon>
        </AutoComplete>
      )}
      {selected && (
        <Tag
          closable={true}
          onClose={(e) => {
            setSelected(undefined)
            onClear()
          }}
        >
          {selected.chargePointSerialNumber}
        </Tag>
      )}
    </>
  )
}
