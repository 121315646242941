import React, { useState } from 'react'

import { Form, Modal, Space, Spin } from 'antd'
import styled from 'styled-components'
import { closeSVG } from '../../../assets/svg/close'
import { ButtonModal } from '../../../atom/button'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule, PeakShavingProgram } from '../../../models/peak-shaving'
import {
  CreateDailyScheduleForEvent,
  CreateNewPeakShavingEvent,
  PublishPeakShavingEvent,
} from '../../../services/data-provider/peak-shaving'
import { EventDetailsBaseForm } from '../form/event-details-base-form'
interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  program: PeakShavingProgram
}

const CreateNewScheduleModalContainer = styled.div`
  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer .ant-btn {
    min-width: 240px;
  }

  .ant-modal-header {
    padding: 27px 25px 20px 25px;
  }
`

export const CreateNewDailyScheduleModal: React.FC<props> = ({ visible, onCancel, onSubmit, program }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [CreateNewScheduleForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const eventSchedules: Omit<PeakShavingEventSchedule, 'id'>[] = Array.from(Array(1), (_, index) => ({
    from: index,
    to: index + 1,
    zone: 'none',
  }))

  const { dailyEventCreateSuccessText } = usePeakShavingTranslation()

  const publishEventAndCreateSchedule = (event: PeakShavingEvent, fields: PeakShavingEventSchedule[]) => {
    const filteredFields = fields
      .filter((field: any) => field.zone !== 'none')
      .map((obj) => {
        return {
          from: obj.from,
          zone: obj.zone,
          dayType: obj.dayType,
        }
      })

    Promise.all([...filteredFields.map((field) => CreateDailyScheduleForEvent(event.id, field))])
      .then((resp) => {
        resp &&
          resp.length > 0 &&
          PublishPeakShavingEvent(event.id).then(() => {
            CustomSuccessMessage({
              key: 'CreateNewDailyEventAndSchedule',
              response: dailyEventCreateSuccessText,
            })
            onSubmit()
          })
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
      .finally(() => {
        CreateNewScheduleForm.resetFields()
      })
  }

  const handleFormSubmit = () => {
    CreateNewScheduleForm.validateFields().then((values) => {
      const {
        zonesWeekday,
        zonesWeekend,
        program,
        participants,
        ...createNewScheduleFormPrams
      } = CreateNewScheduleForm.getFieldsValue()

      const ZonesWeekday = CreateNewScheduleForm.getFieldValue('zonesWeekday')
      const ZonesWeekend = CreateNewScheduleForm.getFieldValue('zonesWeekend')
      const zones: PeakShavingEventSchedule[] = [
        ...ZonesWeekday.filter((obj: PeakShavingEventSchedule) => obj.zone !== 'none').map(
          (obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekday',
            }
          },
        ),
        ...ZonesWeekend.filter((obj: PeakShavingEventSchedule) => obj.zone !== 'none').map(
          (obj: PeakShavingEventSchedule) => {
            return {
              ...obj,
              dayType: 'weekend',
            }
          },
        ),
      ]

      if (zones.length === 0) {
        setError(
          new SwtchApiError({
            message: 'Unable to publish shaving event',
            code: '422',
            details: ['Event must have at least one schedule'],
          }),
        )
        setDisabled(true)
      } else {
        setDisabled(false)
        setLoading(true)
        Promise.all(
          participants.map((participant_id: number) =>
            CreateNewPeakShavingEvent({ ...createNewScheduleFormPrams, participant_id })
              .then((event: PeakShavingEvent) => {
                event && publishEventAndCreateSchedule(event, zones)
              })
              .catch((err: SwtchApiError) => setError(err)),
          ),
        ).finally(() => {
          setLoading(false)
          setDisabled(false)
          createNewScheduleFormPrams.resetFields()
        })
      }
    })
  }

  const handleCancel = () => {
    CreateNewScheduleForm.resetFields()
    setError(undefined)
    onCancel()
  }

  const handleChange = () => {
    setError(undefined)
    CreateNewScheduleForm.validateFields()
      .then(() => {
        setDisabled(false)
        setError(undefined)
      })
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  const { cancelText, publishText, createScheduleText } = usePeakShavingTranslation()

  return (
    <CreateNewScheduleModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{createScheduleText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <ButtonModal type="ghost" icon={<></>} click={handleCancel} text={cancelText} />
            <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={publishText} disabled={disabled} />
          </Space>
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`createNewScheduleModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        <AlertError error={error} closable={true} />
        <Spin spinning={loading}>
          {program && (
            <EventDetailsBaseForm
              program={program}
              form={CreateNewScheduleForm}
              disabled={false}
              schedules={eventSchedules}
              weekEndSchedules={eventSchedules}
              formMode="create"
              formListName="zones"
              scheduleFrequency="daily"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </CreateNewScheduleModalContainer>
  )
}
