import { Links } from '../../../components/routes/paths'
import ServiceStatusBadge from '../../../components/connectors/ServiceStatusBadge'

import { ChargerBase } from '../../../models/charger'
import { ConnectorV2 } from '../../../models/connector'

import { theme } from '../../../theme'
import { SpaceBetween } from '../../../atom/group'
import { CCSPlugForDCFastChargingSVG, CHAdeMOConnectorSVG, J1722ConnectorSVG } from '../../../assets/svg/connectorPlug'
import { Tooltip } from 'antd'

interface props {
  charger?: ChargerBase
  connector?: ConnectorV2
  hideAdditionalInfo?: boolean
  hideServiceStatus?: boolean
  showConnectorIdText?: boolean
}

export const handleChargerPlug = (
  charger?: ChargerBase,
  connector?: ConnectorV2,
  hideServiceStatus?: boolean,
  showConnectorIdText?: boolean,
) => {
  let plugIcon: undefined | JSX.Element
  if (charger?.chargingType === 'DC' && connector?.connectorType === 'CCS') {
    plugIcon = CCSPlugForDCFastChargingSVG
  } else if (charger?.chargingType === 'DC' && connector?.connectorType === 'CHAdeMO') {
    plugIcon = CHAdeMOConnectorSVG
  } else if (charger?.chargingType === 'Level 2' && connector?.connectorType === 'J1772') {
    plugIcon = J1722ConnectorSVG
  }

  return (
    <SpaceBetween>
      {plugIcon ? <Tooltip title={connector ? connector.connectorType : null}>{plugIcon}</Tooltip> : null}

      <span className="opacity-06 paragraph-04-light">
        {showConnectorIdText && connector ? `Conn. ID: ${connector?.connectorId}` : null}
        {!showConnectorIdText && connector ? connector.connectorId : null}{' '}
      </span>
      {!hideServiceStatus && connector && connector.ocppStatus ? (
        <ServiceStatusBadge state={connector.ocppStatus} />
      ) : null}
    </SpaceBetween>
  )
}

const ListingChargerAndConnector: React.FC<props> = ({
  charger,
  connector,
  hideAdditionalInfo = false,
  hideServiceStatus = false,
}) => {
  return (
    <div className="flex-and-gap-below">
      {charger && connector && <ChargerSerialNumber charger={charger} connector={connector} />}
      {charger && !connector && <ChargerSerialNumber charger={charger} />}
      {!charger && connector && <ChargerSerialNumber connector={connector} />}

      {!hideAdditionalInfo && handleChargerPlug(charger, connector, hideServiceStatus)}
    </div>
  )
}

const ChargerSerialNumber: React.FC<{ charger?: ChargerBase; connector?: ConnectorV2 }> = ({ charger, connector }) => {
  if (!charger && connector) {
    return null
  } else {
    return (
      <div className="pointer underline">
        <a
          key={charger?.id}
          style={{ color: theme.colors.softBlack }}
          href={charger?.id ? Links.charger({ chargerId: charger?.id, connectorId: 1 }) : undefined}
          target="_blank"
          rel="noopener noreferrer"
        >
          {charger?.chargePointSerialNumber || '-'}
        </a>
      </div>
    )
  }
}

export default ListingChargerAndConnector
