import { Spin } from 'antd'
import { ListView, LocaleProvider, Pagination } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import enUS from 'antd-mobile/lib/locale-provider/en_US'
import Empty from 'antd/es/empty'
import React, { useEffect, useState } from 'react'
import { styled } from '../theme'
import { PaginationMeta } from '../models/pagination'
import { theme } from '../theme'
export type MobileListViewI<T = any> = React.FC<props<T>>

interface props<T> {
  data: Array<T>
  renderItem(item: T): React.ReactElement<any>
  onPaginationChange?(page: number, pageSize?: number): void
  pagination?: PaginationMeta | undefined
  loading?: boolean
}

const PaginationWrapper = styled.div`
  .am-button.am-button-inline {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
  .am-flexbox-item.am-pagination-wrap > span.active {
    color: ${(props) => props.theme.colors.grey10};
  }
`

const MobileListView: MobileListViewI = ({ data, renderItem, onPaginationChange, pagination, loading }) => {
  const [dataSource, setDataSource] = useState(
    new ListView.DataSource({
      rowHasChanged: (row1: React.ReactElement<any>, row2: React.ReactElement<any>) => row1 !== row2,
    }),
  )

  const total = pagination === undefined ? 1 : Math.max(Math.ceil(pagination.totalEntries / pagination.perPage), 1)

  useEffect(() => {
    setDataSource(dataSource.cloneWithRows(data))
  }, [data])

  const renderRow = (rowData: any, sectionID: string | number, rowID: string | number, highlightRow?: boolean) => {
    return <div>{renderItem(rowData)}</div>
  }

  const listItemSeparator = (sectionID: string | number, rowID: string | number, adjacentRowHighlighted?: boolean) => (
    <div
      key={`${sectionID}-${rowID}`}
      style={{
        backgroundColor: theme.colors.grey1,
        height: 12,
        borderTop: `${theme.borderWidths[1]} solid ${theme.colors.grey1}`,
        borderBottom: `${theme.borderWidths[1]} solid ${theme.colors.grey1}`,
      }}
    />
  )

  const handleOnPaginationChange = (index: Number) => {
    onPaginationChange && onPaginationChange(index.valueOf())
  }

  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {data.length === 0 ? (
            <Empty />
          ) : (
            <>
              <ListView
                dataSource={dataSource}
                renderRow={renderRow}
                style={{
                  height: '100vh',
                  overflow: 'auto',
                }}
                renderSeparator={listItemSeparator}
              />
              {onPaginationChange && (
                <LocaleProvider locale={enUS}>
                  <PaginationWrapper>
                    <Pagination
                      total={total}
                      current={pagination?.currentPage}
                      onChange={handleOnPaginationChange}
                      style={{ marginTop: '8px' }}
                    />
                  </PaginationWrapper>
                </LocaleProvider>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default MobileListView
