import { theme } from 'theme'

export const buildingSVG = (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="locationIcon"
  >
    <path
      d="M13.3033 13.47L8 18.7733L2.69667 13.47C1.64779 12.4211 0.933489 11.0847 0.644107 9.62986C0.354725 8.17501 0.503256 6.66701 1.07092 5.29657C1.63858 3.92613 2.59987 2.7548 3.83324 1.93069C5.0666 1.10658 6.51665 0.666718 8 0.666718C9.48336 0.666718 10.9334 1.10658 12.1668 1.93069C13.4001 2.7548 14.3614 3.92613 14.9291 5.29657C15.4968 6.66701 15.6453 8.17501 15.3559 9.62986C15.0665 11.0847 14.3522 12.4211 13.3033 13.47ZM8 11.5C8.88406 11.5 9.7319 11.1488 10.357 10.5237C10.9821 9.89855 11.3333 9.05071 11.3333 8.16665C11.3333 7.2826 10.9821 6.43475 10.357 5.80963C9.7319 5.18451 8.88406 4.83332 8 4.83332C7.11595 4.83332 6.2681 5.18451 5.64298 5.80963C5.01786 6.43475 4.66667 7.2826 4.66667 8.16665C4.66667 9.05071 5.01786 9.89855 5.64298 10.5237C6.2681 11.1488 7.11595 11.5 8 11.5ZM8 9.83332C7.55798 9.83332 7.13405 9.65773 6.82149 9.34516C6.50893 9.0326 6.33334 8.60868 6.33334 8.16665C6.33334 7.72463 6.50893 7.3007 6.82149 6.98814C7.13405 6.67558 7.55798 6.49999 8 6.49999C8.44203 6.49999 8.86595 6.67558 9.17851 6.98814C9.49108 7.3007 9.66667 7.72463 9.66667 8.16665C9.66667 8.60868 9.49108 9.0326 9.17851 9.34516C8.86595 9.65773 8.44203 9.83332 8 9.83332Z"
      fill={theme.colors.primary}
    />
  </svg>
)
