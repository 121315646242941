import { theme } from 'theme'

export const exchangeSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5013 2.5H17.5013C17.7223 2.5 17.9343 2.5878 18.0906 2.74408C18.2468 2.90036 18.3346 3.11232 18.3346 3.33333V16.6667C18.3346 16.8877 18.2468 17.0996 18.0906 17.2559C17.9343 17.4122 17.7223 17.5 17.5013 17.5H2.5013C2.28029 17.5 2.06833 17.4122 1.91205 17.2559C1.75577 17.0996 1.66797 16.8877 1.66797 16.6667V3.33333C1.66797 3.11232 1.75577 2.90036 1.91205 2.74408C2.06833 2.5878 2.28029 2.5 2.5013 2.5ZM12.5013 5.83333V7.5H9.16797V9.16667H12.5013V10.8333L15.418 8.33333L12.5013 5.83333ZM7.5013 14.1667V12.5H10.8346V10.8333H7.5013V9.16667L4.58464 11.6667L7.5013 14.1667Z"
      fill={theme.colors.primary}
    />
  </svg>
)
