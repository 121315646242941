import { styled, theme } from 'theme'

export const GrayBackground = styled.div`
  background-color: ${theme.colors.grayBackground};
  padding: 16px;
  border-radius: 8px;
`

export const DarkGrayBackground = styled.div`
  background-color: ${theme.colors.cultered};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;

  .ant-row {
    margin-bottom: 0px !important;
  }

  .ant-form-item-control-input {
    width: 500px;
  }
`

export const DiscountPlanHeadingWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`

export const DiscountPlanSectionWrapper = styled.div`
  .ant-btn-default {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    border-color: ${theme.colors.lightGray};
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${theme.fontFamily.regularFontFamily};
    width: 175px;
    margin-right: 10px;
    margin-top: 24px;
    margin-left: 0px !important;
  }

  .ant-btn-default:hover {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.lightGray};
  }
`
