import { Button, Collapse, List, Pagination, Skeleton } from 'antd'
import { PaginationMeta } from '../../models/pagination'
import { UserCollapseWrapper } from '../../atom/collapse'
import { AugmentedUser, DashboardAccessType } from '../../models/user'
import { TenantRef } from '../../models/tenant'
import { ButtonTextSpacing } from '../../atom/user-invite'
import { editBlackSVG } from '../../assets/svg/edit'
import { usePlanTranslation } from '../../hooks/translation/usePlanTranslation'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import { useEffect, useState } from 'react'

interface props {
  loading: boolean
  users: AugmentedUser[]
  pagination: PaginationMeta | undefined
  tenants: TenantRef[]
  onPaginationChange: (page: number) => void
  setEditUserNameForm: (editUserNameForm: boolean) => void
  setSelectedUser: (selectedUser: AugmentedUser) => void
}

const UsersCollapse: React.FC<props> = ({
  loading,
  users,
  pagination,
  tenants,
  onPaginationChange,
  setEditUserNameForm,
  setSelectedUser,
}) => {
  const [pageSize, setPageSize] = useState(40)
  const { nameText } = usePlanTranslation()
  const { idText, userTypeText } = useUserTranslation()

  useEffect(() => {
    if (pagination) {
      setPageSize(pagination.perPage)
    }
  }, [pagination])

  const renderAccessForMobile = (user: AugmentedUser) => {
    const { tenantPermission } = user
    const permissions = Object.values(tenantPermission)

    let access: DashboardAccessType | 'Multi' | undefined
    if (tenants.length === 1) {
      access = permissions
        .filter((permission) => permission.id === tenants[0].id)
        .map((permission) => permission.access)[0]
    }
    if (access === undefined) {
      access = 'none'
    }

    if (access === 'none') {
      return <div>None</div>
    } else if (access === 'manager') {
      return <div>Manager</div>
    } else if (access === 'viewer') {
      return <div>Viewer</div>
    } else if (access === 'Multi') {
      return <div>Multi</div>
    }
  }

  return (
    <Skeleton loading={loading}>
      <UserCollapseWrapper isAdmin>
        <Collapse accordion bordered={false} expandIconPosition="right" style={{ marginBottom: '25px' }}>
          {users.map((user) => (
            <Collapse.Panel
              key={user.id}
              header={
                <div
                  className="paragraph-01-bold"
                  style={{
                    minHeight: '12px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {user.email}
                </div>
              }
            >
              <List
                dataSource={[user]}
                renderItem={(user) => (
                  <>
                    <List.Item actions={[user.id]}>
                      <List.Item.Meta key={`${user.id}-tenant`} title={idText} />
                    </List.Item>
                    <List.Item actions={[user.name]}>
                      <List.Item.Meta key={`${user.name}-tenant`} title={nameText} />
                    </List.Item>
                    <List.Item actions={[renderAccessForMobile(user)]}>
                      <List.Item.Meta key={`${user.role}-tenant`} title={userTypeText} />
                    </List.Item>
                    <>
                      <Button
                        style={{ width: '100%' }}
                        type="primary"
                        onClick={() => {
                          setSelectedUser(user)
                          setEditUserNameForm(true)
                        }}
                      >
                        {editBlackSVG}
                        <ButtonTextSpacing>
                          <span className="paragraph-02-regular">EDIT</span>
                        </ButtonTextSpacing>
                      </Button>
                    </>
                  </>
                )}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
        <Pagination
          current={pagination?.currentPage}
          onChange={onPaginationChange}
          pageSize={pageSize}
          total={pagination?.totalEntries}
          showQuickJumper={false}
          showSizeChanger={false}
          showTitle={true}
          style={{ textAlign: 'center' }}
        />
      </UserCollapseWrapper>
    </Skeleton>
  )
}

export default UsersCollapse
