import { useFormatMessage } from '../../helpers/intl'

export const useFinancialTranslation = () => {
  const taxText = useFormatMessage('dashboard.transactionPage.tax', 'Tax')
  const subTotalText = useFormatMessage('dashboard.transactionPage.subtotal', 'Sub-Total')
  const totalText = useFormatMessage('dashboard.transactionsPage.statsView.total', 'Total')

  return {
    taxText,
    subTotalText,
    totalText,
  }
}
