import { theme } from 'theme'

export const lightningSVG = (
  <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.208984 17.4442L11.2501 0.344666V10.7968H17.7952L6.75403 27.8964V17.4442H0.208984Z"
      fill={theme.colors.primary}
    />
  </svg>
)
