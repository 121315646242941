import { resetSVG } from '../../../../assets/svg/reset'
import { useGeneralTranslation } from '../../../../hooks/translation/useGeneralTranslation'
import { AdvancedSearchProps } from '.'
import { useAppState } from 'state'
import { Button } from 'antd'

type resetProps = Pick<AdvancedSearchProps, 'onResetAdvancedSearch'>

const ResetButton: React.FC<resetProps> = ({ onResetAdvancedSearch }) => {
  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { resetText } = useGeneralTranslation()
  return (
    <Button type="ghost" block={true} onClick={onResetAdvancedSearch} icon={resetSVG}>
      {isDesktop || isLaptop ? undefined : resetText}
    </Button>
  )
}

export default ResetButton
