import { Card } from 'antd'
import { CardWrapper } from 'atom/card'

interface props {
  title: string
  icon: JSX.Element
  loading: boolean
  subTitle?: string | number
  suffix?: string
  hideBorderBottom?: boolean
}

export const CoreConsumptionCard: React.FC<props> = ({
  title,
  icon,
  loading,
  subTitle,
  suffix,
  hideBorderBottom = false,
}) => {
  return (
    <CardWrapper>
      <Card
        title={
          <>
            {icon}
            <p className="heading-06-bold">{title}</p>
          </>
        }
        loading={loading}
        className={!hideBorderBottom ? 'show-border-bottom' : ''}
      >
        {subTitle !== undefined && (
          <h3 className="heading-02-black">
            {subTitle}
            {suffix !== undefined && <span className="paragraph-01-regular">{suffix}</span>}
          </h3>
        )}
      </Card>
    </CardWrapper>
  )
}

export default CoreConsumptionCard
