import { Calendar, List } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import en_US from 'antd-mobile/lib/calendar/locale/en_US'
import { CalendarProps } from 'antd-mobile/lib/calendar/PropsType'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { renderFormatMessage, useFormatMessage } from '../helpers/intl'
import { styled } from '../theme'

import 'moment/locale/fr-ca'
import 'moment/locale/en-ca'

const { Item } = List

interface props {
  startDate: moment.Moment
  endDate: moment.Moment
  handleConfirm(startDateTime?: moment.Moment, endDateTime?: moment.Moment): void
  setMobileCalendarVisible(visible: boolean): void
  language: string
  calendarRanges: string[]
}

const CalendarWrapper = styled.div`
  .am-calendar .shortcut-panel .item,
  .am-calendar .single-month .row .cell .date-wrapper + .date-selected,
  .am-calendar .header .left,
  .am-calendar .header .right {
    color: ${(props) => props.theme.colors.primary};
  }
  .am-calendar .single-month .row .cell .date-wrapper .date-selected,
  .am-calendar .confirm-panel .button {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }
`

export const MobileCalendar: React.FC<props & CalendarProps> = ({
  setMobileCalendarVisible,
  startDate,
  endDate,
  handleConfirm,
  language,
  calendarRanges,
  ...props
}) => {
  const [start, setStart] = useState<moment.Moment | undefined>(startDate)
  const [end, setEnd] = useState<moment.Moment | undefined>(endDate)

  useEffect(() => {
    setStart(moment().startOf('day'))
    setEnd(moment().startOf('day'))
  }, [language])

  const fr_CA = {
    title: useFormatMessage('dashboard.mobileCalendar.title', 'Calendar'),
    today: useFormatMessage('dashboard.mobileCalendar.today', 'Today'),
    month: useFormatMessage('dashboard.mobileCalendar.month', 'Month'),
    year: useFormatMessage('dashboard.mobileCalendar.year', 'Year'),
    am: useFormatMessage('dashboard.mobileCalendar.am', 'AM'),
    pm: useFormatMessage('dashboard.mobileCalendar.pm', 'PM'),
    dateTimeFormat: 'MM/dd/yyyy w hh:mm',
    dateFormat: 'yyyy/MM/dd w',
    noChoose: useFormatMessage('dashboard.mobileCalendar.nochoose', 'No Choose'),
    week: [
      useFormatMessage('dashboard.mobileCalendar.week.sun', 'Sun'),
      useFormatMessage('dashboard.mobileCalendar.week.mon', 'Mon'),
      useFormatMessage('dashboard.mobileCalendar.week.tue', 'Tue'),
      useFormatMessage('dashboard.mobileCalendar.week.wed', 'Wed'),
      useFormatMessage('dashboard.mobileCalendar.week.thu', 'Thu'),
      useFormatMessage('dashboard.mobileCalendar.week.fri', 'Fri'),
      useFormatMessage('dashboard.mobileCalendar.week.sat', 'Sat'),
    ],
    clear: useFormatMessage('dashboard.mobileCalendar.clear', 'Clear'),
    selectTime: useFormatMessage('dashboard.mobileCalendar.selectTime', 'Select Time'),
    selectStartTime: useFormatMessage('dashboard.mobileCalendar.selectStartTime', 'Select Start Time'),
    selectEndTime: useFormatMessage('dashboard.mobileCalendar.selectEndTime', 'Select End Time'),
    start: useFormatMessage('dashboard.mobileCalendar.start', 'Start'),
    end: useFormatMessage('dashboard.mobileCalendar.end', 'End'),
    begin: useFormatMessage('dashboard.mobileCalendar.begin', 'Start'),
    over: useFormatMessage('dashboard.mobileCalendar.over', 'End'),
    begin_over: useFormatMessage('dashboard.mobileCalendar.begin_over', 'S/E'),
    confirm: useFormatMessage('dashboard.mobileCalendar.confirm', 'Confirm'),
    monthTitle: 'yyyy/MM',
    loadPrevMonth: useFormatMessage('dashboard.mobileCalendar.loadPrevMonth', 'Load Prev Month'),
    yesterday: useFormatMessage('dashboard.mobileCalendar.yesterday', 'Yesterday'),
    lastWeek: useFormatMessage('dashboard.mobileCalendar.lastWeek', 'Last Week'),
    lastMonth: useFormatMessage('dashboard.mobileCalendar.lastMonth', 'Last Month'),
  }

  const chooseCalendarLocale = (lang: string | undefined) => {
    let locale = undefined
    switch (lang) {
      case 'fr':
        locale = fr_CA
        moment.locale('fr-ca')
        break
      case 'en':
        locale = en_US
        moment.locale('en-ca')
        break
      default:
        locale = en_US
        moment.locale('en-ca')
    }
    return locale
  }

  const onConfirm = (startDateTime?: Date, endDateTime?: Date) => {
    const startDate = startDateTime ? moment(startDateTime) : undefined
    const endDate = endDateTime ? moment(endDateTime) : undefined
    setStart(startDate)
    setEnd(endDate)
    handleConfirm(startDate, endDate)
  }

  const renderShortcut = (select: (startDate?: Date, endDate?: Date) => void) => {
    return (
      <div className="shortcut-panel">
        <div className="item" onClick={() => select(start?.toDate(), end?.toDate())}>
          {calendarRanges && calendarRanges[0]}
        </div>
        <div className="item" onClick={() => select(moment(start).startOf('week').toDate(), moment(end).toDate())}>
          {calendarRanges && calendarRanges[1]}
        </div>
        <div className="item" onClick={() => select(moment(start).startOf('month').toDate(), moment(end).toDate())}>
          {calendarRanges && calendarRanges[2]}
        </div>
        <div className="item" onClick={() => select(moment(start).startOf('year').toDate(), moment(end).toDate())}>
          {calendarRanges && calendarRanges[3]}
        </div>
      </div>
    )
  }

  return (
    <>
      <List style={{ backgroundColor: 'white' }}>
        <Item
          arrow="horizontal"
          onClick={() => {
            setStart(moment().startOf('day'))
            setEnd(moment().startOf('day'))
            setMobileCalendarVisible(true)
          }}
        >
          {renderFormatMessage('dashboard.mobileCalendar.selectDate', 'Select Date')}
        </Item>
        <Item>
          {renderFormatMessage('dashboard.mobileCalendar.start', 'Start')}
          {': '}
          {moment(start).startOf('day').format('LL HH:mm:ss')}
        </Item>
        <Item>
          {renderFormatMessage('dashboard.mobileCalendar.end', 'End')}
          {': '}
          {moment(end).endOf('day').format('LL HH:mm:ss')}
        </Item>
      </List>
      <CalendarWrapper>
        <Calendar
          locale={chooseCalendarLocale(language)}
          onConfirm={(startDateTime?: Date, endDateTime?: Date) => onConfirm(startDateTime, endDateTime)}
          pickTime={false}
          defaultDate={startDate.toDate()}
          renderShortcut={renderShortcut}
          showShortcut={true}
          rowSize={'xl'}
          {...props}
        />
      </CalendarWrapper>
    </>
  )
}
