import { styled, theme } from '../theme'

interface props {
  isAdmin?: boolean
}

export const TransactionsCollapseWrapper = styled.div<props>`
  font-family: ${theme.fontFamily.regularFontFamily};
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.07);

  .ant-collapse-extra {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-collapse-borderless {
    background-color: ${(props) => props.theme.colors.white};
    border: none;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.07);
  }

  .ant-collapse-item-active {
    border: none;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: ${(props) => props.theme.colors.grayBackground};
  }

  .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-list-item-action > li > div {
    color: ${theme.colors.softBlack};
  }
`
