import { useState } from 'react'
import pluralize from 'pluralize'
import { Alert, Button, Col, Form, Input, Row, Spin } from 'antd'
import { SwtchError } from '../../../models/error'
import { ChargerRef } from '../../../models/charger'
import { RemotePayloadResponse } from '../../../models/charger-remote'
import { RemoteUpdateConfig } from '../../../services/data-provider/charger-remote'
import { layout } from '../../../atom/form/modal-layout'
import { MoreSpacing } from '../../../atom/form/form-layout'
import { InputWrapper } from '../../../atom/chargers'
import { useChargerTranslation } from '../../../hooks/translation/useChargerTranslation'

interface props {
  chargers: ChargerRef[]
}

interface ConfigDataProps {
  key: string
  value: string
}

export const RemoteUpdateConfigurationRedesign: React.FC<props> = ({ chargers }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const filterChargers = chargers.filter((v, i) => {
    return chargers.map((val) => val.id).indexOf(v.id) === i
  })

  const [loading, setLoading] = useState(false)
  const [passed, setPassed] = useState(0)
  const [failed, setFailed] = useState(0)
  const [message, setMessage] = useState<RemotePayloadResponse[]>([])
  const [error, setError] = useState<SwtchError[]>([])
  const [errorMessage, setErrorMessage] = useState<RemotePayloadResponse[]>([])
  const {
    keyText,
    keyWarningText,
    keyInfoText,
    valueText,
    valueInfoText,
    updateConfigurationText,
  } = useChargerTranslation()

  const resetView = (loading: boolean) => {
    setLoading(loading)
    setError([])
    setErrorMessage([])
    setMessage([])
    setPassed(0)
    setFailed(0)
  }

  const onFinish = (values: ConfigDataProps) => {
    resetView(true)
    RemoteUpdateConfig({
      charger_ids: filterChargers.map((f) => f.id),
      key: values['key'],
      value: values['value'] === undefined ? '' : values['value'],
    })
      .then((resp) => {
        setPassed(resp.passed)
        setFailed(resp.failed)
        resp.payload.map((r: RemotePayloadResponse) => {
          return r.success ? setMessage((message) => [...message, r]) : setErrorMessage((error) => [...error, r])
        })
      })
      .catch((err: SwtchError) => setError((error) => [...error, err]))
      .finally(() => setLoading(false))
  }

  const processingRequest = (
    <Alert
      message={
        <>
          <Spin style={{ marginRight: '10px' }} />
          {`Sending configuration changes for ${filterChargers.length > 1 ? filterChargers.length : ''} ${pluralize(
            `charger${filterChargers.length === 1 ? filterChargers.map((c) => ` ${c.chargePointSerialNumber}`) : ''}`,
            filterChargers.length || 1,
          )}!`}
        </>
      }
      type="info"
    />
  )

  const errorAndSuccessResp = (
    <>
      {passed >= 1 &&
        message.map((res, index) => (
          <Alert key={index} message={res.serialNumber} description={res.message} type="info" />
        ))}
      {failed >= 1 &&
        errorMessage.map((err, index) => (
          <Alert key={index} message={err.serialNumber} description={err.message} type="error" />
        ))}
      {failed >= 1 &&
        error.map((err, index) => <Alert key={index} message={err.name} description={err.message} type="error" />)}
    </>
  )

  const ChangeConfigForm = (
    <Form {...layout} onFinish={onFinish}>
      <InputWrapper>
        <MoreSpacing>
          <Form.Item
            name="key"
            rules={[{ required: true, message: keyWarningText }]}
            hasFeedback={true}
            help={keyInfoText}
          >
            <Input placeholder={keyText} required={true} />
          </Form.Item>
        </MoreSpacing>
        <MoreSpacing>
          <Form.Item name="value" hasFeedback={true} help={valueInfoText}>
            <Input placeholder={valueText} required={true} />
          </Form.Item>
        </MoreSpacing>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {updateConfigurationText}
          </Button>
        </Form.Item>
      </InputWrapper>
    </Form>
  )

  return (
    <Row>
      <Col>{ChangeConfigForm}</Col>
      <Col span={24}>
        {loading && processingRequest}
        {errorAndSuccessResp}
      </Col>
    </Row>
  )
}
