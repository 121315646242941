import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exportData from 'highcharts/modules/export-data'
import HC_exporting from 'highcharts/modules/exporting'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TrxnOverview } from '../../../../models/transaction'
import { useAppState } from '../../../../state'

import 'moment/locale/en-ca'
import 'moment/locale/fr-ca'
import { theme } from '../../../../theme'
// import usaflag from '../../../../fonts/'

HC_exporting(Highcharts)
HC_exportData(Highcharts)

const HomePageOperationOverviewChartContainer = styled.div`
  .highcharts-tooltip-box {
    fill: ${(props) => props.theme.colors.softBlack};
    fill-opacity: 1;
    stroke-width: 0;
    width: 19px;
    height: 19px;
  }

  .highcharts-tooltip text {
    fill: white;
    color: white;
    text-shadow: 0 0 3px black;
  }
  .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: ${theme.colors.softBlack};
    stroke-dasharray: 8, 5;
  }

  .highcharts-halo {
    fill-opacity: 0;
    stroke-width: 0;
  }

  .highcharts-axis-line {
    fill: #000;
    stroke: #000;
  }
  .highcharts-yaxis .highcharts-axis-line,
  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1;
    fill: #dedede;
    stroke: #dedede;
  }

  .highcharts-tick,
  .highcharts-title {
    opacity: 0;
  }
`

export const ExportFilesContainer = styled.div`
  .exportData-sub-menu {
    color: ${theme.colors.menuBackground};
    background: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  li.exportData-sub-menu svg {
    margin-left: 10px;
    transition: transform 0.4s ease-in-out;
  }

  li.exportData-sub-menu:hover {
    background: ${theme.colors.buttonHovering};
    border: 1px solid ${theme.colors.buttonHovering};
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
  }

  li.exportData-sub-menu.ant-menu-submenu-open.ant-menu-submenu-active svg {
    transform: rotate(180deg);
  }
`

interface props {
  trnxOverview: TrxnOverview[]
  tabKey: string
  transactionInterval: string
  chartRef: React.MutableRefObject<null>
}

const handleFileName = (tabKey: string) => {
  return `Operation Overview ${tabKey} Data ${moment().format('YYYY-DD-MM')}`
}

const xAxisLabelFormatter = (value: string) => {
  switch (value) {
    case 'HOUR':
      return '%H:%M'
    case 'DAY':
      return '%d %b'
    case 'WEEK':
      return '%d %b'
    case 'MONTH':
      return '%b %Y'
  }
}

export const HomePageOperationOverviewChart: React.FC<props> = ({
  tabKey,
  trnxOverview,
  transactionInterval,
  chartRef,
}) => {
  const { language } = useAppState()

  useEffect(() => {
    switch (language) {
      case 'fr':
        moment.locale('fr-ca')
        break

      case 'en':
        moment.locale('en-ca')
        break
    }
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort(),
      },
    })
  }, [language])

  const [chartOptions, seChartOptions] = useState({
    chart: {
      zoomType: 'x',
      // styledMode: true,
      height: 400,
      style: {
        fontFamily: theme.fontFamily.regularFontFamily,
      },
      marginTop: 5,
    },

    xAxis: {
      type: 'datetime',
      crosshair: {
        width: 5,
        color: theme.colors.softBlack,
        dashStyle: 'ShortDash',
      },
      labels: {
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          fontSize: '11px',
        },
        //@ts-ignore
        formatter: function () {
          //@ts-ignore
          return Highcharts.dateFormat(xAxisLabelFormatter(transactionInterval), this.value)
        },
      },
      dateTimeLabelFormats: {
        month: {
          main: '%b %y',
        },
      },
      lineWidth: 1,
      minPadding: 0,
    },

    yAxis: {
      crosshair: {
        width: 5,
        color: theme.colors.softBlack,
        dashStyle: 'ShortDash',
      },
      labels: {
        format: `$ {value}`,
        style: {
          fontFamily: theme.fontFamily.regularFontFamily,
          fontWeight: 400,
          fontSize: '11px',
        },
      },
      title: {
        text: null,
      },
      lineWidth: 1,
      softMax: 1,
    },

    title: {
      text: '',
    },

    defs: {
      gradient0: {
        tagName: 'linearGradient',
        id: 'gradient-0',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [
          {
            tagName: 'stop',
            offset: 0,
          },
          {
            tagName: 'stop',
            offset: 1,
          },
        ],
      },
      gradient1: {
        tagName: 'linearGradient',
        id: 'gradient-1',
        x1: '0%',
        y1: '0%',
        x2: '100%',
        y2: '100%',
        children: [
          {
            tagName: 'stop',
            offset: 0,
          },
          {
            tagName: 'stop',
            offset: 1,
          },
        ],
      },
    },

    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: '0%',
            y1: '0%',
            x2: '0%',
            y2: '100%',
          },
          stops: [
            [0, theme.colors.graphLinearGradientFrom],
            [0.52, theme.colors.graphLinearGradientTo],
            [1, 'rgba(48, 215, 186, 0)'],
          ],
        },
        marker: {
          enabled: false,
          radius: 2,
          states: {
            hover: {
              fillColor: theme.colors.white,
              lineColor: theme.colors.primary,
            },
          },
        },
        //   lineWidth: 1,
        states: {
          hover: {
            //   lineWidth: 1,
          },
        },
        threshold: null,
      },
      series: {
        lineWidth: 1,
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        showInLegend: false,
        type: 'area',
        color: theme.colors.pieGraphPrimary,
        data: [],
        name: '',
      },
    ],
    tooltip: {
      style: {
        color: '#fff',
        fontFamily: theme.fontFamily.regularFontFamily,
        fontSize: '11px',
        fontWeight: 700,
        textAlign: 'center',
      },
      //@ts-ignore
      formatter: function () {
        //@ts-ignore
        return `$ ${this.y}`
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      csv: {
        dateFormat: '%Y-%m-%d',
      },
      filename: '',
      chartOptions: {
        chart: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
          },
        },
        title: {
          style: {
            fontFamily: theme.fontFamily.regularFontFamily,
            fontWeight: 400,
            fontSize: '10px',
          },
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: '0%',
                y1: '0%',
                x2: '0%',
                y2: '100%',
              },
              stops: [
                [0, 'rgb(226,249,245)'],
                [1, 'rgb(255, 255, 255)'],
              ],
            },
          },
        },
        xAxis: {
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          lineColor: '#000',
          lineWidth: 0.5,
          tickWidth: 0,
        },
        yAxis: {
          labels: {
            style: {
              fontFamily: theme.fontFamily.regularFontFamily,
              fontWeight: 400,
              fontSize: '8px',
            },
          },
          lineColor: '#dedede',
          lineWidth: 1,
        },
      },
    },
  })

  useEffect(() => {
    switch (tabKey) {
      case 'Revenue':
        const revenueData: any = trnxOverview.map((obj) => [moment(obj.datetime).unix() * 1000, obj.revenue / 100])
        seChartOptions({
          ...chartOptions,
          title: {
            text: `Operation Overview - ${tabKey}`,
          },
          yAxis: {
            ...chartOptions.yAxis,
            labels: {
              ...chartOptions.yAxis.labels,
              format: `$ {value}`,
            },
          },
          series: [
            {
              ...chartOptions.series[0],
              data: revenueData,
              name: 'Revenue ($)',
            },
          ],
          tooltip: {
            ...chartOptions.tooltip,
            //@ts-ignore
            formatter: function () {
              //@ts-ignore
              return `$ ${this.y}`
            },
          },
          exporting: {
            ...chartOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })
        break

      case 'Transactions':
        const transactionsData: any = trnxOverview.map((obj) => [moment(obj.datetime).unix() * 1000, obj.txnCount])
        seChartOptions({
          ...chartOptions,
          title: {
            text: `Operation Overview - ${tabKey}`,
          },
          yAxis: {
            ...chartOptions.yAxis,
            labels: {
              ...chartOptions.yAxis.labels,
              format: `{value}`,
            },
          },
          series: [
            {
              ...chartOptions.series[0],
              data: transactionsData,
              name: 'Transactions',
            },
          ],
          tooltip: {
            ...chartOptions.tooltip,
            //@ts-ignore
            formatter: function () {
              //@ts-ignore
              return `${this.y}`
            },
          },
          exporting: {
            ...chartOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })
        break

      case 'Energy':
        const energyData: any = trnxOverview.map((obj) => [moment(obj.datetime).unix() * 1000, obj.energy / 1000])
        seChartOptions({
          ...chartOptions,
          title: {
            text: `Operation Overview - ${tabKey}`,
          },
          yAxis: {
            ...chartOptions.yAxis,
            labels: {
              ...chartOptions.yAxis.labels,
              format: `{value} kWh`,
            },
          },
          series: [
            {
              ...chartOptions.series[0],
              data: energyData,
              name: 'Energy (kWh)',
            },
          ],
          tooltip: {
            ...chartOptions.tooltip,
            //@ts-ignore
            formatter: function () {
              //@ts-ignore
              return `${this.y} kWh`
            },
          },
          exporting: {
            ...chartOptions.exporting,
            filename: handleFileName(tabKey),
          },
        })
        break
    }
  }, [trnxOverview, tabKey, language])

  return (
    <HomePageOperationOverviewChartContainer>
      {tabKey && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
    </HomePageOperationOverviewChartContainer>
  )
}
