import { useState } from 'react'
import { Button, Col, DatePicker, DatePickerProps, Divider, Form, Input, message, Modal, Row, Select } from 'antd'
import en_US from 'antd/es/date-picker/locale/en_US'
import fr_CA from 'antd/es/date-picker/locale/fr_CA'
import moment from 'moment'

import { SwtchError } from '../../../models/error'
import { UserWallet } from '../../../models/user'
import { Plan } from '../../../models/plan'
import { Subscription } from '../../../models/subscription'
import { PlanSelector } from '../../selector/plan-selector'
import { CreateSubscription } from '../../../services/data-provider/subscriptions'
import { useAppState } from '../../../state'
import { renderFormatMessage, useFormatMessage } from '../../../helpers/intl'
import { labelAboveField } from '../../../atom/form/modal-layout'
import { UserSelector } from '../../selector/user-selector'
import { Address, locations, provinces, states } from '../../../helpers/location'
import { MustBePostalCode } from '../../rules/rules'
import { disabledDate } from '../../../helpers/date/calendar'
import { AlertErrorCompact } from '../../error-compact'

const { Option, OptGroup } = Select
interface props {
  onCancel: () => void
  onSubscribe: (subscription: Subscription) => void
  defaultPlan?: Plan
}

interface FormFields {
  zip: string

  state: string
  country: string
  user: UserWallet
}

const CreateSubscriptionForm: React.FC<props> = ({ onCancel, onSubscribe, defaultPlan }) => {
  const [form] = Form.useForm()
  const { language } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const [user, setUser] = useState<UserWallet | undefined>()
  const [plan, setPlan] = useState<Plan | undefined>(defaultPlan)

  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(undefined)

  const chooseDatePickerLocale = (lang: string) => {
    let locale = undefined
    switch (lang) {
      case 'fr':
        locale = fr_CA
        break
      case 'en':
        locale = en_US
        break
      default:
        locale = en_US
    }
    return locale
  }

  const afterClose = () => form.resetFields()

  const onOk = () => {
    setError(undefined)
    form.validateFields().then((values: FormFields) => {
      let address: Address = {}
      if (user?.hasBillingAddr) {
        const { zip, country, state } = user.walletStatus.billingAddr
        address = { zip, country, state }
      } else {
        const { zip, state, country } = values
        address = { zip, state: state.split('-')[0], country: country.slice(-2) }
      }

      if (plan && user) {
        setLoading(true)
        CreateSubscription(plan, user, startDate, address)
          .then((newSubscription) => {
            message.success(`new subscriber added to ${plan.name}`)
            onSubscribe(newSubscription)
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      }
    })
  }

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    if (date) {
      return setStartDate(date)
    }
  }

  const hasPlanAndUser = () => {
    return plan && user ? false : true
  }

  return (
    <>
      <AlertErrorCompact error={error} />
      <Modal
        title="Create Subscription"
        visible={true}
        onCancel={onCancel}
        onOk={onOk}
        confirmLoading={loading}
        afterClose={afterClose}
        footer={[
          <Button key="back" loading={loading} onClick={onCancel}>
            {useFormatMessage('dashboard.button.cancel', 'Cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={hasPlanAndUser()} onClick={onOk}>
            {useFormatMessage('dashboard.button.subscribe', 'Subscribe')}
          </Button>,
        ]}
      >
        <AlertErrorCompact error={error} />
        <Form form={form}>
          <Form.Item {...labelAboveField} key="plan" name="plan">
            <PlanSelector onPlanSelected={(p) => setPlan(p)} onSearch={(p) => setPlan(p)} defaultPlan={plan} />
          </Form.Item>

          {plan && (
            <Form.Item {...labelAboveField} key="email" name="email">
              <UserSelector
                onUserSelected={(user) => setUser(user)}
                onClear={() => setUser(undefined)}
                country={plan?.country}
              />
            </Form.Item>
          )}

          {plan && user && (
            <Row justify="space-between">
              <Col>
                <Form.Item
                  key="start_date"
                  name="startDate"
                  help="Leave blank to start subscription immediately or choose a date to schedule a subscription"
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    disabled={loading}
                    disabledDate={disabledDate}
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    locale={chooseDatePickerLocale(language)}
                    onChange={onChangeStartDate}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {user && !user.hasBillingAddr ? (
            <>
              <Divider>Billing Address</Divider>

              <Form.Item key="zip" name="zip" rules={[MustBePostalCode, { required: true }]}>
                <Input placeholder="Postal Code/Zip Code" disabled={loading} />
              </Form.Item>

              <Form.Item {...labelAboveField} key="state" name="state" rules={[{ required: true }]}>
                <Select
                  placeholder={renderFormatMessage('dashboard.text.provinceState', 'Province/State')}
                  showSearch
                  disabled={loading}
                >
                  <OptGroup label="Province">
                    {provinces.map((province, index) => (
                      <Option key={`${province.value}-${index}`} value={`${province.label} - ${province.value}`}>
                        {province.label} - {province.value}
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label={renderFormatMessage('dashboard.text.state', 'State')}>
                    {states.map((state, index) => (
                      <Option key={`${state.value}-${index}`} value={`${state.label} - ${state.value}`}>
                        {state.label} - {state.value}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
              <Form.Item {...labelAboveField} key="country" name="country" rules={[{ required: true }]}>
                <Select
                  placeholder={renderFormatMessage('dashboard.button.country', 'Country')}
                  showSearch
                  disabled={loading}
                >
                  {locations.map((location, index) => (
                    <Option key={`${location}-${index}`} value={`${location.label} - ${location.value}`}>
                      {location.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Modal>
    </>
  )
}

export default CreateSubscriptionForm
