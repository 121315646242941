import { useEffect, useState } from 'react'
import { Badge, Button } from 'antd'

import { theme } from 'theme'
import { closeSVG } from 'assets/svg/close'
import { filterSVG } from 'assets/svg/filter'

import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { TransactionFilter } from 'models/filter'

interface props {
  onShowAdvancedSearch: () => void
  showAdvancedSearch: boolean
  loading: boolean
  filter: TransactionFilter
}
const AdvancedSearchButton: React.FC<props> = ({ onShowAdvancedSearch, showAdvancedSearch, loading, filter }) => {
  const { filterText } = useGeneralTranslation()
  const [count, setCount] = useState(0)

  useEffect(() => {
    const newCount = calculateCount(filter)

    setCount(newCount)
  }, [filter])

  const calculateCount = (filter: TransactionFilter) => {
    let count = 0

    if (filter.chargers && filter.chargers.length > 0) {
      count += filter.chargers.length
    }
    if (filter.startMethod) count++
    if (filter.state) count++
    if (filter.userName) count++

    return count
  }

  return (
    <Badge count={count} color={theme.colors.primary}>
      <Button
        type="ghost"
        onClick={onShowAdvancedSearch}
        disabled={loading}
        icon={showAdvancedSearch ? closeSVG : filterSVG}
        className={`filter-button ${showAdvancedSearch ? 'active' : ''}`}
      >
        <span className="regular-cap">{filterText}</span>
      </Button>
    </Badge>
  )
}

export default AdvancedSearchButton
