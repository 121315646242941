import { formatDollarsToCents } from './number-format'

let startTime: string = ''
let endTime: string = ''
export let timeOfDayTimetable = [{ weekdays: null, weekends: null }]
export let weekdayTimeSlot: any = []
export let weekendTimeSlot: any = []
let temp: any = {}

export const resetTimeSlot = () => {
  timeOfDayTimetable[0].weekdays = null
  timeOfDayTimetable[0].weekends = null
  weekdayTimeSlot = []
  weekendTimeSlot = []
  return
}

const amOrPm = (hour: number) => {
  if (hour === 24) {
    return 'AM'
  }
  return hour >= 12 ? 'PM' : 'AM'
}

const getHourIn24Hours = (hour: number) => hour % 12 || 12

const setStartTime = (index: number) => {
  if (startTime === '') {
    if (index === 0) {
      startTime = '12:00 AM'
    } else {
      const hours = Math.floor(index / 2) // Calculate hours from the index
      const minutes = index % 2 === 0 ? '00' : '30' // Calculate minutes
      startTime = `${getHourIn24Hours(hours)}:${minutes} ${amOrPm(hours)}`
    }
  }
  return
}

const allEqual = (arr: number[]) => arr.every((val) => val === arr[0])

const setEndTime = (index: number) => {
  const hours = Math.floor(index / 2) // Calculate hours from the index
  const minutes = index % 2 === 0 ? '00' : '30' // Calculate minutes
  endTime = `${getHourIn24Hours(hours)}:${minutes} ${amOrPm(hours)}`
  return
}

const setNewTimeSlot = (
  timeOfDayPrice: number,
  priceType: string,
  timeOfWeek: string,
  isAllDay?: boolean,
  index?: number,
) => {
  let finalTimeSlot: string = ''

  finalTimeSlot = isAllDay ? 'All Day' : `${startTime} - ${endTime}`

  temp = { timeslot: finalTimeSlot, price: `$${formatDollarsToCents(timeOfDayPrice)}/${priceType}` }

  if (timeOfWeek === 'weekdays') {
    weekdayTimeSlot.push(temp)
  } else {
    // timeOfWeek === 'weekends'
    weekendTimeSlot.push(temp)
  }

  timeOfDayTimetable[0].weekdays = weekdayTimeSlot
  timeOfDayTimetable[0].weekends = weekendTimeSlot
  if (isAllDay) {
    return timeOfDayTimetable
  }
  resetStartTime(index)
  return
}

const resetStartTime = (index?: number) => {
  startTime = ''
  if (index) {
    setStartTime(index + 1)
  }
}

export const assemblyTimeOfDayTable = (todPrice: number[], priceType: string, timeOfWeek: string) => {
  startTime = ''
  if (todPrice.length !== 0 && allEqual(todPrice)) {
    // all day
    return setNewTimeSlot(todPrice[0], priceType, timeOfWeek, true)
  }

  for (let index = 0; index < todPrice.length; index++) {
    const timeOfDayPrice = todPrice[index]
    const nextTimeOfDayPrice = todPrice[index + 1]

    if (startTime === null || startTime === '') {
      setStartTime(index)
    }

    if (timeOfDayPrice === nextTimeOfDayPrice) {
      continue
    } else {
      setEndTime(index + 1)
    }

    setNewTimeSlot(timeOfDayPrice, priceType, timeOfWeek, false, index)
  }

  return timeOfDayTimetable
}
