import { UserEmailResponse, UserRef, UserWallet } from '../models/user'

export const mockUserRef = [
  {
    id: 'jXD9CMKXeavenIGoSK6qDQ',
    email: 'laura.bryson@gmail.com',
    name: 'Laura Christine Bryson',
  },
  {
    id: 'jXD9CMKXeavenIGoSK6qDQ23',
    email: 'laura.bryson@swtchenergy.com',
    name: 'Laura Bryson',
  },
  {
    id: 'yinh_nzFeJ4OK4MzU-ja-A',
    email: 'thomas.martin@swtchenergy.com',
    name: 'Thomas Martin-Admin',
  },
  {
    id: 'kSOpOau2d3Fnm2Rk7Mxc6A',
    email: 'weihang.tang@swtchev.com',
    name: 'Weihang Tang',
  },
  {
    id: '6J-GlL-V4rWCIInaxp5USg',
    email: 'nicole.politis@swtchenergy.com',
    name: 'Nicole Politis',
  },
  {
    id: 'bR0mXLrpBHqdvR6-AJHAqw',
    email: 'vladyslav.vakulenko@swtchenergy.com',
    name: 'Vladyslav Vakulenko',
  },
  {
    id: 'g0lCrYG7jpsrVxtGS3K67g',
    email: 'dylan.victoratto@swtchenergy.com',
    name: 'Dylan Victoratto',
  },
  {
    id: 'snDWpfj5p_y-Bn_fbUaBfg',
    email: 'sam.arabastani@swtchenergy.com',
    name: 'Sam Arabastani',
  },
  {
    id: 'q3uVBMCJPOacdrQB60bSrA',
    email: 'juliencassis@gmail.com',
    name: 'Julien Cassis',
  },
] as UserRef[]

export const mockUserWallet = [
  {
    ...mockUserRef[0],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
  {
    ...mockUserRef[1],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
  {
    ...mockUserRef[2],
    wallet: {
      id: 1,
      amount: 1000,
      person_id: 'sldfjsdlf',
      created_at: 'sjofsjf',
      updated_at: 'sjflsjdf',
      country: 'CA',
    },
    hasAlternatePaymentMethod: false,
    hasBillingAddr: false,
    billingAddr: {
      id: 1,
      person_id: 'ZHJGhjH9RgKk0JhpVLUfow',
      stripe_customer_id: 'cus_IXGr25Ncse4OPB',
      stripe_payment_gateway_id: 1,
      state: 'Ontario',
      zip: 'M3N 1S7',
      country: 'CA',
    },
    walletStatus: {
      hasWallet: true,
      walletCountry: 'CA',
      hasValidPaymentMethod: true,
      hasAlternatePaymentMethod: false,
      hasBillingAddr: true,
      billingAddr: {
        city: null,
        country: 'US',
        address1: null,
        address2: null,
        zip: '90210',
        state: 'CA',
      },
    },
    note: '',
  },
] as UserWallet[]

export const mockUserEmailResponse = {
  email_exist: true,
  person_id: 'jXD9CMKXeavenIGoSK6qDQ',
  person_name: 'Laura Christine Bryson',
} as UserEmailResponse
